import {
    Component,
    ContentChildren,
    QueryList,
    OnInit,
    AfterContentInit,
    ElementRef,
    ViewChild,
    Input,
    Output,
    EventEmitter,
    TemplateRef,
    ChangeDetectionStrategy,
    inject,
} from '@angular/core';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { AnimationBuilder, animate, style } from '@angular/animations';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { isNumber } from 'lodash';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

import { OnChange, SimpleChange } from '../../decorators';
import { resizeElement } from '../../utils';

import { CarouselItemDirective } from './carousel-item.directive';

const ANIMATION_TIMINGS = '200ms ease';
const FIRST_ITEM_INDEX = 0;

@UntilDestroy()
@Component({
    selector: 'fi-carousel',
    templateUrl: 'carousel.component.html',
    styleUrls: ['./carousel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [AsyncPipe, NgTemplateOutlet],
})
export class CarouselComponent implements OnInit, AfterContentInit {
    private readonly animationBuilder = inject(AnimationBuilder);

    @OnChange('onActiveItemIndexChange')
    @Input()
    activeItemIndex: number;

    @OnChange('onItemsPerSlideChange')
    @Input()
    itemsPerSlide = 1;

    @Input() preventPanMove = false;

    @Output() activeItemIndexUpdated = new EventEmitter<number>();

    @ContentChildren(CarouselItemDirective, { read: TemplateRef })
    items: QueryList<CarouselItemDirective>;

    @ViewChild('container', { read: ElementRef, static: true })
    private containerRef: ElementRef;

    @ViewChild('list', { read: ElementRef, static: true })
    private listRef: ElementRef;

    listTranslateValue$: Observable<string>;
    listWidthValue$: Observable<string>;

    private container: HTMLElement;
    private list: HTMLElement;

    private containerWidth: number;

    private currentItemIndex = FIRST_ITEM_INDEX;
    private currentItemIndexBeforeAnimation = this.currentItemIndex; // property to store item index before animation starts and allow changes to the item when the app changes activeItemIndex twice

    private currentTranslateOffset = 0;

    private listTranslateValueSubject = new BehaviorSubject(0);
    private listWidthValueSubject = new BehaviorSubject(0);

    ngOnInit(): void {
        this.handleListStylesUpdate();
    }

    ngAfterContentInit(): void {
        this.container = this.containerRef.nativeElement;
        this.list = this.listRef.nativeElement;

        this.setListWidth(this.itemsQuantity);

        this.handleItemsQuantityChange();
        this.handleContainerResize();
    }

    onActiveItemIndexChange(
        itemIndex: number,
        change: SimpleChange<number>,
    ): void {
        if (!change.isFirstChange() && this.canGoToItem(itemIndex)) {
            this.goToItem(itemIndex);
        }
    }

    onItemsPerSlideChange(
        _itemsPerSlide: number,
        change: SimpleChange<number>,
    ): void {
        if (!change.isFirstChange()) {
            this.handleListStylesUpdate();
        }
    }

    moveCarousel($event: HammerInput): void {
        if (this.preventPanMove) {
            return;
        }

        this.listTranslateValueSubject.next(
            (this.itemPercentProportion / this.containerWidth) * $event.deltaX +
                this.currentTranslateOffset,
        );
    }

    adjustCarousel($event: HammerInput): void {
        if (this.preventPanMove) {
            return;
        }

        if (this.canSlideLeft($event)) {
            this.goToItem(this.currentItemIndex + 1);
            return;
        }

        if (this.canSlideRight($event)) {
            this.goToItem(this.currentItemIndex - 1);
            return;
        }

        this.goToItem(this.currentItemIndex);
    }

    goToItem(itemIndex: number): void {
        this.currentItemIndexBeforeAnimation = itemIndex;

        const itemsOffset = -this.itemPercentProportion * itemIndex;

        const animationFactory = this.animationBuilder.build([
            animate(
                ANIMATION_TIMINGS,
                style({
                    transform: this.getListTranslateValue(itemsOffset),
                }),
            ),
        ]);
        const animationPlayer = animationFactory.create(this.list);

        animationPlayer.play();
        animationPlayer.onDone(() => {
            this.setListTranslateValue(itemsOffset);

            animationPlayer.destroy();

            this.setCurrentItemIndex(itemIndex);
        });
    }

    private get itemsQuantity(): number {
        return this.items.length;
    }

    private get firstItem(): boolean {
        return this.currentItemIndex === FIRST_ITEM_INDEX;
    }

    private get lastItem(): boolean {
        return this.currentItemIndex === this.lastItemIndex;
    }

    private get lastItemIndex(): number {
        return this.itemsQuantity - this.itemsPerSlide;
    }

    private get itemPercentProportion(): number {
        return 100 / this.itemsQuantity;
    }

    private get isItemsEnoughToSlide(): boolean {
        return this.itemsQuantity > this.itemsPerSlide;
    }

    private setListWidth(itemsQuantity: number): void {
        this.listWidthValueSubject.next(itemsQuantity);
    }

    private setListTranslateValue(itemsOffset: number): void {
        this.currentTranslateOffset = itemsOffset;
        this.listTranslateValueSubject.next(itemsOffset);
    }

    private setCurrentItemIndex(itemIndex: number): void {
        this.currentItemIndex = itemIndex;
        this.activeItemIndexUpdated.emit(itemIndex);
    }

    private handleItemsQuantityChange(): void {
        this.items.changes
            .pipe(
                map((items) => items.length),
                untilDestroyed(this),
            )
            .subscribe((itemsQuantity: number) => {
                const validatedItemIndex = this.getValidatedCurrentItemIndex();
                const itemsOffset =
                    -this.itemPercentProportion * validatedItemIndex;

                this.setCurrentItemIndex(validatedItemIndex);
                this.setListWidth(itemsQuantity);
                this.setListTranslateValue(itemsOffset);
            });
    }

    private getValidatedCurrentItemIndex(): number {
        if (!this.isItemsEnoughToSlide) {
            return 0;
        }

        // in case if items were deleted from the end of the carousel
        if (
            this.isItemsEnoughToSlide &&
            this.currentItemIndex > this.lastItemIndex
        ) {
            return this.lastItemIndex;
        }

        return this.currentItemIndex;
    }

    private handleListStylesUpdate(): void {
        this.listTranslateValue$ = this.listTranslateValueSubject.pipe(
            map((value) => this.getListTranslateValue(value)),
        );

        this.listWidthValue$ = this.listWidthValueSubject.pipe(
            map((value) => this.getListWidthValue(value)),
        );
    }

    private handleContainerResize(): void {
        resizeElement(this.container)
            .pipe(
                map(({ contentRect: { width } }) => width),
                distinctUntilChanged(),
                untilDestroyed(this),
            )
            .subscribe((width) => {
                this.containerWidth = width;
            });
    }

    private canSlideLeft($event: HammerInput): boolean {
        return (
            this.isSlideLeft($event.deltaX) &&
            !this.lastItem &&
            (this.isVelocityEnoughToSlide($event.velocityX) ||
                this.isMovedEnoughToSlide($event.deltaX))
        );
    }

    private canSlideRight($event: HammerInput): boolean {
        return (
            this.isSlideRight($event.deltaX) &&
            !this.firstItem &&
            (this.isVelocityEnoughToSlide($event.velocityX) ||
                this.isMovedEnoughToSlide($event.deltaX))
        );
    }

    private canGoToItem(itemIndex: number): boolean {
        return (
            isNumber(itemIndex) &&
            itemIndex !== this.currentItemIndexBeforeAnimation &&
            itemIndex >= FIRST_ITEM_INDEX &&
            itemIndex <= this.lastItemIndex
        );
    }

    private getListTranslateValue(offset: number): string {
        return `translate3d(${offset}%, 0, 0)`;
    }

    private getListWidthValue(itemsQuantity: number): string {
        return `${itemsQuantity * (100 / this.itemsPerSlide)}%`;
    }

    private isSlideLeft(deltaX: number): boolean {
        return deltaX < 0;
    }

    private isSlideRight(deltaX: number): boolean {
        return deltaX > 0;
    }

    private isVelocityEnoughToSlide(velocityX: number) {
        return Math.abs(velocityX) > 1;
    }

    private isMovedEnoughToSlide(deltaX: number): boolean {
        return Math.abs(deltaX) > this.containerWidth / 4;
    }
}
