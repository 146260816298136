import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { VariationDirective } from '../../directives';
import { MemoizeFuncPipe } from '../../pipes';
import { IconComponent } from '../icon';
import { FiCheckboxVariation } from './checkbox.constants';

@Component({
    selector: 'fi-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        VariationDirective,
        IconComponent,
        MemoizeFuncPipe,
    ]
})
export class CheckboxComponent {
    @Input() name: string;
    @Input() disabled = false;
    @Input() invariant = false;
    @Input() checked = false;
    @Input() stopPropagation = false;
    @Input() variation: string;
    @Output() change = new EventEmitter<boolean>();

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChange = (_: boolean) => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onTouched = () => {};

    registerOnChange(fn: (value: boolean) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    writeValue(value: boolean): void {
        this.checked = value;
    }

    changeCheckedStatus(event: Event, preventDefault?: boolean): void {
        if (this.stopPropagation) {
            event.stopPropagation();
        }

        if (preventDefault) {
            event.preventDefault();
        }

        if (this.disabled) {
            return;
        }

        this.checked = !this.checked;
        this.onChange(this.checked);
        this.onTouched();
        this.change.emit(this.checked);
    }

    getVariation([variation, isDisabled, invariant]: [string, boolean, boolean]): string {
        if (isDisabled) {
            return `${variation},${invariant ? FiCheckboxVariation.disabledInvariant : FiCheckboxVariation.disabled}`;
        }

        return variation;
    }
}
