<div
    [class.fi-select-menu-option--disabled]="disabled"
    [class.fi-select-menu-option--selected]="isSelected"
    (click)="handleSelect(value)"
    class="fi-select-menu-option"
>
    <span
        #text
        [fiVariation]="variation"
        [fiVariationBase]="'fi-text'"
        class="fi-select-menu-option__value"
    >
        <ng-content></ng-content>
    </span>
</div>
