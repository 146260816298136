import {
    generateIcon,
    generateServiceProviderIcon,
    generateUnitClusterIcon,
    generateUnitHistoryIcon,
    generateUnitRoadsideAssistanceIcon,
} from '../../icons/helpers';

import {
    MapMarkerType,
    SELECTED_MAP_MARKER_RATIO,
    ServiceProviderMapMarkerIcon,
    UnitClusterMapMarkerIcon,
    UnitDirectionMapMarkerIcon,
    UnitMapMarkerIcon,
} from './constants';
import { MapMarkerConfig, MapMarkerLabel, MapMarkerSize } from './models';

export interface AdvancedMapMarker<T extends MapMarkerConfig = MapMarkerConfig>
    extends google.maps.marker.AdvancedMarkerElement {
    updateFI(config: T): void;
    destroyFI(): void;
    getLabelFI(): MapMarkerLabel;
    markerConfig: T;
}

// in previous marker implementation google provided max z-index, discuss other approaches
export const MARKER_BASE_Z_INDEX = 999;

export const createAdvancedMapMarker = <
    T extends MapMarkerConfig = MapMarkerConfig,
>(
    map: google.maps.Map,
    markerConfig: T,
    hoverCallback?: (markerId?: string, markerConfig?: MapMarkerConfig) => void,
    selectCallback?: (markerId: string, markerConfig?: MapMarkerConfig) => void,
): AdvancedMapMarker<T> => {
    let hoverTimeout;
    const {
        id,
        iconType,
        isSelected,
        label,
        position: { latitude, longitude },
    } = markerConfig;

    const content = generateIconElement(markerConfig);
    const zIndex = generateZIndex(isSelected, markerConfig['zIndex']);

    // Instantiate the AdvancedMarkerElement with the appropriate options
    const advancedMarker = new google.maps.marker.AdvancedMarkerElement({
        map: map,
        position: new google.maps.LatLng(latitude, longitude),
        content,
        zIndex,
        title: iconType,
    });

    // Google Maps automatically adds ARIA labels and title to markers for accessibility and additional tooltip is shown on envs
    advancedMarker?.element?.removeAttribute('aria-label');
    advancedMarker?.element?.removeAttribute('title');

    advancedMarker.addListener('click', () => {
        selectCallback && selectCallback(id, markerConfig);
    });

    // Extend the marker to add custom methods
    const extendedMarker = Object.assign(advancedMarker, {
        markerConfig,
        hoverCallback,
        selectCallback,
        label,

        // we use label to keep all additional information (unitNumber, location type, etc.)
        getLabelFI(): MapMarkerLabel {
            return label || this.markerConfig.label || null;
        },

        updateFI(config: T): void {
            const {
                iconType: previousIconType,
                position: previousPosition,
                isHovered: previousIsHovered,
                isSelected: previousIsSelected,
                isFaded: previousIsFaded,
                label: previousLabel,
            } = this.markerConfig;

            const zIndex = config['zIndex'];

            const {
                position: { longitude, latitude },
                isSelected,
                iconType,
                label,
                isHovered,
                isFaded,
            } = config;

            if (
                previousPosition.latitude !== longitude ||
                previousPosition.longitude !== latitude
            ) {
                this.position = new google.maps.LatLng(latitude, longitude);
            }

            if (
                previousIsSelected !== isSelected ||
                previousIsHovered !== isHovered ||
                previousIsFaded !== isFaded ||
                previousIconType !== iconType ||
                previousLabel?.text !== label?.text
            ) {
                this.zIndex = generateZIndex(isSelected, zIndex);
                updateIconElement(config);
            }

            if (this.markerConfig['zIndex'] !== zIndex) {
                this.zIndex = generateZIndex(isSelected, zIndex);
            }

            if (previousLabel !== label) {
                this.label = label || null;
            }

            this.markerConfig = { ...config };
        },

        destroyFI(): void {
            this.map = null;
        },
    });

    function generateZIndex(
        isSelected?: boolean,
        zIndex?: number | undefined,
    ): number | undefined {
        return isSelected
            ? SELECTED_MAP_MARKER_RATIO * MARKER_BASE_Z_INDEX
            : zIndex;
    }

    function getIconSize(markerConfig: T): MapMarkerSize {
        const {
            markerSizes: { scaledSize, size },
            isSelected,
            isHovered,
        } = markerConfig;

        return isSelected || isHovered ? scaledSize || size : size;
    }

    function generateIconElement(config: T): Node {
        const { label, id } = config;

        const content = getContent(config);
        const iconSize = getIconSize(config);

        const tag = document.createElement('span');
        tag.id = id;
        tag.className = 'fi-icon';
        tag.style.width = `${iconSize.x}px`;
        tag.style.height = `${iconSize.y}px`;
        tag.style.opacity = config['opacity'] || 1;

        if ('clickable' in config) {
            tag.style.cursor = config['clickable'] ? 'pointer' : 'initial';
        }

        tag.innerHTML = content;

        addLabel(label, tag);
        addEventListener(tag, id, config);

        return tag;
    }

    function updateIconElement(config: T): void {
        const content = getContent(config);
        const { x, y } = getIconSize(config);

        const tag = advancedMarker.content as HTMLElement;

        tag.style.width = `${x}px`;
        tag.style.height = `${y}px`;
        tag.style.opacity = config['opacity'] || 1;

        tag.innerHTML = content;

        addLabel(config.label, tag);
    }

    // show label under the marker
    function addLabel(label: MapMarkerLabel, tag: HTMLElement): void {
        if (!label?.['showLabel']) {
            return;
        }

        const labelElement = createLabelElement(label);
        tag.appendChild(labelElement);
    }

    function addEventListener(
        element: HTMLElement,
        markerId: string,
        config?: MapMarkerConfig,
    ): void {
        let isHovered = false;

        element.addEventListener('mouseenter', () => {
            hoverTimeout = setTimeout(() => {
                if (!isHovered) {
                    isHovered = true;
                    hoverCallback && hoverCallback(markerId, config);
                }
            }, 100);
        });

        element.addEventListener('mouseleave', () => {
            isHovered = false;
            clearTimeout(hoverTimeout);
            hoverCallback && hoverCallback();
        });
    }

    function createLabelElement({
        text,
        className,
    }: MapMarkerLabel): HTMLElement {
        const labelElement = document.createElement('span');

        labelElement.textContent = text;
        labelElement.className = className;

        return labelElement;
    }

    function getContent(config: T): string {
        const { iconType, isHovered, label, type, isFaded } = config;

        switch (type) {
            case MapMarkerType.UnitHistory: {
                return generateUnitHistoryIcon(
                    iconType as UnitDirectionMapMarkerIcon,
                    isHovered,
                );
            }

            case MapMarkerType.UnitWithRoadReport: {
                return generateUnitRoadsideAssistanceIcon(
                    iconType as UnitMapMarkerIcon,
                    isHovered,
                    isFaded,
                );
            }

            case MapMarkerType.UnitClusterSmall:
            case MapMarkerType.UnitClusterMedium:
            case MapMarkerType.UnitClusterLarge: {
                return generateUnitClusterIcon(
                    iconType as UnitClusterMapMarkerIcon,
                    label?.text || '',
                );
            }

            case MapMarkerType.ServiceProvider: {
                return generateServiceProviderIcon(
                    label?.className,
                    iconType as ServiceProviderMapMarkerIcon,
                    isHovered,
                    isFaded,
                    label?.text,
                );
            }

            default:
                return generateIcon(iconType);
        }
    }

    return extendedMarker as AdvancedMapMarker<T>;
};
