import {
    AfterContentInit,
    Directive,
    ElementRef,
    Input,
    inject
} from '@angular/core';

@Directive({
    selector: '[autoFocus]',
    standalone: true,
})
export class AutofocusDirective implements AfterContentInit {
    private readonly elRef = inject(ElementRef);

    @Input() focusTarget: string;
    @Input() focusTargetSecondary: string;

    ngAfterContentInit(): void {
        const { focusTarget, focusTargetSecondary, elRef } = this;
        const selected =
            elRef.nativeElement.querySelector(`.${focusTarget}`) ||
            elRef.nativeElement.querySelector(`.${focusTargetSecondary}`);
        if (selected) {
            (selected as HTMLElement).focus();
        }
    }
}
