import { Component, Input, ViewEncapsulation } from '@angular/core';

import { VariationDirective } from '../../../../directives/variation.directive';

const enum ModalContentTopVariation {
    Borderless = 'borderless',
    MobileGrey = 'mobile-grey',
    LowIndent = 'low-indent',
    NoIndent = 'no-indent',
}

@Component({
    selector: 'fi-modal-content-top',
    templateUrl: './modal-content-top.component.html',
    styleUrls: ['./modal-content-top.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [VariationDirective],
})
export class ModalContentTopComponent {
    @Input() variation: ModalContentTopVariation;
}
