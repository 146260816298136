import {
    ChangeDetectionStrategy,
    Component,
    Input,
    TemplateRef,
} from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';

import { GridCellDefDirective } from './cell-def.directive';

@Component({
    selector: '[fi-grid-cell]',
    templateUrl: './cell.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgTemplateOutlet],
})
export class GridCellComponent {
    @Input() fieldName = '';
    @Input() row: any;
    @Input() tpl: TemplateRef<GridCellDefDirective>;
}
