import { Injectable } from '@angular/core';

import {
    TagManagerBaseService,
    AnalyticsBaseService,
} from '@pnsk/ui-common/analytics';

@Injectable({ providedIn: 'root' })
export class AnalyticsService extends AnalyticsBaseService {
    constructor(protected gtmService: TagManagerBaseService) {
        super(gtmService);
    }
}
