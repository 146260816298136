import { formatAsSecondaryDateString } from '../../../../utils/transform-numeric-value';

import {
    getEmptyMaskValue,
    FiInputMaskValue,
    FiInputMaskBase,
} from './input-mask-base';

const DATE_FORMAT = '__/__/__';

export class DateSecondaryInputMask extends FiInputMaskBase {
    getValue(value: string): FiInputMaskValue {
        const maskValue = getEmptyMaskValue();

        const filled = formatAsSecondaryDateString(value);
        const placeholder = DATE_FORMAT.substring(filled.length);

        maskValue.filled = filled;
        maskValue.placeholder = placeholder;

        return maskValue;
    }
}
