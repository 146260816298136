<div class="fi-grid-table-header-sticky__spacer" #stickySpacer></div>

@if (!noFilterAutoClose) {
    <div
        class="fi-grid-table-header-sticky__container"
        filterAutoClose
        [scrollTop$]="scrollTop$"
        [stickyStateChange$]="stickyStateChange$"
        [isSticky]="isSticky"
        #stickyContainer
    >
        <ng-container [ngTemplateOutlet]="contentTpl"></ng-container>
    </div>
}

@if (noFilterAutoClose) {
    <div class="fi-grid-table-header-sticky__container" #stickyContainer>
        <ng-container [ngTemplateOutlet]="contentTpl"></ng-container>
    </div>
}

<ng-template #contentTpl>
    <ng-content></ng-content>
</ng-template>
