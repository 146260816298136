import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    OnInit,
    Optional,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { distinctUntilChanged, map, observeOn } from 'rxjs/operators';
import { combineLatest, animationFrameScheduler, Subject } from 'rxjs';
import { get, isEqual } from 'lodash';

import { resizeElement } from '../../../../utils';
import { EnvironmentService } from '../../../../services';
import { TableStickyStyler } from '../../services';
import { GridCustomConfigDirective } from '../../custom-config/custom-grid-config.directive';
import { GridStickyHeaderService } from '../../services/sticky-header.service';

import { GridStickyHeaderFilterAutoCloseDirective } from './filter-auto-close.directive';

// TODO: Get this value as header height from equal component
// @todo: investigate why 71? the top menu height 72px
const STICKY_MARGIN_TOP = 71; // px
const ALERT_BANNER_HEIGHT = 56;
const SUPPORT_HEADER = 56;
const SUPPORT_MODE_SEARCH_HEADER = 52;

@UntilDestroy()
@Component({
    selector: 'fi-grid-sticky-header',
    templateUrl: './sticky-header.component.html',
    styleUrls: ['./sticky-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgTemplateOutlet, GridStickyHeaderFilterAutoCloseDirective],
})
export class GridStickyHeaderComponent implements AfterViewInit, OnInit {
    private boundaryElement: HTMLElement;
    private readonly stickyStateSubject = new Subject<boolean>();

    @ViewChild('stickyContainer')
    stickyContainer: ElementRef;
    @ViewChild('stickySpacer') stickySpacer: ElementRef;

    scrollTop$ = this.environmentService.windowScroll$.pipe(
        map(({ top }) => top),
        distinctUntilChanged(),
        untilDestroyed(this),
    );

    noFilterAutoClose = false;

    isSticky = false;
    stickyStateChange$ = this.stickyStateSubject.asObservable();

    constructor(
        @Optional() private gridCustomConfig: GridCustomConfigDirective,
        private gridStickyHeaderService: GridStickyHeaderService,
        private environmentService: EnvironmentService,
        private stickyStyler: TableStickyStyler,
        private renderer: Renderer2,
        private elmRef: ElementRef,
    ) {}

    ngOnInit(): void {
        this.boundaryElement = this.renderer.parentNode(
            this.elmRef.nativeElement,
        );

        if (this.isInFullPageForm()) {
            this.applyFullPageScroll();
        }
        this.applyCustomConfig();
    }

    ngAfterViewInit(): void {
        const elementResize = combineLatest(
            resizeElement(this.elmRef.nativeElement),
            this.environmentService.windowResize$,
        ).pipe(
            map(
                ([
                    {
                        contentRect: { width, height },
                    },
                ]) => ({
                    width,
                    height,
                }),
            ),
            distinctUntilChanged(isEqual),
            untilDestroyed(this),
        );

        const containerResize = resizeElement(
            this.stickyContainer.nativeElement,
        ).pipe(
            map(({ contentRect: { height }, target }) => ({
                height,
                left: this.getComputedStyle(target as HTMLElement).left,
            })),
            distinctUntilChanged(isEqual),
            untilDestroyed(this),
        );

        combineLatest(elementResize, containerResize, this.scrollTop$)
            .pipe(observeOn(animationFrameScheduler), untilDestroyed(this))
            .subscribe(([elementResize, containerResize, scrollTop]) => {
                const isSticky = this.isStickyCheck(scrollTop);

                if (isSticky) {
                    this.setStickyStyle(
                        scrollTop,
                        elementResize,
                        containerResize,
                    );
                    this.stickyStateSubject.next(true);
                } else if (this.isSticky !== isSticky) {
                    this.clearStickyStyle();
                    this.stickyStateSubject.next(false);
                }

                this.isSticky = isSticky;
            });
    }

    private applyFullPageScroll(): void {
        this.scrollTop$ = this.gridStickyHeaderService?.containerScroll$?.pipe(
            map(({ top }) => top),
            distinctUntilChanged(),
            untilDestroyed(this),
        );
    }

    private isInFullPageForm(): boolean {
        const { classList } = document.body;
        return classList.contains('full-page-form');
    }

    private applyCustomConfig(): void {
        this.noFilterAutoClose = get(
            this.gridCustomConfig,
            'config.noFilterAutoClose',
            false,
        );
    }

    private isStickyCheck(scrollTop: number): boolean {
        return !!(
            scrollTop >
            this.getYPosition(this.elmRef.nativeElement) - STICKY_MARGIN_TOP
        );
    }

    private getTopIndent(): number {
        const { classList } = document.body;
        const hasAlerts = classList.contains('alert-banner');
        const isSupportMode = classList.contains('support-mode');
        const supportModeSearch = classList.contains('support-mode-search');
        const isNestedGroupsPage = classList.contains('page-nested-groups');
        const fullPageForm = classList.contains('full-page-form');

        let marginTop = !isNestedGroupsPage ? STICKY_MARGIN_TOP : 0;

        if (hasAlerts) {
            marginTop = marginTop + ALERT_BANNER_HEIGHT;
        }

        if (isSupportMode) {
            marginTop = marginTop + SUPPORT_HEADER;
        }

        if (fullPageForm) {
            // todo: use only STICKY_MARGIN_TOP when app-header & app-header-shared have equal height
            marginTop = STICKY_MARGIN_TOP + 1;
        }

        if (supportModeSearch) {
            marginTop = SUPPORT_MODE_SEARCH_HEADER;
        }

        return marginTop;
    }

    private setStickyStyle(
        scrollTop: number,
        elementResize: DOMRectReadOnly,
        containerResize: DOMRectReadOnly,
    ) {
        const marginTop = this.getTopIndent();
        const bottom = this.getBoundaryElementBottom();
        const { width, left, height } = elementResize;

        const top = Math.min(bottom - scrollTop - height, marginTop);

        this.stickyStyler.setElementStyles([this.stickySpacer], {
            height: `${containerResize.height}px`,
            position: 'relative',
        });

        this.stickyStyler.setElementStyles([this.stickyContainer], {
            top: `${top}px`,
            left: `${left}px`,
            width: `${width}px`,
        });

        this.stickyContainer.nativeElement.classList.add(
            'fi-grid-table-header-sticky__container--sticky',
        );
    }

    private clearStickyStyle(): void {
        this.stickyStyler.setElementStyles(
            [this.stickySpacer, this.stickyContainer],
            {
                height: null,
                position: null,
                top: null,
                left: null,
                width: null,
            },
        );

        this.stickyContainer.nativeElement.classList.remove(
            'fi-grid-table-header-sticky__container--sticky',
        );
    }

    private getBoundaryElementBottom(): number {
        const boundaryElement = this.boundaryElement;

        const { height } = this.getComputedStyle(boundaryElement);
        const y = this.getYPosition(boundaryElement);

        return height + y;
    }

    private getComputedStyle(element: HTMLElement): ClientRect | DOMRect {
        return element.getBoundingClientRect();
    }

    // Thanks to https://stanko.github.io/javascript-get-element-offset/
    private getYPosition(el: any): number {
        let top = 0;
        let element = el;

        // Loop through the DOM tree
        // and add it's parent's offset to get page offset
        do {
            top += element.offsetTop || 0;
            element = element.offsetParent;
        } while (element);

        return top;
    }
}
