import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { VariationDirective } from '../../directives';

type AlertIconVariation = 'warning';

@Component({
    selector: 'fi-alert-icon',
    templateUrl: './alert-icon.component.html',
    styleUrls: ['./alert-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [VariationDirective],
})
export class AlertIconComponent {
    @Input() variation: AlertIconVariation;
}
