import { Pipe, PipeTransform } from '@angular/core';

/*
 *   USAGE:
 *   {{ argument | memoizeFunc : method [: context] }}
 *
 *   You could provide multiple arguments for the function like that:
 *   {{ [argument1, argument2] | memoizeFunc : method [: context] }}
 *
 *
 *   FUNCTION WITH CONTEXT
 *   We still could have optimization in impure functions with Angular pipes. Just provide context as second parameter
 *   to the pipeFunction pipe.
 *
 *   {{ argument | memoizeFunc : method : this }}
 *
 *   Alternative variant use 'bind'
 *
 *   {{ argument | memoizeFunc : method.bind(this) }}
 *
 * */

@Pipe({
    name: 'memoizeFunc',
    standalone: true,
})
export class MemoizeFuncPipe implements PipeTransform {
    transform<T, K>(value: T, handler: (value: T) => K, context?: any): K {
        if (context) {
            return handler.call(context, value);
        }

        return handler(value);
    }
}
