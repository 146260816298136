export enum ReportingPeriod {
    Anytime = 'ANYTIME',
    ThisWeek = 'THIS_WEEK',
    ThisMonth = 'THIS_MONTH',
    LastThirtyDays = 'LAST_THIRTY_DAYS',
    LastSixtyDays = 'LAST_SIXTY_DAYS',
    Last365Days = 'LAST_365_DAYS',
    ThisQuarter = 'THIS_QUARTER',
    ThisYear = 'THIS_YEAR',
    CustomDate = 'CUSTOM_DATE',
    Under30Days = 'UNDER_30_DAYS',
    From31to60Days = '31_TO_60_DAYS',
    From61to90Days = '61_TO_90_DAYS',
    Over90Days = 'OVER_90_DAYS',
}
