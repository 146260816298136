import { SSO_ID_LENGTH } from '../../input.component';

import {
    FiInputMaskBase,
    getEmptyMaskValue,
    FiInputMaskValue,
} from './input-mask-base';

export class SsoIdMask extends FiInputMaskBase {
    getValue(value: string): FiInputMaskValue {
        const maskValue = getEmptyMaskValue();

        const { filled, placeholder } = this.getFilledRangeWithPlaceholder(
            value,
            SSO_ID_LENGTH,
        );

        maskValue.filled = filled;
        maskValue.placeholder = placeholder;

        return maskValue;
    }
}
