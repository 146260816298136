export function getNumericInputCaretOriginalPosition(
    beforeCaretValue: string,
    transformedValue: string,
    isValueDeleted: boolean,
) {
    const beforeCaretValueRegex = new RegExp(
        `^\\D*${beforeCaretValue.split('').join('\\D*')}${
            isValueDeleted ? '' : '\\D*'
        }`,
        'ig',
    );
    const [beforeCaretTransformedValue = ''] =
        transformedValue.match(beforeCaretValueRegex) || [];
    const targetCaretPosition = beforeCaretTransformedValue.length;

    return targetCaretPosition;
}

export function replaceValueNonDigits(
    value: string,
    maxlength?: number,
): string {
    if (!value) {
        return value;
    }

    const replacedValue = value.replace(/[^0-9]/g, '');

    return maxlength ? replacedValue.substring(0, maxlength) : replacedValue;
}

export function replaceValueNonAlphaNumeric(
    value: string,
    maxlength?: number,
): string {
    if (!value) {
        return value;
    }

    const replacedValue = value.replace(/[^a-zA-Z0-9]/g, '');

    return maxlength ? replacedValue.substring(0, maxlength) : replacedValue;
}


export function formatAsDateString(value: string): string {
    if (!value) {
        return '';
    }

    const digitsOnly = value.replace(/[^0-9]/g, '');

    const month = digitsOnly.slice(0, 2);
    const day = digitsOnly.slice(2, 4);
    const year = digitsOnly.slice(4, 8);

    return [month, day, year].filter((value) => !!value).join('/');
}

export function formatAsSecondaryDateString(value: string): string {
    if (!value) {
        return '';
    }

    const digitsOnly = value.replace(/[^0-9]/g, '');

    const month = digitsOnly.slice(0, 2);
    const day = digitsOnly.slice(2, 4);
    const year = digitsOnly.slice(4, 6);

    return [month, day, year].filter((value) => !!value).join('/');
}