<div [fiVariation]="[variation]" [fiVariationBase]="'fi-grid'">
    <div class="fi-grid__table-wrapper" #tableWrapperRef>
        <ng-content select="fi-grid-header-panel"></ng-content>

        <!-- -------------------------- Desktop table ------------------------------ -->
        @if ((isDesktopView$ | async) && !showCustomTemplate) {
            <fi-grid-table
                [columns]="columns$ | async"
                [groupBy]="groupBy"
                [hasExpandingRow]="hasExpandingRows"
                [variation]="tableVariation"
                [noNestedDataPlaceholderDef]="noNestedDataPlaceholderDef"
                #gridTableRef
            >
                <!----- Sticky table header content ---->
                <ng-container [ngTemplateOutlet]="mainPanel"></ng-container>
                <!---- End Sticky table header content --->
            </fi-grid-table>
        }

        <!-- --------------------------- Mobile list -------------------------------- -->
        @if ((isMobileView$ | async) && !showCustomTemplate) {
            <fi-grid-list
                [columns]="columns$ | async"
                [groupBy]="groupBy"
                [guidedTourMobileStep]="guidedTourMobileStep"
            >
                <ng-container
                    ngProjectAs="mainPanel"
                    [ngTemplateOutlet]="mainPanel"
                ></ng-container>
                <ng-container
                    ngProjectAs="customSkeleton"
                    [ngTemplateOutlet]="customSkeleton"
                ></ng-container>
            </fi-grid-list>
        }

        @if (showCustomTemplate) {
            <ng-container
                ngProjectAs="mainPanel"
                [ngTemplateOutlet]="mainPanel"
            ></ng-container>
            <ng-container
                ngProjectAs="customContent"
                [ngTemplateOutlet]="customContent"
            ></ng-container>
        }

        <!-- --------------------------- No Data Placeholder -------------------------------- -->
        @if (!isDataPresent && !isInitializing && !isLoadingError) {
            <div
                [class.fi-grid__no-data-indicator--loading]="hasLoaderInNoData"
                class="fi-grid__no-data-indicator fi-text fi-text--medium"
                id="gridNoDataHolder"
            >
                @if (noDataIconPlaceholderDef) {
                    <ng-container
                        [ngTemplateOutlet]="noDataIconPlaceholderDef"
                    ></ng-container>
                } @else {
                    <fi-icon
                        [svgName]="'file-alt-slash'"
                        [variation]="'light'"
                        [size]="'42x'"
                        class="fi-grid__no-data-indicator-icon"
                        id="gridNoDataIcon"
                    ></fi-icon>
                }
                @if (noDataPlaceholderDef) {
                    <ng-container
                        [ngTemplateOutlet]="noDataPlaceholderDef"
                    ></ng-container>
                } @else {
                    There is no data to display
                }
                @if (hasLoaderInNoData) {
                    <fi-loader-penske></fi-loader-penske>
                }
            </div>
        }

        <!-- --------------------------- Error Placeholder -------------------------------- -->
        @if (isLoadingError && dataErrorPlaceholderDef) {
            <div id="gridDataErrorHolder">
                <ng-container
                    [ngTemplateOutlet]="dataErrorPlaceholderDef"
                ></ng-container>
            </div>
        }
    </div>

    @if (isFooterShown) {
        <div class="fi-grid__footer-panel-wrapper">
            <div [class.fi-grid__footer-panel]="isDesktopView$ | async">
                @if (isDesktopView$ | async) {
                    <div>
                        <fi-skeleton [isInitializing]="isInitializing">
                            <ng-content
                                select="fi-grid-footer-panel"
                            ></ng-content>
                            <fi-skeleton-element></fi-skeleton-element>
                        </fi-skeleton>
                    </div>
                }
                <fi-grid-paginator
                    [skipPersistence]="notPersistentPaginator"
                    [class.fi-grid__footer-panel--paginator-mobile]="
                        isMobileView$ | async
                    "
                    (pageNumberChange)="pageNumberChange()"
                ></fi-grid-paginator>
            </div>
        </div>
    }
</div>

<ng-template #mainPanel>
    <ng-content select="fi-grid-main-panel"></ng-content>
</ng-template>

<ng-template #customSkeleton>
    <ng-content select="fi-grid-list-skeleton"></ng-content>
</ng-template>

<ng-template #customContent>
    <ng-content select="fi-grid-custom-content"></ng-content>
</ng-template>
