import {
    Component,
    EventEmitter,
    Input,
    Output,
    ChangeDetectionStrategy,
} from '@angular/core';

import { ButtonComponent } from '../button/button.component';
import { IconComponent } from '../icon/icon.component';

@Component({
    selector: 'fi-horizontal-control',
    templateUrl: './horizontal-control.component.html',
    styleUrls: ['./horizontal-control.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        ButtonComponent,
        IconComponent,
    ]
})
export class HorizontalControl {
    @Input() hasPrevious: boolean;
    @Input() hasNext: boolean;

    @Output() previousClick = new EventEmitter<void>();
    @Output() nextClick = new EventEmitter<void>();

    onPreviousClick(): void {
        this.previousClick.emit();
    }

    onNextClick(): void {
        this.nextClick.emit();
    }
}
