import { NgModule, Type } from '@angular/core';

import { PillComponent } from './pill.component';

const FEATURES: Array<Type<any> | any[]> = [PillComponent];

@NgModule({
    imports: [...FEATURES],
    exports: FEATURES,
})
export class PillModule {}
