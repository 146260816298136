import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'toNumArray', standalone: true })
export class ToNumArray implements PipeTransform {
    transform(value: number, isAddPrefix = false): any {
        const res = [];
        for (let i = 0; i <= value; i++) {
            res.push(isAddPrefix && i < 10 ? '0' + i : i);
        }
        return res;
    }
}
