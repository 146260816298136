import { Injectable } from '@angular/core';

import { EnvironmentService } from '../../../services';
import { GridOnTabletDirective } from '../on-tablet/on-tablet.directive';

@Injectable()
export class GridOnTabletService {
    isMobileView$ = this.environmentService.isMobile$;
    isDesktopView$ = this.environmentService.isDesktop$;

    constructor(private environmentService: EnvironmentService) {}

    setViewVariations(gridOnTablet: GridOnTabletDirective | boolean): void {
        if (gridOnTablet) {
            this.isMobileView$ = this.environmentService.isMobileDevice$;
            this.isDesktopView$ = this.environmentService.isDesktopDevice$;
        } else {
            this.isMobileView$ = this.environmentService.isMobile$;
            this.isDesktopView$ = this.environmentService.isDesktop$;
        }
    }
}
