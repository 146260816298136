import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { ScrollDispatcher } from '@angular/cdk/overlay';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounce } from 'lodash';
import { fromEvent, merge, Subject } from 'rxjs';
import {
    filter,
    startWith,
    switchMap,
    tap,
    withLatestFrom,
} from 'rxjs/operators';

import { InputType } from '../../components/input/input.component';
import { KeyCode } from '../../constants';
import { OnChange } from '../../decorators';
import { IconComponent } from '../../components';
import { VariationDirective } from '../../directives';
import { MemoizeFuncPipe } from '../../pipes';
import { GridOnTabletService } from '../grid/services';
import {
    ModalCloseBarComponent,
    ModalComponent,
    ModalContentComponent,
    ModalVariation,
} from '../modal';

import {
    SearchAutocompleteListComponent,
    SearchAutocompleteOption,
    SearchAutocompleteSettings,
} from './autocomplete-list/autocomplete-list.component';
import { SearchTrimOnPasteDirective } from './search-trim-on-paste.directive';

function handleInputChange<T>(
    fn: (value: T) => void,
    debounceTime: number,
): (value: T) => void {
    const timer = debounce(fn, debounceTime);

    return (value: T) => {
        if (!value) {
            timer.cancel();
            fn(value);
            return;
        }

        timer(value);
    };
}

@UntilDestroy()
@Component({
    selector: 'fi-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SearchComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgTemplateOutlet,
        FormsModule,
        ReactiveFormsModule,
        VariationDirective,
        SearchTrimOnPasteDirective,
        IconComponent,
        ModalComponent,
        ModalCloseBarComponent,
        ModalContentComponent,
        SearchAutocompleteListComponent,
        MemoizeFuncPipe,
    ],
})
export class SearchComponent
    implements OnInit, OnChanges, AfterViewInit, ControlValueAccessor
{
    @Input() debounce = 0; // Default debounce time is 0ms
    @Input() name: string;
    @Input() placeholder: string;
    @Input() loading = false;
    @Input() variation = '';
    @Input() type = InputType.Text;
    @Input() maxLength: number = Number.MAX_SAFE_INTEGER;
    @Input() options: SearchAutocompleteOption[];
    @Input() closeModalAfterClear = true;
    @Input() ariaLabel: string;
    @Input() skipAutofocus = false;

    @OnChange('removeEmptySuggestions')
    @Input()
    suggestions: SearchAutocompleteOption[] = [];
    @Input() suggestionsSettings: SearchAutocompleteSettings;
    @Input() optionsSettings: SearchAutocompleteSettings;

    @OnChange(function (this: SearchComponent, value) {
        this.isValuePresent = !!value;
    })
    @Input()
    value = '';

    @Output() valueChange = new EventEmitter<string>();
    @Output() focus = new EventEmitter<void>();
    @Output() blur = new EventEmitter<void>();
    @Output() search = new EventEmitter<void>();
    @Output() clear = new EventEmitter<void>();
    @Output() selectOption = new EventEmitter<SearchAutocompleteOption>();

    @ViewChild('inputElement') inputElement: ElementRef;
    @ViewChild('modalInput') modalInput: ElementRef;
    @ViewChild('clearButton') clearButton: ElementRef;

    readonly modalVariation = [ModalVariation.FullHeight];
    readonly baseItemClass = 'fi-search-autocomplete-list__item';
    readonly baseInputClass = 'fi-search__input';
    readonly isMobileView$ = this.gridOnTabletService.isMobileView$;

    isFocused = false;
    isValuePresent = false;
    nonEmptySuggestions: SearchAutocompleteOption[] = [];
    updateValue: (value: string) => void;

    isSearchOverlayOpened = false;

    classList: string;

    protected arrowKeyDownSubject = new Subject<KeyboardEvent>();
    isArrowKeyDown$ = this.arrowKeyDownSubject.asObservable();

    private activeOption: SearchAutocompleteOption;

    constructor(
        protected changeDetector: ChangeDetectorRef,
        private el: ElementRef,
        protected scrollDispatcher: ScrollDispatcher,
        protected gridOnTabletService: GridOnTabletService,
    ) {}

    ngOnInit(): void {
        if (!this.name) {
            throw new Error(`'name' property is required for 'fi-search'`);
        }

        this.updateValue = handleInputChange((value: string) => {
            this.onChange(value);
            this.valueChange.emit(value);
        }, this.debounce);
    }

    ngAfterViewInit(): void {
        this.handleEvents();
    }

    @HostListener('keydown', ['$event'])
    onArrowDown(event: KeyboardEvent): void {
        if (
            this.isFocused &&
            (event.code === KeyCode.ArrowDown || event.code === KeyCode.ArrowUp)
        ) {
            event.preventDefault();
            this.arrowKeyDownSubject.next(event);
        }
    }

    removeEmptySuggestions(): void {
        this.nonEmptySuggestions = this.suggestions.filter(
            (suggestion) => !!suggestion.text.trim(),
        );
    }

    handleEvents(): void {
        const clearButtonElement = this.clearButton.nativeElement;
        const inputElement = this.inputElement.nativeElement;
        inputElement.autocomplete = 'off';

        const inputFocusEvent = fromEvent(inputElement, 'focus');
        const inputMouseDownEvent = fromEvent(inputElement, 'mousedown');

        const eventToHandle$ = this.skipAutofocus
            ? inputMouseDownEvent
            : merge(inputFocusEvent, inputMouseDownEvent);

        eventToHandle$
            .pipe(
                withLatestFrom(this.isMobileView$),
                tap(([event, isMobile]: [MouseEvent, boolean]) => this.handleFocus(event, isMobile)),
                untilDestroyed(this),
            )
            .subscribe(() => {
                this.changeDetector.markForCheck();
            });


        fromEvent(clearButtonElement, 'mousedown')
            .pipe(
                withLatestFrom(this.isMobileView$),
                tap(([event, isMobile]: [MouseEvent, boolean]) => {
                    this.handleClear();

                    if (isMobile) {
                        this.handleFocus(event, isMobile);
                    } else {
                        setTimeout(
                            () => this.setInputFocus(this.inputElement),
                            0,
                        );
                    }
                }),
                startWith(true),
                switchMap(() =>
                    fromEvent(document, 'keydown').pipe(
                        filter(
                            (event: KeyboardEvent) =>
                                event.code === KeyCode.Escape,
                        ),
                        untilDestroyed(this),
                    ),
                ),
                untilDestroyed(this),
            )
            .subscribe(() => {
                this.handleBlur();
                this.changeDetector.markForCheck();
            });

        this.scrollDispatcher
            .scrolled()
            .pipe(
                filter(() => this.isFocused),
                untilDestroyed(this),
            )
            .subscribe(() => {
                this.handleBlur();
                this.changeDetector.markForCheck();
            });

        fromEvent(document, 'click')
            .pipe(untilDestroyed(this))
            .subscribe(({ target }: Event) => this.handleClickOutside(target));
    }

    ngOnChanges(): void {
        const currentVariations = this.variation.split(',');
        const variations = [...currentVariations];

        if (this.isFocused) {
            variations.push('is-typing');
        }

        if (this.loading) {
            variations.push('loading');
        }

        if (this.isValuePresent) {
            variations.push('not-empty');
        }

        this.classList = variations.join(',');
    }

    writeValue(value: string): void {
        this.value = value;
    }

    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    registerOnTouched() {}

    handleSearchClick(): void {
        this.closeModal();
        this.isFocused = false;

        this.search.emit();
    }

    handleEnterKeyDown(): void {
        if (this.activeOption) {
            this.handleSelectOption(this.activeOption);
            this.activeOption = null;
            return;
        }

        this.handleSearchClick();
        this.inputElement.nativeElement.blur();
    }

    handleChange(value: string, canBeFocused = true): void {
        this.activeOption = null;
        this.isValuePresent = !!value;

        this.updateValue(value);

        if (!this.isFocused && canBeFocused) {
            this.isFocused = true;
            this.focus.emit();
        }
    }

    handleSelectOption(option: SearchAutocompleteOption): void {
        this.valueChange.emit(option.text);
        this.selectOption.emit(option);

        this.closeModal();
        this.isFocused = false;
    }

    setActiveOption(option: SearchAutocompleteOption): void {
        this.activeOption = option;
    }

    getAutocompleteHeader([
        { isHeadingHidden = false, headerText = '' } = {},
        defaultText,
    ]: [SearchAutocompleteSettings, string]): string {
        if (isHeadingHidden) {
            return '';
        }

        return headerText || defaultText;
    }

    getAutocompleteVariation([{ variation = '' } = {}, { length }]: [
        SearchAutocompleteSettings,
        SearchAutocompleteOption[],
    ]): string {
        return `${variation}${length ? ',bottomless' : ''}`;
    }

    closeModal(): void {
        this.isSearchOverlayOpened = false;
    }

    handleBlur(): void {
        this.blur.emit();
        this.isFocused = false;
    }

    handleClear(canBeFocused?: boolean): void {
        if (this.closeModalAfterClear) {
            this.closeModal();
        }

        this.handleChange('', canBeFocused);
        this.clear.emit();
    }

    handleFocus(event: MouseEvent, isMobile: boolean): void {
        if (isMobile) {
            event.preventDefault();
            event.stopPropagation();
            this.isSearchOverlayOpened = true;

            setTimeout(() => this.setInputFocus(this.modalInput), 0);
        } else {
            this.isFocused = true;
        }

        this.focus.emit();
    }

    setInputFocus(elementRef: ElementRef): void {
        const element = elementRef.nativeElement;
        if (!element) {
            return;
        }

        element.focus();
    }

    @HostListener('focusout', ['$event'])
    onFocusOut({ relatedTarget }: FocusEvent): void {
        if (!relatedTarget) {
            return;
        }

        const isInputFocused = (relatedTarget as Element).classList.contains(
            this.baseInputClass,
        );
        const isItemFocused = (relatedTarget as Element).classList.contains(
            this.baseItemClass,
        );

        if (!isInputFocused && !isItemFocused) {
            this.handleBlur();
            this.changeDetector.markForCheck();
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private onChange = (_: string) => {};

    protected handleClickOutside(target: EventTarget): void {
        if (!this.el.nativeElement.contains(target)) {
            this.handleBlur();
            this.changeDetector.markForCheck();
        }
    }
}
