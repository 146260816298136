<div class="table-search">
    <fi-search
        [placeholder]="config?.placeholder"
        [name]="config?.storageKey + '-grid-search'"
        [suggestions]="searchSuggestions"
        [suggestionsSettings]="config?.suggestionsSettings"
        [options]="searchOptions"
        [(value)]="searchState.searchPhrase"
        [loading]="search.isSearching"
        [variation]="variation"
        [maxLength]="config?.maxLength"
        [skipAutofocus]="skipAutofocus"
        [type]="config?.type"
        (valueChange)="handleSearchChange($event)"
        (search)="
            handleSearch(searchState.searchPhrase, searchState.searchPrefix)
        "
        (selectOption)="
            handleSelectSearchOption(searchState.searchPhrase, $event)
        "
        (clear)="handleSearchClear()"
        class="table-search__input"
    ></fi-search>
</div>
