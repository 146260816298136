export enum LastLoggedIn {
    AllTime = 'ALL_TIME',
    Last7Days = 'LAST_7_DAYS',
    Last30Days = 'LAST_30_DAYS',
    Last60Days = 'LAST_60_DAYS',
    Last90Days = 'LAST_90_DAYS',
    PastYear = 'PAST_YEAR',
    MoreThan30DaysAgo = 'MORE_THAN_30_DAYS_AGO',
    MoreThan60DaysAgo = 'MORE_THAN_60_DAYS_AGO',
    MoreThan90DaysAgo = 'MORE_THAN_90_DAYS_AGO',
    MoreThanAYearAgo = 'MORE_THAN_A_YEAR_AGO',
    Never = 'NEVER',
}
