import { NgModule, Type } from '@angular/core';

import { AccordionComponent } from './accordion.component';
import { AccordionItemDirective } from './item/item.directive';
import { AccordionItemHeaderDirective } from './item/header/header.directive';
import { AccordionItemContentDirective } from './item/content/content.directive';
import { AccordionHeaderComponent } from './accordion-header/accordion-header.component';
import { AccordionGroupDirective } from './group/group.directive';
import { AccordionGroupHeaderDirective } from './group/header/header.directive';
import { AccordionItemCustomHeaderDirective } from './item/header/custom-header.directive';
import { AccordionItemExpandedSubHeaderDirective } from './item/sub-header/sub-header-expanded.directive';
import { AccordionItemSubHeaderCollapsedDirective } from './item/sub-header/sub-header-collapsed.directive';
import { AccordionExpanderIconComponent } from './expander-icon/expander-icon.component';
import { AccordionStubComponent } from './accordion-stub/accordion-stub.component';
import { AccordionStubDirective } from './accordion-stub/stub.directive';

const FEATURES: Array<Type<any> | any[]> = [
    AccordionComponent,
    AccordionGroupDirective,
    AccordionGroupHeaderDirective,
    AccordionItemDirective,
    AccordionItemHeaderDirective,
    AccordionItemContentDirective,
    AccordionHeaderComponent,
    AccordionItemExpandedSubHeaderDirective,
    AccordionItemSubHeaderCollapsedDirective,
    AccordionItemCustomHeaderDirective,
    AccordionStubComponent,
    AccordionStubDirective,
    AccordionExpanderIconComponent,
];

@NgModule({
    imports: [...FEATURES],
    exports: FEATURES,
})
export class AccordionModule {}
