import { NgControl } from '@angular/forms';

import { Observable } from 'rxjs';

import { formatAsSecondaryDateString } from '../../../utils/transform-numeric-value';

import { InputFormatBase, InputFormatParsedValue } from './input-format-base';

const FORMATTED_DATE_LENGTH = 8;

export class SecondaryDateFormat extends InputFormatBase {
    valueChanges$: Observable<string>;

    constructor(
        ngControl: NgControl,
        private inputElement: HTMLInputElement,
        private initialValue: string,
    ) {
        super(ngControl);

        const transformedInitialValue = formatAsSecondaryDateString(this.initialValue);
        this.setControlValue(transformedInitialValue);

        this.valueChanges$ = this.getInputEvent(transformedInitialValue);
    }

    protected getParsedValue([
        previousValue,
        currentValue,
    ]: string[]): InputFormatParsedValue {
        const {
            selectionEnd: caretPosition,
            value: originalValue, // just updated yet unformatted value
        } = this.inputElement;
        const isValueDeleted = originalValue.length < previousValue.length;

        return {
            caretPosition,
            isValueDeleted,
            originalValue,
            transformedValue: formatAsSecondaryDateString(currentValue),
        };
    }

    protected returnCaretToOriginalPosition({
        caretPosition,
        transformedValue,
        isValueDeleted,
    }: InputFormatParsedValue): void {
        let targetCaretPosition = 0;

        if (
            isValueDeleted ||
            transformedValue.length === FORMATTED_DATE_LENGTH
        ) {
            targetCaretPosition = caretPosition;
        } else {
            targetCaretPosition = transformedValue.length;
        }

        this.inputElement.setSelectionRange(
            targetCaretPosition,
            targetCaretPosition,
        );
    }
}
