import { DISTRICT_NUMBER_LENGTH } from '../../input.component';
import { FiInputMaskBase, FiInputMaskValue, getEmptyMaskValue } from './input-mask-base';

export class DistrictInputMask extends FiInputMaskBase {
    getValue(value: string): FiInputMaskValue {
        const maskValue = getEmptyMaskValue();

        const { filled, placeholder } = this.getFilledRangeWithPlaceholder(
            value,
            DISTRICT_NUMBER_LENGTH,
        );

        maskValue.filled = filled;
        maskValue.placeholder = placeholder;

        return maskValue;
    }
}
