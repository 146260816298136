import { Provider } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { MockHttpInterceptor } from '@pnsk/ui-common/interceptors/mock-http.interceptor';

import { ApiVersionInterceptor } from './api-version.interceptor';
import { DefaultHeadersInterceptor } from './default-headers.interceptor';

export const httpInterceptorProviders: Provider[] = [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: ApiVersionInterceptor,
        multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: DefaultHeadersInterceptor,
        multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: MockHttpInterceptor,
        multi: true,
    },
];
