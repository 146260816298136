import { NgControl } from '@angular/forms';

import { Observable } from 'rxjs';

import { SSO_ID_LENGTH } from '../input.component';
import {
    getNumericInputCaretOriginalPosition,
    replaceValueNonDigits,
} from '../../../utils';

import { InputFormatBase, InputFormatParsedValue } from './input-format-base';

export class SsoIdFormat extends InputFormatBase {
    valueChanges$: Observable<string>;

    constructor(
        ngControl: NgControl,
        private inputElement: HTMLInputElement,
        private initialValue: string,
    ) {
        super(ngControl);

        const transformedInitialValue = this.getTransformedValue(
            this.initialValue,
        );
        this.setControlValue(transformedInitialValue);

        this.valueChanges$ = this.getInputEvent(transformedInitialValue);
    }

    protected getParsedValue([
        previousValue,
        currentValue,
    ]: string[]): InputFormatParsedValue {
        const {
            selectionEnd: caretPosition,
            value: originalValue, // just updated yet unformatted value
        } = this.inputElement;
        const isValueDeleted = originalValue.length < previousValue.length;

        return {
            caretPosition,
            isValueDeleted,
            originalValue,
            transformedValue: this.getTransformedValue(currentValue),
        };
    }

    protected returnCaretToOriginalPosition({
        caretPosition,
        originalValue,
        transformedValue,
        isValueDeleted,
    }: InputFormatParsedValue): void {
        const beforeCaretValue = replaceValueNonDigits(
            originalValue.substring(0, caretPosition),
            SSO_ID_LENGTH,
        );

        const targetCaretPosition = getNumericInputCaretOriginalPosition(
            beforeCaretValue,
            transformedValue,
            isValueDeleted,
        );

        this.inputElement.setSelectionRange(
            targetCaretPosition,
            targetCaretPosition,
        );
    }

    private getTransformedValue(value: string): string {
        if (!value) {
            return '';
        }

        return replaceValueNonDigits(value, SSO_ID_LENGTH);
    }
}
