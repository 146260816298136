import { NgModule, Type } from '@angular/core';

import { FleetProfileMetricsBarComponent } from './fleet-profile-bar/fleet-profile-bar.component';

const FEATURES: Array<Type<any> | any[]> = [
    FleetProfileMetricsBarComponent,
];

@NgModule({
    imports: [...FEATURES],
    exports: FEATURES,
})
export class MetricsModule {
}
