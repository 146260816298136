import {
    Component,
    ChangeDetectionStrategy,
    ViewEncapsulation,
    Input,
    Output,
    EventEmitter,
    ViewChild,
} from '@angular/core';

import { get, isEqual } from 'lodash';

import {
    DateModule,
    DateRange,
    DateRangeComponent,
    DateRangeState,
    MaxDatesInRange,
    RangeCalendarCurrentMonthPosition,
} from '../../../date';
import { SelectOption } from '../option.component';
import { OnChange } from '../../../../decorators';
import { IconComponent, RadioComponent } from '../../../../components';
import { MemoizeFuncPipe } from '../../../../pipes';

export type CustomDateSelectOption = { showCalendarIcon: boolean };

@Component({
    selector: 'fi-select-custom-date-option',
    templateUrl: './custom-date.component.html',
    styleUrls: ['./custom-date.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [DateModule, RadioComponent, IconComponent, MemoizeFuncPipe],
})
export class SelectCustomDateOptionComponent {
    @OnChange<boolean>(function (this: SelectCustomDateOptionComponent, value) {
        this.handleIconVisibilityState(value, this.selected);
    })
    @Input()
    checked: boolean;
    @OnChange<DateRange>(function (
        this: SelectCustomDateOptionComponent,
        value,
        change,
    ) {
        if (!isEqual(value, change.previousValue)) {
            this.handleIconVisibilityState(this.checked, value);
        }
    })
    @Input()
    selected: DateRange;
    @Input() availableDateRange: DateRangeState;
    @Input() maxDatesInRange: MaxDatesInRange;
    @Input() option: SelectOption;
    @Input() showCalendarIcon: boolean;
    @Input() currentMonthPosition: RangeCalendarCurrentMonthPosition;
    @Input() isNewDateRangeModal: boolean;
    @Input() historicalOnly: boolean;

    @Output() select = new EventEmitter<DateRange>();
    @Output() reset = new EventEmitter<void>();
    @Output() iconVisibilityChange = new EventEmitter<CustomDateSelectOption>();

    @ViewChild(DateRangeComponent)
    dateRange: DateRangeComponent;

    isCalendarIconVisible([showCalendarIcon, checked, selected]: [
        boolean,
        boolean,
        DateRange,
    ]): boolean {
        return (
            showCalendarIcon || this.optionCheckedAndHasDate(checked, selected)
        );
    }

    selectDateRange(range: DateRange): void {
        this.select.emit(range);
    }

    resetDateRange(): void {
        this.reset.emit();
    }

    openDateRangePicker(): void {
        this.dateRange.openDatePicker();
        this.updateIconVisibilityState();
    }

    getDescription(option: SelectOption): string {
        return get(option, 'description', '');
    }

    getRangeTitle(option: Record<string, string>): string {
        return option?.label ?? '';
    }

    private handleIconVisibilityState(
        checked: boolean,
        selected: DateRange,
    ): void {
        if (this.optionCheckedAndHasDate(checked, selected)) {
            this.updateIconVisibilityState();
        }
    }

    private optionCheckedAndHasDate(
        checked: boolean,
        selected: DateRange,
    ): boolean {
        return checked && !!selected?.from && !!selected?.to;
    }

    private updateIconVisibilityState(): void {
        this.iconVisibilityChange.emit({
            showCalendarIcon: true,
        });
    }
}
