import {
    Component,
    OnInit,
    Input,
    ChangeDetectionStrategy,
    Optional,
    inject,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { filter } from 'rxjs/operators';

import { BaseAnalyticsEvent } from './tag-manager.service';
import { AnalyticsBaseService } from './analytics.service';

@UntilDestroy()
@Component({
    selector: 'fi-analytics-track-view',
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class FiAnalyticsTrackViewComponent implements OnInit {
    private readonly analyticsService = inject(AnalyticsBaseService);
    private readonly router = inject(Router, { optional: true });

    @Input() data: BaseAnalyticsEvent;
    @Input() trackUrlChange: boolean;

    ngOnInit(): void {
        this.trackView({
            ...this.data,
            ...(this.trackUrlChange && { pagePath: this.data.pagePath || this.router.url }),
        });

        if (this.trackUrlChange) {
            this.handleRouteChange();
        }
    }

    private trackView(data: BaseAnalyticsEvent): void {
        this.analyticsService.trackView(data);
    }

    private handleRouteChange(): void {
        this.router.events
            .pipe(
                filter(
                    (event): event is NavigationEnd =>
                        event instanceof NavigationEnd,
                ),
                untilDestroyed(this),
            )
            .subscribe(({ url }) => {
                this.trackView({
                    ...this.data,
                    pagePath: url,
                });
            });
    }
}
