import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IconComponent } from '../../../../components';
import { IsDesktopDirective } from '../../../../directives';

@Component({
    selector: 'fi-grid-expand-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [IconComponent, IsDesktopDirective],
})
export class GridExpandIconComponent {
    @Input() isActive = false;
}
