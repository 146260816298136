import { Environment } from './environment.model';

export const environment: Environment = {
    production: true,
    externalConfig: {
        DEFAULT_COUNTRY: 'us/en',
        PUBLIC_API_VERSION: 'https://qa.fleetinsight.com/penske/ceapipub/v1/',
        RESOURCES_PATH: '/fiportal/login',
        'X-IBM-CLIENT-ID': 'f810be03-ee06-466f-af46-b5c93341e736',
        ANALYTICS_KEY: 'GTM-W5QMBBT',
    },
};
