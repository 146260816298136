import { Injectable } from '@angular/core';

export const DEFAULT_TRUNCATE_LENGTH = 50;

@Injectable({
    providedIn: 'root',
})
export class TruncateService {
    needTruncate(
        field = '',
        truncateLength = DEFAULT_TRUNCATE_LENGTH,
    ): boolean {
        return field.length > truncateLength;
    }
}
