import {
    Directive,
    EventEmitter,
    Input,
    Output,
    OnChanges,
    OnDestroy,
    Optional,
} from '@angular/core';

import { Subject } from 'rxjs';
import { differenceBy, find, get } from 'lodash';

import { GridRow } from '../models';
import { GridDataSourceDirective } from '../data-source/data-source.directive';
import { GridNestedDataSourceDirective } from '../expanded/nested-data-source.directive';

import {
    isAllRowsSelected,
    isSomeRowsSelected,
    selectParentRows,
    getNestedItems,
    getParentRows,
} from './nested-row-selector.helper';

@Directive({
    selector: '[fiGridNestedRowSelector]',
    standalone: true,
})
export class GridNestedRowSelectorDirective implements OnChanges, OnDestroy {
    readonly stateChanged = new Subject<void>();

    @Input('fiGridNestedRowSelector') selectedRows: GridRow[] = [];
    @Input('fiGridDisableParentSelection') disableParentSelection = false;
    @Input('fiGridNestedRowSelectorIsSearchApplied') isSearchApplied = false;
    @Output() readonly fiGridRowSelectorChange = new EventEmitter<GridRow[]>();

    isAllRowSelected = false;
    isSomeRowSelected = false;

    stateChanged$ = this.stateChanged.asObservable();

    constructor(
        @Optional() private gridSource: GridDataSourceDirective,
        @Optional() private nestedData: GridNestedDataSourceDirective,
    ) {}

    ngOnChanges(): void {
        this.checkIsAllRowsSelected();
        this.stateChanged.next();
    }

    ngOnDestroy(): void {
        this.stateChanged.complete();
    }

    selectRow(row: GridRow, isSelected: boolean): void {
        if (isSelected) {
            const selectedRows = this.unselectNestedRows(row);
            this.selectedRows = [...selectedRows];
            this.fiGridRowSelectorChange.emit(selectedRows);
            return;
        }

        const selectedRows = this.selectNestedAndParentRows(row);
        this.fiGridRowSelectorChange.emit(selectedRows);
    }

    toggleChildren(isRowSelected: boolean, children: GridRow[]): void {
        if (isRowSelected) {
            const childrenToSelect = differenceBy(
                children,
                this.selectedRows,
                'id',
            );

            if (childrenToSelect.length) {
                const rows = [...this.selectedRows, ...childrenToSelect];
                this.fiGridRowSelectorChange.emit(rows);
            }
        }
    }

    toggleSelectRows(): void {
        if (this.isAllRowSelected) {
            this.fiGridRowSelectorChange.emit([]);
            return;
        }

        const allRows = this.getAllRows();
        this.fiGridRowSelectorChange.emit(allRows);
    }

    isRowSelected(row: GridRow): boolean {
        if (!this.selectedRows.length || !row) {
            return false;
        }

        return !!find(
            this.selectedRows,
            (selectedRow: GridRow) => selectedRow.id === row.id,
        );
    }

    isSomeNestedRowSelected(row: GridRow): boolean {
        if (!row) {
            return false;
        }

        const children = this.getNestedRows(row);
        if (!children) {
            return false;
        }

        const isSomeSelected = isSomeRowsSelected(children, this.selectedRows);
        if (!isSomeSelected) {
            return false;
        }

        const isAllSelected = isAllRowsSelected(children, this.selectedRows);

        return this.disableParentSelection
            ? isSomeSelected
            : isSomeSelected && !isAllSelected;
    }

    private get rows(): GridRow[] {
        const { source: { data = [] } = {} } = this.gridSource || {};

        return data;
    }

    private get nestedRows(): GridRow {
        if (this.isSearchApplied) {
            return {};
        }

        const { source: { values = {} } = {} } = this.nestedData || {};

        return values;
    }

    private getNestedRows(row: GridRow): GridRow[] {
        const nestedRows = this.nestedRows;
        let nestedSelectedRows: GridRow[] = [];

        if (row.hasChildren) {
            const nestedData = nestedRows[row.id];
            if (nestedData) {
                nestedSelectedRows = [...nestedData.data];
                nestedData.data.forEach((item: GridRow) => {
                    nestedSelectedRows = [
                        ...nestedSelectedRows,
                        ...this.getNestedRows(item),
                    ];
                });
            }
        }

        return nestedSelectedRows;
    }

    private unselectNestedRows(row: GridRow): GridRow[] {
        const allRows = this.getAllRows();
        const nestedRows = this.getNestedRows(row);
        const parentRows = this.isSearchApplied
            ? []
            : getParentRows(row, allRows, this.selectedRows);
        const rows = [...nestedRows, ...parentRows, row];

        return this.selectedRows.filter(({ id }) => !find(rows, ['id', id]));
    }

    private getRowsLength(): number {
        const firstLevelLength: number = get(this, 'rows.length', 0);
        const nestedRows: GridRow[] = getNestedItems(this.nestedRows);
        const nestedRowsLength: number = get(nestedRows, 'length', 0);

        return firstLevelLength + nestedRowsLength;
    }

    private getAllRows(): GridRow[] {
        const nestedRows = getNestedItems(this.nestedRows);

        return [...this.rows, ...nestedRows];
    }

    private selectNestedAndParentRows(row: GridRow): GridRow[] {
        const nestedSelectedRows = this.getNestedRows(row);
        const allRows = this.getAllRows();
        const selected = [...this.selectedRows, row, ...nestedSelectedRows];
        const parentRows = this.isSearchApplied
            ? []
            : selectParentRows(row, selected, allRows);

        return this.disableParentSelection
            ? [...selected]
            : [...selected, ...parentRows];
    }

    private checkIsAllRowsSelected(): void {
        this.isSomeRowSelected = !!this.selectedRows.length;

        if (
            !this.isSomeRowSelected ||
            this.selectedRows.length !== this.getRowsLength()
        ) {
            this.isAllRowSelected = false;
            return;
        }

        this.isAllRowSelected = true;
    }
}
