const FIELD_PASSWORD_VISIBLE_CLASS = 'landing-form__field--password-visible';
const INPUT_ERROR_CLASS = 'landing-form__field--error';
const FORM_ERROR_CLASS = 'landing-form__server-error--visible';

const INACTIVE_USER_TYPE = 167772161;

const USERNAME_STORAGE_KEY = 'username';

export class LoginController {
    private loginFormElement = document.querySelector('#login-form');

    private wrongCredentialsErrorElement =
        document.querySelector('#wrong-credentials');
    private inactiveUserErrorElement = document.querySelector('#inactive-user');

    private usernameInputElement = document.querySelector(
        '#username-input',
    ) as HTMLInputElement;

    private passwordFieldElement = document.querySelector('#password-field');
    private passwordInputElement = document.querySelector('#password-input');
    private passwordToggleElement = document.querySelector('#password-toggle');
    private passwordHidden = false;

    private rememberMeCheckboxElement = document.querySelector(
        '#remember-me',
    ) as HTMLInputElement;

    private loginFormInputs = [
        this.usernameInputElement,
        this.passwordInputElement,
    ];

    constructor() {
        this.attachEvents();

        this.checkSavedUsername();

        this.checkCredentialsError();
        this.checkInactiveUserError();
    }

    private attachEvents(): void {
        this.loginFormElement.addEventListener('submit', (event) => {
            this.validateForm(event);
        });

        this.usernameInputElement.addEventListener('input', (event) => {
            this.hideInputError(event.target as HTMLInputElement);
        });
        this.passwordInputElement.addEventListener('input', (event) => {
            this.hideInputError(event.target as HTMLInputElement);
        });

        this.passwordToggleElement.addEventListener('click', () => {
            this.togglePasswordVisibility();
        });
    }

    private checkSavedUsername(): void {
        const savedUsername = window.localStorage.getItem(USERNAME_STORAGE_KEY);

        if (savedUsername) {
            this.usernameInputElement.value = savedUsername;
        }
    }

    private checkCredentialsError(): void {
        const allCookies = document.cookie;
        const wrongCredentials = allCookies.indexOf('SMTRYNO=');

        if (wrongCredentials !== -1) {
            this.wrongCredentialsErrorElement.classList.add(FORM_ERROR_CLASS);
        }
    }

    private checkInactiveUserError(): void {
        const type = this.getQueryVariable('type');

        if (type === INACTIVE_USER_TYPE.toString()) {
            this.inactiveUserErrorElement.classList.add(FORM_ERROR_CLASS);
        }
    }

    private togglePasswordVisibility(): void {
        const type = this.passwordHidden ? 'password' : 'text';
        const classListFn = this.passwordHidden ? 'remove' : 'add';
        const inputAriaLabel = this.passwordHidden
            ? 'Password Hidden'
            : 'Password Visible';
        const toggleAriaLabel = inputAriaLabel + ' Toggle';

        this.passwordInputElement.setAttribute('type', type);
        this.passwordInputElement.setAttribute('aria-label', inputAriaLabel);
        this.passwordToggleElement.setAttribute('aria-label', toggleAriaLabel);
        this.passwordFieldElement.classList[classListFn](
            FIELD_PASSWORD_VISIBLE_CLASS,
        );

        this.passwordHidden = !this.passwordHidden;
    }

    private validateForm(event: Event): void {
        const invalid = this.loginFormInputs.some(this.isInputEmpty);

        this.handleInputsEmptyState();

        if (invalid) {
            event.preventDefault();
            return;
        }

        if (this.rememberMeCheckboxElement.checked) {
            this.saveUsernameToStorage();
        } else {
            this.removeUsernameFromStorage();
        }
    }

    private saveUsernameToStorage(): void {
        window.localStorage.setItem(
            USERNAME_STORAGE_KEY,
            this.usernameInputElement.value,
        );
    }

    private removeUsernameFromStorage(): void {
        window.localStorage.removeItem(USERNAME_STORAGE_KEY);
    }

    private handleInputsEmptyState(): void {
        this.loginFormInputs.forEach((element: HTMLInputElement) => {
            if (this.isInputEmpty(element)) {
                this.showInputError(element);
            } else {
                this.hideInputError(element);
            }
        });
    }

    private isInputEmpty(element: HTMLInputElement): boolean {
        return element.value.trim() === '';
    }

    private showInputError(element: HTMLInputElement): void {
        element.parentElement.classList.add(INPUT_ERROR_CLASS);
    }

    private hideInputError(element: HTMLInputElement): void {
        element.parentElement.classList.remove(INPUT_ERROR_CLASS);
    }

    private getQueryVariable(variable: string): string | undefined {
        const query = window.location.search.substring(1);
        const vars = query.split('&');

        let result;

        vars.forEach((value) => {
            const pair = value.split('=');

            if (pair[0].toLowerCase() === variable.toLowerCase()) {
                result = pair[1];
            }
        });

        return result;
    }
}
