import { NgControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { startWith, map, tap } from 'rxjs/operators';

export interface InputFormatParsedValue {
    caretPosition?: number;
    originalValue?: string;
    transformedValue?: string;
    isValueDeleted?: boolean;
}

export abstract class InputFormatBase {
    abstract valueChanges$: Observable<string>;

    constructor(protected ngControl: NgControl) {}

    protected abstract getParsedValue(values: string[]): InputFormatParsedValue;

    protected abstract returnCaretToOriginalPosition(
        parsedValue: InputFormatParsedValue,
    ): void;

    protected getInputEvent(initialValue: string) {
        let previousValue = initialValue;

        return this.ngControl.valueChanges.pipe(
            startWith(initialValue),
            map((currentValue) => [previousValue, currentValue]),
            map((values) => this.getParsedValue(values)),
            tap(({ transformedValue }) =>
                this.setControlValue(transformedValue),
            ),
            tap((parsedValue) =>
                this.returnCaretToOriginalPosition(parsedValue),
            ),
            tap(({ transformedValue }) => (previousValue = transformedValue)),
            map(({ transformedValue }) => transformedValue),
        );
    }

    protected setControlValue(value: string): void {
        this.ngControl.control.setValue(value, {
            emitEvent: false,
        });
    }
}
