import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'fi-no-chart-data',
    templateUrl: './no-chart-data.component.html',
    styleUrls: ['./no-chart-data.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class NoChartDataComponent {
    @Input() text: string = 'There is no data to display.';
}
