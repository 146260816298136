import {
    Directive,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Subject } from 'rxjs';

import { GridNestedDataState, GridNestedItems } from '../store/models';
import { GridRow } from '../models';

@UntilDestroy()
@Directive({
    selector: '[fiGridNestedData]',
    exportAs: 'fiGridNestedData',
    standalone: true,
})
export class GridNestedDataSourceDirective implements OnDestroy, OnChanges {
    readonly stateChanged = new Subject<void>();

    @Input('fiGridNestedData') readonly source: GridNestedItems;
    @Input('fiGridNestedDataKey') dataKey = '';
    @Input('fiGridNestedDataOpen') openByDefault: boolean;

    @Output()
    readonly fiGridNestedDataChange = new EventEmitter<GridNestedDataState>();
    @Output()
    readonly fiGridNestedDataClose = new EventEmitter<string | number>();

    stateChanged$ = this.stateChanged.asObservable().pipe(untilDestroyed(this));

    ngOnChanges(): void {
        this.stateChanged.next();
    }

    ngOnDestroy(): void {
        this.stateChanged.complete();
    }

    closeExpander(itemPointer: string | number): void {
        this.fiGridNestedDataClose.emit(itemPointer);
    }

    setPage(
        pageNumber: number,
        itemPointer: string | number,
        row?: GridRow,
    ): void {
        const paging = { pageNumber };

        this.fiGridNestedDataChange.emit({
            itemPointer,
            paging,
            row,
        });
    }
}
