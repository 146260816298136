import { Component, HostListener, OnInit } from '@angular/core';

import { BodyUtilService } from '@pnsk/ui-common/services';
import { KeyCode } from '@pnsk/ui-common/constants/key-codes';

const enum ModalName {
    RequestAccess = 'request-access',
}

@Component({
    selector: 'app-login',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    readonly analyticsData = {
        pageSection: 'Login',
        contentName: 'Login',
        pagePath: '/',
    };

    isRequestAccessModalOpen = false;

    constructor(private bodyUtilService: BodyUtilService) {}

    @HostListener('window:click', ['$event.target'])
    handleModalComponentOpen(targetElement: HTMLElement): void {
        if (targetElement.dataset.openModal === ModalName.RequestAccess) {
            this.isRequestAccessModalOpen = true;
        }
    }

    @HostListener('document:keydown', ['$event'])
    setFocusVisibility(event: KeyboardEvent): void {
        const keyboardNavigationKeys = [
            KeyCode.Tab,
            KeyCode.ArrowLeft,
            KeyCode.ArrowRight,
            KeyCode.ArrowUp,
            KeyCode.ArrowDown,
        ];
        if (keyboardNavigationKeys.includes(event.code as KeyCode)) {
            this.bodyUtilService.updateBodyClasses('', 'focus-visible');
        }
    }

    @HostListener('document:mousedown')
    removeFocusVisibility(): void {
        if (this.bodyUtilService.bodyClasses.includes('focus-visible')) {
            this.bodyUtilService.updateBodyClasses('focus-visible', null);
        }
    }

    ngOnInit(): void {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }

    closeRequestAccessModal(): void {
        this.isRequestAccessModalOpen = false;
    }
}
