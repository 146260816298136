import { DOCUMENT } from '@angular/common';
import { ElementRef, Inject, Injectable } from '@angular/core';

import { fromEvent, merge, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

export interface FocusWithinEvent {
    element: HTMLElement | null;
    isClicked: boolean;
}

@Injectable()
export class FocusWithinService extends Observable<FocusWithinEvent> {
    constructor(
        @Inject(DOCUMENT) documentRef: Document,
        { nativeElement }: ElementRef<HTMLElement>,
    ) {
        let isClicked;

        fromEvent(documentRef, 'click').pipe(
            debounceTime(0),
            distinctUntilChanged(),
        ).subscribe(() => (isClicked = true));

        fromEvent(documentRef, 'keydown').pipe(
            debounceTime(0),
            distinctUntilChanged(),
        ).subscribe(() => (isClicked = false));

        const focusedElement$ = merge(
            fromEvent(documentRef, 'focusin'),
            fromEvent(documentRef, 'focusout'),
            of(null),
        ).pipe(
            debounceTime(100), // need to pass non-zero value so focus event occurs later than click/keydown
            map(() => {
                return nativeElement.contains(documentRef.activeElement)
                    ? { element: documentRef.activeElement, isClicked }
                    : { element: null, isClicked };
            }),
            distinctUntilChanged(),
        );

        super((subscriber) => focusedElement$.subscribe(subscriber));
    }
}
