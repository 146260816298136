const isSmoothScrollSupported =
    'scrollBehavior' in document.documentElement.style;

export class ActionsController {
    private topBtn = document.getElementById('landing-page-top-btn');

    constructor() {
        this.handleScrollEvents();
        this.handleTopBtnActions();
    }

    private handleTopBtnActions(): void {
        // in IOS doesn't work when click at the bottom of the page (mobile view)
        this.topBtn.addEventListener('click', () => {
            this.topBtn.style.opacity = '0';
            this.smoothScroll(0);
        });
    }

    private smoothScroll(scrollTo: number): void {
        const scrollToY = scrollTo || 0;
        const scrollStep = 10;

        if (isSmoothScrollSupported) {
            window.scrollTo({
                top: scrollTo,
                left: 0,
                behavior: 'smooth',
            });
        } else if (scrollToY < scrollStep) {
            const scrollToTop = () => {
                const scrollTop =
                    document.documentElement.scrollTop ||
                    document.body.scrollTop;
                if (scrollTop > 0) {
                    window.requestAnimationFrame(scrollToTop);
                    window.scrollTo(0, scrollTop - scrollTop / scrollStep);
                }
            };
            scrollToTop();
        }
    }

    private handleScrollEvents(): void {
        let lastScroll = 0;
        let isScrollUp = false;

        window.addEventListener('scroll', () => {
            const currentScroll = this.getCurrentScrollTop();

            isScrollUp = currentScroll > 0 && lastScroll >= currentScroll;
            lastScroll = currentScroll;

            this.topBtn.style.opacity = isScrollUp ? '1' : '0';
        });
    }

    private getCurrentScrollTop(): number {
        const { pageYOffset } = window;
        const { documentElement, body } = document;

        return pageYOffset || (documentElement || body).scrollTop;
    }
}
