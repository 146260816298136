import { GlobalPositionStrategy, OverlayConfig } from '@angular/cdk/overlay';

import { flatten } from 'lodash';

import { variationClassBuilder } from '../utils';

import { BaseOverlay } from './base-overlay';
import { OverlayParams } from './params';
import { BASE_OVERLAY_CLASS } from './css-classes';
import { OverlayGlobalPosition, OverlayGlobalPositionOffset } from './position';

export class GlobalOverlay<T> extends BaseOverlay<T, OverlayParams, null> {
    protected resolveConfig(): OverlayConfig {
        const {
            position,
            positionOffset,
            scrollStrategy,
            variation = [],
            disposeOnNavigation = true,
            ...other
        } = this.params;

        const config: OverlayConfig = {
            ...other,
            disposeOnNavigation,
            panelClass: [
                BASE_OVERLAY_CLASS,
                ...variationClassBuilder(BASE_OVERLAY_CLASS, [
                    ...flatten([variation]),
                    'global-position',
                    `global-position-${position || 'center'}`,
                ]),
            ],
            positionStrategy: this.getPosition(position, positionOffset),
            scrollStrategy: this.getScrollStrategy(scrollStrategy),
        };

        return new OverlayConfig({
            ...config,
        });
    }

    private getPosition(
        position: OverlayGlobalPosition,
        positionOffset: OverlayGlobalPositionOffset = {},
    ): GlobalPositionStrategy {
        const globalPosition = this.overlayBuilder.position().global();
        const {
            top: topOffset,
            right: rightOffset,
            bottom: bottomOffset,
            left: leftOffset,
        } = positionOffset;

        switch (position) {
            case OverlayGlobalPosition.Bottom: {
                return globalPosition.centerHorizontally().bottom(bottomOffset);
            }

            case OverlayGlobalPosition.Top: {
                return globalPosition.centerHorizontally().top(topOffset);
            }

            case OverlayGlobalPosition.TopRight: {
                return globalPosition
                    .centerHorizontally()
                    .top(topOffset)
                    .right(rightOffset);
            }

            case OverlayGlobalPosition.Right: {
                return globalPosition.centerVertically().right(rightOffset);
            }

            case OverlayGlobalPosition.Left: {
                return globalPosition.centerVertically().left(leftOffset);
            }

            default: {
                return globalPosition.centerHorizontally().centerVertically();
            }
        }
    }
}
