import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
    inject,
} from '@angular/core';
import { DOCUMENT, NgClass, NgTemplateOutlet } from '@angular/common';
import { RouterLink } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { fromEvent } from 'rxjs';
import { finalize, first, tap } from 'rxjs/operators';

import { OnChange } from '../../decorators';
import { IconComponent } from '../../components';
import {
    FileDropDirective,
    IsDesktopDirective,
    IsMobileDirective,
} from '../../directives';
import {
    MemoizeFuncPipe,
    PluralizeStringPipe,
    PluralizePipe,
} from '../../pipes';
import { MIME_TYPES } from '../../services';

import {
    DEFAULT_STEP,
    DEFAULT_UPLOAD_FILE_OPTIONS,
    LARGE_FILES_UPLOAD_STATE_DESCRIPTION,
    UPLOAD_STATE_DESCRIPTION,
} from './constants';
import { DragNDropAreaService } from './drag-n-drop-area.service';
import {
    FileAreaStatesInfo,
    FileDropOptions,
    FileFormats,
    FileTypes,
    FileValidationErrors,
    UploadAreaType,
    UploadType,
} from './models';
import { UploadStepComponent } from './upload-step/upload-step.component';
import { DragNDropUploadErrorComponent } from './drag-n-drop-upload-error/drag-n-drop-upload-error.component';

export const enum StateVariation {
    success = 'UPLOAD_SUCCESS',
    uploading = 'UPLOADING',
    validating = 'VALIDATING',
    error = 'ERROR',
}

@UntilDestroy()
@Component({
    selector: 'drag-n-drop-area',
    templateUrl: './drag-n-drop-area.component.html',
    styleUrls: ['./drag-n-drop-area.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        RouterLink,
        DragNDropUploadErrorComponent,
        UploadStepComponent,
        IconComponent,
        FileDropDirective,
        IsDesktopDirective,
        IsMobileDirective,
        MemoizeFuncPipe,
        PluralizeStringPipe,
        PluralizePipe,
    ],
})
export class DragNDropAreaComponent {
    private document = inject<Document>(DOCUMENT);
    private importItemsService = inject(DragNDropAreaService);
    private cd = inject(ChangeDetectorRef);

    @Input() errorListFileName: string;
    @Input() hideUploadMore: boolean;
    @Input() stageGroupsUpload: boolean;
    @Input() uploadFileAreaStatesInfo: FileAreaStatesInfo;
    @Input() disabled = false;

    @OnChange('handleUploadFileModal')
    @Input()
    closeFileTypeModal: boolean;
    @OnChange('setStepNumber')
    @Input()
    step: number;
    @OnChange('convertFileSizeToMB')
    @Input()
    fileSize: number;
    @OnChange('setFileDropOptions')
    @Input()
    filesQuantity: number;
    @OnChange('setSettings')
    @Input()
    uploadType: UploadAreaType;

    @Output() fileUploaded = new EventEmitter<UploadType>();
    @Output() openFileTypeModal = new EventEmitter<boolean>();

    @ViewChild('successOnAddUsersTpl', { static: true })
    successOnAddUsersTpl: ElementRef<HTMLElement>;
    @ViewChild('successOnAddLocationsTpl', { static: true })
    successOnAddLocationsTpl: ElementRef<HTMLElement>;
    @ViewChild('successOnImportFleetDataTpl', { static: true })
    successOnImportFleetDataTpl: ElementRef<HTMLElement>;
    @ViewChild('successOnAddGroupsTpl', { static: true })
    successOnAddGroupsTpl: ElementRef<HTMLElement>;

    readonly stateSuccess = StateVariation.success;
    readonly stateUploading = StateVariation.uploading;
    readonly stateValidating = StateVariation.validating;
    readonly stateError = StateVariation.error;
    readonly uploadAreaType = UploadAreaType;
    readonly selectFileText = 'Select a File';

    dropZoneActive: boolean;
    uploadError: FileValidationErrors[] = [];
    fileToUpload: File;
    errorFile: string;
    successEmail: string;
    state: StateVariation;
    uploadedItems: number;
    stepNumber: number;
    fileTypeName: FileFormats;
    fileSizeInMB: number;
    fileUploadType: UploadType;
    fileDropOptions: FileDropOptions;
    droppedList: FileList;

    dropZoneState(isDropZoneHovered: boolean): void {
        if (this.disabled) {
            return;
        }

        this.dropZoneActive = isDropZoneHovered;
    }

    handleFileDropped(droppedList: FileList): void {
        if (this.disabled) {
            return;
        }

        this.fileUploadType = UploadType.DragNDrop;

        if (this.uploadType === UploadAreaType.FleetData) {
            this.openFileTypeModal.next(true);
            this.droppedList = droppedList;

            return;
        }

        this.fileToUpload = droppedList[0];
        this.state = StateVariation.uploading;

        this.uploadFile(this.fileUploadType);
    }

    handleFileDropErrors(errors: string[]): void {
        if (this.disabled) {
            return;
        }

        this.uploadError = errors as FileValidationErrors[];
        this.state = StateVariation.error;
        this.cd.detectChanges();
    }

    handleDownloadFile(): void {
        let fileType = MIME_TYPES.xlsx;

        switch (this.uploadType) {
            case UploadAreaType.Groups:
                fileType = MIME_TYPES.xlsm;
                break;
            case UploadAreaType.FleetData:
                fileType = MIME_TYPES.csv;
                break;
            default:
                fileType = MIME_TYPES.xlsx;
                break;
        }

        this.importItemsService.downloadDecodedFile(
            this.errorFile,
            this.errorListFileName,
            fileType,
        );
    }

    handleNewUpload(): void {
        this.uploadError = [];
        this.fileToUpload = null;
        this.errorFile = null;
        this.state = null;
        this.successEmail = null;
        this.uploadedItems = null;
        this.dropZoneActive = false;
    }

    handleResendFile(): void {
        this.state = StateVariation.uploading;
        this.uploadError = [];

        this.uploadFile(this.fileUploadType);
    }

    chooseAndUploadFile(): void {
        this.handleNewUpload();

        let fileInput = this.document.createElement('input');
        fileInput.type = 'file';

        fromEvent(fileInput, 'change')
            .pipe(
                first(),
                tap((event) => {
                    const target = event.target as HTMLInputElement;
                    this.fileToUpload = target.files[0];
                    this.uploadError = this.handleFileValidation(
                        target.files,
                    ) as FileValidationErrors[];
                    this.state = StateVariation.validating;
                }),
                finalize(() => (fileInput = null)),
                untilDestroyed(this),
            )
            .subscribe(() => {
                this.fileUploadType = UploadType.SelectFile;

                if (
                    this.uploadType === UploadAreaType.FleetData &&
                    !this.uploadError.length
                ) {
                    this.openFileTypeModal.next(true);
                    this.state = null;

                    return;
                }

                this.uploadError.length
                    ? (this.state = StateVariation.error)
                    : this.uploadFile(this.fileUploadType);
                this.cd.detectChanges();
            });

        fileInput.click();
    }

    setSettings(): void {
        this.setStepNumber();
        this.setFileType();
        this.convertFileSizeToMB();
        this.setFileDropOptions();
    }

    getSuccessTpl(uploadType: UploadAreaType): ElementRef<HTMLElement> {
        switch (uploadType) {
            case UploadAreaType.Users:
                return this.successOnAddUsersTpl;
            case UploadAreaType.Locations:
                return this.successOnAddLocationsTpl;
            case UploadAreaType.FleetData:
                return this.successOnImportFleetDataTpl;
            default:
                return this.successOnAddGroupsTpl;
        }
    }

    getSvgName(disabled: boolean): string {
        if (disabled) {
            return 'drag-and-drop-no-background';
        }

        return 'drag-and-drop';
    }

    getStepVariation([disabled, dropZoneActive]: [boolean, boolean]): string {
        if (disabled) {
            return 'disabled';
        }

        if (dropZoneActive) {
            return 'dragover';
        }

        return;
    }

    getUploadStateDescription(uploadType: UploadAreaType): string {
        if (uploadType === UploadAreaType.FleetData) {
            return LARGE_FILES_UPLOAD_STATE_DESCRIPTION;
        }

        return UPLOAD_STATE_DESCRIPTION;
    }

    private handleUploadFileModal(): void {
        if (!this.closeFileTypeModal) {
            return;
        }

        switch (this.fileUploadType) {
            case UploadType.DragNDrop:
                this.fileToUpload = this.droppedList[0];
                this.state = StateVariation.uploading;
                this.uploadFile(this.fileUploadType);
                break;
            case UploadType.SelectFile:
                this.state = StateVariation.uploading;
                this.uploadFile(this.fileUploadType);
                this.cd.detectChanges();
                break;
            default:
                break;
        }
    }

    private setStepNumber(): void {
        this.stepNumber = this.step || DEFAULT_STEP;
    }

    private setFileType(): void {
        switch (this.uploadType) {
            case UploadAreaType.Groups:
                this.fileTypeName = '.xlsm';
                break;
            case UploadAreaType.FleetData:
                this.fileTypeName = '.csv';
                break;
            default:
                this.fileTypeName = '.xlsx';
                break;
        }
    }

    private convertFileSizeToMB(): void {
        const fileSizeInBytes =
            this.fileSize || DEFAULT_UPLOAD_FILE_OPTIONS.fileSize;

        this.fileSizeInMB = fileSizeInBytes / (1024 * 1024);
    }

    private setFileDropOptions(): void {
        switch (this.uploadType) {
            case UploadAreaType.Groups:
                this.fileDropOptions = {
                    ...DEFAULT_UPLOAD_FILE_OPTIONS,
                    fileType: FileTypes.fileExcelWithMacro,
                };
                break;
            case UploadAreaType.FleetData:
                this.fileDropOptions = {
                    ...DEFAULT_UPLOAD_FILE_OPTIONS,
                    fileSize: this.fileSize,
                    fileType: FileTypes.fileCsv,
                    filesQuantity: this.filesQuantity,
                };
                break;
            default:
                this.fileDropOptions = DEFAULT_UPLOAD_FILE_OPTIONS;
                break;
        }
    }

    private uploadFile(uploadType: UploadType): void {
        this.state = StateVariation.uploading;

        this.importItemsService
            .uploadFile(this.fileToUpload, this.hideUploadMore)
            .pipe(untilDestroyed(this))
            .subscribe((resp: any) => {
                if (resp.status === 200 || resp.status === 204) {
                    this.state = StateVariation.success;
                    this.successEmail = !!resp.body && resp.body.createdBy;
                    this.uploadedItems = !!resp.body && resp.body.uploadedItems;
                    this.fileUploaded.next(uploadType);
                }

                if (resp.errorStatus) {
                    this.uploadError.push(resp.errorStatus);
                    this.state = StateVariation.error;
                    this.errorFile = resp.errorFile;
                }

                this.cd.detectChanges();
            });
    }

    private handleFileValidation(fileList: FileList): string[] {
        return this.importItemsService.errorListDefine(
            fileList,
            this.fileDropOptions,
        );
    }
}
