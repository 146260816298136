<div
    #container
    [class.fi-select-menu--disabled]="disabled"
    [class.fi-select-menu--invalid]="invalid"
    [class.fi-select-menu--open]="isMenuOpen"
    [tabIndex]="0"
    (keydown)="handleKeydown($event)"
    (click)="toggleMenu()"
    class="fi-select-menu"
>
    <div class="fi-select-menu__label">
        <span
            [class.fi-select-menu__label-value--empty]="!value"
            [fiVariation]="variation"
            [fiVariationBase]="'fi-text'"
            class="fi-select-menu__label-value"
        >
            {{ [selectedText, placeholder] | memoizeFunc: getLabel }}
        </span>

        <fi-icon
            [svgName]="'caret-down'"
            [variation]="'solid'"
            [size]="'medium'"
            class="fi-select-menu__icon"
        ></fi-icon>
    </div>

    <ng-template #menu>
        <div
            [class.fi-select-menu__list-container--invalid]="invalid"
            class="fi-select-menu__list-container"
        >
            <div class="fi-select-menu__list">
                <ng-content></ng-content>
            </div>
        </div>
    </ng-template>
</div>
