import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
    selector: '[fiGridGroupDef]',
    standalone: true,
})
export class GridGroupDefDirective {
    @Input() groupBy = '';

    constructor(public tpl: TemplateRef<any>) {}
}
