@if (isDesktopView$ | async) {
    <div #mainPanel class="fi-grid-main-panel fi-grid-main-panel--desktop">
        <div #subFilter class="fi-grid-main-panel__sub-filter-panel--desktop">
            @if (showSubFilterPanel) {
                <ng-container *ngTemplateOutlet="subFilterPanel"></ng-container>
            } @else {
                <fi-skeleton [isInitializing]="isInitializing">
                    <ng-container *ngTemplateOutlet="subFilterPanel"></ng-container>
                </fi-skeleton>
            }
        </div>

        <div
            #secondaryControlWrapper
            class="fi-grid-main-panel__hide-empty-control"
        >
            <ng-container *ngTemplateOutlet="secondaryControl"></ng-container>
        </div>

        @if (!hasFilterOpenerInMoreAction) {
            <ng-container *ngTemplateOutlet="filterOpenerTpl"></ng-container>
        }
        <div #simpleFiltering class="fi-grid-main-panel__hide-empty-control">
            <ng-content select="fi-simple-filtering"></ng-content>
        </div>

        @if (isPanelNotOverflowed) {
            @if (hasFilterOpenerInMoreAction) {
                <ng-container
                    *ngTemplateOutlet="filterOpenerTpl"
                ></ng-container>
            }

            <div
                *fiIsDesktopDevice
                class="fi-grid-main-panel__hide-empty-control"
            >
                <ng-content select="fi-grid-download-report"></ng-content>
            </div>

            <div
                *fiIsDesktopDevice
                class="fi-grid-main-panel__hide-empty-control"
            >
                <ng-content select="fi-grid-manage-columns"></ng-content>
            </div>
        } @else {
            <ng-content select="fi-grid-more-actions"></ng-content>
        }
    </div>

    @if (!hideFiltersPanel) {
        <fi-filtering-panel
            #fiFilteringPanel="fiFilteringPanel"
        ></fi-filtering-panel>
    }

    @if (!filtering?.isFilterShow || hideFiltersPanel) {
        <div class="fi-grid-main-panel__applied-filters-wrapper">
            <ng-content select="fi-filtering-applied-items"></ng-content>
        </div>
    }
    <!--    TODO Is this block needed?   -->
    <div class="fi-grid-main-panel__sub-filter-panel--desktop">
        <ng-container [ngTemplateOutlet]="subFilterPanel"></ng-container>
    </div>
}

@if (isMobileView$ | async) {
    <div class="fi-grid-main-panel fi-grid-main-panel--mobile">
        <div class="fi-grid-main-panel__sort-picker">
            <fi-grid-sort-picker></fi-grid-sort-picker>
        </div>

        <div class="fi-grid-main-panel__filter-picker">
            <ng-content select="fi-filtering-picker"></ng-content>
        </div>

        <div
            class="fi-grid-main-panel__slot-separator slot slot__inner--separator"
        ></div>
    </div>

    <div class="fi-grid-main-panel__hide-empty-control">
        <ng-container *ngTemplateOutlet="secondaryControl"></ng-container>
    </div>

    <div class="fi-grid-main-panel__sub-filter-panel--mobile">
        <ng-container *ngTemplateOutlet="subFilterPanel"></ng-container>
    </div>
}

<ng-content select="fi-grid-additional-panel"></ng-content>

<ng-template #subFilterPanel>
    <ng-content select="fi-grid-sub-filter-panel"></ng-content>
</ng-template>

<ng-template #secondaryControl>
    <ng-content select="fi-grid-secondary-control"></ng-content>
</ng-template>

<ng-template #filterOpenerTpl>
    <ng-content select="fi-filtering-opener"></ng-content>
</ng-template>
