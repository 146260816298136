import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    Self,
} from '@angular/core';

import { sortBy, isEqual, some, take } from 'lodash';

import { OnChange } from '../../../decorators';
import { SearchAutocompleteOption, SearchModule } from '../../search';
import { GridSearch, TableSearchKey } from '../store';
import { GridSearchConfiguration } from '../models';
import { GridSearchPhraseService } from '../services';

@Component({
    selector: 'fi-grid-search',
    templateUrl: './search.component.html',
    providers: [GridSearchPhraseService],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [SearchModule],
})
export class GridSearchComponent {
    @OnChange(function (this: GridSearchComponent, search: GridSearch) {
        if (isEqual(search.searchPhrase, this.searchState.searchPhrase)) {
            this.handleSearchOptions(search.searchPhrase);
            return;
        }

        this.handleSearchChange(search.searchPhrase);
    })
    @Input()
    search: GridSearch;
    @Input() config: GridSearchConfiguration;
    @Input() skipAutofocus: boolean;

    @Input() variation = '';

    @Output() searchChange = new EventEmitter<GridSearch>();
    @Output() clearSearch = new EventEmitter<void>();

    searchState: GridSearch = { searchPhrase: '' };
    searchSuggestions: SearchAutocompleteOption[] = [];
    searchOptions: SearchAutocompleteOption[] = [];

    private searchSuggestionsMaxNumber = 5;

    constructor(@Self() private searchPhraseService: GridSearchPhraseService) {}

    handleSearchClear(): void {
        this.clearSearch.emit();
    }

    handleSearchChange(searchPhrase: string) {
        if (!searchPhrase) {
            this.handleSearch('');
        }

        this.handleSearchOptions(searchPhrase);
    }

    handleSearchOptions(searchPhrase: string) {
        this.searchState = {
            searchPhrase,
        };

        const { storageKey } = this.config;
        const searchSuggestions = this.searchPhraseService.getRecentSearches(
            searchPhrase,
            storageKey,
        );

        this.searchOptions = this.getSearchOptions(
            this.config,
            searchPhrase,
            TableSearchKey.Suggestions,
        );
        this.searchSuggestions = take(
            this.filterSearchSuggestions(this.config, searchSuggestions),
            this.searchSuggestionsMaxNumber,
        );
    }

    handleSearch(
        searchPhrase: string,
        searchPrefix?: string,
        key?: TableSearchKey,
    ) {
        if (!searchPrefix) {
            searchPrefix = this.searchPhraseService.getIdentifiedSearchPrefix(
                this.config,
                searchPhrase,
            );
        }

        this.searchPhraseService.setRecentSearches(
            this.config,
            searchPhrase,
            searchPrefix,
        );

        this.searchChange.emit({
            searchPhrase,
            searchPrefix,
            key,
        });
    }

    handleSelectSearchOption(
        searchPhrase: string,
        {
            prefix,
            key = TableSearchKey.RecentHistory,
        }: SearchAutocompleteOption,
    ) {
        this.handleSearch(searchPhrase, prefix, key as TableSearchKey);
    }

    private getSearchOptions(
        { prefixes }: GridSearchConfiguration,
        query?: string,
        key?: TableSearchKey,
    ): SearchAutocompleteOption[] {
        if (!prefixes) {
            return;
        }
        if (!query) {
            return [];
        }

        return sortBy(prefixes, 'displayOrder').map((prefix) => ({
            text: query,
            icon: prefix.icon || 'history',
            iconSize: prefix.iconSize,
            iconVariation: prefix.iconVariation,
            variation: prefix.variation,
            prefix: prefix.value,
            key,
        }));
    }

    private filterSearchSuggestions(
        { prefixes }: GridSearchConfiguration,
        suggestions?: SearchAutocompleteOption[],
    ): SearchAutocompleteOption[] {
        return suggestions.filter((item) =>
            some(prefixes, ['value', item.prefix]),
        );
    }
}
