import {
    Component,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    TemplateRef,
    ViewChild,
    AfterViewInit,
    OnInit,
    inject,
} from '@angular/core';
import { NgClass, NgTemplateOutlet } from '@angular/common';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import dayjs, { Dayjs } from 'dayjs';
import { isNull, isUndefined } from 'lodash';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

import { OnChange } from '../../../decorators';
import { InputComponent, IconComponent } from '../../../components';
import { CONFIG } from '../../../config';
import { EnvironmentService } from '../../../services';
import { MemoizeFuncPipe, PluralizePipe } from '../../../pipes';
import { AutofocusDirective, IsDesktopDeviceDirective, IsMobileDeviceDirective } from '../../../directives';
import { TooltipDirective } from '../../tooltip';
import {
    DateRangeModesTitle,
    DateRangeSelectionMode,
    DateRangeState,
    MaxDatesInRange,
    RangeCalendarCurrentMonthPosition,
} from '../models';

import {
    DateOutsideOfRange,
    getDatesDifferenceInMonths,
    isDateAfterRange,
    isDateBeforeRange,
    isDateBetweenFromAndHovered,
    isDateBetweenToAndHovered,
    isDateInDisabledDaysOfWeek,
    isDateOutsideOfMinMaxDates,
    isDateOutsideOfRange,
    isFromMode,
    isToMode,
} from './helpers';

const NUMBER_OF_MONTHS_PER_VIEW = 2;
const ONE_MONTH = 1;

interface CalendarErrors {
    from: {
        valid: boolean;
    };
    to: {
        valid: boolean;
    };
}

@UntilDestroy()
@Component({
    selector: 'fi-range-calendar',
    templateUrl: './range-calendar.component.html',
    styleUrls: ['./range-calendar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        IconComponent,
        InputComponent,
        MemoizeFuncPipe,
        PluralizePipe,
        IsMobileDeviceDirective,
        IsDesktopDeviceDirective,
        AutofocusDirective,
        TooltipDirective,
    ]
})
export class DateRangeCalendarComponent implements OnInit, AfterViewInit {
    public readonly changeDetectorRef = inject(ChangeDetectorRef);
    private readonly environmentService = inject(EnvironmentService);

    @Input() dateRange: DateRangeState;
    @Input() availableDateRange: DateRangeState;
    @Input() maxDatesInRange: MaxDatesInRange;
    @Input() disabledDaysOfWeek: number[];
    @Input() calendarView = false;
    @Input() singleDateMode = false;
    @Input() oneDateSelectionAvailable = true;
    @Input() currentMonthPosition = RangeCalendarCurrentMonthPosition.left;
    @Input() fromTitle = DateRangeModesTitle.From;
    @Input() toTitle = DateRangeModesTitle.To;
    @Input() toDateMessageInfo: string;
    @Input() rangeTitle: string;

    @Output() dateRangeChanged = new EventEmitter<DateRangeState>();

    @ViewChild('dayDisabledTooltipTpl')
    dayDisabledTooltipTpl: TemplateRef<void>;
    @ViewChild('dayOutsideOfRangeTooltipTpl')
    dayOutsideOfRangeTooltipTpl: TemplateRef<void>;

    @ViewChild('fromDate')
    fromDate: InputComponent;

    @ViewChild('toDate')
    toDate: InputComponent;

    readonly dateRangeSelectionMode = DateRangeSelectionMode;

    today = dayjs().startOf('day');
    days: Dayjs[];
    nextMonthDays: Dayjs[];
    daysOfWeek: string[];
    currentMonth: Dayjs;
    nextMonth: Dayjs;
    fromInputState: string;
    toInputState: string;
    selectedState: DateRangeState = this.getEmptySelectedState();
    dateHovered: Dayjs;
    validations: CalendarErrors = {
        from: {
            valid: true,
        },
        to: {
            valid: true,
        },
    };
    datesUpdated$ = new BehaviorSubject<DateRangeState>({
        from: null,
        to: null,
    });
    dateOutsideOfRangeClicked = false;

    @OnChange('setupInitDays')
    private selectedDates: Dayjs[] = [];
    private isMobileOrSmallTablet: boolean;
    private isMobileDevice: boolean;
    private minDateAvailable: Dayjs;
    private maxDateAvailable: Dayjs;
    private selectionMode: DateRangeSelectionMode = DateRangeSelectionMode.From;

    ngOnInit(): void {
        this.handleDatesCalculation();
    }

    ngAfterViewInit(): void {
        this.daysOfWeek = dayjs.weekdaysMin();
        this.setAvailableDateRange();
        this.setIsMobileOrSmallTablet();
        this.initComponent();
        this.changeDetectorRef.detectChanges();
    }

    isAlertBannerOutsideOfRange(): boolean {
        return (
            this.dateOutsideOfRangeClicked &&
            this.isDateSelectable(this.dateHovered) &&
            isDateOutsideOfRange({
                date: this.dateHovered,
                maxDatesInRange: this.maxDatesInRange,
                selectedState: this.selectedState,
                selectionMode: this.selectionMode,
            })
        );
    }

    isFromOrToDate(date: Dayjs): boolean {
        const { from, to } = this.selectedState;

        return (
            (from?.isValid() && date?.isSame(from, 'day')) ||
            (to?.isValid() && date?.isSame(to, 'day'))
        );
    }

    isDayInRange(date: Dayjs): boolean {
        if (this.singleDateMode || !date?.isValid()) {
            return false;
        }

        const { from, to } = this.selectedState;
        const isDateInSelectedRange =
            from?.isValid() && to?.isValid() && date.isBetween(from, to);

        const dateOutsideOfRange: DateOutsideOfRange = {
            date: this.dateHovered,
            maxDatesInRange: this.maxDatesInRange,
            selectedState: this.selectedState,
            selectionMode: this.selectionMode,
        };

        const dateBetweenFromAndHovered = isDateBetweenFromAndHovered(
            date,
            this.dateHovered,
            this.selectedState,
        );
        const isDateBetweenFromAndHoveredInRange =
            dateBetweenFromAndHovered && !isDateAfterRange(dateOutsideOfRange);
        const isDateBetweenFromAndHoveredDisabledOutsideOfRange =
            dateBetweenFromAndHovered &&
            isDateAfterRange(dateOutsideOfRange) &&
            !this.isDateSelectable(this.dateHovered);

        const dateBetweenToAndHovered = isDateBetweenToAndHovered(
            date,
            this.dateHovered,
            this.selectedState,
        );
        const isDateBetweenToAndHoveredInRange =
            dateBetweenToAndHovered && !isDateBeforeRange(dateOutsideOfRange);
        const isDateBetweenToAndHoveredDisabledOutsideOfRange =
            dateBetweenToAndHovered &&
            isDateBeforeRange(dateOutsideOfRange) &&
            !this.isDateSelectable(this.dateHovered);

        return (
            isDateInSelectedRange ||
            isDateBetweenFromAndHoveredInRange ||
            isDateBetweenFromAndHoveredDisabledOutsideOfRange ||
            isDateBetweenToAndHoveredInRange ||
            isDateBetweenToAndHoveredDisabledOutsideOfRange
        );
    }

    isDateFromSeparateMonth([month, day]: [Dayjs, Dayjs]): boolean {
        if (!day?.isValid()) {
            return true;
        }

        const monthStart = month.startOf('month');
        const monthEnd = month.endOf('month');

        return !day.isBetween(monthStart, monthEnd, 'day', '[]');
    }

    isDateDisabled([month, day]: [Dayjs, Dayjs]): boolean {
        if (!day?.isValid()) {
            return false;
        }

        return (
            !this.isDateFromSeparateMonth([month, day]) &&
            !this.isDateSelectable(day)
        );
    }

    isDateOutsideOfRangeHovered(date: Dayjs): boolean {
        return (
            date?.isValid() &&
            this.isDateSelectable(this.dateHovered) &&
            isDateOutsideOfRange({
                date: this.dateHovered,
                maxDatesInRange: this.maxDatesInRange,
                selectedState: this.selectedState,
                selectionMode: this.selectionMode,
            }) &&
            this.dateHovered.isSame(date, 'day')
        );
    }

    isDayOutsideOfRange(date: Dayjs): boolean {
        const fromAndToDatesSelected =
            this.selectedState.from?.isValid() &&
            this.selectedState.to?.isValid();

        if (
            this.singleDateMode ||
            !date?.isValid() ||
            fromAndToDatesSelected ||
            !this.isDateSelectable(this.dateHovered)
        ) {
            return false;
        }

        const dateOutsideOfRange: DateOutsideOfRange = {
            date: this.dateHovered,
            maxDatesInRange: this.maxDatesInRange,
            selectedState: this.selectedState,
            selectionMode: this.selectionMode,
        };
        const isDateBetweenFromAndHoveredOutsideOfRange =
            isDateAfterRange(dateOutsideOfRange) &&
            date.isBetween(this.selectedState.from, this.dateHovered);
        const isDateBetweenToAndHoveredDisabledOutsideOfRange =
            isDateBeforeRange(dateOutsideOfRange) &&
            date.isBetween(this.dateHovered, this.selectedState.to);

        return (
            isDateBetweenFromAndHoveredOutsideOfRange ||
            isDateBetweenToAndHoveredDisabledOutsideOfRange
        );
    }

    onSelectDate(date: Dayjs): void {
        if (!date?.isValid() || !this.isDateSelectable(date)) {
            return;
        }

        if (
            isDateOutsideOfRange({
                date,
                maxDatesInRange: this.maxDatesInRange,
                selectedState: this.selectedState,
                selectionMode: this.selectionMode,
            })
        ) {
            this.dateOutsideOfRangeClicked = true;
            return;
        }

        if (
            !this.oneDateSelectionAvailable &&
            isToMode(this.selectionMode) &&
            date.isSame(dayjs(this.selectedState.from), 'day')
        ) {
            return;
        }

        this.updateDates(this.selectionMode, date);

        this.dateRangeChanged.emit({
            from: this.selectedState.from,
            to: this.selectedState.to,
        });

        // Force blur inputs in the modal to close keyboard on ipad
        // Has no side effect on other devices
        setTimeout(() => {
            this.fromDate?.forceBlur();
            this.toDate?.forceBlur();
        });
    }

    onMouseOver(date: Dayjs): void {
        const isSelectingToDate =
            this.selectedState.from?.isValid() &&
            (!this.selectedState.to?.isValid() || isToMode(this.selectionMode));
        const isSelectingFromDate =
            this.selectedState.to?.isValid() &&
            (!this.selectedState.from?.isValid() ||
                isFromMode(this.selectionMode));
        this.dateHovered =
            isSelectingToDate || isSelectingFromDate ? date : null;
    }

    onMouseOut(): void {
        this.dateHovered = null;
        this.dateOutsideOfRangeClicked = false;
    }

    getDayTooltip(date: Dayjs): TemplateRef<void> | null {
        if (!date?.isValid() || this.isMobileOrSmallTablet) {
            return null;
        }

        const isDateSelectable = this.isDateSelectable(date);
        const isDateSelectableOutsideOfRange =
            isDateSelectable &&
            isDateOutsideOfRange({
                date,
                maxDatesInRange: this.maxDatesInRange,
                selectedState: this.selectedState,
                selectionMode: this.selectionMode,
            });

        if (isDateSelectableOutsideOfRange) {
            return this.dayOutsideOfRangeTooltipTpl;
        }

        if (isDateSelectable) {
            return null;
        }

        return this.dayDisabledTooltipTpl;
    }

    prevControlDisabled(currentMonth: Dayjs): boolean {
        const prevMonth = currentMonth.startOf('month').subtract(1, 'month');

        return prevMonth.isBefore(this.minMonthAvailable);
    }

    nextControlDisabled(currentMonth: Dayjs): boolean {
        const nextMonth = currentMonth.endOf('month').add(1, 'month');

        if (this.isCurrentMonthRightPositioned() && !this.isMobileDevice) {
            return nextMonth.endOf('month').isSame(this.maxMonthAvailable);
        }

        return nextMonth.isAfter(this.maxMonthAvailable);
    }

    onNavigateBackMonth(): void {
        this.nextMonth = this.currentMonth;
        this.nextMonthDays = this.getDays(this.nextMonth);
        this.currentMonth = this.currentMonth.subtract(1, 'month');
        this.days = this.getDays(this.currentMonth);
    }

    onNavigateNextMonth(): void {
        this.setCurrentMonth(this.nextMonth);
    }

    onFromChanged(value: string): void {
        const date = value
            ? dayjs(value, CONFIG.TIME_FORMAT.SHORTEST_DATE, true)
            : null;

        if (!date?.isValid()) {
            this.selectedState.from = null;
            this.selectedDates = [this.selectedState.to];
            this.dateRangeChanged.emit({ ...this.selectedState, from: null });
            this.setFromMode();
            this.changeDetectorRef.markForCheck();
            return;
        }

        if (this.shouldResetFromField(date)) {
            this.validations.from.valid = false;
            this.onResetField(DateRangeSelectionMode.From);
            this.changeDetectorRef.markForCheck();
            return;
        }

        this.validations.from.valid = true;
        this.setFromMode();

        this.datesUpdated$.next({ ...this.selectedState, from: date });

        this.changeDetectorRef.markForCheck();
    }

    onToChanged(value: string): void {
        const date = value
            ? dayjs(value, CONFIG.TIME_FORMAT.SHORTEST_DATE, true)
            : null;

        if (!date?.isValid()) {
            this.selectedState.to = null;
            this.selectedDates = [this.selectedState.from];
            this.dateRangeChanged.emit({
                ...this.datesUpdated$.value,
                to: null,
            });
            this.setToMode();
            this.changeDetectorRef.markForCheck();
            return;
        }

        if (this.shouldResetToField(date)) {
            this.validations.to.valid = false;
            this.onResetField(DateRangeSelectionMode.To);
            this.changeDetectorRef.markForCheck();
            return;
        }

        this.validations.to.valid = true;
        this.setToMode();

        this.datesUpdated$.next({ ...this.datesUpdated$.value, to: date });

        this.changeDetectorRef.markForCheck();
    }

    onInputBlur(
        event: FocusEvent,
        selectionMode: DateRangeSelectionMode,
    ): void {
        const inputValue = (event.target as HTMLInputElement).value;

        const isValueForCurrentModeDateInvalid =
            isNull(this.selectedState[selectionMode]) ||
            isUndefined(this.selectedState[selectionMode]);

        if (!inputValue && isValueForCurrentModeDateInvalid) {
            return;
        }

        const date = inputValue
            ? dayjs(inputValue, CONFIG.TIME_FORMAT.SHORTEST_DATE, true)
            : null;

        if (!this.isDateSelectable(date)) {
            this.validations[selectionMode].valid = false;
            this.onResetField(selectionMode);
            this.changeDetectorRef.markForCheck();
        }
    }

    onClearDate(selectionMode: DateRangeSelectionMode): void {
        this.validations[selectionMode].valid = true;
        this.onResetField(selectionMode);
        this.changeDetectorRef.detectChanges();
    }

    private getSelectedDates(range: DateRangeState): Dayjs[] {
        const days: Dayjs[] = [];

        if (range.from && !range.to) {
            const from = dayjs(range.from).startOf('day');
            days.push(from);
        }

        if (range.from && range.to) {
            let from = dayjs(range.from).startOf('day');
            const to = dayjs(range.to).startOf('day');

            while (from <= to) {
                days.push(from.clone());
                from = from.add(1, 'day');
            }
        }

        return days;
    }

    private updateDates(key: DateRangeSelectionMode, date: Dayjs): void {
        this.validations[key].valid = true;
        this.datesUpdated$.next({ ...this.datesUpdated$.value, [key]: date });
    }

    private setupInitDays(): void {
        if (!this.currentMonth) {
            this.today = dayjs().startOf('day');
            this.currentMonth = this.today.startOf('month');
        }

        this.days = this.getDays(this.currentMonth);
        this.nextMonth = this.currentMonth.add(1, 'month');
        this.nextMonthDays = this.getDays(this.nextMonth);
    }

    private setInputValue(state: DateRangeState): void {
        this.fromInputState = state.from?.format(
            CONFIG.TIME_FORMAT.SHORTEST_DATE,
        );
        this.toInputState = state.to?.format(CONFIG.TIME_FORMAT.SHORTEST_DATE);
    }

    private onResetField(selectionMode: DateRangeSelectionMode): void {
        if (isFromMode(selectionMode)) {
            this.selectedState = this.getEmptySelectedState();
            this.selectedDates = [];

            this.datesUpdated$.next({ from: null, to: null });
            this.setFromMode();
            this.dateRangeChanged.emit(null);
            this.setInputValue(this.selectedState);
            this.onMouseOut();
            this.fromDate.focusInputElement();

            this.clearInputText(selectionMode);
            this.changeDetectorRef.markForCheck();
            return;
        }

        if (this.selectedState) {
            this.selectedState.to = null;
        }

        this.selectedDates = [this.selectedDates[0]];
        this.datesUpdated$.next({ ...this.datesUpdated$.value, to: null });
        this.setToMode();
        this.dateRangeChanged.emit({
            from: this.selectedState.from,
            to: null,
        });
        this.setInputValue(this.selectedState);
        this.onMouseOut();
        this.toDate.focusInputElement();

        this.clearInputText(selectionMode);
        this.changeDetectorRef.markForCheck();
    }

    private isDateSelectable(date: Dayjs): boolean {
        if (
            !date?.isValid() ||
            isDateInDisabledDaysOfWeek(date, this.disabledDaysOfWeek)
        ) {
            return false;
        }

        return !isDateOutsideOfMinMaxDates(
            date,
            this.minDateAvailable,
            this.maxDateAvailable,
        );
    }

    private get minMonthAvailable(): Dayjs {
        return this.minDateAvailable
            ? this.minDateAvailable.startOf('month')
            : null;
    }

    private get maxMonthAvailable(): Dayjs {
        return this.maxDateAvailable
            ? this.maxDateAvailable.endOf('month')
            : null;
    }

    private initComponent(): void {
        let month = this.today.startOf('month');

        if (this.dateRange) {
            month =
                this.dateRange.from?.startOf('month') ||
                dayjs().startOf('month');
            this.selectedState = { ...this.dateRange };
            this.selectedDates = this.getSelectedDates(this.selectedState);

            if (this.dateRange.to?.isValid() && !this.singleDateMode) {
                this.setToMode();
            }

            this.datesUpdated$.next(this.selectedState);
            this.setInputValue(this.selectedState);
        }

        const showPreviousMonthAsCurrent =
            this.isCurrentMonthRightPositioned() &&
            !this.isMobileDevice &&
            month.isSame(dayjs().startOf('month'));

        this.currentMonth = showPreviousMonthAsCurrent
            ? month.subtract(1, 'month')
            : month;
        this.days = this.getDays(this.currentMonth);
        this.nextMonth = this.currentMonth.add(1, 'month');
        this.nextMonthDays = this.getDays(this.nextMonth);
        this.changeDetectorRef.markForCheck();
    }

    private handleDatesCalculation(): void {
        this.datesUpdated$
            .pipe(
                distinctUntilChanged(),
                filter(
                    (dateRange) =>
                        dateRange?.from?.isValid() || dateRange?.to?.isValid(),
                ),
                filter(
                    (dateRange) =>
                        this.isDateSelectable(dateRange.from) ||
                        this.isDateSelectable(dateRange.to),
                ),
                untilDestroyed(this),
            )
            .subscribe((value) => {
                const date = isFromMode(this.selectionMode)
                    ? value.from
                    : value.to;
                this.calculateDates(date, this.selectionMode);
                this.changeDetectorRef.markForCheck();
            });
    }

    private calculateDates(
        date: Dayjs,
        selectionMode?: DateRangeSelectionMode,
    ): void {
        if (!date?.isValid()) {
            return;
        }

        this.selectedDates = [];
        this.selectionMode = selectionMode || this.selectionMode;

        const originalState = { ...this.selectedState };
        const selectedDateRange: DateRangeState = isFromMode(this.selectionMode)
            ? this.getFromSelectedDates(date)
            : this.getToSelectedDates(date);
        const isToDateLessThanFromDate = date.isBefore(this.selectedState.from);

        if (isToMode(this.selectionMode) && isToDateLessThanFromDate) {
            this.datesUpdated$.next({ ...selectedDateRange });
            this.dateRangeChanged.emit({ ...selectedDateRange });
            this.navigateToCurrentMonth(DateRangeSelectionMode.From, {
                ...selectedDateRange,
            });

            if (!this.calendarView) {
                this.clearInputText(DateRangeSelectionMode.To);
                this.toDate.focusInputElement();
            }
        }

        this.selectedDates = this.getSelectedDates(selectedDateRange);
        this.selectedState = { ...selectedDateRange };

        this.setInputValue(this.selectedState);
        this.navigateToCurrentMonth(selectionMode, this.selectedState);
        this.setSelectionMode(date, selectionMode, originalState);

        const { from, to } = selectedDateRange;
        this.dateRangeChanged.emit({ from, to });
        this.changeDetectorRef.markForCheck();
    }

    private getFromSelectedDates(dateFrom: Dayjs): DateRangeState {
        const { selectedState } = this;
        const range: DateRangeState = {};

        range.from = dateFrom;

        if (
            selectedState.to?.isValid() &&
            (dateFrom.isBefore(selectedState.to) ||
                (dateFrom.isSame(selectedState.to) &&
                    this.oneDateSelectionAvailable))
        ) {
            range.to = this.singleDateMode ? dateFrom : selectedState.to;
        }

        return range;
    }

    private getToSelectedDates(dateTo: Dayjs): DateRangeState {
        const { selectedState } = this;
        const range: DateRangeState = {};

        if (!dateTo?.isValid()) {
            return {
                from: selectedState.from,
                to: null,
            };
        }

        const isDateToLessThanDateFrom = dateTo.isBefore(selectedState?.from);
        if (isDateToLessThanDateFrom) {
            range.from = dateTo;
            range.to = null;
        } else {
            range.from = selectedState.from;
            range.to = dateTo;
        }

        return range;
    }

    private getDays(date: Dayjs): Dayjs[] {
        let firstDay: Dayjs = date.startOf('month').startOf('week');
        const lastDay = date.endOf('month').endOf('week');

        const days: Dayjs[] = [];

        while (firstDay <= lastDay) {
            const isFromSeparateMonth = this.isDateFromSeparateMonth([
                date,
                firstDay,
            ]);

            if (isFromSeparateMonth) {
                days.push(null);
            } else {
                days.push(firstDay.clone());
            }

            firstDay = firstDay.add(1, 'day');
        }

        return days;
    }

    private getEmptySelectedState(): DateRangeState {
        return {
            from: null,
            to: null,
        };
    }

    private setSelectionMode(
        date: Dayjs,
        currentSelectionMode: DateRangeSelectionMode,
        originalState: DateRangeState,
    ): void {
        if (this.singleDateMode) {
            return;
        }

        if (isFromMode(currentSelectionMode)) {
            this.setToMode();
            return;
        }

        const isToDateLessThanFromDate = date.isBefore(originalState?.from);
        if (isToDateLessThanFromDate) {
            this.setToMode();
        } else {
            this.setFromMode();
        }
    }

    private shouldResetFromField(date: Dayjs): boolean {
        const { to } = this.selectedState;
        const dateEqualToToDate =
            !this.oneDateSelectionAvailable &&
            to &&
            date?.isSame(dayjs(to), 'day');

        const dateOutsideOfRange =
            !!this.maxDatesInRange &&
            to &&
            date?.isSameOrBefore(
                to.subtract(
                    this.maxDatesInRange.maxDates,
                    this.maxDatesInRange.rangeType,
                ),
            );

        return (
            !this.isDateSelectable(date) ||
            dateEqualToToDate ||
            dateOutsideOfRange
        );
    }

    private shouldResetToField(date: Dayjs): boolean {
        const { from } = this.selectedState;
        const dateEqualToFromDate =
            !this.oneDateSelectionAvailable &&
            from &&
            date?.isSame(dayjs(from), 'day');

        const dateOutsideOfRange =
            !!this.maxDatesInRange &&
            from &&
            date?.isSameOrAfter(
                from.add(
                    this.maxDatesInRange.maxDates,
                    this.maxDatesInRange.rangeType,
                ),
            );

        return (
            !this.isDateSelectable(date) ||
            dateEqualToFromDate ||
            dateOutsideOfRange
        );
    }

    private setCurrentMonth(date: Dayjs): void {
        if (!date?.isValid()) {
            return;
        }

        this.currentMonth = date.startOf('month');
        this.days = this.getDays(this.currentMonth);
        this.nextMonth = this.currentMonth.add(1, 'month');
        this.nextMonthDays = this.getDays(this.nextMonth);
    }

    private navigateToCurrentMonth(
        selectionMode: DateRangeSelectionMode,
        state: DateRangeState,
    ): void {
        const startOfMonthDate = state[selectionMode]?.startOf('month');

        if (!startOfMonthDate?.isValid()) {
            return;
        }

        const monthToShow = this.getMonthToShow(startOfMonthDate, state);
        this.setCurrentMonth(monthToShow);
    }

    private getMonthToShow(
        firstDateOfSelectedMonth: Dayjs,
        state: DateRangeState,
    ): Dayjs {
        const { from, to } = state;

        if (from?.isValid() && to?.isValid()) {
            const firstDateOfFromMonth = from.startOf('month');
            const firstDateOfToMonth = to.startOf('month');
            const datesDifferenceInMonths = getDatesDifferenceInMonths(
                firstDateOfFromMonth,
                firstDateOfToMonth,
            );
            const isDatesDifferenceEqualOneMonth =
                datesDifferenceInMonths === ONE_MONTH;
            const areBothDatesInSameMonth = from.isSame(to, 'month');
            const isCurrentMonthBeforeFromDate = this.currentMonth.isBefore(
                from,
                'month',
            );

            if (
                (isDatesDifferenceEqualOneMonth || areBothDatesInSameMonth) &&
                !isCurrentMonthBeforeFromDate
            ) {
                return firstDateOfFromMonth;
            }
        }

        const monthsDifference = getDatesDifferenceInMonths(
            this.currentMonth,
            firstDateOfSelectedMonth,
        );
        const isSelectedMonthBeforeCurrent =
            monthsDifference === ONE_MONTH &&
            firstDateOfSelectedMonth.isBefore(this.currentMonth, 'month');

        if (
            monthsDifference >= NUMBER_OF_MONTHS_PER_VIEW ||
            isSelectedMonthBeforeCurrent
        ) {
            return firstDateOfSelectedMonth;
        }

        return this.currentMonth;
    }

    private clearInputText(selectionMode: DateRangeSelectionMode): void {
        if (isFromMode(selectionMode)) {
            this.resetFromDate();
            return;
        }

        this.resetToDate();
    }

    private resetFromDate(): void {
        this.fromDate.value = '';
        this.fromDate.maskValue = '';
        this.fromDate.inputElement.value = '';
    }

    private resetToDate(): void {
        this.toDate.value = '';
        this.toDate.maskValue = '';
        this.toDate.inputElement.value = '';
    }

    private setFromMode(): void {
        this.selectionMode = DateRangeSelectionMode.From;
    }

    private setToMode(): void {
        this.selectionMode = DateRangeSelectionMode.To;
    }

    private setAvailableDateRange(): void {
        if (!this.availableDateRange) {
            return;
        }

        this.minDateAvailable = this.availableDateRange.from;
        this.maxDateAvailable = this.availableDateRange.to;
    }

    private setIsMobileOrSmallTablet(): void {
        combineLatest([
            this.environmentService.isMobileDevice$,
            this.environmentService.isSmallTabletDevice$,
        ])
            .pipe(untilDestroyed(this))
            .subscribe(([isMobileDevice, isSmallTabletDevice]) => {
                this.isMobileOrSmallTablet =
                    isMobileDevice || isSmallTabletDevice;
                this.isMobileDevice = isMobileDevice;
            });
    }

    private isCurrentMonthRightPositioned(): boolean {
        return (
            this.currentMonthPosition ===
            RangeCalendarCurrentMonthPosition.right
        );
    }
}
