<ng-template>
    <div
        [ngClass]="gtmClassName"
        [cdkTrapFocusAutoCapture]="true"
        class="fi-modal-wrapper"
        cdkTrapFocus
    >
        <ng-content></ng-content>
    </div>
</ng-template>
