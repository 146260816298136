import { TemplateRef, ViewContainerRef } from '@angular/core';
import {
    BlockScrollStrategy,
    CloseScrollStrategy,
    ComponentType,
    NoopScrollStrategy,
    Overlay,
    OverlayConfig,
    RepositionScrollStrategy,
    OverlayRef,
    OverlaySizeConfig,
} from '@angular/cdk/overlay';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';

import { Observable } from 'rxjs';

import { OverlayContext } from './params';
import { OverlayScrollStrategy } from './scroll';

export abstract class BaseOverlay<T, P, K> {
    protected overlayRef: OverlayRef;

    constructor(
        protected overlayBuilder: Overlay,
        public params: P,
        private context: OverlayContext,
        private content?: T,
        private viewContainerRef?: ViewContainerRef,
        public origin?: K,
    ) {
        this.init();
    }

    get overlayElement(): HTMLElement {
        return this.overlayRef.overlayElement;
    }

    get hostElement(): HTMLElement {
        return this.overlayRef.hostElement;
    }

    protected abstract resolveConfig(params: P): OverlayConfig;

    applyContent(
        content: T,
        viewContainerRef: ViewContainerRef,
        context: OverlayContext,
    ): void {
        this.content = content;
        this.viewContainerRef = viewContainerRef;
        this.context = context;
    }

    attach(): void {
        if (this.content instanceof TemplateRef) {
            this.overlayRef.attach(
                this.resolveTemplatePortal(
                    this.content,
                    this.viewContainerRef,
                    this.context,
                ),
            );

            return;
        }

        const componentRef = this.overlayRef.attach(
            this.resolveComponentPortal(
                this.content as any,
                this.viewContainerRef,
            ),
        );
        // Add input properties to component
        Object.assign(componentRef.instance, this.context);
    }

    attachments(): Observable<void> {
        return this.overlayRef.attachments();
    }

    detach(): void {
        this.overlayRef.detach();
    }

    detachments(): Observable<void> {
        return this.overlayRef.detachments();
    }

    dispose(): void {
        this.overlayRef.dispose();
    }

    getConfig(): OverlayConfig {
        return this.overlayRef.getConfig();
    }

    updateSize(sizeConfig: OverlaySizeConfig): void {
        this.overlayRef.updateSize(sizeConfig);
    }

    updatePosition(): void {
        this.overlayRef.updatePosition();
    }

    protected getScrollStrategy(
        strategyType?: OverlayScrollStrategy,
    ):
        | NoopScrollStrategy
        | CloseScrollStrategy
        | BlockScrollStrategy
        | RepositionScrollStrategy {
        const scrollStrategies = this.overlayBuilder.scrollStrategies;

        if (!strategyType) {
            return scrollStrategies.block();
        }

        return scrollStrategies[strategyType]();
    }

    private init(): void {
        const overlayConfig = this.resolveConfig(this.params);

        this.overlayRef = this.overlayBuilder.create(overlayConfig);
    }

    private resolveTemplatePortal(
        template: TemplateRef<any>,
        viewContainerRef: ViewContainerRef,
        context?: OverlayContext,
    ): TemplatePortal<T> {
        return new TemplatePortal<any>(template, viewContainerRef, context);
    }

    private resolveComponentPortal<T>(
        component: ComponentType<any>,
        viewContainerRef: ViewContainerRef,
    ): ComponentPortal<T> {
        return new ComponentPortal(component, null, viewContainerRef.injector);
    }
}
