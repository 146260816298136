<fi-modal
    [open]="isOpenModal"
    [animation]="modalAnimation"
    [variation]="modalVariation"
    (event)="handleModalEvent($event)"
    class="leave-confirmation-modal"
>
    @if (isOpenModal) {
        <fi-modal-header
            *fiIsDesktopDevice
            [gtmClassName]="gtmClassName?.closeBtnClassName"
            (close)="closeModal()"
        >
            <h3
                class="fi-heading fi-heading--tertiary fi-heading--margin-less fi-heading--dark"
            >
                {{ modalConfig?.title }}
            </h3>
        </fi-modal-header>

        <fi-modal-close-bar
            *fiIsMobileDevice
            [closeIcon]="'times'"
            [title]="modalConfig.title"
            [gtmClassName]="gtmClassName?.closeBtnClassName"
            (close)="closeModal()"
        ></fi-modal-close-bar>

        <fi-modal-content>
            <div class="leave-confirmation-modal__content">
                <span
                    [innerHTML]="modalConfig?.description"
                    class="fi-text"
                ></span>
            </div>
        </fi-modal-content>

        <fi-modal-footer>
            <div class="leave-confirmation-modal__footer">
                <fi-button
                    [ariaLabel]="
                        modalConfig?.leaveBtnText || defaultLeaveBtnText
                    "
                    [variation]="'secondary'"
                    [className]="gtmClassName?.actionBtnClassName"
                    (click)="submitLeave()"
                >
                    {{ modalConfig?.leaveBtnText || defaultLeaveBtnText }}
                </fi-button>

                <fi-button
                    [ariaLabel]="'Stay on page'"
                    [variation]="'primary'"
                    [className]="gtmClassName?.cancelBtnClassName"
                    (click)="closeModal()"
                >
                    Stay on page
                </fi-button>
            </div>
        </fi-modal-footer>
    }
</fi-modal>
