import { environment } from '@environments/environment';

import { NgModule } from '@angular/core';

import { GoogleTagManagerService } from 'angular-google-tag-manager';

import { PnskCommonModule } from '@pnsk/ui-common/pnsk-common.module';
import {
    TagManagerService,
    TagManagerBaseService,
} from '@pnsk/ui-common/analytics';

import { TrackViewComponent } from './components';

const PROVIDERS = environment.production
    ? [
          {
              provide: 'googleTagManagerId',
              useValue: environment.externalConfig.ANALYTICS_KEY,
          },
          {
              provide: TagManagerBaseService,
              useClass: GoogleTagManagerService,
          },
      ]
    : [
          {
              provide: TagManagerBaseService,
              useClass: TagManagerService,
          },
      ];

@NgModule({
    imports: [PnskCommonModule],
    declarations: [TrackViewComponent],
    exports: [TrackViewComponent],
    providers: [PROVIDERS],
})
export class AnalyticsModule {}
