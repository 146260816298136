import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

const enum SelectFieldVariation {
    Medium = 'medium',
    Regular = 'regular',
    Large = 'large',
}

@Component({
    selector: 'fi-select-field',
    templateUrl: './select-field.component.html',
    styleUrls: ['./select-field.component.scss'],
})
export class SelectFieldComponent {
    @Input()
    formGroup: UntypedFormGroup;

    @Input()
    name: string;

    @Input()
    variation: SelectFieldVariation;

    get field() {
        return this.formGroup.controls[this.name];
    }
}
