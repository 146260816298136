<div #chartElement class="fi-quarterly-monthly-chart">
    <div
        [class.fi-quarterly-monthly-chart__container--empty]="!chart"
        class="fi-quarterly-monthly-chart__container"
    >
        <div
            id="fi-quarterly-monthly-chart"
            class="fi-quarterly-monthly-chart__chart-container"
        ></div>

        @if (!chart) {
            <div class="fi-quarterly-monthly-chart__empty-container">
                <span
                    class="fi-quarterly-monthly-chart__empty-chart-block"
                ></span>
                <span class="fi-quarterly-monthly-chart__empty-message">
                    There is no data to display.
                </span>
            </div>
        }
    </div>
</div>
