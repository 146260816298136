import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';

import { SkeletonElementComponent } from '../../../../components';

const ITEMS_LENGTH = 7;

@Component({
    selector: 'fi-grid-list-loader-skeleton',
    templateUrl: './loader-skeleton.component.html',
    styleUrls: ['./loader-skeleton.component.scss'],
    standalone: true,
    imports: [SkeletonElementComponent],
})
export class GridListLoaderSkeletonComponent implements AfterViewInit {
    items: null[] = Array(ITEMS_LENGTH).fill(null);

    constructor(private cdRef: ChangeDetectorRef) {}

    ngAfterViewInit(): void {
        this.cdRef.detach();
    }
}
