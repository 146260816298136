import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlightMatch',
  standalone: true,
})
export class HighlightMatchPipe implements PipeTransform {
  transform(value: string, searchText: string): string {
    if (!value || !searchText) {
      return value;
    }

    //  escape special characters
    const escapedSearchText = searchText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    const regex = new RegExp(`(${escapedSearchText})`, 'gi');
    return value.replace(regex, '<strong>$1</strong>')
  }
}
