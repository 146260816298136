import { environment } from '@environments/environment';

const VIDEO_SRC = `${environment.externalConfig.RESOURCES_PATH}/assets/videos/penske-fleet-insight.mp4`;
const VIDEO_POSTER = `${environment.externalConfig.RESOURCES_PATH}/penske-fleet-video-background.png`;

const VIDEO_CONTAINER_SELECTOR = '#road-ahead-video-container';
const VIDEO_BACKGROUND_SELECTOR = '#road-ahead-video-background';

const VIDEO_POPUP_SELECTOR = '.landing-road-ahead__video-popup';
const VIDEO_POPUP_SHOW_CLASS = 'landing-road-ahead__video-popup--show';

const VIDEO_ATTRIBUTE_ID = 'road-ahead-video';

const BRIGHTCOVE_ACCOUNT_ID = 648728607001;
const BRIGHTCOVE_VIDEO_ID = 6184699474001;

const VIDEO_TELEMATICS_ATTRIBUTE_ID = 'telematics-video-button';
const TELEMATICS_PLAY_BUTTON_SELECTOR = '#telematics-video-button';
const TELEMATICS_VIDEO_ID = 6349673383112;

const MOBILE_BREAKPOINT = 769;
const VIDEO_RATIO = 0.5624;

export class RoadAheadController {
    videoContainer: HTMLElement = document.querySelector(
        VIDEO_CONTAINER_SELECTOR,
    );
    videoBackground: HTMLVideoElement = document.querySelector(
        VIDEO_BACKGROUND_SELECTOR,
    );
    telematicsPlayButtonElement: HTMLElement = document.querySelector(
        TELEMATICS_PLAY_BUTTON_SELECTOR,
    );
    videoPopup: HTMLVideoElement = document.querySelector(VIDEO_POPUP_SELECTOR);
    popupIframe: HTMLIFrameElement;

    constructor() {
        this.initialize();
    }

    private initialize(): void {
        if (!this.isMP4Supported()) {
            this.videoBackground.poster = VIDEO_POSTER;
            this.attachPlayVideoEvent();
            return;
        }

        const sourceMP4 = this.createMP4Source();
        this.videoBackground.appendChild(sourceMP4);

        const windowEventHandler = async (): Promise<void> => {
            await this.playBackgroundVideo();
        };

        window.addEventListener('scroll', windowEventHandler, {
            passive: true,
        });
        window.addEventListener('resize', windowEventHandler, {
            passive: true,
        });

        window.addEventListener(
            'beforeunload',
            () => {
                window.removeEventListener('scroll', windowEventHandler);
                window.removeEventListener('resize', windowEventHandler);
            },
            { once: true },
        );

        this.attachPlayVideoEvent();
    }

    private attachPlayVideoEvent(): void {
        if (!this.videoContainer && !this.telematicsPlayButtonElement) {
            return;
        }

        const clickEventHandler = async (event: MouseEvent): Promise<void> => {
            const iframe = (event.target as HTMLElement).closest(
                `#${VIDEO_ATTRIBUTE_ID}`,
            );
            const videoId =
                (event.target as HTMLElement).id ===
                VIDEO_TELEMATICS_ATTRIBUTE_ID
                    ? TELEMATICS_VIDEO_ID
                    : BRIGHTCOVE_VIDEO_ID;

            if (iframe) {
                return;
            }

            if (this.popupIframe) {
                this.popupIframe.remove();
                this.popupIframe = null;
                this.toggleVideoPopup();
                await this.playBackgroundVideo();
            } else {
                try {
                    await this.loadPopupVideoIframe(videoId);
                    this.pauseBackgroundVideo();
                    this.handleResizeEvent();
                    this.toggleVideoPopup();
                } catch (e) {
                    return;
                }
            }
        };

        this.videoBackground.addEventListener(
            'contextmenu',
            this.contextmenuEventHandler,
        );

        this.videoContainer.addEventListener('click', clickEventHandler);
        this.telematicsPlayButtonElement.addEventListener(
            'click',
            clickEventHandler,
        );
        this.videoPopup.addEventListener('click', clickEventHandler);

        window.addEventListener(
            'beforeunload',
            () => {
                this.videoContainer.removeEventListener(
                    'click',
                    clickEventHandler,
                );

                this.videoPopup.removeEventListener('click', clickEventHandler);
                this.videoBackground.removeEventListener(
                    'contextmenu',
                    this.contextmenuEventHandler,
                );
            },
            { once: true },
        );
    }

    private contextmenuEventHandler($event): void {
        $event.preventDefault();
    }

    private isMP4Supported(): boolean {
        if (!this.videoBackground) {
            return false;
        }

        const video = document.createElement('video');

        return video && !!video.canPlayType('video/mp4').length;
    }

    private createMP4Source(): HTMLElement {
        const sourceMP4: HTMLSourceElement = document.createElement('source');
        sourceMP4.type = 'video/mp4';
        sourceMP4.src = VIDEO_SRC;

        return sourceMP4;
    }

    private isContainerInViewPort(): boolean {
        const { height, top } = this.videoContainer.getBoundingClientRect();
        const windowHeight =
            window.innerHeight || document.documentElement.clientHeight;

        return top <= windowHeight && top + height >= 0;
    }

    private loadPopupVideoIframe(videoId: number): Promise<void> {
        return new Promise((resolve, reject) => {
            this.popupIframe = document.createElement('iframe');
            this.popupIframe.id = 'road-ahead-video';
            this.popupIframe.allowFullscreen = true;
            this.popupIframe.allow = 'encrypted-media';
            this.popupIframe.src = `https://players.brightcove.net/${BRIGHTCOVE_ACCOUNT_ID}/default_default/index.html?videoId=${videoId}`;

            this.popupIframe.addEventListener('load', () => resolve());
            this.popupIframe.addEventListener('error', reject);

            this.videoPopup.appendChild(this.popupIframe);
        });
    }

    private handleResizeEvent(): void {
        const resizeEventHandler = (): void => {
            this.setVideoSize();
        };

        this.setVideoSize();

        window.addEventListener('resize', resizeEventHandler);
        window.addEventListener(
            'change',
            () => {
                window.removeEventListener('resize', resizeEventHandler);
            },
            { once: true },
        );
    }

    private async playBackgroundVideo(): Promise<void> {
        if (window.innerWidth <= MOBILE_BREAKPOINT) {
            this.pauseBackgroundVideo();
            return;
        }

        if (!this.videoBackground) {
            return;
        }

        if (!this.isContainerInViewPort() && this.videoBackground.played) {
            this.pauseBackgroundVideo();
            return;
        }

        await this.videoBackground.play();
    }

    private pauseBackgroundVideo(): void {
        if (!this.videoBackground || !this.videoBackground.played) {
            return;
        }

        this.videoBackground.pause();
    }

    private toggleVideoPopup(): void {
        document
            .querySelector(VIDEO_POPUP_SELECTOR)
            .classList.toggle(VIDEO_POPUP_SHOW_CLASS);
    }

    private setVideoSize(): void {
        if (!this.popupIframe) {
            return;
        }

        const videoWidth =
            Math.min(window.innerWidth, window.innerHeight / VIDEO_RATIO) * 0.8;

        this.popupIframe.style.width = `${Math.ceil(videoWidth / 2) * 2}px`;
        this.popupIframe.style.height = `${
            Math.ceil((videoWidth * VIDEO_RATIO) / 2) * 2
        }px`;
    }
}
