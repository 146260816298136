@if (!noOpener) {
    <fi-filtering-opener
        [desktopViewOnly]="desktopViewOnly"
        [customCountCalculate]="customCountCalculate"
    ></fi-filtering-opener>
}

<fi-modal
    [open]="isModalOpen"
    [variation]="modalVariation"
    [animation]="modalAnimation"
    [hasCustomSmallTabletAnimation]="hasCustomSmallTabletAnimation"
    class="fi-filtering-picker"
>
    @if (isModalOpen) {
        <fi-modal-close-bar [title]="'Filters'" (close)="handleCancelFilters()">
            <fi-button
                [ariaLabel]="resetLabel"
                [variation]="'squared,big,link,white-st-link,datepicker-nav'"
                (click)="handleResetFilters()"
                class="fi-filtering-picker__reset-btn"
            >
                {{ resetLabel }}
            </fi-button>
        </fi-modal-close-bar>

        <fi-modal-content>
            <fi-filtering-list [variation]="listVariation"></fi-filtering-list>
        </fi-modal-content>

        <fi-modal-footer>
            <fi-button
                [ariaLabel]="'Apply'"
                [variation]="'primary,mobile-sticky,block'"
                (click)="handleApplyFilters()"
            >
                Apply
            </fi-button>
        </fi-modal-footer>
    }
</fi-modal>
