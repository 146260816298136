import { NgModule, Type } from '@angular/core';

import { SectionItemComponent } from './section-item.component';
import { SectionExtraHeadingDirective } from './section-extra-heading.directive';
import { SectionItemContentDirective } from './section-item-content.directive';
import { SectionItemFooterDirective } from './section-item-footer.directive';

const FEATURES: Array<Type<any> | any[]> = [
    SectionItemComponent,
    SectionExtraHeadingDirective,
    SectionItemContentDirective,
    SectionItemFooterDirective,
];

@NgModule({
    imports: [...FEATURES],
    exports: FEATURES,
})
export class SectionItemModule {}
