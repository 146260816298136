@if (isDesktopView$ | async) {
    <span
        [class.fi-grid-sort-icon__indicator--active]="isActive"
        [class.fi-grid-sort-icon__indicator--reversed]="reversed"
        class="fi-grid-sort-icon__indicator"
    >
        @switch (order) {
            @case ('DESC') {
                <fi-icon
                    [svgName]="'sort-arrow-short-down'"
                    [variation]="'custom'"
                    [size]="'xx-small'"
                    class="fi-grid-sort-icon__indicator-icon"
                ></fi-icon>
            }
            @default {
                <fi-icon
                    [svgName]="'sort-arrow-short-up'"
                    [variation]="'custom'"
                    [size]="'xx-small'"
                    class="fi-grid-sort-icon__indicator-icon"
                ></fi-icon>
            }
        }
    </span>
}

@if (isMobileView$ | async) {
    @if (isActive) {
        <fi-icon
            [class.fi-grid-order-icon__arrow--active]="order === 'DESC'"
            [svgName]="'long-arrow-down'"
            [variation]="'regular'"
            [size]="'large'"
            class="fi-grid-order-icon__arrow fi-grid-order-icon__arrow--down"
        ></fi-icon>
        <fi-icon
            [class.fi-grid-order-icon__arrow--active]="order === 'ASC'"
            [svgName]="'long-arrow-up'"
            [variation]="'regular'"
            [size]="'large'"
            class="fi-grid-order-icon__arrow fi-grid-order-icon__arrow--up"
        ></fi-icon>
    }
}
