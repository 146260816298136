<fi-modal
    [open]="open"
    [variation]="modalVariation"
    (event)="handleModalEvent($event)"
    class="fi-date-range-modal"
>
    @if (open) {
        <fi-modal-header
            *fiIsDesktopDevice
            (close)="onCloseModal()">
            <h3 class="fi-heading fi-heading--tertiary fi-heading--dark">
                {{ modalTitle || 'Select Dates' }}
            </h3>
        </fi-modal-header>

        <fi-modal-close-bar
            *fiIsMobileDevice
            [closeIcon]="'chevron-left'"
            [title]="modalTitle || 'Select Dates'"
            (close)="onCloseModal()"
        ></fi-modal-close-bar>

        <fi-modal-content class="fi-date-range-modal__calendars">
            <fi-range-calendar
                [dateRange]="dateRange"
                [availableDateRange]="availableDateRange"
                [maxDatesInRange]="maxDatesInRange"
                [disabledDaysOfWeek]="disabledDaysOfWeek"
                [singleDateMode]="singleDateMode"
                [oneDateSelectionAvailable]="oneDateSelectionAvailable"
                [currentMonthPosition]="currentMonthPosition"
                [fromTitle]="fromTitle"
                [toTitle]="toTitle"
                [toDateMessageInfo]="toDateMessageInfo"
                [rangeTitle]="rangeTitle"
                (dateRangeChanged)="onDateRangeChanged($event)"
            ></fi-range-calendar>
        </fi-modal-content>

        <fi-modal-footer *fiIsDesktopDevice>
            <div class="fi-date-range-modal__footer">
                <span
                    class="fi-text fi-text--small fi-text--medium fi-text--light-gray"
                >
                    {{ totalDays || 0 | pluralizeString: 'Day' : 'Days' }}
                    Selected
                </span>

                <div class="fi-date-range-modal__footer-actions">
                    <fi-button
                        [ariaLabel]="'Cancel'"
                        [variation]="'secondary'"
                        (click)="onCloseModal()"
                    >
                        Cancel
                    </fi-button>

                    <fi-button
                        [ariaLabel]="'Select'"
                        [variation]="'primary'"
                        [disabled]="
                            !(
                                tempDateRange
                                | memoizeFunc: isSelectButtonEnabled : this
                            )
                        "
                        (click)="onConfirmDates()"
                    >
                        Select Date{{ singleDateMode ? '' : 's' }}
                    </fi-button>
                </div>
            </div>
        </fi-modal-footer>

        <fi-modal-footer @animatedFooter *fiIsMobileDevice>
            <div class="fi-date-range-modal__footer">
                <span
                    class="fi-text fi-text--small fi-text--medium fi-text--light-gray"
                >
                    {{ totalDays || 0 | pluralizeString: 'Day' : 'Days' }}
                    Selected
                </span>

                <div class="fi-date-range-modal__footer-actions">
                    <fi-button
                        [ariaLabel]="'Select'"
                        [variation]="'primary'"
                        [disabled]="
                            !(
                                tempDateRange
                                | memoizeFunc: isSelectButtonEnabled : this
                            )
                        "
                        (click)="onConfirmDates()"
                    >
                        Select Date{{ singleDateMode ? '' : 's' }}
                    </fi-button>
                </div>
            </div>
        </fi-modal-footer>
    }
</fi-modal>
