import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    Output,
    QueryList,
    SimpleChanges,
    ViewChildren,
} from '@angular/core';
import { FocusKeyManager } from '@angular/cdk/a11y';
import { DatePipe } from '@angular/common';

import dayjs, { Dayjs } from 'dayjs';

import { MemoizeFuncPipe } from '../../../pipes';
import {
    AM_PM_HOURS_DIFF,
    HOURS_DIFF,
    MINUTES_DIFF,
    SectionsActivePick,
    TimePhase,
    TimePickOperation,
} from '../models';
import { TimePickerInputComponent } from '../input/input.component';

export const TIME_FORMAT = 'YYYY-MM-DD';

@Component({
    selector: 'fi-time-picker-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        DatePipe,
        TimePickerInputComponent,
        MemoizeFuncPipe,
    ]
})
export class TimePickerFormComponent implements OnChanges, AfterViewInit {
    @ViewChildren(TimePickerInputComponent)
    items: QueryList<TimePickerInputComponent>;

    @Input() size: string;
    @Input() value: Dayjs;
    @Input() hasAdjustedMinutes = false;

    @Output() valueChange = new EventEmitter<Dayjs>();

    readonly addAction = TimePickOperation.Add;
    readonly subtractAction = TimePickOperation.Subtract;

    private keyManager: FocusKeyManager<TimePickerInputComponent>;
    private isPmTime: boolean;
    private activeTimePick = SectionsActivePick.Hour;

    @HostListener('click', ['$event'])
    handleComponentClick(event: MouseEvent): void {
        event.stopPropagation();
    }

    @HostListener('keydown.tab', ['$event'])
    onTabKeydown(event: KeyboardEvent): void {
        event.preventDefault();

        this.keyManager.setNextItemActive();
        return;
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { value } = changes;

        if (value && value.previousValue !== value.currentValue) {
            this.isPmTime = this.value.hour() >= AM_PM_HOURS_DIFF;
        }
    }

    ngAfterViewInit(): void {
        this.keyManager = new FocusKeyManager<TimePickerInputComponent>(
            this.items,
        ).withWrap();

        setTimeout(() => {
            this.keyManager.setNextItemActive();
        });
    }

    pmAmLabel(_time: Dayjs): TimePhase {
        return this.isPmTime ? TimePhase.Pm : TimePhase.Am;
    }

    handlePmAmChange(): void {
        this.setActiveFiled(SectionsActivePick.Day);
        this.isPmTime = !this.isPmTime;

        const action: TimePickOperation = this.isPmTime
            ? TimePickOperation.Add
            : TimePickOperation.Subtract;

        this.value = this.value[action](AM_PM_HOURS_DIFF, 'hours');

        this.emitValue(this.value);
    }

    handleHoursChange(action: TimePickOperation): void {
        this.setActiveFiled(SectionsActivePick.Hour);

        this.value = this.value[action](HOURS_DIFF, 'hours');

        this.emitValue(this.value);
    }

    handleMinutesChange(action: TimePickOperation): void {
        this.setActiveFiled(SectionsActivePick.Minute);

        if (this.hasAdjustedMinutes) {
            this.value = this.getAdjustedTime(action);
        }

        this.value = this.value[action](MINUTES_DIFF, 'minutes');

        this.emitValue(this.value);
    }

    getValueDate(value: Dayjs): Date {
        return value.toDate();
    }

    private emitValue(time: Dayjs): void {
        const hours = time.hour();
        const minutes = time.minute();

        const newTime = dayjs(
            `${time.format(TIME_FORMAT)} ${hours}:${minutes}`,
        );
        this.valueChange.emit(newTime);
    }

    private setActiveFiled(activeSection: SectionsActivePick): void {
        this.activeTimePick = activeSection;
    }

    private getAdjustedMinutesUp(min: number): number {
        return min >= 1 && min < 60 ? Math.ceil(min / 15) * 15 : 0;
    }

    private getAdjustedMinutesDown(min: number): number {
        return min >= 0 && min < 60 ? Math.floor(min / 15) * 15 : 45;
    }

    private getAdjustedTime(action: TimePickOperation): Dayjs {
        const min = this.value.get('minute');
        const adjustedMin =
            action === TimePickOperation.Add
                ? this.getAdjustedMinutesDown(min)
                : this.getAdjustedMinutesUp(min);

        return this.value.set('minute', adjustedMin);
    }
}
