import { Component, Input, ViewEncapsulation } from '@angular/core';

import { FiIconVariation, FiIconSize } from './icon.constants';

@Component({
    selector: 'fi-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
})
export class IconComponent {
    @Input() svgName: string;
    @Input() variation: FiIconVariation = FiIconVariation.Light;
    @Input() size: FiIconSize = FiIconSize.Medium;
    @Input() color: string;

    get id(): string {
        return '#fi-icon-' + this.variation + '-' + this.svgName;
    }
}
