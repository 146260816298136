import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

import { Observable, of } from 'rxjs';

type MethodTypes = 'GET' | 'POST' | 'PUT' | 'PATCH';
type ResposeFN = (request: HttpRequest<any>) => any;
type ResponseType = ResposeFN | any | any[];

interface MockConfigInterface {
    url: string;
    method: MethodTypes;
    response: ResponseType;
}

@Injectable()
export class MockHttpInterceptor implements HttpInterceptor {
    private static urls: MockConfigInterface[] = [];

    static register(method: MethodTypes, url: string, response: ResponseType) {
        this.urls.push({
            method,
            url,
            response,
        });
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        for (const element of MockHttpInterceptor.urls) {
            if (
                request.url.endsWith(element.url) &&
                request.method === element.method
            ) {
                const response =
                    typeof element.response === 'function'
                        ? element.response(request)
                        : element.response;
                return of(new HttpResponse({ status: 200, body: response }));
            }
        }
        return next.handle(request);
    }
}
