import { Pipe, PipeTransform } from '@angular/core';
import { escapeRegExp } from 'lodash';

interface FilterItem {
    [key: string]: any;
}

@Pipe({ name: 'filterList', standalone: true, })
export class FilterListPipe implements PipeTransform {
    transform<T>(value: T[] = [], keys: string, search: string): T[] {
        if (!search) {
            return value;
        }

        return value.filter((item: FilterItem) =>
            keys
                .split(',')
                .some(
                    (key) =>
                        item.hasOwnProperty(key) &&
                        new RegExp(escapeRegExp(search), 'gi').test(item[key]),
                ),
        );
    }
}
