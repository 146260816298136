import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    Optional,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { CheckboxComponent } from '../../../components';
import { GridRow } from '../models';

import { GridRowSelectorDirective } from './row-selector.directive';

@UntilDestroy()
@Component({
    selector: 'fi-grid-row-selector',
    templateUrl: './row-selector.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CheckboxComponent],
})
export class GridRowSelectorComponent implements OnChanges, OnInit {
    @Input() row: GridRow;
    @Input() disabled: boolean;

    isRowSelected = false;
    isAllRowSelected = false;
    isSomeRowSelected = false;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        @Optional() private rowSelector: GridRowSelectorDirective,
    ) {}

    ngOnChanges(): void {
        this.updateState();
    }

    ngOnInit(): void {
        if (!this.rowSelector) {
            return;
        }

        this.rowSelector.stateChanged$
            .pipe(untilDestroyed(this))
            .subscribe(() => this.updateState());
    }

    handleCheck(): void {
        if (!this.rowSelector) {
            return;
        }

        if (this.row) {
            this.rowSelector.selectRow(this.row, this.isRowSelected);
            return;
        }

        this.rowSelector.toggleSelectRows();
    }

    private updateState(): void {
        if (!this.rowSelector) {
            this.isAllRowSelected =
                this.isRowSelected =
                this.isSomeRowSelected =
                    false;
            return;
        }

        const { isAllRowSelected, isSomeRowSelected } = this.rowSelector;

        this.isRowSelected = this.rowSelector.isRowSelected(this.row);
        this.isAllRowSelected = isAllRowSelected;
        this.isSomeRowSelected = isSomeRowSelected;

        this.changeDetectorRef.markForCheck();
    }
}
