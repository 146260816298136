import { flatten } from 'lodash';

export function variationClassBuilder(
    base: string,
    variations: string[] | string,
): string[] {
    return flatten([variations]).map(
        (variation: string) => `${base}--${variation}`,
    );
}
