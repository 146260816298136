@if (isDesktopView$ | async) {
    <div
        [fiVariationBase]="'fi-grid-table-header'"
        [fiVariation]="[reversed && 'reversed', centered && 'centered']"
        class="fi-grid-table-header"
    >
        @if (tpl) {
            <h4
                id="tableHeaderCell-{{ index }}"
                class="fi-heading fi-heading--quaternary fi-heading--dark"
            >
                <ng-container [ngTemplateOutlet]="tpl"></ng-container>
            </h4>
        } @else {}
        <ng-content></ng-content>
    </div>
}

@if (isMobileView$ | async) {
    @if (tpl) {
        <ng-container
            [ngTemplateOutlet]="tpl"
            [ngTemplateOutletContext]="{
                row: row,
            }"
        ></ng-container>
    } @else {}
}
