const PARSE_PHONE_CHARACTERS_REGEX =
    /(\()?(?:\d{1,3})?(\)\s?)?(?:\d{1,3})?(\s?-\s?)?(?:\d{1,4})?/;
const PARSE_PHONE_DIGITS_REGEX = /(\d{1,3})?(\d{1,3})?(\d{1,4})?/;

export const enum PhoneParsedGroup {
    OpenParenthesis = 'openParenthesis',
    Area = 'area',
    CloseParenthesis = 'closeParenthesis',
    FirstDigitsPart = 'firstDigitsPart',
    Hyphen = 'hyphen',
    LastDigitsPart = 'lastDigitsPart',
    Raw = 'raw',
}

export type PhoneParsedModel = Map<PhoneParsedGroup, string>;

export function parsePhoneValueToGroups(value = ''): PhoneParsedModel {
    const digitsValue = value.replace(/[^0-9]/g, '');

    const matchedCharacters = value.match(PARSE_PHONE_CHARACTERS_REGEX);
    const matchedDigits = digitsValue.match(PARSE_PHONE_DIGITS_REGEX);

    return new Map([
        [PhoneParsedGroup.OpenParenthesis, matchedCharacters[1] || ''],
        [PhoneParsedGroup.Area, matchedDigits[1] || ''],
        [PhoneParsedGroup.CloseParenthesis, matchedCharacters[2] || ''],
        [PhoneParsedGroup.FirstDigitsPart, matchedDigits[2] || ''],
        [PhoneParsedGroup.Hyphen, matchedCharacters[3] || ''],
        [PhoneParsedGroup.LastDigitsPart, matchedDigits[3] || ''],
        [PhoneParsedGroup.Raw, value || ''],
    ]);
}

export function transformPhoneValue(
    value: string,
    isValueDeleted = false,
): string {
    if (!value) {
        return '';
    }

    const parsedValue = parsePhoneValueToGroups(value);

    const openParenthesis = getOpenParenthesis(parsedValue);
    const closeParenthesis = getCloseParenthesis(parsedValue, isValueDeleted);
    const hyphen = getHyphen(parsedValue, isValueDeleted);
    const area = parsedValue.get(PhoneParsedGroup.Area);
    const firstDigitsPart = parsedValue.get(PhoneParsedGroup.FirstDigitsPart);
    const lastDigitsPart = parsedValue.get(PhoneParsedGroup.LastDigitsPart);

    if (!area && isValueDeleted) {
        return '';
    }

    const outputValue = [
        openParenthesis,
        area,
        closeParenthesis,
        firstDigitsPart,
        hyphen,
        lastDigitsPart,
    ].join('');

    return outputValue;
}

function getOpenParenthesis(value: PhoneParsedModel): string {
    return !value.get(PhoneParsedGroup.OpenParenthesis) &&
        value.get(PhoneParsedGroup.Area)
        ? '('
        : value.get(PhoneParsedGroup.OpenParenthesis);
}

function getCloseParenthesis(
    value: PhoneParsedModel,
    isValueDeleted: boolean,
): string {
    const characterRegex = /\)\s?/;
    const characterValue = ') ';

    if (
        value.get(PhoneParsedGroup.Area).length < 3 ||
        (!value.get(PhoneParsedGroup.FirstDigitsPart) && isValueDeleted)
    ) {
        return '';
    }

    if (
        !value.get(PhoneParsedGroup.CloseParenthesis) &&
        (value.get(PhoneParsedGroup.FirstDigitsPart) || !isValueDeleted)
    ) {
        return characterValue;
    }

    // handle whitespace after the ')' with replace
    return value
        .get(PhoneParsedGroup.CloseParenthesis)
        .replace(characterRegex, characterValue);
}

function getHyphen(value: PhoneParsedModel, isValueDeleted: boolean): string {
    const characterRegex = /\s?-\s?/;
    const characterValue = ' - ';

    if (
        value.get(PhoneParsedGroup.Area).length < 3 ||
        value.get(PhoneParsedGroup.FirstDigitsPart).length < 3 ||
        (!value.get(PhoneParsedGroup.LastDigitsPart) && isValueDeleted)
    ) {
        return '';
    }

    if (
        !value.get(PhoneParsedGroup.Hyphen) &&
        (value.get(PhoneParsedGroup.LastDigitsPart) || !isValueDeleted)
    ) {
        return characterValue;
    }

    // handle whitespaces around the '-' with replace
    return value
        .get(PhoneParsedGroup.Hyphen)
        .replace(characterRegex, characterValue);
}
