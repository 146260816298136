<div [fiVariation]="classList" [fiVariationBase]="'fi-search'">
    <div class="fi-search__input-wrapper">
        <input
            #inputElement
            [attr.maxlength]="maxLength"
            [attr.aria-label]="ariaLabel || 'Search'"
            [type]="type"
            [attr.name]="name"
            [ngModel]="value"
            [autofocus]="!skipAutofocus"
            [tabIndex]="skipAutofocus ? -1 : 0"
            (ngModelChange)="handleChange($event)"
            (keydown.enter)="handleEnterKeyDown()"
            id="fiSearchInput"
            class="fi-search__input"
            autocomplete="new-password"
            fiSearchTrimOnPaste
            required
        />
        <div class="fi-search__placeholder">{{ placeholder }}</div>
    </div>
    <div class="fi-search__icon-container">
        <div class="fi-search__icon-container-inner">
            <fi-icon
                [svgName]="loading ? 'temp-spinner' : 'search'"
                [size]="'large'"
                [variation]="'regular'"
                [class.fi-search__search-icon--loading]="loading"
                (mousedown)="handleSearchClick()"
                id="fiSearchIcon"
                class="fi-search__search-icon"
            ></fi-icon>
        </div>
        <span #clearButton>
            <fi-icon
                [svgName]="'times'"
                class="fi-search__clear-icon"
            ></fi-icon>
        </span>
    </div>
</div>

@if (isFocused && !isSearchOverlayOpened && (nonEmptySuggestions?.length || options?.length)) {
    <div class="fi-search__autocomplete-wrapper">
        <ng-container *ngTemplateOutlet="autocompleteOptions"></ng-container>
    </div>
}

<fi-modal
    [(open)]="isSearchOverlayOpened"
    [variation]="modalVariation">
    @if (isSearchOverlayOpened) {
        <fi-modal-close-bar
            [closeIcon]="'angle-left'"
            [title]="'Search'"
            (close)="closeModal()"
        ></fi-modal-close-bar>

        <fi-modal-content>
            <div
                [fiVariation]="classList"
                [fiVariationBase]="'fi-search'"
                class="fi-search__modal"
            >
                <div class="fi-search__modal-header">
                    <div class="fi-search__input-wrapper">
                        <input
                            required
                            #modalInput
                            [attr.name]="name"
                            [ngModel]="value"
                            [autofocus]="!skipAutofocus"
                            (ngModelChange)="handleChange($event)"
                            class="fi-search__input"
                            autocomplete="off"
                            maxLength="maxLength"
                        />

                        <div class="fi-search__placeholder">
                            {{ placeholder }}
                        </div>

                        <div class="fi-search__icon-container">
                            <div class="fi-search__icon-container-inner">
                                <fi-icon
                                    [svgName]="
                                        loading ? 'temp-spinner' : 'search'
                                    "
                                    [size]="'large'"
                                    [variation]="'regular'"
                                    [class.fi-search__search-icon--loading]="loading"
                                    (mousedown)="handleSearchClick()"
                                    class="fi-search__search-icon"
                                ></fi-icon>
                            </div>

                            <fi-icon
                                [svgName]="'times'"
                                (mousedown)="handleClear(false)"
                                class="fi-search__clear-icon"
                            ></fi-icon>
                        </div>
                    </div>
                </div>

                <div class="fi-search__modal-autocomplete">
                    <ng-container
                        [ngTemplateOutlet]="autocompleteOptions"
                    ></ng-container>
                </div>
            </div>
        </fi-modal-content>
    }
</fi-modal>

<ng-template #autocompleteOptions>
    @if (!options?.length && suggestionsSettings?.placeholder) {
        <div
            class="fi-text fi-search__suggestions-placeholder"
        >
            {{ suggestionsSettings.placeholder }}
        </div>
    }

    <fi-search-autocomplete-list
        [value]="value"
        [isArrowKeyDown$]="isArrowKeyDown$"
        [variation]="
            [optionsSettings, []] | memoizeFunc: getAutocompleteVariation
        "
        [options]="options"
        [settings]="optionsSettings"
        [header]="
            [optionsSettings, 'Search For'] | memoizeFunc: getAutocompleteHeader
        "
        [suggestionsHeader]="
            [suggestionsSettings, 'Recent Searches']
                | memoizeFunc: getAutocompleteHeader
        "
        [suggestionsVariation]="
            [suggestionsSettings, nonEmptySuggestions]
                | memoizeFunc: getAutocompleteVariation
        "
        [suggestions]="nonEmptySuggestions"
        [suggestionsSettings]="suggestionsSettings"
        [highlightTextMatch]="true"
        [baseItemClass]="baseItemClass"
        (activeOptionChange)="setActiveOption($event)"
        (selectOption)="handleSelectOption($event)"
    ></fi-search-autocomplete-list>
</ng-template>
