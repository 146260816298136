import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { NgClass } from '@angular/common';

import { IconComponent } from '../icon/icon.component';

const defaultLoaderConfig = {
    letterSize: 'xx-large',
    innerCircleSize: '42x',
    outerCircleSize: '55x',
};

const largeLoaderConfig = {
    letterSize: '85x',
    innerCircleSize: '130x',
    outerCircleSize: '160x',
};

interface LoaderSizeConfig {
    letterSize: string;
    innerCircleSize: string;
    outerCircleSize: string;
}

@Component({
    selector: 'fi-loader-penske',
    templateUrl: './loader-penske.component.html',
    styleUrls: ['./loader-penske.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgClass, IconComponent],
})
export class LoaderPenskeComponent implements OnInit {
    @Input() isCentered = false;
    @Input() large = false;
    @Input() loaderText: string;
    @Input() noBackground = false;

    loaderSizeConfig: LoaderSizeConfig;

    ngOnInit(): void {
        this.loaderSizeConfig = this.large
            ? largeLoaderConfig
            : defaultLoaderConfig;
    }
}
