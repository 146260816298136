import { KeyValuePipe } from '@angular/common';
import { NgModule, Type } from '@angular/core';

import { GridComponent } from './grid.component';
import {
    GridTableComponent,
    GridTableScrollControlsComponent,
    GridScrollingContainerDirective,
    GridTableLoaderSkeletonComponent,
} from './table';
import {
    GridHeaderCellComponent,
    GridHeaderCellDefDirective,
} from './header-cell';
import { GridCellComponent, GridCellDefDirective } from './cell';
import {
    GridListComponent,
    GridListLoaderSkeletonComponent,
    GridListSkeletonComponent,
} from './list';
import { GridSearchComponent } from './search/search.component';
import { GridHeaderPanelComponent } from './header-panel/header-panel.component';
import { GridSubHeaderPanelComponent } from './sub-header-panel/sub-header-panel.component';
import { GridMainPanelComponent } from './main-panel/main-panel.component';
import { GridFooterPanelComponent } from './footer-panel/footer-panel.component';
import { GridSubFilterPanelComponent } from './sub-filter-panel/sub-filter-panel.component';
import { GridAdditionalPanelComponent } from './additional-panel/additional-panel.component';
import {
    GridDataErrorPlaceholderDefDirective,
    GridNoDataPlaceholderDefDirective,
    GridNoDataIconPlaceholderDefDirective,
    GridNoNestedDataPlaceholderDefDirective,
} from './no-data-placeholder';
import { GridResultSummaryComponent } from './result-summary/result-summary.component';
import {
    GridDownloadReportComponent,
    GridDownloadReportHeaderComponent,
    GridDownloadReportFormatsComponent,
    GridDownloadAllColumnsComponent,
} from './download-report';
import { GridColumnDefDirective } from './column/column-def.directive';
import {
    GridSortDirective,
    GridColumnSortDirective,
    GridSortPickerComponent,
    GridSortIconComponent,
} from './sort';
import {
    TableStickyStyler,
    GridStickyHeaderService,
    GridOnTabletService,
} from './services';
import { GridDataSourceDirective } from './data-source/data-source.directive';
import {
    GridRowSelectorDirective,
    GridRowSelectorComponent,
} from './row-selector';
import { GridPaginatorComponent } from './paginator/paginator.component';
import { GridGroupDefDirective } from './group/group-def.directive';
import { GridGroupRowsPipe } from './pipes/group-rows.pipe';
import { GridSecondaryControlComponent } from './secondary-control/grid-secondary-control.component';
import {
    GridExpandedCellDefDirective,
    GridExpandIconComponent,
    GridExpandingRowDirective,
    GridExpandFolderComponent,
    GridExpandDirective,
    GridNestedDataSourceDirective,
    GridNestedRowPaginatorComponent,
} from './expanded';
import {
    ManageColumnsMenuComponent,
    ManageColumnsModalComponent,
} from './manage-columns';
import { GridCustomConfigDirective } from './custom-config/custom-grid-config.directive';
import {
    GridNestedRowSelectorDirective,
    GridNestedRowSelectorComponent,
} from './nested-row-selector';
import { GridSelectOptionComponent } from './filters/select-option/select-option.component';
import { GridOnTabletDirective } from './on-tablet/on-tablet.directive';
import { GridMoreActionsComponent } from './more-actions/more-actions.component';
import { GridAppliedSearchComponent } from './applied-search/applied-search.component';
import { GridFilterSelectComponent } from './filter-select/filter-select.component';
import { GridCustomContentComponent } from './custom-content/custom-content.component';

const FEATURES_STANDALONE: Array<Type<any> | any[]> = [
    GridComponent,
    GridTableScrollControlsComponent,
    GridListSkeletonComponent,
    GridOnTabletDirective,
    GridHeaderCellDefDirective,
    GridColumnSortDirective,
    GridExpandDirective,
    GridSortDirective,
    GridTableScrollControlsComponent,
    GridDataSourceDirective,
    GridMainPanelComponent,
    GridColumnDefDirective,
    GridSubFilterPanelComponent,
    GridFooterPanelComponent,
    GridResultSummaryComponent,
    GridFilterSelectComponent,
    GridCellDefDirective,
    GridAppliedSearchComponent,
    GridAdditionalPanelComponent,
    GridCustomContentComponent,
    GridDownloadReportComponent,
    GridDownloadReportHeaderComponent,
    GridDownloadAllColumnsComponent,
    GridDownloadReportFormatsComponent,
    GridNestedRowSelectorDirective,
    GridNestedRowSelectorComponent,
    GridMoreActionsComponent,
    ManageColumnsMenuComponent,
    GridExpandedCellDefDirective,
    GridExpandFolderComponent,
    GridSecondaryControlComponent,
    GridSubHeaderPanelComponent,
    GridHeaderPanelComponent,
    GridRowSelectorComponent,
    GridSearchComponent,
    GridDataErrorPlaceholderDefDirective,
    GridNoDataPlaceholderDefDirective,
    GridNoDataIconPlaceholderDefDirective,
    GridRowSelectorDirective,
    GridGroupDefDirective,
    GridNestedDataSourceDirective,
    GridNoNestedDataPlaceholderDefDirective,
    GridCustomConfigDirective,
    GridSelectOptionComponent,
];

const ISOLATED_FEATURES_STANDALONE: Array<Type<any> | any[]> = [
    GridTableComponent,
    GridTableLoaderSkeletonComponent,
    GridListLoaderSkeletonComponent,
    GridListComponent,
    GridPaginatorComponent,
    GridGroupRowsPipe,
    GridExpandingRowDirective,
    GridSortIconComponent,
    GridExpandIconComponent,
    GridNestedRowPaginatorComponent,
    GridHeaderCellComponent,
    GridCellComponent,
    GridSortPickerComponent,
    ManageColumnsModalComponent,
    GridScrollingContainerDirective,
];

@NgModule({
    imports: [...FEATURES_STANDALONE, ...ISOLATED_FEATURES_STANDALONE],
    exports: [...FEATURES_STANDALONE],
    providers: [
        TableStickyStyler,
        GridStickyHeaderService,
        KeyValuePipe,
        GridOnTabletService,
    ],
})
export class GridModule {}
