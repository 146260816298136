import {
    AfterContentInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ContentChildren,
    ElementRef,
    Input,
    OnInit,
    Optional,
    QueryList,
    Self,
    TemplateRef,
    ViewChild,
    Type,
} from '@angular/core';
import { NgTemplateOutlet, AsyncPipe } from '@angular/common';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { isEmpty, some, sortBy, filter as lodashFilter } from 'lodash';
import { combineLatest, Observable, MonoTypeOperatorFunction } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';

import { EnvironmentService, BodyService } from '../../services';
import { VariationDirective } from '../../directives';
import {
    IconComponent,
    LoaderPenskeComponent,
    SkeletonComponent,
    SkeletonElementComponent,
} from '../../components';
import { GridOnTabletDirective } from '../grid/on-tablet/on-tablet.directive';

import { GridOnTabletService } from './services/grid-on-tablet.service';
import {
    GridDataErrorPlaceholderDefDirective,
    GridNoDataPlaceholderDefDirective,
    GridNoNestedDataPlaceholderDefDirective,
    GridNoDataIconPlaceholderDefDirective,
} from './no-data-placeholder';
import { GridColumnDefDirective } from './column/column-def.directive';
import { GridTableScrollService } from './services/table-scroll.service';
import { GridDataSourceDirective } from './data-source/data-source.directive';
import { GridGroupDefDirective } from './group/group-def.directive';
import { GridColumnListConfiguration, GridConfiguration } from './store/models';
import {
    GridTableComponent,
    GridTableVariation,
} from './table/table.component';
import { GridListComponent } from './list/list.component';
import { GridPaginatorComponent } from './paginator/paginator.component';

export enum GridVariation {
    WithoutBorder = 'without-border',
    BorderOnTablet = 'border-on-tablet',
}

@UntilDestroy()
@Component({
    selector: 'fi-grid',
    templateUrl: './grid.component.html',
    styleUrls: ['./grid.component.scss'],
    providers: [GridTableScrollService, GridOnTabletService],
    exportAs: 'fiGrid',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AsyncPipe,
        NgTemplateOutlet,
        VariationDirective,
        GridTableComponent,
        GridListComponent,
        LoaderPenskeComponent,
        SkeletonComponent,
        SkeletonElementComponent,
        GridPaginatorComponent,
        IconComponent,
    ],
})
export class GridComponent implements OnInit, AfterContentInit {
    @Input() variation: GridVariation;
    @Input() tableVariation: GridTableVariation;
    @Input() isFooterShown = true;
    @Input() notPersistentPaginator: boolean;
    @Input() scrollTopOnPaginationChange: boolean;
    @Input() showCustomTemplate: boolean;
    @Input() guidedTourMobileStep: Record<string, string | number>;
    @Input() customTemplate: Type<any>;

    @Input('fiGridSettings')
    readonly gridSettings: Observable<GridConfiguration>;

    @ContentChildren(GridColumnDefDirective)
    private columnsQueryList: QueryList<GridColumnDefDirective>;

    @ContentChild(GridGroupDefDirective)
    groupBy: GridGroupDefDirective;

    @ContentChild(GridNoDataPlaceholderDefDirective, {
        read: TemplateRef,
    })
    noDataPlaceholderDef: TemplateRef<GridNoDataPlaceholderDefDirective>;

    @ContentChild(GridDataErrorPlaceholderDefDirective, {
        read: TemplateRef,
    })
    dataErrorPlaceholderDef: TemplateRef<GridDataErrorPlaceholderDefDirective>;

    @ContentChild(GridNoDataIconPlaceholderDefDirective, {
        read: TemplateRef,
    })
    noDataIconPlaceholderDef: TemplateRef<GridNoDataIconPlaceholderDefDirective>;

    @ContentChild(GridNoNestedDataPlaceholderDefDirective, {
        read: TemplateRef,
    })
    noNestedDataPlaceholderDef: TemplateRef<GridNoNestedDataPlaceholderDefDirective>;

    @ViewChild('tableWrapperRef', {
        read: ElementRef,
    })
    tableWrapperRef: ElementRef<HTMLElement>;

    @ViewChild('gridTableRef', { read: ElementRef }) set preventScrolling(
        gridTableRef: ElementRef,
    ) {
        gridTableRef &&
            gridTableRef.nativeElement.addEventListener('scroll', (event) =>
                event.target.scrollTo(0, 0),
            );
    }

    isMobileView$: Observable<boolean>;
    isDesktopView$: Observable<boolean>;

    isInitializing = true;
    isLoading = false;
    isLoadingError;
    isDataPresent = false;
    hasLoaderInNoData = false;
    hasExpandingRows = false;

    columns$: Observable<GridColumnDefDirective[]>;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private environmentService: EnvironmentService,
        @Self() public tableScroll: GridTableScrollService,
        @Optional() private dataSource: GridDataSourceDirective,
        private bodyService: BodyService,
        @Optional() private gridOnTablet: GridOnTabletDirective,
        private gridOnTabletService: GridOnTabletService,
    ) {
        this.gridOnTabletService.setViewVariations(this.gridOnTablet);

        this.isMobileView$ = this.gridOnTabletService.isMobileView$;
        this.isDesktopView$ = this.gridOnTabletService.isDesktopView$;
    }

    ngOnInit(): void {
        if (!this.dataSource) {
            return;
        }

        this.dataSource.stateChanged$
            .pipe(untilDestroyed(this))
            .subscribe(() => this.updateState());

        this.updateState();
    }

    ngAfterContentInit(): void {
        if (!this.gridSettings) {
            this.columns$ = this.columnsQueryList.changes.pipe(
                startWith(this.columnsQueryList),
                map((columns) => [...columns]),
                this.handleExpandedRowsCalc(),
            );
            return;
        }

        this.handleDynamicColumns();
    }

    pageNumberChange(): void {
        if (this.scrollTopOnPaginationChange) {
            this.tableContentScrollTop();
        }
    }

    private updateState(): void {
        const {
            source: { data },
            isInitializing,
            isLoading,
            isLoadingError,
        } = this.dataSource;
        this.isInitializing = isInitializing;
        this.isLoading = isLoading;
        this.isLoadingError = isLoadingError;
        this.isDataPresent = !isEmpty(data);
        this.hasLoaderInNoData =
            !this.isDataPresent && !this.isInitializing && this.isLoading;

        this.changeDetectorRef.markForCheck();
    }

    private handleExpandedRowsCalc(): MonoTypeOperatorFunction<
        GridColumnDefDirective[]
    > {
        return tap(
            (columns) =>
                (this.hasExpandingRows = some(
                    columns,
                    (column: GridColumnDefDirective) => column.expander,
                )),
        );
    }

    private handleDynamicColumns(): void {
        const columnsQuery$ = this.columnsQueryList.changes.pipe(
            startWith(this.columnsQueryList),
        );

        this.columns$ = combineLatest(
            columnsQuery$,
            this.gridSettings,
            this.isMobileView$,
        ).pipe(
            map(([columns, settings, isMobile]) => {
                const columnsList = [...columns];

                if (!settings) {
                    // in case of problems with user service
                    return columnsList;
                }

                const { columns: columnsSettings } = settings;

                if (!columnsSettings || isMobile) {
                    return columnsList;
                }

                const shownColumns = this.filterHiddenColumns(
                    columnsList,
                    columnsSettings,
                );

                return this.sortColumnsByOrder(shownColumns, columnsSettings);
            }),
            this.handleExpandedRowsCalc(),
        );
    }

    private filterHiddenColumns(
        columnsList: GridColumnDefDirective[],
        columnsSettings: GridColumnListConfiguration,
    ): GridColumnDefDirective[] {
        return lodashFilter(columnsList, ({ field }) => {
            const columnSetting = columnsSettings[field];
            return columnSetting ? !columnSetting.isHidden : true;
        });
    }

    private sortColumnsByOrder(
        shownColumns: GridColumnDefDirective[],
        columnsSettings: GridColumnListConfiguration,
    ): GridColumnDefDirective[] {
        return sortBy(shownColumns, [
            ({ field }: GridColumnDefDirective) => {
                const columnSetting = columnsSettings[field];
                return columnSetting && columnSetting.order;
            },
        ]);
    }

    private tableContentScrollTop(): void {
        setTimeout(() => {
            const { top } =
                this.tableWrapperRef.nativeElement.getBoundingClientRect();
            const distanceScrolled = window.scrollY;
            const offsetTop = top + distanceScrolled;

            this.bodyService.scrollTop(offsetTop);
        }, 500);
    }
}
