import {
    Component,
    ChangeDetectionStrategy,
    Input,
    OnInit,
    inject,
} from '@angular/core';
import { AsyncPipe } from '@angular/common';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent, Subscription } from 'rxjs';
import { isFunction } from 'lodash';

import { OnChange } from '../../decorators';
import { AnalyticsModal } from '../../analytics';

import {
    LeaveConfirmationModalComponent,
    LeaveConfirmationModalConfig
} from './leave-confirmation-modal/leave-confirmation-modal.component';
import { DEFAULT_LEAVE_MODAL_CONFIG } from './config/prevent-leave-modal.config';
import { PreventLeaveModalService, PreventLeaveService } from './services';

@UntilDestroy()
@Component({
    selector: 'prevent-leave-modal',
    templateUrl: './prevent-leave-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AsyncPipe,
        LeaveConfirmationModalComponent,
    ]
})
export class PreventLeaveModalComponent implements OnInit {
    private readonly preventLeaveModalService = inject(PreventLeaveModalService);
    private readonly preventLeaveService = inject(PreventLeaveService);

    @Input()
    modalConfig: LeaveConfirmationModalConfig;
    @Input() gtmClassName: AnalyticsModal;
    @Input() canLeave: boolean;
    @Input() skipReload: boolean;

    @OnChange('checkSecondaryLeave')
    @Input()
    secondaryLeaveTrigger: () => void;

    @Input()
    secondaryConfirmLeaveCallback: () => void;

    readonly isOpenModal$ = this.preventLeaveModalService.modalOpened$;

    private confirmLeaveCallback: () => void;
    private subscription: Subscription;

    ngOnInit(): void {
        this.handleReloadListeners();
        this.modalConfig = this.modalConfig || DEFAULT_LEAVE_MODAL_CONFIG;

        this.preventLeaveModalService.leavePageSelection$.subscribe(left => {
            if (left) {
                this.subscription?.unsubscribe();
            }
        })
    }

    checkSecondaryLeave(): void {
        if (!this.secondaryLeaveTrigger) {
            return;
        }

        this.preventLeaveService.checkSecondaryLeave(
            () => this.canLeave,
            (goOnHandler) => {
                this.confirmLeaveCallback = goOnHandler;
                this.preventLeaveModalService.openModal();
            },
            this.secondaryLeaveTrigger,
        );
    }

    closeModal(isLeaving: boolean): void {
        if (isLeaving) {
            this.preventLeaveModalService.closeModal();
        } else {
            this.secondaryConfirmLeaveCallback = null;
            this.confirmLeaveCallback = null;
            this.preventLeaveModalService.handleAction(false);
        }
    }

    confirmLeavePage(): void {
        const { confirmLeaveCallback, secondaryConfirmLeaveCallback } = this;

        if (isFunction(confirmLeaveCallback)) {
            setTimeout(confirmLeaveCallback);
        }

        if (isFunction(secondaryConfirmLeaveCallback)) {
            setTimeout(secondaryConfirmLeaveCallback);
        }

        this.preventLeaveModalService.handleAction(true);
    }

    private handleReloadListeners(): void {
        if (!this.skipReload) {
            this.subscription = this.preventLeaveService
                .handleReloadListener(() => this.canLeave)
                .pipe(untilDestroyed(this))
                .subscribe();

            fromEvent(window, 'unload')
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    this.confirmLeavePage();
                });
        }
    }
}
