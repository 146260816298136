import { parseEmailValueToGroups, EmailParsedGroup } from '../../../../utils';

import {
    FiInputMaskBase,
    FiInputMaskValue,
    getEmptyMaskValue,
    FiInputCharacterMaskValue,
    FiInputMaskKey,
} from './input-mask-base';

export class EmailInputMask extends FiInputMaskBase {
    getValue(value: string): FiInputMaskValue {
        const parsedValue = parseEmailValueToGroups(value);
        const maskValue = getEmptyMaskValue();

        parsedValue.forEach((groupValue, groupKey) => {
            switch (groupKey) {
                case EmailParsedGroup.At:
                case EmailParsedGroup.Dot: {
                    const { maskKey, character } = this.getEmailCharacter(
                        groupKey,
                        groupValue,
                    );
                    maskValue[maskKey] += character;

                    break;
                }

                case EmailParsedGroup.Name:
                case EmailParsedGroup.DomainName:
                case EmailParsedGroup.DomainArea: {
                    const { filled, placeholder } =
                        this.getFilledRangeWithPlaceholder(
                            groupValue || '',
                            groupValue ? 0 : 3,
                        );

                    maskValue.filled += filled;
                    maskValue.placeholder += placeholder;

                    break;
                }
            }
        });

        return maskValue;
    }

    private getEmailCharacter(
        groupKey: EmailParsedGroup,
        groupValue: string,
    ): FiInputCharacterMaskValue {
        const maskKey = groupValue
            ? FiInputMaskKey.Filled
            : FiInputMaskKey.Placeholder;
        let character: string;

        switch (groupKey) {
            case EmailParsedGroup.At:
                character = groupValue ? '@' : ' @ ';
                break;

            case EmailParsedGroup.Dot:
                character = groupValue ? '.' : ' . ';
                break;
        }

        return {
            maskKey,
            character,
        };
    }
}
