<div #container>
    <fi-button
        [ariaLabel]="label"
        [variation]="'secondary,no-horizontal-padding,block,full-width-content'"
        [active]="isOpen"
        (click)="toggleDropdown()"
        class="time-range-dropdown-btn"
    >
        <div class="time-range-dropdown-btn__content">
            <fi-icon [svgName]="'clock'" [variation]="'regular'"></fi-icon>

            <span class="fi-text fi-text--medium">{{ label }}</span>

            <fi-icon
                [svgName]="isOpen ? 'caret-up' : 'caret-down'"
                [variation]="'solid'"
                class="time-range-dropdown-btn__content-caret"
            ></fi-icon>
        </div>
    </fi-button>

    <ng-template *fiIsDesktopDevice #dropdown>
        <ng-container *ngTemplateOutlet="dropdownTpl"></ng-container>
    </ng-template>
</div>

<ng-container *fiIsMobileDevice>
    <fi-modal
        [open]="isOpen"
        [variation]="modalVariation"
        (event)="handleModalEvent($event)"
    >
        <fi-modal-close-bar
            [title]="'Select Time'"
            (close)="closeModal()"
        ></fi-modal-close-bar>

        <ng-container *ngTemplateOutlet="timePickerTpl"></ng-container>
    </fi-modal>
</ng-container>

<ng-template #dropdownTpl>
    <div #dropdownContent class="time-range-dropdown">
        <ng-container *ngTemplateOutlet="timePickerTpl"></ng-container>
    </div>
</ng-template>

<ng-template #timePickerTpl>
    <div class="time-range-dropdown__header">
        <h3
            class="fi-text fi-text--responsive-book fi-text--medium fi-text--dark"
        >
            Time Zone Reflected
        </h3>

        <span class="fi-text fi-text--responsive-book fi-text--medium">
            {{ timeZoneName }}
        </span>
    </div>

    <div class="time-range-dropdown__main">
        <div class="time-range-dropdown__item">
            <span
                class="fi-text fi-text--responsive-book fi-text--medium fi-text--dark"
            >
                {{ START_TIME }}
            </span>

            <fi-time
                [title]="START_TIME"
                [noCloseButton]="false"
                [submitButton]="SUBMIT_BUTTON"
                [(value)]="startTime"
                (valueChange)="startTimeChange()"
            ></fi-time>
        </div>

        <div
            [class.time-range-dropdown__item--with-error]="showTimeValidation"
            class="time-range-dropdown__item"
        >
            <span
                class="fi-text fi-text--responsive-book fi-text--medium fi-text--dark"
            >
                {{ END_TIME }}
            </span>

            <fi-time
                [title]="END_TIME"
                [noCloseButton]="false"
                [submitButton]="SUBMIT_BUTTON"
                [(value)]="endTime"
                (valueChange)="endTimeChange()"
            ></fi-time>
        </div>

        @if (showTimeValidation) {
            <div
                class="time-range-dropdown__error-message fi-text fi-text--small fi-text--error"
            >
                Error: {{ errorMessage }}
            </div>
        }
    </div>

    <div class="time-range-dropdown__footer">
        <fi-button
            [ariaLabel]="'Reset'"
            [variation]="'secondary,block'"
            [class.time-range-dropdown__reset-btn]="isMobileDevice$ | async"
            (click)="clearTime()"
            class="time-range-dropdown__btn"
        >
            Reset
        </fi-button>

        <fi-button
            [ariaLabel]="'Apply'"
            [variation]="'primary,block'"
            (click)="handleTimeApply()"
            class="time-range-dropdown__btn"
        >
            Apply
        </fi-button>
    </div>
</ng-template>
