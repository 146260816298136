import { Directive, ElementRef, HostListener, inject } from '@angular/core';

import { fromEvent, Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';

@Directive({
    selector: '[fiContextMenuItemContainer]',
    standalone: true,
})
export class ContextMenuItemContainerDirective {
    elementRef = inject(ElementRef);

    click$: Observable<null> = fromEvent(
        this.elementRef.nativeElement,
        'mousedown',
    ).pipe(mapTo(null));

    @HostListener('mousedown', ['$event'])
    onMouseDown(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();

        const triggeredEvent = new MouseEvent('click', { bubbles: true });
        this.elementRef.nativeElement.dispatchEvent(triggeredEvent);
    }
}
