import { InjectionToken } from '@angular/core';

import { CONFIG } from '../config'; // todo: decide where to place it eventually

export interface ConfigForms {
    debounce: number;
    dateFormat: string;
    dateStringFormat: string;
    timeStringFormat: string;
    timeStringFullFormat: string;
    preciseTimeStringFormat: string;
    emailRegex: RegExp;
    phoneRegex: RegExp;
    wordsRegex: RegExp;
    dotNumberRegex: RegExp;
    alphanumericHyphenRegex: RegExp;
    emailListRegex: RegExp;
    delimiters: RegExp;
}

export interface TimeFormatConfig {
    FULL: string;
    SHORT_DATE: string;
    SHORTEST_DATE: string;
    DAY_SHORT_DATE: string;
    AMPM: string;
    SHORT_AMPM: string;
    MONTH_FULL: string;
    MONTH_DAY_YEAR: string;
    DAY_MONTH_YEAR: string;
    MONTH_DAY_HOURS: string;
    HOURS_MONTH_DAY: string;
    READABLE_DAY: string;
}

export interface ConfigApp {
    API_VERSION: string;
    PUBLIC_API_VERSION: string;
    forms: ConfigForms;
    PUBLIC_STATE: string;
    WEEKEND: number[];
    NAME_SEPARATOR: string;
    LIST_SEPARATOR: string;
    EMPTY_VALUE: string;
    MULTILINE_STRING_SEPARATOR: string;
    TIME_FORMAT: TimeFormatConfig;
    API_REQUEST_DATE_FORMAT: string;
    API_REQUEST_FULL_DATE_FORMAT: string;
    API_MAX_DATE_VALUE: string;
    TABLE_PAGE_SIZE: number;
    MODAL_TABLE_PAGE_SIZE: number;
    LOCATION_FINDER_NORTH_AMERICA_CENTER_ZOOM: number;
    SERVICE_TYPE_PRIORITIES: string[];
    NO_MOST_VIEWED: number;
    // todo: remove, use HttpKnownErrorMessage
    CUSTOM_HTTP_ERROR_MESSAGES: {
        MAINTENANCE_RENTAL_HISTORY: string;
    };
    // optional from ext-config
    DEFAULT_COUNTRY?: string;
    API_REQUEST_SIGNATURE?: string;
    MAP_KEY?: string;
    SITE_MINDER_COOKIE?: string;
    SMAUTHREASON?: number;

    LOGISTIC_LOGIN_URL?: string;

    RPT_MGR_API_URL?: string;
    SALESFORCE_URL?: string;
    DRIVER_MANAGEMENT_URL?: string;
    DRIVER_API_VERSION?: string;
    RENTAL_API_VERSION?: string;

    CUST_AGREE_API?: string;
    CUST_AGREE_API_PUB?: string;

    LOGIN_URL?: string;
    LOGOUT_URL?: string;

    'X-IBM-CLIENT-ID'?: string;
    'X-PNSK-CLIENT-ID'?: string;

    PHONE_NUMBERS: {
        SUPPORT: string;
        ROAD_ASSISTANCE: string;
        CUSTOMER_SERVICE: string;
    };
    PHONE_NUMBER_LENGTH: number;

    ODOMETER_HOURS_LENGTH: number;

    ENV: string;

    WHATS_NEW: {
        SESSION_DELAY_IN_SECONDS: number;
    };

    LEGALIZATION_SERVICES_URL?: string;
    SAFETY_BULLETINS_URL?: string;

    EMAIL_PFM: string;
    DOT_CRITICAL_REPAIR: string;

    WITH_MOCK_DATA?: boolean;

    MESSAGE_TIMEOUT?: number;
}

export const ConfigAppToken = new InjectionToken<ConfigApp>('CONFIG', {
    factory: () => CONFIG,
});
