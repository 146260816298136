import {
    AfterContentInit,
    ContentChildren,
    Directive,
    EventEmitter,
    Input,
    NgIterable,
    Output,
    QueryList,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Subject } from 'rxjs';
import {
    cloneDeep,
    isEqual,
    xorWith,
    flatten,
    isArray,
    toPairs,
    find,
} from 'lodash';

import { OnChange } from '../../decorators';
import { ReportingPeriod, DateRange } from '../../models';

import { FilteringItemDefDirective } from './item/item-def.directive';
import {
    AppliedFilter,
    FilteringState,
    FilteringViewState,
    FilteringCustomDateKeys,
} from './models';

@UntilDestroy()
@Directive({
    selector: '[fiFiltering]',
    exportAs: 'fiFiltering',
    standalone: true,
})
export class FilteringDirective implements AfterContentInit {
    // Used to notify child components listening to state changes.
    private readonly stateChanged = new Subject<void | boolean>(); // TODO ANGULAR: probably need to revert to boolean type and update 'next' calls

    @OnChange(function (
        this: FilteringDirective,
        { values, defaults }: FilteringState,
    ) {
        this.setFilteringState(values);
        this.setDefaultFilteringState(defaults);
        this.calculateAppliedFilters();

        // this need to update states which looking on update common state
        if (isEqual(values, this.state)) {
            this.stateChanged.next();
        }
    })
    @Input('fiFiltering')
    readonly commonState: FilteringState;
    @Input('fiFilteringCustomDateKeyNames')
    readonly customDateKeyNames: FilteringCustomDateKeys | undefined;
    @Input('fiFilteringDefaultOpen')
    readonly isFilterDefaultOpen: boolean;
    @Input('fiFilteringEmitFiltersUpdate')
    readonly shouldEmitFiltersUpdate: boolean;

    @Output()
    readonly fiFilteringChange = new EventEmitter<FilteringState['values']>();

    @Output()
    readonly fiFilteringUpdate = new EventEmitter<FilteringState['values']>();

    @Output()
    readonly fiFilteringReset = new EventEmitter<void>();

    @Output()
    readonly fiFilteringCancel = new EventEmitter<void>();

    @ContentChildren(FilteringItemDefDirective)
    readonly filterDefs: QueryList<FilteringItemDefDirective> &
        NgIterable<FilteringItemDefDirective>;

    state: FilteringState['values'];
    defaultState: FilteringState['defaults'];
    viewState: FilteringViewState = {};
    isFilterShow = false;
    isFilterAnimating = false;
    appliedFilters: AppliedFilter[] = [];

    stateChanged$ = this.stateChanged.asObservable().pipe(untilDestroyed(this));

    ngAfterContentInit(): void {
        this.handleDefaultOpen();

        this.filterDefs.changes.pipe(untilDestroyed(this)).subscribe(() => {
            this.calculateAppliedFilters();
            this.stateChanged.next();
        });

        this.calculateAppliedFilters();
    }

    resetFilters(): void {
        const { defaults } = this.commonState;
        this.setFilteringState(cloneDeep(defaults));
        this.fiFilteringReset.emit();
    }

    cancelFilters(): void {
        const { values } = this.commonState;

        this.isFilterShow = false;
        this.setFilteringState(cloneDeep(values));
        this.fiFilteringCancel.emit();
    }

    applyFilters(skipClosing = false): void {
        const values = this.customDateValidationRequired(this.state)
            ? this.getValidatedValues(cloneDeep(this.state))
            : this.state;

        if (!skipClosing) {
            this.isFilterShow = false;
        }
        this.fiFilteringChange.emit(values);
    }

    updateFilterValue(value: any, key: string): void {
        /**
         * We need to be able to update filter if value is empty string
         * In case filters have inputs inside
         */
        if (!key || value === null || value === undefined) {
            return;
        }

        this.setFilteringState({ [key]: value });
    }

    removeAppliedFilterValue(value: any, key: string): void {
        const { values, defaults } = this.commonState;

        const newFilterValue: any[] = xorWith(
            flatten([value]),
            flatten([values[key]]),
            isEqual,
        );

        if (!newFilterValue.length) {
            this.fiFilteringChange.emit({
                ...this.state,
                [key]: defaults[key],
            });
            return;
        }

        this.fiFilteringChange.emit({
            ...this.state,
            [key]: isArray(values[key])
                ? newFilterValue
                : flatten(newFilterValue),
        });
    }

    resetAppliedFilters(): void {
        const { defaults } = this.commonState;
        this.fiFilteringChange.emit(defaults);
    }

    toggleShowPanel(): void {
        this.isFilterShow = !this.isFilterShow;
        this.stateChanged.next(this.isFilterShow);
    }

    setPanelAnimatingState(animationState: boolean): void {
        this.isFilterAnimating = animationState;
    }

    updateViewValue(value: FilteringViewState): void {
        this.setViewState(value);
    }

    resetViewState(): void {
        this.setViewState(null);
    }

    private handleDefaultOpen(): void {
        if (this.isFilterDefaultOpen && !this.isFilterShow) {
            this.toggleShowPanel();
        }
    }

    private calculateAppliedFilters(): void {
        const { values, defaults } = this.commonState;

        const appliedFiltersBuff = toPairs(values)
            .filter(([key, value]) => !!value && !!this.getFilterDefByKey(key))
            .map(([key, value]) => {
                const readOnlyData = flatten([defaults[key]]);

                return flatten([value]).map((value) => ({
                    key,
                    value,
                    isReadonly: !!find(readOnlyData, value),
                }));
            });

        const appliedFilters = flatten(appliedFiltersBuff);

        if (!isEqual(this.appliedFilters, appliedFilters)) {
            this.appliedFilters = appliedFilters;
            this.stateChanged.next();
        }
    }

    setFilterOpened() {
        this.stateChanged.next(true);
    }

    private setFilteringState(values: FilteringState['values']): void {
        if (!values) {
            return;
        }

        this.state = {
            ...this.state,
            ...values,
        };

        this.stateChanged.next();

        if (this.shouldEmitFiltersUpdate) {
            const values = this.customDateValidationRequired(this.state)
                ? this.getValidatedValues(cloneDeep(this.state))
                : this.state;

            this.fiFilteringUpdate.emit(values);
        }
    }

    private setDefaultFilteringState(
        defaults: FilteringState['defaults'],
    ): void {
        this.defaultState = cloneDeep(defaults);
    }

    private getFilterDefByKey(key: string): FilteringItemDefDirective | null {
        if (!this.filterDefs) {
            return null;
        }

        return this.filterDefs.find((filterDef) => filterDef.key === key);
    }

    private setViewState(value: FilteringViewState | null): void {
        const updatedState = value
            ? {
                  ...this.viewState,
                  ...value,
              }
            : {};

        this.viewState = updatedState;
        this.stateChanged.next();
    }

    private customDateValidationRequired(
        values: FilteringState['values'],
    ): boolean {
        return (
            this.filteringHasCustomDate() &&
            this.customDatePeriodSelected(values)
        );
    }

    private filteringHasCustomDate(): boolean {
        return !!this.customDateKeyNames;
    }

    private customDatePeriodSelected(
        values: FilteringState['values'],
    ): boolean {
        return (
            values[this.customDateKeyNames.period].code ===
            ReportingPeriod.CustomDate
        );
    }

    private getValidatedValues(
        values: FilteringState['values'],
    ): FilteringState['values'] {
        const { period: periodKey, date: dateKey } = this.customDateKeyNames;
        const { values: previousValues } = this.commonState;

        const customDateValue: DateRange = values[dateKey];

        if (customDateValue.from && customDateValue.to) {
            return values;
        } else {
            return {
                ...values,
                [periodKey]: previousValues[periodKey],
                [dateKey]: previousValues[dateKey],
            };
        }
    }
}
