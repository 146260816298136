<div class="fi-date-range-custom-tabs">
    <div
        [class.fi-date-range-custom-tabs__item--highlighted]="
            highlighted === 'from'
        "
        [class.fi-date-range-custom-tabs__item--clickable]="!!tabs?.from"
        [class.fi-date-range-custom-tabs__item--single-day-selection]="
            !singleDaySelectionDisabled
        "
        (click)="changeTab(DateRangeSelectionMode.From)"
        class="fi-date-range-custom-tabs__item"
    >
        <span class="fi-text fi-text--medium fi-text--small">
            {{ fromTitle }}:
        </span>

        <span
            [ngClass]="tabs?.from ? 'fi-text--dark' : 'fi-text--light'"
            class="fi-text fi-text--medium"
        >
            {{ tabs.from | timeFormat: 'SHORTEST_DATE' : valuePlaceholder }}
        </span>
    </div>

    @if (singleDaySelectionDisabled) {
        <div
            [class.fi-date-range-custom-tabs__item--clickable]="!!tabs?.from"
            (click)="changeTab(DateRangeSelectionMode.To)"
            class="fi-date-range-custom-tabs__item"
        >
            <span class="fi-text fi-text--medium fi-text--small">
                {{ toTitle }}:
            </span>

            <span
                [ngClass]="tabs?.to ? 'fi-text--dark' : 'fi-text--light'"
                class="fi-text fi-text--medium"
            >
                {{ tabs.to | timeFormat: 'SHORTEST_DATE' : valuePlaceholder }}
            </span>
        </div>
    }

    <div
        [class.fi-date-range-custom-tabs__active-indicator--on-right]="
            active === 'to' && singleDaySelectionDisabled
        "
        [class.fi-date-range-custom-tabs__active-indicator--single-day-selection]="
            !singleDaySelectionDisabled
        "
        class="fi-date-range-custom-tabs__active-indicator"
    ></div>

    @if (!singleDaySelectionDisabled && singleDaySelectionMessage) {
        <div
            class="fi-date-range-custom-tabs__single-day-selection-message"
        >
            <span class="fi-text fi-text--medium fi-text--small fi-text--black">
                {{ singleDaySelectionMessage }}
            </span>
        </div>
    }
</div>
