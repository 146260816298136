import { NgControl } from '@angular/forms';

import { Observable } from 'rxjs';

import { transformEmailValue } from '../../../utils';

import { InputFormatBase, InputFormatParsedValue } from './input-format-base';

export class EmailInputFormat extends InputFormatBase {
    valueChanges$: Observable<string>;

    constructor(
        ngControl: NgControl,
        private inputElement: HTMLInputElement,
        private initialValue: string,
    ) {
        super(ngControl);

        this.setControlValue(this.initialValue);

        this.valueChanges$ = this.getInputEvent(this.initialValue);
    }

    protected getParsedValue([
        previousValue,
        currentValue,
    ]: string[]): InputFormatParsedValue {
        const {
            selectionEnd: caretPosition,
            value: originalValue, // just updated yet unformatted value
        } = this.inputElement;

        return {
            caretPosition,
            originalValue,
            transformedValue: transformEmailValue(currentValue, previousValue),
        };
    }

    protected returnCaretToOriginalPosition({
        caretPosition,
        originalValue,
    }: InputFormatParsedValue) {
        const beforeCaretValue = originalValue.substring(0, caretPosition);

        const ignoreLeadingDotAndAt = '(?=^\\.)|(?=^@)';
        const ignoreDoubleDotAndAt = '.+@.+(?=@$)|.+@+(?=@)|.+\\.+(?=\\.)|.+';
        const ignoreRegex = new RegExp(
            `${ignoreLeadingDotAndAt}|${ignoreDoubleDotAndAt}`,
            `g`,
        );

        const [beforeCaretTransformedValue = ''] =
            beforeCaretValue.match(ignoreRegex) || [];
        const targetCaretPosition = beforeCaretTransformedValue.length;

        this.inputElement.setSelectionRange(
            targetCaretPosition,
            targetCaretPosition,
        );
    }
}
