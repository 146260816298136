import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    Optional,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { GridPagedResult } from '../../store/models';
import { GridNestedDataSourceDirective } from '../nested-data-source.directive';
import { GridRow } from '../../models';
import { ButtonComponent } from '../../../../components';

@UntilDestroy()
@Component({
    selector: 'fi-grid-nested-paginator',
    templateUrl: './paginator.component.html',
    styleUrls: ['./paginator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ButtonComponent],
})
export class GridNestedRowPaginatorComponent implements OnInit {
    @Input() nestedDataValue: string;
    @Input() row: GridRow;

    source: Pick<GridPagedResult, 'totalItems' | 'pageSize' | 'pageNumber'>;
    isInitializing = true;
    isLoading = false;
    canLoadMore = false;
    hasLoadMoreButton = false;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        @Optional() private nestedData: GridNestedDataSourceDirective,
    ) {}

    ngOnInit(): void {
        if (!this.nestedData) {
            return;
        }

        this.nestedData.stateChanged$
            .pipe(untilDestroyed(this))
            .subscribe(() => this.updateState());

        this.updateState();
    }

    handlePageNumberChange(pageNumber: number): void {
        if (!this.nestedData) {
            return;
        }

        this.nestedData.setPage(pageNumber, this.nestedDataValue, this.row);
    }

    private updateState(): void {
        const { source } = this.nestedData;
        const { pageNumber, pageSize, totalItems, loading, initializing } =
            source.values[this.nestedDataValue];

        this.isInitializing = initializing;
        this.isLoading = loading;
        this.source = {
            pageNumber,
            pageSize,
            totalItems,
        };

        this.hasLoadMoreButton = totalItems > pageSize;
        this.canLoadMore = pageNumber * pageSize < totalItems;

        this.changeDetectorRef.markForCheck();
    }
}
