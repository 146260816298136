import pluralize from 'pluralize';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'pluralize',
    standalone: true,
})
export class PluralizePipe implements PipeTransform {
    transform(value: string, count?: number, inclusive?: boolean) {
        return pluralize(value, count, inclusive);
    }
}
