import {
    Directive,
    EventEmitter,
    Input,
    Output,
    OnChanges,
    OnDestroy,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Subject } from 'rxjs';

import { GridPagedResult } from '../store';

@UntilDestroy()
@Directive({
    selector: '[fiGridDataSource]',
    exportAs: 'fiGridDataSource',
    standalone: true,
})
export class GridDataSourceDirective implements OnChanges, OnDestroy {
    readonly stateChanged = new Subject<void>();

    @Input('fiGridDataSource') readonly source: GridPagedResult;
    @Output()
    readonly fiGridDataSourceChange = new EventEmitter<
        Partial<GridPagedResult>
    >();

    isInitializing = true;
    isLoading = false;
    isLoadingError;
    isPresenting = false;

    stateChanged$ = this.stateChanged.asObservable().pipe(untilDestroyed(this));

    ngOnChanges(): void {
        this.calculateDataStatus(this.source);
        this.stateChanged.next();
    }

    ngOnDestroy(): void {
        this.stateChanged.complete();
    }

    setPage(pageNumber: number): void {
        this.fiGridDataSourceChange.emit({
            pageNumber,
        });
    }

    private calculateDataStatus({
        loading,
        isLoadingError,
        initializing,
    }: GridPagedResult): void {
        this.isInitializing = initializing;
        this.isLoading = loading;
        this.isLoadingError = isLoadingError;
        this.isPresenting = !initializing && !loading;
    }
}
