import {
    ChangeDetectionStrategy,
    Component,
    Input,
    Output,
    EventEmitter,
    inject,
} from '@angular/core';
import { AsyncPipe } from '@angular/common';

import { get } from 'lodash';

import { MemoizeFuncPipe } from '../../pipes';
import { SelectableListItem } from '../../components/selectable-list';
import { IconComponent, FiIconVariation } from '../../components/icon';
import { GridSearchConfiguration } from '../grid/models';
import { GridOnTabletService } from '../grid/services';
import { GridSearch } from '../grid/store/models';
import { PillComponent } from '../pill';

import {
    SelectModalConfig,
    SelectModalComponent,
} from './select-modal/select-modal.component';

@Component({
    selector: 'fi-select-modal-field',
    templateUrl: './select-modal-field.component.html',
    styleUrls: ['./select-modal-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AsyncPipe,
        PillComponent,
        IconComponent,
        SelectModalComponent,
        MemoizeFuncPipe,
    ],
})
export class SelectModalFieldComponent {
    private gridOnTabletService = inject(GridOnTabletService);

    @Input() title: string;
    @Input() mobileTitle: string;
    @Input() items: SelectableListItem[];
    @Input() selected: SelectableListItem | SelectableListItem[];
    @Input() isMultiple: boolean;
    @Input() isInitializing: boolean;
    @Input() icon: string;
    @Input() iconVariation: FiIconVariation = FiIconVariation.Regular;
    // @TODO: while not translation
    @Input() selectionDescriptionMobile: string;
    @Input() selectionDescriptionDesktop: string;
    @Input() modalConfig: SelectModalConfig;
    @Input() withoutCode: boolean;
    @Input() withCode: boolean;
    @Input() isInfiniteScrollLoading: boolean;
    @Input() gridSearchConfig: GridSearchConfiguration;
    @Input() gridSearchTotalItems: number;
    @Input() gridSearch: GridSearch;
    @Input() variation: string;
    @Input() disabled: boolean;
    @Input() noDataMessage = '';
    @Input() withoutDescription: boolean;

    @Output() gridSearchChange = new EventEmitter<GridSearch>();
    @Output() loadMoreItems = new EventEmitter<void>();
    @Output() selectionChange = new EventEmitter<SelectableListItem[]>();
    @Output() modalOpen = new EventEmitter<boolean>();
    @Output() close = new EventEmitter<void>();

    isModalOpened = false;

    readonly isMobileView$ = this.gridOnTabletService.isMobileView$;
    readonly isDesktopView$ = this.gridOnTabletService.isDesktopView$;

    openModal(): void {
        this.isModalOpened = true;
        this.modalOpen.emit(true);
    }

    closeModal(): void {
        this.isModalOpened = false;
        this.close.emit();
    }

    clearSelection(): void {
        this.selectionChange.emit([]);
        this.closeModal();
    }

    onChangeSelection(selection: SelectableListItem[]): void {
        this.selectionChange.emit(selection);
        this.closeModal();
    }

    getSelectedLabel(selected: SelectableListItem[]): string {
        const count = get(selected, 'length', 0);

        return count === 1 && selected[0].label;
    }

    getSelectedCount(selected: SelectableListItem[]): number {
        return selected?.length;
    }

    getSelectedDescription([selected, isMobile, isMultiple]: [
        SelectableListItem[],
        boolean,
        boolean,
    ]): string {
        const count = get(selected, 'length', 0);

        if (!count) {
            return;
        }

        if (count === 1) {
            const { code, description } = selected[0];

            if (this.withoutDescription) {
                return `${code}`;
            }

            if (this.withCode) {
                return `${code}-${description}`;
            }

            return isMultiple && !this.withoutCode
                ? `${code}-${description}`
                : description;
        }

        return isMobile
            ? `${count} ${this.selectionDescriptionMobile}`
            : `${this.selectionDescriptionDesktop} (${count})`;
    }

    handleGridSearch(search: GridSearch): void {
        this.gridSearchChange.emit(search);
    }

    handleLoadMoreItems(): void {
        this.loadMoreItems.emit();
    }
}
