<div class="fi-go-to-page">
    <div class="fi-go-to-page__wrapper">
        <span
            class="fi-text fi-text--medium fi-text--responsive-book fi-text--settings-color"
        >
            Page
        </span>

        <form
            [formGroup]="goToForm"
            (ngSubmit)="submitForm()"
            class="fi-go-to-page__form"
        >
            <fi-input
                [name]="'page'"
                [variation]="'small,no-error-border'"
                [textAlign]="(isTablet$ | async) ? 'center' : ''"
                [format]="'number'"
                [autocomplete]="'off'"
                formControlName="page"
                class="fi-go-to-page__input"
            ></fi-input>

            @if (isTablet$ | async) {
                <span
                    class="fi-text fi-text--medium fi-text--responsive-book fi-text--settings-color fi-go-to-page__max-page"
                >
                    of {{ maxPage }}
                </span>
            }

            <fi-button
                [variation]="'primary,small'"
                [type]="'submit'"
                [disabled]="isFormDisabled$ | async"
                [ariaLabel]="'Go'"
            >
                <span>Go</span>
            </fi-button>
        </form>
    </div>
</div>
