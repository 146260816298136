import { INPUT_ERROR_CLASS, MODAL_SUCCESS_CLASS } from './common';

export abstract class RecoverUserInfoAbstractController {
    private closeBtns = document.querySelectorAll('.js-close-modal');

    handleCloseModal(): void {
        // NodeList works with ES6, not supported by IE11
        const closeBtnArray = Array.prototype.slice.call(this.closeBtns);

        closeBtnArray.forEach((button: HTMLElement) => {
            button.addEventListener('click', () => this.clearForm());
        });
    }

    isInputEmpty(element: HTMLInputElement): boolean {
        return element.value.trim() === '';
    }

    showInputError(element: HTMLInputElement): void {
        element.parentElement.classList.add(INPUT_ERROR_CLASS);
    }

    hideInputError(element: HTMLInputElement): void {
        element.parentElement.classList.remove(INPUT_ERROR_CLASS);
    }

    showModalSuccess(element: HTMLElement): void {
        element.classList.add(MODAL_SUCCESS_CLASS);
    }

    hideModalSuccess(element: HTMLElement): void {
        element.classList.remove(MODAL_SUCCESS_CLASS);
    }

    clearForm(): void {
        /* implement */
    }

    showMessage(element: HTMLElement, message: string): void {
        element.innerHTML = message;
    }

    sendRequest(
        requestUrl: string,
        callback: (request: XMLHttpRequest) => void,
    ) {
        const request = new XMLHttpRequest();

        request.onreadystatechange = () => {
            if (request && request.responseText) {
                callback(request);
            }
        };

        request.open('POST', requestUrl, true);
        request.setRequestHeader(
            'Content-type',
            'application/json; charset=utf-8',
        );
        request.send();
    }
}
