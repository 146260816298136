import { Injectable } from '@angular/core';

import { sortBy, isEmpty, takeRight, includes, escapeRegExp } from 'lodash';

import { GridSearchConfiguration, GridSearchPrefix } from '../models';
import { SearchAutocompleteOption } from '../../search';
import { CommonStorageManager } from '../../../services/common-storage-manager';

interface QueryInfo {
    icon?: string;
    prefix?: string;
}
@Injectable()
export class GridSearchPhraseService {
    private maxSearchesToStore = 10;

    constructor(protected storageManager: CommonStorageManager) {}

    private get storage() {
        return this.storageManager.getLocalStorage();
    }

    getIdentifiedSearchPrefix(
        { prefixes }: GridSearchConfiguration,
        searchPhrase: string,
        searchPrefix?: string,
    ): string {
        let identifiedSearchPrefix = searchPrefix;

        // at this point we can try to identify the prefix, basing on the query format
        if (!identifiedSearchPrefix) {
            identifiedSearchPrefix = this.tryPrefixAutoDiscovery(
                searchPhrase,
                prefixes,
            );
        }

        return identifiedSearchPrefix;
    }

    getRecentSearches(
        query: string,
        storageKey: string,
    ): SearchAutocompleteOption[] {
        const allSearches = this.getAllRecentSearches(storageKey).reverse();
        const allSearchesInfo = this.getAllRecentSearchesInfo(storageKey);

        if (isEmpty(query)) {
            return allSearches.map((query) => {
                return this.queryStringToSuggestion(
                    query,
                    allSearchesInfo.get(query),
                );
            });
        }

        return this.getRecentSearchesMatchingQuery(query, storageKey);
    }

    setRecentSearches(
        { storageKey }: GridSearchConfiguration,
        searchPhrase: string,
        searchPrefix?: string,
    ) {
        this.insert(
            searchPhrase,
            storageKey,
            searchPrefix && {
                prefix: searchPrefix,
            },
        );
    }

    insert(query: string, storageKey: string, queryInfo?: QueryInfo) {
        if (isEmpty(query)) {
            return;
        }

        const { storage, maxSearchesToStore } = this;
        const allSearches = new Set(this.getAllRecentSearches(storageKey));

        // this is to make sure it's unique and at the end of the list
        allSearches.delete(query);
        allSearches.add(query);

        // based on FI-3399 we need to store last 10 results only
        const recentSearches = takeRight([...allSearches], maxSearchesToStore);
        storage.set(storageKey, recentSearches);

        if (queryInfo) {
            const allSearchesInfo = this.getAllRecentSearchesInfo(storageKey);
            allSearchesInfo.set(query, queryInfo);

            const normalizedSearchesInfo = [
                ...allSearchesInfo.entries(),
            ].filter((item) => includes(recentSearches, item[0]));

            storage.set(
                this.getAllRecentSearchesInfoStorageKey(storageKey),
                normalizedSearchesInfo,
            );
        }
    }

    private tryPrefixAutoDiscovery(
        searchPhrase: string,
        searchPrefixes: GridSearchPrefix[],
    ): string {
        if (!searchPhrase || !searchPrefixes) {
            return null;
        }

        return sortBy(searchPrefixes, 'autoDiscoveryOrder')
            .map((prefix) => {
                const { value, autoDiscoveryPattern } = prefix;

                return (
                    !!searchPhrase.match(new RegExp(autoDiscoveryPattern)) &&
                    value
                );
            })
            .filter((match) => !!match)
            .shift();
    }

    private getAllRecentSearchesInfo(storageKey: string) {
        const { storage } = this;
        const stored = storage.get<Iterable<[string, QueryInfo]>>(
            this.getAllRecentSearchesInfoStorageKey(storageKey),
            [],
        );

        return new Map(stored);
    }

    private getAllRecentSearchesInfoStorageKey(storageKey: string) {
        return `${storageKey}-info`;
    }

    private getAllRecentSearches(storageKey: string) {
        const { storage } = this;
        return storage.get<string[]>(storageKey, []);
    }

    private getRecentSearchesMatchingQuery(query: string, storageKey: string) {
        const regex = new RegExp(escapeRegExp(query), 'ig');
        const allSearches = this.getAllRecentSearches(storageKey).reverse();
        const allSearchesInfo = this.getAllRecentSearchesInfo(storageKey);

        const filteredSearches = allSearches.filter(
            (query) => !!query.match(regex),
        );

        return filteredSearches.map((query) => {
            return this.queryStringToSuggestion(
                query,
                allSearchesInfo.get(query),
            );
        });
    }

    private queryStringToSuggestion(
        query = '',
        queryInfo?: QueryInfo,
    ): SearchAutocompleteOption {
        const suggestion: SearchAutocompleteOption = {
            text: query,
            icon: 'history',
        };

        if (queryInfo && queryInfo.icon) {
            suggestion.icon = queryInfo.icon;
        }
        if (queryInfo && queryInfo.prefix) {
            suggestion.prefix = queryInfo.prefix;
        }

        return suggestion;
    }
}
