<div [fiTooltip]="!isMobileOS && tooltipTpl" class="manage-columns-menu">
    <fi-button
        [variation]="'secondary,squared'"
        [ariaLabel]="'Manage Columns'"
        (click)="openModal()"
    >
        <fi-icon [svgName]="'cog'"
                 [size]="'x-large'">
        </fi-icon>
    </fi-button>
</div>

<manage-columns-modal
    [open]="isModalOpen"
    [config]="columnsSettings"
    (close)="closeModal()"
    (save)="saveConfig($event)"
></manage-columns-modal>

<ng-template #tooltipTpl>
    <span>Manage Columns</span>
</ng-template>
