import {
    ReportingPeriod,
    LastLoggedIn,
    UserStatus,
    DistanceAndHoursFilterAll,
    AccountsRestrictions,
} from '../../../models';

export type FilterSetting = {
    label: string;
    key: string;
    data: any;
    settings: { [key: string]: any };
};

export interface AppliedFilterValue<T = any> {
    code?: T;
    description?: string;
    hasAlertIcon?: boolean;
    isApplyHidden?: boolean;
}

export interface AppliedFilter {
    key: string;
    value: AppliedFilterValue;
    isReadonly?: boolean;
}

export interface FilteringState {
    values: {
        [key: string]: any;
    };
    defaults: {
        [key: string]: any;
    };
}

export interface FilteringViewState {
    [key: string]: string | number | boolean;
}

export interface FilteringCustomDateKeys {
    period: string;
    date: string;
}

export const HIDDEN_ON_APPLY_FILTERS = [
    ReportingPeriod.Anytime,
    LastLoggedIn.AllTime,
    UserStatus.All,
    DistanceAndHoursFilterAll,
    AccountsRestrictions.All,
];
