<div class="files-upload">
    <div
        [class.files-upload__area--dragged-over]="dropZoneActive"
        [fiFileDrop]="fileUploadOptions"
        [fiFileDropIsExternalCheck]="true"
        (fileDropHovered)="dropZoneState($event)"
        (fileDropped)="handleFileDropped($event)"
        class="files-upload__area"
    >
        <div *fiIsDesktop class="files-upload__file-container">
            <ng-container
                *ngTemplateOutlet="
                    !dropZoneActive ? initialStateTpl : dragOverStateTpl
                "
            ></ng-container>
        </div>

        <div *fiIsMobile>
            <p class="files-upload__file">
                <ng-container *ngTemplateOutlet="fileTpl"></ng-container>
                <span
                    class="fi-text fi-text--medium fi-text--large fi-text--dark"
                >
                    to upload.
                </span>
            </p>
        </div>
        <ng-container *ngTemplateOutlet="infoTpl"></ng-container>

        <ng-template #initialStateTpl>
            <div class="files-upload__file">
                <div>
                    <span
                        class="fi-text fi-text--medium fi-text--large fi-text--dark"
                    >
                        Drag and Drop files here to upload or
                    </span>
                    <ng-container *ngTemplateOutlet="fileTpl"></ng-container>
                </div>
            </div>
            <fi-icon
                [variation]="'custom'"
                [size]="'60x'"
                [svgName]="'drag-and-drop'"
                class="files-upload__drop-area-icon"
            ></fi-icon>
        </ng-template>

        <ng-template #dragOverStateTpl>
            <div class="files-upload__file">
                <div>
                    <span class="fi-text fi-text--medium fi-text--white">
                        Drop files here to upload.
                    </span>
                </div>
            </div>
            <div class="files-upload__dragged-over-state">
                <fi-icon
                    [variation]="'custom'"
                    [size]="'wide'"
                    [svgName]="'company-logo'"
                    class="files-upload__company-logo-icon"
                ></fi-icon>
                <fi-icon
                    [size]="'xxx-large'"
                    [svgName]="'server'"
                    class="files-upload__server-icon"
                ></fi-icon>
            </div>
        </ng-template>

        <ng-template #infoTpl>
            <div
                [class.fi-text--white]="dropZoneActive"
                class="fi-text fi-text--small fi-text--medium fi-text--light files-upload__label"
            >
                @if (dropZoneActive) {
                    <fi-icon
                        [variation]="'custom'"
                        [size]="'xxxx-large'"
                        [svgName]="'excel-icon'"
                        class="files-upload__drop-over-icon"
                    ></fi-icon>
                }
                <span>5MB size limit.</span>
                <span>Supported most common image and documents formats</span>
            </div>
        </ng-template>

        <ng-template #fileTpl>
            <button
                (click)="chooseAndUploadFile()"
                type="button"
                aria-label="Select a File"
                class="fi-text fi-text--medium fi-text--large fi-text--link files-upload__select-btn"
            >
                Select a File
            </button>
        </ng-template>
    </div>

    <div class="files-upload__file-list">
        @for (attachment of filesList; track index; let index = $index) {
            <div class="files-upload__file-item">
                @if (!attachment.uploadError && attachment.isUploading) {
                    <fi-icon
                        [svgName]="'temp-spinner'"
                        class="files-upload__loading-indicator"
                    ></fi-icon>
                }
                @if (attachment.uploadError) {
                    <fi-icon
                        [size]="'24x'"
                        [svgName]="'file-exclamation'"
                        class="files-upload__file-icon files-upload__file-icon--error"
                    ></fi-icon>
                }
                @if (!attachment.uploadError && !attachment.isUploading) {
                    <fi-icon
                        [variation]="'regular'"
                        [size]="'24x'"
                        [svgName]="
                            attachment | memoizeFunc: chooseIconType : this
                        "
                        class="files-upload__file-icon files-upload__file-icon--regular"
                    ></fi-icon>
                }
                <div class="files-upload__file-info">
                    <p
                        [class.fi-text--light]="attachment.isUploading"
                        class="files-upload__file-label fi-text fi-text--medium fi-text--light"
                    >
                        {{
                            attachment?.attachmentName
                                | truncate: truncateLength
                        }}
                    </p>
                    <div class="files-upload__size-error-description">
                        @if (!attachment.uploadError) {
                            <p
                                class="fi-text fi-text--medium fi-text--small fi-text--light"
                            >
                                {{
                                    attachment?.attachmentSize
                                        | memoizeFunc: formatBytes
                                }}
                            </p>
                        }
                        @if (
                            !attachment.isUploading && !attachment.uploadError
                        ) {
                            <fi-icon
                                [variation]="'solid'"
                                [svgName]="'check'"
                                class="files-upload__success-upload-icon"
                            ></fi-icon>
                        }
                    </div>

                    @if (attachment.uploadError) {
                        <p
                            class="fi-text fi-text--medium fi-text--small fi-text--error"
                        >
                            {{ attachment.uploadError }}
                        </p>
                    }
                </div>

                @if (attachment.isUploading || attachment.uploadError) {
                    <fi-icon
                        [variation]="'regular'"
                        [size]="'medium'"
                        [svgName]="'times'"
                        (click)="onRemoveFile(index)"
                        class="files-upload__trash-icon"
                    ></fi-icon>
                }
                @if (!attachment.isUploading && !attachment.uploadError) {
                    <fi-icon
                        [variation]="'regular'"
                        [size]="'medium'"
                        [svgName]="'trash-alt'"
                        (click)="onRemoveFile(index)"
                        class="files-upload__cancel-icon"
                    ></fi-icon>
                }
            </div>
        }
    </div>
</div>

<delete-uploaded-file-modal
    [open]="deactivateModalOpen"
    [fileName]="fileToDelete?.attachmentName"
    (close)="closeDeactivateModal()"
    (remove)="deleteUploadedFile()"
></delete-uploaded-file-modal>
