import { Directive, OnInit } from '@angular/core';

import { IsMobileDirective } from './is-mobile.directive';

/**
 * @deprecated
 */
@Directive({
    selector: '[fiIsDesktop]',
    standalone: true,
})
export class IsDesktopDirective extends IsMobileDirective implements OnInit {
    ngOnInit(): void {
        this.createViewSelector(this.environmentService.isDesktop$);
    }
}
