import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild,
    inject,
} from '@angular/core';
import { NgClass, NgTemplateOutlet } from '@angular/common';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { head, isEmpty, orderBy, range, some } from 'lodash';
import { shareReplay } from 'rxjs/operators';
import dayjs, { Dayjs } from 'dayjs';

import { EnvironmentService } from '../../../services';
import { OnChange, SimpleChange } from '../../../decorators';
import { MemoizeFuncPipe } from '../../../pipes';
import { AutofocusDirective, VariationDirective } from '../../../directives';
import { HorizontalControl } from '../../../components';
import { TooltipDirective } from '../../tooltip';
import { BlockingRule } from '../models/blocking-rule';
import { getCalendarMonthDays, isDayInRange } from '../utils';

export const enum RenderDayMode {
    AllSelectable = 'ALL_SELECTABLE',
    RangeSelectable = 'RANGE_SELECTABLE',
    Other = 'OTHER',
}

export const enum DatepickerModes {
    Days = 'DAYS',
    Months = 'MONTHS',
    Years = 'YEARS',
}

export const enum BlockingType {
    Blocked = 'blocked',
    Holiday = 'holiday',
}

export const DATEPICKER_VISIBLE_YEARS = 12;
export const DAYS_LENGTH = 35;

@UntilDestroy()
@Component({
    selector: 'fi-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        HorizontalControl,
        VariationDirective,
        AutofocusDirective,
        TooltipDirective,
        MemoizeFuncPipe,
    ]
})
export class CalendarComponent implements AfterViewInit {
    public readonly changeDetectorRef = inject(ChangeDetectorRef);
    private readonly environmentService = inject(EnvironmentService);

    @Input() availableRange: Dayjs[];
    @Input() minDateAvailable: Dayjs;
    @Input() maxDateAvailable: Dayjs;
    @Input() allDatesSelectable: boolean;
    @Input() warnBeforeDate: Dayjs;
    @Input() warnBeforeDateMessage: string;
    @Input() variation: string;
    @Input() disabledDaysOfWeek: number[];
    @Input() keepCurrentMonth = false;
    @Input() blockingRules: BlockingRule[] = [];
    @Input() shouldHighlightRange = false;

    @OnChange('setupInitDays')
    @Input()
    startDate: Dayjs;
    @OnChange('setupInitDays')
    @Input()
    selectedDates: Dayjs[];

    @Output() selectDate = new EventEmitter<Dayjs>();
    @Output() monthChange = new EventEmitter<Dayjs>();

    @ViewChild('dayWarningTooltipTpl')
    dayWarningTooltipTpl: TemplateRef<void>;
    @ViewChild('dayDisabledTooltipTpl')
    dayDisableTooltipTpl: TemplateRef<void>;

    currentMode: DatepickerModes;
    today = dayjs().startOf('day');
    currentDate: Dayjs;
    currentDatePrevState: Dayjs;

    days: Dayjs[];
    months: string[];
    yearsRange: number[];

    daysOfWeek: string[];

    private isMobile: boolean;
    private readonly isMobile$ = this.environmentService.isMobile$.pipe(
        shareReplay({ bufferSize: 1, refCount: true }),
        untilDestroyed(this),
    );

    ngAfterViewInit(): void {
        this.months = dayjs.monthsShort();
        this.daysOfWeek = dayjs.weekdaysMin();
        this.currentMode = DatepickerModes.Days;
        this.changeDetectorRef.markForCheck();

        this.allDatesSelectable = this.allDatesSelectable || false;
        this.setupYearsRange();

        if (this.availableRange) {
            const sortedDates = orderBy(
                this.availableRange,
                (availableDate: Dayjs) => {
                    return dayjs(availableDate.format('YYYY-MM-DD'));
                },
            );
            this.minDateAvailable = sortedDates[0] || this.today.clone();
            this.maxDateAvailable =
                sortedDates[sortedDates.length - 1] || this.today.clone();
        }

        this.isMobile$.subscribe((isMobile) => (this.isMobile = isMobile));
        this.handleMonthChanges();
    }

    getRenderMode(day: Dayjs): RenderDayMode {
        if (this.isDayDisabled(day)) {
            return RenderDayMode.RangeSelectable;
        }

        if (this.allDatesSelectable) {
            return this.isDayOutOfMinMaxRange(day)
                ? RenderDayMode.Other
                : RenderDayMode.AllSelectable;
        }

        if (this.availableRange) {
            return RenderDayMode.RangeSelectable;
        }

        return RenderDayMode.Other;
    }

    isDaySelected([day, selectedDates]: [Dayjs, Dayjs[]]): boolean {
        return (
            selectedDates &&
            selectedDates.some((date: Dayjs) => {
                return !!date && date.isSame(day, 'day');
            }) &&
            this.canBeSelected(day)
        );
    }

    isDayInRange(day: Dayjs): boolean {
        if (!day || !day.isValid() || !this.selectedDates) {
            return false;
        }

        const [from, to] = this.selectedDates;

        return day.isAfter(from, 'day') && day.isBefore(to, 'day');
    }

    changePickerPage(isAdding?: boolean): void {
        const action = isAdding ? 'add' : 'subtract';

        switch (this.currentMode) {
            case DatepickerModes.Days:
                this.currentDate = this.currentDate[action](1, 'month');
                this.days = getCalendarMonthDays(this.currentDate);
                break;
            case DatepickerModes.Months:
                this.currentDate = this.currentDate[action](1, 'year');
                break;
            case DatepickerModes.Years:
                this.changeYearRange(isAdding);
                break;
        }

        this.handleMonthChanges();
    }

    goToToday(): void {
        this.goToDay(this.today.clone());
    }

    goToDay(day: Dayjs): void {
        this.currentDate = day.clone();
        this.days = getCalendarMonthDays(this.currentDate);
        this.currentMode = DatepickerModes.Days;

        this.handleMonthChanges();
    }

    openMonths(): void {
        this.currentMode = DatepickerModes.Months;
    }

    openYears(): void {
        this.currentMode = DatepickerModes.Years;
    }

    onSelectDate(day: Dayjs): void {
        this.selectDate.emit(day);
    }

    selectMonth(monthNum: number): void {
        this.currentDate = this.currentDate.month(monthNum);
        this.handleMonthChanges();
        this.resetCurrentMode();
    }

    selectYear(year: number): void {
        this.currentDate = this.currentDate.year(year);
        this.handleMonthChanges();
        this.resetCurrentMode();
    }

    canBeSelected(day: Dayjs): boolean {
        if (this.disabledDaysOfWeek && this.isDayDisabled(day)) {
            return;
        }

        if (
            this.isBlocking(day, BlockingType.Holiday) ||
            this.isBlocking(day, BlockingType.Blocked)
        ) {
            return false;
        }

        return this.availableRange
            ? isDayInRange(this.availableRange, day)
            : !this.isDayOutOfMinMaxRange(day);
    }

    isNotActiveDay(day: Dayjs): boolean {
        return this.isDayFromSeparateMonth(day) || !this.canBeSelected(day);
    }

    isBlocking(
        day: Dayjs,
        blockingType: BlockingType = BlockingType.Blocked,
    ): boolean {
        return some(
            this.blockingRules,
            ({ blockingDate, locationBlockingRule }) =>
                day.isSame(dayjs(blockingDate), 'day') &&
                (blockingType === BlockingType.Holiday
                    ? locationBlockingRule.isHoliday
                    : locationBlockingRule.isBlocked),
        );
    }

    getDayWarningTooltip([day, warnBeforeDate]: [
        Dayjs,
        Dayjs,
    ]): TemplateRef<void> | null {
        if (this.isMobile) {
            return null;
        }

        return !isEmpty(warnBeforeDate) && day.isBefore(warnBeforeDate)
            ? this.dayWarningTooltipTpl
            : null;
    }

    getDayDisabledTooltip(day: Dayjs): TemplateRef<void> | null {
        if (this.isMobile) {
            return null;
        }

        return !this.canBeSelected(day) ? this.dayDisableTooltipTpl : null;
    }

    getYearsTitle(): string {
        const yearFrom = this.yearsRange[0];
        const yearTo = this.yearsRange[DATEPICKER_VISIBLE_YEARS - 1];

        return `${yearFrom} - ${yearTo}`;
    }

    isMonthSelected(month: string): boolean {
        return this.currentDate.format('MMM') === month;
    }

    isYearSelected(year: number): boolean {
        return this.currentDate.year() === year;
    }

    isDayToday(day: Dayjs): boolean {
        return this.today.isSame(day, 'day');
    }

    monthHasFiveWeeks(days: Dayjs[]): boolean {
        return days.length === DAYS_LENGTH;
    }

    private setupInitDays(
        _,
        { previousValue }: SimpleChange<Dayjs[] | Dayjs>,
    ): void {
        if (!this.startDate) {
            const hasSelectedDates =
                !isEmpty(this.selectedDates) && !!this.selectedDates[0];
            this.startDate = hasSelectedDates
                ? this.selectedDates[0]
                : dayjs().startOf('day');
        }

        const isSelectedFromRangeOnly = this.isSelectedFromRange(
            this.selectedDates,
            previousValue,
        );
        this.currentDatePrevState = isSelectedFromRangeOnly
            ? this.currentDatePrevState
            : this.currentDate?.clone();
        this.currentDate =
            this.keepCurrentMonth && isSelectedFromRangeOnly
                ? this.currentDatePrevState.clone()
                : this.startDate.clone();
        this.days = getCalendarMonthDays(this.currentDate);
    }

    get prevControlDisabled(): boolean {
        if (!this.isDaysMode || !this.currentDate) {
            return false;
        }

        const prevMonth = this.currentDate
            .startOf('month')
            .subtract(1, 'month');

        return prevMonth.isBefore(this.minMonthAvailable, 'month');
    }

    get nextControlDisabled(): boolean {
        if (!this.isDaysMode || !this.currentDate) {
            return false;
        }

        const nextMonth = this.currentDate.endOf('month').add(1, 'month');

        return nextMonth.isAfter(this.maxMonthAvailable);
    }

    private isSelectedFromRange(
        selectedDates: Dayjs[],
        previousValue: Dayjs[] | Dayjs,
    ): boolean {
        if (!Array.isArray(selectedDates)) {
            return false;
        }

        const fullRangeWasSelected = selectedDates.length === 2;
        if (fullRangeWasSelected) {
            return (
                Array.isArray(previousValue) &&
                selectedDates[0] !== previousValue[0]
            );
        }

        return selectedDates.length === 1;
    }

    private isDayOutOfMinMaxRange(day: Dayjs): boolean {
        return (
            (this.minDateAvailable &&
                day.isBefore(this.minDateAvailable, 'day')) ||
            (this.maxDateAvailable && day.isAfter(this.maxDateAvailable, 'day'))
        );
    }

    private isDayDisabled(currentDay: Dayjs): boolean {
        if (!this.disabledDaysOfWeek) {
            return;
        }

        return this.disabledDaysOfWeek.some(
            (dayNumber) => currentDay.day() === dayNumber,
        );
    }

    private resetCurrentMode(): void {
        this.days = getCalendarMonthDays(this.currentDate);
        this.currentMode = DatepickerModes.Days;
    }

    private isDayFromSeparateMonth(day: Dayjs): boolean {
        const monthStart = this.currentDate.startOf('month');
        const monthEnd = this.currentDate.endOf('month');

        return !dayjs(day).isBetween(monthStart, monthEnd, 'day', '[]');
    }

    private setupYearsRange(): void {
        const year =
            dayjs(this.currentDate).year() - DATEPICKER_VISIBLE_YEARS + 1;

        this.yearsRange = range(year, year + DATEPICKER_VISIBLE_YEARS);
    }

    private changeYearRange(isAdding: boolean): void {
        const firstYearOfRange = head(this.yearsRange);
        const year = isAdding
            ? firstYearOfRange + DATEPICKER_VISIBLE_YEARS
            : firstYearOfRange - DATEPICKER_VISIBLE_YEARS;

        this.yearsRange = range(year, year + DATEPICKER_VISIBLE_YEARS);
    }

    private get isDaysMode(): boolean {
        return this.currentMode === DatepickerModes.Days;
    }

    private get minMonthAvailable(): Dayjs {
        return this.minDateAvailable
            ? this.minDateAvailable.startOf('month')
            : null;
    }

    private get maxMonthAvailable(): Dayjs {
        return this.maxDateAvailable
            ? this.maxDateAvailable.endOf('month')
            : null;
    }

    private handleMonthChanges(): void {
        this.monthChange.emit(this.currentDate.startOf('month'));
    }
}
