import { ElementRef, Injectable } from '@angular/core';

import { sum, max, map } from 'lodash';

import { GridColumnDefDirective } from '../column/column-def.directive';

const STICKY_CELL_CLASS = 'fi-grid-table__cell--fixed';

type StickyDirection = 'left' | 'right';

@Injectable()
export class TableStickyStyler {
    setElementStyles(
        elements: Array<ElementRef | HTMLElement>,
        styles: object,
    ): void {
        elements
            .filter((element) => !!element)
            .map((element) =>
                element instanceof ElementRef ? element.nativeElement : element,
            )
            .forEach((element) => {
                Object.assign(element.style, styles);
            });
    }

    clearCellStickyPosition(
        rows: HTMLElement[],
        stickyDirections: StickyDirection[],
    ): void {
        for (const row of rows) {
            this.setElementStyles([row], { height: '' });

            Array.from(row.children).forEach((cell) => {
                this.removeStickyStyle(cell as HTMLElement, stickyDirections);
            });
        }
    }

    clearCellHeight(rows: HTMLElement[]): void {
        for (const row of rows) {
            this.setElementStyles([row], { height: 'auto' });

            Array.from(row.children).forEach((cell) => {
                this.setElementStyles([cell as HTMLElement], { height: 'auto' });
            });
        }
    }

    getCellWidths(
        elements: HTMLElement[],
        maxStickyColumnWidth: number,
    ): number[] {
        return elements.map((cell) => {
            if (!cell) {
                return 0;
            }

            const innerWidth = cell.clientWidth
                ? cell.getBoundingClientRect().width
                : 0;
            return maxStickyColumnWidth && innerWidth > maxStickyColumnWidth
                ? maxStickyColumnWidth
                : innerWidth;
        });
    }

    getPredefinedCellWidth(
        elements: HTMLElement[],
        columns: GridColumnDefDirective[],
        maxStickyColumnWidth: number,
    ): number {
        let column: GridColumnDefDirective;

        elements.some((element) => {
            column = columns.find((column) => {
                // 'fixed' for the last right column without field name with scroll controls
                const res = element.classList.contains(`fi-grid-table__cell--${column.field || 'fixed'}`);
                return res;
            });
            return !!column;
        });

        return Math.min(column.minWidth, maxStickyColumnWidth);
    }

    updateStickyColumns(
        rows: HTMLElement[],
        stickyStartStates: boolean[],
        stickyEndStates: boolean[],
        cellWidths: number[],
        containerWidth = Infinity,
    ): void {
        const hasStickyColumns =
            stickyStartStates.some((state) => state) ||
            stickyEndStates.some((state) => state);

        if (!rows.length || !hasStickyColumns) {
            return;
        }

        const numCells = rows[0].children.length;

        const startPositions = this.getStickyStartColumnPositions(
            cellWidths,
            stickyStartStates,
        );
        const endPositions = this.getStickyEndColumnPositions(
            cellWidths,
            stickyEndStates,
        );

        for (const row of rows) {
            const rowCells = row.children;
            const rowMinHeight = max(map(rowCells, ({ offsetHeight }: HTMLTableCellElement) => offsetHeight));

            for (
                let cellIndex = 0, position = 0;
                cellIndex < numCells;
                cellIndex++, position++
            ) {
                const cell = rowCells[cellIndex] as HTMLTableCellElement;

                if (!cell) {
                    continue;
                }

                const { colSpan } = cell;

                const width = Math.min(
                    sum(cellWidths.slice(position, position + colSpan)),
                    containerWidth,
                );

                if (stickyStartStates[position]) {
                    this.addStickyStyle(
                        cell,
                        'left',
                        startPositions[position],
                        width,
                        cellIndex + 1,
                        rowMinHeight,
                    );
                }

                if (stickyEndStates[position]) {
                    this.addStickyStyle(
                        cell,
                        'right',
                        endPositions[position],
                        width,
                        0,
                        rowMinHeight,
                    );
                }

                if (colSpan > 1) {
                    position += colSpan - 1;
                }
            }

            this.setElementStyles([row], {
                height: `${rowMinHeight}px`,
            });
        }
    }

    private removeStickyStyle(
        element: HTMLElement,
        stickyDirections: StickyDirection[],
    ): void {
        const styles = {};

        for (const dir of stickyDirections) {
            Object.assign(styles, { [dir]: '' });
        }

        const hasDirection = !!Object.keys(styles).length;

        if (!hasDirection) {
            return;
        }

        this.setElementStyles([element], {
            ...styles,
            position: '',
            width: '',
            display: '',
            zIndex: '',
        });

        for (const dir of stickyDirections) {
            element.classList.remove(`${STICKY_CELL_CLASS}-${dir}`);
        }
    }

    private addStickyStyle(
        element: HTMLElement,
        dir: StickyDirection,
        dirValue: number,
        width: number,
        zIndex: number,
        rowMinHeight?: number,
    ): void {
        element.classList.add(`${STICKY_CELL_CLASS}-${dir}`);

        this.setElementStyles([element], {
            [dir]: `${dirValue}px`,
            position: 'absolute',
            width: `${width}px`,
            height: `${rowMinHeight || element.offsetHeight}px`,
            zIndex,
        });
    }

    private getStickyStartColumnPositions(
        widths: number[],
        stickyStates: boolean[],
    ): number[] {
        const positions: number[] = [];
        let nextPosition = 0;

        for (let i = 0; i < widths.length; i++) {
            if (stickyStates[i]) {
                positions[i] = nextPosition;
                nextPosition += widths[i];
            }
        }

        return positions;
    }

    private getStickyEndColumnPositions(
        widths: number[],
        stickyStates: boolean[],
    ): number[] {
        const positions: number[] = [];
        let nextPosition = 0;

        for (let i = widths.length; i > 0; i--) {
            if (stickyStates[i]) {
                positions[i] = nextPosition;
                nextPosition += widths[i];
            }
        }

        return positions;
    }
}
