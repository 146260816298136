import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';

import { UntilDestroy } from '@ngneat/until-destroy';
import { merge, Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { isEmpty } from 'lodash';
import dayjs, { Dayjs } from 'dayjs';

import { EnvironmentService } from '../../../services/';
import { OnChange } from '../../../decorators';
import { ButtonComponent } from '../../../components';
import { MemoizeFuncPipe, TimeFormatPipe } from '../../../pipes';
import { IsDesktopDeviceDirective, IsMobileDeviceDirective, VariationDirective } from '../../../directives';
import {
    ModalAnimationType,
    ModalCloseBarComponent,
    ModalComponent,
    ModalContentComponent,
    ModalEvent,
    ModalEventType,
    ModalFooterComponent,
    ModalHeaderComponent,
    ModalVariation,
} from '../../modal';
import { PillComponent } from '../../pill';
import {
    DateRange,
    DateRangeModesTitle,
    DateRangeSelectionMode,
    DateRangeState,
} from '../models';
import { CalendarComponent } from '../calendar/calendar.component';
import { DateRangeCustomTabsComponent } from './tabs/tabs.component';

type DateRangeVariation = 'invalid';

export interface AvailableDateRange {
    value: number;
    unit: dayjs.UnitType;
}

export const DATE_FORMAT = 'YYYY-MM-DD';

@UntilDestroy()
@Component({
    selector: 'fi-date-range-custom',
    templateUrl: './range-custom.component.html',
    styleUrls: ['./range-custom.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        AsyncPipe,
        NgTemplateOutlet,
        ModalComponent,
        ModalHeaderComponent,
        ModalCloseBarComponent,
        ModalContentComponent,
        ModalFooterComponent,
        ButtonComponent,
        PillComponent,
        DateRangeCustomTabsComponent,
        CalendarComponent,
        VariationDirective,
        IsMobileDeviceDirective,
        IsDesktopDeviceDirective,
        MemoizeFuncPipe,
        TimeFormatPipe,
    ]
})
export class DateRangeCustomComponent {
    @OnChange<DateRange>(function (this: DateRangeCustomComponent, selected) {
        this.setSelectedState(selected);
    })
    @Input()
    selected: DateRange;
    @Input() availableDateRange: DateRangeState;
    @Input() dateRangeAvailable: AvailableDateRange;
    @Input() historicalOnly: boolean;
    @Input() variation: DateRangeVariation | string = '';
    @Input() keepCurrentMonth = false;
    @Input() modalTitle = 'Custom Date';
    @OnChange('initSelectedDates')
    @Input()
    open = false;
    @Input() showDatesInfo = true;
    @Input() fromTitle = DateRangeModesTitle.From;
    @Input() toTitle = DateRangeModesTitle.To;
    @Input() confirmButtonTitle = 'Done';
    @Input() showCancelButton: boolean;
    @Input() cancelButtonTitle = 'Cancel';
    @Input() singleDaySelectionDisabled = false;
    @Input() singleDaySelectionMessage: string;

    @Output() select = new EventEmitter<DateRange>();
    @Output() reset = new EventEmitter<void>();
    @Output() openChange = new EventEmitter<boolean>();

    @ViewChild(CalendarComponent)
    calendar: CalendarComponent;

    selectedState = this.getEmptySelectedState();

    selectionMode = DateRangeSelectionMode.From;
    changedMode$: Observable<DateRangeSelectionMode | null>;

    currentMonthSelected = true;

    readonly isMobileDevice$ = this.environmentService.isMobileDevice$;

    readonly toPriorFromWarningMessage =
        'Choosing a prior date will update the "From" date';

    readonly modalAnimation = ModalAnimationType.SlideTop;
    readonly modalVariation = [ModalVariation.FullHeight];

    private readonly currentMonth = dayjs().startOf('month');

    constructor(
        public elementRef: ElementRef,
        private environmentService: EnvironmentService,
    ) {}

    closeModal(): void {
        this.open = false;
    }

    handleModalEvent({ type }: ModalEvent): void {
        if (type === ModalEventType.Detach) {
            this.selectionMode = DateRangeSelectionMode.From;
            this.selectedState = this.getEmptySelectedState();

            this.openChange.emit(false);
            this.closeModal();
        }
    }

    handleReset(event: Event): void {
        event.stopPropagation();
        this.reset.emit();
    }

    getSelectedDates(range: DateRange): Dayjs[] {
        return Object.keys(range).reduce(
            (selected: Dayjs[], key: keyof DateRange) => {
                const item = range[key];

                if (item) {
                    selected.push(dayjs(item));
                }
                return selected;
            },
            [],
        );
    }

    getMaxAvailableDate([
        maxDateAvailable,
        historicalOnly,
        from,
        selectionMode,
    ]: [Dayjs, boolean, Dayjs, DateRangeSelectionMode]): Dayjs {
        if (
            selectionMode !== DateRangeSelectionMode.From &&
            this.dateRangeAvailable &&
            from
        ) {
            const { value, unit } = this.dateRangeAvailable;
            const minDate = Math.min(
                +dayjs(from).startOf('day').add(value, unit),
                +dayjs().startOf('day'),
            );
            return dayjs(minDate);
        }

        if (maxDateAvailable) {
            return maxDateAvailable;
        }

        return historicalOnly ? dayjs().startOf('day') : null;
    }

    handleSelect(): void {
        if (
            this.singleDaySelectionDisabled &&
            !(
                this.hasBothDates(this.selectedState) &&
                this.isToDateInRange(
                    this.availableDateRange?.to,
                    this.historicalOnly,
                    this.selectedState,
                )
            )
        ) {
            return;
        }

        this.select.emit(this.selectedState);
        this.closeModal();
    }

    resetDates(): void {
        this.selectionMode = DateRangeSelectionMode.From;
        this.selectedState = this.getEmptySelectedState();
    }

    goToCurrentMonth(): void {
        if (!this.calendar) {
            return;
        }

        this.calendar.goToToday();
        this.calendar.changeDetectorRef.markForCheck();
    }

    changeRangeTab(mode: DateRangeSelectionMode): void {
        this.selectionMode = mode;
        this.syncCurrentMonthWithTab(mode);
    }

    handleSelectedDate(date: Dayjs): void {
        if (
            this.singleDaySelectionDisabled &&
            this.selectionMode === DateRangeSelectionMode.To &&
            date?.isSame(dayjs(this.selectedState?.from), 'day')
        ) {
            return;
        }

        const selectedDateRange =
            this.selectionMode === DateRangeSelectionMode.From
                ? this.getFromSelectedDates(date)
                : this.getToSelectedDates(date);

        this.selectedState = { ...selectedDateRange };
        this.setToDateSelectionModeWhenFromChosen();
        const startDateMonth = this.getDayjsStartDate(
            this.selectedState,
        ).startOf('month');
        this.handleChangedMonth(startDateMonth);
    }

    handleChangedMonth(month: Dayjs): void {
        this.currentMonthSelected = this.currentMonth.isSame(month);
    }

    hasFromDate(range: DateRange): boolean {
        return !isEmpty(range.from);
    }

    hasBothDates(range: DateRange): boolean {
        return !isEmpty(range) && !!range.from && !!range.to;
    }

    hasSelectedDate([range, isMultiSelection]: [DateRange, boolean]): boolean {
        return isMultiSelection
            ? this.hasBothDates(range)
            : this.hasFromDate(range);
    }

    getToPriorFromWarningDate([selectedState, selectionMode]: [
        DateRange,
        DateRangeSelectionMode,
    ]): Dayjs | null {
        if (
            selectionMode === DateRangeSelectionMode.To &&
            !isEmpty(selectedState.from)
        ) {
            return dayjs(selectedState.from);
        }

        return null;
    }

    getDayjsStartDate(dateRange: DateRange): Dayjs {
        return dayjs(
            dateRange[this.selectionMode] ||
                dateRange[DateRangeSelectionMode.From],
        );
    }

    handleCancel(): void {
        this.resetDates();
        this.closeModal();
    }

    private getEmptySelectedState(): DateRange {
        return {
            from: null,
            to: null,
        };
    }

    private setSelectedState(range: DateRange): void {
        this.selectedState = range || this.getEmptySelectedState();
    }

    private initSelectedDates(isModalOpen: boolean): void {
        if (isModalOpen && this.selected) {
            this.setSelectedState(this.selected);
        }
    }

    private getFromSelectedDates(dateFrom: Dayjs): DateRange {
        const { selectedState } = this;
        const range: DateRange = {};

        range.from = dateFrom.format(DATE_FORMAT);

        if (!isEmpty(selectedState.to) && dateFrom.isBefore(selectedState.to)) {
            range.to = selectedState.to;
        }

        return range;
    }

    private getToSelectedDates(dateTo: Dayjs): DateRange {
        const { selectedState } = this;
        const range: DateRange = {};

        if (dateTo.isBefore(selectedState.from)) {
            range.from = dateTo.format(DATE_FORMAT);
            if (this.singleDaySelectionDisabled) {
                this.triggerFromSelectionChange();
            }
        } else {
            range.from = selectedState.from;
            range.to = dateTo.format(DATE_FORMAT);
        }

        return range;
    }

    private triggerFromSelectionChange(): void {
        this.changedMode$ = merge(
            of(DateRangeSelectionMode.From),
            of(null).pipe(delay(250)),
        );
    }

    private syncCurrentMonthWithTab(mode: DateRangeSelectionMode): void {
        if (this.calendar && this.activeTabHasDate(mode)) {
            this.calendar.goToDay(dayjs(this.selectedState[mode]));
        }
    }

    private activeTabHasDate(mode: DateRangeSelectionMode): boolean {
        return !!this.selectedState[mode];
    }

    private isToDateInRange(
        maxDateAvailable: Dayjs,
        historicalOnly: boolean,
        range: DateRange,
    ): boolean {
        const {
            [DateRangeSelectionMode.From]: from,
            [DateRangeSelectionMode.To]: to,
        } = range;
        const maxAvailableDate = this.getMaxAvailableDate([
            maxDateAvailable,
            historicalOnly,
            dayjs(from),
            this.selectionMode,
        ]);
        return !maxAvailableDate || dayjs(to) <= maxAvailableDate;
    }

    private setToDateSelectionModeWhenFromChosen(): void {
        const shouldSetToDateSelectionMode =
            this.singleDaySelectionDisabled &&
            this.selectionMode === DateRangeSelectionMode.From;
        if (shouldSetToDateSelectionMode) {
            this.changeRangeTab(DateRangeSelectionMode.To);
        }
    }
}
