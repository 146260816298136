import {
    Component,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
} from '@angular/core';

import { isEscapeModalEvent } from '../../../overlay';
import {
    ModalEvent,
    ModalVariation,
    ModalComponent,
    ModalHeaderComponent,
    ModalContentComponent,
    ModalFooterComponent,
} from '../../../modal';
import { TRUNCATE_LENGTH } from '../files-upload.component';
import { IsDesktopDeviceDirective } from '../../../../directives';
import { IconComponent, ButtonComponent } from '../../../../components';
import { TruncateTextPipe } from '../../../../pipes';

@Component({
    selector: 'delete-uploaded-file-modal',
    templateUrl: './delete-uploaded-file-modal.component.html',
    styleUrls: ['./delete-uploaded-file-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        ModalComponent,
        ModalHeaderComponent,
        ModalContentComponent,
        ModalFooterComponent,
        IconComponent,
        ButtonComponent,
        TruncateTextPipe,
        IsDesktopDeviceDirective,
    ],
})
export class DeleteUploadedFileModalComponent {
    @Input() open: boolean;
    @Input() fileName: string;

    @Output() close = new EventEmitter<void>();
    @Output() remove = new EventEmitter<void>();

    readonly modalVariation = [ModalVariation.FullHeight];
    readonly truncateLength = TRUNCATE_LENGTH;

    handleClose(): void {
        this.close.emit();
    }

    handleDeactivate(): void {
        this.remove.emit();
    }

    handleModalEvent({ type }: ModalEvent): void {
        if (isEscapeModalEvent(type)) {
            this.handleClose();
        }
    }
}
