import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
    inject,
} from '@angular/core';
import { AsyncPipe, DOCUMENT, NgTemplateOutlet } from '@angular/common';
import { animate, style, transition, trigger } from '@angular/animations';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { fromEvent, Observable, Subject } from 'rxjs';
import {
    distinctUntilChanged,
    filter,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { get, isNumber } from 'lodash';

import { OnChange } from '../../../../decorators';
import {
    ConnectedOverlayParams,
    OverlayConnectedPosition,
    OverlayContainerRef,
    OverlayGlobalPosition,
    OverlayParams,
    OverlayService,
    OverlayScrollStrategy,
    isEscapeModalEvent,
} from '../../../overlay';
import { EnvironmentService, BodyService } from '../../../../services';
import { fromPromiseWithUnsubscribe } from '../../../../utils/from-promise-with-unsubscribe';
import { GridOnTabletService } from '../../../grid/services';
import { GuidedTourIndicatorComponent } from '../guided-tour-indicator/guided-tour-indicator.component';
import { ButtonComponent, IconComponent } from '../../../../components';

import {
    CONTENT_GUIDED_BTN_ID,
    CUSTOM_GUIDED_BTN_ID,
} from './guided-tour-step.directive';

export interface GuidedTourStep {
    elementRef: ElementRef<HTMLElement>;
    element: HTMLElement;
    order: number;
    initialOrder: number;
    title: string;
    text: string | null;
    content: TemplateRef<any> | null;
    scrollOffset: number | null;
    scrollTopMargin: number;
    skip: boolean;
    tourId?: string;
}

export interface GuidedTourActiveStep {
    index: number;
    initialOrder: number;
    visible: boolean;
}

export interface GuidedTourConfig {
    tourId: string;
    hasCustomShowStep?: boolean;
    customShowStep?: Function;
}

const STEP_SCROLL_OFFSET = 220; // min value to show element under sticky headers
const BACKDROP_ANIMATION_TIMINGS = '300ms ease';
const ELEMENT_ACTIVE_CLASS = 'guided-tour-element-active';
const ELEMENT_ACTIVE_PARENT_CLASS = 'guided-tour-element-active-parent';
const ROOT_ELEMENT_ACTIVE_STEP_CLASS = 'guided-tour--step-';
const BODY_ELEMENT_ACTIVE_CLASS = 'guided-tour-active';

@UntilDestroy()
@Component({
    selector: 'guided-tour',
    templateUrl: './guided-tour.component.html',
    styleUrls: ['./guided-tour.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('backdropAnimation', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate(BACKDROP_ANIMATION_TIMINGS, style({ opacity: 1 })),
            ]),
            transition(':leave', [
                animate(BACKDROP_ANIMATION_TIMINGS, style({ opacity: 0 })),
            ]),
        ]),
    ],
    standalone: true,
    imports: [
        AsyncPipe,
        NgTemplateOutlet,
        ButtonComponent,
        IconComponent,
        GuidedTourIndicatorComponent,
    ],
})
export class GuidedTourComponent implements OnInit, AfterViewInit, OnDestroy {
    private document = inject<Document>(DOCUMENT);
    private overlayService = inject(OverlayService);
    private viewContainerRef = inject(ViewContainerRef);
    private bodyService = inject(BodyService);
    private environmentService = inject(EnvironmentService);
    private gridOnTabletService = inject(GridOnTabletService);
    private cd = inject(ChangeDetectorRef);

    @Input() showGuidedTourWidget = true;
    @Input() whenGuidedButtonClickedCb: () => void;
    @Input() customGuideTourButtonClicked: boolean;
    @Input() noScrollOffset: boolean;

    @OnChange('handleTabletView')
    @Input()
    isTabletView: boolean = false;

    @Output() currentStep: EventEmitter<GuidedTourStep> =
        new EventEmitter<GuidedTourStep>();
    @Output() closed: EventEmitter<void> = new EventEmitter<void>();

    activeStep$: Observable<GuidedTourActiveStep>;

    isTourActive = false;

    initialSteps: Map<number, GuidedTourStep> = new Map();
    steps: Map<number, GuidedTourStep> = new Map();
    stepsQuantity = 0;

    activeStep: GuidedTourStep = this.getStepEmptyState();

    controlVariation: string;
    isMobileView$: Observable<boolean>;

    @ContentChild('customGuidedBtn')
    private customGuidedBtn: any;

    @ViewChild('activatorElementRef')
    private activatorElementRef: ElementRef;

    @ViewChild('rootElementRef')
    private rootElementRef: ElementRef;

    @ViewChild('stepTpl', { read: TemplateRef })
    private stepTpl: TemplateRef<any>;

    private activeStepSubject = new Subject<GuidedTourActiveStep>();

    private isMobile: boolean;

    private overlayContainerRef: OverlayContainerRef<TemplateRef<any>> | null;

    private customGuidedBtnStep: GuidedTourStep;
    private contentGuidedBtnStep: GuidedTourStep;

    private hasCustomShowStep: boolean;
    private customShowStep: Function;

    ngOnInit(): void {
        this.activeStep$ = this.activeStepSubject
            .asObservable()
            .pipe(distinctUntilChanged(), untilDestroyed(this));
    }

    ngAfterViewInit(): void {
        if (this.showGuidedTourWidget) {
            this.handleTourActivation();
        }
        this.handleWindowResize();
        this.handleCustomButtonClickedEvent();
    }

    ngOnDestroy(): void {
        this.stopTour();
    }

    startTour(): void {
        this.stepsQuantity = this.getStepsQuantity();

        const firstStepIndex = this.tourHasIntro ? 0 : 1;
        this.openStep(firstStepIndex);

        this.isTourActive = true;
    }

    stopTour(): void {
        this.isTourActive && this.closed.emit();
        this.closeActiveStep();

        this.activeStep = this.getStepEmptyState();
        this.isTourActive = false;
        this.hasCustomShowStep = false;
    }

    goToStep(index: number): void {
        this.closeActiveStep();
        this.openStep(index);
    }

    goToNextStep(): void {
        this.goToStep(this.activeStep.order + 1);
    }

    goToPrevStep(): void {
        this.goToStep(this.activeStep.order - 1);
    }

    getStepsQuantity(): number {
        return this.tourHasIntro ? this.steps.size - 1 : this.steps.size;
    }

    handleStartBtnClick(config: GuidedTourConfig): void {
        if (this.isTourActive) {
            return;
        }

        const { tourId, hasCustomShowStep, customShowStep } = config;
        this.hasCustomShowStep = hasCustomShowStep;
        this.customShowStep = customShowStep;

        if (tourId === CONTENT_GUIDED_BTN_ID) {
            this.handleClickContentGuidedBtn();
            return;
        }

        this.handleClickCustomButton();
    }

    setContentGuidedBtnStep(step: GuidedTourStep): void {
        this.contentGuidedBtnStep = step;
    }

    setCustomGuidedBtnStep(step: GuidedTourStep): void {
        this.customGuidedBtnStep = step;
    }

    showActiveStep(): void {
        this.overlayContainerRef.open();

        this.activeStepSubject.next({
            index: this.activeStep.order,
            initialOrder: this.activeStep.initialOrder,
            visible: true,
        });

        this.toggleElementClass(this.activeStep.element, true);
        this.toggleRootElementClass(this.activeStep.order, true);
        this.toggleParentElementClass(this.activeStep.elementRef, true);
        this.toggleBodyElementClass(this.activeStep.element, true);
    }

    private handleClickCustomButton(): void {
        const contentStepKey = this.getStepKey(CONTENT_GUIDED_BTN_ID);

        contentStepKey &&
            this.initialSteps.set(contentStepKey, this.customGuidedBtnStep);

        this.initGuidedTour();
    }

    private handleClickContentGuidedBtn(): void {
        const customStepKey = this.getStepKey(CUSTOM_GUIDED_BTN_ID);
        const contentStepKey = this.getStepKey(CONTENT_GUIDED_BTN_ID);
        const stepKey =
            customStepKey || contentStepKey || this.initialSteps.size + 1;

        this.initialSteps.set(stepKey, this.contentGuidedBtnStep);

        this.initGuidedTour();
    }

    private normalizeStepsOrder(): void {
        const sortStringKeys = (
            a: [number, GuidedTourStep],
            b: [number, GuidedTourStep],
        ): number => (a[1].initialOrder > b[1].initialOrder ? -1 : 1);
        const normalizedSteps = [...this.initialSteps.entries()]
            .filter((item) => {
                const step = item[1];
                const stepWithoutSkip = !step.skip;
                const stepPlaceholder = step.order === 0;
                const stepBoundingClientRect =
                    step.elementRef.nativeElement.getBoundingClientRect();
                const isStepElemOnTop = this.checkStepElemOnTop(step);

                const stepIsVisible =
                    get(step, 'elementRef.nativeElement.offsetParent') !==
                        null &&
                    stepBoundingClientRect.left >= 0 &&
                    stepBoundingClientRect.right <=
                        (window.innerWidth ||
                            this.document.documentElement.clientWidth) &&
                    isStepElemOnTop;

                return stepWithoutSkip && (stepPlaceholder || stepIsVisible);
            })
            .sort(sortStringKeys)
            .map((step, index, steps) => {
                const orderIndex = steps.length - 1 - index;
                return [orderIndex, { ...step[1], order: orderIndex }];
            });

        // TS does not allow modifying Map but we need to normalize it
        // @ts-ignore
        this.steps = new Map(normalizedSteps);
    }

    private checkStepElemOnTop(step: GuidedTourStep): boolean {
        const tableElems = this.document.getElementsByClassName(
            'fi-grid-table--items',
        );
        const { left, top } =
            step.elementRef.nativeElement.getBoundingClientRect();
        const elemOnTop = document.elementFromPoint(left, top);
        const isStepElemInTable =
            tableElems.length &&
            Array.from(tableElems).some((tableElem) =>
                tableElem.contains(step.elementRef.nativeElement),
            );
        let isStepElemOnTop = true;

        if (isStepElemInTable) {
            isStepElemOnTop =
                step.elementRef.nativeElement.isSameNode(elemOnTop) ||
                step.elementRef.nativeElement.contains(elemOnTop);
        }

        return isStepElemOnTop;
    }

    private handleTabletView(): void {
        this.gridOnTabletService.setViewVariations(this.isTabletView);
        this.isMobileView$ = this.gridOnTabletService.isMobileView$;
    }

    private handleCustomButtonClickedEvent(): void {
        if (this.customGuidedBtn) {
            fromEvent(
                this.customGuidedBtn.customGuidedTourBtn.nativeElement,
                'click',
            )
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    this.initGuidedTour();
                });
        }
    }

    private getStepKey(stepId: string): number | null {
        const mapStep = [...this.initialSteps.entries()].find(
            (item: [number, GuidedTourStep]) => {
                if (item[1].tourId === stepId) {
                    return item;
                }
            },
        );

        return mapStep && mapStep[0];
    }

    private initGuidedTour(): void {
        if (this.whenGuidedButtonClickedCb) {
            this.whenGuidedButtonClickedCb();
            this.cd.detectChanges();
            setTimeout(() => {
                this.initTour();
            });
            return;
        }

        this.initTour();
    }

    private get tourHasIntro(): boolean {
        return !!this.steps.get(0);
    }

    private handleTourActivation(): void {
        fromEvent(this.activatorElementRef.nativeElement, 'click')
            .pipe(untilDestroyed(this))
            .subscribe(() => this.initTour());
    }

    private initTour(): void {
        this.controlVariation = this.getControlVariation(this.isMobile);

        this.normalizeStepsOrder();
        this.startTour();
    }

    private handleWindowResize(): void {
        this.environmentService.windowResize$
            .pipe(
                withLatestFrom(this.isMobileView$),
                tap(([_, isMobile]) => {
                    this.isMobile = isMobile;
                }),
                untilDestroyed(this),
            )
            .subscribe(() => {
                this.stopTour();
            });
    }

    private getControlVariation(isMobile: boolean): string {
        const variation = 'block,no-border,guided-tour';

        return isMobile ? `primary-link,${variation}` : `primary,${variation}`;
    }

    private getOverlayContainerRef(): OverlayContainerRef<
        TemplateRef<any>
    > | null {
        const params = this.isMobile
            ? this.getMobileOverlayParams()
            : this.getDesktopOverlayParams();
        const element = this.activeStep.element || this.activeStep.elementRef;
        const origin = this.isMobile ? null : element;

        return this.overlayService.create(
            this.stepTpl,
            this.viewContainerRef,
            params,
            null,
            origin,
        );
    }

    private getDesktopOverlayParams(): ConnectedOverlayParams {
        return {
            positions: [
                OverlayConnectedPosition.LeftTop,
                OverlayConnectedPosition.RightTop,
                OverlayConnectedPosition.BottomLeft,
                OverlayConnectedPosition.TopLeft,
                OverlayConnectedPosition.TopRight,
                OverlayConnectedPosition.TopLeftInner,
            ],
            scrollStrategy: OverlayScrollStrategy.Block,
        };
    }

    private getMobileOverlayParams(): OverlayParams {
        return {
            position: OverlayGlobalPosition.Bottom,
        };
    }

    private openStep(index: number): void {
        this.activeStep = this.getStep(index);
        this.currentStep.emit(this.activeStep);
        this.overlayContainerRef = this.getOverlayContainerRef();
        this.overlayContainerRef.event$
            .pipe(
                filter((event) => isEscapeModalEvent(event.type)),
                untilDestroyed(this),
            )
            .subscribe(() => this.stopTour());
        const element =
            this.activeStep.element || this.activeStep.elementRef.nativeElement;

        if (this.hasCustomShowStep) {
            this.customShowStep(element, this);

            return;
        }

        const scrollOffset = this.noScrollOffset
            ? 0
            : this.activeStep.scrollOffset;
        const stepScrollOffset = isNumber(scrollOffset)
            ? scrollOffset
            : this.getStepScrollOffset(
                  element,
                  this.activeStep.scrollTopMargin,
              );

        // TODO: refactor after BodyService migration
        fromPromiseWithUnsubscribe(
            this.bodyService.scrollTop(stepScrollOffset) as any,
        ).subscribe(() => {
            this.showActiveStep();
        });
    }

    private closeActiveStep(): void {
        if (this.overlayContainerRef) {
            this.overlayContainerRef.close();
            this.overlayContainerRef = null;

            this.activeStepSubject.next({
                index: this.activeStep.order,
                initialOrder: this.activeStep.initialOrder,
                visible: false,
            });

            this.toggleElementClass(this.activeStep.element, false);
            this.toggleRootElementClass(this.activeStep.order, false);
            this.toggleParentElementClass(this.activeStep.elementRef, false);
            this.toggleBodyElementClass(this.activeStep.element, false);
        }
    }

    private toggleClass(
        element: HTMLElement,
        toggle: boolean,
        className: string,
    ) {
        element.classList[toggle ? 'add' : 'remove'](className);
    }

    private toggleElementClass(element: HTMLElement, toggle: boolean) {
        if (element) {
            this.toggleClass(element, toggle, ELEMENT_ACTIVE_CLASS);
        }
    }

    private toggleRootElementClass(step: number, toggle: boolean) {
        const element = this.rootElementRef.nativeElement;
        const className = `${ROOT_ELEMENT_ACTIVE_STEP_CLASS}${step}`;
        this.toggleClass(element, toggle, className);
    }

    private toggleBodyElementClass(
        activeStepElement: HTMLElement,
        toggle: boolean,
    ) {
        if (activeStepElement) {
            const element = document.body;
            this.toggleClass(element, toggle, BODY_ELEMENT_ACTIVE_CLASS);
        }
    }

    private toggleParentElementClass(
        elementRef: ElementRef<HTMLElement>,
        toggle: boolean,
    ) {
        const parentElement = elementRef.nativeElement.parentElement;

        if (parentElement) {
            this.toggleClass(
                parentElement,
                toggle,
                ELEMENT_ACTIVE_PARENT_CLASS,
            );
        }
    }

    private getStepEmptyState(): GuidedTourStep {
        return {
            elementRef: null,
            element: null,
            order: null,
            initialOrder: null,
            title: '',
            text: '',
            content: null,
            scrollOffset: null,
            scrollTopMargin: null,
            skip: false,
        };
    }

    private getStep(index: number): GuidedTourStep {
        const {
            elementRef,
            element,
            order,
            initialOrder,
            title,
            text,
            content,
            scrollOffset,
            scrollTopMargin,
            skip,
            tourId,
        } = this.steps.get(index);

        return {
            elementRef,
            element,
            order,
            initialOrder,
            title,
            text,
            content,
            scrollOffset,
            scrollTopMargin,
            skip,
            tourId,
        };
    }

    private getStepScrollOffset(
        element: HTMLElement,
        scrollTopMargin: number,
    ): number {
        const bodyRect = document.body.getBoundingClientRect();
        const elemRect = element.getBoundingClientRect();
        const offset = elemRect.top - bodyRect.top;
        const topMargin = scrollTopMargin || STEP_SCROLL_OFFSET;
        const indentedOffset = offset - topMargin;

        // makes sure to have positive offset
        return indentedOffset > 0 ? indentedOffset : 0;
    }
}
