import { NgModule } from '@angular/core';

import { TooltipDirective } from './tooltip.directive';
import { TooltipComponent } from './tooltip.component';

@NgModule({
    imports: [TooltipDirective, TooltipComponent],
    exports: [TooltipComponent, TooltipDirective],
})
export class TooltipModule {}
