import Lottie, {
    AnimationItem,
    AnimationConfigWithPath,
    AnimationConfigWithData,
} from 'lottie-web';

import { TABLET_MAX_PX } from './common';
import actionableInsightsJson from './graphics/Graphic2-Bars.json';
import completeFleetManagementJson from './graphics/Graphic1-Map.json';
import allInOnePlaceJson from './graphics/Graphic3-Devices.json';

// UglifyJsPlugin breaks lottie lib code, so it can not be imported directly
// type LottiePlayer = {
//     loadAnimation(
//         params: AnimationConfigWithPath | AnimationConfigWithData,
//     ): AnimationItem;
// };
// declare const lottie: LottiePlayer;

export class GraphicsController {
    private actionableInsightsGraphic = document.getElementById(
        'actionable-insights-graphic',
    );
    private completeFleetManagementGraphic = document.getElementById(
        'complete-fleet-management-graphic',
    );
    private allInOnePlaceGraphic = document.getElementById(
        'all-in-one-place-graphic',
    );

    constructor() {
        this.initBarsGraphic();
        this.initMapGraphic();
        this.initDevicesGraphic();
    }

    private initBarsGraphic(): void {
        const barsGraphic = this.initAnimation(
            this.actionableInsightsGraphic,
            actionableInsightsJson,
        );

        this.addEvents(this.actionableInsightsGraphic, barsGraphic);
    }

    private initMapGraphic(): void {
        const mapGraphic = this.initAnimation(
            this.completeFleetManagementGraphic,
            completeFleetManagementJson,
        );

        this.addEvents(this.completeFleetManagementGraphic, mapGraphic);
    }

    private initDevicesGraphic(): void {
        const devicesGraphic = this.initAnimation(
            this.allInOnePlaceGraphic,
            allInOnePlaceJson,
        );

        this.addEvents(this.allInOnePlaceGraphic, devicesGraphic);
    }

    private addEvents(el: HTMLElement, animation: AnimationItem): void {
        const isDesktop = window.innerWidth >= TABLET_MAX_PX;
        let timeoutID: any;

        el.addEventListener('mouseenter', () => this.playAnimation(animation));

        el.addEventListener('click', (event) => {
            event.preventDefault();
        });

        if (!isDesktop) {
            window.addEventListener('scroll', () => {
                clearTimeout(timeoutID);

                timeoutID = setTimeout(() => {
                    const isElementVisible = this.isInViewport(el);

                    if (isElementVisible && !isDesktop) {
                        const currentFrame = (animation as any).currentFrame;
                        const lastFrame = (animation as any).totalFrames - 1;

                        if (currentFrame >= lastFrame) {
                            this.playAnimation(animation);
                        }
                    }
                }, 500);
            });
        }
    }

    private playAnimation(animation: AnimationItem): void {
        animation.goToAndPlay(0);
    }

    private initAnimation(
        graphicContainer: HTMLElement,
        graphicAnimation: any,
    ): AnimationItem {
        const animation = Lottie.loadAnimation({
            container: graphicContainer, // the dom element that will contain the animation
            renderer: 'svg',
            loop: false,
            autoplay: false,
            animationData: graphicAnimation,
        });

        const lastFrame = animation.getDuration(true) - 1;
        animation.goToAndStop(lastFrame, true);

        return animation;
    }

    private isInViewport(element: HTMLElement): boolean {
        const bounding = element.getBoundingClientRect();
        const viewHeight = Math.max(
            document.documentElement.clientHeight,
            window.innerHeight,
        );

        return bounding.top >= 0 && bounding.bottom <= viewHeight;
    }
}
