import { NgModule, Type } from '@angular/core';

import {
    SidePanelContentComponent,
    SidePanelHeaderComponent,
    SidePanelFooterComponent,
    SidePanelComponent,
} from './components';

const FEATURES: Array<Type<any> | any[]> = [
    SidePanelContentComponent,
    SidePanelHeaderComponent,
    SidePanelFooterComponent,
    SidePanelComponent,
];

@NgModule({
    imports: [...FEATURES],
    exports: FEATURES,
})
export class SidePanelModule {}
