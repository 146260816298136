import {
    ChangeDetectionStrategy,
    Component,
    NgIterable,
    OnInit,
    Optional,
    QueryList,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';

import { find, isEqual, cloneDeep } from 'lodash';

import {
    ModalAnimationType,
    ModalComponent,
    ModalContentComponent,
    ModalEvent,
    ModalEventType,
    ModalFooterComponent,
    ModalHeaderComponent,
    ModalPosition,
} from '../../../modal';
import { GridColumnDefDirective } from '../../column/column-def.directive';
import { GridSortDirective } from '../sort.directive';
import { GridSorting } from '../../store';
import { GridSortIconComponent } from '../icon/icon.component';
import { MemoizeFuncPipe } from '../../../../pipes';
import { ButtonComponent } from '../../../../components';
import { GridColumnSortDirective } from '../column.directive';

@Component({
    selector: 'fi-grid-sort-picker',
    templateUrl: './picker.component.html',
    styleUrls: ['./picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgTemplateOutlet,
        ModalComponent,
        GridSortDirective,
        GridSortIconComponent,
        MemoizeFuncPipe,
        ButtonComponent,
        ModalComponent,
        ModalHeaderComponent,
        ModalContentComponent,
        ModalFooterComponent,
        GridColumnSortDirective,
    ],
})
export class GridSortPickerComponent implements OnInit {
    @ViewChild('modalWindow', { read: TemplateRef })
    modalWindow: TemplateRef<any>;

    isModalOpen = false;
    modalAnimation = ModalAnimationType.SlideTop;
    modalPosition = ModalPosition.Bottom;

    columns: QueryList<GridColumnDefDirective> &
        NgIterable<GridColumnDefDirective>;
    localSortingState: GridSorting[] = [];

    constructor(@Optional() private commonSort: GridSortDirective) {
        this.columns = this.commonSort.columns;
        this.localSortingState = cloneDeep(this.commonSort.sorting);
    }

    ngOnInit(): void {
        if (!this.commonSort) {
            return;
        }

        this.columns = this.commonSort.columns;
        this.localSortingState = cloneDeep(this.commonSort.sorting);
    }

    openModal(): void {
        this.isModalOpen = true;
    }

    closeModal(): void {
        this.isModalOpen = false;
    }

    handleModalEvent({ type }: ModalEvent): void {
        switch (type) {
            case ModalEventType.Detach: {
                this.localSortingState = cloneDeep(this.commonSort.sorting);
                return;
            }

            case ModalEventType.OutsideClick:
            case ModalEventType.Escape: {
                this.closeModal();
                return;
            }
        }
    }

    handleApplySort(): void {
        this.commonSort.sort(null, this.localSortingState);

        this.closeModal();
    }

    canApply(currentSortState: GridSorting[]): boolean {
        const sortedColumn = find(this.commonSort.sorting, { isActive: true });

        if (!sortedColumn) {
            return false;
        }

        return !isEqual(
            sortedColumn,
            find(currentSortState, { key: sortedColumn.key }),
        );
    }

    handleSortColumns(sorting: GridSorting[]): void {
        this.localSortingState = cloneDeep(sorting);
    }
}
