import {
    Directive,
    EventEmitter,
    Input,
    Output,
    OnChanges,
    OnDestroy,
    Optional,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Subject } from 'rxjs';
import { differenceWith, isEqual, find } from 'lodash';

import { GridRow, nonSelectableItemProperty } from '../models';
import { GridDataSourceDirective } from '../data-source/data-source.directive';

@UntilDestroy()
@Directive({
    selector: '[fiGridRowSelector]',
    standalone: true,
})
export class GridRowSelectorDirective implements OnChanges, OnDestroy {
    readonly stateChanged = new Subject<void>();

    @Input('fiGridRowSelector') selectedRows: GridRow[] = [];
    @Output() readonly fiGridRowSelectorChange = new EventEmitter<GridRow[]>();

    isAllRowSelected = false;
    isSomeRowSelected = false;

    stateChanged$ = this.stateChanged.asObservable().pipe(untilDestroyed(this));

    constructor(@Optional() private gridSource: GridDataSourceDirective) {}

    private get rows(): any[] {
        const { source: { data = [] } = {} } = this.gridSource || {};

        return data.filter((item) => {
            if (nonSelectableItemProperty in item) {
                return !item[nonSelectableItemProperty];
            }

            return true;
        });
    }

    ngOnChanges(): void {
        this.checkIsAllRowsSelected();
        this.stateChanged.next();
    }

    ngOnDestroy(): void {
        this.stateChanged.complete();
    }

    selectRow(row: GridRow, isSelected: boolean): void {
        if (isSelected) {
            this.fiGridRowSelectorChange.emit(
                differenceWith(this.selectedRows, [row], isEqual),
            );
            return;
        }

        this.fiGridRowSelectorChange.emit([...this.selectedRows, row]);
    }

    toggleSelectRows(): void {
        if (this.isAllRowSelected) {
            this.fiGridRowSelectorChange.emit([]);
            return;
        }

        this.fiGridRowSelectorChange.emit(this.rows);
    }

    isRowSelected(row: GridRow): boolean {
        if (!this.selectedRows.length) {
            return false;
        }

        return !!find(this.selectedRows, (selectedRow) =>
            isEqual(selectedRow, row),
        );
    }

    private checkIsAllRowsSelected(): void {
        const rows = this.rows;

        this.isSomeRowSelected = !!this.selectedRows.length;

        if (
            !this.isSomeRowSelected ||
            rows.length !== this.selectedRows.length
        ) {
            this.isAllRowSelected = false;
            return;
        }

        this.isAllRowSelected = true;
    }
}
