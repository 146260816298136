<div
    [fiVariation]="(variation || '') + (readonly ? ',readonly' : '')"
    [fiVariationBase]="'fi-pill'"
    [fiTooltip]="
        (tooltipValue | memoizeFunc: hasEllipsis:this | async) && tooltipTpl
    "
>
    <span
        #pillTitle
        class="
            fi-text fi-text--small fi-text--medium fi-text--white
            fi-pill__title
        "
    >
        <ng-content></ng-content>
    </span>
    <fi-icon
        [svgName]="'times'"
        [variation]="'regular'"
        [size]="'x-small'"
        (click)="handleTimesClick($event)"
    ></fi-icon>
</div>

<ng-template #tooltipTpl>
    <span>{{ tooltipValue }}</span>
</ng-template>
