import { Injectable } from '@angular/core';

import { defer, Subject } from 'rxjs';

@Injectable({
    providedIn: 'any',
})
export class ManualTooltipService {
    readonly autoCloseDelay = 5000;
    readonly tooltipVisible$ = defer(() => this.tooltipVisibilitySubject);

    private tooltipVisibilitySubject = new Subject<boolean>();

    showTooltip(): void {
        this.tooltipVisibilitySubject.next(true);
    }

    showAutoClosingTooltip(): void {
        this.tooltipVisibilitySubject.next(true);
        setTimeout(() => this.hideTooltip(), this.autoCloseDelay);
    }

    hideTooltip(): void {
        this.tooltipVisibilitySubject.next(false);
    }
}
