const LANDING_OVERLAY_OPEN_CLASS = 'landing-overlay--open';
const LANDING_MODAL_OPEN_CLASS = 'landing-modal--open';
const LANDING_PAGE_PUSH_LEFT_CLASS = 'landing-page--push-left';

import { MOBILE_MAX_PX } from './common';

export class ModalController {
    private targetElement: HTMLElement;
    private landingPageElement = document.querySelector('.landing-page');
    private modalTriggers = document.querySelectorAll('.js-open-modal');
    private overlay = document.querySelector('.landing-overlay');
    private closeBtns = document.querySelectorAll('.js-close-modal');

    constructor() {
        this.initModal();
        this.attachEvents();
    }

    manualOpenModal(id: string): void {
        this.targetElement = document.getElementById(id);

        this.open();
    }

    private attachEvents(): void {
        // NodeList works with ES6, not supported by IE11
        const closeBtnArray = Array.prototype.slice.call(this.closeBtns);

        closeBtnArray.forEach((button: any) => {
            button.addEventListener('click', () => this.close());
        });
    }

    get isMobile(): boolean {
        return window.innerWidth < MOBILE_MAX_PX;
    }

    private open(): void {
        this.addBodyStyles();

        this.overlay.classList.add(LANDING_OVERLAY_OPEN_CLASS);
        if (this.isMobile) {
            this.landingPageElement.classList.add(LANDING_PAGE_PUSH_LEFT_CLASS);
        }
        this.targetElement.classList.add(LANDING_MODAL_OPEN_CLASS);
    }

    private close(): void {
        this.removeBodyStyles();

        this.overlay.classList.remove(LANDING_OVERLAY_OPEN_CLASS);
        if (this.isMobile) {
            this.landingPageElement.classList.remove(
                LANDING_PAGE_PUSH_LEFT_CLASS,
            );
        }
        document
            .querySelectorAll('.landing-modal--open')
            .forEach((item: Element) => {
                item.classList.remove(LANDING_MODAL_OPEN_CLASS);
            });
    }

    private initModal(): void {
        // NodeList works with ES6, not supported by IE11
        const modalTriggerArray = Array.prototype.slice.call(
            this.modalTriggers,
        );

        modalTriggerArray.forEach((trigger: any) => {
            trigger.addEventListener('click', (event: Event) => {
                // get id for open modal
                const targetId = (event.target as HTMLElement).getAttribute(
                    'attr-modal',
                );

                // use dynamic target to reference given modal
                this.targetElement = document.getElementById(targetId);

                this.open();
            });
        });
    }

    private addBodyStyles(): void {
        // to prevent scroll when overlay
        const rootElem = document.querySelector('html');
        rootElem.classList.add('cdk-global-scrollblock');
    }

    private removeBodyStyles(): void {
        document.body.style.paddingRight = '';
        const rootElem = document.querySelector('html');
        rootElem.classList.remove('cdk-global-scrollblock');
    }
}
