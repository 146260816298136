import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    ViewEncapsulation,
    inject,
} from '@angular/core';

import { fromEvent, merge, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export type SelectOption = Record<string, any> | string | number;

@Component({
    selector: 'fi-select-option',
    template: '<ng-content></ng-content>',
    styleUrls: ['./option.component.scss'],
    exportAs: 'fiSelectOption',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
})
export class SelectOptionComponent {
    private readonly elementRef = inject(ElementRef);

    @Input() option: SelectOption;
    @Input() disabled = false;

    readonly enterKeyEvent = fromEvent(this.elementRef.nativeElement, 'keydown').pipe(
        filter((event: KeyboardEvent) => event.code === 'Space'),
    );
    readonly clickEvent = fromEvent(this.elementRef.nativeElement, 'click');

    readonly changeEvent$: Observable<SelectOption> = merge(
        this.enterKeyEvent,
        this.clickEvent,
    ).pipe(
        filter(() => !!this.option && !this.disabled),
        map(() => this.option),
    );

    isSelected = false;
}
