import { Directive, Input, TemplateRef, inject } from '@angular/core';

@Directive({
    selector: '[fiFilteringAppliedItemDef]',
    standalone: true,
})
export class FilteringAppliedItemDefDirective {
    templateRef =
        inject<TemplateRef<FilteringAppliedItemDefDirective>>(TemplateRef);

    @Input('fiFilteringAppliedItemDef') key: string | string[];
    @Input('fiFilteringAppliedItemDefGroup') group: boolean;
}
