import {
    ChangeDetectionStrategy,
    Component,
    Input,
    TemplateRef,
    ViewEncapsulation,
} from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';

import { VariationDirective } from '../../directives/variation.directive';

export const enum TooltipVariation {
    Warning = 'warning',
    Preview = 'preview',
    MapMarker = 'map-marker',
    MapMarkerAlert = 'map-marker-alert',
    Light = 'light',
}

@Component({
    selector: 'fi-tooltip-v2',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    animations: [],
    standalone: true,
    imports: [VariationDirective, NgTemplateOutlet],
})
export class TooltipComponent {
    @Input() tpl: TemplateRef<any>;
    @Input() tplContext: any;
    @Input() variation: TooltipVariation;
}
