import { NgModule, Type } from '@angular/core';

import { SelectComponent } from './select.component';
import { SelectOptionComponent } from './option/option.component';
import { SelectCustomDateOptionComponent } from './option/custom-date/custom-date.component';
import { SelectLabelComponent } from './label/label.component';

const FEATURES: Array<Type<any> | any[]> = [
    SelectComponent,
    SelectOptionComponent,
    SelectCustomDateOptionComponent,
    SelectLabelComponent,
];

@NgModule({
    imports: [...FEATURES],
    exports: FEATURES,
})
export class SelectModule {}
