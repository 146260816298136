import {
    Directive,
    ContentChild,
    TemplateRef,
    ContentChildren,
    QueryList,
    NgIterable,
} from '@angular/core';

import { AccordionItemDirective } from '../item/item.directive';
import { AccordionStubDirective } from '../accordion-stub/stub.directive';

import { AccordionGroupHeaderDirective } from './header/header.directive';

@Directive({
    selector: '[fiAccordionGroup]',
    exportAs: 'fiAccordionGroup',
    standalone: true,
})
export class AccordionGroupDirective {
    @ContentChild(AccordionGroupHeaderDirective, {
        read: TemplateRef,
    })
    header: TemplateRef<AccordionGroupHeaderDirective>;

    @ContentChild(AccordionStubDirective, { read: TemplateRef })
    stub: TemplateRef<AccordionStubDirective>;

    @ContentChildren(AccordionItemDirective)
    items: QueryList<AccordionItemDirective> &
        NgIterable<AccordionItemDirective>;
}
