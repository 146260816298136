import {
    Directive,
    ElementRef,
    HostListener,
    Input,
    AfterViewInit,
    Output,
    EventEmitter,
    inject,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { asyncScheduler, of } from 'rxjs';
import { observeOn } from 'rxjs/operators';

/**
 * Directive that scrolls to the element in container (parent element) on boolean condition scrollToElementOnCondition (default value = true)
 */
@UntilDestroy()
@Directive({
    selector: '[scrollToElementOnCondition]',
    standalone: true,
})
export class ScrollToElementOnConditionDirective implements AfterViewInit {
    private readonly el = inject(ElementRef);

    @Input() scrollToElementOnCondition = true;

    @Output() scrollToElementOnConditionChange = new EventEmitter<boolean>();

    @HostListener('scroll')
    onScroll(): void {
        this.scrollToElementOnCondition = false;
        this.scrollToElementOnConditionChange.emit(this.scrollToElementOnCondition);
    }

    ngAfterViewInit(): void {
        this.scrollToElement();
    }

    private get element(): HTMLElement {
        return this.el.nativeElement;
    }

    private scrollToElement(): void {
        if (this.element && this.scrollToElementOnCondition) {
            of(null)
                .pipe(
                    observeOn(asyncScheduler),
                    untilDestroyed(this),
                ).subscribe(() => {
                    this.element.scrollIntoView({ behavior: 'smooth', block: 'end' });
                });
        }
    }
}
