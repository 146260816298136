import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { escapeRegExp } from 'lodash';

import { VariationDirective } from '../../directives';
import { MemoizeFuncPipe } from '../../pipes';

@Component({
    selector: 'fi-highlight-text',
    templateUrl: './highlight-text.component.html',
    styleUrls: ['./highlight-text.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [VariationDirective, MemoizeFuncPipe],
})
export class HighlightTextComponent {
    @Input() fullText: string;
    @Input() highlightQuery: string;
    @Input() variation: string;
    @Input() baseVariation: string;

    result: string[];

    ngOnChanges(): void {
        this.match();
    }

    match(): void {
        const { fullText, highlightQuery } = this;

        if (!this.highlightQuery) {
            this.result = [fullText];
            return;
        }

        const regex = new RegExp(`(${escapeRegExp(highlightQuery)})`, 'gi');

        if (fullText) {
            this.result = fullText.match(regex)
                ? fullText.split(regex)
                : [fullText];
        }
    }

    isMatch([match, highlightQuery]: [string, string]): boolean {
        return match.toLowerCase() === highlightQuery.toLowerCase();
    }
}
