import { Inject, Pipe, PipeTransform, inject } from '@angular/core';

import { Dayjs } from 'dayjs';

import {
    ConfigApp,
    ConfigAppToken,
    TimeFormatConfig,
} from '../services/config.service';
import { DateTimeService } from '../services/date-time.service';

@Pipe({ name: 'timeFormat', standalone: true  })
export class TimeFormatPipe implements PipeTransform {
    private readonly dateTimeService = inject(DateTimeService);

    constructor(
        @Inject(ConfigAppToken) private CONFIG: ConfigApp,
    ) {
    }

    transform(
        time: string | Dayjs,
        type: keyof TimeFormatConfig = 'FULL',
        placeholder: string = this.CONFIG.EMPTY_VALUE,
        isLocalTimeRelated?: boolean,
    ): string {
        return this.dateTimeService.timeFormat(
            time,
            this.CONFIG.TIME_FORMAT[type],
            placeholder,
            isLocalTimeRelated,
        );
    }
}
