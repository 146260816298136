import { MapMarkerTooltipPositions } from './constants';
import { AdvancedMapMarker } from './advanced-map-marker';

export type MapMarkerPopup = google.maps.OverlayView;

enum EventListener {
    addEvent = 'addEventListener',
    removeEvent = 'removeEventListener',
}

enum EventType {
    mouseover = 'mouseenter',
    mouseleave = 'mouseleave',
}

export const createAdvancedMapMarkerPopup = (
    marker: AdvancedMapMarker,
    content: HTMLElement,
    callback?: (value: string | null) => void,
    position?: MapMarkerTooltipPositions,
): MapMarkerPopup => {
    return new (class _ extends google.maps.OverlayView {
        markerId: string;
        containerDiv: HTMLDivElement;
        readonly tooltipBaseClass = 'fi-overlay-pane--position-';

        constructor(
            public marker: AdvancedMapMarker,
            public content: HTMLElement,
            public callback?: (value: string | null) => void,
            public position?: MapMarkerTooltipPositions,
        ) {
            super();
            const { id } = this.marker.markerConfig;
            this.markerId = id;
            // This div is positioned at the bottom of the tip.
            this.containerDiv = document.createElement('div');
            this.containerDiv.classList.add(
                'popup-container',
                'fi-overlay-pane--tooltip-v2',
                'no-margin',
            );
            this.containerDiv.appendChild(this.content);

            // Optionally stop clicks, etc., from bubbling up to the map.
            _.preventMapHitsAndGesturesFrom(this.containerDiv);
        }

        /** Called when the popup is added to the map. */
        onAdd(): void {
            this.getPanes().overlayMouseTarget.appendChild(this.containerDiv);

            this.handleEvents(
                EventListener.addEvent,
                EventType.mouseover,
                this.markerId,
            );
            this.handleEvents(
                EventListener.addEvent,
                EventType.mouseleave,
                null,
            );
        }

        /** Called when the popup is removed from the map. */
        onRemove(): void {
            if (this.containerDiv.parentElement) {
                this.containerDiv.parentElement.removeChild(this.containerDiv);
            }

            this.handleEvents(
                EventListener.removeEvent,
                EventType.mouseover,
                this.markerId,
            );
            this.handleEvents(
                EventListener.removeEvent,
                EventType.mouseleave,
                null,
            );
        }

        /** Called each frame when the popup needs to draw itself. */
        draw(): void {
            const {
                markerSizes: { scaledSize, size },
                label,
            } = this.marker.markerConfig;
            const divPosition = this.getProjection().fromLatLngToDivPixel(
                this.marker.position,
            );
            const tongueOffset = 5;

            this.containerDiv.style.left = divPosition.x + 'px';
            this.containerDiv.style.top =
                divPosition.y -
                (label?.['isOriginPosition']
                    ? (scaledSize?.y || size.y) / 2
                    : scaledSize?.y || size.y) +
                'px';

            if (this.position === MapMarkerTooltipPositions.left) {
                this.containerDiv.classList.add(
                    `${this.tooltipBaseClass}${MapMarkerTooltipPositions.left}`,
                );
                this.containerDiv.style.top = divPosition.y + 'px';
                this.containerDiv.style.left = divPosition.x + 'px';
                this.containerDiv.style.transform = `translate(-${
                    this.containerDiv.offsetWidth +
                    (scaledSize?.y || size.y) / 2 +
                    tongueOffset
                }px, -${
                    this.containerDiv.offsetHeight / 2 +
                    (label?.['isOriginPosition']
                        ? 0
                        : (scaledSize?.y || size.y) / 2)
                }px)`;
            }

            if (this.position === MapMarkerTooltipPositions.leftBottom) {
                this.containerDiv.classList.add(
                    `${this.tooltipBaseClass}${MapMarkerTooltipPositions.leftBottom}`,
                );
                this.containerDiv.style.top = divPosition.y + 'px';
                this.containerDiv.style.left = divPosition.x + 'px';
                this.containerDiv.style.transform = `translate(-${
                    this.containerDiv.offsetWidth +
                    (scaledSize?.y || size.y) / 2 +
                    tongueOffset
                }px, -${
                    label?.['isOriginPosition']
                        ? (scaledSize?.y || size.y) / 2
                        : scaledSize?.y || size.y
                }px)`;
            }

            if (this.position === MapMarkerTooltipPositions.leftTop) {
                this.containerDiv.classList.add(
                    `${this.tooltipBaseClass}${MapMarkerTooltipPositions.leftTop}`,
                );
                this.containerDiv.style.top = divPosition.y + 'px';
                this.containerDiv.style.left = divPosition.x + 'px';
                this.containerDiv.style.transform = `translate(-${
                    this.containerDiv.offsetWidth +
                    (scaledSize?.y || size.y) / 2 +
                    tongueOffset
                }px, -${
                    label?.['isOriginPosition']
                        ? this.containerDiv.offsetHeight -
                          (scaledSize?.y || size.y) / 1.75
                        : this.containerDiv.offsetHeight
                }px)`;
            }

            if (this.position === MapMarkerTooltipPositions.right) {
                this.containerDiv.classList.add(
                    `${this.tooltipBaseClass}${MapMarkerTooltipPositions.right}`,
                );
                this.containerDiv.style.top = divPosition.y + 'px';
                this.containerDiv.style.left = divPosition.x + 'px';
                this.containerDiv.style.transform = `translate(${
                    tongueOffset + (scaledSize?.x || size.x) / 2
                }px, -${
                    this.containerDiv.offsetHeight / 2 +
                    (label?.['isOriginPosition']
                        ? 0
                        : (scaledSize?.y || size.y) / 2)
                }px)`;
            }

            if (this.position === MapMarkerTooltipPositions.rightTop) {
                this.containerDiv.classList.add(
                    `${this.tooltipBaseClass}${MapMarkerTooltipPositions.rightTop}`,
                );
                this.containerDiv.style.top = divPosition.y + 'px';
                this.containerDiv.style.left = divPosition.x + 'px';
                this.containerDiv.style.transform = `translate(${
                    tongueOffset + (scaledSize?.x || size.x) / 2
                }px, -${
                    label?.['isOriginPosition']
                        ? this.containerDiv.offsetHeight -
                          (scaledSize?.y || size.y) / 1.75
                        : this.containerDiv.offsetHeight
                }px)`;
            }

            if (this.position === MapMarkerTooltipPositions.rightBottom) {
                this.containerDiv.classList.add(
                    `${this.tooltipBaseClass}${MapMarkerTooltipPositions.rightBottom}`,
                );
                this.containerDiv.style.top = divPosition.y + 'px';
                this.containerDiv.style.left = divPosition.x + 'px';
                this.containerDiv.style.transform = `translate(${
                    tongueOffset + (scaledSize?.x || size.x) / 2
                }px, -${label?.['isOriginPosition'] ? (scaledSize?.y || size.y) / 2 : scaledSize?.y || size.y}px)`;
            }

            if (this.position === MapMarkerTooltipPositions.bottom) {
                this.containerDiv.classList.add(
                    `${this.tooltipBaseClass}${MapMarkerTooltipPositions.bottom}`,
                );
                this.containerDiv.style.transform = `translate(-50%, ${
                    (scaledSize?.y || size.y) + tongueOffset
                }px)`;
            }

            this.containerDiv.style.display = 'block';
            // @todo: provide correct zIndex
            this.containerDiv.style.zIndex = '99999999';
        }

        private handleEvents(
            eventListener: EventListener,
            type: EventType,
            value: string | null,
        ): void {
            this.containerDiv[eventListener](type, () => {
                this.callback && this.callback(value);
            });
        }
    })(marker, content, callback, position);
};
