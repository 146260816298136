<span
    [fiVariation]="
        [variation, disabled, invariant] | memoizeFunc: getVariation : this
    "
    [fiVariationBase]="'fi-checkbox'"
    class="fi-checkbox"
>
    <input
        [disabled]="disabled"
        [checked]="checked"
        class="fi-checkbox__input"
        tabindex="-1"
        type="checkbox"
    />
    <span
        (click)="changeCheckedStatus($event)"
        (keydown.space)="changeCheckedStatus($event, true)"
        role="button"
        tabindex="{{ disabled ? '-1' : '0' }}"
        class="fi-checkbox__cover"
    >
        @if (invariant) {
            <span class="fi-checkbox__invariant-icon"></span>
        } @else {
            <fi-icon
                [svgName]="'check'"
                [variation]="'solid'"
                [size]="'small'"
                class="fi-checkbox__check-icon"
            ></fi-icon>
        }
    </span>
</span>

<ng-content></ng-content>
