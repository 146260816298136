import { NgModule, Type } from '@angular/core';

import { SelectMenuComponent } from './select-menu.component';
import { SelectMenuOptionComponent } from './option/select-menu-option.component';

const FEATURES: Array<Type<any> | any[]> = [
    SelectMenuComponent,
    SelectMenuOptionComponent,
];

@NgModule({
    imports: [...FEATURES],
    exports: FEATURES,
})
export class SelectMenuModule {}
