import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { VariationDirective } from '../../directives';

@Component({
    selector: 'fi-label',
    templateUrl: './label.component.html',
    styleUrls: ['./label.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [VariationDirective],
})
export class LabelComponent {
    @Input() variation: string;
}
