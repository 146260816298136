import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import { ButtonComponent } from '../../../components';
import { IsDesktopDeviceDirective, IsMobileDeviceDirective } from '../../../directives';
import { AnalyticsModal } from '../../../analytics';
import {
    ModalCloseBarComponent,
    ModalComponent,
    ModalContentComponent,
    ModalFooterComponent,
    ModalHeaderComponent,
    ModalAnimationType,
    ModalEvent,
    ModalEventType,
    ModalVariation,
} from '../../modal';
import { isEscapeModalEvent } from '../../overlay';
import {
    DEFAULT_LEAVE_BTN_TEXT,
    DEFAULT_LEAVE_MODAL_CONFIG,
} from '../config/prevent-leave-modal.config';

export interface LeaveConfirmationModalConfig {
    title: string;
    description: string;
    leaveBtnText?: string;
}

@Component({
    selector: 'leave-confirmation-modal',
    templateUrl: './leave-confirmation-modal.component.html',
    styleUrls: ['./leave-confirmation-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        ModalComponent,
        ModalHeaderComponent,
        ModalCloseBarComponent,
        ModalContentComponent,
        ModalFooterComponent,
        ButtonComponent,
        IsMobileDeviceDirective,
        IsDesktopDeviceDirective,
    ]
})
export class LeaveConfirmationModalComponent {
    @Input() isOpenModal: boolean;
    @Input() modalConfig = DEFAULT_LEAVE_MODAL_CONFIG;
    @Input() gtmClassName: AnalyticsModal;

    @Output() close = new EventEmitter<boolean>();
    @Output() closedOnLeft = new EventEmitter<void>();

    readonly modalAnimation = ModalAnimationType.SlideTop;
    readonly modalVariation = [ModalVariation.FullHeight];

    readonly defaultLeaveBtnText = DEFAULT_LEAVE_BTN_TEXT;

    private isLeavingPage: boolean;

    closeModal(): void {
        this.close.emit(this.isLeavingPage);
    }

    submitLeave(): void {
        this.isLeavingPage = true;
        this.close.emit(this.isLeavingPage);
    }

    handleModalEvent({ type }: ModalEvent): void {
        if (isEscapeModalEvent(type)) {
            this.closeModal();
        }

        if (type === ModalEventType.Detach && this.isLeavingPage) {
            this.isLeavingPage = false;
            this.closedOnLeft.emit();
        }
    }
}
