import { Component, ElementRef, Input, ViewChild } from '@angular/core';

import { VariationDirective } from '../../../directives';

type SkeletonElementVariant = 'block' | 'round' | 'dark';

@Component({
    selector: 'fi-skeleton-element',
    templateUrl: 'skeleton-element.component.html',
    styleUrls: ['./skeleton-element.component.scss'],
    standalone: true,
    imports: [VariationDirective],
})
export class SkeletonElementComponent {
    @Input() variant: SkeletonElementVariant = 'block';

    @ViewChild('contentRef', { read: ElementRef, static: true })
    content: ElementRef;
}
