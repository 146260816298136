import { NgModule } from '@angular/core';

import { ToastComponent } from './toast.component';
import { ToastAnchorDirective } from './toast-anchor.directive';

@NgModule({
    imports: [ToastComponent, ToastAnchorDirective],
    exports: [ToastAnchorDirective],
})
export class ToastModule {}
