import { Injectable } from '@angular/core';

import { shareReplay } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PreventLeaveModalService {
    private modalOpened = new Subject<boolean>();
    modalOpened$ = this.modalOpened
        .asObservable()
        .pipe(shareReplay({ bufferSize: 1, refCount: true }));

    private leavePageSelection = new Subject<boolean>();
    leavePageSelection$ = this.leavePageSelection.asObservable();

    handleAction(isLeave: boolean): void {
        this.leavePageSelection.next(isLeave);
        this.closeModal();
    }

    closeModal(): void {
        this.modalOpened.next(false);
    }

    openModal(): void {
        this.modalOpened.next(true);
    }
}
