export interface Storage<TData = any> {
    get<T = TData>(path: string, defaultValue?: T | null): T | undefined | null;
    set<T = TData>(path: string, value: T): void;
    remove(path: string): void;
    erase(): void;
}

export abstract class CommonStorageManager {
    abstract getLocalStorage(subKey?: string): Storage;
    abstract getSessionStorage(subKey?: string): Storage;
    abstract getInMemoryStorage(subKey?: string): Storage;
    abstract getGlobalLocalStorage(subKey?: string): Storage;
    abstract getGlobalSessionStorage(subKey?: string): Storage;
    abstract getGlobalInMemoryStorage(subKey?: string): Storage;
}
