<div class="fi-time-picker-form">
    <fi-time-picker-input
        [value]="value | memoizeFunc: getValueDate | date: 'hh'"
        [size]="size"
        (increase)="handleHoursChange(addAction)"
        (decrease)="handleHoursChange(subtractAction)"
    ></fi-time-picker-input>

    <fi-time-picker-input
        [value]="value | memoizeFunc: getValueDate | date: 'mm'"
        [size]="size"
        (increase)="handleMinutesChange(addAction)"
        (decrease)="handleMinutesChange(subtractAction)"
    ></fi-time-picker-input>

    <fi-time-picker-input
        [value]="value | memoizeFunc: pmAmLabel : this"
        [size]="size"
        (increase)="handlePmAmChange()"
        (decrease)="handlePmAmChange()"
    ></fi-time-picker-input>
</div>
