<ul
    [fiVariation]="variation"
    [fiVariationBase]="'fi-filtering-list__items'"
    [class.fi-filtering-list__items--tablet-width]="isGridOnTablet$ | async"
>
    @for (filterDef of filterDefs; track filterDef) {
        <li
            [fiVariation]="filterDef.variation"
            [fiVariationBase]="'fi-filtering-list__item'"
        >
            <ng-container
                [ngTemplateOutlet]="filterDef.templateRef"
                [ngTemplateOutletContext]="{
                    state: state,
                    defaultState: defaultState,
                    viewState: viewState,
                    value: state[filterDef.key],
                    updateValue: update.bind(this),
                    updateViewValue: updateViewValue.bind(this),
                }"
            ></ng-container>
        </li>
    }
</ul>
