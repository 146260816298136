import { FlexibleConnectedPositionStrategy } from '@angular/cdk/overlay';
import {
    AnimationBuilder,
    AnimationMetadata,
    AnimationPlayer,
} from '@angular/animations';
import { ViewContainerRef } from '@angular/core';

import { fromEvent, Observable, of, Subject, Subscription } from 'rxjs';
import { filter, first, switchMap, takeUntil } from 'rxjs/operators';
import { get, isFunction } from 'lodash';

import { KeyCode } from '../../../constants';
import { resizeElement } from '../../../utils';

import { OverlayEvent, OverlayEventType } from './event';
import { GlobalOverlay } from './global-overlay';
import { ConnectedOverlay } from './connected-overlay';
import {
    OverlayCloseParams,
    OverlayOpenParams,
    OverlayContext,
} from './params';

export class OverlayContainerRef<T> {
    private readonly event = new Subject<OverlayEvent>();
    private overlaySubscriptions: Subscription = new Subscription();

    event$: Observable<OverlayEvent> = this.event
        .asObservable()
        .pipe(filter((event) => !!event));

    constructor(
        private overlayRef: GlobalOverlay<T> | ConnectedOverlay<T, any>,
        private animationBuilder: AnimationBuilder,
    ) {}

    get overlayElement(): HTMLElement {
        return this.overlayRef.overlayElement;
    }

    applyContent(
        content: T,
        viewContainerRef: ViewContainerRef,
        context?: OverlayContext,
    ): void {
        this.overlayRef.applyContent(content, viewContainerRef, context);
    }

    attachRelativeWidth(): void {
        const origin = this.overlayRef.origin;

        if (!origin) {
            return;
        }

        resizeElement(origin.nativeElement)
            .pipe(takeUntil(this.overlayRef.detachments()))
            .subscribe(({ contentRect: { width } }) => {
                this.overlayRef.updateSize({ width });
            });
    }

    attachRelativePosition(container: HTMLElement): void {
        if (!container) {
            return;
        }

        resizeElement(container)
            .pipe(takeUntil(this.overlayRef.detachments()))
            .subscribe(() => this.overlayRef.updatePosition());
    }

    open(params: OverlayOpenParams = {}): void {
        const { animation } = params;

        // Observable unsubscribe automatically. The unsubscribe doesn't need
        this.overlayRef.attachments().subscribe(() => {
            this.fireEvent(OverlayEventType.Attach);

            of(this.overlayRef.getConfig())
                .pipe(
                    switchMap((config) =>
                        config.positionStrategy instanceof
                        FlexibleConnectedPositionStrategy
                            ? config.positionStrategy.positionChanges
                            : of(true),
                    ),
                    first(),
                )
                .subscribe(() => {
                    this.animate(animation, () => {
                        this.fireEvent(OverlayEventType.Enter);
                        this.attachOutsideClick();
                        this.attachEscapeEvent();
                    });

                    if (!params.withoutFocus) {
                        this.overlayElement.tabIndex = 0;
                        this.overlayElement.focus();
                    }
                });
        });

        // Observable unsubscribe automatically. The unsubscribe doesn't need
        this.overlayRef.detachments().subscribe(() => {
            this.fireEvent(OverlayEventType.Detach);
            this.event.complete();
        });

        this.overlayRef.attach();
    }

    close(params: OverlayCloseParams = {}): void {
        const { animation } = params;

        //in some cases(back button) cdk destroys overlay before animation runs and this way, error appear
        if(!this.overlayRef.hostElement) return;

        this.animate(animation, () => {
            this.fireEvent(OverlayEventType.Leave);
            this.detachOverlay();
        });
    }

    animate(
        animations: AnimationMetadata | AnimationMetadata[],
        onDone?: () => void,
    ): void {
        const player = this.createAnimationPlayer(animations);

        player.onDone(() => {
            if (isFunction(onDone)) {
                onDone();
            }
        });
        player.play();
    }

    private createAnimationPlayer(
        animations: AnimationMetadata | AnimationMetadata[] = [],
    ): AnimationPlayer {
        const animationFactory = this.animationBuilder.build(animations);

        return animationFactory.create(this.overlayRef.hostElement);
    }

    private attachOutsideClick(): void {
        this.overlaySubscriptions.add(fromEvent(document, 'mousedown')
            .pipe(
                filter((event) => this.isClickOutside(event)),
                // todo: find out why takeUntil throws an error
                // takeUntil(this.overlayRef.detachments())
            )
            .subscribe(() => this.fireEvent(OverlayEventType.OutsideClick)));
    }

    private attachEscapeEvent(): void {
        this.overlaySubscriptions.add(fromEvent(document, 'keydown')
            .pipe(
                filter((e: KeyboardEvent) => e.code === KeyCode.Escape),
            )
            .subscribe(() => this.fireEvent(OverlayEventType.Escape)));
    }

    private fireEvent(type: OverlayEventType): void {
        this.event.next(new OverlayEvent(type));
    }

    private isClickOutside(event: Event): boolean {
        const overlayElement = this.overlayRef.overlayElement;
        const originElement = get(this.overlayRef, 'origin.nativeElement');
        const preventClickOnRelativeTo = get(
            this.overlayRef,
            'params.preventClickOnRelativeTo',
        );
        const allowOriginClick =
            preventClickOnRelativeTo &&
            originElement &&
            originElement.contains(event.target);

        return (
            overlayElement &&
            !overlayElement.contains((event as any).target) &&
            !allowOriginClick
        );
    }

    private detachOverlay(): void {
        if (this.overlaySubscriptions) {
            this.overlaySubscriptions.unsubscribe();
        }
        this.overlayRef.detach();
        this.overlayRef.dispose();
    }
}
