import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { OnChange } from '../../decorators';
import { IconComponent } from '../icon';

export const enum Status {
    User = 'USER',
    UserInactive = 'USER_INACTIVE',
    InvoicingUser = 'INVOICING_USER',
    InvoicingUserInactive = 'INVOICING_USER_INACTIVE',
    Admin = 'ADMIN',
    AdminDRM = 'ADMIN_DRM',
    AdminFI = 'ADMIN_FI',
    AdminInactive = 'ADMIN_INACTIVE',
    AdminDRMInactive = 'ADMIN_DRM_INACTIVE',
    AdminFIInactive = 'ADMIN_FI_INACTIVE',
    SuperAdmin = 'SUPER_ADMIN',
    SuperAdminInactive = 'SUPER_ADMIN_INACTIVE',
}

export const AVATAR_TYPES_MAPPING: Record<string, string> = {
    [Status.User]: 'user',
    [Status.UserInactive]: 'user-inactive',
    [Status.InvoicingUser]: 'invoicing',
    [Status.InvoicingUserInactive]: 'invoicing-inactive',
    [Status.Admin]: 'admin-all',
    [Status.AdminFI]: 'admin-fi',
    [Status.AdminDRM]: 'admin-drm',
    [Status.AdminInactive]: 'admin-all-inactive',
    [Status.AdminFIInactive]: 'admin-fi-inactive',
    [Status.AdminDRMInactive]: 'admin-drm-inactive',
    [Status.SuperAdmin]: 'super',
    [Status.SuperAdminInactive]: 'super-inactive',
};

@Component({
    selector: 'fi-status-avatar',
    templateUrl: './status-avatar.component.html',
    styleUrls: ['./status-avatar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [IconComponent]
})
export class StatusAvatarComponent {
    @OnChange('setAvatarType')
    @Input()
    status: Status;

    @Input() size: string;

    avatarType: string;

    setAvatarType(status: Status): void {
        this.avatarType = AVATAR_TYPES_MAPPING[status];
    }
}
