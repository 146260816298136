<button
    [attr.aria-label]="ariaLabel"
    [class.fi-nav-tabs-item--active]="
        [active, routerUrl] | memoizeFunc: getIsActive: this
    "
    [class.fi-nav-tabs-item--disabled]="disabled"
    [fiVariation]="variation"
    [fiVariationBase]="'fi-nav-tabs-item'"
    class="fi-nav-tabs-item"
>
    <ng-content></ng-content>
</button>
