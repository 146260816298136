/* TODO: Move enums in file-upload.constants during code refactor */
export enum UploadType {
    SelectFile = 'SELECT_FILE',
    DragNDrop = 'DRAG_N_DROP',
}

export enum UploadAreaType {
    Users = 'users',
    Groups = 'groups',
    Locations = 'locations',
    FleetData = 'fleet-data',
}
