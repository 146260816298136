import { ActiveDescendantKeyManager, FocusKeyManager } from '@angular/cdk/a11y';
import {
    AfterContentInit,
    Component,
    ContentChildren,
    Input,
    QueryList,
} from '@angular/core';

import { ListItemDirective } from './list-item.directive';

@Component({
    selector: 'fi-list-component',
    template: `
        <ng-content></ng-content>
    `,
    standalone: true,
})
export class ListComponent<T> implements AfterContentInit {
    @ContentChildren(ListItemDirective)
    items: QueryList<ListItemDirective<T>>;

    @Input() autoCapture: boolean;
    @Input() activeDescendantMode = true;

    keyManager:
        | FocusKeyManager<ListItemDirective<T>>
        | ActiveDescendantKeyManager<ListItemDirective<T>>;

    ngAfterContentInit(): void {
        if (this.activeDescendantMode) {
            this.keyManager = new ActiveDescendantKeyManager(this.items)
                .withWrap()
                .withTypeAhead();
        } else {
            this.keyManager = new FocusKeyManager(this.items).withWrap();
        }

        if (this.autoCapture) {
            this.keyManager.setFirstItemActive();
        }
    }
}
