import {
    Component,
    Output,
    EventEmitter,
    Input,
    ChangeDetectionStrategy,
} from '@angular/core';
import { NgClass } from '@angular/common';

import {
    FiIconSize,
    FiIconVariation,
} from '../../../../components/icon/icon.constants';
import { VariationDirective } from '../../../../directives/variation.directive';
import { ButtonComponent } from '../../../../components/button/button.component';
import { IconComponent } from '../../../../components/icon/icon.component';
import { UnitTitleComponent } from '../../../../components/unit-title/unit-title.component';

@Component({
    selector: 'fi-modal-close-bar',
    templateUrl: './close-bar.component.html',
    styleUrls: ['./close-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        VariationDirective,
        ButtonComponent,
        NgClass,
        IconComponent,
        UnitTitleComponent,
    ],
})
export class ModalCloseBarComponent {
    @Input() title: string;

    // TODO: refactor with templates
    @Input() closeIcon = 'times';
    @Input() closeIconSize: FiIconSize = FiIconSize.XLarge;
    @Input() closeIconVariation: FiIconVariation = FiIconVariation.Regular;
    @Input() variation = '';
    @Input() gtmClassName = '';
    @Input() isConnectedUnit = false;

    @Output() close = new EventEmitter<void>();

    handleClose(): void {
        this.close.emit();
    }
}
