<div [fiVariation]="variation" [fiVariationBase]="'fi-nav-tabs'">
    <div class="fi-nav-tabs__items-wrapper" #navTabsWrapper>
        <ng-content></ng-content>
    </div>
    <div
        [class.fi-tabs__active-indicator--top]="activeIndicatorTop"
        [ngStyle]="indicatorStyle$ | async"
        class="fi-nav-tabs__active-indicator"
    ></div>
</div>
