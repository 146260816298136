@if (isDesktopView$ | async) {
    <fi-skeleton [isInitializing]="isInitializing">
        @if (source) {
            <fi-paginator
                [totalItems]="source.totalItems"
                [currentPage]="source.pageNumber"
                [perPage]="source.pageSize"
                (selectPage)="handlePageNumberChange($event)"
            ></fi-paginator>
        }
        <fi-skeleton-element
            class="fi-grid__paginator-skeleton-element"
        ></fi-skeleton-element>
    </fi-skeleton>
}

@if (isMobileView$ | async) {
    <fi-skeleton [isInitializing]="isInitializing">
        @if (hasLoadMoreButton) {
            <fi-button
                [ariaLabel]="'Load More'"
                [variation]="'secondary,mobile-sticky,block'"
                [disabled]="isLoading || !canLoadMore"
                [loading]="isLoading"
                (click)="handlePageNumberChange(source.pageNumber + 1)"
            >
                {{ isLoading ? 'Loading' : 'Load More' }}
            </fi-button>
        }
        <fi-skeleton-element
            class="fi-grid__paginator-skeleton-element"
        ></fi-skeleton-element>
    </fi-skeleton>
}
