import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    OnInit,
    Input,
    Output,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    OnDestroy,
    inject,
} from '@angular/core';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';

import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import dayjs, { Dayjs } from 'dayjs';
import { fromEvent, Subscription } from 'rxjs';

import { ButtonComponent, IconComponent } from '../../../components';
import { IsDesktopDeviceDirective, IsMobileDeviceDirective } from '../../../directives';
import { ModalCloseBarComponent, ModalComponent, ModalEvent, ModalVariation } from '../../../feature/modal';
import {
    OverlayConnectedPosition,
    OverlayContainerRef,
    OverlayService,
    isEscapeModalEvent,
} from '../../../feature/overlay';
import { EnvironmentService } from '../../../services';
import { TimeComponent } from '../../time-picker/time.component';
import { DateRangeState } from '../models/range';

export const LABEL_TIME_FORMAT = 'h:mm A';

export const DEFAULT_LABEL = 'None Set';
export const MOBILE_LABEL = 'Time Set';

export const SAME_TIME_ERROR_MESSAGE = 'Adjust Start or End Time.';
export const START_TIME_ERROR_MESSAGE = 'Start Time is later than End Time.';
export const END_TIME_ERROR_MESSAGE = 'End Time is before the Start Time.';

const DEFAULT_START_HOUR = 0;
const DEFAULT_START_MINUTE = 0;
const DEFAULT_END_HOUR = 23;
const DEFAULT_END_MINUTE = 45;

interface TimePickerDto {
    startTime: string;
    endTime: string;
}

interface TimeFilter {
    hour: number;
    minute: number;
}

@UntilDestroy()
@Component({
    selector: 'fi-time-range-dropdown',
    templateUrl: './time-range-dropdown.component.html',
    styleUrls: ['./time-range-dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AsyncPipe,
        NgTemplateOutlet,
        ButtonComponent,
        IconComponent,
        IsMobileDeviceDirective,
        IsDesktopDeviceDirective,
        ModalComponent,
        ModalCloseBarComponent,
        TimeComponent,
    ]
})
export class TimeRangeDropdownComponent implements OnInit, OnDestroy {
    private readonly overlayService = inject(OverlayService);
    private readonly viewContainerRef = inject(ViewContainerRef);
    private readonly changeDetectorRef = inject(ChangeDetectorRef);
    private readonly environmentService = inject(EnvironmentService);

    @ViewChild('container') containerElRef: ElementRef;

    @ViewChild('dropdown', { read: TemplateRef })
    dropdownTplRef: TemplateRef<HTMLElement>;

    @ViewChild('dropdownContent') dropdownContentRef: ElementRef;

    @Input() dateRange: DateRangeState;

    @Output() timeSubmit = new EventEmitter<DateRangeState>();

    readonly isMobileDevice$ = this.environmentService.isMobileDevice$;
    readonly modalVariation = [
        ModalVariation.FullHeight,
        ModalVariation.Wide600x,
    ];
    readonly START_TIME = 'Start Time';
    readonly END_TIME = 'End Time';
    readonly SUBMIT_BUTTON = 'Select';

    isOpen = false;
    label: string;
    timeZoneName: string;
    startTime: Dayjs;
    endTime: Dayjs;
    errorMessage: string;
    showTimeValidation: boolean;
    isMobileDevice: boolean;

    private overlayContainerRef: OverlayContainerRef<
        TemplateRef<HTMLElement>
    > | null;
    private clickOutsideSubscription: Subscription | null;

    ngOnInit(): void {
        this.handeSubscription();
        this.initData();
    }

    ngOnDestroy(): void {
        this.hideModal();
    }

    toggleDropdown(): void {
        this.isOpen ? this.hideModal() : this.showModal();
    }

    clearTime(): void {
        this.setDefaultTime();
        this.clearValidation();
        this.handleTimeApply();
    }

    handleTimeApply(): void {
        if (this.showTimeValidation) {
            return;
        }

        this.refreshLabel();
        this.emitData();
        this.hideModal();
    }

    startTimeChange(): void {
        this.timeValidation(START_TIME_ERROR_MESSAGE);
    }

    endTimeChange(): void {
        this.timeValidation(END_TIME_ERROR_MESSAGE);
    }

    handleModalEvent({ type }: ModalEvent): void {
        if (isEscapeModalEvent(type)) {
            this.closeModal();
        }
    }

    closeModal(): void {
        this.isOpen = false;
    }

    private handeSubscription(): void {
        this.environmentService.isMobileDevice$
            .pipe(untilDestroyed(this))
            .subscribe(
                (isMobileDevice) => (this.isMobileDevice = isMobileDevice),
            );
    }

    private initData(): void {
        const { from, to } = this.dateRange;

        this.setTimeZoneName();

        if (!from && !to) {
            this.setLabel();
            this.setDefaultTime();

            return;
        }

        this.startTime = from;
        this.endTime = to;

        this.refreshLabel();
    }

    private openModal(): void {
        this.isOpen = true;
    }

    private setLabel(name: string = DEFAULT_LABEL): void {
        this.label = name;
    }

    private setTimeZoneName(): void {
        const gmt = new Date()
            .toString()
            .match(/([A-Z]+[\+-][0-9]+)/)[1]
            .slice(0, 6);
        const tzName = new Date()
            .toLocaleDateString(undefined, {
                day: '2-digit',
                timeZoneName: 'long',
            })
            .substring(4);

        this.timeZoneName = `${tzName} (${gmt})`;
    }

    private setDefaultTime(): void {
        this.startTime = this.getTimePickerFormattedTime(
            DEFAULT_START_HOUR,
            DEFAULT_START_MINUTE,
        );
        this.endTime = this.getTimePickerFormattedTime(
            DEFAULT_END_HOUR,
            DEFAULT_END_MINUTE,
        );
    }

    private refreshLabel(): void {
        if (this.isMobileDevice) {
            this.setLabel(MOBILE_LABEL);

            return;
        }

        const { startTime, endTime } = this.getTimeFromPicker();

        this.setLabel(`${startTime} to ${endTime}`);
    }

    private getTimeFromPicker(): TimePickerDto {
        const startTime = dayjs(this.startTime).format(LABEL_TIME_FORMAT);
        const endTime = dayjs(this.endTime).format(LABEL_TIME_FORMAT);

        return { startTime, endTime };
    }

    private emitData(): void {
        const data = {
            from: this.startTime,
            to: this.endTime,
        };

        this.timeSubmit.emit(data);
    }

    private showModal(): void {
        this.openModal();

        if (!this.isMobileDevice) {
            this.createContainer();
        }
    }

    private hideModal(): void {
        this.closeModal();

        if (!this.isMobileDevice) {
            this.destroyContainer();
        }
    }

    private createContainer(): void {
        this.overlayContainerRef = this.getOverlayContainerRef();
        this.overlayContainerRef.open();

        this.attachClickOutsideListener();
    }

    private destroyContainer(): void {
        if (!this.overlayContainerRef) {
            return;
        }

        this.overlayContainerRef.close();
        this.overlayContainerRef = null;

        this.detachClickOutsideListener();
        this.changeDetectorRef.markForCheck();
    }

    private attachClickOutsideListener(): void {
        this.clickOutsideSubscription = fromEvent(document, 'click')
            .pipe(
                filter(({ target }: Event) => this.filterClickOutside(target)),
            )
            .subscribe(() => this.hideModal());
    }

    private detachClickOutsideListener(): void {
        this.clickOutsideSubscription.unsubscribe();
        this.clickOutsideSubscription = null;
    }

    private filterClickOutside(target: EventTarget): boolean {
        const isToggleClicked =
            this.containerElRef.nativeElement.contains(target);
        const isDropdownClicked =
            this.dropdownContentRef.nativeElement.contains(target);

        return !isToggleClicked && !isDropdownClicked;
    }

    private getOverlayContainerRef(): OverlayContainerRef<
        TemplateRef<HTMLElement>
    > {
        if (this.overlayContainerRef) {
            return this.overlayContainerRef;
        }

        return this.overlayService.create(
            this.dropdownTplRef,
            this.viewContainerRef,
            {
                positions: [OverlayConnectedPosition.BottomLeft],
            },
            null,
            this.containerElRef,
        );
    }

    private timeValidation(message: string): void {
        const { hour: startHour, minute: startMinute } = this.getTimeObject(
            this.startTime,
        );
        const { hour: endHour, minute: endMinute } = this.getTimeObject(
            this.endTime,
        );

        const isSameHour = startHour === endHour;
        const isSameMinute = startMinute === endMinute;

        if (startHour > endHour || (isSameHour && startMinute > endMinute)) {
            this.showTimeValidation = true;
            this.errorMessage = message;
            return;
        }

        if (isSameHour && isSameMinute) {
            this.showTimeValidation = true;
            this.errorMessage = SAME_TIME_ERROR_MESSAGE;
            return;
        }

        this.clearValidation();
    }

    private clearValidation(): void {
        this.showTimeValidation = false;
        this.errorMessage = '';
    }

    private getTimeObject(time: Dayjs): TimeFilter {
        return {
            hour: time.get('hour'),
            minute: time.get('minute'),
        };
    }

    private getTimePickerFormattedTime(hour: number, minute: number): Dayjs {
        return dayjs().set('hour', hour).set('minute', minute);
    }
}
