import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { LocationMapCoordinates } from '../models';
import {
    METRES_IN_ONE_MILE,
    MAX_MAP_RADIUS,
    NORTH_AMERICA_CENTER_COORDINATES,
    DEFAULT_MAP_ZOOM,
} from '../constants';

@Injectable({
    providedIn: 'root',
})
export class LocationMapService {
    geocoder: google.maps.Geocoder;
    autocompleteService: google.maps.places.AutocompleteService;
    placeService: google.maps.places.PlacesService;

    constructor(@Inject(DOCUMENT) private document: Document) {}

    get trigger(): any {
        return google.maps.event.trigger;
    }

    getGpsForPlaceId(placeId: string): Promise<LocationMapCoordinates> {
        return this.loadGpsForPlaceId(placeId);
    }

    getGpsForAddress(address: string): Promise<LocationMapCoordinates> {
        return this.loadGpsForAddress(address);
    }

    getAddressForGps(gps: LocationMapCoordinates): Promise<string> {
        return this.loadAddressForGps(gps);
    }

    getCityStateForGps(gps: LocationMapCoordinates): Promise<string> {
        return this.loadCityStateForGps(gps);
    }

    // TODO: remove when deleting location unit finder
    removeListener(listener: google.maps.MapsEventListener): void {
        google.maps.event.removeListener(listener);
    }

    getLatLng(coords: LocationMapCoordinates): google.maps.LatLng {
        const latitude =
            coords?.latitude || NORTH_AMERICA_CENTER_COORDINATES.latitude;
        const longitude =
            coords?.longitude || NORTH_AMERICA_CENTER_COORDINATES.longitude;

        return new google.maps.LatLng(latitude, longitude);
    }

    isNorthAmericaCenter(instance: any): boolean {
        const { latitude, longitude } = NORTH_AMERICA_CENTER_COORDINATES;

        return instance.center.equals({
            // find out type since center is not present on Map
            lat: () => latitude,
            lng: () => longitude,
        });
    }

    getBounds(): google.maps.LatLngBounds {
        return new google.maps.LatLngBounds();
    }

    getMap(
        element: HTMLElement,
        options: google.maps.MapOptions,
    ): google.maps.Map {
        return new google.maps.Map(element, {
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            zoom: DEFAULT_MAP_ZOOM,
            clickableIcons: false,
            ...options,
        });
    }

    setMapStyles(
        map: google.maps.Map,
        { mapTypeId, styles }: google.maps.MapOptions,
    ) {
        map.setOptions({
            mapTypeId,
            styles,
        });
    }

    // TODO: remove when deleting location unit finder
    getVisibleMapRadius(map: google.maps.Map): number {
        const bounds = map.getBounds();
        const center = map.getCenter();

        if (bounds && center) {
            const ne = bounds.getNorthEast();
            // Calculate radius (in meters).
            const radius =
                google.maps.geometry.spherical.computeDistanceBetween(
                    center,
                    ne,
                );
            const radiusInMiles = this.revertRadiusInMiles(radius);

            return radiusInMiles > MAX_MAP_RADIUS
                ? MAX_MAP_RADIUS
                : radiusInMiles;
        }
    }

    initializeAutocomplete(): void {
        this.autocompleteService = new google.maps.places.AutocompleteService();

        this.placeService = new google.maps.places.PlacesService(
            this.document.createElement('div'),
        );
    }

    initializeGeocoder(): void {
        this.geocoder = new google.maps.Geocoder();
    }

    // TODO: remove when deleting location unit finder
    private revertRadiusInMiles(radius: number): number {
        return Math.floor(radius / METRES_IN_ONE_MILE) || 1;
    }

    private loadGpsForPlaceId(
        placeId: string,
    ): Promise<LocationMapCoordinates> {
        return new Promise<LocationMapCoordinates>((resolve, reject) => {
            this.placeService.getDetails({ placeId }, (result, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    resolve({
                        latitude: result.geometry.location.lat(),
                        longitude: result.geometry.location.lng(),
                    });

                    return;
                }

                reject({
                    error: `PlaceService error: ${status}`,
                });
            });
        });
    }

    private loadGpsForAddress(
        address: string,
    ): Promise<LocationMapCoordinates> {
        return new Promise<any>((resolve, reject) => {
            this.geocoder.geocode(
                { address },
                (
                    results: google.maps.GeocoderResult[],
                    status: google.maps.GeocoderStatus,
                ) => {
                    if (status === google.maps.GeocoderStatus.OK) {
                        const location = results[0].geometry.location;

                        resolve({
                            latitude: location.lat(),
                            longitude: location.lng(),
                        });
                        return;
                    }

                    reject({
                        error: `Geocode was not successful for the following reason: ${status}`,
                    });
                },
            );
        });
    }

    private loadAddressForGps(gps: LocationMapCoordinates): Promise<string> {
        const { latitude: lat, longitude: lng } = gps;

        return new Promise<string>((resolve, reject) => {
            this.geocoder.geocode(
                { location: { lat, lng } },
                (results, status: google.maps.GeocoderStatus) => {
                    if (status === google.maps.GeocoderStatus.OK) {
                        const address = results[0].formatted_address;
                        resolve(address);
                        return;
                    }

                    reject({
                        error: `Geocode was not successful for the following reason: ${status}`,
                    });
                },
            );
        });
    }

    private loadCityStateForGps(gps: LocationMapCoordinates): Promise<string> {
        const { latitude: lat, longitude: lng } = gps;

        return new Promise<string>((resolve, reject) => {
            this.geocoder.geocode(
                { location: { lat, lng } },
                (results, status: google.maps.GeocoderStatus) => {
                    if (status === google.maps.GeocoderStatus.OK) {
                        const [{ address_components: addressComponents }] =
                            results;
                        const city = this.getAddressPart(
                            addressComponents,
                            'locality',
                            'long_name',
                        );
                        const state = this.getAddressPart(
                            addressComponents,
                            'administrative_area_level_1',
                            'short_name',
                        );
                        resolve(this.formatCityState(city, state));
                        return;
                    }

                    reject({
                        error: `Geocode was not successful for the following reason: ${status}`,
                    });
                },
            );
        });
    }

    private formatCityState(
        city: string | undefined,
        state: string | undefined,
    ): string {
        const formattedCity = city ?? '';
        const formattedState = state ?? '';
        const formattedComma = city && state ? ', ' : '';

        return `${formattedCity}${formattedComma}${formattedState}`;
    }

    private getAddressPart(
        addressComponents: google.maps.GeocoderAddressComponent[],
        type: string,
        part: string,
    ): string | undefined {
        return addressComponents.find((component) =>
            component.types.includes(type),
        )?.[part];
    }
}
