import { NgModule } from '@angular/core';

import { PnskCommonModule } from '@pnsk/ui-common/pnsk-common.module';

import { RequestAccessComponent } from './request-access.component';
import { RequestAccessService } from './request-access.service';

@NgModule({
    imports: [PnskCommonModule],
    declarations: [RequestAccessComponent],
    providers: [RequestAccessService],
    exports: [RequestAccessComponent],
})
export class RequestAccessModule {}
