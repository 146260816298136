import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostBinding,
    HostListener,
    Input,
} from '@angular/core';
import { Highlightable } from '@angular/cdk/a11y';

import { Observable, fromEvent, merge } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export type DropdownOption = { [key: string]: any } | string | number;

@Component({
    selector: 'fi-dropdown-item',
    template: '<ng-content></ng-content>',
    styleUrls: ['./item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class DropdownItemComponent implements Highlightable {
    @Input() option: DropdownOption;

    @HostBinding('class.fi-dropdown__item--focused') focused = false;

    @HostBinding('attr.tabindex') tabindex = 0;

    readonly enterKeyEvent = fromEvent(
        this.elementRef.nativeElement,
        'keydown',
    ).pipe(filter((event: KeyboardEvent) => event.code === 'Enter'));
    readonly clickEvent = fromEvent(this.elementRef.nativeElement, 'click');

    isSelected = false;

    changeEvent$: Observable<DropdownOption> = merge(
        this.enterKeyEvent,
        this.clickEvent,
    ).pipe(map(() => this.option));

    constructor(public elementRef: ElementRef) {}

    @HostListener('mousedown', ['$event'])
    onMouseDown(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();
    }

    setActiveStyles(): void {
        this.focused = true;
    }

    setInactiveStyles(): void {
        this.focused = false;
    }
}
