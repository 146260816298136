<div [fiVariation]="variation" [fiVariationBase]="'fi-modal-header'">
    @if (hasBackButton) {
        <fi-button
            [ariaLabel]="'Back'"
            [variation]="'squared,secondary-link'"
            (click)="handleBack()"
            class="fi-modal-header__control"
        >
            <fi-icon
                [svgName]="'chevron-left'"
                [variation]="'regular'"
                [size]="'medium'"
            ></fi-icon>
        </fi-button>
    }

    <div
        [class.fi-modal-header__container--text-centered]="hasBackButton"
        class="fi-modal-header__container"
    >
        <ng-content></ng-content>
    </div>

    @if (!noCloseButton) {
        <fi-button
            [ariaLabel]="'Close'"
            [variation]="'squared,secondary-link'"
            [ngClass]="gtmClassName"
            (click)="handleClose()"
            class="fi-modal-header__control"
        >
            <fi-icon
                [svgName]="'times'"
                [variation]="'regular'"
                [size]="'x-large'"
            ></fi-icon>
        </fi-button>
    }
</div>
