import {
    Component,
    Input,
    OnChanges,
    HostListener,
    HostBinding,
} from '@angular/core';

import { VariationDirective } from '../../directives/variation.directive';
import { IconComponent } from '../icon/icon.component';

type ButtonType = 'button' | 'submit' | 'reset';

@Component({
    selector: 'fi-button',
    templateUrl: 'button.component.html',
    styleUrls: ['./button.component.scss'],
    standalone: true,
    imports: [VariationDirective, IconComponent],
})
export class ButtonComponent implements OnChanges {
    @Input() variation: string;
    @Input() loading: boolean;
    @Input() active: boolean;
    @Input() disabled: boolean;
    @Input() counter: number;
    @Input() type: ButtonType = 'button';
    @Input() ariaLabel: string;

    @HostBinding('attr.tabindex') tabindex = -1;

    classList: string;

    @HostListener('click', ['$event'])
    handleClick(event: Event): void {
        /*
         * Disabled buttons still clickable in IE11
         * TODO: use custom click event name or replace fi-button component with native button tag
         */
        if (this.disabled) {
            event.stopImmediatePropagation();
        }
    }

    ngOnChanges(): void {
        let variations = [];

        if (this.loading) {
            variations.push('loading');
        }

        if (this.variation) {
            variations.push(...this.variation.split(','));
        }

        if (this.active) {
            variations = variations.concat(
                variations.map((variation) => `${variation}--active`),
            );
        }

        this.classList = variations.join(',');

        /**
         * This is to postpone the animation; we do not want it to run when switched from the default.
         */
        setTimeout(() => (this.classList = `${this.classList},animated`));
    }
}
