import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
} from '@angular/core';

import { Chart } from 'highcharts';
import { cloneDeep, find, sumBy } from 'lodash';

import { OnChange } from '../../../decorators';
import { NoChartDataComponent } from '../no-chart-data/no-chart-data.component';
import {
    ChartOptions,
    PieChartData,
    PieOptions,
    SubtitleOptions,
    TitleOptions,
    TooltipOptions,
} from './models';

const DEFAULT_COLOR = '#e4e9ed';
const SKELETON_LIGHT_COLOR = '#edf0f2';
const SKELETON_DARK_COLOR = '#d7dde1';
const DEFAULT_DATA = {
    title: null,
    tab: null,
    color: DEFAULT_COLOR,
    y: 1,
} as PieChartData<string>;

@Component({
    selector: 'fi-pie-chart',
    templateUrl: './pie.component.html',
    styleUrls: ['./pie.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NoChartDataComponent]
})
export class PieComponent implements OnDestroy {
    @OnChange('handleLoading')
    @Input()
    isInitializing: boolean;

    @OnChange('handleData')
    @Input()
    data: Partial<PieChartData<string>>[];

    @OnChange('handleActiveItem')
    @Input()
    activeItem: Partial<PieChartData<string>>;

    @OnChange('handleTitle')
    @Input()
    title: string;

    @OnChange('handleSubtitle')
    @Input()
    subtitle: string;

    @Input() defaultColor: string = DEFAULT_COLOR;
    @Input() chartOptions: ChartOptions = {};
    @Input() pieOptions: PieOptions = {};
    @Input() titleOptions: TitleOptions = {};
    @Input() subtitleOptions: SubtitleOptions = {};
    @Input() tooltipOptions: TooltipOptions = {};
    @Input() minPercentageOfSection: number = 2;

    @Output() enter: EventEmitter<PieChartData<string>> = new EventEmitter<
        PieChartData<string>
    >();
    @Output() leave: EventEmitter<void> = new EventEmitter<void>();
    @Output() click: EventEmitter<PieChartData<string>> = new EventEmitter<
        PieChartData<string>
    >();

    private chart: Chart;
    private chartData: Partial<PieChartData<string>>[];
    private intervalId: number;

    ngOnDestroy(): void {
        this.hideSkeleton();
    }

    private handleActiveItem(): void {
        if (this.chart && this.activeItem) {
            this.updateColors(this.activeItem);
            return;
        }

        if (this.chart) {
            this.setDefaultColors();
        }
    }

    private handleTitle(): void {
        if (this.chart) {
            this.chart.title.update({ text: this.title });
        }
    }

    private handleSubtitle(): void {
        if (this.chart) {
            this.chart.subtitle.update({ text: this.subtitle });
        }
    }

    private updateColors(activeItem: Partial<PieChartData<string>>): void {
        this.chart.series[0].data.map((item: PieChartData<string>) => {
            const color =
                item.tab === activeItem.tab
                    ? item.defaultColor
                    : this.defaultColor;

            item.update({ color });
        });
    }

    private setDefaultColors(): void {
        this.chart.series[0].data.map((item: PieChartData<string>) => {
            item.update({ color: item.defaultColor || this.defaultColor });
        });
    }

    private buildChart(): void {
        this.chart = new Chart('chart-container', {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                margin: [0, 0, 0, 0],
                spacingTop: 0,
                spacingLeft: 0,
                spacingRight: 0,
                ...this.chartOptions,
            },
            title: {
                text: this.title || '',
                ...this.titleOptions,
            },
            subtitle: {
                text: this.subtitle || '',
                ...this.subtitleOptions,
            },
            credits: { enabled: false },
            tooltip: {
                enabled: false,
                ...this.tooltipOptions,
            },
            plotOptions: {
                pie: {
                    dataLabels: { enabled: false },
                    cursor: 'pointer',
                    ...this.pieOptions,
                },
                series: {
                    states: {
                        hover: {
                            enabled: false,
                            halo: {
                                size: 0,
                            },
                        },
                    },
                    point: {
                        events: this.getHandlers(),
                    },
                },
            },
            series: [
                {
                    type: 'pie',
                    data: this.chartData,
                },
            ],
        });
    }

    private getHandlers(): Record<string, unknown> {
        return {
            mouseOver: ({ target }): void => {
                const { options, total } = target;

                if (total === 1) {
                    return;
                }

                this.updateColors(options);

                const item = find(this.data, { tab: options.tab });
                this.enter.emit(item as PieChartData<string>);
            },
            mouseOut: (): void => {
                this.setDefaultColors();
                this.leave.emit();
            },
            click: (event): void => {
                const { point } = event;

                this.click.emit(point);
            },
        };
    }

    private showSkeleton(): void {
        let i = 1;
        this.intervalId = window.setInterval(() => {
            const color = i % 2 ? SKELETON_LIGHT_COLOR : SKELETON_DARK_COLOR;
            this.chart.series[0].data[0].update({ color });
            i++;
        }, 1000);
    }

    private hideSkeleton(): void {
        window.clearInterval(this.intervalId);
    }

    private handleLoading(): void {
        if (this.isInitializing) {
            this.chartData = [DEFAULT_DATA];
            this.buildChart();

            if (!this.intervalId) {
                this.showSkeleton();
            }

            return;
        }

        this.hideSkeleton();
    }

    private handleData(): void {
        if (!this.isInitializing && this.data?.length) {
            setTimeout(() => {
                this.chartData = this.normalizeData();
                this.buildChart();
            });
        }

        if (!this.isInitializing && !this.data) {
            setTimeout(() => this.chart.destroy());
        }
    }

    private normalizeData(): Partial<PieChartData<string>>[] {
        const data = cloneDeep(this.data);
        const minValue = this.getMinDisplayedValue(data);

        return data.map((item) => {
            const { y } = item;

            if (y < minValue) {
                item.y = minValue;
            }

            return item;
        });
    }

    private getMinDisplayedValue(
        data: Partial<PieChartData<string>>[],
    ): number {
        const total = sumBy(data, 'y');

        return Math.round((total / 100) * this.minPercentageOfSection);
    }
}
