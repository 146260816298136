import { InjectionToken } from '@angular/core';

export interface ColumnsSettingsParamsDto {
    column: string;
    order: number;
    required: boolean;
    selected: boolean;
    movable: boolean;
    manageColumnId: number;
}

export interface ColumnsSettingsTabDto {
    tab?: string;
    page?: string;
    columns: ColumnsSettingsParamsDto[];
}

export const COLUMNS_SETTINGS_MAPPING_TOKEN = new InjectionToken<
    Record<string, any>
>(null);
