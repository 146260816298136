import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';

import { IconComponent, ButtonComponent } from '../../../components';
import { IsDesktopDirective } from '../../../directives';
import { MemoizeFuncPipe } from '../../../pipes';
import { OnChange } from '../../../decorators';
import {
    FileFormats,
    FileStateInfo,
    FileValidationErrors,
    UploadAreaType,
} from '../models';
import {
    DEFAULT_FILE_ERROR_INFO,
    DOWNLOAD_ERROR_LOG_BUTTON_LABEL,
    DOWNLOAD_FILE_BUTTON_LABEL,
} from '../constants';
import { UploadStepComponent } from '../upload-step/upload-step.component';

@Component({
    selector: 'drag-n-drop-upload-error',
    templateUrl: './drag-n-drop-upload-error.component.html',
    styleUrls: ['./drag-n-drop-upload-error.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgTemplateOutlet,
        UploadStepComponent,
        IconComponent,
        ButtonComponent,
        IsDesktopDirective,
        MemoizeFuncPipe,
    ],
})
export class DragNDropUploadErrorComponent {
    @OnChange('setErrors')
    @Input()
    errors: FileValidationErrors[];
    @OnChange('setErrorStatesInfo')
    @Input()
    fileErrorStatesInfo: FileStateInfo;
    @Input() fileType: FileFormats = '.xlsx';
    @Input() fileSize: number;
    @Input() uploadType: UploadAreaType;
    @Input() step: number;

    isTypeError: boolean;
    isSizeError: boolean;
    isUploadError: boolean;
    isContentError: boolean;
    isFilesQuantityError: boolean;
    errorStatesInfo: FileStateInfo = DEFAULT_FILE_ERROR_INFO;

    @Output() onNewFileUpload = new EventEmitter<void>();
    @Output() onRetrySendFile = new EventEmitter<void>();
    @Output() onDownloadFile = new EventEmitter<void>();

    readonly uploadAreaType = UploadAreaType;

    uploadNewFile(): void {
        this.onNewFileUpload.emit();
    }

    retrySendFile(): void {
        this.onRetrySendFile.emit();
    }

    downloadFile(): void {
        this.onDownloadFile.emit();
    }

    setErrors(): void {
        this.isTypeError = this.errors.includes(FileValidationErrors.fileType);
        this.isSizeError = this.errors.includes(FileValidationErrors.fileSize);
        this.isUploadError = this.errors.includes(
            FileValidationErrors.fileUpload,
        );
        this.isContentError = this.errors.includes(
            FileValidationErrors.fileContent,
        );
        this.isFilesQuantityError = this.errors.includes(
            FileValidationErrors.filesQuantity,
        );
    }

    showUploadErrorMsg(uploadType: UploadAreaType): boolean {
        return [UploadAreaType.Locations, UploadAreaType.FleetData].includes(
            uploadType,
        );
    }

    getIconLabel(uploadType: UploadAreaType): string {
        if (uploadType === UploadAreaType.FleetData) {
            return DOWNLOAD_ERROR_LOG_BUTTON_LABEL;
        }

        return DOWNLOAD_FILE_BUTTON_LABEL;
    }

    private setErrorStatesInfo(): void {
        if (this.fileErrorStatesInfo) {
            this.errorStatesInfo = {
                ...DEFAULT_FILE_ERROR_INFO,
                ...this.fileErrorStatesInfo,
            };
        }
    }
}
