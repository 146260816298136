import {
    Component,
    Input,
    ChangeDetectionStrategy,
    EventEmitter,
    Output,
    inject,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgClass, NgTemplateOutlet } from '@angular/common';

import { isEmpty } from 'lodash';

import {
    UnitTitleComponent,
    AlertIconComponent,
    HighlightTextComponent,
} from '../../../../components';
import { OnChange } from '../../../../decorators';
import { VariationDirective } from '../../../../directives';
import { UnitAlerts } from '../../../../models';
import { EnvironmentService } from '../../../../services';
import { OverlayConnectedPosition } from '../../../overlay';
import { TooltipDirective } from '../../../tooltip';

type UnitRowVariation = 'no-paddings' | 'medium-label';

@Component({
    selector: 'fi-modal-unit-row',
    templateUrl: './modal-unit-row.component.html',
    styleUrls: ['./modal-unit-row.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        VariationDirective,
        UnitTitleComponent,
        AlertIconComponent,
        TooltipDirective,
        HighlightTextComponent,
    ],
})
export class ModalUnitRowComponent {
    private router = inject(Router);
    private environmentService = inject(EnvironmentService);

    @Input() unitNumber: string;
    @Input() nonContractUnitNumber: string;
    @Input() isNonContractUnit: boolean;
    @Input() showSecondField = true;
    @Input() secondField: string;
    @Input() isConnectedUnit: boolean;
    @Input() hasNoUnitRedirect: boolean;
    @Input() query: string;
    @Input() variation: UnitRowVariation;
    @Input() disabled: boolean;
    @OnChange('updateHasAlerts')
    @Input()
    alerts: UnitAlerts;

    @Output() unitClicked = new EventEmitter<void>();

    readonly isMobileOS = this.environmentService.isMobileOS;
    readonly mobileOSInfoTooltipPositions = [
        OverlayConnectedPosition.Right,
        OverlayConnectedPosition.Left,
    ];

    hasAlerts: boolean;

    updateHasAlerts(): void {
        this.hasAlerts = !isEmpty(this.alerts);
    }

    handleUnitClicked(): void {
        if (this.hasNoUnitRedirect) {
            this.unitClicked.emit();
            return;
        }

        this.router.navigate(['unit/', this.unitNumber]);
    }
}
