/** Interface for a directive that holds sorting state consumed by `MatSortHeader`. */
import {
    ContentChildren,
    Directive,
    EventEmitter,
    Input,
    NgIterable,
    Output,
    QueryList,
    OnChanges,
    OnDestroy,
} from '@angular/core';

import { Subject } from 'rxjs';

import { GridColumnDefDirective } from '../column/column-def.directive';
import { GridSorting } from '../store';
import { SortingOrder } from '../../../models';

/** Container for MatSortables to manage the sort state and provide default sort parameters. */
@Directive({
    selector: '[fiGridSort]',
    exportAs: 'fiGridSort',
    standalone: true,
})
export class GridSortDirective implements OnChanges, OnDestroy {
    // Used to notify child components listening to state changes.
    readonly stateChanged = new Subject<void>();

    @Input('fiGridSort') sorting: GridSorting[];

    @Output() readonly fiGridSortChange = new EventEmitter<GridSorting[]>();

    @ContentChildren(GridColumnDefDirective)
    columns: QueryList<GridColumnDefDirective> &
        NgIterable<GridColumnDefDirective>;

    sort(key: string, appliedSorting?: GridSorting[]): void {
        this.fiGridSortChange.emit(
            appliedSorting || this.sortColumnCollection(key, this.sorting),
        );
    }

    ngOnChanges(): void {
        this.stateChanged.next();
    }

    ngOnDestroy(): void {
        this.stateChanged.complete();
    }

    private sortColumnCollection(
        fieldKey: string,
        sortingState: GridSorting[],
    ): GridSorting[] {
        const { order = SortingOrder.Asc, isActive = false } =
            sortingState.find(({ key }) => key === fieldKey) || {};

        return sortingState
            .filter(({ key }) => key !== fieldKey)
            .map((column) => ({
                ...column,
                isActive: false,
            }))
            .concat([
                {
                    key: fieldKey,
                    isActive: true,
                    order: isActive
                        ? this.getOppositeSortingOrder(order)
                        : order,
                },
            ]);
    }

    private getOppositeSortingOrder(order: SortingOrder): SortingOrder {
        return order === SortingOrder.Asc
            ? SortingOrder.Desc
            : SortingOrder.Asc;
    }
}
