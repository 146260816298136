<div class="fi-range-calendar">
    @if (maxDatesInRange) {
        <div
            [class.fi-range-calendar__disclaimer--error]="
            isAlertBannerOutsideOfRange()
        "
            class="fi-range-calendar__disclaimer fi-text"
        >
            @if (isAlertBannerOutsideOfRange()) {
                <fi-icon
                    [svgName]="'warning'"
                    [variation]="'custom'"
                    [size]="'xxx-large'"
                ></fi-icon>

                Date range exceeds
                {{
                    maxDatesInRange.rangeType
                        | pluralize: maxDatesInRange.maxDates : true
                }}.
            } @else {
                @if (rangeTitle) {
                    {{ rangeTitle }}
                } @else {
                    Select a date range within
                    {{
                        maxDatesInRange.rangeType
                            | pluralize: maxDatesInRange.maxDates : true
                    }}.
                }
            }
        </div>
    }

    @if (!calendarView) {
        <div class="fi-range-calendar__range">
            <div class="fi-range-calendar__range-input">
                <span
                    class="fi-range-calendar__range-input-label fi-text fi-text--medium fi-text--small fi-text--light-gray"
                >
                    {{ fromTitle || 'From' }}
                </span>

                <div
                    [ngClass]="{
                        'fi-range-calendar__range-input-control-wrapper--activated':
                            selectedState.from?.isValid(),
                        'fi-range-calendar__range-input-control-wrapper--error':
                            !validations.from.valid
                    }"
                    class="fi-range-calendar__range-input-control-wrapper"
                >
                    <fi-input
                        [name]="'Pick-up Date'"
                        [variation]="'no-border'"
                        [type]="'text'"
                        [value]="fromInputState"
                        [format]="'dateSecondary'"
                        [placeholder]="fromDate.focused ? '' : '__/__/__'"
                        (valueChange)="onFromChanged($event)"
                        (blur)="onInputBlur($event, dateRangeSelectionMode.From)"
                        class="fi-range-calendar__range-input-control"
                        #fromDate
                    ></fi-input>

                    @if (selectedState.from?.isValid()) {
                        <fi-icon
                            [svgName]="'times'"
                            [size]="'small'"
                            [color]="'oslo-gray'"
                            (click)="onClearDate(dateRangeSelectionMode.From)"
                            class="fi-range-calendar__range-input-clear-icon"
                        ></fi-icon>
                    }

                    <div
                        [class.fi-range-calendar__range-input-calendar-icon--activated]="
                            selectedState.from?.isValid()
                        "
                        class="fi-range-calendar__range-input-calendar-icon"
                    >
                        <fi-icon
                            [svgName]="'calendar-alt'"
                            [size]="'large'"
                        ></fi-icon>
                    </div>
                </div>

                @if (!validations.from.valid) {
                    <ng-container
                        [ngTemplateOutlet]="errorMessageTpl"
                    ></ng-container>
                }
            </div>

            @if (!singleDateMode) {
                <div class="fi-range-calendar__range-input">
                    <span
                        class="fi-range-calendar__range-input-label fi-text fi-text--medium fi-text--small fi-text--light-gray"
                    >
                        {{ toTitle || 'To' }}
                    </span>

                    <div
                        [ngClass]="{
                            'fi-range-calendar__range-input-control-wrapper--activated':
                                selectedState.to?.isValid(),
                            'fi-range-calendar__range-input-control-wrapper--error':
                                !validations.to.valid
                        }"
                        class="fi-range-calendar__range-input-control-wrapper"
                    >
                        <fi-input
                            [name]="'Drop-off Date'"
                            [variation]="'no-border'"
                            [type]="'text'"
                            [value]="toInputState"
                            [format]="'dateSecondary'"
                            [placeholder]="toDate.focused ? '' : '__/__/__'"
                            (valueChange)="onToChanged($event)"
                            (blur)="onInputBlur($event, dateRangeSelectionMode.To)"
                            class="fi-range-calendar__range-input-control"
                            #toDate
                        ></fi-input>

                        @if (selectedState.to?.isValid()) {
                            <fi-icon
                                [svgName]="'times'"
                                [size]="'small'"
                                [color]="'expandable-type'"
                                (click)="onClearDate(dateRangeSelectionMode.To)"
                                class="fi-range-calendar__range-input-clear-icon"
                            ></fi-icon>
                        }

                        <div
                            [class.fi-range-calendar__range-input-calendar-icon--activated]="
                                selectedState.to?.isValid()
                            "
                            class="fi-range-calendar__range-input-calendar-icon"
                        >
                            <fi-icon
                                [svgName]="'calendar-alt'"
                                [size]="'large'"
                            ></fi-icon>
                        </div>
                    </div>

                    @if (!validations.to.valid) {
                        <ng-container
                            [ngTemplateOutlet]="errorMessageTpl"
                        ></ng-container>
                    }
                </div>
            } @else {
                <div class="fi-range-calendar__range-to-date-message">
                    <span
                        class="fi-text fi-text--small fi-text--medium fi-text--light-gray"
                    >
                        {{ toDateMessageInfo }}
                    </span>
                </div>
            }
        </div>
    }

    <div class="fi-range-calendar__months-titles">
        @let isPrevControlDisabled = currentMonth | memoizeFunc: prevControlDisabled : this;
        @let isNextControlDisabled = currentMonth | memoizeFunc: nextControlDisabled : this;

        <div class="fi-range-calendar__months-title">
            @if (!isPrevControlDisabled) {
                <button
                    (click)="onNavigateBackMonth()"
                    aria-label="Navigate next month"
                    class="fi-range-calendar__months-navigator fi-range-calendar__months-navigator--prev"
                >
                    <fi-icon
                        [svgName]="'arrow-left'"
                        [variation]="'regular'"
                        [size]="'x-small'"
                    ></fi-icon>
                </button>
            }

            <span class="fi-text fi-text--heavy fi-text--black">
                {{ currentMonth.format('MMMM YYYY') }}
            </span>

            <ng-container *fiIsMobileDevice>
                @if (!isNextControlDisabled) {
                    <button
                        (click)="onNavigateNextMonth()"
                        aria-label="Navigate next month"
                        class="fi-range-calendar__months-navigator fi-range-calendar__months-navigator--next"
                    >
                        <ng-container
                            *ngTemplateOutlet="arrowRightIcon"
                        ></ng-container>
                    </button>
                }
            </ng-container>
        </div>

        <div *fiIsDesktopDevice class="fi-range-calendar__months-title">
            <span class="fi-text fi-text--heavy fi-text--black">
                {{ nextMonth.format('MMMM YYYY') }}
            </span>

            @if (!isNextControlDisabled) {
                <button
                    (click)="onNavigateNextMonth()"
                    aria-label="Navigate next month"
                    class="fi-range-calendar__months-navigator fi-range-calendar__months-navigator--next"
                >
                    <ng-container *ngTemplateOutlet="arrowRightIcon"></ng-container>
                </button>
            }
        </div>
    </div>

    <div class="fi-range-calendar__calendar-wrapper">
        <div class="fi-range-calendar__calendar-month">
            <ng-container
                *ngTemplateOutlet="
                    weekDayTpl;
                    context: { daysOfWeek: daysOfWeek }
                "
            ></ng-container>

            <ng-container
                *ngTemplateOutlet="
                    dayTpl;
                    context: { days: days, month: currentMonth }
                "
            ></ng-container>
        </div>

        <div *fiIsDesktopDevice class="fi-range-calendar__calendar-month">
            <ng-container
                *ngTemplateOutlet="
                    weekDayTpl;
                    context: { daysOfWeek: daysOfWeek }
                "
            ></ng-container>

            <ng-container
                *ngTemplateOutlet="
                    dayTpl;
                    context: { days: nextMonthDays, month: nextMonth }
                "
            ></ng-container>
        </div>
    </div>
</div>

<ng-template #weekDayTpl let-daysOfWeek="daysOfWeek">
    <div class="fi-range-calendar__calendar-weekdays">
        @for (dayOfWeek of daysOfWeek; track dayOfWeek) {
            <div
                class="fi-range-calendar__calendar-weekday"
            >
                <span
                    class="fi-text fi-text--small fi-text--medium fi-text--lighter"
                >
                    {{ dayOfWeek }}
                </span>
            </div>
        }
    </div>
</ng-template>

<ng-template #dayTpl let-days="days" let-month="month">
    <div
        autoFocus
        focusTarget="fi-range-calendar__calendar-day--selected"
        focusTargetSecondary="fi-range-calendar__calendar-day--today"
        class="fi-range-calendar__calendar-days"
    >
        @for (day of days; track $index) {
            <button
                [fiTooltip]="day | memoizeFunc: getDayTooltip : this"
                [ngClass]="{
                    'fi-range-calendar__calendar-day--selected':
                        (day | memoizeFunc: isFromOrToDate : this),
                    'fi-range-calendar__calendar-day--in-range': isDayInRange(day),
                    'fi-range-calendar__calendar-day--outside-of-month':
                        [month, day] | memoizeFunc: isDateFromSeparateMonth : this,
                    'fi-range-calendar__calendar-day--disabled':
                        [month, day] | memoizeFunc: isDateDisabled : this,
                    'fi-range-calendar__calendar-day--outside-of-range-hovered':
                        isDateOutsideOfRangeHovered(day),
                    'fi-range-calendar__calendar-day--outside-of-range':
                        isDayOutsideOfRange(day)
                }"
                (click)="onSelectDate(day)"
                (mouseover)="onMouseOver(day)"
                (mouseout)="onMouseOut()"
                class="fi-range-calendar__calendar-day"
                type="button"
            >
                <ng-container
                    *ngTemplateOutlet="
                        dayValueTpl;
                        context: { day: day, month: month }
                    "
                ></ng-container>
            </button>
        }
    </div>
</ng-template>

<ng-template #dayValueTpl let-day="day" let-month="month">
    <span
        class="fi-range-calendar__calendar-day-value fi-text fi-text--medium fi-text--small fi-text--dark-gray"
    >
        {{ day ? day.format('D') : '' }}
    </span>
</ng-template>

<ng-template #dayDisabledTooltipTpl>
    <div class="fi-range-calendar__calendar-day-tooltip">
        This day cannot be selected
    </div>
</ng-template>

<ng-template #dayOutsideOfRangeTooltipTpl>
    <div class="fi-range-calendar__calendar-day-tooltip">
        Date range exceeds
        {{
            maxDatesInRange.rangeType
                | pluralize: maxDatesInRange.maxDates : true
        }}
    </div>
</ng-template>

<ng-template #arrowRightIcon>
    <fi-icon
        [svgName]="'arrow-right'"
        [variation]="'regular'"
        [size]="'x-small'"
    ></fi-icon>
</ng-template>

<ng-template #errorMessageTpl>
    <span class="fi-text fi-text--error fi-text--medium fi-text--x-small">
        Date entered could not be selected.
    </span>
</ng-template>
