import { Dayjs } from 'dayjs';

import {
    DateRangeSelectionMode,
    DateRangeState,
    MaxDatesInRange,
} from '../../models';

export interface DateOutsideOfRange {
    date: Dayjs;
    selectedState: DateRangeState;
    maxDatesInRange: MaxDatesInRange;
    selectionMode: DateRangeSelectionMode;
}

export const isFromMode = (selectionMode: DateRangeSelectionMode): boolean => {
    return selectionMode === DateRangeSelectionMode.From;
};

export const isToMode = (selectionMode: DateRangeSelectionMode): boolean => {
    return selectionMode === DateRangeSelectionMode.To;
};

export const isDateBeforeRange = ({
    date,
    selectedState,
    maxDatesInRange,
    selectionMode,
}: DateOutsideOfRange): boolean => {
    return (
        maxDatesInRange &&
        date?.isValid() &&
        selectedState.to?.isValid() &&
        date.isSameOrBefore(
            selectedState.to.subtract(
                maxDatesInRange.maxDates,
                maxDatesInRange.rangeType,
            ),
            'day',
        ) &&
        isFromMode(selectionMode)
    );
};

export const isDateAfterRange = ({
    date,
    selectedState,
    maxDatesInRange,
    selectionMode,
}: DateOutsideOfRange): boolean => {
    return (
        maxDatesInRange &&
        date?.isValid() &&
        selectedState.from?.isValid() &&
        date.isSameOrAfter(
            selectedState.from.add(
                maxDatesInRange.maxDates,
                maxDatesInRange.rangeType,
            ),
            'day',
        ) &&
        isToMode(selectionMode)
    );
};

export const isDateOutsideOfRange = (
    dateOutsideRange: DateOutsideOfRange,
): boolean => {
    return (
        isDateAfterRange(dateOutsideRange) ||
        isDateBeforeRange(dateOutsideRange)
    );
};

export const getDatesDifferenceInMonths = (
    baseMonth: Dayjs,
    secondMonth: Dayjs,
): number => {
    return Math.abs(baseMonth.diff(secondMonth, 'month'));
};

export const isDateBetweenFromAndHovered = (
    date: Dayjs,
    dateHovered: Dayjs,
    { from, to }: DateRangeState,
): boolean => {
    return (
        from?.isValid() && !to?.isValid() && date.isBetween(from, dateHovered)
    );
};

export const isDateBetweenToAndHovered = (
    date: Dayjs,
    dateHovered: Dayjs,
    { from, to }: DateRangeState,
): boolean => {
    return to?.isValid() && !from?.isValid() && date.isBetween(dateHovered, to);
};

export const isDateInDisabledDaysOfWeek = (
    date: Dayjs,
    disabledDaysOfWeek: number[],
): boolean => {
    if (!disabledDaysOfWeek) {
        return false;
    }

    return disabledDaysOfWeek.some((dayNumber) => date.day() === dayNumber);
};

export const isDateOutsideOfMinMaxDates = (
    date: Dayjs,
    minDateAvailable: Dayjs,
    maxDateAvailable: Dayjs,
): boolean => {
    return (
        (minDateAvailable && date.isBefore(minDateAvailable, 'day')) ||
        (maxDateAvailable && date.isAfter(maxDateAvailable, 'day'))
    );
};
