import {
    ChangeDetectorRef,
    Directive,
    ElementRef,
    HostBinding,
    Input,
    Output,
    EventEmitter,
    HostListener,
    inject,
} from '@angular/core';
import { Highlightable } from '@angular/cdk/a11y';

@Directive({
    selector: '[fiListItem]',
    standalone: true,
})
export class ListItemDirective<T> implements Highlightable {
    private readonly element = inject(ElementRef);
    private readonly cd = inject(ChangeDetectorRef);

    @Input() option: T;
    @Input() label: string;

    @Output() activeOptionChange = new EventEmitter<T>();
    @Output() optionFocused = new EventEmitter<ListItemDirective<T>>();

    private isItemActive = false;

    @HostBinding('class.active') get isActive(): boolean {
        return this.isItemActive;
    }

    @HostListener('focus')
    onFocus(): void {
        this.optionFocused.emit(this);
    }

    @HostListener('blur')
    onBlur(): void {
        this.setInactiveStyles();
    }

    getLabel(): string {
        return this.label;
    }

    setActiveStyles(): void {
        this.isItemActive = true;
        this.activeOptionChange.emit(this.option);
        this.cd.detectChanges();
    }

    setInactiveStyles(): void {
        this.isItemActive = false;
    }

    focus(): void {
        this.element.nativeElement.focus();
    }
}
