import {
    AfterContentInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    EventEmitter,
    Input,
    Output,
    QueryList,
    ViewEncapsulation,
    inject,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { merge } from 'rxjs';
import { startWith, switchMap, tap } from 'rxjs/operators';
import { find, flatten, isEqual, xorWith } from 'lodash';

import { OnChange } from '../../decorators';
import { VariationDirective } from '../../directives';

import { SelectOption, SelectOptionComponent } from './option/option.component';

export const enum SelectVariation {
    Justify = 'justify',
    Row = 'row',
    RowWithGap = 'row-with-gap',
    RowReverse = 'row-reverse',
    ColumnReverse = 'column-reverse',
    SpaceBetween = 'space-between',
    NoMargin = 'no-margin',
    Wrap = 'wrap',
    Mobile = 'mobile',
}

@UntilDestroy()
@Component({
    selector: 'fi-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [VariationDirective],
})
export class SelectComponent implements AfterContentInit {
    private cd = inject(ChangeDetectorRef);

    @Input() multiple: boolean;
    @Input() variation: SelectVariation | SelectVariation[];

    @OnChange('updateSelectedFlag')
    @Input()
    selected: SelectOption | SelectOption[] = [];

    @Output() optionChange = new EventEmitter<SelectOption | SelectOption[]>();

    @ContentChildren(SelectOptionComponent)
    private options: QueryList<SelectOptionComponent>;

    ngAfterContentInit(): void {
        this.options.changes
            .pipe(
                startWith(this.options),
                // timeout to prevent ExpressionChangedAfterItHasBeenCheckedError
                tap(() => setTimeout(() => this.updateSelectedFlag(), 0)),
                switchMap((options) =>
                    merge(
                        ...options.map(
                            (option: SelectOptionComponent) =>
                                option.changeEvent$,
                        ),
                    ),
                ),
                untilDestroyed(this),
            )
            .subscribe((option: SelectOption) => this.handleClick(option));
    }

    private handleClick(option: SelectOption): void {
        this.toggleOptionSelection(option);
        this.updateSelectedFlag();
        this.optionChange.emit(this.selected);
    }

    private isOptionSelected(option: SelectOption): boolean {
        return !!find(flatten([this.selected]), (selectedItem: SelectOption) =>
            isEqual(option, selectedItem),
        );
    }

    private toggleOptionSelection(option: SelectOption): void {
        const isItemSelected = this.isOptionSelected(option);

        if (this.multiple) {
            this.toggleMultipleSelection(option, isItemSelected);
            return;
        }

        this.toggleSingleSelection(option);
    }

    private toggleSingleSelection(option: SelectOption): void {
        this.selected = option;
    }

    private toggleMultipleSelection(
        option: SelectOption,
        isOptionSelected: boolean,
    ): void {
        if (isOptionSelected) {
            this.selected = xorWith(
                [option],
                flatten([this.selected]),
                isEqual,
            );

            return;
        }

        // @ts-ignore
        this.selected = [...(this.selected || []), option];
    }

    private updateSelectedFlag(): void {
        if (!this.options) {
            return;
        }

        this.options.forEach((optionComponent: SelectOptionComponent) => {
            optionComponent.isSelected = !!find(
                flatten([this.selected]),
                (selectedItem: SelectOption) =>
                    isEqual(optionComponent.option, selectedItem),
            );
        });

        this.cd.markForCheck();
    }
}
