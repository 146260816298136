import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';

import { ButtonComponent, IconComponent } from '../../../components';
import { IsDesktopDeviceDirective, IsMobileDeviceDirective } from '../../../directives';
import {
    ModalCloseBarComponent,
    ModalComponent,
    ModalContentComponent,
    ModalFooterComponent,
    ModalVariation
} from '../../modal';
import { SimpleDropdownComponent } from '../../dropdown';
import { DateRangeState, RangeCalendarCurrentMonthPosition } from '../models';
import { DateRangeCalendarComponent } from '../range-calendar/range-calendar.component';

@Component({
    selector: 'fi-range-dropdown',
    templateUrl: './range-dropdown.component.html',
    styleUrls: ['./range-dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgTemplateOutlet,
        SimpleDropdownComponent,
        DateRangeCalendarComponent,
        ButtonComponent,
        IconComponent,
        ModalComponent,
        ModalCloseBarComponent,
        ModalContentComponent,
        ModalFooterComponent,
        IsMobileDeviceDirective,
        IsDesktopDeviceDirective,
    ]
})
export class DateRangeDropdownComponent {
    @Input() availableDateRange: DateRangeState;
    @Input() dateRange: DateRangeState;
    @Input() singleDateMode: boolean;

    @Output() selectDates = new EventEmitter<DateRangeState>();

    @ViewChild('rangeDropdownToggle', { read: ElementRef })
    rangeDropdownButton: ElementRef;

    readonly dateFormat = 'MM/DD/YY';
    readonly currentMonthPosition = RangeCalendarCurrentMonthPosition.right;
    readonly modalVariation = [ModalVariation.FullHeight];

    isOpen = false;
    tempDateRange: DateRangeState;

    onDatesSelect(): void {
        this.selectDates.emit(this.tempDateRange);
        this.isOpen = false;
    }

    onDateRangeChanged(dates: DateRangeState): void {
        this.tempDateRange = dates;
    }

    toggleDropdown(): void {
        this.isOpen = !this.isOpen;
    }

    closeCalendar(): void {
        this.tempDateRange = null;
        this.isOpen = false;
    }
}
