export enum TimePickOperation {
    Add = 'add',
    Subtract = 'subtract',
}

export enum TimePhase {
    Am = 'AM',
    Pm = 'PM',
}

export enum SectionsActivePick {
    Hour = 'hour',
    Minute = 'minute',
    Day = 'day',
}

export const MINUTES_DIFF = 15;
export const HOURS_DIFF = 1;
export const AM_PM_HOURS_DIFF = 12;

export const TIME_FORMAT = 'h:mm A';
