import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { VariationDirective } from '../../directives';
import { IconComponent, FiIconSize } from '../icon';

type ConnectedTitleVariation = 'small | medium | block | icon-static';

@Component({
    selector: 'fi-unit-title',
    templateUrl: './unit-title.component.html',
    styleUrls: ['./unit-title.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [IconComponent, VariationDirective],
})
export class UnitTitleComponent {
    @Input() isConnectedIconShown = false;
    @Input() title = 'Penske Unit';
    @Input() variation: ConnectedTitleVariation;
    @Input() iconSize: FiIconSize = FiIconSize.XSmall;
}
