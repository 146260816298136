import { Injectable } from '@angular/core';

import { isFunction } from 'lodash';
import { Observable, fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';

type CheckAbilityCallback = () => boolean;
type NotifyCallback = (confirmLeaveCallback: () => void) => void;

@Injectable({
    providedIn: 'root',
})
export class PreventLeaveService {
    handleReloadListener(checkAbility: CheckAbilityCallback): Observable<void> {
        return fromEvent(window, 'beforeunload').pipe(
            map((event) => this.onBeforeUnload(checkAbility, event)),
        );
    }

    checkSecondaryLeave(
        checkAbility: CheckAbilityCallback,
        notify: NotifyCallback,
        secondaryLeaveTrigger: () => void,
    ): void {
        const canLeave = checkAbility();

        if (canLeave) {
            return secondaryLeaveTrigger();
        }

        if (isFunction(notify)) {
            notify(() => {
                secondaryLeaveTrigger();
            });
        }
    }

    private onBeforeUnload(
        checkAbility: CheckAbilityCallback,
        event: Event,
    ): void {
        if (!checkAbility()) {
            event.returnValue = false;
        }
    }
}
