<fi-modal
    [(open)]="open"
    [animation]="modalAnimation"
    [variation]="modalVariation"
    class="request-access"
>
    <ng-container *ngIf="open">
        <fi-modal-header *fiIsDesktop (close)="handleClose()">
            <h3 class="fi-heading fi-heading--tertiary fi-heading--dark">
                Fleet Insight Access Request
            </h3>
        </fi-modal-header>

        <fi-modal-close-bar
            *fiIsMobile
            [closeIcon]="'times'"
            [title]="'Request Access'"
            (close)="handleClose()"
        ></fi-modal-close-bar>

        <fi-modal-content>
            <div class="request-access__content">
                <form
                    *ngIf="!isRequestSubmitted"
                    [formGroup]="form"
                    class="request-access__form"
                >
                    <p class="request-access__head fi-text">
                        Please fill out all of the required information below.
                    </p>

                    <ng-container
                        *ngIf="step | memoizeFunc: isFirstStepActive : this"
                    >
                        <div class="request-access__form-group">
                            <label
                                for="customer-number"
                                class="fi-text fi-text--medium fi-text--dark fi-text--small"
                            >
                                <ng-container>Customer Number</ng-container>
                            </label>

                            <fi-input
                                #customerNumberInput
                                [id]="'customer-number'"
                                [name]="'customer-number'"
                                [type]="'text'"
                                [format]="'customer-number'"
                                [placeholder]="
                                    customerNumberInput.focused
                                        ? ''
                                        : '_ _ _ _ _ _'
                                "
                                [invalid]="form.get('customerNumber').invalid"
                                [required]="true"
                                class="request-access__form-control"
                                formControlName="customerNumber"
                            ></fi-input>

                            <ng-container
                                *ngIf="
                                    form.get('customerNumber').touched &&
                                    form.get('customerNumber').errors?.required
                                "
                                [ngTemplateOutlet]="controlRequiredErrorTpl"
                            ></ng-container>

                            <span
                                *ngIf="
                                    form.get('customerNumber').touched &&
                                    form.get('customerNumber').errors?.minlength
                                "
                                class="request-access__form-control-error fi-text fi-text--medium fi-text--small fi-text--error"
                            >
                                6 Character Alpha-Numeric Customer Number is
                                Required
                            </span>

                            <span
                                *ngIf="
                                    form.get('customerNumber').touched &&
                                    form.get('customerNumber').errors
                                        ?.notRecognized
                                "
                                class="request-access__form-control-error fi-text fi-text--medium fi-text--small fi-text--error"
                            >
                                We do not recognize the customer number you have
                                entered. Please try again.
                                <br />
                                <br />
                                If further assistance is needed contact Penske
                                Customer Success at:
                                <br />
                                <a
                                    href="tel:(844) 426-4555"
                                    class="fi-text fi-text--medium fi-text--small fi-text--link"
                                >
                                    (844) 426-4555
                                </a>
                                or
                                <a
                                    href="mailto:hello@penskecustomersuccess.com"
                                    class="fi-text fi-text--medium fi-text--small fi-text--link"
                                >
                                    hello&#64;penskecustomersuccess.com
                                </a>
                                <br />
                                <br />
                                Monday to Friday: 7 AM - 12 AM ET
                                <br />
                                Saturday and Sunday: 7 AM - 5 PM ET
                            </span>

                            <p
                                class="request-access__customer-number-tip fi-text fi-text--small"
                            >
                                If you do not know your company’s
                                6-Alpha-Numeric Customer Number, please contact
                                your Fleet Manager.
                            </p>
                        </div>

                        <div class="request-access__form-group">
                            <label
                                for="first-name"
                                class="fi-text fi-text--medium fi-text--dark fi-text--small"
                            >
                                <ng-container>First Name</ng-container>
                            </label>

                            <fi-input
                                [id]="'first-name'"
                                [name]="'first-name'"
                                [type]="'text'"
                                [placeholder]="'Enter Your First Name'"
                                [invalid]="form.get('firstName').invalid"
                                [maxlength]="50"
                                [required]="true"
                                class="request-access__form-control"
                                formControlName="firstName"
                            ></fi-input>

                            <ng-container
                                *ngIf="
                                    form.get('firstName').touched &&
                                    form.get('firstName').errors?.required
                                "
                                [ngTemplateOutlet]="controlRequiredErrorTpl"
                            ></ng-container>
                        </div>

                        <div class="request-access__form-group">
                            <label
                                for="last-name"
                                class="fi-text fi-text--medium fi-text--dark fi-text--small"
                            >
                                <ng-container>Last Name</ng-container>
                            </label>

                            <fi-input
                                [id]="'last-name'"
                                [name]="'last-name'"
                                [type]="'text'"
                                [placeholder]="'Enter Your Last Name'"
                                [invalid]="form.get('lastName').invalid"
                                [maxlength]="50"
                                [required]="true"
                                class="request-access__form-control"
                                formControlName="lastName"
                            ></fi-input>

                            <ng-container
                                *ngIf="
                                    form.get('lastName').touched &&
                                    form.get('lastName').errors?.required
                                "
                                [ngTemplateOutlet]="controlRequiredErrorTpl"
                            ></ng-container>
                        </div>
                    </ng-container>

                    <ng-container
                        *ngIf="step | memoizeFunc: isSecondStepActive : this"
                    >
                        <div class="request-access__form-group">
                            <label
                                for="phone"
                                class="fi-text fi-text--medium fi-text--dark fi-text--small"
                            >
                                <ng-container>
                                    Primary Phone Number
                                </ng-container>
                            </label>

                            <fi-input
                                #phoneInput
                                [id]="'phone'"
                                [name]="'phone'"
                                [type]="'tel'"
                                [format]="'phone'"
                                [placeholder]="
                                    phoneInput.focused
                                        ? ''
                                        : 'Enter Your Phone Number'
                                "
                                [invalid]="form.get('phone').invalid"
                                [required]="true"
                                class="request-access__form-control"
                                formControlName="phone"
                            ></fi-input>

                            <ng-container
                                *ngIf="
                                    form.get('phone').touched &&
                                    form.get('phone').errors?.required
                                "
                                [ngTemplateOutlet]="controlRequiredErrorTpl"
                            ></ng-container>

                            <span
                                *ngIf="
                                    form.get('phone').touched &&
                                    form.get('phone').errors?.minlength
                                "
                                class="request-access__form-control-error fi-text fi-text--medium fi-text--small fi-text--error"
                            >
                                Please enter a valid Phone Number
                            </span>
                        </div>

                        <div class="request-access__form-group">
                            <label
                                for="email"
                                class="fi-text fi-text--medium fi-text--dark fi-text--small"
                            >
                                <ng-container>Email Address</ng-container>
                            </label>

                            <fi-input
                                #emailInput
                                [id]="'email'"
                                [name]="'email'"
                                [type]="'text'"
                                [format]="'email'"
                                [inputmode]="'email'"
                                [placeholder]="
                                    emailInput.focused
                                        ? ''
                                        : 'Enter Your Email Address'
                                "
                                [maxlength]="50"
                                [required]="true"
                                [invalid]="form.get('email').invalid"
                                class="request-access__form-control"
                                formControlName="email"
                            ></fi-input>

                            <ng-container
                                *ngIf="
                                    form.get('email').touched &&
                                    form.get('email').errors?.required
                                "
                                [ngTemplateOutlet]="controlRequiredErrorTpl"
                            ></ng-container>

                            <span
                                *ngIf="
                                    form.get('email').touched &&
                                    form.get('email').errors?.pattern
                                "
                                class="request-access__form-control-error fi-text fi-text--medium fi-text--small fi-text--error"
                            >
                                Please enter a valid Email
                            </span>

                            <span
                                *ngIf="
                                    form.get('email').touched &&
                                    form.get('email').errors?.userExisted
                                "
                                class="request-access__form-control-error fi-text fi-text--medium fi-text--small fi-text--error"
                            >
                                This email already exists, please reset your
                                password by clicking below.
                            </span>
                        </div>
                    </ng-container>

                    <span
                        *fiIsMobile
                        class="request-access__step fi-text fi-text--small fi-text--medium fi-text--dark"
                    >
                        Step {{ step }} of 2
                    </span>
                </form>

                <div *ngIf="isRequestSubmitted" class="request-access__success">
                    <span class="request-access__success-icon">
                        <fi-icon
                            [svgName]="'check'"
                            [variation]="'solid'"
                            [size]="'xxx-large'"
                        ></fi-icon>
                    </span>

                    <h3
                        class="request-access__success-title fi-heading fi-heading--dark"
                    >
                        Request successfully submitted!
                    </h3>
                    <p class="request-access__success-description fi-text">
                        Once your request for access has been approved you will
                        receive an email to
                        <span class="fi-text fi-text--heavy">
                            {{ form.get('email').value }}
                        </span>
                        with your Fleet Insight account information to login.
                        This process should take around 1 business day.
                    </p>
                </div>
            </div>
        </fi-modal-content>

        <fi-modal-footer>
            <div
                [class.request-access__footer--justify-end]="isRequestSubmitted"
                class="request-access__footer"
            >
                <ng-container *ngIf="!isRequestSubmitted">
                    <span
                        *fiIsDesktop
                        class="fi-text fi-text--small fi-text--medium fi-text--dark"
                    >
                        Step {{ step }} of 2
                    </span>

                    <fi-button
                        *ngIf="
                            [step, isRequestSubmitted]
                                | memoizeFunc: isFirstStepActive : this
                        "
                        [ariaLabel]="'Next'"
                        [variation]="'primary,block'"
                        (click)="handleGoToNextStep()"
                        class="request-access__footer-control"
                    >
                        <span>Next</span>
                        <fi-icon
                            [svgName]="'angle-right'"
                            [variation]="'regular'"
                            [size]="'medium'"
                        ></fi-icon>
                    </fi-button>

                    <div
                        *ngIf="
                            [step, isRequestSubmitted]
                                | memoizeFunc: isSecondStepActive : this
                        "
                        class="request-access__footer-controls"
                    >
                        <fi-button
                            [variation]="'secondary,block'"
                            [ariaLabel]="'Previous'"
                            (click)="goToPreviousStep()"
                            class="request-access__footer-control"
                        >
                            <fi-icon
                                [svgName]="'angle-left'"
                                [variation]="'regular'"
                                [size]="'medium'"
                            ></fi-icon>
                            <span>Previous</span>
                        </fi-button>

                        <fi-button
                            *ngIf="
                                !form.get('email').errors?.userExisted;
                                else resetPasswordButton
                            "
                            [ariaLabel]="'Request Access'"
                            [variation]="'primary,block'"
                            [disabled]="form.invalid || validationInProgress"
                            (click)="handleRequestAccess()"
                            class="request-access__footer-control"
                        >
                            <span>Request Access</span>
                        </fi-button>

                        <ng-template #resetPasswordButton>
                            <fi-button
                                [ariaLabel]="'Reset Password'"
                                [variation]="'primary,block'"
                                (click)="handleResetPassword()"
                                class="request-access__footer-control"
                            >
                                <span>Reset Password</span>
                            </fi-button>
                        </ng-template>
                    </div>
                </ng-container>

                <fi-button
                    *ngIf="isRequestSubmitted"
                    [ariaLabel]="'Close'"
                    [variation]="'primary,block'"
                    (click)="handleClose()"
                    class="request-access__footer-control"
                >
                    Close
                </fi-button>
            </div>
        </fi-modal-footer>
    </ng-container>
</fi-modal>

<ng-template #controlRequiredErrorTpl>
    <span
        class="request-access__form-control-error fi-text fi-text--medium fi-text--small fi-text--error"
    >
        This field is required
    </span>
</ng-template>
