import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    ElementRef,
    Input,
    QueryList,
    ViewChild,
    inject,
} from '@angular/core';
import { AsyncPipe, NgStyle } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
    animationFrameScheduler,
    from,
    fromEvent,
    Observable,
    of,
    Subject,
    defer,
} from 'rxjs';
import {
    debounceTime,
    filter,
    map,
    mergeMap,
    observeOn,
    startWith,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs/operators';
import { isEmpty } from 'lodash';

import { OnChange } from '../../decorators';
import { VariationDirective } from '../../directives';
import { resizeElement } from '../../utils';
import { NavTabsItemComponent } from './nav-tabs-item/nav-tabs-item.component';

@UntilDestroy()
@Component({
    selector: 'fi-nav-tabs',
    templateUrl: './nav-tabs.component.html',
    styleUrls: ['./nav-tabs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgStyle, AsyncPipe, VariationDirective],
})
export class NavTabsComponent implements AfterViewInit {
    private readonly router = inject(Router);
    private readonly changeDetectorRef = inject(ChangeDetectorRef);

    @OnChange('hideIndicator')
    @Input()
    resetOnCustomCondition: boolean;
    @Input() variation: string;
    @Input() resetOnMouseLeave: boolean;
    @Input() activeIndicatorTop: boolean;
    @Input() isLineUnderCard: boolean;

    @ContentChildren(NavTabsItemComponent)
    navItems: QueryList<NavTabsItemComponent>;

    @ViewChild('navTabsWrapper') navTabsWrapper: ElementRef;

    readonly indicatorStyle$: Observable<{
        transform: string;
    }> = defer(() => this.updateIndicatorAlignment.asObservable()).pipe(
        startWith(null),
        observeOn(animationFrameScheduler),
        switchMap((transformSettings?: { transform: string }) => {
            if (transformSettings) {
                return of(transformSettings);
            }
            return this.indicatorMainUpdateStyle$;
        }),
        // run CD manually since animationFrameScheduler used
        tap(() => this.changeDetectorRef.detectChanges()),
    );

    private updateIndicatorAlignment = new Subject<void | {
        transform: string;
    }>();

    private indicatorMainUpdateStyle$: Observable<{
        transform: string;
    }> = of(null).pipe(
        map(() => this.getActiveItemDimensions()),
        filter((dimensions) => !isEmpty(dimensions)),
        map(({ width, left, top }) => ({
            width: `${width}px`,
            transform: `translateX(${left}px)`,
            ...(this.isLineUnderCard ? { top: `${top}px` } : ''),
        })),
    );

    private navItemsChanges$: Observable<void>;

    private navTabsWrapperElement: HTMLElement;

    ngAfterViewInit(): void {
        this.navTabsWrapperElement = this.navTabsWrapper.nativeElement;

        if (this.resetOnMouseLeave) {
            fromEvent(this.navTabsWrapperElement, 'mouseleave')
                .pipe(untilDestroyed(this))
                .subscribe(() => this.hideIndicator());
        }

        resizeElement(this.navTabsWrapperElement)
            .pipe(untilDestroyed(this))
            .subscribe(() => this.updateIndicatorAlignment.next());

        this.router.events
            .pipe(
                filter(
                    (event): event is NavigationEnd =>
                        event instanceof NavigationEnd,
                ),
                untilDestroyed(this),
            )
            .subscribe(() => this.updateIndicatorAlignment.next());

        this.updateIndicatorAlignmentOnItemStateChange();
    }

    private updateIndicatorAlignmentOnItemStateChange(): void {
        this.navItemsChanges$ = this.navItems.changes;

        this.navItemsChanges$
            .pipe(
                switchMap(() => from(this.navItems.toArray())),
                mergeMap((item) =>
                    item.activeStateChange$.pipe(
                        takeUntil(this.navItemsChanges$),
                        untilDestroyed(this),
                    ),
                ),
                debounceTime(0), // get latest value from activeStateChange$
                untilDestroyed(this),
            )
            .subscribe(() => this.updateIndicatorAlignment.next());

        // trigger ContentChildren changes on component init
        this.navItems.notifyOnChanges();
    }

    private getActiveItemDimensions(): {
        width: number;
        left: number;
        top: number;
    } {
        const activeItem = this.getActiveItem();

        if (!activeItem) {
            return;
        }

        const { width, left, top } = activeItem.getViewPosition();

        return { width, left, top };
    }

    private getActiveItem(): NavTabsItemComponent {
        return this.navItems.find((navItem) => !!navItem.getIsActive());
    }

    private hideIndicator(): void {
        const hiddenIndicatorStyle = {
            transform: 'scaleX(0)',
            transition: 'none',
        };

        this.updateIndicatorAlignment.next(hiddenIndicatorStyle);
    }
}
