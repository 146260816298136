import {
    Component,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
    HostListener,
    TemplateRef,
    ContentChild,
    ElementRef,
    ViewContainerRef,
    OnDestroy,
    inject,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, take } from 'rxjs/operators';

import { OnChange } from '../../decorators';
import {
    OverlayConnectedPosition,
    OverlayContainerRef,
    OverlayEvent,
    OverlayEventType,
    OverlayScrollStrategy,
    OverlayService,
} from '../overlay';

@UntilDestroy()
@Component({
    selector: 'fi-simple-dropdown',
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class SimpleDropdownComponent implements OnDestroy {
    private readonly elementRef = inject(ElementRef);
    private readonly overlayService = inject(OverlayService);
    private readonly viewContainerRef = inject(ViewContainerRef);

    @OnChange(function (this: SimpleDropdownComponent) {
        this.toggleOpenWindow();
    })
    @Input()
    open = false;

    @Input() relativeTo: ElementRef;
    @Input() containerToWatchResize: ElementRef;
    @Input() updatePositionOnResize = true;
    @Input() preventClickOnRelative = false;
    @Input() scrollStrategy = OverlayScrollStrategy.Reposition;
    @Input() shouldFocusWhenShown = true;
    @Input() variation: string | string[] = '';

    @Output() openChange = new EventEmitter<boolean>();

    @ContentChild(TemplateRef) pickerTpl: TemplateRef<any>;

    private overlayContainerRef: OverlayContainerRef<TemplateRef<any>> | null;

    @HostListener('document:keydown.escape')
    handleEscapeKeydown(): void {
        this.close();
    }

    ngOnDestroy(): void {
        this.close();
    }

    private show(): void {
        if (this.overlayContainerRef) {
            return;
        }

        this.overlayContainerRef = this.overlayService.create(
            this.pickerTpl,
            this.viewContainerRef,
            {
                variation: this.variation,
                positions: [
                    OverlayConnectedPosition.BottomLeft,
                    OverlayConnectedPosition.BottomRight,
                    OverlayConnectedPosition.TopLeft,
                    OverlayConnectedPosition.TopRight,
                    OverlayConnectedPosition.RightTop,
                    OverlayConnectedPosition.RightBottom,
                ],
                scrollStrategy: this.scrollStrategy,
                preventClickOnRelativeTo: this.preventClickOnRelative,
            },
            null,
            this.relativeTo || this.elementRef.nativeElement,
        );

        if (!this.overlayContainerRef) {
            return;
        }

        this.overlayContainerRef.open({
            withoutFocus: !this.shouldFocusWhenShown,
        });
        this.attachOverlayListener();

        if (this.containerToWatchResize && this.updatePositionOnResize) {
            this.overlayContainerRef.attachRelativePosition(
                this.containerToWatchResize.nativeElement,
            );
        }
    }

    private close(): void {
        if (!this.overlayContainerRef) {
            return;
        }

        this.overlayContainerRef.close();
        this.overlayContainerRef = null;
    }

    private toggleOpenWindow(): void {
        if (this.open) {
            this.show();
            return;
        }

        this.close();
    }

    private attachOverlayListener(): void {
        this.overlayContainerRef.event$
            .pipe(
                filter(({ type }: OverlayEvent) =>
                    [
                        OverlayEventType.Detach,
                        OverlayEventType.OutsideClick,
                    ].includes(type),
                ),
                take(1),
                untilDestroyed(this),
            )
            .subscribe(() => {
                this.openChange.emit(false);
            });
    }
}
