@if (items?.length) {
    <div
        cdkDropList
        [class.fi-draggable-list--with-placeholder]="withPlaceholder"
        (cdkDropListDropped)="dropChangeOrder($event)"
        class="fi-draggable-list"
    >
        @for (draggableItem of items; let itemIndex = $index; track draggableItem) {
            <div
                cdkDrag
                [cdkDragPreviewClass]="hideControls ? 'no-styling-preview' : ''"
                [cdkDragBoundary]="boundaryClass"
                [fiVariation]="draggableItem.variation"
                [fiVariationBase]="'fi-draggable-list__item'"
            >
                <div *cdkDragPlaceholder class="fi-draggable-list__custom-placeholder">
                    <div
                        [fiVariation]="draggableItem.variation"
                        [fiVariationBase]="'fi-draggable-list__item'"
                    >
                        <ng-container *ngTemplateOutlet="draggableItem.template"></ng-container>
                    </div>
                </div>

                <div cdkDragHandle class="fi-draggable-list__setting-container">
                    @if (!hideControls) {
                        <div class="fi-draggable-list__drag-handle">
                            <ng-container *ngTemplateOutlet="lineTpl"></ng-container>
                            <ng-container *ngTemplateOutlet="lineTpl"></ng-container>
                        </div>
                    }

                    <ng-container *ngTemplateOutlet="draggableItem.template"></ng-container>
                </div>

                <!-- @todo: update if need to pass template -->
                <span class="fi-text fi-text--medium fi-text--small fi-text--light fi-draggable-list__arrows-placeholder">
            {{ draggableItem.arrowsPlaceholder }}
        </span>

                <div class="fi-draggable-list__arrows-container">
                    <fi-button
                        [ariaLabel]="'Up'"
                        [variation]="'secondary,mobile-squared'"
                        [disabled]="itemIndex | memoizeFunc: isFirstItem:this"
                        [fiTooltip]="arrowTooltipTmpl"
                        [fiTooltipContext]="{ direction: 'Up' }"
                        (click)="!isFirstItem(itemIndex) && manualChangeOrder(itemIndex, itemIndex - 1)"
                        class="fi-draggable-list__arrow-btn">
                        <fi-icon
                            [svgName]="'long-arrow-alt-up'"
                            [variation]="'regular'"
                            class="fi-draggable-list__arrow-icon"
                        ></fi-icon>
                    </fi-button>

                    <fi-button
                        [ariaLabel]="'Down'"
                        [variation]="'secondary,mobile-squared'"
                        [disabled]="itemIndex | memoizeFunc: isLastItem:this"
                        [fiTooltip]="arrowTooltipTmpl"
                        [fiTooltipContext]="{ direction: 'Down' }"
                        (click)="!isLastItem(itemIndex) && manualChangeOrder(itemIndex, itemIndex + 1)"
                        class="fi-draggable-list__arrow-btn">
                        <fi-icon
                            [svgName]="'long-arrow-alt-down'"
                            [variation]="'regular'"
                            class="fi-draggable-list__arrow-icon"
                        ></fi-icon>
                    </fi-button>
                </div>
            </div>
        }
    </div>
}

<!-- Template for arrow ordering tooltip -->
<ng-template #arrowTooltipTmpl let-direction="direction">
    <span>Move {{ direction }}</span>
</ng-template>

<ng-template #lineTpl>
    <div class="fi-draggable-list__drag-handle-container">
        @for (element of 5 | toNumArray; track element) {
            <div class="fi-draggable-list__drag-handle-item"></div>
        }
    </div>
</ng-template>
