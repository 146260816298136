import {
    AfterViewInit,
    Directive,
    ElementRef,
    Inject,
    Input,
    Self,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { DecimalPipe } from '@angular/common';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { empty, Observable } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';

import {
    InputFormatModifierType,
    InputFormatType,
} from '../components/input/input.component';
import {
    InputFormatBase,
    PhoneInputFormat,
    EmailInputFormat,
    OdometerHoursFormat,
    NumberFormat,
    CustomerNumberFormat,
    DateFormat,
    OdometerHoursModifierFormat,
    SsoIdFormat,
    SecondaryDateFormat,
    DistrictInputFormat,
} from '../components/input/format';
import { ConfigApp, ConfigAppToken } from '../services/config.service';

@UntilDestroy()
@Directive({
    selector: '[fiInputFormat]',
    standalone: true,
})
export class InputFormatDirective implements AfterViewInit {
    @Input() fiInputFormat: InputFormatType;
    @Input() fiInputFormatModifier: InputFormatModifierType;

    inputElement: HTMLInputElement;

    constructor(
        @Self() private ngControl: NgControl,
        private decimalPipe: DecimalPipe,
        private elementRef: ElementRef,
        @Inject(ConfigAppToken) private CONFIG: ConfigApp,
    ) {}

    ngAfterViewInit(): void {
        this.inputElement = this.elementRef.nativeElement;

        this.initialValue$
            .pipe(
                switchMap((value) => {
                    const inputFormatType = this.getInputFormatType(value);

                    return inputFormatType
                        ? inputFormatType.valueChanges$.pipe(
                              untilDestroyed(this),
                          )
                        : empty();
                }),
            )
            .subscribe();
    }

    private get initialValue$(): Observable<string> {
        return this.ngControl.valueChanges.pipe(
            filter((value) => !!value),
            take(1),
        );
    }

    private getInputFormatType(initialValue: string): InputFormatBase | null {
        switch (this.fiInputFormat) {
            case InputFormatType.Email:
                return new EmailInputFormat(
                    this.ngControl,
                    this.inputElement,
                    initialValue,
                );

            case InputFormatType.Phone:
                return new PhoneInputFormat(
                    this.ngControl,
                    this.inputElement,
                    initialValue,
                    this.CONFIG,
                );

            case InputFormatType.OdometerHours:
                return new OdometerHoursFormat(
                    this.ngControl,
                    this.decimalPipe,
                    this.inputElement,
                    initialValue,
                    this.CONFIG,
                );

            case InputFormatType.OdometerHoursModifier:
                return new OdometerHoursModifierFormat(
                    this.ngControl,
                    this.decimalPipe,
                    this.inputElement,
                    initialValue,
                    this.fiInputFormatModifier,
                );

            case InputFormatType.Number:
                return new NumberFormat(this.ngControl, initialValue);

            case InputFormatType.CustomerNumber:
                return new CustomerNumberFormat(
                    this.ngControl,
                    this.inputElement,
                    initialValue,
                );

            case InputFormatType.SsoId:
                return new SsoIdFormat(
                    this.ngControl,
                    this.inputElement,
                    initialValue,
                );

            case InputFormatType.Date:
                return new DateFormat(
                    this.ngControl,
                    this.inputElement,
                    initialValue,
                );

            case InputFormatType.DateSecondary:
                return new SecondaryDateFormat(
                    this.ngControl,
                    this.inputElement,
                    initialValue,
                );

            case InputFormatType.District:
                return new DistrictInputFormat(
                    this.ngControl,
                    this.inputElement,
                    initialValue,
                );

            default:
                return null;
        }
    }
}
