import {
    Component,
    ChangeDetectionStrategy,
    forwardRef,
    Input,
    ViewChild,
    ElementRef,
    TemplateRef,
    ContentChildren,
    QueryList,
    ViewContainerRef,
    ChangeDetectorRef,
    OnDestroy,
    ViewEncapsulation,
    Output,
    EventEmitter,
    AfterContentInit,
    inject,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Subscription, fromEvent } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { KeyCode } from '../../constants';
import {
    OverlayContainerRef,
    OverlayService,
    OverlayConnectedPosition,
    OverlayScrollStrategy,
    OverlayEvent,
    OverlayEventType,
} from '../overlay';
import { OnChange } from '../../decorators';
import { VariationDirective } from '../../directives';
import { IconComponent } from '../../components';
import { MemoizeFuncPipe } from '../../pipes';

import { SelectMenuOptionComponent } from './option/select-menu-option.component';

@UntilDestroy()
@Component({
    selector: 'fi-select-menu',
    templateUrl: './select-menu.component.html',
    styleUrls: ['./select-menu.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectMenuComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [VariationDirective, IconComponent, MemoizeFuncPipe],
})
export class SelectMenuComponent
    implements ControlValueAccessor, AfterContentInit, OnDestroy
{
    private overlayService = inject(OverlayService);
    private viewContainerRef = inject(ViewContainerRef);
    private changeDetectorRef = inject(ChangeDetectorRef);

    @Input() disabled = false;
    @Input() invalid = false;
    @OnChange(function (this: SelectMenuComponent) {
        this.setSelectedText(
            this.options,
            this.value,
            this.isDisplayOptionValue,
        );
    })
    @Input()
    isDisplayOptionValue = false;
    @Input() placeholder = '';
    @Input() variation = 'medium';

    @ViewChild('container') containerElRef: ElementRef;

    @ViewChild('menu', { read: TemplateRef })
    menuTplRef: TemplateRef<HTMLElement>;

    @ContentChildren(SelectMenuOptionComponent)
    options: QueryList<SelectMenuOptionComponent>;

    @OnChange(function (this: SelectMenuComponent) {
        this.setSelectedText(
            this.options,
            this.value,
            this.isDisplayOptionValue,
        );
    })
    @Input()
    value: string;
    @Output() change = new EventEmitter<string>();
    isMenuOpen = false;
    selectedText: string;

    private overlayContainerRef: OverlayContainerRef<
        TemplateRef<HTMLElement>
    > | null;

    private clickOutsideSubscription: Subscription | null;
    private selectOptionSubscriptions: Subscription[] = [];

    ngAfterContentInit(): void {
        this.setSelectedText(
            this.options,
            this.value,
            this.isDisplayOptionValue,
        );

        this.options.changes.pipe(untilDestroyed(this)).subscribe(() => {
            this.setSelectedText(
                this.options,
                this.value,
                this.isDisplayOptionValue,
            );
        });
    }

    ngOnDestroy(): void {
        this.closeMenu();
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChange = (_: string) => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onTouched = () => {};

    writeValue(value: string): void {
        this.value = value;
        this.changeDetectorRef.markForCheck();
    }

    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    getLabel([selectedText, placeholder]: [string, string]): string {
        return selectedText || placeholder;
    }

    toggleMenu(): void {
        if (this.disabled) {
            return;
        }

        this.isMenuOpen ? this.closeMenu() : this.openMenu();
    }

    handleKeydown(event: KeyboardEvent): void {
        if (event.key === KeyCode.Enter) {
            this.openMenu();
        }
    }

    private setSelectedText(
        options: QueryList<SelectMenuOptionComponent>,
        value: string,
        isDisplayOptionValue: boolean,
    ): void {
        const selectedOption = options?.find(
            (option) => option.value === value,
        );
        this.selectedText = selectedOption
            ? isDisplayOptionValue
                ? selectedOption.value
                : selectedOption.text
            : null;
    }

    private openMenu(): void {
        this.overlayContainerRef = this.getOverlayContainerRef();
        this.overlayContainerRef.open();
        this.overlayContainerRef.attachRelativeWidth();

        this.isMenuOpen = true;

        this.attachClickOutsideListener();
        this.handleOverlayDetach();

        this.highlightSelectedOption();
        this.attachSelectOptionListeners();
    }

    private closeMenu(): void {
        if (this.overlayContainerRef) {
            this.overlayContainerRef.close();
            this.overlayContainerRef = null;

            this.isMenuOpen = false;

            this.onTouched();

            this.detachClickOutsideListener();
            this.detachSelectOptionListeners();
        }
    }

    private getOverlayContainerRef(): OverlayContainerRef<
        TemplateRef<HTMLElement>
    > {
        if (this.overlayContainerRef) {
            return this.overlayContainerRef;
        }

        return this.overlayService.create(
            this.menuTplRef,
            this.viewContainerRef,
            {
                positions: [
                    OverlayConnectedPosition.BottomLeft,
                    OverlayConnectedPosition.TopLeft,
                ],
                scrollStrategy: OverlayScrollStrategy.Close,
            },
            null,
            this.containerElRef,
        );
    }

    private attachClickOutsideListener(): void {
        this.clickOutsideSubscription = fromEvent(document, 'click').subscribe(
            ({ target }: Event) => this.handleClickOutside(target),
        );
    }

    private detachClickOutsideListener(): void {
        this.clickOutsideSubscription.unsubscribe();
        this.clickOutsideSubscription = null;
    }

    private handleClickOutside(target: EventTarget): void {
        if (!this.containerElRef.nativeElement.contains(target)) {
            this.closeMenu();
            this.changeDetectorRef.markForCheck();
        }
    }

    // handle menu close on page scroll
    private handleOverlayDetach(): void {
        this.overlayContainerRef.event$
            .pipe(
                filter(
                    ({ type }: OverlayEvent) =>
                        type === OverlayEventType.Detach,
                ),
                take(1),
            )
            .subscribe(() => {
                this.closeMenu();
                this.changeDetectorRef.markForCheck();
            });
    }

    private highlightSelectedOption(): void {
        this.options.forEach((option) => {
            option.isSelected = option.value === this.value;
        });
    }

    private attachSelectOptionListeners(): void {
        this.options.forEach((option) => {
            this.selectOptionSubscriptions.push(
                option.select$
                    .pipe(take(1))
                    .subscribe((value) => this.selectOption(value)),
            );
        });
    }

    private detachSelectOptionListeners(): void {
        this.selectOptionSubscriptions.forEach((subscription) =>
            subscription.unsubscribe(),
        );
        this.selectOptionSubscriptions = [];
    }

    private selectOption(value: string): void {
        this.value = value;
        this.change.emit(value);
        this.onChange(value);
    }
}
