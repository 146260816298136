import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import { VariationDirective } from '../../directives';

export const enum FiRadioVariation {
    Regular = 'regular',
    Large = 'large',
    SelectOptionContent = 'select-option-content',
}

@Component({
    selector: 'fi-radio',
    templateUrl: './radio.component.html',
    styleUrls: ['./radio.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [VariationDirective],
})
export class RadioComponent {
    @Input() disabled = false;
    @Input() checked = false;
    @Input() variation: FiRadioVariation = FiRadioVariation.Regular;

    @Input() gtmClassName = '';

    @Output() change = new EventEmitter<void>();

    handleRadioChange(event?: KeyboardEvent) {
        if (event) {
            event.preventDefault();
        }

        if (this.disabled) {
            return;
        }

        this.change.emit();
    }
}
