import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    Optional,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { filter, switchMap } from 'rxjs/operators';

import { EnvironmentService } from '../../../services';
import { SkeletonComponent } from '../../../components';
import { IsDesktopDeviceDirective } from '../../../directives';
import { FilteringModule, FilteringDirective } from '../../filtering';
import { GridDataSourceDirective } from '../data-source/data-source.directive';
import { GridOnTabletService } from '../services/grid-on-tablet.service';
import { GridSortPickerComponent } from '../sort/picker/picker.component';

const FILTER_OPENER_BUTTON_WIDTH = 125;
const DOWNLOAD_REPORT_BUTTON_WIDTH = 50;
const MANAGE_COLUMNS_BUTTON_WIDTH = 50;
const MARGINS = 60;

@UntilDestroy()
@Component({
    selector: 'fi-grid-main-panel',
    templateUrl: './main-panel.component.html',
    styleUrls: ['./main-panel.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        AsyncPipe,
        NgTemplateOutlet,
        FilteringModule,
        SkeletonComponent,
        GridSortPickerComponent,
        IsDesktopDeviceDirective,
    ],
})
export class GridMainPanelComponent implements OnInit {
    @Input() hasFilterOpenerInMoreAction = false;
    @Input() hideFiltersPanel = false;
    @Input() showSubFilterPanel = false;

    @ViewChild('mainPanel') mainPanel: ElementRef<HTMLElement>;
    @ViewChild('subFilter') subFilter: ElementRef<HTMLElement>;
    @ViewChild('secondaryControlWrapper')
    secondaryControlWrapper: ElementRef<HTMLElement>;
    @ViewChild('simpleFiltering') simpleFiltering: ElementRef<HTMLElement>;

    readonly isMobileView$ = this.gridOnTabletService.isMobileView$;
    readonly isDesktopView$ = this.gridOnTabletService.isDesktopView$;

    isInitializing = true;
    isPanelNotOverflowed = true;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        @Optional() private dataSource: GridDataSourceDirective,
        private gridOnTabletService: GridOnTabletService,
        private environmentService: EnvironmentService,
        @Optional() public filtering: FilteringDirective,
    ) {}

    ngOnInit(): void {
        if (!this.dataSource) {
            return;
        }

        this.dataSource.stateChanged$
            .pipe(untilDestroyed(this))
            .subscribe(() => this.updateState());

        this.updateState();
    }

    private updateState(): void {
        const { isInitializing } = this.dataSource;

        if (this.isInitializing !== isInitializing) {
            this.isInitializing = isInitializing;

            setTimeout(() => {
                this.handleWindowResize();
            });

            this.changeDetectorRef.markForCheck();
        }
    }

    private handleWindowResize(): void {
        this.environmentService.windowResize$
            .pipe(
                switchMap(() =>
                    this.environmentService.isSmallTabletDevice$.pipe(
                        untilDestroyed(this),
                    ),
                ),
                filter(Boolean),
                untilDestroyed(this),
            )
            .subscribe(() => {
                this.setIsPanelNotOverflowed();
            });
    }

    private setIsPanelNotOverflowed(): void {
        const mainPanelWidth = this.mainPanel?.nativeElement.offsetWidth;
        const subFilterTextWidth =
            this.subFilter?.nativeElement?.children[0]?.getBoundingClientRect()
                ?.width;
        const secondaryControlWidth =
            this.secondaryControlWrapper?.nativeElement.offsetWidth;
        const simpleFilteringWidth =
            this.simpleFiltering?.nativeElement.offsetWidth;

        this.isPanelNotOverflowed =
            mainPanelWidth >
            subFilterTextWidth +
                secondaryControlWidth +
                simpleFilteringWidth +
                FILTER_OPENER_BUTTON_WIDTH +
                DOWNLOAD_REPORT_BUTTON_WIDTH +
                MANAGE_COLUMNS_BUTTON_WIDTH +
                MARGINS;
    }
}
