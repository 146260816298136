import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AsyncPipe } from '@angular/common';

import { GridOnTabletService } from '../../services/grid-on-tablet.service';
import { SortingOrder } from '../../../../models';
import { IconComponent } from '../../../../components';

@Component({
    selector: 'fi-grid-sort-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [AsyncPipe, IconComponent],
})
export class GridSortIconComponent {
    @Input() isActive = false;
    @Input() order: SortingOrder;
    @Input() reversed = false;

    readonly isMobileView$ = this.gridOnTabletService.isMobileView$;
    readonly isDesktopView$ = this.gridOnTabletService.isDesktopView$;

    constructor(private gridOnTabletService: GridOnTabletService) {}
}
