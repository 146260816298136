<div
    [fiVariation]="variationList"
    [fiVariationBase]="'fi-input'"
    [cdkTrapFocus]="focusedInitially"
    [cdkTrapFocusAutoCapture]="focusedInitially"
    class="fi-input"
>
    @if (
        [focused, ngControl?.invalid, ngControl?.touched]
            | memoizeFunc: isMaskVisible : this
    ) {
        <fi-input-mask
            [format]="format"
            [value]="maskValue"
            class="fi-input__mask"
        ></fi-input-mask>
    }

    <input
        #inputElement
        cdkFocusInitial
        [ngClass]="{ 'fi-input__input--has-icon': icon || showResetButton }"
        [id]="id"
        [ngModel]="value"
        [name]="name"
        [type]="type"
        [fiInputFormat]="format"
        [fiInputFormatModifier]="formatModifier"
        [disabled]="disabled"
        [placeholder]="placeholder"
        [autocomplete]="autocomplete"
        [readonly]="readonly"
        [maxlength]="maxlength"
        [minlength]="minlength"
        [attr.inputmode]="inputmode"
        [attr.required]="required"
        (blur)="handleInputBlur($event)"
        (click)="handleInputClick()"
        class="fi-input__input"
    />

    <!-- Temporary solution to add input description icon until FI-14585 is done -->
    <ng-content select=".fi-input__input-icon"></ng-content>

    @if (changeIndicator) {
        <fi-icon
            [svgName]="'pen'"
            [size]="'small'"
            (click)="focusInputElement()"
            class="fi-input__pencil-icon"
        ></fi-icon>
    }

    @if (type === 'search' || showResetButton) {
        <div class="fi-input__clear-icon-wrapper">
            <button
                #resetButton
                [attr.aria-label]="'Reset'"
                (click)="handleInputClear()"
                class="fi-input__clear-icon-button"
            >
                <fi-icon
                    [svgName]="'times'"
                    class="fi-input__clear-icon"
                ></fi-icon>
            </button>
        </div>
    }

    @if (icon) {
        <fi-icon
            [svgName]="icon"
            [variation]="iconVariation"
            [size]="'large'"
            (click)="handleIconClick()"
            class="fi-input__icon"
        ></fi-icon>
    }

    @if (buttonTitle) {
        <fi-button
            [ariaLabel]="buttonTitle"
            [variation]="'primary,smallest'"
            (click)="handleButtonClick()"
            class="fi-input__button"
        >
            {{ buttonTitle }}
        </fi-button>
    }

    @if (suffixLabelTitle) {
        <label class="fi-input__suffix-label fi-text fi-text--locked">
            {{ suffixLabelTitle }}
        </label>
    }
</div>

@if (showCounter && (focused || showRequiredMinlength)) {
    <div class="fi-input__counter">
        <div>
            <span
                [ngClass]="
                    showRequiredMinlength
                        ? 'fi-text--small'
                        : 'fi-text--x-small'
                "
                class="fi-text fi-text--light"
            >
                {{ ngControl?.value?.trim()?.length || 0 }}/{{ maxlength }}
            </span>

            @if (showRequiredMinlength) {
                <span
                    [ngClass]="{
                        'fi-text--error fi-text--medium':
                            (ngControl?.errors?.required ||
                                ngControl?.errors?.minlength ||
                                ngControl?.errors?.noWhitespaceMinLength) &&
                            ngControl?.touched,
                    }"
                    class="fi-text fi-text--small fi-text--light"
                >
                    (Min. {{ minlength }})
                </span>
            }
        </div>

        @let errorMsg = ngControl?.errors?.errorMsg;
        @if (errorMsg) {
            <div class="fi-text fi-text--small fi-text--error fi-text--medium">
                {{ errorMsg }}
            </div>
        }
    </div>
}
