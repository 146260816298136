<button
    [class.metrics-tab--active]="isActiveTab"
    [attr.aria-label]="ariaLabel"
    type="button"
    class="metrics-tab"
>
    <span class="fi-text fi-text--medium fi-text--dark">
        <ng-content select=".metrics-tab-title"></ng-content>
    </span>
    <span class="fi-text fi-text--link fi-text--medium">
        <ng-content select=".metrics-tab-body"></ng-content>
    </span>
</button>
