<ng-content select="mainPanel"></ng-content>

<div class="fi-grid-list__wrapper">
    @if (!isInitializing) {
        <ul class="fi-grid-list">
            @for (
                group of rows | gridGroupRows: groupBy : columns;
                track group;
                let first = $first
            ) {
                <li
                    [guidedTourStep]="
                        first && guidedTourMobileStep?.stepId
                            ? guidedTourMobileStep.stepId
                            : null
                    "
                    [guidedTourTitle]="guidedTourMobileStep?.title || ''"
                    [guidedTourText]="guidedTourMobileStep?.text || ''"
                    class="fi-grid-list__item"
                >
                    @if (groupBy?.tpl && group.length) {
                        <div>
                            <ng-container
                                [ngTemplateOutlet]="groupBy.tpl"
                                [ngTemplateOutletContext]="{ items: group }"
                            ></ng-container>
                        </div>
                    }
                    @for (row of group; track row) {
                        <div class="fi-grid-list__item-content">
                            <div class="fi-grid-list__item-left-container">
                                @for (
                                    column of columns;
                                    track trackByColumnFieldName($index, column)
                                ) {
                                    @if (column.sticky && !column.field) {
                                        <div class="fi-grid-list__cell">
                                            <div
                                                [tpl]="column.headerCell"
                                                class="fi-text fi-text--small fi-text--medium"
                                                fi-grid-header-cell
                                            ></div>
                                            <div
                                                fi-grid-cell
                                                [fieldName]="column.field"
                                                [row]="row"
                                                [tpl]="column.cell"
                                                class="fi-text fi-text--dark fi-text--medium"
                                            ></div>
                                        </div>
                                    }
                                }
                            </div>
                            <div class="fi-grid-list__item-central-container">
                                @for (
                                    column of columns;
                                    track trackByColumnFieldName($index, column)
                                ) {
                                    @if (column.field) {
                                        <div
                                            [fiVariation]="
                                                column.mobileVariation
                                            "
                                            [fiVariationBase]="
                                                'fi-grid-list__cell'
                                            "
                                            class="fi-grid-list__cell"
                                        >
                                            <div
                                                [tpl]="column.headerCell"
                                                [row]="row"
                                                class="fi-grid-list__cell-header fi-text fi-text--small fi-text--medium"
                                                fi-grid-header-cell
                                            ></div>
                                            <div
                                                [fieldName]="column.field"
                                                [row]="row"
                                                [tpl]="column.cell"
                                                class="fi-text fi-text--dark fi-text--medium"
                                                fi-grid-cell
                                            ></div>
                                        </div>
                                    }
                                }
                            </div>
                            <div class="fi-grid-list__item-right-container">
                                @for (
                                    column of columns;
                                    track trackByColumnFieldName($index, column)
                                ) {
                                    @if (column.stickyRight && !column.field) {
                                        <div class="fi-grid-list__cell">
                                            <div
                                                [tpl]="column.headerCell"
                                                class="fi-text fi-text--small fi-text--medium"
                                                fi-grid-header-cell
                                            ></div>
                                            <div
                                                [fieldName]="column.field"
                                                [row]="row"
                                                [tpl]="column.cell"
                                                class="fi-text fi-text--dark fi-text--medium"
                                                fi-grid-cell
                                            ></div>
                                        </div>
                                    }
                                }
                            </div>
                        </div>
                    }
                </li>
            }
        </ul>
    }

    @if (isLoading && !isInitializing && isDataPresent) {
        <fi-loader-penske></fi-loader-penske>
    }

    @if (isInitializing) {
        <fi-grid-list-loader-skeleton>
            <ng-content select="customSkeleton"></ng-content>
        </fi-grid-list-loader-skeleton>
    }
</div>
