import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation,
    inject,
} from '@angular/core';
import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { merge, Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { isEmpty } from 'lodash';
import dayjs, { Dayjs } from 'dayjs';

import { OnChange } from '../../../decorators';
import { EnvironmentService } from '../../../services';
import { MemoizeFuncPipe, TimeFormatPipe } from '../../../pipes';
import { IsDesktopDeviceDirective, IsMobileDeviceDirective, VariationDirective } from '../../../directives';
import { ButtonComponent, IconComponent } from '../../../components';
import { SimpleDropdownComponent } from '../../dropdown';
import { PillComponent } from '../../pill';
import {
    ModalCloseBarComponent,
    ModalComponent, ModalContentComponent,
    ModalEvent,
    ModalEventType, ModalFooterComponent,
    ModalHeaderComponent,
    ModalVariation
} from '../../modal';
import { CalendarComponent } from '../calendar/calendar.component';
import {
    DateRange,
    DateRangeModesTitle,
    DateRangeSelectionMode,
    DateRangeState,
    MaxDatesInRange,
    RangeCalendarCurrentMonthPosition,
} from '../models';
import { DateRangeModalComponent } from '../range-modal/range-modal.component';
import { DateRangeTabsComponent } from './tabs/tabs.component';

type DateRangeVariation = 'invalid';

@UntilDestroy()
@Component({
    selector: 'fi-date-range',
    templateUrl: './range.component.html',
    styleUrls: ['./range.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        AsyncPipe,
        NgClass,
        NgTemplateOutlet,
        TimeFormatPipe,
        MemoizeFuncPipe,
        VariationDirective,
        IsMobileDeviceDirective,
        IsDesktopDeviceDirective,
        IconComponent,
        PillComponent,
        ButtonComponent,
        ModalComponent,
        ModalHeaderComponent,
        ModalCloseBarComponent,
        ModalContentComponent,
        ModalFooterComponent,
        SimpleDropdownComponent,
        DateRangeTabsComponent,
        CalendarComponent,
        DateRangeModalComponent,
    ]
})
export class DateRangeComponent implements OnInit {
    public readonly elementRef = inject(ElementRef);
    private readonly changeDetectorRef = inject(ChangeDetectorRef);
    private readonly environmentService = inject(EnvironmentService);

    @OnChange<DateRange>(function (this: DateRangeComponent, selected) {
        this.setSelectedState(selected);
    })
    @Input()
    selected: DateRange;
    @Input() availableDateRange: DateRangeState;
    @Input() maxDatesInRange: MaxDatesInRange;
    @Input() singleDateMode: boolean;
    @Input() oneDateSelectionAvailable = true;
    @Input() currentMonthPosition: RangeCalendarCurrentMonthPosition;
    @Input() fromTitle = DateRangeModesTitle.From;
    @Input() toTitle = DateRangeModesTitle.To;
    @Input() toDateMessageInfo: string;
    @Input() isNewDateRangeModal = false;
    @Input() modalTitle = 'Custom Date';
    @Input() rangeTitle: string;
    @Input() historicalOnly: boolean;
    @Input() openInDropdown = false;
    @Input() variation: DateRangeVariation | string = '';
    @Input() keepCurrentMonth = false;
    @OnChange('initSelectedDates')
    @Input()
    isModalOpen = false;
    @Input() showDatesInfo = true;
    @Input() confirmButtonTitle = 'Done';
    @Input() showCancelButton: boolean;
    @Input() cancelButtonTitle = 'Cancel';

    @Output() select = new EventEmitter<DateRange>();
    @Output() dropdownClose = new EventEmitter<DateRange>();
    @Output() reset = new EventEmitter<void>();
    @Output() close = new EventEmitter<void>();

    @ViewChild(CalendarComponent)
    calendar: CalendarComponent;

    readonly dateRangeModesTitle = DateRangeModesTitle;
    readonly isMobileDevice$ = this.environmentService.isMobileDevice$;
    readonly modalVariation = [ModalVariation.FullHeight];
    readonly modalVariationDesktop = [ModalVariation.Narrow];
    readonly toPriorFromWarningMessage =
        'Choosing a prior date will update the "From" date';

    isDropdownOpen = false;
    selectedState = this.getEmptySelectedState();
    dateRange = this.getEmptySelectedStateNew();
    selectionMode = DateRangeSelectionMode.From;
    changedMode$: Observable<DateRangeSelectionMode | null>;
    currentMonthSelected = true;
    isMobileDevice: boolean;

    private readonly currentMonth = dayjs().startOf('month');
    private readonly dateFormat = 'YYYY-MM-DD';

    ngOnInit(): void {
        this.environmentService.isMobileDevice$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileDevice) => {
                this.isMobileDevice = isMobileDevice;
            });
    }

    openDatePicker(): void {
        if (!this.isModalOpen) {
            this.isModalOpen = true;
            this.changeDetectorRef.markForCheck();
        }
    }

    closeDatePicker(): void {
        if (this.openInDropdown) {
            this.toggleDropdownDatePicker();
        } else {
            this.isModalOpen = false;
        }
    }

    dropdownOpenChange(): void {
        this.dropdownClose.emit(this.selectedState);
    }

    toggleDropdownDatePicker(): void {
        if (this.isMobileDevice) {
            this.isModalOpen = !this.isModalOpen;
            return;
        }

        this.isDropdownOpen = !this.isDropdownOpen;
    }

    handleModalEvent({ type }: ModalEvent): void {
        if (type === ModalEventType.Detach) {
            this.selectionMode = DateRangeSelectionMode.From;
            this.selectedState = this.getEmptySelectedState();

            this.close.emit();
        }
    }

    handleReset(event: Event): void {
        event.stopPropagation();
        this.reset.emit();
    }

    getSelectedDates(range: DateRange): Dayjs[] {
        return Object.keys(range).reduce(
            (selected: Dayjs[], key: keyof DateRange) => {
                const item = range[key];

                if (item) {
                    selected.push(dayjs(item));
                }
                return selected;
            },
            [],
        );
    }

    getMaxAvailableDate([
        maxDateAvailable,
        historicalOnly,
        from,
        selectionMode,
    ]: [Dayjs, boolean, Dayjs, DateRangeSelectionMode]): Dayjs {
        if (
            selectionMode === DateRangeSelectionMode.To &&
            this.maxDatesInRange &&
            from
        ) {
            const { maxDates, rangeType } = this.maxDatesInRange;
            const minDate = Math.min(
                +dayjs(from).startOf('day').add(maxDates, rangeType),
                +dayjs().startOf('day'),
            );
            return dayjs(minDate);
        }

        if (maxDateAvailable) {
            return maxDateAvailable;
        }

        return historicalOnly ? dayjs().startOf('day') : null;
    }

    handleSelect(): void {
        if (
            !(
                this.hasBothDates(this.selectedState) &&
                this.isToDateInRange(
                    this.availableDateRange?.to,
                    this.historicalOnly,
                    this.selectedState,
                )
            )
        ) {
            return;
        }

        this.select.emit(this.selectedState);
        this.closeDatePicker();
    }

    onDateRangeSelected({ from, to }: DateRangeState): void {
        if (from?.isValid() && to?.isValid()) {
            this.select.emit({ from: from.toString(), to: to.toString() });
            this.closeDatePicker();
        }
    }

    resetDates(): void {
        this.selectionMode = DateRangeSelectionMode.From;
        this.selectedState = this.getEmptySelectedState();
    }

    goToCurrentMonth(): void {
        if (!this.calendar) {
            return;
        }

        this.calendar.goToToday();
        this.calendar.changeDetectorRef.markForCheck();
    }

    changeRangeTab(mode: DateRangeSelectionMode): void {
        this.selectionMode = mode;
        this.syncCurrentMonthWithTab(mode);
    }

    handleSelectedDate(date: Dayjs): void {
        let selectedDateRange: DateRange;

        if (this.selectionMode === DateRangeSelectionMode.From) {
            selectedDateRange = this.getFromSelectedDates(date);
        } else {
            selectedDateRange = this.getToSelectedDates(date);
        }

        this.selectedState = { ...selectedDateRange };

        if (this.openInDropdown && !this.isMobileDevice) {
            this.handleSelect();
        }
    }

    handleChangedMonth(month: Dayjs): void {
        this.currentMonthSelected = this.currentMonth.isSame(month);
    }

    hasBothDates(range: DateRange): boolean {
        return !isEmpty(range) && !!range.from && !!range.to;
    }

    getToPriorFromWarningDate([selectedState, selectionMode]: [
        DateRange,
        DateRangeSelectionMode,
    ]): Dayjs | null {
        if (
            selectionMode === DateRangeSelectionMode.To &&
            !isEmpty(selectedState.from)
        ) {
            return dayjs(selectedState.from);
        }

        return null;
    }

    getDayjsStartDate(date: DateRange): Dayjs {
        return dayjs(date[this.selectionMode]);
    }

    handleCancel(): void {
        this.resetDates();
        this.isModalOpen = false;
    }

    private initSelectedDates(isModalOpen: boolean): void {
        if (isModalOpen && this.selected) {
            this.setSelectedState(this.selected);
        }
    }

    private getEmptySelectedState(): DateRange {
        return {
            from: null,
            to: null,
        };
    }

    private getEmptySelectedStateNew(): DateRangeState {
        return {
            from: null,
            to: null,
        };
    }

    private setSelectedState(range: DateRange): void {
        if (!isEmpty(range)) {
            this.selectedState = range;
            this.dateRange = {
                ...(!!range.from && { from: dayjs(range.from) }),
                ...(!!range.to && { to: dayjs(range.to) }),
            };
        }
    }

    private getFromSelectedDates(dateFrom: Dayjs): DateRange {
        const { selectedState } = this;
        const range: DateRange = {};

        range.from = dateFrom.format(this.dateFormat);

        if (!isEmpty(selectedState.to) && dateFrom.isBefore(selectedState.to)) {
            range.to = selectedState.to;
        }

        this.selectionMode = DateRangeSelectionMode.To;

        return range;
    }

    private getToSelectedDates(dateTo: Dayjs): DateRange {
        const { selectedState } = this;
        const range: DateRange = {};

        if (dateTo.isBefore(selectedState.from)) {
            range.from = dateTo.format(this.dateFormat);
            this.notifyThatDateFromWasChanged();
        } else {
            range.from = selectedState.from;
            range.to = dateTo.format(this.dateFormat);
        }

        return range;
    }

    private notifyThatDateFromWasChanged(): void {
        this.changedMode$ = merge(
            of(DateRangeSelectionMode.From),
            of(null).pipe(delay(250)),
        );
    }

    private syncCurrentMonthWithTab(mode: DateRangeSelectionMode): void {
        if (this.calendar && this.activeTabHasDate(mode)) {
            this.calendar.goToDay(dayjs(this.selectedState[mode]));
        }
    }

    private activeTabHasDate(mode: DateRangeSelectionMode): boolean {
        return !!this.selectedState[mode];
    }

    private isToDateInRange(
        maxDateAvailable: Dayjs,
        historicalOnly: boolean,
        range: DateRange,
    ): boolean {
        const {
            [DateRangeSelectionMode.From]: from,
            [DateRangeSelectionMode.To]: to,
        } = range;
        const maxAvailableDate = this.getMaxAvailableDate([
            maxDateAvailable,
            historicalOnly,
            dayjs(from),
            this.selectionMode,
        ]);
        return !maxAvailableDate || dayjs(to) <= maxAvailableDate;
    }
}
