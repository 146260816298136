import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[fiNoCopyPaste]',
    standalone: true,
})
export class BlockCopyPasteDirective {
    @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent): void {
        event.preventDefault();
    }

    @HostListener('copy', ['$event']) blockCopy(event: KeyboardEvent): void {
        event.preventDefault();
    }

    @HostListener('cut', ['$event']) blockCut(event: KeyboardEvent): void {
        event.preventDefault();
    }
}
