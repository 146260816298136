import {
    AfterViewInit,
    Directive,
    ElementRef,
    Input,
    inject,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { asyncScheduler, of } from 'rxjs';
import { observeOn } from 'rxjs/operators';

/**
 * Focused on the element if condition is true, and blur the focus if condition is false
 *
 * Searching focusable element by allowing conditions: by selector from "focusOn" attribute or set itself as focusable element
 */
@UntilDestroy()
@Directive({
    selector: '[focusOnCondition]',
    standalone: true,
})
export class FocusOnConditionDirective implements AfterViewInit {
    private readonly el = inject(ElementRef);

    @Input() focusOnCondition: boolean;
    @Input() focusOn: string;

    ngAfterViewInit(): void {
        this.toggleFocus();
    }

    private get element(): HTMLElement {
        return this.el.nativeElement;
    }

    private toggleFocus(): void {
        if (this.focusOnCondition) {
            const element = this.getFocusableElement();
            of(null)
                .pipe(observeOn(asyncScheduler), untilDestroyed(this))
                .subscribe(() => {
                    element.scrollIntoView();
                    element.focus();
                });
        }
    }

    private getFocusableElement(): HTMLElement {
        let focusElement;

        // Searching the element by selector from "focusOn" attribute
        if (this.focusOn) {
            focusElement = this.element.querySelector(this.focusOn);
        }

        // Set yourself as focusable element
        return focusElement || this.element;
    }
}
