export type MapMarkerIcon =
    | LocationMapMarkerIcon
    | TruckMapMarkerIcon
    | UnitMapMarkerIcon
    | UnitDirectionMapMarkerIcon
    | UnitClusterMapMarkerIcon
    | ServiceProviderMapMarkerIcon
    | HubMapMarkerIcon;

export enum LocationMapMarkerIcon {
    Penske = 'locationPenske',
    PenskeFaded = 'locationPenskeFaded',
    PenskePromo = 'locationPenskePromo',
    PenskePromoFaded = 'locationPenskePromoFaded',
    PenskeFavorite = 'locationPenskeFavorite',
    PenskeFavoriteFaded = 'locationPenskeFavoriteFaded',
    PenskeClosed = 'locationPenskeClosed',
    PenskeClosedFaded = 'locationPenskeClosedFaded',
    PenskePromoClosed = 'locationPenskePromoClosed',
    PenskePromoClosedFaded = 'locationPenskePromoClosedFaded',
    PenskeFavoriteClosed = 'locationPenskeFavoriteClosed',
    PenskeFavoriteClosedFaded = 'locationPenskeFavoriteClosedFaded',
    PenskeBlocked = 'locationPenskeBlocked',
    PenskeBlockedFaded = 'locationPenskeBlockedFaded',

    Loves = 'locationLoves',
    LovesFaded = 'locationLovesFaded',
    LovesFavorite = 'locationLovesFavorite',
    LovesFavoriteFaded = 'locationLovesFavoriteFaded',
    LovesClosed = 'locationLovesClosed',
    LovesClosedFaded = 'locationLovesClosedFaded',
    LovesFavoriteClosed = 'locationLovesFavoriteClosed',
    LovesFavoriteClosedFaded = 'locationLovesFavoriteClosedFaded',

    ThirdParty = 'locationThirdParty',
    ThirdPartyFaded = 'locationThirdPartyFaded',
    ThirdPartyPromo = 'locationThirdPartyPromo',
    ThirdPartyPromoFaded = 'locationThirdPartyPromoFaded',
    ThirdPartyFavorite = 'locationThirdPartyFavorite',
    ThirdPartyFavoriteFaded = 'locationThirdPartyFavoriteFaded',
    ThirdPartyClosed = 'locationThirdPartyClosed',
    ThirdPartyClosedFaded = 'locationThirdPartyClosedFaded',
    ThirdPartyPromoClosed = 'locationThirdPartyPromoClosed',
    ThirdPartyPromoClosedFaded = 'locationThirdPartyPromoClosedFaded',
    ThirdPartyFavoriteClosed = 'locationThirdPartyFavoriteClosed',
    ThirdPartyFavoriteClosedFaded = 'locationThirdPartyFavoriteClosedFaded',
    ThirdPartyBlocked = 'locationThirdPartyBlocked',
    ThirdPartyBlockedFaded = 'locationThirdPartyBlockedFaded',

    Current = 'locationCurrent',
}

export enum TruckMapMarkerIcon {
    Alert = 'truckAlert',
    AlertFaded = 'truckAlertFaded',
    AlertSelected = 'truckAlertSelected',
    Connected = 'truckConnected',
    ConnectedFaded = 'truckConnectedFaded',
    ConnectedSelected = 'truckConnectedSelected',
}

export const enum UnitMapMarkerIcon {
    Complete = 'unitComplete',
    Progress = 'unitProgress',
    ProviderAssigned = 'unitProviderAssigned',
    ProviderOnSite = 'unitProviderOnSite',
    ProviderOnTheWay = 'unitProviderOnTheWay',
}

export const enum ServiceProviderMapMarkerIcon {
    ProviderOnTheWay = 'unitProviderOnTheWay',
    ProviderOnSite = 'unitProviderOnSite',
}

export const enum UnitDirectionMapMarkerIcon {
    NoDirection = 'noUnitDirection',
    North = 'unitDirectionNorth',
    NorthWest = 'unitDirectionNorthWest',
    West = 'unitDirectionWest',
    SouthWest = 'unitDirectionSouthWest',
    South = 'unitDirectionSouth',
    SouthEast = 'unitDirectionSouthEast',
    East = 'unitDirectionEast',
    NorthEast = 'unitDirectionNorthEast',
}

export const enum UnitClusterMapMarkerIcon {
    NoAlerts = 'noAlertsCluster',
    NoAlertsHovered = 'noAlertsClusterHovered',
    HasAlerts = 'hasAlertsCluster',
    HasAlertsHovered = 'hasAlertsClusterHovered',
    IsAlert = 'isAlertCluster',
    IsAlertHovered = 'isAlertClusterHovered',
}

export const enum HubMapMarkerIcon {
    CurrentHub = 'current-hub',
}
