import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    inject,
} from '@angular/core';
import { AsyncPipe } from '@angular/common';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { get, isFunction, includes } from 'lodash';
import { delay } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { EnvironmentService } from '../../../services';
import { ButtonComponent, IconComponent } from '../../../components';
import { MemoizeFuncPipe } from '../../../pipes';
import { FilteringDirective } from '../filtering.directive';
import { AppliedFilter, HIDDEN_ON_APPLY_FILTERS } from '../models';

@UntilDestroy()
@Component({
    selector: 'fi-filtering-opener',
    templateUrl: './opener.component.html',
    styleUrls: ['./opener.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [AsyncPipe, ButtonComponent, IconComponent, MemoizeFuncPipe],
})
export class FilteringOpenerComponent implements OnInit {
    private changeDetectorRef = inject(ChangeDetectorRef);
    private environmentService = inject(EnvironmentService);
    private filtering = inject(FilteringDirective, { optional: true });

    @Input() customCountCalculate: (filters: AppliedFilter[]) => number;
    @Input() desktopViewOnly = false;
    @Input('isMobileDevice') isMobileDevice$: Observable<boolean> =
        this.environmentService.isMobile$;
    @Input() hideIcon = false;
    @Input() title = 'Filter';

    isFilterShow = false;
    appliedFilters: AppliedFilter[] = [];

    ngOnInit(): void {
        if (!this.filtering) {
            return;
        }

        this.filtering.stateChanged$
            .pipe(
                // delay to prevent ExpressionChangedAfterItHasBeenCheckedError
                delay(0),
                untilDestroyed(this),
            )
            .subscribe(() => this.updateState());

        this.updateState();
    }

    toggleShowFilters(): void {
        if (!this.filtering) {
            return;
        }

        this.filtering.toggleShowPanel();
    }

    getAppliedFiltersCount(filters: AppliedFilter[]): number {
        if (isFunction(this.customCountCalculate)) {
            return this.customCountCalculate(filters);
        }

        const hiddenOnApplyValueFiltersCount =
            this.getHiddenOnApplyValueFiltersCount(filters);

        if (hiddenOnApplyValueFiltersCount > 0) {
            return filters.length - hiddenOnApplyValueFiltersCount;
        }

        return filters.length;
    }

    private getHiddenOnApplyValueFiltersCount(
        filters: AppliedFilter[],
    ): number {
        return filters.filter((filter) => {
            return (
                get(filter, 'value.isApplyHidden') ||
                includes(HIDDEN_ON_APPLY_FILTERS, get(filter, 'value.code'))
            );
        }).length;
    }

    private updateState(): void {
        const { appliedFilters, isFilterShow } = this.filtering;

        this.appliedFilters = appliedFilters;
        this.isFilterShow = isFilterShow;

        this.changeDetectorRef.markForCheck();
    }
}
