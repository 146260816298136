import { Directive, EventEmitter, Inject, Output } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Observable } from 'rxjs';

import { FocusWithinService } from '../services';

@UntilDestroy()
@Directive({
    selector: '[focusWithin]',
    providers: [FocusWithinService],
    standalone: true,
})
export class FocusWithinDirective {
    @Output('focusWithin') readonly focusWithinElem = new EventEmitter<Element | null>();

    constructor(@Inject(FocusWithinService) readonly focusWithin: Observable<Element | null>) {
        this.focusWithin
            .pipe(untilDestroyed(this))
            .subscribe((element: HTMLElement | null) => this.focusWithinElem.emit(element));
    }
}
