import { Injectable, inject } from '@angular/core';

import { Subject } from 'rxjs';

import { CommonStorageManager } from '../../services';

const UNIQUE_STORE_KEY = 'accordion/state';

@Injectable({
    providedIn: 'root',
})
export class AccordionService {
    private storeManager = inject(CommonStorageManager);

    private toggleItemSubject = new Subject<string>();
    toggleItem$ = this.toggleItemSubject.asObservable();

    private get storage() {
        return this.storeManager.getLocalStorage(UNIQUE_STORE_KEY);
    }

    isExpanded(
        accordionId: string,
        itemId: string,
        defaultExpanded = false,
    ): boolean {
        return this.storage.get(
            this.generateKey(accordionId, itemId),
            defaultExpanded,
        );
    }

    memoizeExpandedStatus(
        accordionId: string,
        itemId: string,
        isExpanded: boolean,
    ): void {
        this.storage.set(this.generateKey(accordionId, itemId), isExpanded);
    }

    toggleItem(itemId: string): void {
        this.toggleItemSubject.next(itemId);
    }

    private generateKey(accordionId: string, itemId: string): string {
        return `${accordionId}:${itemId}`;
    }
}
