import { AfterViewInit, Directive, ElementRef, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { fromEvent } from 'rxjs';

@UntilDestroy()
@Directive({
    selector: '[fiSearchTrimOnPaste]',
    standalone: true,
})
export class SearchTrimOnPasteDirective implements AfterViewInit {
    constructor(
        private elementRef: ElementRef<HTMLInputElement>,
        @Self() private ngControl: NgControl,
    ) {}

    ngAfterViewInit(): void {
        fromEvent<ClipboardEvent & { target: HTMLInputElement }>(
            this.elementRef.nativeElement,
            'paste',
        )
            .pipe(untilDestroyed(this))
            .subscribe(({ clipboardData, target: input }) => {
                event.preventDefault();

                const valueToPaste =
                    this.getClipboardTrimmedText(clipboardData);
                const [beforeCaretValue, afterCaretValue] =
                    this.getInputSplitValue(input);
                const updatedCaretPosition =
                    input.selectionStart + valueToPaste.length;
                const updatedValue = `${beforeCaretValue}${valueToPaste}${afterCaretValue}`;

                this.ngControl.control.setValue(updatedValue, {
                    emitEvent: false,
                });

                input.setSelectionRange(
                    updatedCaretPosition,
                    updatedCaretPosition,
                );
            });
    }

    private getClipboardTrimmedText(
        clipboardData: ClipboardEvent['clipboardData'],
    ): string {
        const data: ClipboardEvent['clipboardData'] =
            clipboardData || (window as any).clipboardData;
        const text = data ? data.getData('text') : '';

        return text.trim();
    }

    private getInputSplitValue(input: HTMLInputElement): [string, string] {
        const caretStartPosition = input.selectionStart;
        const caretEndPosition = input.selectionEnd;
        const value = input.value;

        const beforeCaretValue = value.substring(0, caretStartPosition);
        const afterCaretValue = value.substring(caretEndPosition, value.length);

        return [beforeCaretValue, afterCaretValue];
    }
}
