import {
    Directive,
    Input,
    HostBinding,
    AfterViewInit,
    ElementRef,
    ChangeDetectorRef,
    OnChanges,
    SimpleChanges,
    SimpleChange,
    TemplateRef,
    ViewContainerRef,
    inject,
} from '@angular/core';

import { isNil } from 'lodash';

import {
    GuidedTourComponent,
    GuidedTourActiveStep,
    GuidedTourStep,
} from './guided-tour.component';

export const CUSTOM_GUIDED_BTN_ID = 'custom-guided-btn-id';
export const CONTENT_GUIDED_BTN_ID = 'content-guided-btn-id';
const BODY_CONTAINER_CLASS_NAME = '.app-wrap';

@Directive({
    selector: '[guidedTourStep]',
    standalone: true,
})
export class GuidedTourStepDirective implements OnChanges, AfterViewInit {
    private elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
    private changeDetectorRef = inject(ChangeDetectorRef);
    private view = inject(ViewContainerRef);

    @Input() guidedTourStep: number | null;
    @Input() guidedTourTitle: string;
    @Input() guidedTourText: string | null;
    @Input() guidedTourContent: TemplateRef<any> | null;
    @Input() guidedTourScrollOffset: number | null;
    @Input() guidedTourScrollTopMargin: number;
    @Input() guidedTourSkipStep = false;
    // Highlight components outside <guided-tour> by class
    @Input() guidedTourGetByClass: string;
    @Input() guidedTourId: string;

    @HostBinding('class.guided-tour-element-active') active = false;

    private guidedTourComponent: GuidedTourComponent;

    ngAfterViewInit(): void {
        this.guidedTourComponent = this.view.injector.get(
            GuidedTourComponent,
            null,
        );

        if (!this.guidedTourComponent) {
            return;
        }

        if (this.guidedTourGetByClass) {
            this.registerByClassIfElementPresented();
        } else {
            this.registerSelfInSteps();
        }

        this.guidedTourComponent.activeStep$.subscribe((value) => {
            this.active = this.isCurrentStepActive(value);
            this.changeDetectorRef.markForCheck();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        const {
            guidedTourSkipStep: skip,
            guidedTourStep: step,
            guidedTourText: text,
        } = changes;

        if (
            this.inputChanged(skip) ||
            this.inputChanged(step) ||
            this.inputChanged(text)
        ) {
            this.registerSelfInSteps();
        }
    }

    private registerByClassIfElementPresented(): void {
        const elementObserver = new MutationObserver(() => {
            const stepElement = this.getElementRef();
            const isElementPresented = document.body.contains(stepElement);

            if (isElementPresented) {
                this.registerSelfInSteps();
                elementObserver.disconnect();
            }
        });
        const mainContainerElement = document.querySelector(
            BODY_CONTAINER_CLASS_NAME,
        );

        elementObserver.observe(mainContainerElement, {
            childList: true,
            subtree: true,
        });
    }

    private inputChanged(input: SimpleChange): boolean {
        return (
            input &&
            !input.firstChange &&
            input.previousValue !== input.currentValue
        );
    }

    private registerSelfInSteps(): void {
        if (isNil(this.guidedTourStep)) {
            return;
        }

        const newStep = this.getNewStep();

        this.setGuidedBtnStep(newStep);

        if (this.guidedTourId === CONTENT_GUIDED_BTN_ID) {
            return;
        }

        const tourStepKey = this.getStepKey();
        const stepKey =
            tourStepKey || this.guidedTourComponent.initialSteps.size + 1;

        this.guidedTourComponent.initialSteps.set(stepKey, newStep);
    }

    private setGuidedBtnStep(step: GuidedTourStep): void {
        if (this.guidedTourId === CONTENT_GUIDED_BTN_ID) {
            this.guidedTourComponent.setContentGuidedBtnStep(step);
        }

        if (this.guidedTourId === CUSTOM_GUIDED_BTN_ID) {
            this.guidedTourComponent.setCustomGuidedBtnStep(step);
        }
    }

    private getNewStep(): GuidedTourStep {
        return {
            elementRef: this.elementRef,
            element: this.guidedTourGetByClass ? this.getElementRef() : null,
            order: this.guidedTourStep,
            initialOrder: this.guidedTourStep,
            title: this.guidedTourTitle,
            text: this.guidedTourText,
            content: this.guidedTourContent,
            scrollOffset: this.guidedTourScrollOffset,
            scrollTopMargin: this.guidedTourScrollTopMargin,
            skip: this.guidedTourSkipStep,
            tourId: this.guidedTourId,
        };
    }

    private getStepKey(): number {
        // Returns Map's item if it has the same step number as a new step.
        const existingMapStep = [
            ...this.guidedTourComponent.initialSteps.entries(),
        ].find((item: [number, GuidedTourStep]) => {
            if (item[1].order === this.guidedTourStep) {
                return item;
            }
        });

        return existingMapStep && existingMapStep[0];
    }

    private isCurrentStepActive(step: GuidedTourActiveStep): boolean {
        return step.visible && step.initialOrder === this.guidedTourStep;
    }

    private getElementRef(): HTMLElement {
        return document.querySelector(this.guidedTourGetByClass);
    }
}
