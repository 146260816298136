<div class="fi-select-custom-date-option">
    <div
        (click)="openDateRangePicker()"
        (keydown.enter)="openDateRangePicker()"
        role="button"
        aria-label="Open date picker"
        class="fi-select__option"
    >
        <fi-radio
            [checked]="checked"
            [variation]="'select-option-content,regular'"
            id="customDateRadio"
            class="fi-select__option-content"
        >
            <span id="customDateDescription" class="fi-text fi-text--medium">
                {{ option | memoizeFunc: getDescription }}
            </span>
        </fi-radio>

        @if (
            [showCalendarIcon, checked, selected]
                | memoizeFunc: isCalendarIconVisible : this
        ) {
            <fi-icon
                [svgName]="'calendar-alt'"
                [variation]="'regular'"
                class="fi-select-custom-date-option__icon"
            ></fi-icon>
        }
    </div>

    <fi-date-range
        [selected]="selected"
        [availableDateRange]="availableDateRange"
        [maxDatesInRange]="maxDatesInRange"
        [currentMonthPosition]="currentMonthPosition"
        [isNewDateRangeModal]="isNewDateRangeModal"
        [historicalOnly]="historicalOnly"
        [rangeTitle]="option | memoizeFunc: getRangeTitle"
        [class.fi-select-custom-date-option__range--open]="checked"
        (select)="selectDateRange($event)"
        (reset)="resetDateRange()"
        class="fi-select-custom-date-option__range"
    ></fi-date-range>
</div>
