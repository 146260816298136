<span
    [className]="gtmClassName"
    [fiVariation]="variation"
    [fiVariationBase]="'fi-radio'">
    <input
        [disabled]="disabled"
        [checked]="checked"
        tabindex="-1"
        class="fi-radio__input"
        type="radio"
    />
    <span
          (click)="handleRadioChange()"
          (keydown.space)="handleRadioChange($event)"
          class="fi-radio__cover"
          tabindex="{{ disabled ? '-1' : 0 }}">
    </span>
</span>

<ng-content></ng-content>
