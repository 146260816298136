import { NgModule, Type } from '@angular/core';

import { DateFieldComponent } from './field/field.component';
import { DateRangeComponent } from './range/range.component';
import { DateRangeControlComponent } from './range-control/range-control.component';
import { DateRangeModalComponent } from './range-modal/range-modal.component';
import { DateRangeDropdownComponent } from './range-dropdown/range-dropdown.component';
import { TimeRangeDropdownComponent } from './time-range-dropdown/time-range-dropdown.component';
import { DateRangeCustomComponent } from './range-custom/range-custom.component';

const FEATURES: Array<Type<any> | any[]> = [
    TimeRangeDropdownComponent,
    DateRangeModalComponent,
    DateRangeDropdownComponent,
    DateFieldComponent,
    DateRangeComponent,
    DateRangeCustomComponent,
    DateRangeControlComponent,
];

@NgModule({
    imports: [...FEATURES],
    exports: [...FEATURES],
})
export class DateModule {}
