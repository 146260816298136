import { NgModule, Type } from '@angular/core';

import { GuidedTourComponent } from './components/guided-tour/guided-tour.component';
import { GuidedTourStepDirective } from './components/guided-tour/guided-tour-step.directive';
import { GuidedTourIndicatorComponent } from './components/guided-tour-indicator/guided-tour-indicator.component';
import { GuidedTourButtonComponent } from './components/guided-tour-button/guided-tour-button.component';

const FEATURES: Array<Type<any> | any[]> = [
    GuidedTourComponent,
    GuidedTourStepDirective,
    GuidedTourButtonComponent,
];

const ISOLATED_FEATURES: Array<Type<any> | any[]> = [
    GuidedTourIndicatorComponent,
];

@NgModule({
    imports: [...ISOLATED_FEATURES, ...FEATURES],
    exports: [FEATURES],
})
export class GuidedTourModule {}
