<cdk-accordion
    [fiVariation]="variation"
    [fiVariationBase]="'fi-accordion'"
    [multi]="multi"
>
    <ng-container
        [ngTemplateOutlet]="grouped ? groupTpl : itemTpl"
        [ngTemplateOutletContext]="grouped ? {} : { items: items }"
    ></ng-container>

    <ng-template #itemTpl let-items="items">
        @for (item of items; track item; let i = $index) {
            <cdk-accordion-item
                #cdkAccordionItem="cdkAccordionItem"
                [class.fi-accordion__item--open]="item.expanded"
                [expanded]="item.expanded"
                [disabled]="item.disabled"
                [id]="item.id"
                [guidedTourSkipStep]="!item.guidedTourItem"
                [guidedTourStep]="
                    item.guidedTourItem ? item.guidedTourItem.step : null
                "
                [guidedTourTitle]="
                    item.guidedTourItem ? item.guidedTourItem.title : ''
                "
                [guidedTourText]="
                    item.guidedTourItem ? item.guidedTourItem.text : ''
                "
                [guidedTourScrollTopMargin]="
                    item.guidedTourItem ? item.guidedTourItem.topMargin : null
                "
                [guidedTourId]="
                    item.guidedTourItem ? item.guidedTourItem.tourId : ''
                "
                (expandedChange)="handleExpandChange($event, item, i)"
                class="fi-accordion__item"
            >
                @if (!item.customHeader) {
                    <div
                        [class.fi-accordion__header--disabled]="item.disabled"
                        (click)="toggleAccordion(item, cdkAccordionItem)"
                        (keydown.enter)="
                            toggleAccordion(item, cdkAccordionItem)
                        "
                        class="fi-accordion__header"
                        role="button"
                        tabindex="0"
                    >
                        <div
                            [guidedTourSkipStep]="!item.guidedTourHeader"
                            [guidedTourStep]="
                                item.guidedTourHeader
                                    ? item.guidedTourHeader.step
                                    : null
                            "
                            [guidedTourTitle]="
                                item.guidedTourHeader
                                    ? item.guidedTourHeader.title
                                    : ''
                            "
                            [guidedTourText]="
                                item.guidedTourHeader
                                    ? item.guidedTourHeader.text
                                    : ''
                            "
                            [guidedTourScrollTopMargin]="
                                item.guidedTourHeader
                                    ? item.guidedTourHeader.topMargin
                                    : null
                            "
                            class="fi-accordion__header-guided"
                        >
                            <div class="fi-accordion__open-icon">
                                <fi-accordion-expander-icon
                                    [expanded]="cdkAccordionItem.expanded"
                                ></fi-accordion-expander-icon>
                            </div>
                            <div class="fi-accordion__header-content">
                                <ng-container
                                    [ngTemplateOutlet]="item.header"
                                ></ng-container>
                            </div>
                        </div>
                    </div>
                }
                @if (item.customHeader) {
                    <div
                        (click)="toggleAccordion(item, cdkAccordionItem)"
                        (keydown.enter)="
                            toggleAccordion(item, cdkAccordionItem)
                        "
                        class="fi-accordion__custom-header"
                        role="button"
                        tabindex="0"
                    >
                        <div class="fi-accordion__header-content">
                            <ng-container
                                [ngTemplateOutlet]="item.customHeader"
                            ></ng-container>
                        </div>
                    </div>
                }
                @if (cdkAccordionItem.expanded || accordionForPrint) {
                    <div
                        @animateExpansion
                        [class.fi-accordion__content]="
                            cdkAccordionItem.expanded && !accordionForPrint
                        "
                        [class.fi-accordion__content-print]="accordionForPrint"
                        [guidedTourSkipStep]="!item.guidedTourContent"
                        [guidedTourStep]="
                            item.guidedTourContent
                                ? item.guidedTourContent.step
                                : null
                        "
                        [guidedTourTitle]="
                            item.guidedTourContent
                                ? item.guidedTourContent.title
                                : ''
                        "
                        [guidedTourText]="
                            item.guidedTourContent
                                ? item.guidedTourContent.text
                                : ''
                        "
                        [guidedTourScrollTopMargin]="
                            item.guidedTourContent
                                ? item.guidedTourContent.topMargin
                                : null
                        "
                    >
                        <ng-container
                            [ngTemplateOutlet]="item.content"
                        ></ng-container>
                    </div>
                }
            </cdk-accordion-item>
        }
    </ng-template>

    <ng-template #groupTpl>
        @for (group of groups; track group) {
            <div class="fi-accordion__group">
                <ng-container [ngTemplateOutlet]="group.header"></ng-container>
                <ng-container [ngTemplateOutlet]="group.stub"></ng-container>
                <ng-container
                    [ngTemplateOutlet]="itemTpl"
                    [ngTemplateOutletContext]="{ items: group.items }"
                ></ng-container>
            </div>
        }
    </ng-template>
</cdk-accordion>

@if (hasPreventLeaveModal) {
    <leave-confirmation-modal
        [isOpenModal]="isPreventLeaveOpenModal"
        (close)="closeLeaveModal()"
        (closedOnLeft)="confirmClosingAccordion()"
    ></leave-confirmation-modal>
}
