import { NgModule, Type } from '@angular/core';

import { DropdownItemComponent } from './item/item.component';
import { DropdownComponent } from './dropdown.component';
import { DropdownListDefDirective } from './list/list-def.directive';
import { DropdownLabelComponent } from './label/label.component';
import { SimpleDropdownComponent } from './dropdown-simple.component';

const FEATURES: Array<Type<any> | any[]> = [
    DropdownItemComponent,
    DropdownComponent,
    DropdownListDefDirective,
    DropdownLabelComponent,
    SimpleDropdownComponent,
];

@NgModule({
    imports: [...FEATURES],
    exports: FEATURES,
})
export class DropdownModule {}
