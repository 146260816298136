import { repeat } from 'lodash';

export const enum EmailParsedGroup {
    Name = 'name',
    At = 'at',
    DomainName = 'domainName',
    Dot = 'dot',
    DomainArea = 'domainArea',
}

export type EmailParsedModel = Map<EmailParsedGroup, string>;

export function parseEmailValueToGroups(value = ''): EmailParsedModel {
    const matched = value.match(EMAIL_REGEX);

    return new Map([
        [EmailParsedGroup.Name, matched[1]],
        [EmailParsedGroup.At, matched[2]],
        [EmailParsedGroup.DomainName, matched[3]],
        [EmailParsedGroup.Dot, matched[4]],
        [EmailParsedGroup.DomainArea, matched[5]],
    ]);
}

export const enum MaskKey {
    Filled = 'filled',
    Placeholder = 'placeholder',
}

const EMAIL_REGEX = /(.[^@]*)?(@)?(.[^\.]*)?(\.)?(.*)?/;

export type MaskValue = {
    [key: string]: string;
};

type CharacterMaskValue = {
    maskKey: MaskKey;
    character: string;
};

export function emailFieldFormat(value: string, previousValue: string): string {
    if (!value) {
        return '';
    }

    if (hasOnlyDotOrAt(value)) {
        return '';
    }

    if (startWithDotOrAt(value) || hasDoubleDotsOrAt(value)) {
        return previousValue;
    }

    if (hasSecondAt(value)) {
        return getValueWithMovedAt(value, previousValue);
    }

    return value;
}

function hasOnlyDotOrAt(value: string): boolean {
    return /^[\.|@]$/.test(value);
}

function startWithDotOrAt(value: string): boolean {
    return /^\.|^@/.test(value);
}

function hasDoubleDotsOrAt(value: string): boolean {
    return /\.{2}|@{2}/.test(value);
}

function hasSecondAt(value: string): boolean {
    const matched = value.match(/@/g);

    return matched && matched.length > 1;
}

function getValueWithMovedAt(
    currentValue: string,
    previousValue: string,
): string {
    const previousAtPosition = previousValue.indexOf('@');

    if (currentValue.indexOf('@') < previousAtPosition) {
        const parsedValue = currentValue.split('');
        parsedValue.splice(previousAtPosition + 1, 1, '');

        return parsedValue.join('');
    }

    return previousValue;
}

export function getEmailMaskValue(value: string): MaskValue {
    if (!value) {
        return {
            filled: '',
            placeholder: '___ @ ___ . ___',
        };
    }

    const parsedValue = parseEmailValueToGroups(value);
    const maskValue = getMaskValueEmptyState();

    parsedValue.forEach((groupValue, groupKey) => {
        switch (groupKey) {
            case EmailParsedGroup.At:
            case EmailParsedGroup.Dot: {
                const { maskKey, character } = getEmailCharacter(
                    groupKey,
                    groupValue,
                );
                maskValue[maskKey] += character;

                break;
            }

            case EmailParsedGroup.Name:
            case EmailParsedGroup.DomainName:
            case EmailParsedGroup.DomainArea: {
                const { filled, placeholder } = getFilledRangeWithPlaceholder(
                    groupValue || '',
                    groupValue ? 0 : 3,
                );

                maskValue.filled += filled;
                maskValue.placeholder += placeholder;

                break;
            }
        }
    });

    return maskValue;
}

function getMaskValueEmptyState(): MaskValue {
    return {
        filled: '',
        placeholder: '',
    };
}

function getFilledRangeWithPlaceholder(
    filled: string,
    expectedFilledAmount: number,
): MaskValue {
    const placeholderCharactersAmount = expectedFilledAmount - filled.length;

    return {
        filled,
        placeholder: repeat('_', placeholderCharactersAmount),
    };
}

function getEmailCharacter(
    groupKey: EmailParsedGroup,
    groupValue: string,
): CharacterMaskValue {
    const maskKey = groupValue ? MaskKey.Filled : MaskKey.Placeholder;
    let character: string;

    switch (groupKey) {
        case EmailParsedGroup.At:
            character = groupValue ? '@' : ' @ ';
            break;

        case EmailParsedGroup.Dot:
            character = groupValue ? '.' : ' . ';
            break;
    }

    return {
        maskKey,
        character,
    };
}
