<button
    [type]="type"
    [disabled]="disabled"
    [fiVariation]="classList"
    [fiVariationBase]="'fi-button'"
    [attr.aria-label]="ariaLabel"
>
    <div class="fi-button__content">
        @if (loading) {
            <fi-icon
                [svgName]="'temp-spinner'"
                class="fi-button__loading-indicator"
            ></fi-icon>
        }

        <span class="fi-button__transclude-container">
            <ng-content></ng-content>
        </span>

        @if (!!counter) {
            <span class="fi-button__count">
                {{ counter }}
            </span>
        }
    </div>
</button>
