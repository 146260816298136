import { NgModule } from '@angular/core';

import { SlidePanelComponent } from './slide-panel.component';
import {
    SlidePanelExpanderComponent,
} from './panel-expander/panel-expander.component';

@NgModule({
    imports: [SlidePanelComponent, SlidePanelExpanderComponent],
    exports: [SlidePanelComponent],
})
export class SlidePanelModule {}
