import { NgModule, Type } from '@angular/core';

import { SelectModalComponent } from './select-modal/select-modal.component';
import { SelectModalFieldComponent } from './select-modal-field.component';

const FEATURES: Array<Type<any> | any[]> = [
    SelectModalFieldComponent,
    SelectModalComponent,
];

@NgModule({
    imports: [...FEATURES],
    exports: FEATURES,
})
export class SelectModalFieldModule {}
