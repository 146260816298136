<div
    [fiVariation]="variation"
    [fiVariationBase]="'upload-step'"
>
    <span
        [class.upload-step__dragged-over]="variation === 'dragover'"
        class="fi-text fi-text--white upload-step__title"
    >
        {{ step }}
    </span>
</div>
