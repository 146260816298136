import { NgModule } from '@angular/core';

import { features } from './feature';
import { UiCommonModule } from './ui-common.module';
import { ToastModule } from './toast';
import { FiAnalyticsModule } from './analytics';

@NgModule({
    imports: [...features, UiCommonModule, ToastModule, FiAnalyticsModule],
    exports: [...features, UiCommonModule, ToastModule, FiAnalyticsModule],
    providers: [...features],
})
export class PnskCommonModule {}
