<span class="fi-input-mask">
    <span class="fi-input-mask__filled">{{ maskValue.filled }}</span>
    <span
        [class.fi-input-mask__placeholder--phone]="format === 'phone'"
        [class.fi-input-mask__placeholder--customer-number]="
            format === 'customer-number'
        "
        [class.fi-input-mask__placeholder--sso-id]="format === 'sso-id'"
        [class.fi-input-mask__placeholder--district]="format === 'district'"
        class="fi-input-mask__placeholder"
    >
        {{ maskValue.placeholder }}
    </span>
</span>
