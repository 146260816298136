import {
    Component,
    ChangeDetectionStrategy,
    Input,
    EventEmitter,
    Output,
    ChangeDetectorRef,
    OnInit,
    inject,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { filter } from 'rxjs/operators';

import { EnvironmentService } from '../../../services';
import { ButtonComponent, IconComponent } from '../../../components';
import { TooltipDirective } from '../../tooltip';
import { GridMoreActionsService } from '../services/more-actions.service';
import {
    GridColumnListConfiguration,
    GridConfiguration,
} from '../store/models';

import { ManageColumnsModalComponent } from './manage-columns-modal/manage-columns-modal.component';

@UntilDestroy()
@Component({
    selector: 'fi-grid-manage-columns',
    templateUrl: './manage-columns-menu.component.html',
    styleUrls: ['./manage-columns-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        ButtonComponent,
        IconComponent,
        TooltipDirective,
        ManageColumnsModalComponent,
    ],
})
export class ManageColumnsMenuComponent implements OnInit {
    private readonly moreActionsService = inject(GridMoreActionsService);
    private readonly changeDetectorRef = inject(ChangeDetectorRef);
    private readonly environmentService = inject(EnvironmentService);

    @Input() columnsSettings: GridColumnListConfiguration;

    @Output() save = new EventEmitter<Partial<GridConfiguration>>();

    readonly isMobileOS = this.environmentService.isMobileOS;
    readonly manageColumnsClass = 'manage-columns';

    isModalOpen: boolean;

    ngOnInit(): void {
        this.moreActionsService.isOpenedManageColumnModal$
            .pipe(
                filter((isOpened) => isOpened),
                untilDestroyed(this),
            )
            .subscribe(() => {
                this.openModal();
                this.changeDetectorRef.markForCheck();
            });
    }

    openModal(): void {
        this.isModalOpen = true;
        this.addClass(this.manageColumnsClass);
    }

    closeModal(): void {
        this.isModalOpen = false;
        this.removeClass(this.manageColumnsClass);
        this.moreActionsService.closeManageColumnModal();
    }

    saveConfig(config: GridColumnListConfiguration): void {
        this.save.emit({ columns: config });
        this.closeModal();
    }

    private addClass(className: string): void {
        document.body.classList.add(className);
    }

    private removeClass(className: string): void {
        document.body.classList.remove(className);
    }
}
