import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
} from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
    ReactiveFormsModule,
} from '@angular/forms';
import { AsyncPipe } from '@angular/common';

import { Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';

import { EnvironmentService } from '../../services';
import { OnChange } from '../../decorators';
import { InputComponent } from '../../components/input/input.component';
import { ButtonComponent } from '../../components/button/button.component';

function validatePageNumber(
    this: GoToPageComponent,
    control: UntypedFormControl,
): { validatePageNumber: boolean } {
    const value = parseInt(control.value, 10);
    const isValueInvalid = value > this.maxPage;

    return isValueInvalid ? { validatePageNumber: true } : null;
}

@Component({
    selector: 'fi-go-to-page',
    templateUrl: './go-to-page.component.html',
    styleUrls: ['./go-to-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [AsyncPipe, ReactiveFormsModule, ButtonComponent, InputComponent],
})
export class GoToPageComponent implements OnInit, OnChanges {
    @Input() maxPage: number;

    @OnChange('handleCurrentPage')
    @Input()
    currentPage: number;

    @Output() goToPage = new EventEmitter<number>();

    readonly isTablet$ = this.environmentService.isTablet$.pipe(
        tap((isTablet) => {
            this.isTablet = isTablet;

            if (isTablet && this.currentPage) {
                this.setForm(this.currentPage);
            }
        }),
    );

    goToForm: UntypedFormGroup;
    isFormDisabled$: Observable<boolean>;
    isTablet: boolean;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private environmentService: EnvironmentService,
    ) {}

    ngOnChanges(): void {
        this.updateControlValidationManually();
    }

    ngOnInit(): void {
        this.buildForm();

        this.isFormDisabled$ = this.goToForm.valueChanges.pipe(
            startWith(null),
            map(() => !this.goToForm.valid),
        );
    }

    submitForm(): void {
        if (!this.goToForm.valid) {
            return;
        }

        const { page } = this.goToForm.getRawValue();

        this.goToPage.emit(parseInt(page, 10));
    }

    private handleCurrentPage(): void {
        if (this.isTablet) {
            this.setForm(this.currentPage);
        }
    }

    private setForm(currentPage: number): void {
        this.goToForm.patchValue({ page: currentPage.toString() });
    }

    private updateControlValidationManually(): void {
        if (!this.goToForm) {
            return;
        }

        const control = this.goToForm.get('page');
        control.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    }

    private buildForm(): void {
        this.goToForm = this.formBuilder.group({
            page: [
                null,
                [
                    Validators.required,
                    Validators.min(1),
                    validatePageNumber.bind(this),
                ],
            ],
        });
    }
}
