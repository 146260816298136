import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    inject,
} from '@angular/core';
import { animate, AnimationMetadata, style } from '@angular/animations';
import { CdkTrapFocus } from '@angular/cdk/a11y';
import { NgClass } from '@angular/common';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { combineLatest, Observable, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

import {
    OverlayContainerRef,
    OverlayEvent,
    OverlayEventType,
    OverlayScrollStrategy,
    OverlayService,
} from '../../../../feature/overlay';
import { OnChange } from '../../../../decorators';
import { EnvironmentService } from '../../../../services';
import {
    ModalAnimationType,
    ModalEvent,
    ModalEventType,
    ModalPosition,
    ModalVariation,
} from '../../models';

@UntilDestroy()
@Component({
    selector: 'fi-modal',
    templateUrl: 'modal.component.html',
    styleUrls: ['./modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CdkTrapFocus, NgClass],
})
export class ModalComponent implements OnDestroy, AfterViewInit {
    private environmentService = inject(EnvironmentService);
    private overlayService = inject(OverlayService);
    private viewContainerRef = inject(ViewContainerRef);

    @Input() position: ModalPosition;
    @Input() animation: ModalAnimationType = ModalAnimationType.SlideLeft;
    @Input() variation: ModalVariation[] = [];
    @Input() hasBackdrop = true;
    @Input() gtmClassName = '';
    @Input() scrollStrategy: OverlayScrollStrategy;
    @Input() hasCustomSmallTabletAnimation = false;

    @OnChange(function (this: ModalComponent) {
        this.toggleOpenWindow();
    })
    @Input()
    open = false;

    @Output() openChange = new EventEmitter<boolean>();
    @Output() event = new EventEmitter<ModalEvent>();

    @ViewChild(TemplateRef) modalRef: TemplateRef<any>;

    overlayDetach$ = new Subject<void>();
    detach$ = new Subject<void>();
    isOpened: boolean;

    private overlayContainerRef: OverlayContainerRef<TemplateRef<any>> | null;

    ngOnDestroy(): void {
        this.overlayDetach$.next();
        this.overlayDetach$.complete();

        this.detach$.complete();

        this.close();
    }

    ngAfterViewInit(): void {
        if (this.isOpened) {
            this.openModal();
        }
    }

    private toggleOpenWindow(): void {
        if (this.open) {
            this.show();
            return;
        }

        this.close();
    }

    private show(): void {
        if (this.overlayContainerRef) {
            return;
        }

        this.isOpened = true;

        if (!this.modalRef) {
            return;
        }

        this.openModal();
    }

    private close(): void {
        if (!this.overlayContainerRef) {
            return;
        }

        this.isOpened = false;

        this.getAnimationMetadata('leave')
            .pipe(untilDestroyed(this))
            .subscribe((animationMetadata) => {
                this.overlayContainerRef.close({
                    animation: animationMetadata,
                });

                this.overlayContainerRef = null;
            });
    }

    private openModal(): void {
        this.getAnimationMetadata('enter')
            .pipe(untilDestroyed(this))
            .subscribe((animationMetadata) => {
                const classVariations = this.variation.map(
                    (variation: string) => `modal-${variation}`,
                );

                this.overlayContainerRef = this.overlayService.create(
                    this.modalRef,
                    this.viewContainerRef,
                    {
                        variation: ['modal', ...classVariations],
                        position: this.position,
                        hasBackdrop: this.hasBackdrop,
                        ...(this.scrollStrategy && {
                            scrollStrategy: this.scrollStrategy,
                        }),
                    },
                );

                this.overlayContainerRef.open({
                    animation: animationMetadata,
                });

                if (this.overlayContainerRef) {
                    this.attachOverlayListener();
                }
            });
    }

    private attachOverlayListener(): void {
        this.overlayContainerRef.event$
            .pipe(
                takeUntil(this.overlayDetach$.asObservable()),
                untilDestroyed(this),
            )
            .subscribe(({ type }: OverlayEvent) => this.fireEvent(type));
    }

    private fireEvent(type: ModalEventType): void {
        this.event.emit(new ModalEvent(type));

        if (type === OverlayEventType.Detach) {
            this.detach$.next();
        }

        if (type === OverlayEventType.Escape) {
            this.openChange.emit(false);
        }
    }

    private getAnimationMetadata(
        direction: 'enter' | 'leave',
    ): Observable<AnimationMetadata[]> {
        return combineLatest([
            this.environmentService.isMobileDevice$,
            this.environmentService.isSmallTabletDevice$,
        ]).pipe(
            take(1),
            map(([isMobile, isSmallTablet]) => {
                const animation =
                    isMobile ||
                    (this.hasCustomSmallTabletAnimation && isSmallTablet)
                        ? this.animation
                        : ModalAnimationType.SlideTop;

                const state = this.getAnimationStartState(animation);
                const timings = this.getAnimationTimings(animation);

                const from = direction === 'enter' ? state : '*';
                const to = direction === 'enter' ? '*' : state;

                return [style(from), animate(timings, style(to))];
            }),
        );
    }

    private getAnimationStartState(animationType: ModalAnimationType): {
        [key: string]: string | number;
    } {
        switch (animationType) {
            case ModalAnimationType.SlideLeft: {
                return {
                    transform: 'translateX(100%)',
                };
            }

            case ModalAnimationType.SlideTop:
            default: {
                return {
                    transform: 'translateY(20%)',
                    opacity: 0,
                };
            }
        }
    }

    private getAnimationTimings(animationType: ModalAnimationType): string {
        switch (animationType) {
            case ModalAnimationType.SlideLeft: {
                return '500ms ease-in-out';
            }

            // temporary solution for small-view-modal
            case ModalAnimationType.None: {
                return '0ms';
            }

            case ModalAnimationType.SlideTop:
            default: {
                return '300ms ease';
            }
        }
    }
}
