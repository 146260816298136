<table class="fi-grid-table-skeleton">
    <thead>
        <tr>
            @for (column of columns; track column) {
                <th class="fi-grid-table-skeleton__cell">
                    <fi-skeleton-element
                        class="fi-grid-table-skeleton__element"
                    ></fi-skeleton-element>
                </th>
            }
        </tr>
    </thead>
    <tbody>
        @for (row of rows; track row) {
            <tr>
                <td class="fi-grid-table-skeleton__cell">
                    <fi-skeleton-element
                        class="fi-grid-table-skeleton__element"
                    ></fi-skeleton-element>
                </td>
                <td class="fi-grid-table-skeleton__cell" colspan="4">
                    <fi-skeleton-element
                        class="fi-grid-table-skeleton__element"
                    ></fi-skeleton-element>
                </td>
            </tr>
        }
    </tbody>
</table>
