import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgClass } from '@angular/common';

import { VariationDirective } from '../../../../directives';
import { ButtonComponent, IconComponent } from '../../../../components';
import { ModalHeaderVariation } from '../../models';

@Component({
    selector: 'fi-modal-header',
    templateUrl: './modal-header.component.html',
    styleUrls: ['./modal-header.component.scss'],
    standalone: true,
    imports: [NgClass, VariationDirective, ButtonComponent, IconComponent],
})
export class ModalHeaderComponent {
    @Input() variation: ModalHeaderVariation;
    @Input() noCloseButton = false;
    @Input() hasBackButton = false;
    @Input() gtmClassName = '';

    @Output() close = new EventEmitter<void>();
    @Output() back = new EventEmitter<void>();

    handleClose(): void {
        this.close.emit();
    }

    handleBack(): void {
        this.back.emit();
    }
}
