<!--@todo: revise classes-->
<div
    #origin
    [fiVariation]="variation"
    [fiVariationBase]="'fi-dropdown__header'"
    class="slot--center-vertically"
>
    <div class="slot__inner--spanned">
        <ng-content select="fi-dropdown-label"></ng-content>
    </div>
    <div class="slot__inner fi-dropdown__label-arrow">
        <fi-icon
            [svgName]="(isDropdownOpen$ | async) ? 'caret-up' : 'caret-down'"
            [variation]="'solid'"
        ></fi-icon>
    </div>
</div>
