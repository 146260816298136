import {
    Component,
    forwardRef,
    Input,
    Output,
    EventEmitter,
    ViewChild, ElementRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormsModule } from '@angular/forms';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';

import { OnChange } from '../../decorators';
import { TruncateService } from '../../services';
import { VariationDirective } from '../../directives';
import { MemoizeFuncPipe } from '../../pipes';

const DEFAULT_MAXLENGTH = 200;
export const TRUNCATE_LENGTH = 30;

const enum InputCommentVariation {
    Large = 'large',
    Medium = 'medium',
    Small = 'small',
    LimitXLarge = 'limit-x-large',
    NoWrap = 'no-wrap',
    LimitSmall = 'limit-small',
    MediumFont = 'medium-font',
    Gray = 'gray',
    Truncate = 'truncate',
    OverflowHidden = 'overflow-hidden',
}

@Component({
    selector: 'fi-input-comment',
    templateUrl: './input-comment.component.html',
    styleUrls: ['./input-comment.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputCommentComponent),
            multi: true,
        },
    ],
    standalone: true,
    imports: [
        FormsModule,
        VariationDirective,
        CdkTextareaAutosize,
        MemoizeFuncPipe,
    ]
})
export class InputCommentComponent implements ControlValueAccessor {
    @Input() value = '';
    @Input() id = '';
    @Input() maxlength = DEFAULT_MAXLENGTH;
    @Input() maxRows: number;
    @Input() placeholder = '';
    @Input() errorMsg = 'This field is required';
    @Input() invalid = false;
    @Input() warningIndicator = false;
    @Input() disabled = false;
    @Input() limitVisible = false;
    @Input() required: boolean;
    @Input() isFullRemainingFormat = false;

    @OnChange('handleVariation')
    @Input()
    variation: string;

    @OnChange('handleTruncate')
    @Input()
    hasTruncate = false;

    @Output() valueChange = new EventEmitter<string>();
    @Output() blurChange = new EventEmitter<boolean>();
    @Output() focusChange = new EventEmitter<boolean>();

    @ViewChild(CdkTextareaAutosize)
    textareaAutosize: CdkTextareaAutosize;

    @ViewChild('textareaElement', { read: ElementRef }) textareaRef: ElementRef;

    isFocused = false;
    styleVariation: string;

    constructor(private truncateService: TruncateService) {}

    handleInputFocus(): void {
        this.isFocused = true;
        this.focusChange.emit(true);
        this.blurChange.emit(false);
        this.triggerTextareaResizeEvent();
        this.styleVariation = this.variation;
    }

    handleInputBlur(): void {
        this.isFocused = false;
        this.focusChange.emit(false);
        this.blurChange.emit(true);
        this.onTouched();
        this.triggerTextareaResizeEvent();

        const needTruncate =
            this.hasTruncate &&
            this.truncateService.needTruncate(this.value, TRUNCATE_LENGTH);

        if (needTruncate) {
            this.setTruncateVariation();
        }
    }

    handleKeydownEvent(event: KeyboardEvent): void {
        event.stopPropagation();
    }

    isTooMany(value: string): boolean {
        return value.length - this.maxlength > 0;
    }

    handleInputChange(value: string): void {
        // workaround to avoid infinite loop if value is empty in IE11
        // TODO: investigate if we can fix it
        if (this.value === value) {
            return;
        }

        this.onChange(value);
        this.valueChange.emit(value);
        this.value = value;

        this.triggerTextareaResizeEvent();
    }

    writeValue(value: string): void {
        this.value = value;
    }

    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChange = (_: string) => {};

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onTouched = () => {};

    updateValue(insideValue: string): void {
        this.value = insideValue;
        this.onChange(insideValue);
        this.onTouched();
    }

    focusInput(): void {
        this.textareaRef.nativeElement.focus();
    }

    // TODO: investigate why it is necessary to call reset() method to get correct textarea size
    private triggerTextareaResizeEvent(): void {
        this.textareaAutosize.reset();
        this.textareaAutosize.resizeToFitContent(true);
    }

    private handleVariation(): void {
        this.styleVariation = this.variation || '';
    }

    private handleTruncate(): void {
        if (this.hasTruncate && !this.isFocused) {
            this.setTruncateVariation();

            return;
        }

        this.styleVariation = this.variation;
    }

    private setTruncateVariation(): void {
        this.styleVariation = `${this.variation},${InputCommentVariation.Truncate}`;
    }
}
