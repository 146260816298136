import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnInit,
    Optional,
    Output,
    Self,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NgControl } from '@angular/forms';
import { NgClass } from '@angular/common';
import { A11yModule } from '@angular/cdk/a11y';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { fromEvent } from 'rxjs';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { compact, includes } from 'lodash';

import { replaceValueNonDigits } from '../../utils';
import { ConfigApp, ConfigAppToken } from '../../services/config.service';
import { EnvironmentService } from '../../services/environment.service';
import { AbstractControlValidationStatus } from '../../constants';
import {
    formatAsDateString,
    formatAsSecondaryDateString,
} from '../../utils/transform-numeric-value';
import { FiIconVariation } from '../icon/icon.constants';
import { VariationDirective } from '../../directives/variation.directive';
import { IconComponent } from '../icon/icon.component';
import { ButtonComponent } from '../button/button.component';
import { MemoizeFuncPipe } from '../../pipes/memoize-func.pipe';
import { InputFormatDirective } from '../../directives/input-format.directive';

import { InputMaskComponent } from './mask/input-mask.component';

const enum InputSize {
    Regular = 'regular',
    Small = 'small',
}

const enum InputTextAlign {
    Left = 'left',
    Center = 'center',
    Right = 'right',
}

export const enum InputType {
    Email = 'email',
    Number = 'number',
    Password = 'password',
    Search = 'search',
    Tel = 'tel',
    Text = 'text',
}

export const enum InputFormatType {
    Email = 'email',
    OdometerHours = 'odometer-hours',
    OdometerHoursModifier = 'odometer-hours-modifier',
    Phone = 'phone',
    Number = 'number',
    CustomerNumber = 'customer-number',
    SsoId = 'sso-id',
    Date = 'date',
    DateSecondary = 'dateSecondary',
    District = 'district',
}

export const enum InputFormatModifierType {
    Miles = 'Miles',
    Kilometers = 'Kilometers',
    Hours = 'Hours',
}

export const CUSTOMER_NUMBER_LENGTH = 6;
export const SSO_ID_LENGTH = 9;
export const DISTRICT_NUMBER_LENGTH = 4;

const enum InputVariation {
    BoldBorder = 'bold-border',
    Changed = 'changed',
    Disabled = 'disabled',
    Filled = 'filled',
    Inline = 'inline',
    Invalid = 'invalid',
    NoBorder = 'no-border',
    Readonly = 'readonly',
    Search = 'search',
    Text = 'text',
    NoError = 'no-error-border',
    FullHeight = 'full-height',
    DoneButtonPadding = 'done-button-padding',
    IconLeft = 'icon-left',
    Warning = 'warning',
    ChineseColor = 'chinese-color',
}

const enum Key {
    Backspace = 8,
    Enter = 13,
    Tab = 9,
}

@UntilDestroy()
@Component({
    selector: 'fi-input',
    templateUrl: 'input.component.html',
    styleUrls: ['./input.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        FormsModule,
        A11yModule,
        VariationDirective,
        IconComponent,
        ButtonComponent,
        InputMaskComponent,
        InputFormatDirective,
        MemoizeFuncPipe,
    ],
    // todo: investigate how to implement onPush strategy
    //  issue: form patchValue doesn't trigger change detection (refer to D&H form on unit overview section)
})
export class InputComponent
    implements ControlValueAccessor, OnInit, AfterViewInit, OnChanges
{
    @Input() name: string;

    @Input() autocomplete = '';
    @Input() id = '';
    @Input() placeholder = '';
    @Input() size = InputSize.Regular;
    @Input() textAlign = InputTextAlign.Left;
    @Input() tabindex = 0;
    @Input() type = InputType.Text;
    @Input() value = '';

    @Input() changed: boolean;
    @Input() changeIndicator: boolean;
    @Input() disabled: boolean;
    @Input() focusedInitially: boolean;
    @Input() format: InputFormatType;
    @Input() formatModifier: InputFormatModifierType;
    @Input() icon: string;
    @Input() iconVariation: FiIconVariation = FiIconVariation.Light;
    @Input() buttonTitle: string;
    @Input() suffixLabelTitle: string;
    @Input() invalid: boolean;
    @Input() maxlength: number;
    @Input() minlength: number;
    @Input() readonly: boolean;
    @Input() variation: InputVariation;
    @Input() showCounter: boolean;
    @Input() inputmode: string;
    @Input() showRequiredMinlength: boolean;
    @Input() showResetButton: boolean;
    @Input() shouldFocusOnInputIconClick = false;
    @Input() shouldBlurOnReset = true;
    @Input() required: boolean;

    @Output() blur = new EventEmitter<FocusEvent>();
    @Output() focus = new EventEmitter<void>();
    @Output() valueChange = new EventEmitter<string>();
    @Output() buttonClick = new EventEmitter<void>();
    @Output() resetButtonClick = new EventEmitter<void>();
    @Output() iconClick = new EventEmitter<void>();
    @Output() inputClick = new EventEmitter<void>();

    @ViewChild('inputElement')
    private inputElementRef: ElementRef;
    @ViewChild('resetButton')
    private resetButtonElementRef: ElementRef;

    inputElement: HTMLInputElement;

    variationList: string;

    focused = false;

    maskValue = '';

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChange = (_: string) => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onTouched = () => {};

    constructor(
        private environmentService: EnvironmentService,
        private changeDetectorRef: ChangeDetectorRef,
        @Optional() @Self() public ngControl: NgControl,
        @Inject(ConfigAppToken) private CONFIG: ConfigApp,
    ) {
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    ngOnInit(): void {
        if (!this.name) {
            throw new Error(`'name' property is required for 'fi-input'`);
        }

        if (
            this.format === InputFormatType.Email &&
            this.type === InputType.Email
        ) {
            throw new Error(
                `'email' input format can not be used with input type="email" because of selection API limitations`,
            );
        }
    }

    ngAfterViewInit(): void {
        const { environmentService: isIOS } = this;

        this.inputElement = this.inputElementRef.nativeElement;

        this.updateVariationList();

        this.handleInput();
        this.handleInputFocus();

        if (this.ngControl) {
            this.handleInputStatusValidChange();
        }

        if (isIOS && this.type === InputType.Number) {
            this.validateIOSInputNumberKeys();
        }
        if (isIOS) {
            this.handleIOSInputFocus();
        }
    }

    ngOnChanges(): void {
        this.updateVariationList();
    }

    writeValue(value: string): void {
        this.value = value;
    }

    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    handleInputClear(): void {
        this.handleValueChange('');

        this.focusInputElement();
        this.resetButtonClick.emit();
    }

    handleInputBlur(event: FocusEvent): void {
        if (
            !this.shouldBlurOnReset &&
            this.showResetButton &&
            event.relatedTarget === this.resetButtonElementRef.nativeElement
        ) {
            return;
        }

        this.focused = false;
        this.onTouched();
        this.blur.emit(event);
    }

    focusInputElement(): void {
        this.inputElement.focus();
    }

    handleIconClick(): void {
        if (this.shouldFocusOnInputIconClick) {
            this.focusInputElement();
        }
        this.iconClick.emit();
    }

    handleInputClick(): void {
        this.inputClick.emit();
    }

    isMaskVisible([focused, invalid, touched]: [
        boolean,
        boolean,
        boolean,
    ]): boolean {
        if (!this.format) {
            return false;
        }

        return (
            focused || (!focused && this.maskValue.length && invalid && touched)
        );
    }

    handleButtonClick(): void {
        this.buttonClick.emit();
    }

    forceBlur(): void {
        this.inputElement.blur();
    }

    private get isPhoneFormat(): boolean {
        return this.format === InputFormatType.Phone;
    }

    private get isOdometerHoursFormat(): boolean {
        return this.format === InputFormatType.OdometerHours;
    }

    private get isNumberFormat(): boolean {
        return this.format === InputFormatType.Number;
    }

    private get isDateFormat(): boolean {
        return this.format === InputFormatType.Date;
    }

    private get isSecondaryDateFormat(): boolean {
        return this.format === InputFormatType.DateSecondary;
    }

    private get isSearch(): boolean {
        return this.type === InputType.Search;
    }

    private get isText(): boolean {
        return this.type === InputType.Text;
    }

    private get isFilled(): boolean {
        return (
            (this.isSearch || this.isText) &&
            this.value &&
            this.value.length > 0
        );
    }

    private handleValueChange(value: string): void {
        if (this.ngControl) {
            this.writeValue(value);
        }

        this.onChange(value);
        this.valueChange.emit(value);
    }

    private updateVariationList(): void {
        this.variationList = compact([
            this.variation || null,
            this.size || null,
            this.textAlign || null,
            this.readonly ? InputVariation.Readonly : null,
            this.invalid ? InputVariation.Invalid : null,
            this.changed ? InputVariation.Changed : null,
            this.disabled ? InputVariation.Disabled : null,
            this.isSearch ? InputVariation.Search : null,
            this.isText ? InputVariation.Text : null,
            this.isFilled ? InputVariation.Filled : null,
        ]).join();
    }

    private handleInput(): void {
        fromEvent<Event & { target: HTMLInputElement }>(
            this.inputElement,
            'input',
        )
            .pipe(
                map(({ target: { value } }) => value),
                // since verifyControlValue method might replace non digits symbols
                // updateMaskValue should be called before to make sure
                // that mask component gets correct value
                tap((value) => this.updateMaskValue(value)),
                map((value) => this.verifyControlValue(value)),
                tap((value) => this.handleValueChange(value)),
                // in case if isFilled variation changed
                tap(() => this.updateVariationList()),
                untilDestroyed(this),
            )
            .subscribe(() => {
                this.changeDetectorRef.markForCheck();
            });
    }

    private verifyControlValue(value: string): string {
        if (this.isOdometerHoursFormat) {
            return replaceValueNonDigits(
                value,
                this.CONFIG.ODOMETER_HOURS_LENGTH,
            );
        }

        if (this.isNumberFormat) {
            return replaceValueNonDigits(value);
        }

        if (this.isPhoneFormat) {
            return replaceValueNonDigits(
                value,
                this.CONFIG.PHONE_NUMBER_LENGTH,
            );
        }

        if (this.isDateFormat) {
            return formatAsDateString(value);
        }

        if (this.isSecondaryDateFormat) {
            return formatAsSecondaryDateString(value);
        }

        return value;
    }

    private updateMaskValue(value: string): void {
        this.maskValue = value;
    }

    private handleInputFocus(): void {
        fromEvent<Event & { target: HTMLInputElement }>(
            this.inputElement,
            'focus',
        )
            .pipe(
                map(({ target: { value } }) => value),
                tap((value) => this.updateMaskValue(value)),
                tap(() => this.updateFocusState()),
                tap(() => this.verifyControlStatus()),
                withLatestFrom(this.environmentService.isMobileDevice$),
                map(([_, isMobile]) => {
                    if (!isMobile) {
                        this.selectInputContent();
                    }
                }),
                untilDestroyed(this),
            )
            .subscribe(() => {
                this.changeDetectorRef.markForCheck();
            });
    }

    private verifyControlStatus(): void {
        if (this.ngControl && this.ngControl.valid) {
            this.ngControl.control.markAsUntouched();
        }
    }

    private handleInputStatusValidChange(): void {
        this.ngControl.statusChanges
            .pipe(
                filter(
                    (status: AbstractControlValidationStatus) =>
                        status === AbstractControlValidationStatus.Valid,
                ),
                untilDestroyed(this),
            )
            .subscribe(() => this.ngControl.control.markAsUntouched());
    }

    private updateFocusState(): void {
        this.focused = true;
        this.focus.emit();
    }

    // Fix iOS number type non numeric keys input
    private validateIOSInputNumberKeys(): void {
        const allowedKeys = [Key.Backspace, Key.Tab, Key.Enter];

        fromEvent(this.inputElement, 'keydown')
            .pipe(
                filter((e: KeyboardEvent) => !includes(allowedKeys, e.keyCode)),
                map((e: KeyboardEvent) => {
                    if (/[^0-9]/.test(e.key)) {
                        e.preventDefault();
                    }
                }),
                untilDestroyed(this),
            )
            .subscribe();
    }

    // Fix iOS input highlight issue
    private handleIOSInputFocus(): void {
        fromEvent(this.inputElement, 'touchstart')
            .pipe(untilDestroyed(this))
            .subscribe((e) => {
                e.preventDefault();
                this.focusInputElement();
            });

        fromEvent(this.inputElement, 'touchend')
            .pipe(untilDestroyed(this))
            .subscribe((e) => {
                e.preventDefault();
                e.stopPropagation();
            });
    }

    private selectInputContent(): void {
        const { inputElement } = this;

        if (this.environmentService.isSafari) {
            inputElement.setSelectionRange(0, inputElement.value.length);
        } else {
            inputElement.select();
        }
    }
}
