<button
    #customGuidedTourBtn
    [fiTooltip]="(isMobile$ | async) === false && tourGuideToolTip"
    aria-label="Guided Tour"
    class="guided-tour-button fi-text fi-text--medium fi-text--link-with-icon"
>
    <fi-icon
        [size]="'small'"
        [svgName]="'compass'"
        [color]="'dark-blue'"
        [variation]="'regular'"
        class="guided-tour-button__tempo"
    ></fi-icon>

    <span class="guided-tour-button__label">
        {{ (isMobile$ | async) ? 'Tour' : 'Guided Tour' }}
    </span>
</button>

<ng-template #tourGuideToolTip>
    <span>Tour highlighting features of this page</span>
</ng-template>
