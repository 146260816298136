import {
    Component,
    ViewEncapsulation,
    ElementRef,
    AfterViewInit,
    inject,
} from '@angular/core';
import { AsyncPipe } from '@angular/common';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { fromEvent } from 'rxjs';
import { throttleTime, tap, debounceTime } from 'rxjs/operators';

import { EnvironmentService } from '../../../../services/environment.service';

@UntilDestroy()
@Component({
    selector: 'fi-modal-content',
    templateUrl: './modal-content.component.html',
    styleUrls: ['./modal-content.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [AsyncPipe],
})
export class ModalContentComponent implements AfterViewInit {
    private elementRef = inject(ElementRef);
    private environmentService = inject(EnvironmentService);

    readonly isMobile$ = this.environmentService.isMobile$;

    ngAfterViewInit(): void {
        const element = this.elementRef.nativeElement;
        const scrollingState = 'fi-modal-content--scrolling';

        fromEvent<Event>(element, 'scroll')
            .pipe(
                throttleTime(100),
                tap(() => element.classList.add(scrollingState)),
                debounceTime(200),
                tap(() => element.classList.remove(scrollingState)),
                untilDestroyed(this),
            )
            .subscribe();
    }
}
