import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    Output,
    inject,
} from '@angular/core';
import { FocusableOption } from '@angular/cdk/a11y';

import { EnvironmentService } from '../../../services';
import { IconComponent } from '../../../components';

@Component({
    selector: 'fi-time-picker-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        IconComponent,
    ]
})
export class TimePickerInputComponent implements FocusableOption {
    private readonly elementRef = inject(ElementRef);
    private readonly environmentService = inject(EnvironmentService);

    @Input() value: string;
    @Input() size: string;

    @Output() increase = new EventEmitter<void>();
    @Output() decrease = new EventEmitter<void>();

    @HostBinding('tabindex') tabIndex = -1;

    @HostListener('keydown.arrowup')
    onArrowUpKeydown(): void {
        this.handleIncrease();
    }

    @HostListener('keydown.arrowdown')
    onArrowDownKeydown(): void {
        this.handleDecrease();
    }

    @HostListener('click')
    handleIEFocusState(): void {
        if (
            this.environmentService.isInternetExplorer ||
            this.environmentService.isMSEdge
        ) {
            this.focus();
        }
    }

    handleDecrease(): void {
        this.decrease.emit();
    }

    handleIncrease(): void {
        this.increase.emit();
    }

    focus(): void {
        this.elementRef.nativeElement.focus();
    }
}
