import {
    ChangeDetectionStrategy,
    Component,
    Input,
    TemplateRef,
} from '@angular/core';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';

import { GridOnTabletService } from '../services/grid-on-tablet.service';
import { GridRow } from '../models';
import { VariationDirective } from '../../../directives';

import { GridHeaderCellDefDirective } from './header-cell-def.directive';

@Component({
    selector: '[fi-grid-header-cell]',
    templateUrl: './header-cell.component.html',
    styleUrls: ['./header-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [AsyncPipe, NgTemplateOutlet, VariationDirective],
})
export class GridHeaderCellComponent {
    @Input() tpl: TemplateRef<GridHeaderCellDefDirective>;
    @Input() reversed: boolean;
    @Input() centered: boolean;
    @Input() index: number;
    @Input() row: GridRow;

    readonly isMobileView$ = this.gridOnTabletService.isMobileView$;
    readonly isDesktopView$ = this.gridOnTabletService.isDesktopView$;

    constructor(private gridOnTabletService: GridOnTabletService) {}
}
