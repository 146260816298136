<ng-select
    [items]="items"
    [(ngModel)]="value"
    [searchable]="searchable"
    [notFoundText]="notFoundText"
    [placeholder]="placeholder"
    [clearable]="clearable"
    [multiple]="multiple"
    [required]="required"
    [bindLabel]="bindLabel"
    [bindValue]="bindValue"
    [closeOnSelect]="closeOnSelect"
    [disabled]="disabled"
    (change)="onValueChange($event)"
    [ngClass]="{ 'select-dropdown--invalid': invalid }"
    class="select-dropdown"
>
    @if (labelTemplate) {
        <ng-template
            ng-label-tmp
            [ngTemplateOutlet]="labelTemplate"
        ></ng-template>
    }

    @if (!labelTemplate; as item) {
        <ng-template ng-label-tmp let-item="item">
            <span class="fi-text fi-text--medium select-dropdown__label">
                <span
                    [fiTooltip]="optionTooltipTpl"
                    [fiTooltipPosition]="optionTooltipPosition"
                    [fiTooltipContext]="{ option: item[bindLabel] }"
                    [fiNeedToCheckOverflow]="true"
                    class="select-dropdown__label-value"
                >
                    {{ item[bindLabel] }}
                </span>
                @if (item.tooltip) {
                    <fi-icon
                        [svgName]="'info'"
                        [variation]="'solid'"
                        [size]="'10x'"
                        [color]="'base-grey'"
                        [fiTooltip]="iconTooltipTpl"
                        [fiTooltipPosition]="iconTooltipPosition"
                        [fiTooltipContext]="{
                            tooltip: item.tooltip,
                        }"
                        class="select-dropdown__label-info-icon"
                    ></fi-icon>
                }
            </span>
        </ng-template>
    }

    @if (optionTemplate) {
        <ng-template
            ng-option-tmp
            [ngTemplateOutlet]="optionTemplate"
        ></ng-template>
    }

    <!-- todo -->
    <ng-template
        ng-option-tmp
        let-item="item"
        let-item$="item$"
        let-search="searchTerm"
    >
        @if (!optionTemplate) {
            <span class="fi-text fi-text--medium select-dropdown__option">
                @if (multiple) {
                    <fi-checkbox
                        [checked]="item$.selected"
                        [variation]="'select-option-content'"
                        class="select-dropdown__checkbox"
                    ></fi-checkbox>
                }
                    <span
                        [fiTooltip]="optionTooltipTpl"
                        [fiTooltipPosition]="optionTooltipPosition"
                        [fiTooltipContext]="{ option: item[bindLabel] }"
                        [fiNeedToCheckOverflow]="true"
                        class="select-dropdown__option-value"
                        [innerHTML]="item[bindLabel] | highlightMatch:search"
                        >
                    </span>
                @if (item.tooltip) {
                    <fi-icon
                        [svgName]="'info'"
                        [variation]="'solid'"
                        [size]="'10x'"
                        [color]="'base-grey'"
                        [fiTooltip]="iconTooltipTpl"
                        [fiTooltipPosition]="iconTooltipPosition"
                        [fiTooltipContext]="{
                            tooltip: item.tooltip,
                        }"
                        class="select-dropdown__option-info-icon"
                    ></fi-icon>
                }
            </span>
        }
    </ng-template>
</ng-select>

<ng-template #iconTooltipTpl let-tooltip="tooltip">
    <span>{{ tooltip }}</span>
</ng-template>

<ng-template #optionTooltipTpl let-option="option">
    <span>{{ option }}</span>
</ng-template>
