import { NgModule, Type } from '@angular/core';

import { LocationMapComponent } from './location-map.component';

const FEATURES: Array<Type<any> | any[]> = [LocationMapComponent];

@NgModule({
    imports: [...FEATURES],
    exports: FEATURES,
})
export class LocationMapModule {}
