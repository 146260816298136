import { NgModule, Type } from '@angular/core';

import { TimeComponent } from './time.component';
import { TimePickerFormComponent } from './form/form.component';
import { TimePickerInputComponent } from './input/input.component';
import { TimePickerComponent } from './picker/picker.component';

const FEATURES: Array<Type<any> | any[]> = [TimeComponent];

const ISOLATED_FEATURES: Array<Type<any> | any[]> = [
    TimePickerFormComponent,
    TimePickerInputComponent,
    TimePickerComponent,
];

@NgModule({
    imports: [...FEATURES, ...ISOLATED_FEATURES],
    exports: FEATURES,
})
export class TimeModule {}
