@let isMobileDevice = isMobileDevice$ | async;

<div class="fi-time-picker-field">
    @if (!listView) {
        <div
            [class.fi-time-picker-field__input-wrapper--disabled]="disabled"
            (mousedown)="show($event)"
            role="button"
            class="fi-time-picker-field__input-wrapper"
        >
        <span
            [fiVariation]="[disabled ? 'light' : 'dark']"
            [fiVariationBase]="'fi-text'"
            class="fi-time-picker-field__value"
        >
            {{ value | timeFormat: 'AMPM' : timeStringFullFormat }}
        </span>

            <input class="fi-time-picker-field__input" />
        </div>
    }
</div>

@if (isMobileDevice === false) {
    <fi-time-picker
        [(open)]="isPickerOpen"
    >
        <ng-template>
            <div class="fi-time-picker__content fi-time-picker__content--desktop">
                <fi-time-picker-form
                    [value]="time"
                    [size]="'medium'"
                    [hasAdjustedMinutes]="hasAdjustedMinutes"
                    (valueChange)="updateValue($event)"
                ></fi-time-picker-form>
            </div>
        </ng-template>
    </fi-time-picker>
}

<fi-modal
    [open]="isPickerOpen && isMobileDevice"
    [animation]="modalAnimation"
    [position]="modalPosition"
    (event)="handleModalEvent($event)"
>
    @if (isPickerOpen && isMobileDevice) {
        @if (title) {
            <fi-modal-header
                [noCloseButton]="noCloseButton"
                [variation]="'regular'"
                (close)="hide()"
                class="fi-time-picker__header"
            >
                <span class="fi-text fi-text--dark fi-text--medium">
                    {{ title }}
                </span>
            </fi-modal-header>
        }

        <fi-modal-content class="fi-time-picker__content">
            <fi-time-picker-form
                [(value)]="bufferTime"
                [size]="'xxx-large'"
            ></fi-time-picker-form>
        </fi-modal-content>

        <fi-modal-footer>
            <div class="slot">
                <div class="slot__inner slot__inner--spanned">
                    <fi-button [ariaLabel]="'Cancel'" [variation]="'secondary,block'" (click)="hide()">
                        Cancel
                    </fi-button>
                </div>

                <div class="slot__inner slot__inner--spanned">
                    <fi-button
                        [ariaLabel]="submitButton"
                        [variation]="'primary,block'"
                        (click)="handleApply()"
                    >
                        {{ submitButton }}
                    </fi-button>
                </div>
            </div>
        </fi-modal-footer>
    }
</fi-modal>
