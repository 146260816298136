<div
    [fiVariation]="[
        focused && 'focused',
        disabled && 'disabled',
        withSeparator && 'with-separator',
        withDistinctSeparator && 'with-distinct-separator',
        noAction && 'no-action',
    ]"
    [fiVariationBase]="'fi-context-menu__item'"
>
    @if (disabled) {
        <span
            [fiTooltip]="tooltip && tooltipTpl"
            [fiTooltipPosition]="tooltipPosition"
            class="fi-context-menu__item--disabled-option"
        >
            @if (icon) {
                <span class="fi-context-menu__item-icon-container">
                    <fi-icon
                        [fiVariationBase]="'fi-context-menu__item-icon'"
                        [fiVariation]="iconType"
                        [svgName]="icon"
                        [variation]="iconVariation"
                        [size]="iconSize"
                    ></fi-icon>
                </span>
            }
            <span class="fi-text fi-text--medium fi-text--lighter">
                <ng-container *ngTemplateOutlet="content"></ng-container>
            </span>
        </span>
    }

    @if (noAction && !disabled) {
        <div class="fi-context-menu__item-container">
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </div>
    }

    @if (!disabled && !noAction) {
        <fi-button
            [variation]="'secondary-link,menu-option,block'"
            [fiTooltip]="tooltip && tooltipTpl"
            [fiTooltipPosition]="tooltipPosition"
            class="fi-context-menu__item-button"
        >
            @if (icon) {
                <span class="fi-context-menu__item-icon-container">
                    <fi-icon
                        [fiVariationBase]="'fi-context-menu__item-icon'"
                        [fiVariation]="iconType"
                        [variation]="iconVariation"
                        [size]="iconSize"
                        [svgName]="icon"
                    ></fi-icon>
                </span>
            }
            <div class="fi-context-menu__item-content">
                <ng-container *ngTemplateOutlet="content"></ng-container>
            </div>
        </fi-button>
    }

    @if (withDistinctSeparator) {
        <div class="fi-context-menu__item-separator"></div>
    }
</div>

<ng-template #content>
    <ng-content></ng-content>
</ng-template>

<ng-template #tooltipTpl>
    <span>{{ tooltip }}</span>
</ng-template>
