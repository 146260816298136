import {
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    Output,
    TemplateRef,
    ViewContainerRef,
    inject,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { filter, take } from 'rxjs/operators';

import {
    OverlayConnectedPosition,
    OverlayContainerRef,
    OverlayEvent,
    OverlayEventType,
    OverlayScrollStrategy,
    OverlayService,
} from '../../../feature/overlay';
import { OnChange } from '../../../decorators';

@UntilDestroy()
@Component({
    selector: 'fi-time-picker',
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class TimePickerComponent implements OnDestroy {
    private readonly elementRef = inject(ElementRef);
    private readonly overlayService = inject(OverlayService);
    private readonly viewContainerRef = inject(ViewContainerRef);

    @OnChange(function (this: TimePickerComponent) {
        this.toggleOpenWindow();
    })
    @Input()
    open = false;

    @Output() openChange = new EventEmitter<boolean>();

    @ContentChild(TemplateRef) pickerTpl: TemplateRef<any>;

    private overlayContainerRef: OverlayContainerRef<TemplateRef<any>> | null;

    @HostListener('document:keydown.escape')
    handleEscapeKeydown(): void {
        this.close();
    }

    ngOnDestroy(): void {
        this.close();
    }

    private show(): void {
        if (this.overlayContainerRef) {
            return;
        }

        this.overlayContainerRef = this.overlayService.create(
            this.pickerTpl,
            this.viewContainerRef,
            {
                variation: '',
                positions: [OverlayConnectedPosition.BottomLeft],
                scrollStrategy: OverlayScrollStrategy.Close,
            },
            null,
            this.elementRef.nativeElement,
        );

        if (!this.overlayContainerRef) {
            return;
        }

        this.overlayContainerRef.open();
        this.attachOverlayListener();
    }

    private close(): void {
        if (!this.overlayContainerRef) {
            return;
        }

        this.overlayContainerRef.close();
        this.overlayContainerRef = null;
    }

    private toggleOpenWindow(): void {
        if (this.open) {
            this.show();
            return;
        }

        this.close();
    }

    private attachOverlayListener(): void {
        this.overlayContainerRef.event$
            .pipe(
                filter(({ type }: OverlayEvent) =>
                    [
                        OverlayEventType.Detach,
                        OverlayEventType.OutsideClick,
                    ].includes(type),
                ),
                take(1),
                untilDestroyed(this),
            )
            .subscribe(() => {
                this.openChange.emit(false);
            });
    }
}
