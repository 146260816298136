function arrayMoveMutate<T>(array: T[], from: number, to: number) {
    const startIndex = to < 0 ? array.length + to : to;

    if (startIndex >= 0 && startIndex < array.length) {
        const item = array.splice(from, 1)[0];
        array.splice(startIndex, 0, item);
    }
}

export function arrayMove<T = any>(array: T[], from: number, to: number): T[] {
    array = [...array];
    arrayMoveMutate<T>(array, from, to);
    return array;
}
