import { Pipe, PipeTransform } from '@angular/core';

import { map, groupBy } from 'lodash';

import { GridGroupDefDirective } from '../group/group-def.directive';
import { GridColumnDefDirective } from '../column/column-def.directive';

@Pipe({
    name: 'gridGroupRows',
    standalone: true,
})
export class GridGroupRowsPipe implements PipeTransform {
    transform(
        items: any[],
        groupByDef: GridGroupDefDirective,
        columns: GridColumnDefDirective[] | null,
    ): any[][] {
        if (!columns || !columns.length) {
            return [];
        }

        const groupKey = groupByDef && groupByDef.groupBy;

        if (!groupKey) {
            return items.map((item) => [item]);
        }

        const groupedRows = groupBy(items, (item) => {
            return item[groupKey];
        });

        return map(groupedRows, (items) => {
            return items;
        });
    }
}
