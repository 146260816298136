@let isFromValid = dateRange?.from?.isValid();

<div
    [class.fi-date-range-control--activated]="isFromValid"
    class="fi-date-range-control"
>
    <div class="fi-date-range-control__input-wrapper">
        <input
            [ngClass]="
                isFromValid ? 'fi-text--dark' : 'fi-text--locked'
            "
            [placeholder]="fromTitle"
            [value]="dateRange?.from?.format(dateFormat) || fromTitle"
            (mousedown)="openModal()"
            class="fi-date-range-control__input fi-text"
            readonly
        />

        <div class="fi-date-range-control__icons-group">
            @if (isFromValid) {
                <div
                    (click)="onTimesIconClicked(dateRangeSelectionMode.From)"
                    role="button"
                    aria-label="Clear date"
                    class="fi-date-range-control__times-icon"
                >
                    <fi-icon
                        [svgName]="'times'"
                        [size]="'small'"
                        [color]="'expandable-type'"
                    ></fi-icon>
                </div>
            }

            <div
                [class.fi-date-range-control__icon--activated]="isFromValid"
                (mousedown)="openModal()"
                role="button"
                class="fi-date-range-control__icon"
            >
                <fi-icon
                    [svgName]="'calendar-alt'"
                    [size]="'large'"
                    [color]="isFromValid ? 'white' : 'expandable-type'"
                ></fi-icon>
            </div>
        </div>
    </div>
</div>

@if (!singleDateMode) {
    @let isToValid = dateRange?.to?.isValid();

    <span class="fi-date-range-control__to-title fi-text fi-text--small">
        to
    </span>

    <div
        [class.fi-date-range-control--activated]="isToValid"
        class="fi-date-range-control"
    >
        <div class="fi-date-range-control__input-wrapper">
            <input
                [ngClass]="
                    isToValid
                        ? 'fi-text--dark'
                        : 'fi-text--locked'
                "
                [placeholder]="toTitle"
                [value]="dateRange?.to?.format(dateFormat) || toTitle"
                (mousedown)="openModal()"
                class="fi-date-range-control__input fi-text"
                readonly
            />

            <div class="fi-date-range-control__icons-group">
                @if (isToValid) {
                    <div
                        (click)="onTimesIconClicked(dateRangeSelectionMode.To)"
                        aria-label="Clear date"
                        class="fi-date-range-control__times-icon"
                    >
                        <fi-icon
                            [svgName]="'times'"
                            [size]="'small'"
                            [color]="'expandable-type'"
                        ></fi-icon>
                    </div>
                }

                <div
                    [class.fi-date-range-control__icon--activated]="isToValid"
                    (mousedown)="openModal()"
                    role="button"
                    aria-label="Open modal"
                    class="fi-date-range-control__icon"
                >
                    <fi-icon
                        [svgName]="'calendar-alt'"
                        [size]="'large'"
                        [color]="isToValid ? 'white' : 'expandable-type'"
                    ></fi-icon>
                </div>
            </div>
        </div>
    </div>
}

@if ((isTabletOrMobile$ | async) === false) {
    <fi-date-range-modal
        [(open)]="isModalOpen"
        [dateRange]="dateRange"
        [availableDateRange]="availableDateRange"
        [maxDatesInRange]="maxDatesInRange"
        [disabledDaysOfWeek]="disabledDaysOfWeek"
        [singleDateMode]="singleDateMode"
        [oneDateSelectionAvailable]="oneDateSelectionAvailable"
        [currentMonthPosition]="currentMonthPosition"
        [fromTitle]="fromTitle"
        [toTitle]="toTitle"
        [toDateMessageInfo]="toDateMessageInfo"
        [modalTitle]="modalTitle"
        (dateRangeSelected)="onDateRangeSelected($event)"
    ></fi-date-range-modal>
}

@if (isTabletOrMobile$ | async) {
    <fi-date-range-custom
        [modalTitle]="modalTitle"
        [selected]="dateRange"
        [availableDateRange]="availableDateRange"
        [(open)]="isModalOpen"
        [showDatesInfo]="false"
        [confirmButtonTitle]="'Select Dates'"
        [showCancelButton]="true"
        [fromTitle]="fromTitle"
        [toTitle]="toTitle"
        [singleDaySelectionDisabled]="!singleDateMode"
        [singleDaySelectionMessage]="toDateMessageInfo"
        (select)="onDateRangeSelected($event)"
    ></fi-date-range-custom>
}
