import { Injectable } from '@angular/core';

interface RouteStyleOptions {
    strokeColor?: string;
    strokeWeight?: number;
    strokeOpacity?: number;
}

interface DirectionsRendererOptions {
    polylineOptions: RouteStyleOptions;
}

interface PolylineOptions extends RouteStyleOptions {
    map: google.maps.Map;
    path: google.maps.LatLng[];
}

interface Waypoints {
    location: google.maps.LatLngLiteral;
    stopover: boolean;
}

export interface DrawPathOptions extends RouteStyleOptions {
    map: google.maps.Map;
    origin: google.maps.LatLngLiteral;
    destination: google.maps.LatLngLiteral;
    waypoints?: Waypoints[];
    travelMode?: google.maps.TravelMode;
}

const DEFAULT_TRAVEL_MODE = window.google?.maps.TravelMode.DRIVING;
const DEFAULT_STROKE_COLOR = '#0073cf';
const DEFAULT_STROKE_WEIGHT = 2;

@Injectable({
    providedIn: 'root',
})
export class DirectionMapService {
    private readonly directionsService = new google.maps.DirectionsService();

    private renderers: (
        | google.maps.DirectionsRenderer
        | google.maps.Polyline
    )[] = [];

    drawDirection({
        map,
        origin,
        destination,
        waypoints = [],
        travelMode = DEFAULT_TRAVEL_MODE,
    }: DrawPathOptions): void {
        const directionsRenderer = this.createDirectionsRenderer();

        this.setPathToMap(directionsRenderer, map);
        this.setRenderers(directionsRenderer);

        this.directionsService.route(
            {
                origin,
                destination,
                waypoints,
                travelMode,
            },
            (response, status) => {
                if (status === google.maps.DirectionsStatus.OK) {
                    directionsRenderer.setDirections(response);
                }
            },
        );
    }

    drawPolyline({
        map,
        origin,
        destination,
        waypoints = [],
        travelMode = DEFAULT_TRAVEL_MODE,
        strokeColor = DEFAULT_STROKE_COLOR,
        strokeWeight = DEFAULT_STROKE_WEIGHT,
    }: DrawPathOptions): void {
        const polyline = this.createPolyline();

        this.setPathToMap(polyline, map);
        this.setRenderers(polyline);

        this.directionsService.route(
            {
                origin,
                destination,
                waypoints,
                travelMode,
            },
            (response, status) => {
                if (status === google.maps.DirectionsStatus.OK) {
                    polyline.setOptions({
                        path: response.routes[0].overview_path,
                        strokeColor,
                        strokeWeight,
                    });
                }
            },
        );
    }

    removeRenderers(): void {
        this.renderers.forEach((renderer) => this.setPathToMap(renderer, null));
    }

    private setPathToMap(
        renderer: google.maps.DirectionsRenderer | google.maps.Polyline,
        map: google.maps.Map | null,
    ): void {
        renderer.setMap(map);
    }

    private createDirectionsRenderer(
        options: DirectionsRendererOptions = null,
    ): google.maps.DirectionsRenderer {
        return new google.maps.DirectionsRenderer(options);
    }

    private createPolyline(
        options: PolylineOptions = null,
    ): google.maps.Polyline {
        return new google.maps.Polyline(options);
    }

    private setRenderers(
        rendered: google.maps.DirectionsRenderer | google.maps.Polyline,
    ): void {
        this.renderers.push(rendered);
    }
}
