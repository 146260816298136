import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    QueryList,
    TemplateRef,
    ViewChildren,
    inject,
} from '@angular/core';
import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { isArrayLike, isNil } from 'lodash';

import { EnvironmentService } from '../../services';
import { SelectOption } from '../../models';
import { VariationDirective } from '../../directives';
import { MemoizeFuncPipe } from '../../pipes';
import { AlertIconComponent } from '../alert-icon/alert-icon.component';
import { RadioComponent } from '../radio/radio.component';
import { LabelComponent } from '..//label/label.component';
import { IconComponent } from '../icon/icon.component';
import { HighlightTextComponent } from '../highlight-text/highlight-text.component';
import { CheckboxComponent } from '../checkbox/checkbox.component';

export interface SelectableListItem<T = any, K = { [key: string]: any }>
    extends SelectOption {
    data?: K;
    hasAlertIcon?: boolean;
    icon?: string;
    info?: string;
    indicator?: string;
}

export const LIST_ITEM_ID_PREFIX = 'selectable-list-item-';

@Component({
    selector: 'fi-selectable-list',
    templateUrl: './selectable-list.component.html',
    styleUrls: ['./selectable-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgTemplateOutlet,
        NgClass,
        AsyncPipe,
        LabelComponent,
        HighlightTextComponent,
        VariationDirective,
        IconComponent,
        AlertIconComponent,
        MemoizeFuncPipe,
        RadioComponent,
        CheckboxComponent,
        ScrollingModule,
    ],
})
export class SelectableListComponent {
    private readonly environmentService = inject(EnvironmentService);

    @Input() items: SelectableListItem[];
    @Input() multiple: boolean;
    @Input() selected: SelectableListItem[] = [];
    @Input() variation: string;
    @Input() type: string;
    @Input() withCheck = false;
    @Input() codeShownAsSecondary = false;
    @Input() labelShownAsSecondary = false;
    @Input() templateShownAsSecondary: TemplateRef<any>;
    @Input() labelShowInfo: boolean;
    @Input() withHighlight = false;
    @Input() highlightValue: string;
    @Input() virtualScroll = true;
    @Input() hideSelection = false;
    @Input() withoutSelection = false;

    @Output() selectItems = new EventEmitter<SelectableListItem[]>();

    @ViewChildren('groupItem', { read: ElementRef })
    groupItemsRefList: QueryList<ElementRef>;

    readonly isMobile$ = this.environmentService.isMobile$;

    private get selectedList(): SelectableListItem[] {
        const selected = this.selected;

        if (!selected) {
            return [];
        }

        return isArrayLike(selected) ? selected : [selected];
    }

    getLabelVariation(item: SelectableListItem): string {
        if (item.disabled) {
            return 'large,disabled,medium';
        }

        const isSelected = this.withCheck && this.isItemSelected([item]);
        const withLabel =
            this.labelShownAsSecondary && !isSelected ? ',dark' : '';
        const hasAccess = this.hasAccess(item) ? '' : ',disabled';

        return (
            'medium,large,clickable' +
            withLabel +
            hasAccess +
            (isSelected && this.labelShowInfo ? ',dark' : '') +
            (isSelected && !this.labelShowInfo ? ',dark-blue' : '')
        );
    }

    handleClick(item: SelectableListItem): void {
        this.toggleItemSelection(item);
        this.selectItems.emit(this.selected);
    }

    isItemSelected([item]: [
        SelectableListItem,
        SelectableListItem[]?,
    ]): boolean {
        return !!this.selectedList.find((search) => item.code === search.code);
    }

    getItemId(item: SelectableListItem): string {
        return `${LIST_ITEM_ID_PREFIX}${item.code}`;
    }

    hasAccess(item: SelectableListItem): boolean {
        if (
            item.data &&
            item.data.hasOwnProperty('hasAccess') &&
            !isNil(item.data.hasAccess)
        ) {
            return item.data.hasAccess;
        }

        return true;
    }

    private toggleItemSelection(item: SelectableListItem): void {
        const isItemSelected = this.isItemSelected([item]);

        if (this.multiple) {
            this.toggleMultipleSelection(item, isItemSelected);
        } else {
            this.toggleSingleSelection(item, isItemSelected);
        }
    }

    private toggleSingleSelection(
        item: SelectableListItem,
        isItemSelected: boolean,
    ): void {
        this.selected = isItemSelected ? [] : [item];
    }

    private toggleMultipleSelection(
        item: SelectableListItem,
        isItemSelected: boolean,
    ): void {
        if (isItemSelected) {
            this.selected = this.selectedList.filter(
                (search) => item.code !== search.code,
            );
        } else {
            this.selected = [...this.selected, item];
        }
    }
}
