import { NgModule, Type } from '@angular/core';

import { PieComponent } from './pie/pie.component';
import { NoChartDataComponent } from './no-chart-data/no-chart-data.component';

const FEATURES: Array<Type<any> | any[]> = [
    PieComponent,
    NoChartDataComponent,
];

@NgModule({
    imports: [...FEATURES],
    exports: FEATURES,
})
export class ChartsModule {
}
