import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
    inject,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ButtonComponent } from '../../../components';
import {
    ModalAnimationType,
    ModalVariation,
    ModalComponent,
    ModalCloseBarComponent,
    ModalContentComponent,
    ModalFooterComponent,
} from '../../modal';
import { FilteringDirective } from '../filtering.directive';
import { AppliedFilter } from '../models';
import {
    FilterItemsVariations,
    FilteringListComponent,
} from '../list/list.component';
import { FilteringOpenerComponent } from '../opener/opener.component';

@UntilDestroy()
@Component({
    selector: 'fi-filtering-picker',
    templateUrl: './picker.component.html',
    styleUrls: ['./picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        FilteringOpenerComponent,
        FilteringListComponent,
        ModalComponent,
        ModalCloseBarComponent,
        ModalContentComponent,
        ModalFooterComponent,
        ButtonComponent,
    ],
})
export class FilteringPickerComponent implements OnInit, OnDestroy {
    private changeDetectorRef = inject(ChangeDetectorRef);
    private filtering = inject(FilteringDirective, { optional: true });

    @Input() noOpener = false;
    @Input() desktopViewOnly = false;
    @Input() resetLabel = 'Reset';
    @Input() customCountCalculate: (filters: AppliedFilter[]) => number;
    @Input() listVariation: FilterItemsVariations = 'mobile';
    @Input() modalVariation = [ModalVariation.FullHeight];
    @Input() modalAnimation = ModalAnimationType.SlideLeft;
    @Input() hasCustomSmallTabletAnimation = false;

    @Output() resetFilters = new EventEmitter<void>();
    @Output() modalOpened = new EventEmitter<void>();

    @ViewChild('filtersMobileTpl', { read: TemplateRef })
    filtersMobileTpl: TemplateRef<any>;

    isModalOpen = false;
    appliedFilters: AppliedFilter[] = [];

    ngOnInit(): void {
        if (!this.filtering) {
            return;
        }

        this.filtering.stateChanged$
            .pipe(untilDestroyed(this))
            .subscribe(() => this.updateState());

        this.updateState();
    }

    ngOnDestroy(): void {
        this.isModalOpen = false;
    }

    handleCancelFilters(): void {
        this.filtering.cancelFilters();
    }

    handleApplyFilters(): void {
        this.filtering.applyFilters();
    }

    handleResetFilters(): void {
        this.resetFilters.emit();
        this.filtering.resetFilters();
    }

    private updateState(): void {
        this.isModalOpen = this.filtering.isFilterShow;

        if (this.isModalOpen) {
            this.modalOpened.emit();
        }

        this.changeDetectorRef.markForCheck();
    }
}
