import {
    Directive,
    HostBinding,
    HostListener,
    Input,
    OnInit,
    Optional,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { find } from 'lodash';

import { SortingOrder } from '../../../models';

import { GridSortDirective } from './sort.directive';

@UntilDestroy()
@Directive({
    selector: '[fiGridSortColumn]',
    exportAs: 'fiGridSortColumn',
    standalone: true,
})
export class GridColumnSortDirective implements OnInit {
    @Input('fiGridSortColumn') fieldKey: string;

    @HostBinding('class.fi-grid-sort--active') isActive = false;

    order: SortingOrder;

    constructor(@Optional() private sort: GridSortDirective) {}

    @HostListener('click')
    handleClick(): void {
        if (!this.fieldKey) {
            return;
        }

        this.sort.sort(this.fieldKey);
    }

    ngOnInit(): void {
        if (!this.fieldKey || !this.sort) {
            return;
        }

        this.attachSortChangeListener();
        this.updateSortData();
    }

    private attachSortChangeListener(): void {
        this.sort.stateChanged
            .asObservable()
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.updateSortData();
            });
    }

    private updateSortData(): void {
        const { isActive = false, order = SortingOrder.Asc } =
            find(this.sort.sorting, { key: this.fieldKey }) || {};

        this.isActive = isActive;
        this.order = order;
    }
}
