import { NgModule, Type } from '@angular/core';

import { DragNDropAreaComponent } from './drag-n-drop-area.component';
import { DragNDropUploadErrorComponent } from './drag-n-drop-upload-error/drag-n-drop-upload-error.component';
import { UploadStepComponent } from './upload-step/upload-step.component';
import { FilesUploadComponent } from './files-upload/files-upload.component';
import { DeleteUploadedFileModalComponent } from './files-upload/delete-uploaded-file-modal/delete-uploaded-file-modal.component';

const FEATURES: Array<Type<any> | any[]> = [
    DragNDropAreaComponent,
    DragNDropUploadErrorComponent,
    UploadStepComponent,
    FilesUploadComponent,
    DeleteUploadedFileModalComponent,
];

@NgModule({
    imports: [...FEATURES],
    exports: FEATURES,
})
export class DragNDropAreaModule {}
