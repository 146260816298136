import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OverlayModule } from '@angular/cdk/overlay';

import { UiCommonModule } from '../../ui-common.module';

import { OverlayService } from './overlay.service';

@NgModule({
    imports: [CommonModule, OverlayModule, UiCommonModule],
    providers: [OverlayService],
})
export class OverlayModuleOwn {}
