import { MemoizeFuncPipe } from './memoize-func.pipe';
import { PhoneFormatPipe } from './phone-format.pipe';
import { TimeFormatPipe } from './time-format.pipe';
import { TzFormatPipe } from './tz-format.pipe';
import { TzShortPipe } from './tz-short.pipe';
import { TimeAsIsPipe } from './time-as-is.pipe';
import { EmptyFallbackPipe } from './empty-fallback.pipe';
import { FilterListPipe } from './filter-list.pipe';
import { ArrayToStringPipe } from './array-to-string.pipe';
import { PluralizeStringPipe } from './pluralize-string.pipe';
import { PluralizePipe } from './pluralize.pipe';
import { TruncateTextPipe } from './truncate-text.pipe';
import { ToNumArray } from './to-num-array';
import { OrdinalPipe } from './ordinal.pipe';
import { MinutesToHoursPipe } from './minutes-to-hours.pipe';
import { AddressFormatPipe } from './address-format.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { CamelCasePipe } from './camel-case.pipe';
import { AddingSymbolPipe } from './adding-symbol.pipe';

export {
    MemoizeFuncPipe,
    PhoneFormatPipe,
    TimeFormatPipe,
    TzFormatPipe,
    TzShortPipe,
    TimeAsIsPipe,
    EmptyFallbackPipe,
    FilterListPipe,
    ArrayToStringPipe,
    PluralizeStringPipe,
    PluralizePipe,
    TruncateTextPipe,
    OrdinalPipe,
    MinutesToHoursPipe,
    AddressFormatPipe,
    SafeUrlPipe,
    CamelCasePipe,
    ToNumArray,
    AddingSymbolPipe,
};

export const standalonePipes = [
    EmptyFallbackPipe,
    MemoizeFuncPipe,
    PhoneFormatPipe,
    TimeFormatPipe,
    ToNumArray,
    PluralizeStringPipe,
    MinutesToHoursPipe,
    ArrayToStringPipe,
    TruncateTextPipe,
    CamelCasePipe,
    PluralizePipe,
    AddingSymbolPipe,
    AddressFormatPipe,
    OrdinalPipe,
    SafeUrlPipe,
    FilterListPipe,
    TimeAsIsPipe,
    TzFormatPipe,
    TzShortPipe,
];
