import { Observable, Subscriber } from 'rxjs';

export function fromPromiseWithUnsubscribe<T>(
    promise: Promise<T>,
): Observable<T> {
    return new Observable((observer: Subscriber<T>) => {
        promise
            .then((data) => {
                observer.next(data);
                observer.complete();
            })
            .catch((err) => {
                observer.error(err);
            });
    });
}
