import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    inject,
} from '@angular/core';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { range, uniq } from 'lodash';

import { EnvironmentService } from '../../services';
import { ButtonComponent } from '../button/button.component';
import { IconComponent } from '../icon/icon.component';
import { GoToPageComponent } from '../go-to-page/go-to-page.component';

type Ellipsis = '...';
type PaginationList = Array<number | Ellipsis>;

const TABLET_SIMPLE_MODE_THRESHOLD = 7;
const DESKTOP_SIMPLE_MODE_THRESHOLD = 5;

@UntilDestroy()
@Component({
    selector: 'fi-paginator',
    templateUrl: './paginator.component.html',
    styleUrls: ['./paginator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AsyncPipe,
        NgTemplateOutlet,
        ButtonComponent,
        IconComponent,
        GoToPageComponent,
    ],
})
export class PaginatorComponent implements OnChanges, OnInit {
    private readonly environmentService = inject(EnvironmentService);

    @Input() currentPage: number;
    @Input() perPage = 10;
    @Input() totalItems = 0;
    @Input() simpleModeThreshold;

    @Output() selectPage = new EventEmitter<number>();

    readonly isDesktop$ = this.environmentService.isDesktop$;
    readonly isTablet$ = this.environmentService.isTablet$;
    readonly ELLIPSIS: Ellipsis = '...';

    pages: PaginationList = [];
    pageCount = 0;
    isSimpleMode = false;
    isTablet = false;
    threshold = DESKTOP_SIMPLE_MODE_THRESHOLD;

    ngOnInit(): void {
        this.isTablet$.pipe(untilDestroyed(this)).subscribe((isTablet) => {
            this.threshold = isTablet
                ? TABLET_SIMPLE_MODE_THRESHOLD
                : DESKTOP_SIMPLE_MODE_THRESHOLD;

            this.isSimpleMode =
                this.pageCount <= (this.simpleModeThreshold || this.threshold);
            this.pages = this.getComputedPageRange();
        });
    }

    ngOnChanges(): void {
        this.pageCount = this.getPageCount();
        this.isSimpleMode =
            this.pageCount <= (this.simpleModeThreshold || this.threshold);
        this.pages = this.getComputedPageRange();
    }

    selectPageByNumber(page: number | Ellipsis): void {
        if (page === this.ELLIPSIS || page === this.currentPage) {
            return;
        }

        this.setPageNumber(page);
    }

    setPageByDirection(diff: number): void {
        this.setPageNumber(this.currentPage + diff);
    }

    private setPageNumber(newPageNumber: number): void {
        const page = Math.min(Math.max(0, newPageNumber), this.pageCount);
        this.selectPage.emit(page);
    }

    private getPageCount(): number {
        return Math.ceil(this.totalItems / this.perPage);
    }

    private getComputedPageRange(): PaginationList {
        const perPage = this.perPage;
        const totalItems = this.totalItems;
        const currentPage = this.currentPage;
        const pageCount = this.pageCount;

        if (perPage <= 0 || totalItems <= 0) {
            return [];
        }

        if (this.isSimpleMode) {
            return range(1, pageCount + 1);
        }

        const middleRangeStart =
            currentPage - 1 > 1
                ? (currentPage === pageCount ? pageCount - 1 : currentPage) - 1
                : 1;

        const middleRangeEnd =
            currentPage < pageCount
                ? (currentPage === 1 ? 2 : currentPage) + 1
                : pageCount;

        const middleRange = range(middleRangeStart, middleRangeEnd + 1);
        const visiblePages = uniq([1, ...middleRange, pageCount]);

        const visiblePagesFormatted: PaginationList = [];

        for (const pageNumber of visiblePages) {
            const prevPageIndex = visiblePages.indexOf(pageNumber) - 1;
            if (prevPageIndex > -1) {
                const prevPage = visiblePages[prevPageIndex];
                if (pageNumber - prevPage > 1) {
                    visiblePagesFormatted.push(this.ELLIPSIS);
                }
            }
            visiblePagesFormatted.push(pageNumber);
        }
        return visiblePagesFormatted;
    }
}
