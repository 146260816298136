<div class="upload-error">
    <upload-step class="upload-error__step" [step]="step"></upload-step>

    @if (isSizeError || isTypeError) {
        <ng-container *ngTemplateOutlet="fileValidationTpl"></ng-container>
    }

    @if (isUploadError) {
        <ng-container *ngTemplateOutlet="fileUploadError"></ng-container>
    }

    @if (isContentError) {
        <ng-container *ngTemplateOutlet="fileContentError"></ng-container>
    }

    @if (isFilesQuantityError) {
        <ng-container *ngTemplateOutlet="filesQuantityError"></ng-container>
    }

    <div class="upload-error__file">
        <!-- @todo: create separate component-->
        <div class="upload-error__file-content">
            <fi-icon
                [class.upload-error__file-icon--error]="isSizeError"
                [size]="'x-small'"
                [svgName]="isSizeError ? 'times' : 'check'"
                class="upload-error__file-icon"
            ></fi-icon>
            <span
                [class.fi-text--error]="isSizeError"
                class="fi-text fi-text--small fi-text--medium"
            >
                {{ fileSize }}MB size limit.
            </span>
        </div>

        <div class="upload-error__file-content">
            <fi-icon
                [class.upload-error__file-icon--error]="isTypeError"
                [size]="'x-small'"
                [svgName]="isTypeError ? 'times' : 'check'"
                class="upload-error__file-icon"
            ></fi-icon>
            <span
                [class.fi-text--error]="isTypeError"
                class="fi-text fi-text--small fi-text--medium"
            >
                {{ fileType }} file type only.
            </span>
        </div>
    </div>
</div>

<ng-template #fileValidationTpl>
    <div class="upload-error__content">
        @if ((isSizeError && !isTypeError) || (isTypeError && !isSizeError)) {
            <span class="fi-text fi-text--medium fi-text--large fi-text--error">
                Document could not be uploaded.
            </span>
        }

        @if (isSizeError && isTypeError) {
            <span class="fi-text fi-text--medium fi-text--large fi-text--error">
                Incorrect file type and size.
            </span>
        }

        @if (isSizeError && !isTypeError) {
            <span class="fi-text fi-text--medium fi-text--error">
                Please replace with a smaller file.
            </span>
        }

        @if (isTypeError && !isSizeError) {
            <span class="fi-text fi-text--medium fi-text--error">
                Please replace with proper file format ({{ fileType }}).
            </span>
        }

        @if (isTypeError && isSizeError) {
            <span class="fi-text fi-text--medium fi-text--error">
                Please replace with
                {{ fileType }} file under {{ fileSize }}MB.
            </span>
        }

        <fi-button
            [ariaLabel]="'Upload New File'"
            [variation]="'secondary'"
            (click)="uploadNewFile()"
            class="upload-error__upload-btn"
        >
            Upload New File
        </fi-button>

        <fi-icon
            *fiIsDesktop
            [size]="'42x'"
            [variation]="'custom'"
            [svgName]="'drag-and-drop'"
            class="upload-error__drop-over-icon"
        ></fi-icon>
    </div>
</ng-template>

<ng-template #fileUploadError>
    <div class="upload-error__content">
        <span class="fi-text fi-text--medium fi-text--large fi-text--error">
            File failed to upload.
        </span>

        @if (uploadType | memoizeFunc: showUploadErrorMsg) {
            <span
                class="upload-error__msg fi-text fi-text--medium fi-text--error"
            >
                Please try again later.
            </span>
        }

        <div class="upload-error__upload-error">
            <fi-button
                [ariaLabel]="'Upload New File'"
                [variation]="'secondary,block'"
                (click)="uploadNewFile()"
                class="upload-error__btn"
            >
                Upload New File
            </fi-button>

            <fi-button
                [ariaLabel]="'Retry'"
                [variation]="'primary,block'"
                (click)="retrySendFile()"
                class="upload-error__btn"
            >
                Retry
            </fi-button>
        </div>

        @if (uploadType | memoizeFunc: showUploadErrorMsg) {
            <span>
                <fi-icon
                    *fiIsDesktop
                    [size]="'42x'"
                    [variation]="'custom'"
                    [svgName]="'drag-and-drop'"
                    class="upload-error__drop-over-icon"
                ></fi-icon>
            </span>
        } @else {
            <span
                class="upload-error__msg fi-text fi-text--medium fi-text--error"
            >
                If problem persists, contact Penske Connected Fleet at
                <a
                    href="mailto:success@penskeconnectedfleet.com"
                    aria-label="success@penskeconnectedfleet.com"
                    class="fi-text fi-text--medium fi-text--link"
                >
                    success&#64;penskeconnectedfleet.com
                </a>
                or call
                <a
                    href="tel:844-426-4555"
                    aria-label="844-426-4555"
                    class="fi-text fi-text--medium fi-text--error"
                >
                    (844) 426-4555.
                </a>
            </span>
        }
    </div>
</ng-template>

<ng-template #fileContentError>
    <div class="upload-error__content">
        <span class="fi-text fi-text--medium fi-text--large fi-text--error">
            {{ errorStatesInfo.title }}
        </span>

        <span
            class="fi-text fi-text--medium fi-text--error upload-error__download-msg"
        >
            {{ errorStatesInfo.description }}
        </span>

        <div class="upload-error__upload-error">
            <fi-button
                [ariaLabel]="'Upload New File'"
                [variation]="'secondary,block'"
                (click)="uploadNewFile()"
                class="upload-error__btn upload-error__btn--big"
            >
                Upload New File
            </fi-button>

            <fi-button
                [ariaLabel]="uploadType | memoizeFunc: getIconLabel"
                [variation]="'primary,block'"
                [class.upload-error__btn--auto-width]="
                    uploadType === uploadAreaType.FleetData
                "
                (click)="downloadFile()"
                class="upload-error__btn upload-error__btn--big"
            >
                <fi-icon
                    [size]="'large'"
                    [variation]="'custom'"
                    [svgName]="'excel-icon'"
                    class="upload-error__btn-icon"
                ></fi-icon>
                {{ uploadType | memoizeFunc: getIconLabel }}
            </fi-button>
        </div>

        <fi-icon
            [variation]="'custom'"
            [size]="'60x'"
            [svgName]="'drag-and-drop'"
        ></fi-icon>
    </div>
</ng-template>

<ng-template #filesQuantityError>
    <div class="upload-error__content">
        <span class="fi-text fi-text--medium fi-text--large fi-text--error">
            Please upload one file at a time.
        </span>

        <fi-button
            [ariaLabel]="'Upload New File'"
            [variation]="'secondary'"
            (click)="uploadNewFile()"
            class="upload-error__upload-btn"
        >
            Upload New File
        </fi-button>

        <fi-icon
            *fiIsDesktop
            [size]="'42x'"
            [variation]="'custom'"
            [svgName]="'drag-and-drop'"
            class="upload-error__drop-over-icon"
        ></fi-icon>
    </div>
</ng-template>
