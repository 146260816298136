<fi-modal
    [open]="open"
    [variation]="modalVariation"
    (event)="handleModalEvent($event)"
    class="delete-uploaded-file-modal"
>
    @if (open) {
        <fi-modal-header *fiIsDesktopDevice (close)="handleClose()">
            <h3 class="fi-heading fi-heading--tertiary fi-heading--dark">
                Delete Document?
            </h3>
        </fi-modal-header>

        <fi-modal-content>
            <div class="delete-uploaded-file-modal__content">
                <fi-icon
                    [svgName]="'trash-alt'"
                    [variation]="'regular'"
                    [size]="'medium'"
                    class="delete-uploaded-file-modal__icon"
                ></fi-icon>
                <div class="delete-uploaded-file-modal__description">
                    <p class="fi-text">
                        This will permanently delete
                        <span
                            class="fi-text delete-uploaded-file-modal__highlighted-text"
                        >
                            {{ fileName | truncate: truncateLength }}
                        </span>
                        and this action cannot be undone. Are you sure you want
                        to delete this document?
                    </p>
                </div>
            </div>
        </fi-modal-content>

        <fi-modal-footer>
            <div class="delete-uploaded-file-modal__footer">
                <fi-button
                    [ariaLabel]="'Cancel'"
                    [variation]="'secondary,block'"
                    (click)="handleClose()"
                    class="delete-uploaded-file-modal__footer-control"
                >
                    Cancel
                </fi-button>

                <fi-button
                    [ariaLabel]="'Delete Document'"
                    [variation]="'danger,block'"
                    (click)="handleDeactivate()"
                    class="delete-uploaded-file-modal__footer-control"
                >
                    Delete Document
                </fi-button>
            </div>
        </fi-modal-footer>
    }
</fi-modal>
