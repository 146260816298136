import ResizeObserver from 'resize-observer-polyfill';

import { Observable, Subscriber } from 'rxjs';
// todo: remove when IE support is removed,
//  resize-observer-polyfill is broken after TS 4.2.2 version
//  refer to: https://github.com/que-etc/resize-observer-polyfill/issues/83

export function resizeElement(
    element: Element,
): Observable<ResizeObserverEntry> {
    return new Observable((observer: Subscriber<ResizeObserverEntry>) => {
        // @ts-ignore
        const resizeObserver = new ResizeObserver(([entry]) => {
            observer.next(entry);
        });

        resizeObserver.observe(element);

        return () => {
            resizeObserver.disconnect();
        };
    });
}
