@if (currentMode && !!currentDate) {
    <div
        [fiVariation]="variation"
        [fiVariationBase]="'fi-calendar'"
    >
        <fi-horizontal-control
            [hasPrevious]="!prevControlDisabled"
            [hasNext]="!nextControlDisabled"
            (previousClick)="changePickerPage()"
            (nextClick)="changePickerPage(true)"
        >
            @if (currentMode === 'YEARS') {
                <span
                    class="fi-text fi-text--medium fi-text--dark-blue"
                >
                    {{ getYearsTitle() }}
                </span>
            } @else {
                <div>
                    <button
                        (click)="openMonths()"
                        aria-label="Open months"
                        class="fi-calendar__title-button">
                        <span class="fi-text fi-text--medium fi-text--link">
                            {{ currentDate.format('MMMM') }}
                        </span>
                    </button>

                    <button
                        (click)="openYears()"
                        aria-label="Open years"
                        class="fi-calendar__title-button">
                        <span class="fi-text fi-text--medium fi-text--link">
                            {{ currentDate.format('YYYY') }}
                        </span>
                    </button>
                </div>
            }
        </fi-horizontal-control>

        @switch (currentMode) {
            @case ('MONTHS') {
                <div>
                    <div class="fi-calendar__controls">
                        @for (month of months; track month; let monthNum = $index) {
                            <div class="fi-calendar__month">
                                @let hasMonthSelection = month | memoizeFunc: isMonthSelected : this;

                                <button
                                    [class.fi-calendar__body-control--selected]="hasMonthSelection"
                                    (click)="selectMonth(monthNum)"
                                    type="button"
                                    aria-label="Select month"
                                    class="fi-calendar__body-control"
                                >
                                    <span
                                        [class.fi-text--white]="hasMonthSelection"
                                        class="fi-calendar__control-value fi-text fi-text--medium"
                                    >
                                        {{ month }}
                                    </span>
                                </button>
                            </div>
                        }
                    </div>
                </div>
            }

            @case ('YEARS') {
                <div>
                    <div class="fi-calendar__controls">
                        @for (year of yearsRange; track year) {
                            <div class="fi-calendar__month">
                                @let hasYearSelection = year | memoizeFunc: isYearSelected : this;

                                <button
                                    [class.fi-calendar__body-control--selected]="hasYearSelection"
                                    (click)="selectYear(year)"
                                    type="button"
                                    aria-label="Select year"
                                    class="fi-calendar__body-control"
                                >
                                    <span
                                        [class.fi-text--white]="hasYearSelection"
                                        class="fi-calendar__control-value fi-text fi-text--medium"
                                    >
                                        {{ year }}
                                    </span>
                                </button>
                            </div>
                        }
                    </div>
                </div>
            }

            @default {
                <div>
                    <div class="fi-calendar__controls">
                        @for (dayOfWeek of daysOfWeek; track dayOfWeek) {
                            <div
                                class="fi-calendar__day fi-calendar__day--of-week"
                            >
                                <div class="fi-calendar__day-cell">
                                    <span
                                        class="fi-calendar__day-value fi-text fi-text--heavy fi-text--small fi-text--light"
                                    >
                                        {{ dayOfWeek }}
                                    </span>
                                </div>
                            </div>
                        }
                    </div>

                    <div
                        class="fi-calendar__controls"
                        autoFocus
                        focusTarget="fi-calendar__body-control--selected"
                        focusTargetSecondary="fi-calendar__body-control--today"
                    >
                        @for (day of days; track day) {
                            <div class="fi-calendar__day">
                                @let hasDaySelected = [day, selectedDates] | memoizeFunc: isDaySelected : this;
                                @let isToday = day | memoizeFunc: isDayToday : this;
                                @let hasDayInRange = shouldHighlightRange && day | memoizeFunc: isDayInRange : this;
                                @let isDayDisabled = !(day | memoizeFunc: canBeSelected : this);
                                @let hasDisabledTooltip = day | memoizeFunc: getDayDisabledTooltip : this;

                                @switch (day | memoizeFunc: getRenderMode : this) {
                                    @case ('ALL_SELECTABLE') {
                                        <div
                                            [fiTooltip]="
                                                [day, warnBeforeDate]
                                                    | memoizeFunc: getDayWarningTooltip : this
                                            "
                                            class="fi-calendar__control-container"
                                        >
                                            <button
                                                [ngClass]="{
                                                    'fi-calendar__body-control--selected': hasDaySelected,
                                                    'fi-calendar__body-control--today': isToday,
                                                    'fi-calendar__body-control--in-range': hasDayInRange,
                                                    'fi-calendar__body-control--disabled': isDayDisabled
                                                }"
                                                (click)="onSelectDate(day)"
                                                type="button"
                                                aria-label="Select date"
                                                class="fi-calendar__body-control"
                                            >
                                                <ng-container
                                                    *ngTemplateOutlet="dayTmpl; context: { day: day }"
                                                ></ng-container>
                                            </button>
                                        </div>
                                    }

                                    @case ('RANGE_SELECTABLE') {
                                        <div
                                            [fiTooltip]="hasDisabledTooltip"
                                            class="fi-calendar__control-container"
                                        >
                                            <button
                                                [ngClass]="{
                                                    'fi-calendar__body-control--selected': hasDaySelected,
                                                    'fi-calendar__body-control--today': isToday,
                                                    'fi-calendar__body-control--in-range': hasDayInRange,
                                                    'fi-calendar__body-control--disabled': isDayDisabled
                                                }"
                                                [disabled]="!canBeSelected(day)"
                                                (click)="onSelectDate(day)"
                                                type="button"
                                                aria-label="Select date"
                                                class="fi-calendar__body-control"
                                            >
                                                <ng-container
                                                    *ngTemplateOutlet="dayTmpl; context: { day: day }"
                                                ></ng-container>
                                            </button>
                                        </div>
                                    }

                                    @default {
                                        <div
                                            [class.fi-calendar__day-cell--today]="isToday"
                                            [class.fi-calendar__day-cell--selected]="hasDaySelected"
                                            [fiTooltip]="hasDisabledTooltip"
                                            class="fi-calendar__day-cell"
                                        >
                                            <ng-container
                                                *ngTemplateOutlet="dayTmpl; context: { day: day }"
                                            ></ng-container>
                                        </div>
                                    }
                                }
                            </div>
                        }

                        @if (days | memoizeFunc: monthHasFiveWeeks) {
                            <div
                                class="fi-calendar__week-placeholder"
                            ></div>
                        }
                    </div>
                </div>
            }
        }
    </div>
}

<ng-template #dayTmpl let-day="day">
    <span
        [ngClass]="{
            'fi-text--lighter': day | memoizeFunc: isNotActiveDay : this,
            'fi-text--white':
                [day, selectedDates] | memoizeFunc: isDaySelected : this,
            'fi-calendar__control-value--blocked':
                day | memoizeFunc: isBlocking : this
        }"
        class="fi-calendar__control-value fi-text fi-text--medium fi-text--small"
    >
        {{ day.format('D') }}
    </span>
</ng-template>

<ng-template #dayWarningTooltipTpl>
    <div class="fi-calendar__tooltip">{{ warnBeforeDateMessage }}</div>
</ng-template>

<ng-template #dayDisabledTooltipTpl>
    <div class="fi-calendar__tooltip">Not available</div>
</ng-template>
