import { UnitDirectionMapMarkerIcon } from '../../feature/map-marker';

import { HOVERED_BLUE_COLOR, BLUE_COLOR } from './constants';

const paths: Record<UnitDirectionMapMarkerIcon, string> = {
    [UnitDirectionMapMarkerIcon.North]:
        '<path id="Path_3898" data-name="Path 3898" class="cls-4" d="m37.32,36.1l-7.28-1.91-7.57,1.63,7.5-19.09,7.35,19.37Z"/>',
    [UnitDirectionMapMarkerIcon.NorthWest]:
        '<path id="Path_3898" data-name="Path 3898" class="cls-4" d="m39.45,29.15l-6.5,3.8-4.2,6.5-8.2-18.8,18.9,8.5Z"/>',
    [UnitDirectionMapMarkerIcon.West]:
        '<path id="Path_3898" data-name="Path 3898" class="cls-4" d="M36,22.7L34.1,30l1.6,7.6l-19.1-7.5L36,22.7z"/>',
    [UnitDirectionMapMarkerIcon.SouthWest]:
        '<path id="Path_3898" data-name="Path 3898" class="cls-4" d="m29.15,20.61l3.79,6.45,6.45,4.17-18.78,8.16,8.54-18.78Z"/>',
    [UnitDirectionMapMarkerIcon.South]:
        '<path id="Path_3898" data-name="Path 3898" class="cls-4" d="m22.68,24l7.28,1.91,7.57-1.63-7.5,19.09-7.35-19.37Z"/>',
    [UnitDirectionMapMarkerIcon.SouthEast]:
        '<path id="Path_3898" data-name="Path 3898" class="cls-4" d="m20.55,30.95l6.5-3.8,4.2-6.5,8.2,18.8-18.9-8.5Z"/>',
    [UnitDirectionMapMarkerIcon.East]:
        '<path id="Path_3898" data-name="Path 3898" class="cls-4" d="m23.95,37.37l1.91-7.28-1.63-7.57,19.09,7.5-19.37,7.35Z"/>',
    [UnitDirectionMapMarkerIcon.NorthEast]:
        '<path id="Path_3898" data-name="Path 3898" class="cls-4" d="m30.9,39.5l-3.8-6.5-6.5-4.2,18.8-8.2-8.5,18.9Z"/>',
    [UnitDirectionMapMarkerIcon.NoDirection]: '',
};

export function generateUnitHistoryIcon(
    iconType: UnitDirectionMapMarkerIcon,
    hovered = false,
): string {
    const markerColor = hovered ? HOVERED_BLUE_COLOR : BLUE_COLOR;
    const markerPath = paths[iconType];

    return `
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
            <defs>
                <style>
                .cls-1 {
                    fill: none;
                }

                .cls-4 {
                    fill: #fff;
                }

                .cls-3 {
                    isolation: isolate;
                    opacity: .5;
                }
                </style>
            </defs>
            <rect class="cls-1" width="60" height="60"/>
            <circle id="Ellipse_572" data-name="Ellipse 572" class="cls-3" cx="30" cy="30" r="30" fill="${markerColor}"/>
            <circle id="Ellipse_573" data-name="Ellipse 573" class="cls-2" cx="30" cy="30" r="18.8" fill="${markerColor}"/>
            ${markerPath}
        </svg>`;
}
