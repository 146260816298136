import { isArray, uniq } from 'lodash';

import { FileDropOptions, FileTypes, FileValidationErrors } from '../models';
import { CSV_FILE_EXTENSION, DEFAULT_UPLOAD_FILE_OPTIONS } from '../constants';

export function checkFileType(
    uploadedFileType: string,
    definedFileType: string | string[],
) {
    if (typeof definedFileType === 'string') {
        return uploadedFileType.toLowerCase() === definedFileType.toLowerCase();
    }

    return definedFileType.find((item) => {
        return item === uploadedFileType;
    });
}

export function isFileTypeValid(
    currentType: string,
    validTypes: string[] | string,
): boolean {
    if (isArray(validTypes)) {
        return !!(validTypes as string[]).filter(
            (defaultType) =>
                currentType.toLowerCase() === defaultType.toLowerCase(),
        ).length;
    }

    return currentType.toLowerCase() === (validTypes as string).toLowerCase();
}

export function errorListDefine(
    uploadFiles: FileList,
    fileUploadOptions: FileDropOptions = DEFAULT_UPLOAD_FILE_OPTIONS,
): string[] {
    let errors: string[] = [];

    if (uploadFiles.length > fileUploadOptions.filesQuantity) {
        errors.push(FileValidationErrors.filesQuantity);
    }

    // destructuring used to iterate FileList type
    [...uploadFiles].forEach((item: File) => {
        errors = [
            ...errors,
            ...getUploadFileErrors(errors, item, fileUploadOptions),
        ];
    });

    return uniq(errors);
}

export function uploadFileErrors(
    file: File,
    fileUploadOptions: FileDropOptions = DEFAULT_UPLOAD_FILE_OPTIONS,
): string[] {
    const errors = getUploadFileErrors([], file, fileUploadOptions);

    return uniq(errors);
}

export function getUploadFileErrors(
    errors: string[] = [],
    file: File,
    fileUploadOptions: FileDropOptions,
): string[] {
    const extension = file.name.split('.').pop()?.toLowerCase();
    let fileType = file.type;

    if (extension === CSV_FILE_EXTENSION) {
        fileType = FileTypes.fileCsv;
    }

    if (!checkFileType(fileType, fileUploadOptions.fileType)) {
        errors.push(FileValidationErrors.fileType);
    }

    if (!(file.size <= fileUploadOptions.fileSize)) {
        errors.push(FileValidationErrors.fileSize);
    }

    if (file.name.length > fileUploadOptions.fileNameLength) {
        errors.push(FileValidationErrors.fileNameLength);
    }

    return errors;
}
