@if (hasLoadMoreButton && canLoadMore) {
    <fi-button
        [ariaLabel]="'Load More'"
        [variation]="'secondary,block,wide,animated'"
        [disabled]="isLoading"
        [loading]="isLoading"
        (click)="handlePageNumberChange(source.pageNumber + 1)"
    >
        {{ isLoading ? 'Loading' : 'Load More' }}
    </fi-button>
}
