import {
    trigger,
    style,
    transition,
    animate,
    query,
    animateChild,
    state,
} from '@angular/animations';

const ANIMATION_TIMINGS = '300ms ease';
const FLASH_MESSAGE_ANIMATION_TIMINGSS = '200ms 500ms linear';
const ANIMATION_EXPAND_TIMINGS = '300ms linear';
const ANIMATION_PADDING_TIMINGS = '50ms linear';

export const Animations = {
    AnimateFooter: trigger('animatedFooter', [
        transition(':enter', [
            style({ transform: 'translateY(100%)' }),
            animate(ANIMATION_TIMINGS, style({ transform: 'translateY(0)' })),
        ]),
        transition(':leave', [
            animate(
                ANIMATION_TIMINGS,
                style({ transform: 'translateY(100%)' }),
            ),
        ]),
    ]),
    AnimateHeader: trigger('animatedHeader', [
        transition(':enter', [
            style({ transform: 'translateY(-100%)' }),
            animate(ANIMATION_TIMINGS, style({ transform: 'translateY(0)' })),
        ]),
        transition(':leave', [
            animate(
                ANIMATION_TIMINGS,
                style({ transform: 'translateY(-100%)' }),
            ),
        ]),
    ]),
    AnimateFlashMessage: trigger('animatedFlashMessage', [
        transition('* => *', [
            query(
                '.flash-message:enter',
                style({
                    opacity: '0',
                    transform: 'translateY(-100%)',
                }),
                { optional: true },
            ),
            query(
                '.flash-message--mobile:enter',
                style({
                    opacity: '0',
                    transform: 'translateY(100%)',
                }),
                { optional: true },
            ),

            query(
                '.flash-message:enter',
                animate(
                    FLASH_MESSAGE_ANIMATION_TIMINGSS,
                    style({
                        opacity: '1',
                        transform: 'translateY(0)',
                    }),
                ),
                { optional: true },
            ),

            query(
                '.flash-message--mobile:leave',
                animate(
                    FLASH_MESSAGE_ANIMATION_TIMINGSS,
                    style({
                        opacity: '0',
                        transform: 'translateY(100%)',
                    }),
                ),
                { optional: true },
            ),
            query(
                '.flash-message:leave',
                animate(
                    FLASH_MESSAGE_ANIMATION_TIMINGSS,
                    style({
                        opacity: '0',
                        transform: 'translateY(-100%)',
                    }),
                ),
                { optional: true },
            ),
        ]),
    ]),
    AnimateExpansion: trigger('animateExpansion', [
        transition(':enter', [
            style({ height: '0px' }),
            animate(ANIMATION_EXPAND_TIMINGS, style({ height: '*' })),
        ]),
        transition(':leave', [
            style({ height: '*' }),
            animate(ANIMATION_EXPAND_TIMINGS, style({ height: '0px' })),
        ]),
    ]),
    AnimateInnerExpansion: trigger('animateInnerExpansion', [
        transition('void => *', [
            style({ height: '0px' }),
            animate(ANIMATION_EXPAND_TIMINGS, style({ height: '*' })),
        ]),
        transition('* => void', [
            style({ height: '*' }),
            animate(ANIMATION_EXPAND_TIMINGS, style({ height: '0px' })),
        ]),
    ]),
    AnimateNgIfChildren: trigger('ngIfAnimation', [
        transition(':leave', [query('@*', animateChild())]),
    ]),
    AnimatePaddingExpansion: trigger('animatePaddingExpansion', [
        transition('void => *', [
            style({ padding: '0px' }),
            animate(ANIMATION_PADDING_TIMINGS, style({ padding: '*' })),
        ]),
        transition('* => void', [
            style({ padding: '*' }),
            animate(ANIMATION_PADDING_TIMINGS, style({ padding: '0px' })),
        ]),
    ]),
    AnimateFadeInOut: trigger('animateFadeInOut', [
        transition(':enter', [
            style({ opacity: '0' }),
            animate(ANIMATION_TIMINGS, style({ opacity: '1' })),
        ]),
        transition(':leave', [
            style({ opacity: '1', position: 'absolute' }),
            animate(ANIMATION_TIMINGS, style({ opacity: '0' })),
        ]),
    ]),
    AnimateSlideInOut: trigger('animateSlideInOut', [
        transition(':enter', [
            style({ transform: 'translateY(-100%)', opacity: '0' }),
            animate(
                ANIMATION_TIMINGS,
                style({ transform: 'translateY(0)', opacity: '1' }),
            ),
        ]),
        transition(':leave', [
            style({ opacity: '1', position: 'absolute' }),
            animate(
                ANIMATION_TIMINGS,
                style({ transform: 'translateY(-100%)', opacity: '0' }),
            ),
        ]),
    ]),
    AnimateStateFadeInAndOut: trigger('animateStateFadeInAndOut', [
        state('false', style({ opacity: 0, visibility: 'hidden' })),
        state('true', style({ opacity: 1, visibility: 'visible' })),
        transition('false => true', animate('300ms ease-in')),
        transition('true => false', animate('300ms ease-out')),
    ]),
    AnimateEnterLeave: trigger('enterLeave', [
        transition(':enter', [
            style({ transform: 'translateX(100%)' }),
            animate(ANIMATION_TIMINGS),
        ]),
        transition(':leave', [
            animate(
                ANIMATION_TIMINGS,
                style({ transform: 'translateX(100%)' }),
            ),
        ]),
    ]),
};
