import { LoginController } from './login-controller';
import { GraphicsController } from './graphics-controller';
import { ActionsController } from './actions-controller';
import { ModalController } from './modal-controller';
import { RecoverUsernameController } from './recover-username';
import { RecoverPasswordController } from './recover-password';
import { RoadAheadController } from './road-ahead-controller';
import { isIOS } from './common';

const isIOSDevice = isIOS();

if (isIOSDevice) {
    document.body.classList.add('device-ios');
}

if (document.querySelector('.landing-page')) {
    const actionsController = new ActionsController();
}

if (document.querySelector('#login-form')) {
    const loginController = new LoginController();
    const modalController = new ModalController();
    const recoverUsernameController = new RecoverUsernameController();
    const recoverPasswordController = new RecoverPasswordController();
}

if (document.querySelector('.landing-graphics')) {
    const graphicsController = new GraphicsController();
}

if (document.querySelector('.landing-road-ahead')) {
    const roadAheadController = new RoadAheadController();
}

