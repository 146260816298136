<div class="fi-horizontal-control">
    <div class="fi-horizontal-control__navigation">
        <fi-button
            [variation]="'secondary-link,datepicker-nav'"
            [disabled]="!hasPrevious"
            [ariaLabel]="'Back'"
            (click)="onPreviousClick()"
            class="
                fi-horizontal-control__button
                fi-horizontal-control__button--prev
            "
        >
            <fi-icon
                [size]="'x-large'"
                [variation]="'solid'"
                [svgName]="'caret-left'"
            ></fi-icon>
        </fi-button>
    </div>

    <div class="fi-horizontal-control__name">
        <span class="fi-text fi-text--medium fi-text--dark">
            <ng-content></ng-content>
        </span>
    </div>

    <div class="fi-horizontal-control__navigation">
        <fi-button
            [variation]="'secondary-link,datepicker-nav'"
            [disabled]="!hasNext"
            [ariaLabel]="'Forward'"
            (click)="onNextClick()"
            class="
                fi-horizontal-control__button
                fi-horizontal-control__button--next
            "
        >
            <fi-icon
                [size]="'x-large'"
                [variation]="'solid'"
                [svgName]="'caret-right'"
            ></fi-icon>
        </fi-button>
    </div>
</div>
