<!--@todo: revise classes-->
@if (isFilterShow) {
    <div
        @animateExpansion
        (@animateExpansion.start)="captureAnimationStart()"
        (@animateExpansion.done)="captureAnimationDone()"
        class="fi-filtering-panel"
    >
        <div class="fi-filtering-panel__wrapper">
            <div class="fi-filtering-panel--inner">
                <fi-filtering-list></fi-filtering-list>
                <div class="fi-filtering-panel__actions">
                    <div class="slot">
                        <div class="slot__inner slot__inner--spanned">
                            <fi-button
                                [ariaLabel]="'Reset'"
                                [variation]="'secondary'"
                                (click)="handleResetFilters()"
                                id="resetFilters"
                            >
                                Reset
                            </fi-button>
                        </div>
                        <div class="slot__inner">
                            <fi-button
                                [ariaLabel]="'Cancel'"
                                [variation]="'secondary'"
                                (click)="handleCancelFilters()"
                                id="cancelFilters"
                            >
                                Cancel
                            </fi-button>
                        </div>
                        <div class="slot__inner">
                            <fi-button
                                [ariaLabel]="'Apply'"
                                [variation]="'primary,block'"
                                (click)="handleApplyFilters()"
                                id="applyFilters"
                                class="fi-filtering-panel__action--save-button"
                            >
                                Apply
                            </fi-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
