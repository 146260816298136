import { NgModule, Type } from '@angular/core';

import { FleetProfileMetricsTabsComponent } from './fleet-profile-tabs/fleet-profile-tabs.component';
import { MetricsTabComponent } from './metrics-tab';

const FEATURES: Array<Type<any> | any[]> = [
    FleetProfileMetricsTabsComponent,
    MetricsTabComponent,
]

@NgModule({
    imports: [...FEATURES],
    exports: FEATURES,
})
export class MetricsTabsModule {
}
