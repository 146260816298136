import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { VariationDirective } from '../../../directives';

@Component({
    selector: 'upload-step',
    templateUrl: './upload-step.component.html',
    styleUrls: ['./upload-step.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [VariationDirective],
})
export class UploadStepComponent {
    @Input() variation: string;
    @Input() step: string;
}
