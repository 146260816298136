import {
    Component,
    ContentChildren,
    Output,
    NgIterable,
    QueryList,
    EventEmitter,
    Input,
} from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';
import { CdkDragDrop, DragDropModule } from '@angular/cdk/drag-drop';

import { VariationDirective } from '../../directives';
import { ButtonComponent, IconComponent } from '../../components';
import { MemoizeFuncPipe, ToNumArray } from '../../pipes';
import { TooltipDirective } from '../tooltip';
import { DraggableListItemDirective } from './item/item.directive';

export interface DraggableListChange {
    previousIndex: number;
    currentIndex: number;
}

@Component({
    selector: 'fi-draggable-list',
    templateUrl: './draggable-list.component.html',
    styleUrls: ['./draggable-list.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        DragDropModule,
        VariationDirective,
        DraggableListItemDirective,
        TooltipDirective,
        ButtonComponent,
        IconComponent,
        MemoizeFuncPipe,
        ToNumArray,
    ]
})
export class DraggableListComponent {
    @Input() withPlaceholder = false;
    @Input() boundaryClass = '.fi-draggable-list';
    @Input() hideControls: boolean;

    @Output() changeOrder = new EventEmitter<DraggableListChange>();

    @ContentChildren(DraggableListItemDirective)
    items: QueryList<DraggableListItemDirective> &
        NgIterable<DraggableListItemDirective>;

    isFirstItem(index: number): boolean {
        return index === 0;
    }

    isLastItem(index: number): boolean {
        return this.items.length === index + 1;
    }

    manualChangeOrder(previousIndex: number, currentIndex: number) {
        this.dropChangeOrder({
            previousIndex,
            currentIndex,
        });
    }

    dropChangeOrder({
        previousIndex,
        currentIndex,
    }: Partial<CdkDragDrop<unknown>>) {
        this.changeOrder.emit({ previousIndex, currentIndex });
    }
}
