import { NgModule, Type } from '@angular/core';

import {
    ModalCloseBarComponent,
    ModalComponent,
    ModalContentComponent,
    ModalContentTopComponent,
    ModalFooterComponent,
    ModalHeaderComponent,
    ModalRowComponent,
    ModalSplitRowComponent,
    ModalUnitRowComponent,
} from './components';

const FEATURES: Array<Type<any> | any[]> = [
    ModalRowComponent,
    ModalSplitRowComponent,
    ModalUnitRowComponent,
    ModalHeaderComponent,
    ModalCloseBarComponent,
    ModalContentTopComponent,
    ModalContentComponent,
    ModalFooterComponent,
    ModalComponent,
];

const ISOLATED_FEATURES: Array<Type<any> | any[]> = [];

@NgModule({
    imports: [...ISOLATED_FEATURES, ...FEATURES],
    exports: [...FEATURES],
})
export class ModalModule {}
