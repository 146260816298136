import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { forEach, isEmpty } from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class BodyUtilService {
    private renderer: Renderer2;

    constructor(
        @Inject(DOCUMENT)
        private document: Document,
        rendererFactory: RendererFactory2,
    ) {
        // You cannot inject Renderer2, but we can run RendererFactory2 to get Renderer2 instance inside @Injectable() service.
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    get bodyClasses(): string[] {
        return document.body.className.split(' ');
    }

    updateBodyClasses(
        previousBodyClasses: string,
        currentBodyClasses: string,
    ): void {
        if (!isEmpty(previousBodyClasses)) {
            forEach(previousBodyClasses.split(' '), (className) => {
                this.renderer.removeClass(document.body, className);
            });
        }

        if (!isEmpty(currentBodyClasses)) {
            this.addClasses(currentBodyClasses);
        }
    }

    addClasses(classes: string): void {
        if (!isEmpty(classes)) {
            forEach(classes.split(' '), (className) => {
                this.renderer.addClass(document.body, className);
            });
        }
    }
}
