<div
    [fiVariation]="variation"
    [fiVariationBase]="'fi-modal-close-bar'"
    class="fi-modal-close-bar"
>
    <div class="fi-modal-close-bar__row slot slot--center-vertically">
        <div class="slot__inner">
            <fi-button
                [ariaLabel]="'Close'"
                [variation]="'squared,white-st-link'"
                [ngClass]="gtmClassName"
                (click)="handleClose()"
                class="fi-modal-close-bar__control"
            >
                <fi-icon
                    [svgName]="closeIcon"
                    [variation]="closeIconVariation"
                    [size]="closeIconSize"
                ></fi-icon>
            </fi-button>
        </div>

        @if (title) {
            <fi-unit-title
                [title]="title"
                [isConnectedIconShown]="isConnectedUnit"
                [variation]="'small'"
                [iconSize]="'small'"
                class="fi-modal-close-bar__title"
            ></fi-unit-title>
        }

        <div class="slot__inner">
            <div class="fi-modal-close-bar__control">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>
