import {
    AfterContentInit,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter,
    Input,
    NgIterable,
    OnInit,
    Output,
    QueryList,
    ViewChildren,
    inject,
} from '@angular/core';
import { CdkAccordionItem, CdkAccordion } from '@angular/cdk/accordion';
import { NgTemplateOutlet } from '@angular/common';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { asyncScheduler, of } from 'rxjs';
import { observeOn, shareReplay, take } from 'rxjs/operators';
import { isBoolean, isNil } from 'lodash';

import { EnvironmentService } from '../../services';
import { Animations } from '../../animations';
import { VariationDirective } from '../../directives';
import { GuidedTourStepDirective } from '../guided-tour';
import { PreventLeaveModalModule } from '../prevent-leave-modal';

import { AccordionExpanderIconComponent } from './expander-icon/expander-icon.component';
import { AccordionService } from './accordion.service';
import { AccordionGroupDirective } from './group/group.directive';
import { AccordionItemDirective } from './item/item.directive';

export const enum AccordionVariation {
    NoIndents = 'no-indents',
    NoHeadingBorder = 'no-heading-border',
    WithSubtitle = 'with-subtitle',
    SimpleExpand = 'simple-expand',
    NoStyling = 'no-styling',
    WithPointerEvent = 'with-pointer-event',
    NoContentStyling = 'no-content-styling',
    WithBorder = 'with-border',
    HeadingEqualPadding = 'heading-equal-padding',
    HeadingEqualResponsivePadding = 'heading-equal-responsive-padding',
    NoMargins = 'no-margins',
    NoBorderRadius = 'no-border-radius',
    GreyHeader = 'grey-header',
    WhiteHeader = 'white-header',
    NoBorder = 'no-border',
}

const ANIMATION_DELAY = 300;

@UntilDestroy()
@Component({
    selector: 'fi-accordion',
    templateUrl: './accordion.component.html',
    styleUrls: ['./accordion.component.scss'],
    exportAs: 'fiAccordion',
    animations: [Animations.AnimateExpansion],
    standalone: true,
    imports: [
        CdkAccordion,
        VariationDirective,
        NgTemplateOutlet,
        CdkAccordionItem,
        GuidedTourStepDirective,
        AccordionExpanderIconComponent,
        PreventLeaveModalModule,
    ],
})
export class AccordionComponent implements OnInit, AfterContentInit {
    private environmentService = inject(EnvironmentService);
    private accordionService = inject(AccordionService);
    private cdf = inject(ChangeDetectorRef);

    @Input() id: string = null;
    @Input() multi = true;
    @Input() grouped = false;
    @Input() variation: AccordionVariation;
    @Input() accordionForPrint: boolean;
    @Input() scrollIntoView: boolean;

    @Output() openItem = new EventEmitter();

    @ContentChildren(AccordionGroupDirective)
    groups: QueryList<AccordionGroupDirective> &
        NgIterable<AccordionGroupDirective>;

    @ContentChildren(AccordionItemDirective)
    items: QueryList<AccordionItemDirective> &
        NgIterable<AccordionItemDirective>;

    @ViewChildren(CdkAccordionItem, { read: ElementRef })
    accordionElements: QueryList<ElementRef>;

    readonly isMobile$ = this.environmentService.isMobile$.pipe(
        shareReplay({ bufferSize: 1, refCount: true }),
    );

    hasPreventLeaveModal: boolean;
    isPreventLeaveOpenModal: boolean;
    closingAccordion: CdkAccordionItem;

    ngOnInit(): void {
        this.accordionService.toggleItem$
            .pipe(untilDestroyed(this))
            .subscribe((itemId) => {
                const item = this.items.find(({ id }) => id === itemId);

                if (item) {
                    this.toggleItem(true, item);
                    this.cdf.markForCheck();
                }
            });
    }

    ngAfterContentInit(): void {
        this.items.forEach((item) => {
            if (item.expanded) {
                this.handleExpandChange(item.expanded, item);
                return;
            }

            item.expanded = this.accordionService.isExpanded(
                this.id,
                item.id,
                item.defaultExpanded,
            );
        });

        this.hasPreventLeaveModal = this.items.some((item) =>
            isBoolean(item.hasChanges),
        );
    }

    handleExpandChange(
        isExpanded: boolean,
        item: AccordionItemDirective,
        accordionElementIndex: number = null,
    ): void {
        if (
            this.scrollIntoView &&
            isExpanded &&
            !isNil(accordionElementIndex)
        ) {
            setTimeout(() => {
                this.accordionElements
                    ?.toArray()
                    [accordionElementIndex]?.nativeElement.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end',
                    });
            }, ANIMATION_DELAY);
        }

        if (!item || isExpanded === item.expanded) {
            return;
        }

        this.toggleItem(isExpanded, item);
        item.expandedChange.emit(isExpanded);
    }

    closeLeaveModal(): void {
        this.isPreventLeaveOpenModal = false;
    }

    toggleAccordion(
        item: AccordionItemDirective,
        cdkAccordionItem: CdkAccordionItem,
    ): void {
        if (item.hasChanges && item.expanded) {
            this.isPreventLeaveOpenModal = true;
            this.closingAccordion = cdkAccordionItem;
            return;
        }

        // need manually close accordion item before open another to avoid issue with tab routing
        if (!this.multi) {
            const openedItem = this.items.find((item) => item.expanded);

            if (openedItem && openedItem.id !== item.id) {
                this.toggleItem(false, openedItem);
            }
        }

        of(null)
            .pipe(take(1), observeOn(asyncScheduler))
            .subscribe(() => cdkAccordionItem.toggle());
    }

    confirmClosingAccordion(): void {
        this.closingAccordion.toggle();
        this.closingAccordion = null;
    }

    private toggleItem(
        isExpanded: boolean,
        item: AccordionItemDirective,
    ): void {
        // todo: refactor accordion component to pass expanded state from outside
        // instead of saving state in component itself

        if (this.id) {
            this.accordionService.memoizeExpandedStatus(
                this.id,
                item.id,
                isExpanded,
            );
        }
        item.expanded = isExpanded;
    }
}
