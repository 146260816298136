<fi-grid-sticky-header>
    <div>
        <ng-content></ng-content>
    </div>
    @if (!isInitializing) {
        <div
            #tableWrapper
            class="fi-grid-table__wrapper fi-grid-table__wrapper--header-table"
        >
            <table
                [fiVariation]="variation"
                [fiVariationBase]="'fi-grid-table'"
                (touchstart)="tableTouchStart($event)"
                (touchend)="tableTouchEnd($event)"
                class="fi-grid-table--no-spacing"
                #table
            >
                <thead>
                    <!-- This row is needed for check columns width-->
                    <ng-container
                        [ngTemplateOutlet]="noHeightTableRow"
                    ></ng-container>
                    <tr
                        class="fi-grid-table__row fi-grid-table__row--header"
                        #tr
                    >
                        @for (
                            column of columns;
                            track trackByColumnFieldName(
                                headerCellIndex,
                                column
                            );
                            let headerCellIndex = $index
                        ) {
                            @if (!column.asRow) {
                                <th
                                    [fiVariation]="[
                                        column.field,
                                        'header',
                                        column.sortable && 'sortable',
                                        (column.sticky || column.stickyRight) &&
                                            'fixed',
                                        column.centered && 'header-centered',
                                    ]"
                                    [fiVariationBase]="'fi-grid-table__cell'"
                                    fi-grid-header-cell
                                    [index]="headerCellIndex"
                                    [tpl]="column.headerCell"
                                    [reversed]="column.reversed"
                                    [centered]="column.centered"
                                    [fiGridSortColumn]="
                                        column.sortable && column.field
                                    "
                                    #fiGridSortColumn="fiGridSortColumn"
                                >
                                    @if (column.sortable) {
                                        <fi-grid-sort-icon
                                            [isActive]="
                                                fiGridSortColumn.isActive
                                            "
                                            [order]="fiGridSortColumn.order"
                                            [reversed]="column.reversed"
                                        ></fi-grid-sort-icon>
                                    }
                                </th>
                            }
                        }
                    </tr>
                </thead>
            </table>
        </div>
    }
</fi-grid-sticky-header>

<div
    class="fi-grid-table__wrapper"
    [class.fi-grid-table__wrapper--with-loading-data]="
        isDataPresent && isLoading
    "
>
    <table
        [fiVariation]="variation"
        [fiVariationBase]="'fi-grid-table'"
        (touchstart)="tableTouchStart($event)"
        (touchend)="tableTouchEnd($event)"
        (focusWithin)="handleFocusWithin($event)"
        class="fi-grid-table--items"
        #table
    >
        @if (!isInitializing) {
            <!-- This row is needed for check columns width-->
            <ng-container [ngTemplateOutlet]="noHeightTableRow"></ng-container>
            <!-- @todo: remove after implementation nested tables-->
            @for (
                group of rows | gridGroupRows: groupBy : columns;
                track trackByIndex($index, group)
            ) {
                @if (groupBy?.tpl && group.length) {
                    <tr class="fi-text fi-grid-table__row">
                        <td
                            class="fi-grid-table__cell fi-grid-table__cell--fixed"
                            [attr.colspan]="columns.length"
                        >
                            <ng-container
                                [ngTemplateOutlet]="groupBy.tpl"
                                [ngTemplateOutletContext]="{ items: group }"
                            ></ng-container>
                        </td>
                    </tr>
                }
                @for (
                    row of group;
                    track trackByIndex(rowIndex, row);
                    let rowIndex = $index
                ) {
                    <ng-container
                        [fiGridExpandingRow]="hasExpandingRow && row"
                        #fiGridExpandingRow="fiGridExpandingRow"
                    >
                        <tr
                            [ngClass]="{
                                'fi-grid-table__row--invalid': row.rowInvalid,
                            }"
                            class="fi-text fi-grid-table__row"
                            #tr
                        >
                            @for (
                                column of columns;
                                track trackByColumnFieldName($index, column)
                            ) {
                                @if (!column.asRow) {
                                    <td
                                        [style.paddingLeft.px]="
                                            column.expander && !row.hasChildren
                                                ? expanderWidth
                                                : ''
                                        "
                                        [fiVariation]="[
                                            column.field,
                                            'data',
                                            column.flexible && 'flex',
                                            (column.sticky ||
                                                column.stickyRight) &&
                                                'fixed',
                                            column.reversed && 'reversed',
                                            column.expander
                                                ? fiGridExpandingRow.isActive
                                                    ? 'open-folder'
                                                    : 'folder'
                                                : '',
                                        ]"
                                        [fiVariationBase]="
                                            'fi-grid-table__cell'
                                        "
                                        fi-grid-cell
                                        [fieldName]="column.field"
                                        [row]="row"
                                        [tpl]="column.cell"
                                    >
                                        @if (
                                            column.expander && row.hasChildren
                                        ) {
                                            <fi-grid-expand-icon
                                                [fiGridExpand]="isLoading"
                                                [openByDefault]="openByDefault"
                                                [isRowExpanded]="row.expanded"
                                                (fiGridExpandChange)="
                                                    handleExpandRow(
                                                        $event,
                                                        row[nestedDataKey],
                                                        row
                                                    )
                                                "
                                                [isActive]="
                                                    fiGridExpandingRow.isActive
                                                "
                                            ></fi-grid-expand-icon>
                                        }
                                    </td>
                                }
                            }
                        </tr>
                        <!-- -- Expandable nested rows -- -->
                        <ng-container
                            *ngTemplateOutlet="
                                nestedTpl;
                                context: {
                                    row: row,
                                    active: fiGridExpandingRow.isActive,
                                    level: 1,
                                }
                            "
                        ></ng-container>
                        <!-- -- End Expandable nested rows -- -->
                        @if (
                            [columns, row] | memoizeFunc: getRowForRowColumn;
                            as rowColumns
                        ) {
                            <tr
                                class="fi-text fi-grid-table__row fi-grid-table__row--nested"
                                #tr
                            >
                                @for (
                                    column of rowColumns;
                                    track trackByColumnFieldName($index, column)
                                ) {
                                    @if (
                                        column.asRow ||
                                        column.sticky ||
                                        column.stickyRight
                                    ) {
                                        <td
                                            [attr.colspan]="
                                                column.asRow &&
                                                tableColumnsCount
                                            "
                                            [fiVariation]="[
                                                column.field,
                                                'data',
                                                (column.sticky ||
                                                    column.stickyRight) &&
                                                    'fixed',
                                            ]"
                                            [fiVariationBase]="
                                                'fi-grid-table__cell'
                                            "
                                            fi-grid-cell
                                            [fieldName]="
                                                column.asRow && column.field
                                            "
                                            [row]="row"
                                            [tpl]="column.asRow && column.cell"
                                        ></td>
                                    }
                                }
                            </tr>
                        }
                    </ng-container>
                }
            }
        }
    </table>

    @if (isLoading && !isInitializing && isDataPresent) {
        <fi-loader-penske></fi-loader-penske>
    }
</div>

@if (isInitializing) {
    <fi-grid-table-loader-skeleton></fi-grid-table-loader-skeleton>
}

<!-- Table zero height row to check column widths -->
<ng-template #noHeightTableRow>
    <tr class="fi-grid-table__row fi-grid-table__row--no-height">
        @for (column of columns; track trackByColumnFieldName($index, column)) {
            <td
                [fiVariation]="[
                    !column.asRow && column.field,
                    (column.sticky || column.stickyRight) && 'fixed',
                ]"
                [fiVariationBase]="'fi-grid-table__cell'"
            ></td>
        }
    </tr>
</ng-template>

<ng-template #nestedTpl let-row="row" let-active="active" let-level="level">
    @if (
        [row, nestedRows] | memoizeFunc: getPureNestedRows : this;
        as nestedRowsMap
    ) {
        @for (
            nestedRow of [active, nestedRowsMap.data]
                | memoizeFunc: getNestedRows;
            track trackByIndex($index, nestedRow)
        ) {
            <ng-container
                [fiGridExpandingRow]="nestedRow && nestedRow.hasChildren"
                #fiGridExpandingRow="fiGridExpandingRow"
            >
                <tr
                    class="fi-text fi-grid-table__row fi-grid-table__row--nested"
                    #tr
                >
                    @for (
                        column of columns;
                        track trackByColumnFieldName($index, column)
                    ) {
                        @if (active) {
                            <td
                                [style.paddingLeft.px]="
                                    column.expander &&
                                    ([nestedRow.hasChildren, level]
                                        | memoizeFunc: getIndent)
                                "
                                [fiVariation]="[
                                    column.field,
                                    'data',
                                    column.flexible && 'flex',
                                    (column.sticky || column.stickyRight) &&
                                        'fixed',
                                    column.expander
                                        ? fiGridExpandingRow.isActive
                                            ? 'open-folder'
                                            : 'folder'
                                        : '',
                                ]"
                                [fiVariationBase]="'fi-grid-table__cell'"
                                fi-grid-cell
                                [fieldName]="column.field"
                                [row]="nestedRow"
                                [tpl]="column.expandedCell"
                            >
                                @if (column.expander && nestedRow.hasChildren) {
                                    <fi-grid-expand-icon
                                        [fiGridExpand]="isLoading"
                                        [openByDefault]="openByDefault"
                                        [isRowExpanded]="nestedRow.expanded"
                                        (fiGridExpandChange)="
                                            handleExpandRow(
                                                $event,
                                                nestedRow[nestedDataKey],
                                                nestedRow
                                            )
                                        "
                                        [isActive]="fiGridExpandingRow.isActive"
                                    ></fi-grid-expand-icon>
                                }
                            </td>
                        }
                    }
                </tr>
                <ng-container
                    *ngTemplateOutlet="
                        nestedTpl;
                        context: {
                            row: nestedRow,
                            active: fiGridExpandingRow.isActive,
                            level: level + 1,
                        }
                    "
                ></ng-container>
            </ng-container>
        }
        @if (
            nestedRowsMap.initializing ||
            (nestedRowsMap.loading && !nestedRowsMap.data?.length)
        ) {
            @for (h of 2 | toNumArray; track h) {
                <tr class="fi-grid-table__row" #tr>
                    <td
                        class="fi-grid-table__nested-skeleton-cell fi-grid-table__cell fi-grid-table__cell--fixed"
                    >
                        @if (active) {
                            <fi-skeleton-element></fi-skeleton-element>
                        }
                    </td>
                    <td
                        [attr.colspan]="tableAllColumnsCount - 1"
                        class="fi-grid-table__nested-skeleton-cell fi-grid-table__cell fi-grid-table__cell--fixed"
                    >
                        @if (active) {
                            <fi-skeleton-element></fi-skeleton-element>
                        }
                    </td>
                </tr>
            }
        }
        @if (active && nestedRowsMap.data?.length) {
            @if (
                [nestedRowsMap.data, row[nestedDataKey]]
                    | memoizeFunc: hasLoadMoreBtn : this
            ) {
                <tr>
                    <td
                        [attr.colspan]="tableAllColumnsCount"
                        class="fi-grid-table__nested-paginator-cell"
                    >
                        <fi-grid-nested-paginator
                            class="fi-grid-table__nested-paginator"
                            [row]="row"
                            [nestedDataValue]="row[nestedDataKey]"
                        ></fi-grid-nested-paginator>
                    </td>
                </tr>
            }
        }
        @if (active && !nestedRowsMap.loading && !nestedRowsMap.data?.length) {
            <tr class="fi-grid-table__row">
                <td
                    [attr.colspan]="tableAllColumnsCount"
                    class="fi-grid-table__cell fi-grid-table__cell--fixed"
                >
                    <div
                        class="fi-grid-table__nested-no-data fi-text fi-text--medium"
                    >
                        <fi-icon
                            class="fi-grid-table__nested-no-data-icon"
                            [svgName]="'file-alt-slash'"
                            [variation]="'light'"
                            [size]="'42x'"
                        ></fi-icon>
                        @if (noNestedDataPlaceholderDef) {
                            <ng-container
                                [ngTemplateOutlet]="noNestedDataPlaceholderDef"
                            ></ng-container>
                        } @else {
                            There is no data to display
                        }
                    </div>
                </td>
            </tr>
        }
    }
</ng-template>
