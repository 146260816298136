<div
    [fiVariation]="variation"
    [fiVariationBase]="'fi-date-range'"
    class="fi-date-range"
>
    @if (openInDropdown) {
        <div
            (mousedown)="toggleDropdownDatePicker()"
            role="button"
            aria-label="Date picker"
            class="fi-date-range__dropdown"
        >
            <span
                [ngClass]="selected ? 'fi-text--dark' : 'fi-text--light'"
                class="fi-date-range__dropdown-value fi-text fi-text--small"
            >
                <span class="fi-date-range__dropdown-date">
                    {{
                        selected
                            ? (selected.from | timeFormat: 'SHORT_DATE')
                            : dateRangeModesTitle.From
                    }}
                </span>

                <span class="fi-date-range__dropdown-dash"></span>

                <span class="fi-date-range__dropdown-date">
                    {{
                        selected
                            ? (selected.to | timeFormat: 'SHORT_DATE')
                            : dateRangeModesTitle.To
                    }}
                </span>
            </span>

            <fi-icon
                [svgName]="'calendar-alt'"
                [size]="'large'"
                class="fi-date-range__dropdown-icon"
            ></fi-icon>
        </div>

        <fi-simple-dropdown
            *fiIsDesktopDevice
            [(open)]="isDropdownOpen"
            [relativeTo]="elementRef"
            (openChange)="dropdownOpenChange()"
        >
            <ng-template>
                <div class="fi-date-range__dropdown-picker">
                    <ng-container [ngTemplateOutlet]="content"></ng-container>

                    <div
                        [class.fi-date-range__dropdown-footer--hidden]="
                            currentMonthSelected
                        "
                        class="fi-date-range__dropdown-footer"
                    >
                        <span
                            (click)="goToCurrentMonth()"
                            role="button"
                            aria-label="Current Month"
                            class="fi-text fi-text--medium fi-text--link"
                        >
                            Current Month
                        </span>
                    </div>
                </div>
            </ng-template>
        </fi-simple-dropdown>

        <ng-container
            *fiIsMobileDevice
            [ngTemplateOutlet]="modal"
        ></ng-container>
    } @else {
        @if ((selected | memoizeFunc: hasBothDates) && showDatesInfo) {
            <fi-pill
                [variation]="'auto-height'"
                (pillClick)="handleReset($event)"
                class="fi-date-range__selected"
            >
                {{ selected.from | timeFormat: 'SHORT_DATE' }}
                -
                {{ selected.to | timeFormat: 'SHORT_DATE' }}
            </fi-pill>
        }

        <ng-container [ngTemplateOutlet]="modal"></ng-container>
    }
</div>

<ng-template #content>
    <fi-date-range-tabs
        [active]="selectionMode"
        [tabs]="selectedState"
        [highlighted]="changedMode$ | async"
        [fromTitle]="fromTitle"
        [toTitle]="toTitle"
        (change)="changeRangeTab($event)"
    ></fi-date-range-tabs>

    <div class="fi-date-range__calendar">
        <fi-calendar
            [allDatesSelectable]="true"
            [startDate]="
                !!selectedState.from &&
                (selectedState | memoizeFunc: getDayjsStartDate : this)
            "
            [selectedDates]="selectedState | memoizeFunc: getSelectedDates"
            [minDateAvailable]="availableDateRange?.from"
            [maxDateAvailable]="
                [
                    availableDateRange?.to,
                    historicalOnly,
                    selectedState.from,
                    selectionMode
                ] | memoizeFunc: getMaxAvailableDate : this
            "
            [warnBeforeDate]="
                [selectedState, selectionMode]
                    | memoizeFunc: getToPriorFromWarningDate
            "
            [warnBeforeDateMessage]="toPriorFromWarningMessage"
            [keepCurrentMonth]="keepCurrentMonth"
            (selectDate)="handleSelectedDate($event)"
            (monthChange)="handleChangedMonth($event)"
        ></fi-calendar>
    </div>
</ng-template>

<ng-template #footer>
    <div class="fi-date-range__footer">
        @if (!currentMonthSelected) {
            <span
                (click)="goToCurrentMonth()"
                role="button"
                aria-label="Current Month"
                class="fi-text fi-text--medium fi-text--link"
            >
                Current Month
            </span>
        }

        <div class="fi-date-range__footer-controls">
            @if (showCancelButton) {
                <fi-button
                    [ariaLabel]="cancelButtonTitle"
                    [variation]="'secondary'"
                    (click)="handleCancel()"
                >
                    {{ cancelButtonTitle }}
                </fi-button>
            }

            <fi-button
                [ariaLabel]="confirmButtonTitle"
                [variation]="'primary'"
                [disabled]="!(selectedState | memoizeFunc: hasBothDates)"
                (click)="handleSelect()"
            >
                {{ confirmButtonTitle }}
            </fi-button>
        </div>
    </div>
</ng-template>

<ng-template #modal>
    @if (isNewDateRangeModal) {
        <fi-date-range-modal
            [(open)]="isModalOpen"
            [dateRange]="dateRange"
            [availableDateRange]="availableDateRange"
            [maxDatesInRange]="maxDatesInRange"
            [singleDateMode]="singleDateMode"
            [oneDateSelectionAvailable]="oneDateSelectionAvailable"
            [currentMonthPosition]="currentMonthPosition"
            [fromTitle]="fromTitle"
            [toTitle]="toTitle"
            [toDateMessageInfo]="toDateMessageInfo"
            [modalTitle]="modalTitle"
            [rangeTitle]="rangeTitle"
            (dateRangeSelected)="onDateRangeSelected($event)"
        ></fi-date-range-modal>
    } @else {
        <ng-container *ngTemplateOutlet="oldDateRangeModal"></ng-container>
    }
</ng-template>

<!-- Will be removed when new modal is used everywhere across the app -->
<ng-template #oldDateRangeModal>
    <fi-modal
        [(open)]="isModalOpen"
        [variation]="
            (isMobileDevice$ | async) ? modalVariation : modalVariationDesktop
        "
        (event)="handleModalEvent($event)"
    >
        @if (isModalOpen) {
            <fi-modal-close-bar
                *fiIsMobileDevice
                [title]="modalTitle"
                (close)="closeDatePicker()"
            >
                <fi-button
                    [ariaLabel]="'Reset'"
                    [variation]="'squared,big,link,white-st-link'"
                    (click)="resetDates()"
                    class="fi-date-range__reset-btn"
                >
                    Reset
                </fi-button>
            </fi-modal-close-bar>

            <fi-modal-header *fiIsDesktopDevice (close)="closeDatePicker()">
                <h3 class="fi-heading fi-heading--tertiary fi-heading--dark">
                    {{ modalTitle }}
                </h3>
            </fi-modal-header>

            <fi-modal-content>
                <ng-container [ngTemplateOutlet]="content"></ng-container>
            </fi-modal-content>

            <fi-modal-footer>
                <ng-container [ngTemplateOutlet]="footer"></ng-container>
            </fi-modal-footer>
        }
    </fi-modal>
</ng-template>
