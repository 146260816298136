import { environment } from '@environments/environment';

import { USER_ID_ERROR_CODE, ENTER_KEY } from './common';
const EMPTY_ERROR_MESSAGE = 'Please enter email address';
import { getEmailMaskValue, emailFieldFormat, MaskValue } from './email-format';
import { RecoverUserInfoAbstractController } from './recover-user-info.abstract.controller';

const VALID_ERROR_MESSAGE = 'Please enter a valid Email';

const NOT_FOUND_ERROR_MESSAGE =
    'We do not recognize your email address. Please try again or contact us at <a href="tel:8444264555">(844) 426-4555</a>.';

const EMAIL_REGEX =
    /^[-a-zA-Z0-9#$%_]+(\.[-a-zA-Z0-9#$%_]+)*@(([a-zA-Z0-9]+|[a-zA-Z0-9][-a-zA-Z0-9]+[a-zA-Z0-9])\.)+(?!\d+$)[a-zA-Z0-9]{2,}$/;

export class RecoverUsernameController extends RecoverUserInfoAbstractController {
    private modalElement = document.getElementById(
        'recover-username-modal',
    ) as HTMLElement;

    private emailInputElement = document.getElementById(
        'recover-email-input',
    ) as HTMLInputElement;
    private errorElement = document.getElementById(
        'recover-email-error',
    ) as HTMLInputElement;
    private responseElement = document.getElementById(
        'recover-username-response',
    ) as HTMLInputElement;
    private sendBtn = document.getElementById('recover-username-send-btn');

    private maskFilledElement = document.querySelector(
        '.landing-input-mask__filled',
    );
    private placeholderFilledElement = document.querySelector(
        '.landing-input-mask__placeholder',
    );

    private prevValue = '';

    constructor() {
        super();
        this.handleCloseModal();
        this.attachEvents();
    }

    private attachEvents(): void {
        this.emailInputElement.addEventListener('input', ({ target }) => {
            this.updateMaskForInput(target as HTMLInputElement);
            this.hideInputError(target as HTMLInputElement);
        });

        this.emailInputElement.addEventListener('focus', () => {
            const { value } = this.emailInputElement;

            this.setMaskProperty(value);
        });

        this.emailInputElement.addEventListener('blur', () => {
            this.validateForm();
            this.updateMaskValue();
        });

        this.emailInputElement.addEventListener('keyup', (event) => {
            if (event.keyCode === ENTER_KEY) {
                this.emailInputElement.blur();
            }
        });

        this.sendBtn.addEventListener('click', () => {
            const isFormValid = this.validateForm();

            if (isFormValid) {
                const { value } = this.emailInputElement;
                const requestUrl = `${environment.externalConfig.PUBLIC_API_VERSION}us/en/public/${value}/forgotUserName`;

                this.sendRequest(requestUrl, this.sendCallback.bind(this));
            }
        });
    }

    private updateMaskForInput({ value = '' }: { value: string }): void {
        const res = emailFieldFormat(value, this.prevValue);

        if (res !== value) {
            this.emailInputElement.value = res;
        }

        this.prevValue = res;
        this.setMaskProperty(res);
    }

    private setMaskProperty(value: string): void {
        const { filled, placeholder } = getEmailMaskValue(value) as MaskValue;

        this.updateMaskValue(filled, placeholder);
    }

    private updateMaskValue(filled = '', placeholder = ''): void {
        this.maskFilledElement.innerHTML = filled;
        this.placeholderFilledElement.innerHTML = placeholder;
    }

    clearForm(): void {
        this.emailInputElement.value = '';
        this.hideInputError(this.emailInputElement);
        this.hideModalSuccess(this.modalElement);
    }

    private validateForm(): boolean {
        const isEmptyInput = this.handleEmptyInputState(this.emailInputElement);

        if (isEmptyInput) {
            return false;
        }

        return this.handleValidInputState(this.emailInputElement);
    }

    private handleEmptyInputState(element: HTMLInputElement): boolean {
        const isEmpty = this.isInputEmpty(element);

        if (isEmpty) {
            this.showMessage(this.errorElement, EMPTY_ERROR_MESSAGE);
            this.showInputError(element);
        } else {
            this.hideInputError(element);
        }

        return isEmpty;
    }

    private handleValidInputState(element: HTMLInputElement): boolean {
        const isValid = EMAIL_REGEX.test(element.value);

        if (isValid) {
            this.hideInputError(element);
        } else {
            this.showMessage(this.errorElement, VALID_ERROR_MESSAGE);
            this.showInputError(element);
        }

        return isValid;
    }

    private sendCallback(request: XMLHttpRequest): void {
        const { responseText, status, readyState } = request;

        if (!responseText) {
            return;
        }
        const data = JSON.parse(responseText);

        if (readyState === 4 && status === 200) {
            this.handleSuccess(data.email);
        }

        if (status === 400 && data.code === USER_ID_ERROR_CODE) {
            this.handleError();
        }
    }

    private handleSuccess(responseEmail: string): void {
        this.showModalSuccess(this.modalElement);
        this.showMessage(this.responseElement, responseEmail);
    }

    private handleError(): void {
        this.showMessage(this.errorElement, NOT_FOUND_ERROR_MESSAGE);
        this.showInputError(this.emailInputElement);
    }
}
