import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    inject,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { DEFAULT_MAP_ZOOM, NORTH_AMERICA_CENTER_ZOOM } from '../../constants';
import { OnChange } from '../../decorators';
import { LocationMapService } from '../../services';
import { resizeElement } from '../../utils';
import {
    AdvancedMapMarker,
    createAdvancedMapMarker,
    createAdvancedMapMarkerPopup,
    LocationMapMarker,
    LocationMapMarkerIcon,
    MAP_MARKER_SIZES,
    MapMarkerPopup,
    MapMarkerType,
} from '../map-marker';
import {
    TooltipComponent,
    TooltipVariation,
} from '../tooltip/tooltip.component';

import { LocationMapDraggableCursor } from './constants';
import { LocationMapOptions } from './models';

@UntilDestroy()
@Component({
    selector: 'fi-location-map',
    templateUrl: './location-map.component.html',
    styleUrls: ['./location-map.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TooltipComponent],
})
export class LocationMapComponent implements OnInit, AfterViewInit, OnDestroy {
    private readonly locationMapService = inject(LocationMapService);
    private readonly changeDetectorRef = inject(ChangeDetectorRef);

    @OnChange('handleLocationMapOptionsChange')
    @Input()
    options: LocationMapOptions;

    @Output() markerClicked = new EventEmitter<void>();
    @Output() markerHovered = new EventEmitter<void>();

    @ViewChild('mapContainer', { static: true })
    mapContainer: ElementRef<HTMLElement>;
    @ViewChild('markerPopupContainer', { read: ElementRef })
    markerPopupContainer: ElementRef;

    readonly markerPopupVariation = TooltipVariation.MapMarker;

    markerPopupAvailable = false;

    private map: google.maps.Map;
    private marker: AdvancedMapMarker;
    private markerPopup: MapMarkerPopup;

    ngOnInit(): void {
        this.appendMap();
    }

    ngAfterViewInit(): void {
        resizeElement(this.mapContainer.nativeElement)
            .pipe(untilDestroyed(this))
            .subscribe(() => this.refreshCenter());
    }

    ngOnDestroy(): void {
        this.destroyMarker();
        this.markerPopup && this.markerPopup.setMap(null);
        google.maps.event.clearInstanceListeners(this.map);
    }

    handleLocationMapOptionsChange(): void {
        if (this.options.mapCenterOffset) {
            this.updateMapCenterOffset();
        }

        if (this.options.iconType) {
            this.addCenterMarker();
        }
    }

    private appendMap(): void {
        const center = this.locationMapService.getLatLng(this.options.center);
        const { draggableCursor, mapId } = this.options;

        this.map = this.locationMapService.getMap(
            this.mapContainer.nativeElement,
            {
                mapId,
                center,
                draggableCursor,
                disableDefaultUI: true,
                gestureHandling: 'none',
                scrollwheel: false,
                disableDoubleClickZoom: true,
                keyboardShortcuts: false,
            },
        );

        this.map.addListener('center_changed', () =>
            this.handleMapCenterChanged(),
        );
        this.addCenterMarker();
    }

    private handleMapCenterChanged(): void {
        if (this.options.zoom) {
            this.map.setZoom(this.options.zoom);
        } else if (this.locationMapService.isNorthAmericaCenter(this.map)) {
            this.destroyMarker();
            this.map.setZoom(NORTH_AMERICA_CENTER_ZOOM);
        } else {
            this.map.setZoom(DEFAULT_MAP_ZOOM);
        }
    }

    private updateMapCenterOffset(
        x: number = this.options.mapCenterOffset.x,
        y: number = this.options.mapCenterOffset.y,
    ): void {
        if (!this.map || !this.map.getProjection()) {
            return;
        }

        const latLng = this.marker.position;
        const point1 = this.map.getProjection().fromLatLngToPoint(latLng);
        const currentZoomTiles = Math.pow(2, this.map.getZoom());
        const point2 = new google.maps.Point(
            x / currentZoomTiles,
            y / currentZoomTiles,
        );

        this.map.setCenter(
            this.map
                .getProjection()
                .fromPointToLatLng(
                    new google.maps.Point(
                        point1.x - point2.x,
                        point1.y + point2.y,
                    ),
                ),
        );
    }

    private refreshCenter(): void {
        if (!this.map) {
            return;
        }

        this.map.setCenter(this.marker.position);

        if (this.options.mapCenterOffset) {
            this.map.addListener('projection_changed', () =>
                this.updateMapCenterOffset(),
            );
            this.updateMapCenterOffset();
        }

        this.resizeMap();
    }

    private resizeMap(): void {
        setTimeout(() => {
            this.locationMapService.trigger(this.map, 'resize');
        }, 0);
    }

    private destroyMarker(): void {
        if (this.marker) {
            this.marker.destroyFI();
            this.marker = null;
        }
    }

    private addCenterMarker(): void {
        if (!this.map) {
            return;
        }

        this.destroyMarker();

        const {
            center: position,
            iconType = LocationMapMarkerIcon.ThirdParty,
            type = MapMarkerType.Location,
            popupSettings,
            markerSizes = MAP_MARKER_SIZES[type],
            draggableCursor,
        } = this.options;

        const clickable =
            draggableCursor === LocationMapDraggableCursor.Pointer;

        const config: LocationMapMarker = {
            id: null,
            type,
            position,
            iconType,
            clickable,
            isHovered: false,
            isSelected: true,
            markerSizes,
        };

        this.marker = createAdvancedMapMarker<LocationMapMarker>(
            this.map,
            config,
            () => this.markerHovered.emit(),
            () => this.markerClicked.emit(),
        );

        this.refreshCenter();

        this.markerPopupAvailable = false;

        if (popupSettings) {
            this.renderMarkerPopup();
        }
    }

    private renderMarkerPopup(): void {
        this.markerPopupAvailable = true;
        this.changeDetectorRef.detectChanges();

        this.markerPopup = createAdvancedMapMarkerPopup(
            this.marker,
            this.markerPopupContainer.nativeElement,
            this.options.popupSettings.callback,
        );
        this.markerPopup.setMap(this.map);
    }
}
