import { environment } from '@environments/environment';

import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpHeaders,
} from '@angular/common/http';

@Injectable()
export class DefaultHeadersInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const headers = new HttpHeaders({
            'x-ibm-client-id': environment.externalConfig['X-IBM-CLIENT-ID'],
        });

        return next.handle(req.clone({ headers }));
    }
}
