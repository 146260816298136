import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { OnChange } from '../../../decorators';
import { InputFormatType } from '../input.component';

import {
    getEmptyMaskValue,
    FiInputMaskBase,
    FiInputMaskValue,
    PhoneInputMask,
    EmailInputMask,
    CustomerNumberInputMask,
    DateInputMask,
    SsoIdMask,
    DateSecondaryInputMask,
    DistrictInputMask,
} from './masks';

@Component({
    selector: 'fi-input-mask',
    templateUrl: './input-mask.component.html',
    styleUrls: ['./input-mask.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class InputMaskComponent {
    @Input() format: InputFormatType;

    @OnChange('onValueChange')
    @Input()
    value: string;

    maskValue: FiInputMaskValue = getEmptyMaskValue();

    onValueChange(currentValue: string): void {
        const maskType = this.getMaskType();

        if (maskType) {
            this.maskValue = maskType.getValue(currentValue);
            return;
        }

        this.maskValue = getEmptyMaskValue();
    }

    private getMaskType(): FiInputMaskBase | null {
        switch (this.format) {
            case InputFormatType.Email:
                return new EmailInputMask();
            case InputFormatType.Phone:
                return new PhoneInputMask();
            case InputFormatType.CustomerNumber:
                return new CustomerNumberInputMask();
            case InputFormatType.SsoId:
                return new SsoIdMask();
            case InputFormatType.Date:
                return new DateInputMask();
            case InputFormatType.DateSecondary:
                return new DateSecondaryInputMask();
            case InputFormatType.District:
                return new DistrictInputMask();
            default:
                return null;
        }
    }
}
