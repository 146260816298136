import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    Optional,
} from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { isEmpty } from 'lodash';

import { VariationDirective } from '../../../directives';
import { LoaderPenskeComponent } from '../../../components';
import { GuidedTourStepDirective } from '../../guided-tour';
import { GridGroupRowsPipe } from '../pipes/group-rows.pipe';
import { GridColumnDefDirective } from '../column/column-def.directive';
import { GridDataSourceDirective } from '../data-source';
import { GridGroupDefDirective } from '../group/group-def.directive';
import { GridHeaderCellComponent } from '../header-cell/header-cell.component';
import { GridCellComponent } from '../cell/cell.component';
import { GridRow } from '../models';

import { GridListLoaderSkeletonComponent } from './loader-skeleton/loader-skeleton.component';

@UntilDestroy()
@Component({
    selector: 'fi-grid-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgTemplateOutlet,
        GuidedTourStepDirective,
        VariationDirective,
        GridGroupRowsPipe,
        GridHeaderCellComponent,
        GridListLoaderSkeletonComponent,
        LoaderPenskeComponent,
        GridCellComponent,
    ],
})
export class GridListComponent implements OnInit {
    @Input() columns: GridColumnDefDirective[];
    @Input() groupBy: GridGroupDefDirective;
    @Input() guidedTourMobileStep: Record<string, string | number>;

    rows: GridRow[];
    isInitializing = true;
    isLoading = false;
    isDataPresent = false;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        @Optional() private dataSource: GridDataSourceDirective,
    ) {}

    ngOnInit(): void {
        if (!this.dataSource) {
            return;
        }

        this.dataSource.stateChanged$
            .pipe(untilDestroyed(this))
            .subscribe(() => this.updateState());

        this.updateState();
    }

    trackByColumnFieldName(_: number, column: GridColumnDefDirective): string {
        return column.field;
    }

    private updateState(): void {
        const {
            source: { data },
            isInitializing,
            isLoading,
        } = this.dataSource;

        this.rows = data;
        this.isInitializing = isInitializing;
        this.isLoading = isLoading;
        this.isDataPresent = !isEmpty(data);

        this.changeDetectorRef.markForCheck();
    }
}
