import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';

import { defer, Observable, Subject } from 'rxjs';
import { finalize, map, mapTo, tap } from 'rxjs/operators';
import { identity, isEmpty } from 'lodash';

const DEFAULT_MIME_TYPE = 'pdf';

export const MIME_TYPES: { [type: string]: string } = {
    xls: 'application/xls',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    pdf: 'application/pdf',
    zip: 'application/octet-stream',
    csv: 'text/csv',
};

export interface DownloadFileRequest {
    [key: string]: any;
}

export interface DownloadFileRequestData {
    body: DownloadFileRequest | null;
    params?: DownloadFileHttpParams | null;
}

export type DownloadFileRequestParams = DownloadFileHttpParams &
    DownloadFileRequest;

export type DownloadFileHttpParams =
    | HttpParams
    | { [param: string]: string | string[] };

export interface DownloadFileOutputParams {
    type: string;
    name: string;
}

export type DownloadFileMethod = 'GET' | 'POST';

@Injectable({
    providedIn: 'root',
})
export class DownloadFileService {
    readonly inProgress$: Observable<boolean> = defer(() => this.inProgress);

    private readonly URL: typeof URL = window.URL || (window as any).webkitURL;
    private readonly documentBody = this.document.body;

    private readonly requestOptions = {
        observe: 'response',
        responseType: 'arraybuffer',
    } as const;

    private renderer: Renderer2;
    private readonly inProgress: Subject<boolean> = new Subject();

    constructor(
        private rendererFactory: RendererFactory2,
        @Inject(DOCUMENT) private document: Document,
        private httpClient: HttpClient,
    ) {
        this.renderer = this.rendererFactory.createRenderer(null, null);
        this.inProgress$ = this.inProgress.asObservable();
    }

    /**
     * Download file using GET method
     *
     * @param url    Endpoint url
     * @param params Params for GET call (URL params)
     * @param output Default parameters for output file.
     * The service will try to use data from the server:
     * for type 'content-type' header or mimeTypes config will be used. Default is 'pdf'
     * for name: 'content-disposition' in format '...; filename={target name with extension}'.
     * @todo: output param can be excess - it is used as a fallback in case of server error. Remove it if needed.
     * @param method HTTP method
     * @param headers HTTP headers
     *
     *
     * @returns Observable for loading file
     */
    start(
        url: string,
        params: DownloadFileRequestParams,
        output: DownloadFileOutputParams,
        method?: 'GET',
        headers?: HttpHeaders,
    ): Observable<void>;

    /**
     * Download file using POST method
     *
     * @param url    Endpoint url
     * @param data   Data with body and params for POST call
     * @param output Default parameters for output file.
     * The service will try to use data from the server:
     * for type 'content-type' header or mimeTypes config will be used. Default is 'pdf'
     * for name: 'content-disposition' in format '...; filename={target name with extension}'.
     * @todo: output param can be excess - it is used as a fallback in case of server error. Remove it if needed.
     * @param method HTTP method
     * @param headers HTTP headers
     *
     *
     * @returns Observable for loading file
     */
    start(
        url: string,
        data: DownloadFileRequestData,
        output: DownloadFileOutputParams,
        method: 'POST',
        headers?: HttpHeaders,
    ): Observable<void>;

    start(
        url: string,
        data: DownloadFileRequestParams | DownloadFileRequestData,
        output: DownloadFileOutputParams,
        method: DownloadFileMethod = 'GET',
        headers: HttpHeaders = new HttpHeaders(),
    ): Observable<void> {
        let request: Observable<HttpResponse<ArrayBuffer>>;

        switch (method) {
            case 'GET':
                request = this.sendGetRequest(
                    url,
                    data as DownloadFileRequestParams,
                    headers,
                );
                break;
            case 'POST':
                request = this.sendPostRequest(
                    url,
                    data as DownloadFileRequestData,
                    headers,
                );
                break;
            default:
                throw new Error('Only GET or POST method allowed');
        }

        this.startProgress();

        return request.pipe(
            map((response) => this.createObjectOfResponse(output, response)),
            tap((result) => {
                this.openWithName(result);
                this.revokeUrlObject(result.url);
            }),
            mapTo(null),
            finalize(() => this.stopProgress()),
        );
    }

    /*
        Download file on IOS is triggered with openning an api
        in a new tab.
        IMPORTANT: Api url must has:
        1. application-type/octet-stream header
        2. WildCard at the end to make iOS save file with custom name(which is just printed at the end)
        For example: ${apiname} should be called if Front-end calls ${apiname}/name-needed.type-of-file
    */
    startIOS(url: string, params: { [key: string]: any } = {}): void {
        const query: URLSearchParams = new URLSearchParams();
        Object.keys(params).forEach((key) => {
            query.set(key, params[key]);
        });

        window.open(`${url}?${query}`, '_blank');
    }

    preview(url: string): Observable<Blob> {
        return this.httpClient
            .get(url, {
                observe: 'response',
                responseType: 'arraybuffer',
            })
            .pipe(
                map(({ headers, body }) => {
                    const blobType = this.getMimeTypeFromHeaders(headers);
                    const blob = this.createBlob(body, blobType);

                    return blob;
                }),
            );
    }

    downloadDecodedFile(
        encodedString: string,
        fileName = '',
        fileType = MIME_TYPES.xlsx,
    ): void {
        const blob = this.createBlob(
            this.stringToArrayBuffer(encodedString),
            fileType,
        );

        this.openObjectUrlWithName(window.URL.createObjectURL(blob), fileName);
    }

    private sendGetRequest(
        url: string,
        params: DownloadFileRequestParams,
        headers: HttpHeaders = new HttpHeaders(),
    ): Observable<HttpResponse<ArrayBuffer>> {
        return this.httpClient.get(url, {
            ...this.requestOptions,
            params,
            headers,
        });
    }

    private sendPostRequest(
        url: string,
        { body, params }: DownloadFileRequestData,
        headers: HttpHeaders = new HttpHeaders(),
    ): Observable<HttpResponse<ArrayBuffer>> {
        return this.httpClient.post(url, body, {
            ...this.requestOptions,
            ...(!isEmpty(params) && { params }),
            headers,
        });
    }

    private createObjectOfResponse(
        output: DownloadFileOutputParams,
        response: HttpResponse<ArrayBuffer>,
    ): {
        name: string;
        url: string;
        blob: Blob;
    } {
        const blobType = this.getMimeType(response.headers, output.type);
        const blob = this.createBlob(response.body, blobType);

        return {
            name: this.getNameFromHeaders(
                response.headers,
                `${output.name}.${output.type}`,
            ),
            url: this.createObjectURL(blob),
            blob,
        };
    }

    private getNameFromHeaders(
        headers: HttpHeaders,
        defaultName: string,
    ): string {
        const contentDisposition = headers.get('content-disposition') || '';
        const nameFromServer = contentDisposition.split('filename=')[1];

        return nameFromServer || defaultName;
    }

    private openObjectUrlWithName(url: string, name: string): void {
        const linkElement = this.renderer.createElement('a');
        linkElement.href = url;
        linkElement.download = name;
        linkElement.target = '_blank';

        linkElement.setAttribute('data-click-outside-policy', 'skip');

        this.documentBody.appendChild(linkElement);

        linkElement.click();
        this.documentBody.removeChild(linkElement);
    }

    private openBlobWithName(blob: any, name: string): void {
        (window.navigator as any).msSaveOrOpenBlob(blob, name); // TODO ANGULAR: non-supported in TS navigator method
    }

    private openWithName(result: any): void {
        this.openObjectUrlWithName(result.url, result.name);
    }

    private createBlob(data: any, type: string): Blob {
        return new Blob([data], { type });
    }

    private getMimeType(headers: HttpHeaders, type: string): string {
        return (
            this.getMimeTypeFromHeaders(headers) ||
            this.getMimeTypeFromConfig(type)
        );
    }

    private getMimeTypeFromHeaders(headers: HttpHeaders): string {
        return headers.get('content-type');
    }

    private getMimeTypeFromConfig(type: string): string {
        return MIME_TYPES[type] || MIME_TYPES[DEFAULT_MIME_TYPE];
    }

    private createObjectURL(blob: Blob): string {
        const createObjectURL = this.URL.createObjectURL || identity;
        return createObjectURL(blob);
    }

    private revokeUrlObject(url: string): void {
        const revokeObjectURL = this.URL.revokeObjectURL || identity;
        return revokeObjectURL(url);
    }

    private stringToArrayBuffer(base64Response: string): ArrayBuffer {
        const decodedString = atob(base64Response);
        const buf = new ArrayBuffer(decodedString.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i !== base64Response.length; ++i) {
            // eslint-disable-next-line no-bitwise
            view[i] = decodedString.charCodeAt(i) & 0xff;
        }

        return buf;
    }

    private startProgress(): void {
        this.inProgress.next(true);
    }

    private stopProgress(): void {
        this.inProgress.next(false);
    }
}
