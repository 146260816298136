import { Directive, OnInit } from '@angular/core';

import { IsMobileDeviceDirective } from './is-mobile-device.directive';

@Directive({
    selector: '[fiIsDesktopDevice]',
    standalone: true,
})
export class IsDesktopDeviceDirective
    extends IsMobileDeviceDirective
    implements OnInit
{
    ngOnInit(): void {
        this.createViewSelector(this.environmentService.isDesktopDevice$);
    }
}
