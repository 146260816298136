import {
    ChangeDetectorRef,
    Directive, inject,
    OnInit,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { NgIf } from '@angular/common';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Observable } from 'rxjs';

import { EnvironmentService } from '../services/environment.service';

@UntilDestroy()
@Directive({
    selector: '[fiIsMobileDevice]',
    standalone: true,
    providers: [NgIf],
})
export class IsMobileDeviceDirective implements OnInit {
    private readonly templateRef = inject(TemplateRef<any>);
    private readonly viewContainer = inject(ViewContainerRef);
    private readonly changeDetectorRef = inject(ChangeDetectorRef);
    protected readonly environmentService = inject(EnvironmentService);

    private ngIfDirective: NgIf;

    constructor(
    ) {
        this.initialize();
    }

    ngOnInit(): void {
        this.createViewSelector(this.environmentService.isMobileDevice$);
    }

    protected createViewSelector(observable: Observable<boolean>): void {
        observable.pipe(untilDestroyed(this)).subscribe((showView) => {
            if (showView) {
                this.attachTemplate();
                return;
            }

            this.detachTemplate();
        });
    }

    private initialize(): void {
        this.ngIfDirective = new NgIf(this.viewContainer, this.templateRef);
    }

    private attachTemplate(): void {
        this.ngIfDirective.ngIf = true;
        this.changeDetectorRef.markForCheck();
    }

    private detachTemplate(): void {
        this.ngIfDirective.ngIf = false;
    }
}
