import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'fi-radio-field',
    templateUrl: './radio-field.component.html',
})
export class RadioFieldComponent {
    @Input() formGroup: UntypedFormGroup;
    @Input() value: any;
    @Input() name: string;
    @Input() disabled: boolean;

    get currentValue() {
        return this.formGroup.controls[this.name].value;
    }

    handleChange(): void {
        const control = this.formGroup.controls[this.name];

        control.setValue(this.value);
        control.markAsDirty();
    }
}
