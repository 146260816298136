import { Input, Directive, TemplateRef, inject } from '@angular/core';

const enum DraggableItemVariation {
    Selected = 'selected',
    NoArrows = 'no-arrows',
    NoStyling = 'no-styling',
    WithArrowsPlaceholder = 'with-arrows-placeholder'
}

@Directive({
    selector: '[fiDraggableListItem]',
    standalone: true,
})
export class DraggableListItemDirective {
    public readonly template = inject(TemplateRef<any>);

    @Input('fiDraggableListItemVariation') variation: DraggableItemVariation;
    @Input('fiDraggableListItemArrowsPlaceholder') arrowsPlaceholder: string;
}
