<span
    [fiVariationBase]="'unit-title'"
    [fiVariation]="variation"
>
    {{ title }}

    @if (isConnectedIconShown) {
        <fi-icon
            [svgName]="'connected-unit'"
            [size]="iconSize"
            [variation]="'custom'"
            class="unit-title__icon"
        ></fi-icon>
    }
</span>

