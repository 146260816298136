import { Component, ViewEncapsulation, Input } from '@angular/core';

import { VariationDirective } from '../../../../directives';
import { ModalFooterVariation } from '../../models';

@Component({
    selector: 'fi-modal-footer',
    templateUrl: './modal-footer.component.html',
    styleUrls: ['./modal-footer.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [VariationDirective],
})
export class ModalFooterComponent {
    @Input() variation: ModalFooterVariation;
}
