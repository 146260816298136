import { NgModule, NO_ERRORS_SCHEMA, Type } from '@angular/core';

import { IndicatorComponent } from './indicator.component';

const FEATURES: Array<Type<any> | any[]> = [IndicatorComponent];

@NgModule({
    imports: FEATURES,
    exports: FEATURES,
    schemas: [NO_ERRORS_SCHEMA],
})
export class IndicatorModule {}
