import { AsyncPipe } from '@angular/common';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { range } from 'lodash';
import { Observable } from 'rxjs';

@Component({
    selector: 'guided-tour-indicator',
    templateUrl: './guided-tour-indicator.component.html',
    styleUrls: ['./guided-tour-indicator.component.scss'],
    standalone: true,
    imports: [AsyncPipe],
})
export class GuidedTourIndicatorComponent implements OnInit {
    @Input() stepsQuantity = 0;
    @Input() activeStepIndex = 1;
    @Input('isMobileView') isMobileView$: Observable<boolean>;

    @Output() activeStepIndexChanged = new EventEmitter<number>();

    steps: number[];

    ngOnInit(): void {
        this.steps = this.getStepsRange(this.stepsQuantity);
    }

    changeActiveStepIndex(index: number): void {
        if (this.activeStepIndex !== index) {
            this.activeStepIndexChanged.emit(index);
        }
    }

    private getStepsRange(quantity: number): number[] {
        return range(1, quantity + 1);
    }
}
