@if (tpl) {
    <div [fiVariation]="variation" [fiVariationBase]="'fi-tooltip-v2'">
        <div class="fi-tooltip-v2__content">
            <ng-container
                [ngTemplateOutlet]="tpl"
                [ngTemplateOutletContext]="tplContext"
            ></ng-container>
        </div>
        <svg class="fi-tooltip-v2__tongue" width="12" height="12">
            <path d="M0 6l6 6 6-6z" />
            <path
                class="fi-tooltip-v2__tongue-border"
                stroke-linecap="round"
                d="M0 6l6 6M6 12l6-6"
            />
        </svg>
    </div>
}
