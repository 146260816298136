import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Optional,
    Output,
} from '@angular/core';
import { Router, ActivatedRoute, ActivationEnd } from '@angular/router';
import { AsyncPipe } from '@angular/common';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { distinctUntilKeyChanged, filter, map } from 'rxjs/operators';

import { EnvironmentService } from '../../../services';
import {
    SkeletonComponent,
    SkeletonElementComponent,
    PaginatorComponent,
    ButtonComponent,
} from '../../../components';
import { GridDataSourceDirective } from '../data-source/data-source.directive';
import { GridPagedResult } from '../store';
import { GridOnTabletService } from '../services/grid-on-tablet.service';

@UntilDestroy()
@Component({
    selector: 'fi-grid-paginator',
    templateUrl: './paginator.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AsyncPipe,
        SkeletonComponent,
        SkeletonElementComponent,
        PaginatorComponent,
        ButtonComponent,
    ],
})
export class GridPaginatorComponent implements OnInit {
    @Input() skipPersistence: boolean;

    @Output() pageNumberChange = new EventEmitter<void>();

    readonly isMobileView$ = this.gridOnTabletService.isMobileView$;
    readonly isDesktopView$ = this.gridOnTabletService.isDesktopView$;

    source: Pick<GridPagedResult, 'totalItems' | 'pageSize' | 'pageNumber'>;
    isInitializing = true;
    isLoading = false;
    canLoadMore = false;
    hasLoadMoreButton = false;

    isMobile: boolean;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private environmentService: EnvironmentService,
        private changeDetectorRef: ChangeDetectorRef,
        @Optional() private dataSource: GridDataSourceDirective,
        private gridOnTabletService: GridOnTabletService,
    ) {
        this.environmentService.isMobile$
            .pipe(untilDestroyed(this))
            .subscribe((isMobile: boolean) => (this.isMobile = isMobile));
    }

    ngOnInit(): void {
        if (!this.skipPersistence) {
            this.initStateParams();
        }

        if (!this.dataSource) {
            return;
        }

        this.dataSource.stateChanged$
            .pipe(untilDestroyed(this))
            .subscribe(() => this.updateState());

        this.updateState();
        this.subscribeToPageNumberChange();
    }

    handlePageNumberChange(pageNumber: number): void {
        if (!this.dataSource) {
            return;
        }

        if (this.isMobile || this.skipPersistence) {
            this.dataSource.setPage(pageNumber);
            return;
        }

        this.pageNumberChange.emit();
        this.goTo(pageNumber);
    }

    private subscribeToPageNumberChange(): void {
        this.router.events
            .pipe(
                filter(
                    (event): event is ActivationEnd =>
                        event instanceof ActivationEnd,
                ),
                map(({ snapshot }: ActivationEnd) => snapshot.queryParams),
                distinctUntilKeyChanged('page'),
                untilDestroyed(this),
            )
            .subscribe(({ page }) => {
                if (!page) {
                    return;
                }

                this.dataSource.setPage(parseInt(page, 10));
            });
    }

    private initStateParams(): void {
        const page = this.route.snapshot.queryParamMap.get('page');
        if (!page) {
            this.goTo(1);
        }
    }

    private updateState(): void {
        const {
            source: { pageSize, totalItems },
            isInitializing,
            isLoading,
        } = this.dataSource;
        let {
            source: { pageNumber },
        } = this.dataSource;

        /**
         * default initial pageNumber value to 1 instead of undefined
         */
        const originalPageNumber = this.source?.pageNumber ?? 1;

        this.isInitializing = isInitializing;
        this.isLoading = isLoading;

        /**
         * api returns pageNumber = 0 if nothing is found, which is not suitable for the front end
         * we always want to stay on the first page, even if there are no results
         */
        if (pageNumber === 0) {
            pageNumber = 1;
        }

        this.source = {
            pageNumber,
            pageSize,
            totalItems,
        };

        this.hasLoadMoreButton = this.source && totalItems > pageSize;
        this.canLoadMore = pageNumber * pageSize < totalItems;

        if (pageNumber !== originalPageNumber && !this.skipPersistence) {
            this.goTo(pageNumber);
        }

        this.changeDetectorRef.markForCheck();
    }

    private goTo(page: number): void {
        this.router.navigate(['.'], {
            relativeTo: this.route,
            queryParams: { page },
            queryParamsHandling: 'merge',
            replaceUrl: true,
            state: { preventScrollTopOnStateChange: true },
        });
    }
}
