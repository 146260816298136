import {
    Directive,
    AfterViewInit,
    ElementRef,
    Input,
    inject
} from '@angular/core';

import { EnvironmentService } from '../services';

const BASE_CLASS_NAME = `fi-custom-scroll`;

enum ScrollVariation {
    Light = 'light',
    Firefox = 'firefox',
}

@Directive({
    selector: '[fiScroll]',
    standalone: true,
})
export class CustomScrollDirective implements AfterViewInit {
    private readonly elRef = inject(ElementRef);
    protected readonly environmentService = inject(EnvironmentService);

    @Input() fiCustomScrollTheme: string;

    ngAfterViewInit(): void {
        const { isMacLike, isFireFox } = this.environmentService;
        const isLight = this.fiCustomScrollTheme === ScrollVariation.Light;

        if (isMacLike) {
            return;
        }

        this.addClassName(BASE_CLASS_NAME);

        if (isFireFox) {
            this.setClassName(ScrollVariation.Firefox);
        }

        if (isLight) {
            this.setClassName(ScrollVariation.Light);
        }
    }

    private addClassName(className: string): void {
        this.elRef.nativeElement.classList.add(className);
    }

    private setClassName(name: string): void {
        const className = `${BASE_CLASS_NAME}--${name}`;
        this.addClassName(className);
    }
}
