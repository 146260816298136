<fi-modal
    [open]="isModalOpened"
    [variation]="modalVariation | memoizeFunc: getModalVariation : this"
    [hasCustomSmallTabletAnimation]="hasCustomSmallTabletAnimation"
    (event)="handleModalEvent($event)"
    class="fi-select-modal"
>
    @if (isModalOpened) {
        @if (isDesktopView$ | async) {
            <fi-modal-header (close)="closeModal()">
                <h3 class="fi-heading fi-heading--tertiary fi-heading--dark">
                    {{ modalConfig?.title }}
                </h3>
            </fi-modal-header>
        } @else {
            <fi-modal-close-bar
                [closeIcon]="'chevron-left'"
                [title]="modalConfig?.title"
                (close)="closeModal()"
            >
                @if (
                    !(
                        isInitializing
                        | memoizeFunc: showNoDataPlaceholder : this
                    )
                ) {
                    <fi-button
                        [ariaLabel]="'Reset'"
                        [variation]="'squared,big,link,white-st-link'"
                        (click)="reset()"
                        class="fi-select-modal__reset-btn"
                    >
                        Reset
                    </fi-button>
                }
            </fi-modal-close-bar>
        }
        @if (!(isInitializing | memoizeFunc: showNoDataPlaceholder : this)) {
            <fi-modal-content-top
                [variation]="
                    modalConfig.showTotalDescription ? 'mobile-grey' : ''
                "
            >
                @if (!modalConfig.gridSearch) {
                    <form [formGroup]="form" class="fi-select-modal__form">
                        <fi-input
                            [variation]="'search'"
                            [name]="'search'"
                            [icon]="'search'"
                            [placeholder]="modalConfig?.searchPlaceholder"
                            [autocomplete]="'off'"
                            formControlName="search"
                            class="fi-select-modal__input"
                        ></fi-input>
                    </form>
                }
                @if (modalConfig.gridSearch) {
                    <fi-grid-search
                        [config]="gridSearchConfig"
                        [search]="gridSearch"
                        [variation]="'full-width'"
                        (searchChange)="handleGridSearch($event)"
                        class="fi-select-modal__search"
                    ></fi-grid-search>
                }
                @if (
                    (isMobileView$ | async) && modalConfig.showTotalDescription
                ) {
                    <div class="fi-select-modal__extra-label-wrapper">
                        <span class="fi-text fi-text--medium">
                            <span>Total:</span>
                            {{
                                [filteredItems?.length, currentSelected?.length]
                                    | memoizeFunc: getDescription : this
                            }}
                        </span>
                    </div>
                }
            </fi-modal-content-top>
        }
        <fi-modal-content class="fi-select-modal__list">
            <fi-infinite-scroll
                [class.fi-select-modal__scroll--full-height]="
                    !modalConfig.gridSearch
                "
                (scrolled)="handleLoadMoreItems()"
                class="fi-select-modal__scroll"
            >
                <fi-skeleton
                    [class.fi-select-modal__skeleton-list--skeleton]="
                        isInitializing
                    "
                    [isInitializing]="isInitializing"
                    class="fi-select-modal__skeleton-list"
                >
                    <!-- No Data Placeholder -->
                    @if (
                        isInitializing
                            | memoizeFunc: showNoDataPlaceholder : this
                    ) {
                        <ng-container
                            [ngTemplateOutlet]="noDataPlaceholderContent"
                        ></ng-container>
                    }
                    <!-- @todo: remove when investigate noDataPlaceholderContent-->
                    @if (noDataMessage && !filteredItems?.length) {
                        <p
                            class="fi-text fi-text--medium fi-select-modal__no-data"
                        >
                            {{ noDataMessage }}
                        </p>
                    }
                    <!-- End No Data Placeholder -->
                    @if (filteredItems?.length) {
                        <fi-selectable-list
                            [type]="modalConfig.type"
                            [variation]="modalConfig.listVariation || 'modal'"
                            [withCheck]="isMobileView$ | async"
                            [hideSelection]="modalConfig.hideSelection"
                            [items]="filteredItems"
                            [selected]="selected"
                            [multiple]="isMultipleSelection"
                            [codeShownAsSecondary]="
                                modalConfig.codeShownAsSecondary
                            "
                            [labelShownAsSecondary]="
                                modalConfig.labelShownAsSecondary
                            "
                            [templateShownAsSecondary]="
                                modalConfig.templateShownAsSecondary
                            "
                            [labelShowInfo]="modalConfig.labelShowInfo"
                            [virtualScroll]="!modalConfig.gridSearch"
                            [withoutSelection]="modalConfig.withoutSelection"
                            (selectItems)="onSelectItems($event)"
                            class="fi-select-modal__selectable-list"
                        ></fi-selectable-list>
                    }
                    <fi-skeleton-element
                        class="fi-select-modal__skeleton-wrapper"
                    >
                        <ng-container
                            *ngTemplateOutlet="skeleton"
                        ></ng-container>
                        <ng-container
                            *ngTemplateOutlet="skeleton"
                        ></ng-container>
                        <ng-container
                            *ngTemplateOutlet="skeleton"
                        ></ng-container>
                    </fi-skeleton-element>
                </fi-skeleton>
                @if (isInfiniteScrollLoading) {
                    <div class="fi-select-modal__loader">
                        <fi-icon
                            [svgName]="'temp-spinner'"
                            [size]="'x-large'"
                            class="fi-select-modal__loader-icon"
                        ></fi-icon>
                        <p
                            class="fi-select-modal__loader-message fi-text fi-text--medium"
                        >
                            {{ modalConfig.loaderLabel }}
                        </p>
                    </div>
                }
            </fi-infinite-scroll>
        </fi-modal-content>

        @if (isDesktopView$ | async) {
            <fi-modal-footer
                [class.fi-select-modal__footer-empty]="
                    isInitializing | memoizeFunc: showNoDataPlaceholder : this
                "
            >
                <fi-skeleton [isInitializing]="isInitializing">
                    @if (
                        !(
                            isInitializing
                            | memoizeFunc: showNoDataPlaceholder : this
                        )
                    ) {
                        <div class="fi-select-modal__footer">
                            <span class="fi-text fi-text--medium">
                                {{
                                    [
                                        filteredItems?.length,
                                        currentSelected?.length,
                                        items,
                                    ] | memoizeFunc: getDescription : this
                                }}
                            </span>
                            <div class="fi-select-modal__footer-control">
                                <fi-button
                                    [ariaLabel]="'Cancel'"
                                    [variation]="'secondary'"
                                    (click)="closeModal()"
                                >
                                    Cancel
                                </fi-button>
                                <fi-button
                                    [ariaLabel]="
                                        modalConfig?.primaryButtonLabel ||
                                        'Select'
                                    "
                                    [variation]="'primary'"
                                    [disabled]="!currentSelected.length"
                                    [ngClass]="
                                        modalConfig?.gtmClassNamePrimaryButton
                                    "
                                    (click)="onSelect()"
                                >
                                    {{
                                        modalConfig?.primaryButtonLabel ||
                                            'Select'
                                    }}
                                </fi-button>
                            </div>
                        </div>
                    }
                    <fi-skeleton-element
                        class="fi-select-modal__skeleton-element fi-select-modal__skeleton-element--footer"
                    ></fi-skeleton-element>
                </fi-skeleton>
            </fi-modal-footer>
        }

        @if (selectionChanged && isMobileView$ | async) {
            <fi-modal-footer @animatedFooter>
                <fi-button
                    [ariaLabel]="modalConfig?.primaryButtonLabel || 'Select'"
                    [variation]="'primary,block,mobile-sticky'"
                    (click)="onSelect()"
                >
                    {{ modalConfig?.primaryButtonLabel || 'Select' }}
                    @if (modalConfig?.showTotal && currentSelected?.length) {
                        ({{ currentSelected?.length }})
                    }
                </fi-button>
            </fi-modal-footer>
        }
    }
</fi-modal>

<ng-template #skeleton>
    <fi-skeleton-element
        class="fi-select-modal__skeleton-element"
    ></fi-skeleton-element>
    <fi-skeleton-element
        class="fi-select-modal__skeleton-element fi-select-modal__skeleton-element--long"
    ></fi-skeleton-element>
</ng-template>
