import {
    Component,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
} from '@angular/core';
import { NgClass } from '@angular/common';

import { isEmpty } from 'lodash';

import { TimeFormatPipe } from '../../../../pipes';
import {
    DateRange,
    DateRangeModesTitle,
    DateRangeSelectionMode,
} from '../../models';

@Component({
    selector: 'fi-date-range-tabs',
    templateUrl: 'tabs.component.html',
    styleUrls: ['./tabs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgClass,
        TimeFormatPipe,
    ]
})
export class DateRangeTabsComponent {
    @Input() active: DateRangeSelectionMode;
    @Input() tabs: DateRange;
    @Input() highlighted: DateRangeSelectionMode;
    @Input() fromTitle = DateRangeModesTitle.From;
    @Input() toTitle = DateRangeModesTitle.To;

    @Output() change = new EventEmitter<DateRangeSelectionMode>();

    readonly valuePlaceholder = 'Choose a date';

    changeTab(tab: any): void {
        if (this.canChange && this.active !== tab) {
            this.change.emit(tab);
        }
    }

    private get canChange(): boolean {
        return !isEmpty(this.tabs.from);
    }
}
