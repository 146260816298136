import {
    ChangeDetectorRef,
    Component,
    ContentChild,
    Input,
    OnInit,
    Optional,
    TemplateRef,
} from '@angular/core';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Observable } from 'rxjs';

import { EnvironmentService } from '../../../services';
import { MemoizeFuncPipe } from '../../../pipes';
import { GridDataSourceDirective } from '../data-source/data-source.directive';
import { GridPagedResult } from '../store';

type PagedData = Pick<
    GridPagedResult,
    'totalItems' | 'pageSize' | 'pageNumber'
>;

@UntilDestroy()
@Component({
    selector: 'fi-grid-result-summary',
    templateUrl: './result-summary.component.html',
    standalone: true,
    imports: [NgTemplateOutlet, AsyncPipe, MemoizeFuncPipe],
})
export class GridResultSummaryComponent implements OnInit {
    @Input('isShortSummary') isShortSummary$: Observable<boolean> =
        this.environmentService.isMobile$;

    paging: PagedData;
    isInitializing: boolean;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        @Optional() private dataSource: GridDataSourceDirective,
        private environmentService: EnvironmentService,
    ) {}

    @ContentChild(TemplateRef) layout: TemplateRef<GridResultSummaryComponent>;

    ngOnInit(): void {
        if (!this.dataSource) {
            return;
        }

        this.dataSource.stateChanged$
            .pipe(untilDestroyed(this))
            .subscribe(() => this.updateState());

        this.updateState();
    }

    getDesktopPhrase(paging: PagedData): string {
        const { pageSize, totalItems } = paging;

        if (totalItems <= pageSize) {
            return `Showing ${totalItems}`;
        }

        const { start, end } = this.getRecordsRange(paging);
        return `Showing ${start}-${end} of ${totalItems}`;
    }

    getMobilePhrase(paging: PagedData): string {
        return `Total: ${paging.totalItems}`;
    }

    getShowingCount(paging: PagedData): number {
        const { pageSize, totalItems } = paging;

        if (totalItems <= pageSize) {
            return totalItems;
        }

        const { start, end } = this.getRecordsRange(paging);
        return end - start + 1;
    }

    private getRecordsRange({ pageNumber, pageSize, totalItems }: PagedData): {
        start: number;
        end: number;
    } {
        const start = (pageNumber - 1) * pageSize + 1;
        const calculatedEnd = start + pageSize - 1;
        const end = calculatedEnd > totalItems ? totalItems : calculatedEnd;
        return { start, end };
    }

    private updateState(): void {
        const {
            source: { pageNumber, pageSize, totalItems },
            isInitializing,
        } = this.dataSource;

        this.paging = {
            pageNumber,
            pageSize,
            totalItems,
        };
        this.isInitializing = isInitializing;

        this.changeDetectorRef.markForCheck();
    }
}
