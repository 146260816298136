<div #mapContainer class="fi-location-map__container"></div>

@if (markerPopupAvailable) {
    <fi-tooltip-v2
        [tpl]="markerPopup"
        [variation]="markerPopupVariation"
        #markerPopupContainer
    ></fi-tooltip-v2>
}

<ng-template #markerPopup>
    <div [innerHTML]="options.popupSettings.content"></div>
</ng-template>
