import {
    Component,
    Input,
    Output,
    ChangeDetectionStrategy,
    OnDestroy,
    EventEmitter,
    inject,
} from '@angular/core';
import { KeyValue, KeyValuePipe, NgTemplateOutlet } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { isEqual, toPairs, fromPairs, sortBy, filter, values } from 'lodash';

import { arrayMove } from '../../../../utils';
import { OnChange } from '../../../../decorators';
import { CustomScrollDirective } from '../../../../directives';
import {
    ModalComponent,
    ModalContentComponent,
    ModalFooterComponent,
    ModalHeaderComponent,
} from '../../../../feature/modal';
import { ButtonComponent, CheckboxComponent } from '../../../../components';
import { MemoizeFuncPipe } from '../../../../pipes';
import { isEscapeModalEvent, OverlayScrollStrategy } from '../../../overlay';
import {
    ModalAnimationType,
    ModalEvent,
    ModalVariation,
} from '../../../modal/models';
import {
    GridColumnConfiguration,
    GridColumnListConfiguration,
} from '../../store/models';
import {
    DraggableListChange,
    DraggableListModule,
} from '../../../draggable-list';

type ConfigListItem = [string, GridColumnConfiguration];
export type TransformedSettingsMap = KeyValue<string, GridColumnConfiguration>;

@Component({
    selector: 'manage-columns-modal',
    templateUrl: './manage-columns-modal.component.html',
    styleUrls: ['./manage-columns-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [KeyValuePipe],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        CheckboxComponent,
        ButtonComponent,
        ModalComponent,
        ModalHeaderComponent,
        ModalContentComponent,
        ModalFooterComponent,
        CustomScrollDirective,
        MemoizeFuncPipe,
        DraggableListModule,
        ScrollingModule,
    ],
})
export class ManageColumnsModalComponent implements OnDestroy {
    private readonly keyValuePipe = inject(KeyValuePipe);

    @OnChange('setInitialConfig')
    @Input('config')
    initialConfig: GridColumnListConfiguration;
    @Input() open: boolean;

    @Output() close = new EventEmitter<void>();
    @Output() save = new EventEmitter<{
        [key: string]: GridColumnConfiguration;
    }>();

    readonly modalAnimation = ModalAnimationType.SlideTop;
    readonly modalVariation = [
        ModalVariation.Wide600x,
        ModalVariation.FullHeight,
    ];
    readonly scrollStrategy = OverlayScrollStrategy.Noop;

    config: GridColumnListConfiguration;

    ngOnDestroy(): void {
        this.closeModal();
    }

    setInitialConfig(): void {
        const { initialConfig } = this;
        const initialConfigSorted = sortBy(toPairs(initialConfig), [
            ([_, { order }]: ConfigListItem) => order,
        ]);

        this.config = fromPairs(initialConfigSorted);
    }

    toggleSetting(settingKey: string, isChecked: boolean): void {
        const { config } = this;
        const { isHidden, ...setting } = config[settingKey];
        const updatedSetting = isChecked
            ? { ...setting, isHidden: isChecked }
            : { ...setting };

        config[settingKey] = updatedSetting;
        this.config = { ...config };
    }

    handleSave(): void {
        this.save.emit({ ...this.config });
    }

    closeModal(): void {
        this.setInitialConfig();
        this.close.emit();
    }

    hasChange([config, initialConfig]: Record<
        string,
        GridColumnConfiguration
    >[]): boolean {
        return isEqual(config, initialConfig);
    }

    changeSettingOrder({
        previousIndex,
        currentIndex,
    }: DraggableListChange): void {
        if (previousIndex === currentIndex) {
            return;
        }

        const { config } = this;
        const configList = toPairs(config);
        const notMovableCount = this.filterSettingByMovable([
            config,
            false,
        ]).length;
        const indexFrom = previousIndex + notMovableCount;
        const indexTo = currentIndex + notMovableCount;

        const updatedConfigList = arrayMove<ConfigListItem>(
            configList,
            indexFrom,
            indexTo,
        ).map(
            ([key, setting]: ConfigListItem, index: number): ConfigListItem => {
                return [key, { ...setting, order: index + 1 }];
            },
        );

        this.config = fromPairs([...updatedConfigList]);
    }

    filterSettingByMovable([config, isMovable]: [
        GridColumnListConfiguration,
        boolean,
    ]): TransformedSettingsMap[] {
        const settings: TransformedSettingsMap[] = this.keyValuePipe.transform(
            config,
            ({ value }) => value.order,
        );

        return filter(settings, ({ value }) => !value.notMovable === isMovable);
    }

    getDescription(config: GridColumnListConfiguration): string {
        const settings = values(config);
        const columnsLength = settings.length;
        const hiddenColumnsLength = filter(settings, 'isHidden').length;
        const visibleColumnsLength = columnsLength - hiddenColumnsLength;

        return `${visibleColumnsLength} of ${columnsLength} columns visible`;
    }

    handleModalEvent({ type }: ModalEvent): void {
        if (isEscapeModalEvent(type)) {
            this.closeModal();
        }
    }
}
