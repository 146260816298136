@if (row) {
    <fi-checkbox
        [variation]="
            disabled && isSomeNestedRowSelected ? 'disabled-invariant' : ''
        "
        [invariant]="isSomeNestedRowSelected && !isRowSelected"
        [checked]="isRowSelected"
        [disabled]="disabled"
        (change)="handleCheck()"
    ></fi-checkbox>
} @else {
    <fi-checkbox
        [invariant]="isSomeRowSelected && !isAllRowSelected"
        [stopPropagation]="true"
        [checked]="isAllRowSelected"
        [disabled]="disabled"
        (change)="handleSelectAll()"
        id="gridSelectAllCheckbox"
    ></fi-checkbox>
}
