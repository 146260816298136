import { MapMarkerSizes } from '../models';

import { MapMarkerType } from './map-marker-type.enum';

export const MAP_MARKER_SIZES: Record<MapMarkerType, MapMarkerSizes> = {
    [MapMarkerType.Location]: {
        size: { x: 24, y: 36 },
        scaledSize: { x: 36, y: 48 },
    },
    [MapMarkerType.Unit]: {
        size: { x: 34, y: 43 },
        scaledSize: { x: 45.19, y: 56.5 },
    },
    [MapMarkerType.UnitWithRoadReport]: {
        size: { x: 34, y: 43 },
        scaledSize: { x: 45.19, y: 56.5 },
    },
    [MapMarkerType.CurrentLocation]: {
        size: { x: 42.84, y: 50.88 },
        scaledSize: { x: 42.84, y: 50.88 },
    },
    [MapMarkerType.UnitHistory]: {
        size: { x: 36, y: 36 },
        scaledSize: { x: 54, y: 54 },
    },
    [MapMarkerType.UnitHistoryDetails]: {
        size: { x: 34, y: 43 },
        scaledSize: { x: 45.19, y: 56.5 },
    },
    [MapMarkerType.UnitClusterSmall]: {
        size: { x: 48, y: 48 },
        scaledSize: { x: 48, y: 48 },
    },
    [MapMarkerType.UnitClusterMedium]: {
        size: { x: 64, y: 64 },
        scaledSize: { x: 64, y: 64 },
    },
    [MapMarkerType.UnitClusterLarge]: {
        size: { x: 72, y: 72 },
        scaledSize: { x: 72, y: 72 },
    },
    [MapMarkerType.ServiceProvider]: {
        size: { x: 24, y: 24 },
        scaledSize: { x: 56, y: 56 },
    },
};
