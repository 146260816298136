import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    Optional,
    OnInit,
    ChangeDetectorRef,
    HostBinding,
} from '@angular/core';
import { RouterLink, Router, NavigationEnd } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { defer, Subject } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { OnChange } from '../../../decorators';
import { VariationDirective } from '../../../directives';
import { MemoizeFuncPipe } from '../../../pipes';

export const enum NavTabsItemVariation {
    FullWidth = 'full-width',
}

@UntilDestroy()
@Component({
    selector: 'fi-nav-tabs-item',
    templateUrl: './nav-tabs-item.component.html',
    styleUrls: ['./nav-tabs-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [VariationDirective, MemoizeFuncPipe],
})
export class NavTabsItemComponent implements OnInit {
    @OnChange<boolean>(function (this: NavTabsItemComponent) {
        this.activeStateChange.next();
    })
    @Input()
    active: boolean;
    @Input()
    skipHistory: boolean = true;
    @Input() disabled = false;
    @Input() variation: NavTabsItemVariation;
    @Input() ariaLabel: string;

    @HostBinding('attr.tabindex') tabindex = -1;

    routerUrl = this.router.url;
    activeStateChange$ = defer(() => this.activeStateChange);

    private readonly activeStateChange = new Subject<void>();

    constructor(
        private elementRef: ElementRef,
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        @Optional() private routerLink: RouterLink,
    ) {}

    ngOnInit(): void {
        // navigation by tabs should not be saved in history
        if (this.routerLink && this.skipHistory) {
            this.routerLink.replaceUrl = true;
        }

        this.router.events
            .pipe(
                filter(() => !!this.routerLink),
                filter(
                    (event): event is NavigationEnd =>
                        event instanceof NavigationEnd,
                ),
                map(({ url }) => url),
                tap((url) => (this.routerUrl = url)),
                untilDestroyed(this),
            )
            .subscribe((url) => {
                if (
                    this.isCurrentRouteActive(
                        url,
                        this.routerLink.urlTree.toString(),
                    )
                ) {
                    this.active = true;
                    this.activeStateChange.next();
                } else {
                    this.active = false;
                }

                this.changeDetectorRef.markForCheck();
            });
    }

    getIsActive(): boolean {
        return (
            this.active ||
            (this.routerLink &&
                !this.disabled &&
                this.isCurrentRouteActive(
                    this.router.url,
                    this.routerLink.urlTree.toString(),
                ))
        );
    }

    getViewPosition(): Partial<ClientRect | DOMRect> {
        const element = this.elementRef.nativeElement;
        const { width, height } = element.getBoundingClientRect();

        return {
            width,
            left: element.offsetLeft,
            top: element.offsetTop + height,
        };
    }

    private isCurrentRouteActive(
        routerUrl: string,
        routerLinkUrl: string,
    ): boolean {
        const [routerUrlWithoutQueryParams] = routerUrl.split('?');
        const [routerLinkUrlWithoutQueryParams] = routerLinkUrl.split('?');

        return routerUrlWithoutQueryParams.includes(
            routerLinkUrlWithoutQueryParams,
        );
    }
}
