import { LocationMapCoordinates } from '../models';

export const DEFAULT_MAP_ZOOM = 11;
export const NORTH_AMERICA_CENTER_ZOOM = 2;

export const METRES_IN_ONE_MILE = 1609.34;

export const MIN_MAP_RADIUS = 0.05;
export const MAX_MAP_RADIUS = 120;

export const NORTH_AMERICA_CENTER_COORDINATES: LocationMapCoordinates = {
    latitude: 37.09024,
    longitude: -95.71289000000002,
};
