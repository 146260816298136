<fi-skeleton
    [isInitializing]="isInitializing"
    class="fleet-profile-tabs"
>
    <fi-nav-tabs
        [resetOnCustomCondition]="isActiveTabIndicatorReset"
        [resetOnMouseLeave]="true"
        [isLineUnderCard]="true"
        [variation]="activeMetricTab | memoizeFunc : getTabsVariation : this"
        class="fleet-profile-tabs__tabs"
    >
        @if ([leaseTab, metrics] | memoizeFunc : isMetricChartTabVisible : this) {
            @let isLeaseTabActive = [leaseTab, activeMetricTab] | memoizeFunc : isActiveMetric : this;
            <fi-nav-tabs-item
                [active]="isLeaseTabActive"
                [variation]="'full-width'"
                (mouseover)="setActiveMetric(leaseTab)"
                (mouseleave)="setDefaultMetric()"
                class="fleet-profile-tabs__item"
            >
                <fi-metrics-tab
                    [ariaLabel]="'Lease'"
                    [isActiveTab]="isLeaseTabActive"
                    (click)="handleClick(leaseTab)"
                >
                    <div class="metrics-tab-title">
                        <span
                            class="
                                fleet-profile-tabs__point
                                fleet-profile-tabs__point--lease
                            "
                        ></span>
                        <span class="fleet-profile-tabs__label">Lease</span>
                    </div>
                    <span
                        class="fleet-profile-tabs__link metrics-tab-body"
                    >
                        <ng-container
                            *ngTemplateOutlet="
                                title;
                                context: { count: metrics.leaseUnits }
                            "
                        ></ng-container>
                    </span>
                </fi-metrics-tab>
            </fi-nav-tabs-item>
        }

        @if ([contractMaintenanceTab, metrics] | memoizeFunc : isMetricChartTabVisible : this) {
            @let isContractMaintenanceTabActive = [contractMaintenanceTab, activeMetricTab] | memoizeFunc : isActiveMetric : this;
            <fi-nav-tabs-item
                [active]="isContractMaintenanceTabActive"
                [variation]="'full-width'"
                (mouseover)="setActiveMetric(contractMaintenanceTab)"
                (mouseleave)="setDefaultMetric()"
                class="fleet-profile-tabs__item"
            >
                <fi-metrics-tab
                    [ariaLabel]="'Contract Maintenance'"
                    [isActiveTab]="isContractMaintenanceTabActive"
                    (click)="handleClick(contractMaintenanceTab)"
                >
                    <div class="metrics-tab-title">
                        <span
                            class="
                                fleet-profile-tabs__point
                                fleet-profile-tabs__point--contract-maintenance
                            "
                        ></span>
                        <span class="fleet-profile-tabs__label">
                            Contract Maintenance
                        </span>
                    </div>
                    <span
                        class="fleet-profile-tabs__link metrics-tab-body"
                    >
                        <ng-container
                            *ngTemplateOutlet="
                                title;
                                context: {
                                    count: metrics.contractMaintenanceUnits
                                }
                            "
                        ></ng-container>
                    </span>
                </fi-metrics-tab>
            </fi-nav-tabs-item>
        }

        @if ([rentalTab, metrics] | memoizeFunc : isMetricChartTabVisible : this) {
            @let isRentalTabActive = [rentalTab, activeMetricTab] | memoizeFunc : isActiveMetric : this;
            <fi-nav-tabs-item
                [active]="isRentalTabActive"
                [variation]="'full-width'"
                (mouseover)="setActiveMetric(rentalTab)"
                (mouseleave)="setDefaultMetric()"
                class="fleet-profile-tabs__item"
            >
                <fi-metrics-tab
                    [ariaLabel]="'Rental'"
                    [isActiveTab]="isRentalTabActive"
                    (click)="handleClick(rentalTab)"
                >
                    <div class="metrics-tab-title">
                        <span
                            class="
                                fleet-profile-tabs__point
                                fleet-profile-tabs__point--rental
                            "
                        ></span>
                        <span class="fleet-profile-tabs__label">Rental</span>
                    </div>
                    <span
                        class="fleet-profile-tabs__link metrics-tab-body"
                    >
                        <ng-container
                            *ngTemplateOutlet="
                                title;
                                context: { count: metrics.rentalUnits }
                            "
                        ></ng-container>
                    </span>
                </fi-metrics-tab>
            </fi-nav-tabs-item>
        }
    </fi-nav-tabs>

    <fi-skeleton-element class="fleet-profile-tabs__skeleton">
        <div class="fleet-profile-tabs__skeleton-tabs">
            @for (element of 2 | toNumArray; track element) {
                <div
                    class="fleet-profile-tabs__skeleton-tabs-item"
                >
                <span class="fleet-profile-tabs__skeleton-tabs-title">
                    <fi-skeleton-element
                        [variant]="'round'"
                        class="fleet-profile-tabs__skeleton-tabs-point"
                    ></fi-skeleton-element>
                    <fi-skeleton-element
                        class="fleet-profile-tabs__skeleton-tabs-label"
                    ></fi-skeleton-element>
                </span>
                    <fi-skeleton-element
                        class="fleet-profile-tabs__skeleton-tabs-link"
                    ></fi-skeleton-element>
                </div>
            }
        </div>
    </fi-skeleton-element>
</fi-skeleton>

<ng-template #title let-count="count">
    {{ count }} {{ 'Unit' | pluralize: count }}
</ng-template>

