import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { FileDropOptions } from '../models';
import {
    errorListDefine,
    uploadFileErrors,
} from '../utils/drag-n-drop-area.helpers';

@Injectable({
    providedIn: 'root',
})
export class FilesUploadService {
    private httpClient = inject(HttpClient);

    removeAttachment(attachmentList: number[]): Observable<void> {
        const attachmentIds = attachmentList.join();
        const url = `{api_v1}/attachments/requests`;
        const params = { attachmentIds };

        return this.httpClient.delete<void>(url, { params });
    }

    uploadAttachments(
        selectedFile: File,
        pathUnitNumber: string,
        requestId?: string,
    ): Observable<any> {
        const url = `{api_v1}/attachments/requests/${pathUnitNumber}/upload`;
        const uploadData = new FormData();

        uploadData.append('file', selectedFile, selectedFile.name);

        const headers = new HttpHeaders({
            'X-Skip-Interceptor': 'X-Skip-Interceptor',
        });
        const params = {
            ...(!!requestId && { requestId }),
        };

        return this.httpClient.post(url, uploadData, {
            reportProgress: true,
            observe: 'events',
            headers,
            params,
        });
    }

    errorListDefine(
        uploadFiles: FileList,
        fileUploadOptions: FileDropOptions,
    ): string[] {
        return errorListDefine(uploadFiles, fileUploadOptions);
    }

    isOneFileDropError(
        file: File,
        fileUploadOptions: FileDropOptions,
    ): string[] {
        return uploadFileErrors(file, fileUploadOptions);
    }
}
