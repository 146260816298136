import {
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    inject,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { fromEvent, merge, of } from 'rxjs';
import { delay, map, switchMap } from 'rxjs/operators';

const HOVER_DELAY = 500;

@UntilDestroy()
@Directive({
    selector: '[fiDelayedHover]',
    standalone: true,
})
export class DelayedHoverDirective implements OnInit {
    private readonly element = inject(ElementRef);

    @Input() delay = HOVER_DELAY;

    @Output('fiDelayedHover') hoverEvent = new EventEmitter();

    ngOnInit(): void {
        const { nativeElement } = this.element;

        const hide$ = fromEvent(nativeElement, 'mouseleave').pipe(
            map(() => false),
        );
        const show$ = fromEvent(nativeElement, 'mouseenter').pipe(
            map(() => true),
        );

        merge(hide$, show$)
            .pipe(
                switchMap((show) => {
                    if (!show) {
                        return of(false);
                    }
                    return of(true).pipe(delay(this.delay));
                }),
                untilDestroyed(this),
            )
            .subscribe((show) => {
                if (show) {
                    this.hoverEvent.emit();
                }
            });
    }
}
