import {
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    TemplateRef,
    ViewContainerRef,
    inject,
} from '@angular/core';
import { animate, AnimationMetadata, style } from '@angular/animations';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
    OverlayContainerRef,
    OverlayEvent,
    OverlayEventType,
    OverlayService,
} from '../../../../feature/overlay';
import { OnChange } from '../../../../decorators';
import {
    SidePanelEvent,
    SidePanelEventType,
    SidePanelPosition,
} from '../../models';

@Component({
    selector: 'fi-side-panel',
    template: '',
    styleUrls: ['./side-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class SidePanelComponent implements OnDestroy {
    private readonly overlayService = inject(OverlayService);
    private readonly viewContainerRef = inject(ViewContainerRef);

    @OnChange(function (this: SidePanelComponent) {
        this.toggleOpenWindow();
    })
    @Input()
    open = false;

    @Input() hasBackdrop = true;
    @Input() backdropClass: string;

    @Output() event = new EventEmitter<SidePanelEvent>();

    @ContentChild(TemplateRef) sidePanelRef: TemplateRef<any>;

    readonly detach$ = new Subject<void>();
    readonly overlayDetach$ = new Subject<void>();
    readonly position: SidePanelPosition = SidePanelPosition.Right;

    private overlayContainerRef: OverlayContainerRef<TemplateRef<any>> | null;

    ngOnDestroy(): void {
        this.overlayDetach$.next();
        this.overlayDetach$.complete();

        this.detach$.complete();

        this.close();
    }

    private toggleOpenWindow(): void {
        if (this.open) {
            this.show();
            return;
        }

        this.close();
    }

    private show(): void {
        if (this.overlayContainerRef) {
            return;
        }

        const animationMetadata = this.getAnimationMetadata('enter');

        this.overlayContainerRef = this.overlayService.create(
            this.sidePanelRef,
            this.viewContainerRef,
            {
                variation: 'side-panel',
                position: this.position,
                hasBackdrop: this.hasBackdrop,
                ...(this.backdropClass && { backdropClass: `cdk-overlay-backdrop--${this.backdropClass}` })
            } as any,
        );

        this.overlayContainerRef.open({
            animation: animationMetadata,
        });

        if (this.overlayContainerRef) {
            this.attachOverlayListener();
        }
    }

    private close(): void {
        if (!this.overlayContainerRef) {
            return;
        }

        const animationMetadata = this.getAnimationMetadata('leave');
        this.overlayContainerRef.close({ animation: animationMetadata });

        this.overlayContainerRef = null;
    }

    private attachOverlayListener(): void {
        this.overlayContainerRef.event$
            .pipe(takeUntil(this.overlayDetach$.asObservable()))
            .subscribe(({ type }: OverlayEvent) => this.fireEvent(type));
    }

    private fireEvent(type: SidePanelEventType): void {
        this.event.emit(new SidePanelEvent(type));

        if (type === OverlayEventType.Detach) {
            this.detach$.next();
        }
    }

    private getAnimationMetadata(
        direction: 'enter' | 'leave',
    ): AnimationMetadata[] {
        const state = {
            transform: 'translateX(100%)',
        };
        const timings = '500ms ease-in-out';

        const from = direction === 'enter' ? state : '*';
        const to = direction === 'enter' ? '*' : state;

        return [style(from), animate(timings, style(to))];
    }
}
