@if (!isInitializing && paging) {
    @if (isShortSummary$ | async) {
        <span class="fi-text fi-text--medium">
            <ng-container
                [ngTemplateOutlet]="layout"
                [ngTemplateOutletContext]="{
                    resultPhrase: paging | memoizeFunc: getMobilePhrase : this,
                    totalItems: paging.totalItems,
                }"
            ></ng-container>
        </span>
    } @else {
        <span class="fi-text fi-text--medium">
            <ng-container
                [ngTemplateOutlet]="layout"
                [ngTemplateOutletContext]="{
                    resultPhrase: paging | memoizeFunc: getDesktopPhrase : this,
                    totalItems: paging.totalItems,
                    showingCount: paging | memoizeFunc: getShowingCount : this,
                }"
            ></ng-container>
        </span>
    }
}
