import { Injectable } from '@angular/core';

import { animationFrameScheduler, fromEvent, Observable } from 'rxjs';
import { debounceTime, map, shareReplay, startWith } from 'rxjs/operators';

type ContainerScrollPosition = {
    top: number;
    left: number;
};

@Injectable()
export class GridStickyHeaderService {
    containerScroll$: Observable<ContainerScrollPosition>;

    setScrollingContainer(container: HTMLElement): void {
        this.containerScroll$ = fromEvent<Event>(container, 'scroll', {
            passive: true,
        }).pipe(
            startWith(null),
            debounceTime(0, animationFrameScheduler),
            map(() => container),
            map(({ scrollTop, scrollLeft }) => ({
                top: scrollTop,
                left: scrollLeft,
            })),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
    }
}
