import { CommonModule, DecimalPipe } from '@angular/common';
import { Injectable, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TextFieldModule } from '@angular/cdk/text-field';
import {
    HAMMER_GESTURE_CONFIG,
    HammerGestureConfig,
    HammerModule,
} from '@angular/platform-browser';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { A11yModule } from '@angular/cdk/a11y';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import 'hammerjs';

import './services/config.service';
import './animations';
import { standalonePipes } from './pipes';
import { standaloneDirectives } from './directives';
import { components, standaloneComponents } from './components';

const standaloneModules = [
    ...standaloneDirectives,
    ...standalonePipes,
    ...standaloneComponents,
];

const modules = [...components];
// To enable scroll in carousel component
// https://github.com/angular/angular/issues/10541#issuecomment-271083162
@Injectable()
export class EnableScrollHammerConfig extends HammerGestureConfig {
    overrides = {
        pinch: { enable: false },
        rotate: { enable: false },
    };
}

@NgModule({
    declarations: [...modules],
    imports: [
        TextFieldModule,
        ReactiveFormsModule,
        FormsModule,
        CommonModule,
        ScrollingModule,
        A11yModule,
        HammerModule,
        RouterModule,
        ...standaloneModules,
    ],
    exports: [
        ReactiveFormsModule,
        FormsModule,
        CommonModule,
        ...modules,
        ...standaloneModules,
    ],
    providers: [
        DecimalPipe,
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: EnableScrollHammerConfig,
        },
    ],
})
export class UiCommonModule {}
