import { MapMarkerIcon } from  '../../feature/map-marker';

export function generateIcon(iconType: MapMarkerIcon): string {
    const iconName = toKebabCase(iconType);
    const id = `#fi-icon-custom-${iconName}`;

    return `
        <svg aria-hidden="true" focusable="false">
            <use xlink:href="${id}" />
        </svg>
    `;
}

// can be removed when MapMarkerIcon will be renamed to common icon name view
function toKebabCase(input: string = ''): string {
    return input
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .toLowerCase();
}
