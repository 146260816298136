import { NgControl } from '@angular/forms';

import { Observable } from 'rxjs';

import { getNumericInputCaretOriginalPosition, replaceValueNonDigits } from '../../../utils';
import { DISTRICT_NUMBER_LENGTH } from '../input.component';

import { InputFormatBase, InputFormatParsedValue } from './input-format-base';

export class DistrictInputFormat extends InputFormatBase {
    valueChanges$: Observable<string>;

    constructor(
        ngControl: NgControl,
        private inputElement: HTMLInputElement,
        private initialValue: string,
    ) {
        super(ngControl);

        const transformedInitialValue = this.getTransformedValue(this.initialValue);
        this.setControlValue(transformedInitialValue);

        this.valueChanges$ = this.getInputEvent(transformedInitialValue);
    }

    protected getParsedValue([previousValue, currentValue]: string[]): InputFormatParsedValue {
        const {
            selectionEnd: caretPosition,
            value: originalValue, 
        } = this.inputElement;
        const isValueDeleted = originalValue.length < previousValue.length;

        return {
            caretPosition,
            isValueDeleted,
            originalValue,
            transformedValue: this.getTransformedValue(currentValue),
        };
    }

    protected returnCaretToOriginalPosition({
        caretPosition,
        originalValue,
        transformedValue,
        isValueDeleted,
    }: InputFormatParsedValue): void {
        const beforeCaretValue = replaceValueNonDigits(
            originalValue.substring(0, caretPosition),
            DISTRICT_NUMBER_LENGTH,
        );

        const targetCaretPosition = getNumericInputCaretOriginalPosition(
            beforeCaretValue,
            transformedValue,
            isValueDeleted,
        );

        this.inputElement.setSelectionRange(targetCaretPosition, targetCaretPosition);
    }

    private getTransformedValue(value: string): string {
        if (!value) {
            return '';
        }

        return replaceValueNonDigits(value, DISTRICT_NUMBER_LENGTH);
    }
}
