import { NgModule, Type } from '@angular/core';

import { ContextMenuListDirective } from './list/list.directive';
import { ContextMenuLabelComponent } from './label/label.component';
import { ContextMenuItemComponent } from './item/item.component';
import { ContextMenuComponent } from './context-menu.component';
import { ContextMenuItemContainerDirective } from './item/item-container.directive';

const FEATURES: Array<Type<any> | any[]> = [
    ContextMenuComponent,
    ContextMenuListDirective,
    ContextMenuLabelComponent,
    ContextMenuItemComponent,
    ContextMenuItemContainerDirective,
];

@NgModule({
    imports: [...FEATURES],
    exports: FEATURES,
})
export class ContextMenuModule {}
