<div
    [fiTooltip]="!isMobileOS && tooltipValue && tooltipTpl"
    class="fi-grid-download-report"
>
    <fi-button
        [variation]="buttonVariation"
        [ariaLabel]="'Download Report'"
        (click)="openModal()"
        id="downloadButton"
        class="fi-grid-download-report__control"
    >
        <fi-icon
            [svgName]="'download'"
            [variation]="iconVariation"
            [size]="'x-large'"
        ></fi-icon>
    </fi-button>
</div>

<ng-template #tooltipTpl>
    <span>{{ tooltipValue }}</span>
</ng-template>

<fi-modal [(open)]="isModalOpen">
    @if (isModalOpen) {
        <fi-modal-header
            (close)="closeModal()"
            class="fi-grid-download-report__modal-header"
        >
            <ng-content select="fi-grid-download-report-header"></ng-content>
        </fi-modal-header>

        <fi-modal-content class="fi-grid-download-report__modal-content">
            <ng-content></ng-content>
        </fi-modal-content>

        <fi-modal-footer [@.disabled]="true">
            <div class="fi-grid-download-report__modal-footer">
                <fi-button
                    [ariaLabel]="'Cancel'"
                    [variation]="'secondary'"
                    (click)="closeModal()"
                >
                    Cancel
                </fi-button>

                <fi-button
                    [ariaLabel]="'Download'"
                    [variation]="'primary,animated'"
                    [loading]="downloadInProgress$ | async"
                    (click)="handleDownloadReport()"
                >
                    {{ (downloadInProgress$ | async) ? 'Loading' : 'Download' }}
                </fi-button>
            </div>
        </fi-modal-footer>
    }
</fi-modal>
