import { Directive, Optional, AfterViewInit, Input } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { distinctUntilChanged, filter, pairwise, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { FilteringDirective } from '../../../filtering/filtering.directive';

const AUTO_CLOSE_OFFSET = 110;

@UntilDestroy()
@Directive({
    selector: '[filterAutoClose]',
    standalone: true,
})
export class GridStickyHeaderFilterAutoCloseDirective implements AfterViewInit {
    @Input() scrollTop$!: Observable<number>;
    @Input() stickyStateChange$!: Observable<boolean>;
    @Input() isSticky!: boolean;

    private stickyFilterWasOpen = false;
    private openFilterPosition: number;

    constructor(@Optional() private filtering: FilteringDirective) {}

    ngAfterViewInit(): void {
        // this covers the case of tables without filtering
        if (!this.filtering) {
            return;
        }

        this.watchStickyHeaderScroll();
        this.watchStickyHeaderStateChanges();

        this.watchFilteringStateChanges();
    }

    private watchStickyHeaderScroll(): void {
        this.scrollTop$
            .pipe(
                filter(
                    () =>
                        this.isStickyFilterOpen() &&
                        !this.isFilterPanelAnimating(),
                ),
                tap((value) => this.setInitialFilterPosition(value)),
                pairwise(),
                tap((values) => this.updateFilterPositionOnBackScroll(values)),
                untilDestroyed(this),
            )
            .subscribe(([_, currentValue]) => {
                if (
                    currentValue >
                    this.openFilterPosition + AUTO_CLOSE_OFFSET
                ) {
                    this.hideFilterPanel();
                }
            });
    }

    private watchStickyHeaderStateChanges(): void {
        this.stickyStateChange$
            .pipe(
                distinctUntilChanged(),
                filter(
                    (isSticky) => isSticky && !this.isFilterPanelAnimating(),
                ),
                untilDestroyed(this),
            )
            .subscribe(() => {
                this.hideFilterPanel();
            });
    }

    private watchFilteringStateChanges(): void {
        this.filtering.stateChanged$
            .pipe(untilDestroyed(this))
            .subscribe((isOpen) => {
                if (this.isSticky && typeof isOpen === 'boolean') {
                    this.stickyFilterWasOpen = isOpen;
                }
            });
    }

    private hideFilterPanel(): void {
        if (this.filtering.isFilterShow) {
            this.filtering.toggleShowPanel();
        }
    }

    private isStickyFilterOpen(): boolean {
        return this.filtering.isFilterShow && this.isSticky;
    }

    private isFilterPanelAnimating(): boolean {
        return this.filtering.isFilterAnimating;
    }

    // Initial position should be set in the beginning of scroll event
    // to make sure that scrollTop and openFilterPosition values are synced
    private setInitialFilterPosition(value: number): void {
        if (this.stickyFilterWasOpen) {
            this.openFilterPosition = value;
            this.stickyFilterWasOpen = false;
        }
    }

    private updateFilterPositionOnBackScroll([
        previousValue,
        currentValue,
    ]: number[]): void {
        if (currentValue < previousValue) {
            this.openFilterPosition = currentValue;
        }
    }
}
