import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import {
    BaseAnalyticsEvent,
    AnalyticsBaseService,
} from '@pnsk/ui-common/analytics';

import { AnalyticsService } from '../../services/analytics.service';

@Component({
    selector: 'fi-track-view',
    template:
        '<fi-analytics-track-view [data]="data"></fi-analytics-track-view>',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: AnalyticsBaseService,
            useClass: AnalyticsService,
        },
    ],
})
export class TrackViewComponent {
    @Input() data: BaseAnalyticsEvent;
}
