import { Injectable, ViewContainerRef, ElementRef } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { AnimationBuilder } from '@angular/animations';

import {
    ConnectedOverlayParams,
    ConnectedOverlay,
    GlobalOverlay,
    OverlayContext,
    OverlayParams,
    OverlayContainerRef,
} from './models';

type OverlayConditionalParams<K> = K extends ElementRef | HTMLElement
    ? ConnectedOverlayParams
    : OverlayParams;

@Injectable()
export class OverlayService {
    constructor(
        private overlayBuilder: Overlay,
        private animationBuilder: AnimationBuilder,
    ) {}

    create<T, K>(
        content: T,
        viewContainerRef: ViewContainerRef,
        params: OverlayConditionalParams<K>,
        context?: OverlayContext,
        origin?: K,
    ): OverlayContainerRef<T> | null {
        if (!content || !viewContainerRef) {
            return null;
        }

        const overlayRef = this.getOverlayRef<T, K>(
            params,
            content,
            context,
            viewContainerRef,
            origin,
        );

        return new OverlayContainerRef<T>(overlayRef, this.animationBuilder);
    }

    createWithoutContent<K>(
        params: OverlayConditionalParams<K>,
        origin?: K,
    ): OverlayContainerRef<any> {
        const overlayRef = this.getOverlayRef(params, null, null, null, origin);

        return new OverlayContainerRef<any>(overlayRef, this.animationBuilder);
    }

    private getOverlayRef<T, K>(
        params: K extends ElementRef | HTMLElement
            ? ConnectedOverlayParams
            : OverlayParams,
        content?: T,
        context?: OverlayContext,
        viewContainerRef?: ViewContainerRef,
        origin?: K,
    ): ConnectedOverlay<T, K> | GlobalOverlay<T> {
        return origin
            ? new ConnectedOverlay(
                  this.overlayBuilder,
                  params,
                  context,
                  content,
                  viewContainerRef,
                  origin,
              )
            : new GlobalOverlay(
                  this.overlayBuilder,
                  params,
                  context,
                  content,
                  viewContainerRef,
                  null,
              );
    }
}
