import { ConfigApp } from './services/config.service';

export const CONFIG: ConfigApp = {
    API_VERSION: '', // extended from external config
    PUBLIC_API_VERSION: '', // extended from external config
    ENV: '', // extended from external config
    forms: {
        debounce: 300,
        dateFormat: 'MM/DD/YYYY',
        dateStringFormat: 'YYYY-MM-DD',
        timeStringFormat: 'HH:mm',
        timeStringFullFormat: 'hh:mm A',
        preciseTimeStringFormat: 'YYYY-MM-DDTHH:mm:ss.SSS',
        emailRegex:
            /^[-a-zA-Z0-9#$%_']+(\.[-a-zA-Z0-9#$%_']+)*@(([a-zA-Z0-9']+|[a-zA-Z0-9'][-a-zA-Z0-9']+[a-zA-Z0-9'])\.)+(?!\d+$)[a-zA-Z0-9]{2,}$/,
        wordsRegex: /^[a-z0-9\s]+$/i,
        phoneRegex: /^[0-9]{10}$/,
        dotNumberRegex: /^[0-9]{1,9}$/,
        alphanumericHyphenRegex: /^[\w\-]+$/i,
        emailListRegex:
            /^([-a-zA-Z0-9#$%_']+(\.[-a-zA-Z0-9#$%_']+)*@(([a-zA-Z0-9']+|[a-zA-Z0-9'][-a-zA-Z0-9']+[a-zA-Z0-9'])\.)+(?!\d+$)[a-zA-Z0-9]{2,}(\r|\n|,|\s|;)?(\s)?)+$/,
        delimiters: /\r|\n|,|\s|;/gm,
    },
    PUBLIC_STATE: '/public',
    WEEKEND: [6, 7],
    NAME_SEPARATOR: 'and',
    LIST_SEPARATOR: ', ',
    EMPTY_VALUE: '—',
    MULTILINE_STRING_SEPARATOR: '+|+',
    TIME_FORMAT: {
        MONTH_DAY_YEAR: 'MMMM DD, YYYY',
        FULL: 'MM/DD/YYYY, hh:mm A',
        SHORT_DATE: 'MM/DD/YYYY',
        SHORTEST_DATE: 'MM/DD/YY',
        DAY_SHORT_DATE: 'dddd MM/DD/YYYY',
        AMPM: 'hh:mm A',
        SHORT_AMPM: 'h:mm A',
        MONTH_FULL: 'MMM DD, YYYY, hh:mm a',
        DAY_MONTH_YEAR: 'DD MMM, YYYY',
        MONTH_DAY_HOURS: 'MM/DD h:mm A',
        HOURS_MONTH_DAY: 'h:mm a MM/DD',
        READABLE_DAY: 'dddd, MMMM D, YYYY',
    },
    API_REQUEST_DATE_FORMAT: 'YYYY-MM-DD',
    API_REQUEST_FULL_DATE_FORMAT: 'YYYY-MM-DDTHH:mm:ss.SSS',
    API_MAX_DATE_VALUE: '12/31/+999999999',
    TABLE_PAGE_SIZE: 50,
    MODAL_TABLE_PAGE_SIZE: 30,
    LOCATION_FINDER_NORTH_AMERICA_CENTER_ZOOM: 4,
    SERVICE_TYPE_PRIORITIES: ['SERVICE', 'FUEL', 'RENTAL'],
    NO_MOST_VIEWED: 3,
    CUSTOM_HTTP_ERROR_MESSAGES: {
        MAINTENANCE_RENTAL_HISTORY:
            'We do not display maintenance history for rental units',
    },
    PHONE_NUMBERS: {
        SUPPORT: '8885551212',
        ROAD_ASSISTANCE: '8005260798',
        CUSTOMER_SERVICE: '8444264555',
    },
    PHONE_NUMBER_LENGTH: 10,
    ODOMETER_HOURS_LENGTH: 7,
    WHATS_NEW: {
        SESSION_DELAY_IN_SECONDS: 10,
    },
    EMAIL_PFM: 'pfmfleet@penske.com',
    DOT_CRITICAL_REPAIR: 'DOT Critical Repair',
    MESSAGE_TIMEOUT: 6000,
};
