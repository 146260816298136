<div class="fi-grid-sort-picker__open-button slot" (click)="openModal()">
    @for (column of columns; track column) {
        @if (column.sortable) {
            <ng-container
                [fiGridSortColumn]="column.field"
                #fiGridSortColumn="fiGridSortColumn"
            >
                @if (fiGridSortColumn.isActive) {
                    <fi-grid-sort-icon
                        [isActive]="fiGridSortColumn.isActive"
                        [order]="fiGridSortColumn.order"
                        class="slot__inner"
                    ></fi-grid-sort-icon>
                    <span class="slot__inner fi-text fi-text--medium">
                        Sort by
                    </span>
                }
            </ng-container>
        }
    }
</div>

<fi-modal
    [(open)]="isModalOpen"
    [animation]="modalAnimation"
    [position]="modalPosition"
    [hasBackdrop]="true"
    (event)="handleModalEvent($event)"
>
    @if (isModalOpen) {
        <fi-modal-header
            (close)="closeModal()"
            class="fi-grid-sort-picker__modal-header"
        >
            <h3 class="fi-heading fi-heading--tertiary fi-heading--dark">
                Sort by
            </h3>
        </fi-modal-header>

        <fi-modal-content
            [fiGridSort]="localSortingState"
            (fiGridSortChange)="handleSortColumns($event)"
            #fiGridSort="fiGridSort"
        >
            <ul class="fi-grid-sort-picker__list fi-text fi-text--medium">
                @for (column of columns; track column) {
                    @if (column.sortable) {
                        <li
                            [fiGridSortColumn]="column.field"
                            #fiGridSortColumn="fiGridSortColumn"
                            class="fi-grid-sort-picker__list-item"
                        >
                            <span
                                class="fi-grid-sort-picker__list-item-caption"
                            >
                                <ng-container
                                    [ngTemplateOutlet]="column.headerCell"
                                ></ng-container>
                            </span>
                            @if (fiGridSortColumn.isActive) {
                                <fi-grid-sort-icon
                                    [isActive]="true"
                                    [order]="fiGridSortColumn.order"
                                ></fi-grid-sort-icon>
                            }
                        </li>
                    }
                }
            </ul>
        </fi-modal-content>

        <fi-modal-footer>
            <div class="fi-modal-footer__button-wrapper">
                <div class="fi-modal-footer__button-container">
                    <fi-button
                        [ariaLabel]="'Cancel'"
                        [variation]="'mobile-sticky,secondary,block'"
                        (click)="closeModal()"
                    >
                        Cancel
                    </fi-button>
                </div>

                <div class="fi-modal-footer__button-container">
                    <fi-button
                        [ariaLabel]="'Apply'"
                        [variation]="'mobile-sticky,primary,block'"
                        [disabled]="
                            !(fiGridSort.sorting | memoizeFunc: canApply : this)
                        "
                        (click)="handleApplySort()"
                    >
                        Apply
                    </fi-button>
                </div>
            </div>
        </fi-modal-footer>
    }
</fi-modal>
