<div class="fi-paginator">
    @if (pages.length > 1) {
        <div
            [class.fi-paginator__container--simple-mode]="
                isSimpleMode && isTablet$ | async
            "
            class="fi-paginator__container"
        >
            <fi-button
                [variation]="'smallest,pagination'"
                [disabled]="currentPage === 1"
                [ariaLabel]="'Prev'"
                (click)="setPageByDirection(-1)"
                id="paginatorPrevButton"
                class="fi-paginator__button fi-paginator__button--previous"
            >
                <fi-icon
                    [svgName]="'angle-left'"
                    [variation]="'regular'"
                ></fi-icon>
                Prev
            </fi-button>

            @if (isSimpleMode || (isTablet$ | async) === false) {
                @for (page of pages; track page) {
                    <fi-button
                        [class.fi-paginator__ellipsis]="page === ELLIPSIS"
                        [disabled]="page === ELLIPSIS"
                        [attr.aria-label]="page"
                        [variation]="
                            (isTablet$ | async)
                                ? 'mobile-squared,pagination'
                                : 'smallest,pagination'
                        "
                        [active]="page === currentPage"
                        (click)="selectPageByNumber(page)"
                        class="fi-paginator__button"
                    >
                        {{ page }}
                    </fi-button>
                }
            } @else {
                <fi-go-to-page
                    [currentPage]="currentPage"
                    [maxPage]="pageCount"
                    (goToPage)="selectPageByNumber($event)"
                    id="paginatorGoTo"
                ></fi-go-to-page>
            }

            <fi-button
                [variation]="'smallest,pagination'"
                [disabled]="currentPage === pageCount"
                [ariaLabel]="'Next'"
                (click)="setPageByDirection(1)"
                id="paginatorNextButton"
                class="fi-paginator__button fi-paginator__button--next"
            >
                Next
                <fi-icon
                    [variation]="'regular'"
                    [svgName]="'angle-right'"
                ></fi-icon>
            </fi-button>

            @if (!isSimpleMode && (isTablet$ | async) === false) {
                <ng-container [ngTemplateOutlet]="goToTpl"></ng-container>
            }
        </div>
    }
</div>

<ng-template #goToTpl>
    <fi-go-to-page
        [currentPage]="currentPage"
        [maxPage]="pageCount"
        (goToPage)="selectPageByNumber($event)"
        id="paginatorGoTo"
    ></fi-go-to-page>
</ng-template>
