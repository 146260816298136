@if ((isMobileDevice$ | async) === false || desktopViewOnly) {
    <div
        (click)="toggleShowFilters()"
        role="button"
        aria-label="Toggle filters"
        class="fi-filtering__opener"
    >
        <fi-button
            [variation]="isFilterShow ? 'primary' : 'secondary'"
            [ariaLabel]="'Filters'"
            id="toggleFilters"
        >
            @if (!hideIcon) {
                <fi-icon [svgName]="'sliders-h'" [size]="'x-large'"></fi-icon>
            }

            <span>Filters</span>

            @if (
                appliedFilters | memoizeFunc: getAppliedFiltersCount : this;
                as appliedFiltersCount
            ) {
                <span
                    class="fi-text fi-text--white fi-text--heavy fi-text--small fi-filtering__opener-applied-count"
                >
                    {{ appliedFiltersCount }}
                </span>
            }
        </fi-button>
    </div>
} @else {
    <div
        (click)="toggleShowFilters()"
        role="button"
        aria-label="Toggle filters"
        class="fi-filtering__opener-picker"
    >
        <div class="fi-filtering__opener-picker-icon-wrap">
            @if (!hideIcon) {
                <fi-icon
                    [svgName]="'sliders-h'"
                    [variation]="'regular'"
                    [size]="'x-large'"
                    class="fi-filtering__opener-picker-icon"
                ></fi-icon>
            }
        </div>

        <span class="fi-text fi-text--medium">{{ title }}</span>

        @if (
            appliedFilters | memoizeFunc: getAppliedFiltersCount : this;
            as appliedFiltersCount
        ) {
            <span class="fi-filtering__opener-picker-applied">
                <span
                    class="fi-text fi-text--x-small fi-text--white fi-text--heavy"
                >
                    {{ appliedFiltersCount }}
                </span>
            </span>
        }
    </div>
}
