import { Injectable, Injector, ViewContainerRef, inject } from '@angular/core';
import { PortalInjector } from '@angular/cdk/portal';

import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import {
    OverlayContainerRef,
    OverlayEventType,
    OverlayGlobalPosition,
    OverlayScrollStrategy,
    OverlayService,
} from '../feature/overlay';
import { EnvironmentService } from '../services';

import { ToastCloseEvent, ToastReference } from './toast-reference';
import { getAnimationMetadata$ } from './toast-animation';
import { ToastComponent, ToastVariation } from './toast.component';

export interface ToastConfig {
    text?: string;
    action?: string;
    variation?: ToastVariation[];
    timeout?: number;
}

export interface ToastRef extends Pick<ToastReference, 'close$'> {
    close$: Observable<ToastCloseEvent>;
}

// TODO: use ToastAnchorDirective directive for toast notifications after migration
const BASE_ANCHOR_CLASS_NAME = '.navigation-header';

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    private readonly injector = inject(Injector);
    private readonly overlayService = inject(OverlayService);
    private readonly environmentService = inject(EnvironmentService);

    private overlayContainerRefList: Set<OverlayContainerRef<any>> = new Set();
    private anchorElement: HTMLElement;
    private isShowing = false;

    success(config?: ToastConfig): ToastRef {
        return this.showToast(
            this.mergeVariations(ToastVariation.Success, config),
        );
    }

    error(config?: ToastConfig): ToastRef {
        return this.showToast(
            this.mergeVariations(ToastVariation.Error, config),
        );
    }

    warning(config?: ToastConfig): ToastRef {
        return this.showToast(
            this.mergeVariations(ToastVariation.Warning, config),
        );
    }

    setAnchor(elem: HTMLElement): void {
        this.anchorElement = elem;
    }

    private get latestOverlayContainerRef(): OverlayContainerRef<any> {
        const list = this.overlayContainerRefList;

        return Array.from(list.keys())[list.size - 1];
    }

    private get topPositionOffset(): string {
        let offset: number;

        if (this.overlayContainerRefList.size) {
            offset =
                this.latestOverlayContainerRef.overlayElement.getBoundingClientRect()
                    .bottom;
        } else {
            const elem = this.anchorElement
                ? this.anchorElement
                : document.querySelector<HTMLElement>(BASE_ANCHOR_CLASS_NAME);

            offset = elem?.getBoundingClientRect().height;
        }

        return `${offset}px`;
    }

    private showToast(config: ToastConfig = {}): ToastRef {
        if (this.isShowing) {
            return;
        }

        const { overlay: overlayContainerRef, toast: toastReference } =
            this.getOverlayReferences(config);
        this.isShowing = true;

        getAnimationMetadata$(
            'open',
            this.environmentService.isMobile$,
        ).subscribe((animation) => {
            overlayContainerRef.open({
                animation,
            });

            this.overlayContainerRefList.add(overlayContainerRef);
            this.handleOverlayDetachEvent(overlayContainerRef);
        });

        return {
            close$: toastReference.close$,
        };
    }

    private getOverlayReferences({
        text,
        action,
        variation,
        timeout,
    }: ToastConfig): {
        overlay: OverlayContainerRef<any>;
        toast: ToastReference;
    } {
        const overlayContainerRef = this.overlayService.createWithoutContent({
            position: OverlayGlobalPosition.TopRight,
            scrollStrategy: OverlayScrollStrategy.Noop,
            positionOffset: {
                top: this.topPositionOffset,
            },
            disposeOnNavigation: false,
        });
        const toastReference = new ToastReference(
            overlayContainerRef,
            this.environmentService,
        );
        const injector = this.getInjector(
            toastReference,
            this.environmentService,
        );

        overlayContainerRef.applyContent(
            ToastComponent,
            this.getFakeViewContainerRef(injector),
            {
                ...(text && { text }),
                action,
                variation,
                timeout,
            },
        );

        return { overlay: overlayContainerRef, toast: toastReference };
    }

    private getFakeViewContainerRef(injector: Injector): ViewContainerRef {
        return {
            injector,
        } as ViewContainerRef;
    }

    private getInjector(
        toastReference: ToastReference,
        environmentService: EnvironmentService,
    ) {
        const tokens = new WeakMap();

        tokens.set(ToastReference, toastReference);
        tokens.set(EnvironmentService, environmentService);

        return new PortalInjector(this.injector, tokens);
    }

    private handleOverlayDetachEvent(ref: OverlayContainerRef<any>): void {
        ref.event$
            .pipe(
                filter(({ type }) => type === OverlayEventType.Detach),
                take(1),
            )
            .subscribe(() => {
                this.isShowing = false;
                this.overlayContainerRefList.delete(ref);
            });
    }

    private mergeVariations(
        singleVariation: ToastVariation,
        toastConfig?: ToastConfig,
    ): ToastConfig {
        const { variation } = toastConfig;

        return {
            ...toastConfig,
            variation: variation
                ? [...variation, singleVariation]
                : [singleVariation],
        };
    }
}
