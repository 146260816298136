import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    EventEmitter,
    Input,
    NgIterable,
    OnInit,
    Output,
    QueryList,
    TemplateRef,
    inject,
} from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { flatten, includes } from 'lodash';
import { asyncScheduler } from 'rxjs';
import { observeOn } from 'rxjs/operators';

import { ButtonComponent } from '../../../../components';
import { MemoizeFuncPipe } from '../../../../pipes';
import { FilteringDirective } from '../../filtering.directive';
import {
    AppliedFilter,
    AppliedFilterValue,
    FilteringState,
    HIDDEN_ON_APPLY_FILTERS,
} from '../../models';
import { FilteringAppliedItemDefDirective } from '../item/applied-item-def.directive';

@UntilDestroy()
@Component({
    selector: 'fi-filtering-applied-items',
    templateUrl: './items.component.html',
    exportAs: 'fiFilteringAppliedItems',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgTemplateOutlet, ButtonComponent, MemoizeFuncPipe],
})
export class FilteringAppliedItemsComponent implements OnInit {
    private changeDetectorRef = inject(ChangeDetectorRef);
    private filtering = inject(FilteringDirective, { optional: true });

    @Input() hasControls = true;
    @Input() hideEmptyGroups = false;

    @Output() resetFilters = new EventEmitter<boolean>();

    @ContentChildren(FilteringAppliedItemDefDirective)
    appliedItemDefs: QueryList<FilteringAppliedItemDefDirective> &
        NgIterable<FilteringAppliedItemDefDirective>;

    appliedFilters: AppliedFilter[] = [];
    state: FilteringState['values'];

    private defaultState: FilteringState['defaults'];

    ngOnInit(): void {
        if (!this.filtering) {
            return;
        }

        this.filtering.stateChanged$
            .pipe(observeOn(asyncScheduler), untilDestroyed(this))
            .subscribe(() => this.updateState());

        this.updateState();
    }

    handleResetAppliedFilters(): void {
        if (!this.filtering) {
            return;
        }

        this.resetFilters.emit(true);
        this.filtering.resetAppliedFilters();
    }

    removeAppliedFilter(value: any, key: string, subKey?: string): void {
        if (subKey) {
            this.filtering.updateFilterValue(this.defaultState[subKey], subKey);
        }

        this.filtering.removeAppliedFilterValue(value, key);
    }

    removeAppliedFilterGroup(key: string, state: any): void {
        const values = state[key];
        this.removeAppliedFilter(values, key);
    }

    modifyListStructure(
        appliedItemDefs: QueryList<FilteringAppliedItemDefDirective>,
    ): Array<{
        key: string;
        templateRef: TemplateRef<FilteringAppliedItemDefDirective>;
        group: boolean;
    }> {
        if (!appliedItemDefs) {
            return [];
        }

        const modifiedStructure = appliedItemDefs.map((itemDef) =>
            flatten([itemDef.key]).map((key) => ({
                key,
                templateRef: itemDef.templateRef,
                group: itemDef.group,
            })),
        );

        return flatten(modifiedStructure);
    }

    getAppliedFilterDataByKey([filterKey, appliedData]: [
        string,
        AppliedFilter[],
    ]): AppliedFilter[] {
        const filteredData = appliedData.filter(
            ({ key, value }) =>
                !this.isHiddenOnApplyFilter(value) && filterKey === key,
        );

        return filterKey ? filteredData : [{ key: filterKey, value: {} }];
    }

    canReset(appliedFilters: AppliedFilter[]): boolean {
        if (!appliedFilters) {
            return false;
        }

        const isEveryReadonly = appliedFilters.every(
            ({ isReadonly }) => isReadonly,
        );
        const isEveryApplyHidden = appliedFilters.every(
            ({ value: { isApplyHidden } }) => isApplyHidden,
        );
        const isEveryHidden = isEveryReadonly || isEveryApplyHidden;

        return appliedFilters.length && !isEveryHidden;
    }

    private isHiddenOnApplyFilter({
        code,
        isApplyHidden,
    }: AppliedFilterValue): boolean {
        return isApplyHidden || includes(HIDDEN_ON_APPLY_FILTERS, code);
    }

    private updateState(): void {
        const {
            commonState: { values, defaults },
            appliedFilters,
        } = this.filtering;

        this.state = values;
        this.defaultState = defaults;
        this.appliedFilters = appliedFilters;

        this.changeDetectorRef.markForCheck();
    }
}
