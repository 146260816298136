export enum MapMarkerType {
    CurrentLocation = 'currentLocation',
    Location = 'location',
    Unit = 'unit',
    UnitWithRoadReport = 'unitWithRoadReport',
    UnitHistory = 'unitHistory',
    UnitHistoryDetails = 'unitHistoryDetails',
    UnitClusterSmall = 'unitClusterSmall',
    UnitClusterMedium = 'unitClusterMedium',
    UnitClusterLarge = 'unitClusterLarge',
    ServiceProvider = 'serviceProvider',
}
