<div
    #rootElementRef
    [class.guided-tour--active]="isTourActive"
    class="guided-tour"
>
    <ng-content></ng-content>

    @if (isTourActive) {
        <div @backdropAnimation class="guided-tour__backdrop"></div>
    }

    @if (showGuidedTourWidget) {
        <button #activatorElementRef class="guided-tour__activator">?</button>
    }
</div>

<ng-template #stepTpl>
    <div class="guided-tour__step">
        <div class="guided-tour__step-header">
            <h3 class="guided-tour__step-title fi-heading fi-heading--dark">
                {{ activeStep.title }}
            </h3>

            <fi-button
                [ariaLabel]="'Close'"
                [variation]="'squared,secondary-link'"
                (click)="stopTour()"
                class="guided-tour__step-close"
            >
                <fi-icon
                    [svgName]="'times'"
                    [variation]="'regular'"
                    [size]="'x-large'"
                ></fi-icon>
            </fi-button>
        </div>

        <div class="guided-tour__step-content">
            <ng-container
                *ngTemplateOutlet="activeStep.content || text"
            ></ng-container>

            <ng-template #text>
                <span [innerHTML]="activeStep.text" class="fi-text"></span>
            </ng-template>
        </div>

        @if ((isMobileView$ | async) === false) {
            <ng-container *ngTemplateOutlet="indicatorTpl"></ng-container>
        }

        <div class="guided-tour__step-footer">
            @if (activeStep.order !== 0) {
                <fi-button
                    [ariaLabel]="'Prev'"
                    [variation]="controlVariation"
                    [disabled]="activeStep.order === 1"
                    (click)="goToPrevStep()"
                    class="guided-tour__step-control"
                >
                    <fi-icon
                        [svgName]="'chevron-left'"
                        [variation]="'solid'"
                        [size]="'x-small'"
                    ></fi-icon>
                    <span>Prev</span>
                </fi-button>
            }

            @if (isMobileView$ | async) {
                <ng-container *ngTemplateOutlet="indicatorTpl"></ng-container>
            }

            @if (activeStep.order !== stepsQuantity) {
                <fi-button
                    [ariaLabel]="'Next'"
                    [variation]="controlVariation"
                    (click)="goToNextStep()"
                    class="guided-tour__step-control"
                >
                    <span>Next</span>
                    @if (activeStep.order !== 0) {
                        <fi-icon
                            [svgName]="'chevron-right'"
                            [variation]="'solid'"
                            [size]="'x-small'"
                        ></fi-icon>
                    }
                </fi-button>
            } @else {
                <fi-button
                    [ariaLabel]="'Close'"
                    [variation]="controlVariation"
                    (click)="stopTour()"
                    class="guided-tour__step-control"
                >
                    <span>Close</span>
                </fi-button>
            }
        </div>
    </div>
</ng-template>

<ng-template #indicatorTpl>
    @if (activeStep.order !== 0) {
        <guided-tour-indicator
            [stepsQuantity]="stepsQuantity"
            [activeStepIndex]="activeStep.order"
            [isMobileView]="isMobileView$"
            (activeStepIndexChanged)="goToStep($event)"
            class="guided-tour__step-indicator"
        ></guided-tour-indicator>
    }
</ng-template>
