<span
    *fiIsDesktop
    [class.fi-grid-expand-icon--no-active]="!isActive"
    class="fi-grid-expand-icon"
>
    <fi-icon
        [svgName]="isActive ? 'caret-down' : 'caret-right'"
        [variation]="'solid'"
        [size]="'large'"
        class="fi-grid-expand-icon__ico"
    ></fi-icon>
</span>
