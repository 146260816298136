<div
    [fiVariation]="variation"
    [fiVariationBase]="'fi-date-range-custom'"
    class="fi-date-range-custom"
>
    @if ((selected | memoizeFunc: hasBothDates) && showDatesInfo) {
        <fi-pill
            [variation]="'auto-height'"
            (pillClick)="handleReset($event)"
            class="fi-date-range-custom__selected"
        >
            {{ selected.from | timeFormat: 'SHORT_DATE' }}
            -
            {{ selected.to | timeFormat: 'SHORT_DATE' }}
        </fi-pill>
    }

    <ng-container [ngTemplateOutlet]="modal"></ng-container>
</div>

<ng-template #content>
    <fi-date-range-custom-tabs
        [active]="selectionMode"
        [tabs]="selectedState"
        [highlighted]="changedMode$ | async"
        [fromTitle]="fromTitle"
        [toTitle]="toTitle"
        [singleDaySelectionDisabled]="singleDaySelectionDisabled"
        [singleDaySelectionMessage]="singleDaySelectionMessage"
        (change)="changeRangeTab($event)"
    ></fi-date-range-custom-tabs>

    <div class="fi-date-range-custom__calendar">
        <fi-calendar
            [allDatesSelectable]="true"
            [startDate]="
                !!selectedState.from &&
                (selectedState | memoizeFunc: getDayjsStartDate : this)
            "
            [selectedDates]="selectedState | memoizeFunc: getSelectedDates"
            [minDateAvailable]="availableDateRange?.from"
            [maxDateAvailable]="
                [
                    availableDateRange?.to,
                    historicalOnly,
                    selectedState.from,
                    selectionMode
                ] | memoizeFunc: getMaxAvailableDate : this
            "
            [warnBeforeDate]="
                [selectedState, selectionMode]
                    | memoizeFunc: getToPriorFromWarningDate
            "
            [warnBeforeDateMessage]="toPriorFromWarningMessage"
            [keepCurrentMonth]="keepCurrentMonth"
            [shouldHighlightRange]="true"
            (selectDate)="handleSelectedDate($event)"
            (monthChange)="handleChangedMonth($event)"
        ></fi-calendar>
    </div>
</ng-template>

<ng-template #footer>
    <div class="fi-date-range-custom__footer">
        @if (!currentMonthSelected) {
            <span
                (click)="goToCurrentMonth()"
                role="button"
                aria-label="Current Month"
                class="fi-text fi-text--medium fi-text--link"
            >
                Current Month
            </span>
        }

        <div class="fi-date-range-custom__footer-controls">
            @if (showCancelButton) {
                <fi-button
                    [ariaLabel]="'Cancel'"
                    [variation]="'secondary'"
                    (click)="handleCancel()"
                >
                    {{ cancelButtonTitle }}
                </fi-button>
            }

            <fi-button
                [ariaLabel]="'Select Dates'"
                [variation]="'primary'"
                [disabled]="
                    !(
                        [selectedState, singleDaySelectionDisabled]
                        | memoizeFunc: hasSelectedDate : this
                    )
                "
                (click)="handleSelect()"
            >
                {{ confirmButtonTitle }}
            </fi-button>
        </div>
    </div>
</ng-template>

<ng-template #modal>
    <fi-modal
        [(open)]="open"
        [animation]="modalAnimation"
        [variation]="modalVariation"
        (event)="handleModalEvent($event)"
    >
        @if (open) {
            <fi-modal-close-bar
                *fiIsMobileDevice
                [title]="modalTitle"
                (close)="closeModal()"
            >
                <fi-button
                    [ariaLabel]="'Reset'"
                    [variation]="'squared,big,link,white-st-link'"
                    (click)="resetDates()"
                    class="fi-date-range-custom__reset-btn"
                >
                    Reset
                </fi-button>
            </fi-modal-close-bar>

            <fi-modal-header *fiIsDesktopDevice (close)="closeModal()">
                <h3 class="fi-heading fi-heading--tertiary fi-heading--dark">
                    {{ modalTitle }}
                </h3>
            </fi-modal-header>

            <fi-modal-content>
                <ng-container [ngTemplateOutlet]="content"></ng-container>
            </fi-modal-content>

            <fi-modal-footer>
                <ng-container [ngTemplateOutlet]="footer"></ng-container>
            </fi-modal-footer>
        }
    </fi-modal>
</ng-template>
