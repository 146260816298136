import {
    AfterViewInit,
    ChangeDetectorRef,
    Directive,
    ElementRef,
    Input,
    OnDestroy,
    TemplateRef,
    ViewContainerRef,
    inject,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { fromEvent, merge, Observable, of, timer } from 'rxjs';
import {
    exhaustMap,
    filter,
    first,
    mapTo,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs/operators';

import { textHasOverflow, textHasVerticalOverflow } from '../../utils';
import {
    HIDDEN_UNDER_HEADER_OVERLAY,
    OverlayConnectedPosition,
    OverlayContainerRef,
    OverlayEvent,
    OverlayEventType,
    OverlayScrollStrategy,
    OverlayService,
} from '../overlay';

import { ManualTooltipService } from './manual-tooltip.service';
import { ENTER_ANIMATIONS, LEAVE_ANIMATIONS } from './constants';
import { TooltipComponent, TooltipVariation } from './tooltip.component';

export const enum TooltipOverlayVariation {
    HiddenUnderHeader = HIDDEN_UNDER_HEADER_OVERLAY,
}

const MOUSE_MOVE_DELAY = 300;

const TOOLTIP_POSITIONS: OverlayConnectedPosition[] = [
    OverlayConnectedPosition.Top,
    OverlayConnectedPosition.TopLeft,
    OverlayConnectedPosition.TopRight,
    OverlayConnectedPosition.Bottom,
    OverlayConnectedPosition.BottomLeft,
    OverlayConnectedPosition.BottomRight,
];

@UntilDestroy()
@Directive({
    selector: '[fiTooltip]',
    standalone: true,
})
export class TooltipDirective implements AfterViewInit, OnDestroy {
    private overlayContainerRef: OverlayContainerRef<
        typeof TooltipComponent
    > | null;

    private tooltipClose$: Observable<null> = of(null).pipe(
        switchMap(() => this.overlayContainerRef.event$),
        filter(
            ({ type }: OverlayEvent) =>
                type === OverlayEventType.Detach ||
                type === OverlayEventType.Escape,
        ),
        mapTo(null),
    );

    @Input() fiTooltip: TemplateRef<any>;
    @Input() fiTooltipVariation: TooltipVariation;
    @Input() fiTooltipOverlayVariation: TooltipOverlayVariation;
    @Input() fiTooltipContext: any;
    @Input() fiTooltipPosition:
        | OverlayConnectedPosition
        | OverlayConnectedPosition[];
    @Input() fiNeedToCheckOverflow = false;
    // When ellipsis applies not for the first row, it has vertical overflow
    @Input() fiNeedToCheckVerticalOverflow = false;
    @Input() fiTooltipUpdateOnResize = false;
    @Input() keepVisibleOnHover = false;
    @Input() fiTooltipHideOnScroll = false;
    @Input() fiTooltipScrollableElement: HTMLElement;

    private readonly overlayService = inject(OverlayService);
    private readonly viewContainerRef = inject(ViewContainerRef);
    private readonly changeDetectorRef = inject(ChangeDetectorRef);
    private readonly elementRef = inject(ElementRef);
    private readonly manualTooltipService = inject(ManualTooltipService);

    ngAfterViewInit(): void {
        this.handleTooltipEvents();
        this.handleManualTooltip();
        this.hideOnScroll();
    }

    ngOnDestroy(): void {
        this.hideTooltip();
    }

    showTooltip(): void {
        // in case when tooltip was already opened externally
        if (this.overlayContainerRef) {
            return;
        }

        this.overlayContainerRef = this.overlayService.create(
            TooltipComponent,
            this.viewContainerRef,
            {
                variation: ['tooltip-v2', this.fiTooltipOverlayVariation],
                positions: this.fiTooltipPosition || TOOLTIP_POSITIONS,
                scrollStrategy: OverlayScrollStrategy.Close,
            },
            {
                tpl: this.fiTooltip,
                variation: this.fiTooltipVariation,
                tplContext: this.fiTooltipContext,
            },
            this.elementRef,
        );

        this.overlayContainerRef.open({
            animation: ENTER_ANIMATIONS,
            withoutFocus: true,
        });

        if (this.elementRef && this.fiTooltipUpdateOnResize) {
            this.overlayContainerRef.attachRelativePosition(
                this.elementRef.nativeElement,
            );
        }

        this.changeDetectorRef.markForCheck();
    }

    hideTooltip(): void {
        if (!this.overlayContainerRef) {
            return;
        }

        this.overlayContainerRef.close({
            animation: LEAVE_ANIMATIONS,
        });

        this.overlayContainerRef = null;
    }

    private handleTooltipEvents(): void {
        merge(
            fromEvent(this.elementRef.nativeElement, 'mouseenter'),
            fromEvent(this.elementRef.nativeElement, 'touchstart'),
        )
            .pipe(
                filter(() => {
                    if (
                        this.fiNeedToCheckOverflow &&
                        this.fiNeedToCheckVerticalOverflow
                    ) {
                        return (
                            textHasOverflow(this.elementRef.nativeElement) ||
                            textHasVerticalOverflow(
                                this.elementRef.nativeElement,
                            )
                        );
                    }

                    if (this.fiNeedToCheckOverflow) {
                        return textHasOverflow(this.elementRef.nativeElement);
                    }

                    if (this.fiNeedToCheckVerticalOverflow) {
                        return textHasVerticalOverflow(
                            this.elementRef.nativeElement,
                        );
                    }

                    return true;
                }),
                filter(() => !!this.fiTooltip),
                exhaustMap(() => {
                    this.showTooltip();

                    return merge(
                        this.keepVisibleOnHover
                            ? this.handleHoveredTooltip()
                            : fromEvent(
                                this.elementRef.nativeElement,
                                'mouseleave',
                            ),
                        this.tooltipClose$,
                    ).pipe(first(), untilDestroyed(this));
                }),
                untilDestroyed(this),
            )
            .subscribe(() => {
                this.hideTooltip();
            });
    }

    private handleManualTooltip(): void {
        this.manualTooltipService.tooltipVisible$
            .pipe(
                tap((isVisible) => {
                    isVisible ? this.showTooltip() : this.hideTooltip();
                }),
                untilDestroyed(this),
            )
            .subscribe();
    }

    private handleHoveredTooltip(): Observable<any> {
        const elementMouseEnterEvent$ = fromEvent(
            this.elementRef.nativeElement,
            'mouseenter',
        );
        const elementMouseLeaveEvent$ = fromEvent(
            this.elementRef.nativeElement,
            'mouseleave',
        );
        const tooltipMouseEnterEvent$ = fromEvent(
            this.overlayContainerRef.overlayElement,
            'mouseenter',
        );
        const tooltipMouseLeaveEvent$ = fromEvent(
            this.overlayContainerRef.overlayElement,
            'mouseleave',
        );

        return merge(
            elementMouseLeaveEvent$.pipe(
                switchMap(() =>
                    timer(MOUSE_MOVE_DELAY).pipe(
                        takeUntil(tooltipMouseEnterEvent$),
                    ),
                ),
            ),
            tooltipMouseLeaveEvent$.pipe(
                switchMap(() =>
                    timer(MOUSE_MOVE_DELAY).pipe(
                        takeUntil(elementMouseEnterEvent$),
                    ),
                ),
            ),
        );
    }

    private hideOnScroll(): void {
        if (!this.fiTooltipHideOnScroll) {
            return;
        }

        const scrollableElement = this.fiTooltipScrollableElement || window;
        fromEvent(scrollableElement, 'scroll')
            .pipe(untilDestroyed(this))
            .subscribe(() => this.hideTooltip());
    }
}
