import { Pipe, PipeTransform } from '@angular/core';

const TRUNCATE_SUFFIX = '...';
const DEFAULT_TRUNCATE_LIMIT = 25;

@Pipe({
    name: 'truncate',
    standalone: true,
})
export class TruncateTextPipe implements PipeTransform {
    transform(value = '', limit = DEFAULT_TRUNCATE_LIMIT): string {
        const suffix = TRUNCATE_SUFFIX;
        let result = value;

        if (value.length > Math.abs(limit)) {
            result = `${value.slice(0, limit)}${suffix}`;
        }

        return result;
    }
}
