import { NgControl } from '@angular/forms';
import { DecimalPipe } from '@angular/common';

import { Observable } from 'rxjs';

import {
    getNumericInputCaretOriginalPosition,
    replaceValueNonDigits,
} from '../../../utils';
import { InputFormatModifierType } from '../../input/input.component';

import { InputFormatBase, InputFormatParsedValue } from './input-format-base';

export class OdometerHoursModifierFormat extends InputFormatBase {
    valueChanges$: Observable<string>;

    constructor(
        ngControl: NgControl,
        private decimalPipe: DecimalPipe,
        private inputElement: HTMLInputElement,
        private initialValue: string,
        private formatModifier: InputFormatModifierType,
    ) {
        super(ngControl);

        const transformedInitialValue = this.getTransformedValue(
            this.initialValue,
        );
        this.setControlValue(transformedInitialValue);

        this.valueChanges$ = this.getInputEvent(transformedInitialValue);
    }

    protected getParsedValue([
                                 _previousValue,
                                 currentValue,
                             ]: string[]): InputFormatParsedValue {
        return {
            transformedValue: this.getTransformedValue(currentValue),
        };
    }

    protected returnCaretToOriginalPosition({
                                                caretPosition,
                                                originalValue,
                                                transformedValue,
                                                isValueDeleted,
                                            }: InputFormatParsedValue): void {

        const beforeCaretValue = replaceValueNonDigits(
            transformedValue.substring(0, caretPosition),
        );

        let targetCaretPosition = getNumericInputCaretOriginalPosition(
            beforeCaretValue,
            transformedValue,
            isValueDeleted,
        );
        targetCaretPosition = isValueDeleted
            ? targetCaretPosition
            : targetCaretPosition - (this.formatModifier.length + 1);

        this.inputElement.setSelectionRange(
            targetCaretPosition,
            targetCaretPosition,
        );
    }

    private getTransformedValue(value: string): string {
        if (!value) {
            return '';
        }

        const digitsValue = this.transformValue(replaceValueNonDigits(value));

        return digitsValue
            ? `${digitsValue} ${this.formatModifier}`
            : '';
    }

    private transformValue(value: string): string {
        return this.decimalPipe.transform(value) || '';
    }
}
