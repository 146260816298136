import {
    Component,
    AfterViewInit,
    Input,
    ViewChild,
    ElementRef,
    inject,
} from '@angular/core';
import { AsyncPipe } from '@angular/common';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { timer, Observable, fromEvent } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { FiIconSize } from '../components/icon';
import { IconComponent } from '../components';
import { VariationDirective } from '../directives';
import { MemoizeFuncPipe } from '../pipes';
import { CONFIG } from '../config';
import { EnvironmentService } from '../services';

import { ToastReference } from './toast-reference';

export const enum ToastVariation {
    Success = 'success',
    Error = 'error',
    Compact = 'compact',
    WithoutMessage = 'without-message',
    Warning = 'warning',
}

export const enum IconName {
    Success = 'check',
    Error = 'times',
    Warning = 'exclamation',
}

const DEFAULT_TEXT = 'The submitted change has been saved.';

@UntilDestroy()
@Component({
    selector: 'fi-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss'],
    standalone: true,
    imports: [
        AsyncPipe,
        VariationDirective,
        IconComponent,
        MemoizeFuncPipe,
    ]
})
export class ToastComponent implements AfterViewInit {
    private readonly toastReference = inject(ToastReference);
    private readonly environmentService = inject(EnvironmentService);

    @Input() text = DEFAULT_TEXT;
    @Input() action: string;
    @Input() variation: ToastVariation[];
    @Input() timeout: number;

    readonly isMobileDevice$ = this.environmentService.isMobileDevice$;

    @ViewChild('container', { read: ElementRef })
    private containerElementRef: ElementRef;
    @ViewChild('actionControl', { read: ElementRef })
    private actionElementRef: ElementRef;

    private closeClick$: Observable<void>;
    private closeWithActionClick$: Observable<void>;

    ngAfterViewInit(): void {
        this.handleCloseEvents();

        timer(this.timeout || CONFIG.MESSAGE_TIMEOUT)
            .pipe(
                takeUntil(this.closeClick$),
                takeUntil(this.closeWithActionClick$),
            )
            .subscribe(() => this.close());
    }

    shouldShowToastLabel([isMobileDevice, variations]: [
        boolean,
        ToastVariation[],
    ]): boolean {
        return !isMobileDevice || this.hasWithoutMessageVariation(variations);
    }

    getIconName(variations: ToastVariation[]): IconName {
        if (this.hasSuccessVariation(variations)) {
            return IconName.Success;
        }

        if (this.hasWarningVariation(variations)) {
            return IconName.Warning;
        }

        return IconName.Error;
    }

    getIconSize(isMobile: boolean): FiIconSize {
        return isMobile ? FiIconSize.XXSmall : FiIconSize.Small;
    }

    getLabel(variations: ToastVariation[]): string {
        if (this.hasSuccessVariation(variations)) {
            return 'Success!';
        }

        return 'Error!';
    }

    private handleCloseEvents(): void {
        this.closeClick$ = fromEvent<void>(
            this.containerElementRef.nativeElement,
            'click',
        ).pipe(
            tap(() => this.close()),
            untilDestroyed(this),
        );

        this.closeWithActionClick$ = fromEvent<void>(
            this.actionElementRef.nativeElement,
            'click',
        ).pipe(
            tap(() => this.close(true)),
            untilDestroyed(this),
        );
    }

    private close(withAction = false): void {
        this.toastReference.close(withAction);
    }

    private hasSuccessVariation(variations: ToastVariation[]): boolean {
        return variations.some(
            (variation) => variation === ToastVariation.Success,
        );
    }

    private hasWithoutMessageVariation(variations: ToastVariation[]): boolean {
        return variations.some(
            (variation) => variation === ToastVariation.WithoutMessage,
        );
    }

    private hasWarningVariation(variations: ToastVariation[]): boolean {
        return variations.some(
            (variation) => variation === ToastVariation.Warning,
        );
    }
}
