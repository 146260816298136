import { LeaveConfirmationModalConfig } from '../leave-confirmation-modal/leave-confirmation-modal.component';

export const DEFAULT_LEAVE_BTN_TEXT = 'Leave page';

export const DEFAULT_LEAVE_MODAL_CONFIG: LeaveConfirmationModalConfig = {
    title: 'Unsaved Changes',
    description:
        'Please be advised, your entry was not submitted. If you leave, your entry will be deleted. Do you wish to continue?',
    leaveBtnText: DEFAULT_LEAVE_BTN_TEXT,
};
