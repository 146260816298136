import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    inject,
} from '@angular/core';
import { DOCUMENT, NgTemplateOutlet } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { finalize, first, map, takeUntil } from 'rxjs/operators';
import { fromEvent, Subject } from 'rxjs';
import { isEmpty } from 'lodash';

import {
    AttachmentHelperService,
    FileType,
    FileTypeIcon,
} from '../../../services';
import { OnChange } from '../../../decorators';
import { ToastService } from '../../../toast';
import {
    IsDesktopDirective,
    IsMobileDirective,
    FileDropDirective,
} from '../../../directives';
import { IconComponent } from '../../../components';
import { MemoizeFuncPipe, TruncateTextPipe } from '../../../pipes';
import { FileDropOptions, FileListItem, FileValidationErrors } from '../models';

import { FilesUploadService } from './files-upload.service';
import { DeleteUploadedFileModalComponent } from './delete-uploaded-file-modal/delete-uploaded-file-modal.component';

export const DropErrorTitles: { [key: string]: string } = {
    FILE_SIZE: 'Exceeded 5MB size limit',
    FILE_TYPE: 'File format not supported',
    FILES_QUANTITY: 'To much files to upload at once',
    UNIT_NUMBER_REQUIRED:
        'To upload a file Penske unit or Non-Contract unit should be filled',
    FILE_UPLOAD: 'There is a Problem Uploading Your File or Files',
    FILE_REMOVE: 'There is a Problem Removing Your File',
    FILE_NAME_LENGTH: 'File name should be less than 45 characters',
};

export const UPLOAD_FILES_OPTIONS: FileDropOptions = {
    fileSize: 5242880,
    fileType: [
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
        'application/msword',
        'application/pdf',
        'image/png',
        'image/jpeg',
        'video/avi',
        'video/mp4',
        'video/mpeg',
        'video/quicktime',
    ],
    filesQuantity: 10,
    fileNameLength: 45,
};

export const TRUNCATE_LENGTH = 35;
const SUCCESS_DELETED_FILE_MESSAGE = 'Document deleted';

@UntilDestroy()
@Component({
    selector: 'files-upload',
    templateUrl: './files-upload.component.html',
    styleUrls: ['./files-upload.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgTemplateOutlet,
        IsDesktopDirective,
        IsMobileDirective,
        IconComponent,
        DeleteUploadedFileModalComponent,
        MemoizeFuncPipe,
        TruncateTextPipe,
        FileDropDirective,
    ],
})
export class FilesUploadComponent implements OnInit {
    private attachmentHelperService = inject(AttachmentHelperService);
    private document = inject<Document>(DOCUMENT);
    private filesUploadService = inject(FilesUploadService);
    private toastService = inject(ToastService);

    @OnChange('setFilesList')
    @Input()
    attachments: FileListItem[];
    filesList: FileListItem[];

    @Input() unitNumber: string;
    @Input() requestId: string;

    @Output() uploadChange = new EventEmitter<FileListItem[]>();

    readonly truncateLength = TRUNCATE_LENGTH;
    readonly deletedFileSuccessMessage = SUCCESS_DELETED_FILE_MESSAGE;
    readonly fileUploadOptions: FileDropOptions = UPLOAD_FILES_OPTIONS;

    dropZoneActive: boolean;
    uploadErrors: FileValidationErrors[] = [];
    cancelEvent$ = new Subject();

    deactivateModalOpen = false;
    fileToDelete: FileListItem;
    openUserDetailsId: string;

    ngOnInit(): void {
        this.dataInit();
    }

    dropZoneState(isDropZoneHovered: boolean): void {
        this.dropZoneActive = isDropZoneHovered;
    }

    handleFileDropped(droppedList: FileList): void {
        if (!this.unitNumber) {
            this.handleErrorsMessages(DropErrorTitles.UNIT_NUMBER_REQUIRED);
            return;
        }
        const filesCreated = this.fileListCreation(droppedList);
        this.uploadAttachments(filesCreated);
    }

    onRemoveFile(index: number): void {
        this.fileToDelete = this.filesList[index];

        if (isEmpty(this.fileToDelete)) {
            return;
        }

        this.deactivateModalOpen = true;
    }

    chooseAndUploadFile(): void {
        if (!this.unitNumber) {
            this.handleErrorsMessages(DropErrorTitles.UNIT_NUMBER_REQUIRED);
            return;
        }

        let fileInput = this.document.createElement('input');
        fileInput.multiple = true;
        fileInput.type = 'file';

        fromEvent(fileInput, 'change')
            .pipe(
                first(),
                map((event) => {
                    const target = event.target as HTMLInputElement;
                    return this.fileListCreation(target.files);
                }),
                finalize(() => (fileInput = null)),
                untilDestroyed(this),
            )
            .subscribe((filesCreated) => {
                this.uploadAttachments(filesCreated);
            });

        fileInput.click();
    }

    formatBytes(bytes: string, decimals = 2): string {
        if (!bytes) {
            return '';
        }

        const bytesNum = Number(bytes);
        if (bytesNum === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytesNum) / Math.log(k));
        return (
            parseFloat((bytesNum / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
        );
    }

    chooseIconType(uploadItem: FileListItem): FileTypeIcon {
        const attachmentExtension = this.getFileType(uploadItem);

        return this.attachmentHelperService.getAttachmentIcon(
            attachmentExtension,
        );
    }

    setFilesList(): void {
        this.filesList = [...this.attachments];
    }

    trackByIndex(index: number): number {
        return index;
    }

    closeDeactivateModal(): void {
        this.deactivateModalOpen = false;
    }

    deleteUploadedFile(): void {
        const id = this.fileToDelete.attachmentId;
        const index = this.filesList.indexOf(this.fileToDelete);

        this.closeDeactivateModal();
        if (!id) {
            this.handleRemoveFile(index);
            this.cancelEvent$.next(true);
            return;
        }

        this.filesUploadService
            .removeAttachment([this.filesList[index].attachmentId])
            .pipe(untilDestroyed(this))
            .subscribe(
                () => {
                    this.handleRemoveFile(index);
                },
                () => {
                    this.handleErrorsMessages(DropErrorTitles.FILE_REMOVE);
                },
            );
    }

    private getFileType(uploadItem: FileListItem): FileType | string {
        const fileName = uploadItem.attachmentName || null;
        return (
            uploadItem.attachmentExtension ||
            fileName.substring(fileName.lastIndexOf('.'), fileName.length) ||
            fileName
        );
    }

    private dataInit(): void {
        this.cancelEvent$.pipe(untilDestroyed(this)).subscribe();
    }

    private fileListCreation(filesList: FileList): FileListItem[] {
        this.uploadErrors = [
            ...this.getMultipleFilesValidationErrors(filesList),
        ] as FileValidationErrors[];

        const filesCreated = [...filesList].map((file) => {
            const fileUploadCheck = this.getSingleFileValidationErrors(file);

            return {
                isUploading: true,
                uploadError: fileUploadCheck.length
                    ? DropErrorTitles[fileUploadCheck[0]]
                    : '',
                attachmentName: file.name,
                attachmentSize: file.size.toString(),
                file,
            };
        });
        this.filesList = [...this.filesList, ...filesCreated];
        this.notifyFilesChanged();

        return filesCreated;
    }

    private uploadAttachments(filesCreated: FileListItem[]): void {
        filesCreated.forEach((uploadItem: FileListItem) => {
            if (uploadItem.uploadError) {
                uploadItem.isUploading = false;
                return;
            }

            return this.filesUploadService
                .uploadAttachments(
                    uploadItem.file,
                    this.unitNumber,
                    this.requestId,
                )
                .pipe(takeUntil(this.cancelEvent$), untilDestroyed(this))
                .subscribe(
                    (resp: any) => {
                        if (resp.status === 200) {
                            uploadItem.isUploading = false;
                            uploadItem.attachmentId =
                                !!resp.body && resp.body.attachmentId;
                            this.notifyFilesChanged();
                        }
                    },
                    (errorResp: HttpErrorResponse) => {
                        uploadItem.isUploading = false;
                        uploadItem.uploadError =
                            errorResp.error.message || 'Unknown Error';
                        this.notifyFilesChanged();
                    },
                );
        });
    }

    private notifyFilesChanged(): void {
        this.uploadChange.emit([...this.filesList]);
    }

    private handleErrorsMessages(error: string): void {
        this.toastService.error({ text: error });
    }

    private handleSuccessMessages(): void {
        this.toastService.success({ text: this.deletedFileSuccessMessage });
    }

    private getMultipleFilesValidationErrors(fileList: FileList): string[] {
        return this.filesUploadService.errorListDefine(
            fileList,
            UPLOAD_FILES_OPTIONS,
        );
    }

    private getSingleFileValidationErrors(file: File): string[] {
        return this.filesUploadService.isOneFileDropError(
            file,
            UPLOAD_FILES_OPTIONS,
        );
    }

    private handleRemoveFile(index: number): void {
        this.filesList.splice(index, 1);
        this.handleSuccessMessages();
        this.notifyFilesChanged();
    }
}
