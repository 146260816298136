import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostBinding,
    HostListener,
    Input,
    inject,
} from '@angular/core';
import { Highlightable } from '@angular/cdk/a11y';
import { NgTemplateOutlet } from '@angular/common';

import { Observable, fromEvent, from } from 'rxjs';
import { mapTo, filter, withLatestFrom } from 'rxjs/operators';

import {
    FiIconVariation,
    FiIconSize,
    IconComponent,
} from '../../../components/icon';
import { ButtonComponent } from '../../../components';
import { VariationDirective } from '../../../directives';
import { TooltipDirective } from '../../tooltip';
import { OverlayConnectedPosition } from '../../overlay';

const enum IconTypes {
    Success = 'success',
    Error = 'error',
}

@Component({
    selector: 'fi-context-menu-item',
    templateUrl: './item.component.html',
    styleUrls: ['./item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgTemplateOutlet,
        VariationDirective,
        TooltipDirective,
        IconComponent,
        ButtonComponent,
    ],
})
export class ContextMenuItemComponent implements Highlightable {
    elementRef = inject(ElementRef);

    @Input() withSeparator = false;
    @Input() withDistinctSeparator = false;
    @Input() disabled = false;
    @Input() icon: string;
    @Input() iconType: IconTypes;
    @Input() iconVariation = FiIconVariation.Light;
    @Input() iconSize = FiIconSize.Medium;
    @Input() tooltip: string;
    @Input() tooltipPosition: OverlayConnectedPosition;
    @Input() inContainer = false;

    // for menu item like tabs
    @Input() noAction = false;

    @HostBinding() tabindex = -1;

    focused = false;

    click$: Observable<null> = fromEvent(
        this.elementRef.nativeElement,
        'mousedown',
    ).pipe(
        withLatestFrom(from([this.disabled])),
        filter(() => !this.noAction),
        mapTo(null),
    );

    @HostListener('mousedown', ['$event'])
    onMouseDown(event: MouseEvent): void {
        if (!this.inContainer) {
            event.stopPropagation();
        }

        event.preventDefault();

        if (!this.disabled) {
            const triggeredEvent = new MouseEvent('click', {
                bubbles: true,
            });
            this.elementRef.nativeElement.dispatchEvent(triggeredEvent);
        }
    }

    setActiveStyles(): void {
        this.focused = true;
    }

    setInactiveStyles(): void {
        this.focused = false;
    }
}
