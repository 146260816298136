import { NgControl } from '@angular/forms';

import { Observable } from 'rxjs';

import {
    transformPhoneValue,
    getNumericInputCaretOriginalPosition,
    replaceValueNonDigits,
} from '../../../utils';
import { ConfigApp } from '../../../services/config.service';

import { InputFormatBase, InputFormatParsedValue } from './input-format-base';

export class PhoneInputFormat extends InputFormatBase {
    valueChanges$: Observable<string>;

    constructor(
        ngControl: NgControl,
        private inputElement: HTMLInputElement,
        private initialValue: string,
        private CONFIG: ConfigApp,
    ) {
        super(ngControl);

        const transformedInitialValue = this.getTransformedValue(
            this.initialValue,
        );
        this.setControlValue(transformedInitialValue);

        this.valueChanges$ = this.getInputEvent(transformedInitialValue);
    }

    protected getParsedValue([
        previousValue,
        currentValue,
    ]: string[]): InputFormatParsedValue {
        const {
            selectionEnd: caretPosition,
            value: originalValue, // just updated yet unformatted value
        } = this.inputElement;
        const isValueDeleted = originalValue.length < previousValue.length;

        return {
            caretPosition,
            isValueDeleted,
            originalValue,
            transformedValue: this.getTransformedValue(
                currentValue,
                isValueDeleted,
            ),
        };
    }

    protected returnCaretToOriginalPosition({
        caretPosition,
        originalValue,
        transformedValue,
        isValueDeleted,
    }: InputFormatParsedValue) {
        const beforeCaretValue = replaceValueNonDigits(
            originalValue.substring(0, caretPosition),
            this.CONFIG.PHONE_NUMBER_LENGTH,
        );

        const targetCaretPosition = getNumericInputCaretOriginalPosition(
            beforeCaretValue,
            transformedValue,
            isValueDeleted,
        );

        this.inputElement.setSelectionRange(
            targetCaretPosition,
            targetCaretPosition,
        );
    }

    private getTransformedValue(
        value: string,
        isValueDeleted?: boolean,
    ): string {
        return transformPhoneValue(value, isValueDeleted);
    }
}
