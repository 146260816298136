import { NgModule, Type } from '@angular/core';

import { SearchComponent } from './search.component';
import { SearchAutocompleteListComponent } from './autocomplete-list/autocomplete-list.component';
import { SearchTrimOnPasteDirective } from './search-trim-on-paste.directive';

const DECLARABLES: Array<Type<any> | any[]> = [
    SearchAutocompleteListComponent,
    SearchTrimOnPasteDirective,
];
const PUBLIC_DECLARABLES: Array<Type<any> | any[]> = [SearchComponent];

@NgModule({
    imports: [...DECLARABLES, ...PUBLIC_DECLARABLES],
    exports: [...PUBLIC_DECLARABLES],
})
export class SearchModule {}
