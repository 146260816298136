import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    NgIterable,
    OnInit,
    QueryList,
    ViewEncapsulation,
    inject,
} from '@angular/core';
import { NgTemplateOutlet, AsyncPipe } from '@angular/common';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { cloneDeep } from 'lodash';

import { VariationDirective } from '../../../directives';
import { GridOnTabletDirective } from '../../grid/on-tablet/on-tablet.directive';
import { FilteringDirective } from '../filtering.directive';
import { FilteringItemDefDirective } from '../item/item-def.directive';
import { FilteringState, FilteringViewState } from '../models';

export type FilterItemsVariations =
    | 'mobile'
    | 'desktop'
    | 'desktop-column'
    | 'full-height'
    | 'gray-bg';

@UntilDestroy()
@Component({
    selector: 'fi-filtering-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgTemplateOutlet, AsyncPipe, VariationDirective],
})
export class FilteringListComponent implements OnInit {
    private changeDetectorRef = inject(ChangeDetectorRef);
    private filtering = inject(FilteringDirective, { optional: true });
    private gridOnTablet = inject(GridOnTabletDirective, { optional: true });

    @Input() variation: FilterItemsVariations = 'desktop';
    @Input() applyAfterChange: boolean;

    readonly isGridOnTablet$ = this.gridOnTablet?.isGridOnTablet$;

    filterDefs: QueryList<FilteringItemDefDirective> &
        NgIterable<FilteringItemDefDirective>;

    state: FilteringState['values'] = {};
    defaultState: FilteringState['defaults'] = {};
    viewState: FilteringViewState = {};

    ngOnInit(): void {
        if (!this.filtering) {
            return;
        }

        this.filtering.stateChanged$
            .pipe(untilDestroyed(this))
            .subscribe(() => this.updateState());

        this.updateState();
    }

    update(value: any, key: string | string[]): void {
        if (!this.filtering) {
            return;
        }

        if (Array.isArray(key)) {
            key.map((item) => {
                this.filtering.updateFilterValue(value[item], item);
            });
        } else {
            this.filtering.updateFilterValue(value, key as string);
        }

        if (this.applyAfterChange) {
            this.filtering.applyFilters();
        }
    }

    updateViewValue(value: FilteringViewState): void {
        if (!value) {
            return;
        }

        this.filtering.updateViewValue(value);
    }

    private updateState(): void {
        this.filterDefs = this.filtering.filterDefs;
        this.state = cloneDeep(this.filtering.state);
        this.defaultState = cloneDeep(this.filtering.defaultState);
        this.viewState = cloneDeep(this.filtering.viewState);

        this.changeDetectorRef.markForCheck();
    }
}
