import { OverlayModuleOwn } from './overlay';
import { DropdownModule } from './dropdown';
import { TooltipModule } from './tooltip';
import { AccordionModule } from './accordion';
import { ContextMenuModule } from './context-menu';
import { DateModule } from './date';
import { FilteringModule } from './filtering';
import { GridModule } from './grid';
import { GuidedTourModule } from './guided-tour/guided-tour.module';
import { LocationMapModule } from './location-map';
import { ModalModule } from './modal';
import { PillModule } from './pill';
import { SearchModule } from './search';
import { SidePanelModule } from './side-panel';
import { TimeModule } from './time-picker';
import { SelectModule } from './select';
import { SelectMenuModule } from './select-menu';
import { IndicatorModule } from './indicator';
import { PreventLeaveModalModule } from './prevent-leave-modal';
import { SelectModalFieldModule } from './select-modal-field';
import { DraggableListModule } from './draggable-list';
import { SectionItemModule } from './section-item';
import { DragNDropAreaModule } from './drag-n-drop-upload-area';
import { ChartsModule } from './charts';
import { MetricsModule } from './metrics';
import { MetricsTabsModule } from './metrics-tabs';
import { SlidePanelModule } from './slide-panel';
import { SelectDropdownModule } from './select-dropdown';

export const features = [
    OverlayModuleOwn,
    DropdownModule,
    TooltipModule,
    AccordionModule,
    ContextMenuModule,
    DateModule,
    FilteringModule,
    GridModule,
    GuidedTourModule,
    LocationMapModule,
    ModalModule,
    PillModule,
    SearchModule,
    SelectModule,
    SelectMenuModule,
    SidePanelModule,
    TimeModule,
    IndicatorModule,
    PreventLeaveModalModule,
    SelectModalFieldModule,
    DraggableListModule,
    SectionItemModule,
    DragNDropAreaModule,
    ChartsModule,
    MetricsModule,
    MetricsTabsModule,
    SlidePanelModule,
    SelectDropdownModule,
];
