import { Directive, Input, TemplateRef, inject } from '@angular/core';

type Variation =
    | 'no-bottom-border'
    | 'no-indent'
    | 'col-half'
    | 'col-quarter'
    | 'gray-bg'
    | 'full-width';

@Directive({
    selector: '[fiFilteringItemDef]',
    standalone: true,
})
export class FilteringItemDefDirective {
    templateRef = inject<TemplateRef<FilteringItemDefDirective>>(TemplateRef);

    @Input('fiFilteringItemDef') key: string;
    @Input('fiFilteringItemDefVariation') variation: Variation;
}
