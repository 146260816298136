import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'pluralizeString',
    standalone: true,
})
export class PluralizeStringPipe implements PipeTransform {
    transform(
        amount: number,
        singleKey: string,
        pluralKey: string,
        inclusive = true,
    ) {
        return `${inclusive ? amount + ' ' : ''}${
            amount === 1 ? singleKey : pluralKey
        }`;
    }
}
