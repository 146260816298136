import { AfterViewInit, ContentChild, Directive, Input } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { GridExpandDirective } from './expand.directive';

@UntilDestroy()
@Directive({
    selector: '[fiGridExpandingRow]',
    exportAs: 'fiGridExpandingRow',
    standalone: true,
})
export class GridExpandingRowDirective implements AfterViewInit {
    @Input('fiGridExpandingRow') row: any;

    isActive = false;

    @ContentChild(GridExpandDirective)
    expand: GridExpandDirective;

    ngAfterViewInit(): void {
        if (!this.expand) {
            return;
        }

        this.attachSortChangeListener();
        this.updateExpandState();
    }

    private attachSortChangeListener(): void {
        this.expand.stateChanged
            .asObservable()
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.updateExpandState();
            });
    }

    private updateExpandState(): void {
        this.isActive = this.expand.state;
    }
}
