<div
    [fiVariation]="variation"
    [fiVariationBase]="'fi-search-autocomplete-list'"
>
    <fi-list-component #list>
        @if (options?.length && header) {
            <header class="fi-search-autocomplete-list__header">
                <h4 >{{ header }}</h4>
            </header>
        }

        @for (option of options; track trackByValue(option)) {
            <button
                fiListItem
                [option]="option"
                [label]="option.text"
                [fiVariation]="option.variation"
                [fiVariationBase]="baseItemClass"
                [ngClass]="option.gtmClassName"
                (activeOptionChange)="handleActiveOption(option)"
                (optionFocused)="onFocus($event)"
                (click)="handleSelectOption(option)"
            >
                @if (option.icon) {
                    <fi-icon
                        [svgName]="option.icon"
                        [size]="settings?.iconsSize || option.iconSize || 'small'"
                        [variation]="option.iconVariation || 'light'"
                        class="fi-search-autocomplete-list__icon"
                    ></fi-icon>
                }

                <span class="fi-search-autocomplete-list__text">
                    <fi-highlight-text
                        [fullText]="option | memoizeFunc: displayableValue"
                        [highlightQuery]="highlightTextMatch ? value : ''"
                        [baseVariation]="option.textVariation || ''"
                    ></fi-highlight-text>
                </span>
            </button>
        }

        <div class="fi-search-autocomplete-list__separator"></div>

        @if (suggestions.length) {
            <header
                [class.fi-search-autocomplete-list__header--padded]="isHeadingPadded"
                class="fi-search-autocomplete-list__header">
                <h4>{{ suggestionsHeader }}</h4>
            </header>
        }

        @for (suggestion of suggestions; track trackByValue(suggestion)) {
            <button
                fiListItem
                [option]="suggestion"
                [fiVariation]="suggestion.variation"
                [fiVariationBase]="baseItemClass"
                [ngClass]="suggestion.gtmClassName"
                (optionFocused)="onFocus($event)"
                (activeOptionChange)="handleActiveOption(suggestion)"
                (click)="handleSelectOption(suggestion)"
            >
                @if (suggestion.icon) {
                    <fi-icon
                        [svgName]="suggestion.icon"
                        [size]="settings?.iconsSize || suggestion.iconSize || 'small'"
                        [variation]="suggestion.iconVariation || 'light'"
                        class="fi-search-autocomplete-list__icon"
                    ></fi-icon>
                }

                <span class="fi-search-autocomplete-list__text">
                    <fi-highlight-text
                        [fullText]="suggestion | memoizeFunc: displayableValue"
                        [highlightQuery]="highlightTextMatch ? value : ''"
                        [baseVariation]="suggestion.textVariation || ''"
                    ></fi-highlight-text>
                </span>
            </button>
        }
    </fi-list-component>
</div>
