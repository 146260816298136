import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    provideHttpClient,
    withInterceptorsFromDi,
} from '@angular/common/http';

import { PnskCommonModule } from '@pnsk/ui-common/pnsk-common.module';

import { httpInterceptorProviders } from './core/services/http/interceptors';
import { AnalyticsModule } from './core/libs/analytics';
import { AppComponent } from './app.component';
import { RequestAccessModule } from './request-access/request-access.module';

@NgModule({
    declarations: [AppComponent],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        PnskCommonModule,
        RequestAccessModule,
        AnalyticsModule,
    ],
    providers: [
        httpInterceptorProviders,
        provideHttpClient(withInterceptorsFromDi()),
    ],
})
export class AppModule {}
