<div class="fi-select-modal-field">
    @if (isDesktopView$ | async) {
        <div class="fi-select-modal-field__pill-slot">
            <button
                [class.fi-select-modal-field__title-with-margin]="
                    selected | memoizeFunc: getSelectedCount
                "
                [disabled]="disabled"
                (click)="openModal()"
                type="button"
                aria-label="Open modal"
                class="fi-select-modal-field__title-btn fi-text fi-text--medium fi-text--link"
            >
                {{ title }}
            </button>

            @if (selected | memoizeFunc: getSelectedCount) {
                @let selectedDescription =
                    [selected, isMobileView$ | async, isMultiple]
                        | memoizeFunc: getSelectedDescription : this;
                <div>
                    <fi-pill
                        [variation]="variation || 'with-tooltip'"
                        [tooltipValue]="selectedDescription"
                        [readonly]="disabled"
                        (timesClick)="clearSelection()"
                        (pillClick)="clearSelection()"
                    >
                        <span>
                            {{ selectedDescription }}
                        </span>
                    </fi-pill>
                </div>
            }
        </div>
    }

    @if (isMobileView$ | async) {
        <div
            (click)="openModal()"
            aria-label="Open modal"
            role="button"
            class="fi-select-modal-field__mobile"
        >
            @if (icon) {
                <fi-icon
                    [svgName]="icon"
                    [size]="'x-large'"
                    [variation]="iconVariation"
                ></fi-icon>
            }
            <div class="fi-select-modal-field__mobile-title">
                <span class="fi-text fi-text--dark fi-text--heavy">
                    {{ mobileTitle || title }}
                </span>

                @if (selected | memoizeFunc: getSelectedCount) {
                    <div>
                        <span class="fi-text fi-text--small fi-text--medium">
                            {{
                                [selected, isMobileView$ | async, isMultiple]
                                    | memoizeFunc: getSelectedDescription : this
                            }}
                        </span>

                        @if (
                            (isMobileView$ | async) &&
                            modalConfig.labelShownAsSecondary &&
                            !modalConfig.showTotal
                        ) {
                            <span>
                                <fi-icon
                                    [svgName]="'circle'"
                                    [variation]="'solid'"
                                    [size]="'xxx-small'"
                                    class="fi-text fi-select-modal-field__selected-label-icon"
                                ></fi-icon>
                                <span
                                    class="fi-text fi-text--small fi-text--medium"
                                >
                                    {{
                                        selected | memoizeFunc: getSelectedLabel
                                    }}
                                </span>
                            </span>
                        }
                    </div>
                }
            </div>

            <fi-icon
                [svgName]="'chevron-right'"
                [variation]="'solid'"
            ></fi-icon>
        </div>
    }
</div>

@if (isModalOpened) {
    <fi-select-modal
        [isModalOpened]="isModalOpened"
        [items]="items"
        [isInitializing]="isInitializing"
        [selected]="selected"
        [modalConfig]="modalConfig"
        [modalVariation]="modalConfig.modalVariation"
        [hasCustomSmallTabletAnimation]="
            modalConfig.hasCustomSmallTabletAnimation
        "
        [searchFields]="modalConfig.searchFields"
        [isMultipleSelection]="isMultiple"
        [isInfiniteScrollLoading]="isInfiniteScrollLoading"
        [gridSearchConfig]="gridSearchConfig"
        [gridSearchTotalItems]="gridSearchTotalItems"
        [gridSearch]="gridSearch"
        [noDataMessage]="noDataMessage"
        (close)="closeModal()"
        (gridSearchChange)="handleGridSearch($event)"
        (loadMoreItems)="handleLoadMoreItems()"
        (selectionChange)="onChangeSelection($event)"
    ></fi-select-modal>
}
