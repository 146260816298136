import { UnitClusterMapMarkerIcon } from '../../feature/map-marker';

import {
    RED_COLOR,
    HOVERED_RED_COLOR,
    BLUE_COLOR,
    HOVERED_BLUE_COLOR,
} from './constants';

export function generateUnitClusterIcon(
    iconType: UnitClusterMapMarkerIcon,
    content = '',
): string {
    const { markerInnerCircleColor, markerOuterCircleColor } =
        generateUnitClusterIconColors(iconType);

    return `
        <span style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);" class="fi-text fi-text--heavy fi-text--x-small fi-text--white">${content}</span>
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
            <defs>
                <style>
                    .cls-3 {
                        isolation: isolate;
                        opacity: .5;
                    }

                    .cls-text {
                        text-anchor: middle;
                        dominant-baseline: middle;
                        fill: #fff;
                    }
                </style>
            </defs>

            <circle id="Ellipse_580" data-name="Ellipse 580" class="cls-3" cx="32" cy="32" r="32" fill="${markerOuterCircleColor}"/>
            <circle id="Ellipse_581" data-name="Ellipse 581" class="cls-4" cx="32" cy="32" r="25" fill="#fff"/>
            <circle id="Ellipse_582" data-name="Ellipse 582" class="cls-2" cx="32" cy="32" r="24" fill="${markerInnerCircleColor}"/>
        </svg>`;
}

function generateUnitClusterIconColors(iconType: UnitClusterMapMarkerIcon): {
    markerInnerCircleColor: string;
    markerOuterCircleColor: string;
} {
    let markerInnerCircleColor: string;
    let markerOuterCircleColor: string;

    switch (iconType) {
        case UnitClusterMapMarkerIcon.IsAlertHovered:
            markerInnerCircleColor = HOVERED_RED_COLOR;
            markerOuterCircleColor = RED_COLOR;
            break;
        case UnitClusterMapMarkerIcon.IsAlert:
            markerInnerCircleColor = RED_COLOR;
            markerOuterCircleColor = RED_COLOR;
            break;
        case UnitClusterMapMarkerIcon.HasAlertsHovered:
            markerInnerCircleColor = HOVERED_BLUE_COLOR;
            markerOuterCircleColor = RED_COLOR;
            break;
        case UnitClusterMapMarkerIcon.NoAlertsHovered:
            markerInnerCircleColor = HOVERED_BLUE_COLOR;
            markerOuterCircleColor = HOVERED_BLUE_COLOR;
            break;
        case UnitClusterMapMarkerIcon.HasAlerts:
            markerInnerCircleColor = BLUE_COLOR;
            markerOuterCircleColor = RED_COLOR;
            break;
        case UnitClusterMapMarkerIcon.NoAlerts:
        default:
            markerInnerCircleColor = BLUE_COLOR;
            markerOuterCircleColor = BLUE_COLOR;
            break;
    }

    return { markerInnerCircleColor, markerOuterCircleColor };
}
