import { HttpErrorResponse, HttpEvent } from '@angular/common/http';

import { Observable, of } from 'rxjs';

import { DownloadFileService } from '../../services';

import { DEFAULT_UPLOAD_FILE_OPTIONS } from './constants';
import {
    UploadResponseDto,
    FileDropOptions,
    FileValidationErrors,
} from './models';
import { errorListDefine, isFileTypeValid, uploadFileErrors } from './utils';

export abstract class DragNDropAreaService {
    protected constructor(protected downloadFileService: DownloadFileService) {}

    abstract uploadFile(
        selectedFile: File,
        isSetupInProgress?: boolean,
    ): Observable<
        | HttpEvent<UploadResponseDto | void>
        | { [key: string]: File | FileValidationErrors }
    >;

    abstract downloadTemplate(): Observable<void> | void;

    downloadDecodedFile(
        encodedString: string,
        errorListFileName: string,
        fileType: string,
    ) {
        this.downloadFileService.downloadDecodedFile(
            encodedString,
            errorListFileName,
            fileType,
        );
    }

    errorListDefine(
        uploadFiles: FileList,
        fileUploadOptions: FileDropOptions = DEFAULT_UPLOAD_FILE_OPTIONS,
    ): string[] {
        return errorListDefine(uploadFiles, fileUploadOptions);
    }

    uploadFileErrors(
        file: File,
        fileUploadOptions: FileDropOptions = DEFAULT_UPLOAD_FILE_OPTIONS,
    ): string[] {
        return uploadFileErrors(file, fileUploadOptions);
    }

    uploadFilesLengthCheck(
        uploadFiles: FileList,
        fileUploadOptions: FileDropOptions,
    ): boolean {
        return uploadFiles.length > fileUploadOptions.filesQuantity;
    }

    protected uploadErrorHandler(
        response: HttpErrorResponse,
    ): Observable<{ [key: string]: FileValidationErrors | File }> {
        if (response.status === 400) {
            return of({
                errorStatus: FileValidationErrors.fileContent,
                errorFile: response.error.body,
            });
        }

        return of({ errorStatus: FileValidationErrors.fileUpload });
    }

    isFileDropError(
        file: File,
        fileUploadOptions: FileDropOptions = DEFAULT_UPLOAD_FILE_OPTIONS,
    ): boolean {
        return (
            file.name.length > fileUploadOptions.fileNameLength ||
            file.size > fileUploadOptions.fileSize ||
            isFileTypeValid(file.type, fileUploadOptions.fileType)
        );
    }
}
