import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    Optional,
    QueryList,
    TemplateRef,
    ViewChildren,
    ViewEncapsulation,
    ViewChild,
} from '@angular/core';
import { NgTemplateOutlet, NgClass } from '@angular/common';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { zip, isEmpty, get, isUndefined } from 'lodash';
import {
    combineLatest,
    distinctUntilChanged,
    map,
    observeOn,
    startWith,
    take,
} from 'rxjs/operators';
import { animationFrameScheduler } from 'rxjs';

import { Animations } from '../../../animations';
import { OnChange } from '../../../decorators';
import { FocusWithinEvent } from '../../../services';
import { resizeElement } from '../../../utils';
import { GridColumnDefDirective } from '../column/column-def.directive';
import { GridDataSourceDirective } from '../data-source/data-source.directive';
import { GridNestedDataSourceDirective } from '../expanded/nested-data-source.directive';
import { GridGroupDefDirective } from '../group/group-def.directive';
import { GridRow } from '../models';
import { GridNoNestedDataPlaceholderDefDirective } from '../no-data-placeholder/no-nested-data-placeholder-def.directive';
import { GridTableScrollService } from '../services/table-scroll.service';
import { TableStickyStyler } from '../services/table-sticky-styler.service';
import { GridNestedPagedResultMap } from '../store/models';
import { VariationDirective } from '../../../directives/variation.directive';
import { GridHeaderCellComponent } from '../header-cell/header-cell.component';
import { GridColumnSortDirective } from '../sort/column.directive';
import { GridSortIconComponent } from '../sort/icon/icon.component';
import { GridExpandingRowDirective } from '../expanded/expanding-row.directive';
import { GridCellComponent } from '../cell/cell.component';
import { GridExpandIconComponent } from '../expanded/icon/icon.component';
import { GridExpandDirective } from '../expanded/expand.directive';
import { LoaderPenskeComponent } from '../../../components/loader-penske/loader-penske.component';
import { SkeletonElementComponent } from '../../../components/skeleton/element/skeleton-element.component';
import { IconComponent } from '../../../components/icon/icon.component';
import { MemoizeFuncPipe } from '../../../pipes/memoize-func.pipe';
import { ToNumArray } from '../../../pipes/to-num-array';
import { GridGroupRowsPipe } from '../pipes/group-rows.pipe';
import { GridNestedRowPaginatorComponent } from '../expanded/paginator/paginator.component';

import { GridStickyHeaderComponent } from './sticky-header/sticky-header.component';
import { GridTableLoaderSkeletonComponent } from './loader-skeleton/loader-skeleton.component';

export enum GridTableVariation {
    LeftIndentIncreased = 'left-indent-increased',
}

const TABLE_HORIZONTAL_SCROLL_CLASS = 'fi-grid-table--has-horizontal-scroll';
// 18px (width) + 8px (right indent)
export const EXPANDER_WIDTH = 26;
export const CHECKBOX_WIDTH = 26;
const TABLE_CELL_HORIZONTAL_MARGIN = 14;

@UntilDestroy()
@Component({
    selector: 'fi-grid-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    animations: [
        Animations.AnimateNgIfChildren,
        Animations.AnimateInnerExpansion,
        Animations.AnimatePaddingExpansion,
    ],
    standalone: true,
    imports: [
        GridStickyHeaderComponent,
        VariationDirective,
        NgTemplateOutlet,
        NgClass,
        GridHeaderCellComponent,
        GridColumnSortDirective,
        GridSortIconComponent,
        GridExpandingRowDirective,
        GridCellComponent,
        GridExpandIconComponent,
        GridExpandDirective,
        LoaderPenskeComponent,
        GridTableLoaderSkeletonComponent,
        SkeletonElementComponent,
        IconComponent,
        MemoizeFuncPipe,
        ToNumArray,
        GridGroupRowsPipe,
        GridNestedRowPaginatorComponent,
    ],
})
export class GridTableComponent implements OnInit, AfterViewInit {
    @OnChange(function (this: GridTableComponent) {
        this.calculateRegularColumns();
    })
    @Input()
    columns: GridColumnDefDirective[];
    @Input() groupBy: GridGroupDefDirective;
    @Input() variation: GridTableVariation;
    @Input() hasExpandingRow: boolean;
    @Input()
    noNestedDataPlaceholderDef: TemplateRef<GridNoNestedDataPlaceholderDefDirective>;
    nestedDataKey: string | undefined;
    nestedRows: GridNestedPagedResultMap;

    @ViewChildren('table') tables: QueryList<ElementRef>;
    @ViewChildren('tr') tableRows: QueryList<ElementRef>;
    @ViewChild('tableWrapper') tableWrapper: ElementRef;

    hasScrollPrev = false;
    cellWidths: number[] = [];
    rows: GridRow[];
    tableColumnsCount = 0;
    tableAllColumnsCount = 0;
    prevFocusedElement: HTMLElement;

    isInitializing = true;
    isLoading = false;
    isDataPresent = false;
    openByDefault = false;

    readonly expanderWidth = EXPANDER_WIDTH;

    private startSwipeCoordinates: { x: number; y: number };
    private startSwipeTime: number;

    constructor(
        private elmRef: ElementRef,
        private tableScrollService: GridTableScrollService,
        private stickyStyler: TableStickyStyler,
        private changeDetectorRef: ChangeDetectorRef,
        @Optional() private dataSource: GridDataSourceDirective,
        @Optional() private nestedData: GridNestedDataSourceDirective,
    ) {}

    private get width(): number {
        return this.elmRef.nativeElement.offsetWidth;
    }

    ngOnInit(): void {
        if (!this.dataSource) {
            return;
        }

        this.dataSource.stateChanged$
            .pipe(untilDestroyed(this))
            .subscribe(() => this.updateState());

        this.updateState();

        if (!this.nestedData) {
            return;
        }

        this.nestedData.stateChanged$
            .pipe(untilDestroyed(this))
            .subscribe(() => this.updateNestedState());

        this.updateNestedState();
    }

    ngAfterViewInit(): void {
        this.tableScrollService.breakPoints$
            .pipe(untilDestroyed(this))
            .subscribe(({ index, points }) => {
                const marginLeft = this.normalizeTableMargin(points[index]);

                this.setTableStyles({
                    marginLeft: `${marginLeft}px`,
                });
            });

        resizeElement(this.elmRef.nativeElement)
            .pipe(
                map(({ contentRect: { width } }) => width),
                distinctUntilChanged(),
                observeOn(animationFrameScheduler),
                combineLatest(
                    this.tableRows.changes.pipe(
                        startWith(this.tableRows),
                        untilDestroyed(this),
                    ),
                ),
                untilDestroyed(this),
            )
            .subscribe(() => {
                this.recalculate();
            });

        if (this.nestedData) {
            this.nestedDataKey = this.nestedData.dataKey;
        }
    }

    recalculate(): void {
        if (!this.tables || !this.tables.length) {
            return;
        }

        this.synchronizeColumnsWidths();

        const hasScroll = this.hasScroll(
            this.cellWidths,
            this.elmRef.nativeElement,
        );

        if (hasScroll) {
            this.recalculateTableWithScrollStyles();
        }

        if (!hasScroll && this.hasScrollPrev) {
            this.setTableStyles({
                paddingLeft: '',
                paddingRight: '',
            });
        }

        const breakPoints = hasScroll
            ? this.calculateColumnsScrollBreakpoints(this.cellWidths)
            : [];

        // timeout to prevent ExpressionChangedAfterItHasBeenCheckedError
        setTimeout(() => {
            this.tableScrollService.setBreakPoints({
                points: breakPoints,
            });
        }, 0);

        this.hasScrollPrev = hasScroll;
    }

    trackByColumnFieldName(_: number, column: GridColumnDefDirective): string {
        return column.field;
    }

    trackByIndex(index: number, row: any): number {
        return row?.uniqueId ?? index;
    }

    getRowForRowColumn([columns, row]: [
        QueryList<GridColumnDefDirective>,
        any,
    ]): GridColumnDefDirective[] | null {
        const rowColumn = columns.find(({ asRow }) => asRow);

        if (rowColumn && !isEmpty(row[rowColumn.field])) {
            return columns.filter((column) => {
                return (
                    column === rowColumn || column.stickyRight || column.sticky
                );
            });
        }

        return null;
    }

    handleExpandRow(
        state: boolean,
        itemPointer: string | number,
        row?: GridRow,
    ): void {
        if (!state) {
            this.nestedData.closeExpander(itemPointer);
            return;
        }

        this.nestedData.setPage(1, itemPointer, row);
    }

    getIndent([hasChildren, level]: [boolean, number]): number {
        const leftIndent = CHECKBOX_WIDTH * level;

        return hasChildren ? leftIndent : leftIndent + EXPANDER_WIDTH;
    }

    getPureNestedRows([row, nestedRows]: [
        Record<string, any>,
        GridNestedPagedResultMap,
    ]): any {
        const rowMapPointer = row[this.nestedDataKey];

        if (!rowMapPointer) {
            return;
        }

        return get(nestedRows, rowMapPointer, null);
    }

    getNestedRows([isExpanded, rows]: [boolean, any[]]): any[] {
        return isExpanded ? rows : [];
    }

    hasLoadMoreBtn([_, nestedDataValue]: [
        GridNestedPagedResultMap,
        string,
    ]): boolean {
        const { source } = this.nestedData;
        const { pageSize, totalItems, pageNumber } =
            source.values[nestedDataValue];

        return pageNumber * pageSize < totalItems;
    }

    tableTouchStart(event: TouchEvent): void {
        this.startSwipeCoordinates = this.getTouchEventCoordinates(event);
        this.startSwipeTime = new Date().getTime();
    }

    tableTouchEnd(event: TouchEvent): void {
        const endSwipeCoordinates = this.getTouchEventCoordinates(event);
        const endSwipeTime = new Date().getTime();
        const direction = {
            xDiff: endSwipeCoordinates.x - this.startSwipeCoordinates.x,
            yDiff: endSwipeCoordinates.y - this.startSwipeCoordinates.y,
        };
        const swipeDuration = endSwipeTime - this.startSwipeTime;
        const needToSwipeTable =
            swipeDuration < 1000 &&
            Math.abs(direction.xDiff) > 30 && // x-axis length condition
            Math.abs(direction.xDiff) > Math.abs(direction.yDiff * 3); // condition horizontal movement greater than vertical

        needToSwipeTable && this.swipeTable(direction.xDiff);
    }

    handleFocusWithin(focusWithinEvent: FocusWithinEvent): void {
        const { element } = focusWithinEvent;
        if (
            (element && this.canGrowInHeightOnFocusElement(element)) ||
            this.canGrowInHeightOnFocusElement(this.prevFocusedElement)
        ) {
            this.recalculateTableWhenFocusChanged(); // TODO: think how to call function only on focused element resized
        }
        this.prevFocusedElement = element;

        const isElementFocusable = this.isElementFocusable(focusWithinEvent);

        if (!isElementFocusable) {
            return;
        }

        const tableDataElement = element.closest('td');
        const tableDataBoundingClientRect =
            tableDataElement.getBoundingClientRect();
        const tableElem = tableDataElement.closest('table');
        const tableWrapperBoundingClientRect =
            tableElem.parentElement.getBoundingClientRect();
        const rightStickyElementsWidth = this.calculateStickyElementsWidth(
            tableDataElement,
            'fi-grid-table__cell--fixed-right',
        );
        const leftStickyElementsWidth = this.calculateStickyElementsWidth(
            tableDataElement,
            'fi-grid-table__cell--fixed-left',
        );
        const shouldScrollLeft =
            Math.ceil(tableDataBoundingClientRect.left) <
            Math.ceil(
                tableWrapperBoundingClientRect.left + leftStickyElementsWidth,
            );
        const shouldScrollRight =
            Math.ceil(
                tableDataBoundingClientRect.right -
                    TABLE_CELL_HORIZONTAL_MARGIN,
            ) >
            Math.ceil(
                tableWrapperBoundingClientRect.right - rightStickyElementsWidth,
            );

        if (shouldScrollLeft) {
            this.scrollTableToLeft(
                tableDataBoundingClientRect,
                tableWrapperBoundingClientRect,
                rightStickyElementsWidth,
                leftStickyElementsWidth,
                tableElem,
            );
        }

        if (shouldScrollRight) {
            this.scrollTableToRight(
                tableDataBoundingClientRect,
                tableWrapperBoundingClientRect,
                rightStickyElementsWidth,
                leftStickyElementsWidth,
            );
        }

        this.prevFocusedElement = element;
    }

    private canGrowInHeightOnFocusElement(element: HTMLElement): boolean {
        return (
            element instanceof HTMLInputElement ||
            element instanceof HTMLTextAreaElement
        );
    }

    private isElementFocusable(focusWithinEvent: FocusWithinEvent): boolean {
        const { element, isClicked } = focusWithinEvent;
        return (
            element &&
            (element instanceof HTMLInputElement ||
                element instanceof HTMLTextAreaElement ||
                element instanceof HTMLSelectElement ||
                element instanceof HTMLButtonElement ||
                !isClicked)
        );
    }

    private scrollTableToLeft(
        tableDataBoundingClientRect: DOMRect,
        tableWrapperBoundingClientRect: DOMRect,
        rightStickyElementsWidth: number,
        leftStickyElementsWidth: number,
        tableElem: HTMLTableElement,
    ): void {
        this.tableScrollService.breakPoints$
            .pipe(take(1))
            .subscribe(({ points, index: pointIndex }) => {
                const leftPoints = points.slice(0, pointIndex).reverse();
                const tableMarginLeft = Number(
                    tableElem.style.marginLeft.replace('px', ''),
                );
                if (Math.abs(tableMarginLeft) > Math.abs(leftPoints[0])) {
                    leftPoints.unshift(tableMarginLeft);
                }
                let resultIndex = -1;
                let pointsAbs = 0;

                leftPoints.some((point, index, arr) => {
                    if (isUndefined(arr[index + 1])) {
                        return true;
                    }

                    const abs = Math.abs(point - arr[index + 1]);
                    const elemWillBeVisibleAfterScroll =
                        Math.ceil(
                            tableDataBoundingClientRect.left +
                                (pointsAbs + abs),
                        ) >=
                            Math.ceil(
                                tableWrapperBoundingClientRect.left +
                                    leftStickyElementsWidth,
                            ) &&
                        Math.ceil(
                            tableDataBoundingClientRect.right +
                                (pointsAbs + abs),
                        ) <=
                            Math.ceil(
                                tableWrapperBoundingClientRect.right -
                                    rightStickyElementsWidth,
                            );

                    if (elemWillBeVisibleAfterScroll) {
                        return true;
                    }

                    resultIndex -= 1;
                    pointsAbs += abs;
                    return false;
                });

                this.tableScrollService.scrollColumns(resultIndex);
            });
    }

    private scrollTableToRight(
        tableDataBoundingClientRect: DOMRect,
        tableWrapperBoundingClientRect: DOMRect,
        rightStickyElementsWidth: number,
        leftStickyElementsWidth: number,
    ): void {
        this.tableScrollService.breakPoints$
            .pipe(take(1))
            .subscribe(({ points, index }) => {
                const rightPoints = points.slice(index);
                let resultIndex = 1;
                let pointsAbs = 0;

                rightPoints.some((point, index, arr) => {
                    if (isUndefined(arr[index + 1])) {
                        return true;
                    }

                    const abs = Math.abs(arr[index + 1] - point);
                    const elemWillBeVisibleAfterScroll =
                        Math.ceil(
                            tableDataBoundingClientRect.left -
                                (pointsAbs + abs),
                        ) >=
                            Math.ceil(
                                tableWrapperBoundingClientRect.left +
                                    leftStickyElementsWidth,
                            ) &&
                        Math.ceil(
                            tableDataBoundingClientRect.right -
                                (pointsAbs + abs),
                        ) <=
                            Math.ceil(
                                tableWrapperBoundingClientRect.right -
                                    rightStickyElementsWidth,
                            );

                    if (elemWillBeVisibleAfterScroll) {
                        return true;
                    }

                    resultIndex += 1;
                    pointsAbs += abs;
                    return false;
                });

                this.tableScrollService.scrollColumns(resultIndex);
            });
    }

    private calculateStickyElementsWidth(
        tableDataElement: HTMLTableCellElement,
        stickyElemClassName: string,
    ): number {
        const tableRowElement = tableDataElement.closest('tr');

        return Array.from(
            tableRowElement.getElementsByClassName(stickyElemClassName),
        ).reduce((acc, element) => {
            if (tableDataElement.isSameNode(element)) {
                return acc;
            }
            return acc + element.getBoundingClientRect().width;
        }, 0);
    }

    private recalculateTableWithScrollStyles(): void {
        const stickyStartStates = this.columns
            .filter(({ asRow }) => !asRow)
            .map(({ sticky }) => !!sticky);

        const stickyEndStates = this.columns
            .filter(({ asRow }) => !asRow)
            .map(({ stickyRight }) => !!stickyRight);

        this.setTableStickyStyle(
            this.cellWidths,
            stickyStartStates,
            stickyEndStates,
        );

        this.tables.forEach((table) => {
            this.stickyStyler.updateStickyColumns(
                table.nativeElement.rows,
                stickyStartStates,
                stickyEndStates,
                this.cellWidths,
                this.elmRef.nativeElement.offsetWidth,
            );
        });
    }

    private recalculateTableWhenFocusChanged(): void {
        const hasScroll = this.hasScroll(
            this.cellWidths,
            this.elmRef.nativeElement,
        );

        if (!hasScroll) {
            return;
        }

        this.removeTablesStickyHeight();
        this.recalculateTableWithScrollStyles();
    }

    private getTouchEventCoordinates(event: TouchEvent): {
        x: number;
        y: number;
    } {
        const { pageX: x, pageY: y } = event.changedTouches[0];
        return { x, y };
    }

    private swipeTable(xAxisDifference: number): void {
        if (xAxisDifference < 0) {
            this.tableScrollService.scrollColumns(1); // next value
        } else {
            this.tableScrollService.scrollColumns(-1); // previous value
        }
    }

    private calculateRegularColumns(): void {
        this.tableColumnsCount = (this.columns || []).filter(
            ({ asRow, sticky, stickyRight }) =>
                !asRow && !sticky && !stickyRight,
        ).length;
        this.tableAllColumnsCount = (this.columns || []).filter(
            ({ asRow }) => !asRow,
        ).length;

        setTimeout(() => this.recalculate(), 0);
    }

    private enableRecalculationMode(): void {
        this.tables.map((table) => {
            table.nativeElement.classList.add('fi-grid-table--calculation');
        });
    }

    private disableRecalculationMode(): void {
        this.tables.map((table) => {
            table.nativeElement.classList.remove('fi-grid-table--calculation');
        });
    }

    private synchronizeColumnsWidths(): void {
        this.enableRecalculationMode();
        this.updateTableScrollClass(false);

        this.removeTablesStickyStyles();

        const cells: HTMLTableCellElement[][] = zip(
            ...this.tables.map((table: ElementRef<HTMLTableElement>) => {
                const row = table.nativeElement.rows[0];

                return Array.from(
                    row ? row.children : [],
                ) as HTMLTableCellElement[];
            }),
        );

        const maxStickyColumnWidth = this.getMaxStickyColumnWidth();
        const hasAllColumnsMinWidth = this.columns?.every(
            (column) => column.minWidth,
        );

        let cellWidths = cells.map((cells) => {
            if (hasAllColumnsMinWidth) {
                return this.stickyStyler.getPredefinedCellWidth(
                    cells,
                    this.columns,
                    maxStickyColumnWidth,
                );
            }

            return Math.max(
                ...this.stickyStyler.getCellWidths(cells, maxStickyColumnWidth),
            );
        });

        const hasScroll = this.hasScroll(cellWidths, this.elmRef.nativeElement);
        this.updateTableScrollClass(hasScroll);

        if (hasScroll || hasScroll !== this.hasScrollPrev) {
            cellWidths = cells.map((cells) => {
                if (hasAllColumnsMinWidth) {
                    return this.stickyStyler.getPredefinedCellWidth(
                        cells,
                        this.columns,
                        maxStickyColumnWidth,
                    );
                }

                return Math.max(
                    ...this.stickyStyler.getCellWidths(
                        cells,
                        maxStickyColumnWidth,
                    ),
                );
            });
        }

        cells.map((cells, i) => {
            const width = cellWidths[i];

            this.stickyStyler.setElementStyles(cells as HTMLElement[], {
                width: `${width}px`,
            });

            this.stickyStyler.setElementStyles([cells[0]] as HTMLElement[], {
                display: !width && 'none',
            });
        });

        this.cellWidths = cellWidths;
        this.disableRecalculationMode();
    }

    private getMaxStickyColumnWidth(): number {
        const tableWidth = get(
            this,
            'tableWrapper.nativeElement.offsetWidth',
            0,
        );

        // max width of sticky left column - 66% of the table
        return Math.round(0.66 * tableWidth);
    }

    private hasScroll(cellWidths: number[], container: HTMLElement): boolean {
        const totalWidth = cellWidths.reduce((acc, w) => {
            return acc + w;
        }, 0);

        return container.offsetWidth < totalWidth;
    }

    private removeTablesStickyStyles(): void {
        this.tables.forEach((table) => {
            this.stickyStyler.clearCellStickyPosition(
                table.nativeElement.rows,
                ['left', 'right'],
            );
        });
    }

    private removeTablesStickyHeight(): void {
        this.tables.forEach((table) => {
            this.stickyStyler.clearCellHeight(table.nativeElement.rows);
        });
    }

    private setTableStickyStyle(
        cellWidths: number[],
        stickyStartStates: boolean[],
        stickyEndStates: boolean[],
    ): void {
        const paddingLeft = cellWidths
            .filter((_width, i) => stickyStartStates[i])
            .reduce((acc, w) => {
                return acc + w;
            }, 0);

        const paddingRight = cellWidths
            .filter((_width, i) => stickyEndStates[i])
            .reduce((acc, w) => {
                return acc + w;
            }, 0);

        this.setTableStyles({
            paddingLeft: `${paddingLeft}px`,
            paddingRight: `${paddingRight}px`,
        });
    }

    private normalizeTableMargin(scrollMargin: number): number {
        const margin = Math.min(0, scrollMargin);

        if (!margin) {
            return 0;
        }

        const flags = this.columns.map(
            ({ sticky, stickyRight }) => !sticky || !stickyRight,
        );

        const viewWidth = this.cellWidths
            .filter((width, i) => (!flags[i] ? 0 : width))
            .reduce((acc, width) => acc + width, 0);

        return Math.max(margin, this.width - viewWidth);
    }

    private setTableStyles(styles: object): void {
        this.stickyStyler.setElementStyles(
            this.tables.map((table) => table.nativeElement),
            styles,
        );
    }

    private updateTableScrollClass(hasScroll: boolean): void {
        this.tables.forEach((table) => {
            table.nativeElement.classList.toggle(
                TABLE_HORIZONTAL_SCROLL_CLASS,
                hasScroll,
            );
        });

        // TODO: Think how to do it better
        const scroller = this.elmRef.nativeElement.getElementsByTagName(
            'fi-grid-table-scroll-controls',
        )[0];

        this.stickyStyler.setElementStyles([scroller], {
            width: hasScroll ? null : '0px',
            display: hasScroll ? 'block' : 'none',
        });
    }

    private calculateColumnsScrollBreakpoints(cellWidths: number[]): number[] {
        const flags = this.columns.map(
            ({ sticky, stickyRight }) => sticky || stickyRight,
        );

        const viewWidth = cellWidths
            .filter((width, i) => (!flags[i] ? 0 : width))
            .reduce((acc, width) => acc - width, this.width);

        const scrollBreakpoints = cellWidths
            .filter((_width, i) => !flags[i])
            .reduce(
                (breakpoints, columnWidth) => {
                    const newBreakpoint =
                        breakpoints[breakpoints.length - 1] + columnWidth;

                    if (newBreakpoint >= viewWidth) {
                        return [...breakpoints, columnWidth];
                    }

                    breakpoints[breakpoints.length - 1] = newBreakpoint;
                    return breakpoints;
                },
                [0],
            )
            .reduce(
                (breakpoints, point) => [
                    ...breakpoints,
                    breakpoints[breakpoints.length - 1] - point,
                ],
                [0],
            );

        return scrollBreakpoints.slice(0, -1);
    }

    private updateState(): void {
        const {
            source: { data },
            isInitializing,
            isLoading,
        } = this.dataSource;

        this.rows = data;
        this.isInitializing = isInitializing;
        this.isLoading = isLoading;
        this.isDataPresent = !isEmpty(data);

        this.changeDetectorRef.markForCheck();
    }

    private updateNestedState(): void {
        const { source: data } = this.nestedData;

        this.nestedRows = data.values;
        this.openByDefault = this.nestedData.openByDefault;

        this.changeDetectorRef.markForCheck();
    }
}
