import { NgControl } from '@angular/forms';

import { Observable } from 'rxjs';

import { replaceValueNonDigits } from '../../../utils';

import { InputFormatBase, InputFormatParsedValue } from './input-format-base';

export class NumberFormat extends InputFormatBase {
    valueChanges$: Observable<string>;

    constructor(ngControl: NgControl, private initialValue: string) {
        super(ngControl);

        const transformedInitialValue = this.getTransformedValue(
            this.initialValue,
        );
        this.setControlValue(transformedInitialValue);

        this.valueChanges$ = this.getInputEvent(transformedInitialValue);
    }

    protected getParsedValue([
        _previousValue,
        currentValue,
    ]: string[]): InputFormatParsedValue {
        return {
            transformedValue: this.getTransformedValue(currentValue),
        };
    }

    protected returnCaretToOriginalPosition() {
        return {};
    }

    private getTransformedValue(value: string): string {
        if (!value) {
            return '';
        }

        return replaceValueNonDigits(value);
    }
}
