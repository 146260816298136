import { Component, Input } from '@angular/core';

import { IconComponent } from '../../../components';
import { VariationDirective } from '../../../directives';

@Component({
    selector: 'fi-accordion-expander-icon',
    templateUrl: './expander-icon.component.html',
    styleUrls: ['./expander-icon.component.scss'],
    standalone: true,
    imports: [IconComponent, VariationDirective],
})
export class AccordionExpanderIconComponent {
    @Input() expanded: boolean;
    @Input() variation: string;
}
