import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    inject,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { FilteringDirective } from '../filtering.directive';
import { AppliedFilter } from '../models';
import { FilteringListComponent } from '../list/list.component';

type FilterItemsVariations = 'mobile' | 'desktop' | 'desktop-column';

@UntilDestroy()
@Component({
    selector: 'fi-simple-filtering',
    templateUrl: './simple.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [FilteringListComponent],
})
export class SimpleFilteringComponent implements OnInit {
    private changeDetectorRef = inject(ChangeDetectorRef);
    private filtering = inject(FilteringDirective, { optional: true });

    @Input() variation: FilterItemsVariations;

    appliedFilters: AppliedFilter[] = [];

    ngOnInit(): void {
        if (!this.filtering) {
            return;
        }

        this.filtering.stateChanged$
            .pipe(untilDestroyed(this))
            .subscribe(() => this.updateState());

        // for set filtering open
        setTimeout(() => this.filtering.setFilterOpened());
        this.updateState();
    }

    handleApplyFilters(): void {
        this.filtering.applyFilters();
    }

    private updateState(): void {
        this.changeDetectorRef.markForCheck();
    }
}
