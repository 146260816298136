import { FileDropOptions, FileStateInfo, FileTypes } from '../models';

export const DEFAULT_UPLOAD_FILE_OPTIONS: FileDropOptions = {
    fileSize: 1048576,
    // default type uses for '.xlsx' file type
    fileType: FileTypes.fileExcel,
    filesQuantity: 1,
    fileNameLength: 300,
};

export const DEFAULT_STEP = 2;

export const DEFAULT_FILE_ERROR_INFO: FileStateInfo = {
    title: 'Errors found on spreadsheet.',
    description:
        'Download spreadsheet file and correct errors, or upload a new file.',
};

export const DOWNLOAD_ERROR_LOG_BUTTON_LABEL = 'Download Error Log';
export const DOWNLOAD_FILE_BUTTON_LABEL = 'Download File';

export const UPLOAD_STATE_DESCRIPTION = 'This should take just a minute...';
export const LARGE_FILES_UPLOAD_STATE_DESCRIPTION =
    'Larger files may take several minutes. Please do not leave this page until the upload is complete.';

export const CSV_FILE_EXTENSION = 'csv';
