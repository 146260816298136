import { environment } from '@environments/environment';

import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

@Injectable()
export class ApiVersionInterceptor implements HttpInterceptor {
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        const { PUBLIC_API_VERSION, DEFAULT_COUNTRY } =
            environment.externalConfig;
        const apiVersion = PUBLIC_API_VERSION + DEFAULT_COUNTRY;

        const url = req.url.replace('{api_v1}', apiVersion);

        return next.handle(req.clone({ url }));
    }
}
