import { Dayjs } from 'dayjs';

export const getCalendarMonthDays = (date: Dayjs): Dayjs[] => {
    let firstDay: Dayjs = date.startOf('month').startOf('week');
    const lastDay = date.endOf('month').endOf('week');

    const days: Dayjs[] = [];

    while (firstDay <= lastDay) {
        days.push(firstDay.clone());
        firstDay = firstDay.add(1, 'day');
    }

    return days;
}