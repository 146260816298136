import {
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
} from '@angular/core';

import { range } from 'lodash';

import { OnChange } from '../../../decorators/on-change.decorator';

@Component({
    selector: 'fi-carousel-indicator',
    templateUrl: 'carousel-indicator.component.html',
    styleUrls: ['./carousel-indicator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class CarouselIndicatorComponent implements OnInit {
    @OnChange('onItemsQuantityChange')
    @Input()
    itemsQuantity = 0;

    @Output() activeItemIndexChange = new EventEmitter<number>();
    @Input()
    get activeItemIndex(): number {
        return this.activeItemIndexValue;
    }

    set activeItemIndex(value: number) {
        this.activeItemIndexValue = value;
        this.activeItemIndexChange.emit(this.activeItemIndexValue);
    }

    items: number[];

    private activeItemIndexValue = 0;

    ngOnInit(): void {
        this.items = this.getItemsRange(this.itemsQuantity);
    }

    onItemsQuantityChange(quantity: number): void {
        this.items = this.getItemsRange(quantity);
    }

    changeActiveItemIndex(value: number): void {
        this.activeItemIndex = value;
    }

    private getItemsRange(quantity: number): number[] {
        return range(quantity);
    }
}
