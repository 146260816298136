import { parsePhoneValueToGroups, PhoneParsedGroup } from '../../../../utils';

import {
    FiInputMaskBase,
    getEmptyMaskValue,
    FiInputMaskKey,
    FiInputCharacterMaskValue,
    FiInputMaskValue,
} from './input-mask-base';

export class PhoneInputMask extends FiInputMaskBase {
    getValue(value: string): FiInputMaskValue {
        const parsedValue = parsePhoneValueToGroups(value);
        const maskValue = getEmptyMaskValue();

        parsedValue.forEach((groupValue, groupKey) => {
            switch (groupKey) {
                case PhoneParsedGroup.OpenParenthesis:
                case PhoneParsedGroup.CloseParenthesis:
                case PhoneParsedGroup.Hyphen: {
                    const { maskKey, character } = this.getPhoneCharacter(
                        groupKey,
                        groupValue,
                    );
                    maskValue[maskKey] += character;

                    break;
                }
                case PhoneParsedGroup.Area:
                case PhoneParsedGroup.FirstDigitsPart: {
                    const { filled, placeholder } =
                        this.getFilledRangeWithPlaceholder(groupValue, 3);

                    maskValue.filled += filled;
                    maskValue.placeholder += placeholder;

                    break;
                }
                case PhoneParsedGroup.LastDigitsPart: {
                    const { filled, placeholder } =
                        this.getFilledRangeWithPlaceholder(groupValue, 4);

                    maskValue.filled += filled;
                    maskValue.placeholder += placeholder;

                    break;
                }
                case PhoneParsedGroup.Raw: {
                    break;
                }
            }
        });

        return maskValue;
    }

    private getPhoneCharacter(
        groupKey: PhoneParsedGroup,
        groupValue: string,
    ): FiInputCharacterMaskValue {
        const maskKey = groupValue
            ? FiInputMaskKey.Filled
            : FiInputMaskKey.Placeholder;
        let character: string;

        switch (groupKey) {
            case PhoneParsedGroup.OpenParenthesis:
                character = '(';
                break;

            case PhoneParsedGroup.CloseParenthesis:
                character = ') ';
                break;

            case PhoneParsedGroup.Hyphen:
                character = ' - ';
                break;
        }

        return {
            maskKey,
            character,
        };
    }
}
