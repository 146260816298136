<span
    [fiVariationBase]="'fi-highlight-text'"
    [fiVariation]="baseVariation"
    >
    @for (match of result; track $index) {
        @if ([match, highlightQuery] | memoizeFunc: isMatch) {
            <span
                [fiVariationBase]="'fi-highlight-text__spotlight'"
                [fiVariation]="variation"
            >{{ match }}</span>
        } @else {{{ match }}}
    }
</span>
