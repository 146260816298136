import { Component, ElementRef, Input, ViewChild } from '@angular/core';

import { defer, Observable, Subject } from 'rxjs';

import { VariationDirective } from '../../../directives';

// @deprecated: use fi-select-dropdown instead
@Component({
    selector: 'fi-select-menu-option',
    templateUrl: './select-menu-option.component.html',
    styleUrls: ['./select-menu-option.component.scss'],
    standalone: true,
    imports: [VariationDirective],
})
export class SelectMenuOptionComponent {
    @Input() value: string;
    @Input() disabled = false;
    @Input() variation = 'medium';

    @ViewChild('text', { static: true })
    textElementRef: ElementRef<HTMLElement>;

    isSelected: boolean;
    select$: Observable<string> = defer(() => this.select);

    private select = new Subject<string>();

    get text(): string {
        return this.textElementRef.nativeElement.innerText;
    }

    handleSelect(value: string): void {
        if (!this.disabled) {
            this.select.next(value);
        }
    }
}
