<!--Possible component states-->
<div
    [ngClass]="{
        'upload-area--centered': uploadType === uploadAreaType.FleetData,
        'upload-area--disabled': disabled,
    }"
    class="upload-area"
>
    @switch (state) {
        @case (stateUploading) {
            <div class="upload-area__upload-state">
                <ng-container *ngTemplateOutlet="uploadTpl"></ng-container>
            </div>
        }

        @case (stateValidating) {
            <div class="upload-area__validate-state">
                <ng-container *ngTemplateOutlet="validateTpl"></ng-container>
            </div>
        }

        @case (stateError) {
            <div class="upload-area__error-state">
                <drag-n-drop-upload-error
                    [errors]="uploadError"
                    [step]="stepNumber"
                    [fileType]="fileTypeName"
                    [fileSize]="fileSizeInMB"
                    [uploadType]="uploadType"
                    [fileErrorStatesInfo]="uploadFileAreaStatesInfo?.error"
                    (onDownloadFile)="handleDownloadFile()"
                    (onNewFileUpload)="handleNewUpload()"
                    (onRetrySendFile)="handleResendFile()"
                ></drag-n-drop-upload-error>
            </div>
        }

        @case (stateSuccess) {
            <div class="upload-area__success-state">
                <ng-container
                    [ngTemplateOutlet]="
                        uploadType | memoizeFunc: getSuccessTpl : this
                    "
                ></ng-container>
            </div>
        }

        <!--    Initial state contain drag'n'drop state and drag over state-->
        @default {
            <div
                [class.upload-area__initial-state--dragged-over]="
                    dropZoneActive
                "
                [fiFileDrop]="fileDropOptions"
                (fileDropHovered)="dropZoneState($event)"
                (fileDropped)="handleFileDropped($event)"
                (fileDropErrorMessages)="handleFileDropErrors($event)"
                class="upload-area__initial-state"
            >
                <ng-container *ngTemplateOutlet="initialTpl"></ng-container>
            </div>
        }
    }
</div>

<!--Template list-->
<ng-template #uploadTpl>
    <div>
        <fi-icon
            [svgName]="'temp-spinner'"
            [size]="'x-large'"
            class="upload-area__loader-icon"
        ></fi-icon>
        <span class="fi-text fi-text--medium fi-text--large">
            Uploading to Penske
        </span>
    </div>

    <p
        class="fi-text fi-text--medium fi-text--small upload-area__state-description"
    >
        {{ uploadType | memoizeFunc: getUploadStateDescription }}
    </p>
</ng-template>

<ng-template #validateTpl>
    <div>
        <fi-icon
            [svgName]="'temp-spinner'"
            [size]="'x-large'"
            class="upload-area__loader-icon"
        ></fi-icon>
        <span class="fi-text fi-text--medium fi-text--large">
            Validating File
        </span>
    </div>

    <p
        class="fi-text fi-text--medium fi-text--small upload-area__state-description"
    >
        This should take just a minute…
    </p>

    <ng-container *ngTemplateOutlet="infoTpl"></ng-container>
</ng-template>

<ng-template #successOnAddUsersTpl>
    <upload-step [variation]="'success'" [step]="stepNumber"></upload-step>

    <div class="upload-area__title-content">
        <fi-icon
            [size]="'medium'"
            [svgName]="'check'"
            class="upload-area__file-icon"
        ></fi-icon>
        <span class="fi-text fi-text--black fi-text--medium fi-text--large">
            Success!
        </span>
    </div>

    <div class="upload-area__success-msg">
        <p class="fi-text fi-text--black">
            User Accounts are currently being processed.
        </p>
        <p class="fi-text fi-text--black">
            An email will be sent out to {{ successEmail }} when your User
            Accounts are ready.
        </p>
    </div>

    <fi-icon
        [size]="'xxxx-large'"
        [svgName]="'envelope'"
        class="upload-area__mail-icon"
    ></fi-icon>

    <span
        [class.upload-area__label--add-point-event]="state == stateSuccess"
        (click)="chooseAndUploadFile()"
        role="button"
        aria-label="Upload Another File?"
        class="fi-text fi-text--medium fi-text--small fi-text--link upload-area__label"
    >
        Upload Another File?
    </span>
</ng-template>

<ng-template #successOnAddGroupsTpl>
    <upload-step [variation]="'success'" [step]="stepNumber"></upload-step>

    <div class="upload-area__title-content">
        <fi-icon
            [size]="'medium'"
            [svgName]="'check'"
            class="upload-area__file-icon"
        ></fi-icon>

        <span class="fi-text fi-text--black fi-text--medium fi-text--large">
            Successfully added {{ uploadedItems ? uploadedItems : '' }} new
            {{ 'Group' | pluralize: uploadedItems }}!
        </span>
    </div>

    <div class="upload-area__success-msg-group">
        @if (stageGroupsUpload) {
            <p class="fi-text fi-text--black">
                Your groups have been uploaded and are available in the
                <span
                    [routerLink]="[
                        '/settings/group-management/custom/nested/table/groups',
                    ]"
                    [queryParams]="{ page: 1 }"
                    class="fi-text fi-text--link fi-text--medium"
                >
                    company level
                </span>
                of your groups.
            </p>
        } @else {
            <p class="fi-text fi-text--black">
                Your additional groups are setup and ready in the
                <span
                    [routerLink]="['/settings/group-management/custom/groups']"
                    [queryParams]="{ page: 1 }"
                    class="fi-text fi-text--link fi-text--medium"
                >
                    Group Management page
                </span>
                now.
            </p>
        }
    </div>

    @if (!hideUploadMore) {
        <span
            [class.upload-area__label--add-point-event]="state == stateSuccess"
            (click)="chooseAndUploadFile()"
            role="button"
            aria-label="Upload Another File?"
            class="fi-text fi-text--medium fi-text--small fi-text--link upload-area__label"
        >
            Upload Another File?
        </span>
    }
</ng-template>

<ng-template #successOnAddLocationsTpl>
    <upload-step [variation]="'success'" [step]="stepNumber"></upload-step>

    <div class="upload-area__title-content">
        <fi-icon
            [size]="'medium'"
            [svgName]="'check'"
            class="upload-area__file-icon"
        ></fi-icon>

        <span class="fi-text fi-text--black fi-text--medium fi-text--large">
            Successfully added {{ uploadedItems ? uploadedItems : '' }} new
            {{ 'location' | pluralize: uploadedItems }}!
        </span>
    </div>

    @if (!hideUploadMore) {
        <span
            [class.upload-area__label--add-point-event]="state == stateSuccess"
            (click)="chooseAndUploadFile()"
            role="button"
            aria-label="Upload Another File?"
            class="fi-text fi-text--medium fi-text--small fi-text--link upload-area__label"
        >
            Upload Another File?
        </span>
    }
</ng-template>

<ng-template #successOnImportFleetDataTpl>
    <upload-step
        [variation]="'success'"
        [step]="stepNumber"
        class="upload-area__without-pointer-events"
    ></upload-step>

    <h3
        class="fi-text fi-text--heavy fi-text--large fi-text--black upload-area__title upload-area__title--success"
    >
        {{ uploadFileAreaStatesInfo?.success?.title || 'Success!' }}
    </h3>

    <div class="upload-area__success-msg">
        <p class="fi-text fi-text--large fi-text--medium fi-text--black">
            <fi-icon
                [size]="'medium'"
                [variation]="'regular'"
                [svgName]="'check'"
                class="upload-area__file-icon"
            ></fi-icon>

            {{ uploadFileAreaStatesInfo?.success?.subtitle }}
        </p>
        <p class="fi-text fi-text--small fi-text--medium fi-text--black">
            {{ uploadFileAreaStatesInfo?.success?.description }}
        </p>
    </div>

    <span
        [class.upload-area__label--add-point-event]="state == stateSuccess"
        (click)="chooseAndUploadFile()"
        role="button"
        aria-label="Upload Another File?"
        class="fi-text fi-text--medium fi-text--large fi-text--link upload-area__label"
    >
        Upload Another File?
    </span>
</ng-template>

<ng-template #initialTpl>
    <upload-step
        [variation]="[disabled, dropZoneActive] | memoizeFunc: getStepVariation"
        [step]="stepNumber"
        class="upload-area__without-pointer-events"
    ></upload-step>

    <div *fiIsDesktop class="upload-area__file-container">
        <div class="upload-area__file">
            @if (dropZoneActive) {
                <div>
                    @if (uploadFileAreaStatesInfo?.initial?.title) {
                        <h3
                            class="fi-text fi-text--heavy fi-text--large fi-text--white upload-area__title"
                        >
                            {{ uploadFileAreaStatesInfo?.initial?.title }}
                        </h3>
                    }

                    <span
                        class="fi-text fi-text--medium fi-text--large fi-text--white"
                    >
                        Drop
                        {{ 'file' | pluralize: filesQuantity }}
                        here to upload.
                    </span>
                </div>
            } @else {
                <div>
                    @if (uploadFileAreaStatesInfo?.initial?.title) {
                        <h3
                            class="fi-text fi-text--heavy fi-text--black fi-text--large upload-area__title"
                        >
                            {{ uploadFileAreaStatesInfo?.initial?.title }}
                        </h3>
                    }

                    <span
                        class="fi-text fi-text--medium fi-text--black fi-text--large"
                    >
                        Drag and Drop
                        {{
                            filesQuantity
                                | pluralizeString: 'a file' : 'files' : false
                        }}
                        here to upload or
                    </span>
                    <ng-container *ngTemplateOutlet="fileTpl"></ng-container>
                </div>
            }
        </div>

        @if (!dropZoneActive) {
            <fi-icon
                [variation]="'custom'"
                [size]="'60x'"
                [svgName]="disabled | memoizeFunc: getSvgName"
                class="upload-area__drop-area-icon"
            ></fi-icon>
        }

        @if (dropZoneActive) {
            <div class="upload-area__dragged-over-state">
                <fi-icon
                    [variation]="'custom'"
                    [svgName]="'company-logo'"
                    class="upload-area__company-logo-icon"
                ></fi-icon>
                <fi-icon
                    [size]="'xxx-large'"
                    [svgName]="'server'"
                    class="upload-area__server-icon"
                ></fi-icon>
            </div>
        }
    </div>

    <div *fiIsMobile>
        <p class="upload-area__file">
            <ng-container *ngTemplateOutlet="fileTpl"></ng-container>
            <span class="fi-text fi-text--medium fi-text--large fi-text--black">
                to upload.
            </span>
        </p>
    </div>
    <ng-container *ngTemplateOutlet="infoTpl"></ng-container>
</ng-template>

<ng-template #infoTpl>
    <div
        [class.fi-text--white]="dropZoneActive"
        class="fi-text fi-text--small fi-text--medium upload-area__label"
    >
        @if (dropZoneActive) {
            <fi-icon
                [variation]="'custom'"
                [size]="'xxxx-large'"
                [svgName]="'excel-icon'"
                class="upload-area__drop-over-icon"
            ></fi-icon>
        }
        <span>{{ fileSizeInMB }}MB size limit.</span>

        <span>{{ fileTypeName }} file type only.</span>
    </div>
</ng-template>

<ng-template #fileTpl>
    @if (!disabled) {
        <button
            (click)="chooseAndUploadFile()"
            type="button"
            aria-label="Select a File"
            class="fi-text fi-text--medium fi-text--large fi-text--link upload-area__with-pointer-events"
        >
            {{ selectFileText }}
        </button>
        <!-- prettier-ignore -->
        <span class="fi-text fi-text--medium fi-text--black fi-text--large"
    >.</span>
    } @else {
        <span class="fi-text fi-text--medium fi-text--black fi-text--large">
            {{ selectFileText }}.
        </span>
    }
</ng-template>
