import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Observable } from 'rxjs';

import {
    HighlightTextComponent,
    IconComponent,
    ListComponent,
    ListItemDirective,
} from '../../../components';
import { VariationDirective } from '../../../directives';
import { MemoizeFuncPipe } from '../../../pipes';

export interface SearchAutocompleteSettings {
    headerText?: string;
    isHeadingHidden?: boolean;
    iconsSize?: string;
    variation?: string;
    isHighlightTextMatch?: boolean;
    placeholder?: string;
    key?: string;
}

export interface SearchAutocompleteOption<T = any> {
    text?: string;
    icon?: string;
    iconSize?: string;
    iconVariation?: string;
    prefix?: string;
    variation?: string;
    type?: T;
    description?: string;
    unitNumber?: string;
    nonContractUnitNumber?: boolean;
    key?: string;
    textVariation?: string;
    gtmClassName?: string;
}

function displayableValue(option: SearchAutocompleteOption) {
    return `${option.prefix || ' '} ${option.text}`;
}

@UntilDestroy()
@Component({
    selector: 'fi-search-autocomplete-list',
    templateUrl: './autocomplete-list.component.html',
    styleUrls: ['./autocomplete-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        VariationDirective,
        ListItemDirective,
        ListComponent,
        IconComponent,
        HighlightTextComponent,
        MemoizeFuncPipe,
    ],
})
export class SearchAutocompleteListComponent implements OnInit {
    @Input() value: string;
    @Input() header: string;
    @Input() options: SearchAutocompleteOption[];
    @Input() settings: SearchAutocompleteSettings;
    @Input() variation: string;
    @Input() suggestionsHeader: string;
    @Input() suggestions: SearchAutocompleteOption[];
    @Input() suggestionsSettings: SearchAutocompleteSettings;
    @Input() suggestionsVariation: string;
    @Input() baseItemClass: string;
    @Input() highlightTextMatch = false;
    @Input() isArrowKeyDown$: Observable<KeyboardEvent>;

    @Output() selectOption = new EventEmitter<SearchAutocompleteOption>();
    @Output() activeOptionChange = new EventEmitter<SearchAutocompleteOption>();

    @ViewChild('list') listComponent: ListComponent<SearchAutocompleteOption>;

    isHeadingPadded = false;

    ngOnInit(): void {
        this.isArrowKeyDown$
            .pipe(untilDestroyed(this))
            .subscribe((event: KeyboardEvent) => {
                this.listComponent.keyManager.onKeydown(event);
            });

        this.handleHeading(this.suggestionsVariation);
    }

    displayableValue(option: SearchAutocompleteOption): string {
        return displayableValue(option);
    }

    trackByValue(option: SearchAutocompleteOption): string {
        return displayableValue(option);
    }

    handleSelectOption(option: SearchAutocompleteOption): void {
        this.selectOption.emit(option);
    }

    handleActiveOption(option: SearchAutocompleteOption): void {
        this.activeOptionChange.emit(option);
    }

    onFocus(item: ListItemDirective<SearchAutocompleteOption>): void {
        this.listComponent.keyManager.setActiveItem(item);
    }

    handleHeading(suggestionsVariation: string): void {
        this.isHeadingPadded = suggestionsVariation?.includes('heading-padded');
    }
}
