import {
    Directive,
    EventEmitter,
    HostListener,
    Input,
    Output,
} from '@angular/core';

// do not simplify imports
import {
    errorListDefine,
    isFileTypeValid,
} from '../feature/drag-n-drop-upload-area/utils';
import { FileDropOptions } from '../feature/drag-n-drop-upload-area/models';
import { DEFAULT_UPLOAD_FILE_OPTIONS } from '../feature/drag-n-drop-upload-area/constants';

@Directive({
    selector: '[fiFileDrop]',
    standalone: true,
})
export class FileDropDirective {
    @Input() fiFileDrop: FileDropOptions = DEFAULT_UPLOAD_FILE_OPTIONS;
    @Input() fiFileDropIsExternalCheck = false;

    @Output() fileDropped = new EventEmitter<FileList>();
    @Output() fileDropHovered = new EventEmitter<boolean>();
    @Output() fileDropErrorMessages = new EventEmitter<string[]>();

    @HostListener('drop', ['$event'])
    onDrop($event: DragEvent): void {
        $event.preventDefault();

        const transfer = $event.dataTransfer;
        if (
            this.fiFileDropIsExternalCheck ||
            this.uploadFilesCheck(transfer.files)
        ) {
            this.fileDropped.emit(transfer.files);
        }

        this.fileDropHovered.emit(false);
    }

    @HostListener('dragover', ['$event'])
    onDragOver($event: DragEvent): void {
        $event.preventDefault();

        this.fileDropHovered.emit(true);
    }

    @HostListener('dragleave', ['$event'])
    onDragLeave($event: DragEvent): void {
        $event.preventDefault();

        this.fileDropHovered.emit(false);
    }

    private uploadFilesCheck(uploadFiles: FileList): boolean {
        if (this.isOneFileDrop(uploadFiles)) {
            return true;
        }

        if (uploadFiles.length > 1 && this.isMultipleFileDrop(uploadFiles)) {
            return true;
        }

        const errorList = errorListDefine(uploadFiles, this.fiFileDrop);
        this.fileDropErrorMessages.emit(errorList);

        return false;
    }

    private isOneFileDrop(uploadFiles: FileList): boolean {
        const { fileType } = this.fiFileDrop;
        const formattedFileType =
            typeof fileType === 'string'
                ? fileType.toLowerCase()
                : fileType[0].toLowerCase();

        return (
            uploadFiles.length === 1 &&
            this.fiFileDrop.fileNameLength >= uploadFiles[0].name.length &&
            this.fiFileDrop.filesQuantity === 1 &&
            uploadFiles[0].type.toLowerCase() === formattedFileType &&
            uploadFiles[0].size <= this.fiFileDrop.fileSize
        );
    }

    private isMultipleFileDrop(uploadFiles: FileList): boolean {
        if (
            uploadFiles.length > 1 &&
            this.fiFileDrop.filesQuantity < uploadFiles.length
        ) {
            return false;
        }

        for (const item of uploadFiles) {
            if (
                item.size > this.fiFileDrop.fileSize ||
                item.name.length > this.fiFileDrop.fileNameLength ||
                isFileTypeValid(item.type, this.fiFileDrop.fileType)
            ) {
                return;
            }
        }

        return true;
    }
}
