import {
    NgSelectComponent,
    NgLabelTemplateDirective,
    NgOptionTemplateDirective,
} from '@ng-select/ng-select';

import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule,
    FormsModule,
} from '@angular/forms';
import {
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    EventEmitter,
    forwardRef,
    Input,
    Output,
    TemplateRef,
} from '@angular/core';
import { NgClass, NgTemplateOutlet } from '@angular/common';

import { HighlightMatchPipe } from '../../pipes/highlight-search-keyword.pipe';
import { OnChange } from '../../decorators';
import { IconComponent, CheckboxComponent } from '../../components';
import { SelectOption } from '../../models';
import { OverlayConnectedPosition } from '../overlay';
import { TooltipDirective } from '../tooltip';

@Component({
    selector: 'fi-select-dropdown',
    templateUrl: './select-dropdown.component.html',
    styleUrls: ['./select-dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectDropdownComponent),
            multi: true,
        },
    ],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        NgSelectComponent,
        TooltipDirective,
        NgLabelTemplateDirective,
        NgOptionTemplateDirective,
        CheckboxComponent,
        IconComponent,
        ReactiveFormsModule,
        FormsModule,
        HighlightMatchPipe,
    ],
})
export class SelectDropdownComponent implements ControlValueAccessor {
    @ContentChild('optionTemplate') optionTemplate!: TemplateRef<any>;
    @ContentChild('labelTemplate') labelTemplate!: TemplateRef<any>;

    @OnChange('writeValue')
    @Input()
    value: string | string[] = null;
    @Input() items: SelectOption[] = [];
    @Input() searchable = false;
    @Input() notFoundText = 'No Results Found';
    @Input() placeholder = 'Select...';
    @Input() clearable = false;
    @Input() multiple = false;
    @Input() required = false;
    @Input() invalid = false;
    @Input() disabled = false;
    @Input() closeOnSelect = true;
    @Input()
    iconTooltipPosition: OverlayConnectedPosition | OverlayConnectedPosition[] =
        OverlayConnectedPosition.Right;
    @Input() optionTooltipPosition:
        | OverlayConnectedPosition
        | OverlayConnectedPosition[] = OverlayConnectedPosition.Right;

    @Input() bindLabel: keyof SelectOption = 'description';
    @Input() bindValue: keyof SelectOption = 'code';

    @Output() valueChange = new EventEmitter<SelectOption | SelectOption[]>();

    readonly valueKey: keyof SelectOption = this.bindValue;

    onChange = (_: string | string[]) => {};
    onTouched = () => {};

    writeValue(value: string | string[]): void {
        this.value = value;
    }

    registerOnChange(fn: (value: string | string[]) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    onValueChange(value: SelectOption | SelectOption[]): void {
        if (this.multiple && Array.isArray(value)) {
            this.value = value.map((item) => item[this.valueKey]);
        } else if (this.valueKey in value) {
            this.value = value[this.valueKey];
        }

        this.onChange(this.value);
        this.onTouched();
        this.valueChange.emit(value);
    }
}
