import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    forwardRef,
    Inject,
    Input,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import dayjs, { Dayjs } from 'dayjs';

import { ConfigApp, ConfigAppToken, EnvironmentService } from '../../services';
import { OnChange } from '../../decorators';
import { VariationDirective } from '../../directives';
import { TimeFormatPipe} from '../../pipes';
import { ButtonComponent } from '../../components';
import { isEscapeModalEvent } from '../overlay';
import {
    ModalAnimationType,
    ModalEvent,
    ModalEventType,
    ModalComponent,
    ModalContentComponent,
    ModalFooterComponent,
    ModalHeaderComponent,
    ModalPosition,
} from '../modal';
import { TimePickerComponent } from './picker/picker.component';
import { TimePickerFormComponent } from './form/form.component';

@Component({
    selector: 'fi-time',
    templateUrl: './time.component.html',
    styleUrls: ['./time.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TimeComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AsyncPipe,
        VariationDirective,
        TimeFormatPipe,
        TimePickerComponent,
        TimePickerFormComponent,
        ModalComponent,
        ModalHeaderComponent,
        ModalContentComponent,
        ModalFooterComponent,
        ButtonComponent,
    ]
})
export class TimeComponent implements ControlValueAccessor {
    @Input() variation: string;
    @Input() chevronSize = 'xxx-large';
    @Input() disabled = false;
    @Input() title: string;
    @Input() listView: boolean;
    @Input() startHour: number;
    @Input() noCloseButton = true;
    @Input() submitButton = 'Apply';
    @Input() hasAdjustedMinutes = false;

    @OnChange(function (this: TimeComponent) {
        if (!this.value) {
            return;
        }

        this.time = this.value.clone();
        this.onChange(this.time.clone());
    })
    @Input()
    value: Dayjs;

    @Output() valueChange = new EventEmitter<Dayjs>();

    @ViewChild('modalTpl', { read: TemplateRef })
    modalTpl: TemplateRef<any>;

    @OnChange(function (this: TimeComponent) {
        this.bufferTime = this.time.clone();
    })
    time: Dayjs = dayjs();
    bufferTime: Dayjs;

    isPickerOpen = false;

    readonly timeStringFullFormat = this.CONFIG_APP.TIME_FORMAT.AMPM;
    readonly modalAnimation = ModalAnimationType.SlideTop;
    readonly modalPosition = ModalPosition.Bottom;

    readonly isMobileDevice$ = this.environmentService.isMobileDevice$;

    constructor(
        private environmentService: EnvironmentService,
        @Inject(ConfigAppToken) private CONFIG_APP: ConfigApp,
    ) {}

    writeValue(time: Dayjs): void {
        this.time = time.clone();
        this.valueChange.emit(time.clone());
    }

    registerOnChange(fn: (value: Dayjs) => void): void {
        this.onChange = fn;
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    registerOnTouched(): void {}

    show(event: MouseEvent): void {
        if (this.disabled) {
            return;
        }

        if (this.isPickerOpen) {
            event.stopPropagation();
            return;
        }

        this.bufferTime = this.time.clone();
        this.isPickerOpen = true;
    }

    hide(): void {
        this.isPickerOpen = false;
    }

    updateValue(time: Dayjs): void {
        this.onChange(time.clone());
        this.valueChange.emit(time.clone());
    }

    handleApply(): void {
        this.updateValue(this.bufferTime);
        this.hide();
    }

    handleModalEvent({ type }: ModalEvent): void {
        if (isEscapeModalEvent(type) || type === ModalEventType.OutsideClick) {
            this.hide();
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private onChange = (_: Dayjs) => {};
}
