import { NgModule, Type } from '@angular/core';

import { LeaveConfirmationModalComponent } from './leave-confirmation-modal/leave-confirmation-modal.component';
import { PreventLeaveModalComponent } from './prevent-leave-modal.component';

const FEATURES: Array<Type<any> | any[]> = [
    PreventLeaveModalComponent,
    LeaveConfirmationModalComponent,
];

@NgModule({
    imports: [...FEATURES],
    exports: FEATURES,
})
export class PreventLeaveModalModule {}
