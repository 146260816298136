import { Subject, Observable, defer } from 'rxjs';

import { EnvironmentService } from '../services/environment.service';
import { OverlayContainerRef } from '../feature/overlay';

import { getAnimationMetadata$ } from './toast-animation';

export type ToastCloseEvent = { withAction: boolean };

export class ToastReference {
    close$: Observable<ToastCloseEvent> = defer(() =>
        this.closeSubject.asObservable(),
    );

    private readonly closeSubject: Subject<ToastCloseEvent> = new Subject();

    constructor(
        private overlayContainerContainerRef: OverlayContainerRef<any>,
        private environmentService: EnvironmentService,
    ) {}

    close(withAction: boolean): void {
        getAnimationMetadata$(
            'close',
            this.environmentService.isMobile$,
        ).subscribe((animation) => {
            this.overlayContainerContainerRef.close({
                animation,
            });
            this.closeSubject.next({ withAction });
        });
    }
}
