import { Dayjs, UnitType } from 'dayjs';

export enum DateRangeSelectionMode {
    From = 'from',
    To = 'to',
}

export enum DateRangeModesTitle {
    From = 'From',
    To = 'To',
}

export enum RangeCalendarCurrentMonthPosition {
    left = 'LEFT',
    right = 'RIGHT',
}

export interface DateRange {
    [DateRangeSelectionMode.From]?: string;
    [DateRangeSelectionMode.To]?: string;
}

export interface DateRangeState {
    from?: Dayjs;
    to?: Dayjs;
}

export interface MaxDatesInRange {
    maxDates: number;
    rangeType: UnitType;
}
