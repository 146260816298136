<div
    #container
    [fiVariation]="variation"
    [fiVariationBase]="'toast'"
    class="toast"
>
    <div class="toast__info">
        <fi-icon
            [svgName]="variation | memoizeFunc: getIconName:this"
            [variation]="'solid'"
            [size]="isMobileDevice$ | async | memoizeFunc: getIconSize"
            class="toast__icon"
        ></fi-icon>

        <div class="toast__description">
            @if ([isMobileDevice$ | async, variation] | memoizeFunc : shouldShowToastLabel : this) {
                <span class="toast__label fi-text fi-text--heavy">
                    {{ variation | memoizeFunc: getLabel:this }}
                </span>
            }

            <span class="toast__text fi-text fi-text--dark" [innerHtml]="text"></span>
        </div>
    </div>

    <button
        #actionControl
        [attr.aria-label]="action"
        type="button"
        class="toast__action fi-text fi-text--medium fi-text--link"
    >
        {{ action }}
    </button>

    <fi-icon
        [svgName]="'times'"
        [variation]="'solid'"
        [size]="'small'"
        class="toast__close-icon"
    ></fi-icon>
</div>
