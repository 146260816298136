import { repeat } from 'lodash';

export const enum FiInputMaskKey {
    Filled = 'filled',
    Placeholder = 'placeholder',
}

export type FiInputMaskValue = {
    [FiInputMaskKey.Filled]: string;
    [FiInputMaskKey.Placeholder]: string;
};

export type FiInputCharacterMaskValue = {
    character: string;
    maskKey: FiInputMaskKey;
};

export function getEmptyMaskValue(): FiInputMaskValue {
    return {
        [FiInputMaskKey.Filled]: '',
        [FiInputMaskKey.Placeholder]: '',
    };
}

export abstract class FiInputMaskBase {
    abstract getValue(value: string): FiInputMaskValue;

    protected getFilledRangeWithPlaceholder(
        filled: string,
        expectedFilledAmount: number,
    ): FiInputMaskValue {
        const placeholderCharactersAmount =
            expectedFilledAmount - filled.length;

        return {
            filled,
            placeholder: repeat('_', placeholderCharactersAmount),
        };
    }
}
