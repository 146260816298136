import { NgModule, Type } from '@angular/core';

import { SelectDropdownComponent } from './select-dropdown.component';

const FEATURES: Array<Type<any> | any[]> = [SelectDropdownComponent];

@NgModule({
    imports: FEATURES,
    exports: FEATURES,
})
export class SelectDropdownModule {}
