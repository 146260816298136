import { formatAsDateString } from '../../../../utils/transform-numeric-value';

import {
    getEmptyMaskValue,
    FiInputMaskValue,
    FiInputMaskBase,
} from './input-mask-base';

const DATE_FORMAT = 'MM/DD/YYYY';

export class DateInputMask extends FiInputMaskBase {
    getValue(value: string): FiInputMaskValue {
        const maskValue = getEmptyMaskValue();

        const filled = formatAsDateString(value);
        const placeholder = DATE_FORMAT.substring(filled.length);

        maskValue.filled = filled;
        maskValue.placeholder = placeholder;

        return maskValue;
    }
}
