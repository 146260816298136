import {
    AfterContentInit,
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    ContentChildren,
    ElementRef,
    HostBinding,
    HostListener,
    QueryList,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
    Input,
    OnDestroy,
    inject,
} from '@angular/core';
import {
    ActiveDescendantKeyManager,
    FocusMonitor,
    FocusOrigin,
} from '@angular/cdk/a11y';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { BehaviorSubject, merge, of, Observable } from 'rxjs';
import {
    exhaustMap,
    filter,
    first,
    mapTo,
    skip,
    switchMap,
} from 'rxjs/operators';

import { KeyCode } from '../../constants';
import {
    OverlayContainerRef,
    OverlayEvent,
    OverlayEventType,
    OverlayService,
    OverlayConnectedPosition,
    OverlayScrollStrategy,
    isBlurEvent,
} from '../overlay';

import { ContextMenuItemComponent } from './item/item.component';
import { ContextMenuListDirective } from './list/list.directive';
import { ContextMenuItemContainerDirective } from './item/item-container.directive';

const enum ContextMenuVariation {
    Small = 'small',
    Regular = 'regular',
    IndentTop = 'indent-top',
}

@UntilDestroy()
@Component({
    selector: 'fi-context-menu',
    templateUrl: './context-menu.component.html',
    styleUrls: ['./context-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    exportAs: 'fiContextMenu',
    standalone: true,
})
export class ContextMenuComponent implements AfterContentInit, OnDestroy {
    private focusMonitor = inject(FocusMonitor);
    private elementRef = inject(ElementRef);
    private overlayService = inject(OverlayService);
    private viewContainerRef = inject(ViewContainerRef);

    @Input()
    positions = [
        OverlayConnectedPosition.BottomLeft,
        OverlayConnectedPosition.BottomRight,
        OverlayConnectedPosition.TopLeft,
        OverlayConnectedPosition.TopRight,
    ];

    @Input() variation: ContextMenuVariation = ContextMenuVariation.Regular;
    @Input() stopPropagation = false;

    @ContentChild(ContextMenuListDirective, {
        read: TemplateRef,
    })
    list: TemplateRef<ContextMenuListDirective>;

    @ContentChildren(ContextMenuItemComponent)
    items: QueryList<ContextMenuItemComponent>;

    @ContentChildren(ContextMenuItemContainerDirective)
    itemContainers: QueryList<ContextMenuItemContainerDirective>;

    @ViewChild('origin') origin: ElementRef;

    @HostBinding('attr.tabindex') tabindex = 0;

    private keyManager: ActiveDescendantKeyManager<ContextMenuItemComponent>;
    private overlayContainerRef: OverlayContainerRef<TemplateRef<any>> | null;

    private isDropdownOpen = new BehaviorSubject<boolean>(false);
    isDropdownOpen$ = this.isDropdownOpen.asObservable();

    private closeDropdown$ = this.isDropdownOpen$.pipe(
        skip(1),
        filter((isOpen) => !isOpen),
        mapTo(null),
        untilDestroyed(this),
    );

    private openDropdown$ = this.isDropdownOpen$.pipe(
        skip(1),
        filter((isOpen) => isOpen),
        mapTo(null),
        untilDestroyed(this),
    );

    private itemClick$: Observable<null>;
    private itemContainerClick$: Observable<null>;

    private focusMonitor$: Observable<FocusOrigin> = this.focusMonitor.monitor(
        this.elementRef.nativeElement,
    );

    private focusDropdown$: Observable<null> = this.focusMonitor$.pipe(
        filter((origin: FocusOrigin) => !!origin),
        mapTo(null),
        untilDestroyed(this),
    );

    private blurDropdown$: Observable<null> = this.focusMonitor$.pipe(
        switchMap(() => this.overlayContainerRef.event$),
        filter(({ type }: OverlayEvent) => isBlurEvent(type)),
        mapTo(null),
        untilDestroyed(this),
    );

    private overlayDetached$: Observable<null> = of(null).pipe(
        switchMap(() => this.overlayContainerRef.event$),
        filter(({ type }: OverlayEvent) => type === OverlayEventType.Detach),
        mapTo(null),
        untilDestroyed(this),
    );

    @HostListener('keydown', ['$event'])
    onKeydown(event: KeyboardEvent): void {
        if (event.code !== KeyCode.Tab) {
            event.preventDefault();
        }

        this.keyManager.onKeydown(event);

        if (event.key === KeyCode.Enter) {
            this.triggerClickOnItem(this.keyManager.activeItem);
        }
    }

    @HostListener('keydown.space', ['$event'])
    @HostListener('keydown.enter', ['$event'])
    onSpaceKeydown(event: KeyboardEvent): void {
        event.preventDefault();
        this.isDropdownOpen.next(true);
    }

    @HostListener('click', ['$event'])
    onMouseClick(event: MouseEvent): void {
        if (this.stopPropagation) {
            event.stopPropagation();
        }

        this.isDropdownOpen.next(true);
    }

    @HostListener('keydown.escape')
    onEscapeKeydown(): void {
        this.isDropdownOpen.next(false);
    }

    ngAfterContentInit(): void {
        this.itemClick$ = this.getItemListClick$(this.items.changes);
        this.itemContainerClick$ = this.getItemListClick$(
            this.itemContainers.changes,
        );

        this.keyManager = new ActiveDescendantKeyManager(this.items)
            .skipPredicate(({ disabled }: ContextMenuItemComponent) => disabled)
            .withWrap();

        this.focusDropdown$
            .pipe(
                exhaustMap(() => this.openDropdown$),
                exhaustMap(() => {
                    this.openDropdown();

                    return merge(
                        this.closeDropdown$,
                        this.itemClick$,
                        this.itemContainerClick$,
                        this.blurDropdown$,
                        this.overlayDetached$,
                    ).pipe(first(), untilDestroyed(this));
                }),
                untilDestroyed(this),
            )
            .subscribe(() => {
                this.closeDropdown();
                this.isDropdownOpen.next(false);
            });
    }

    ngOnDestroy(): void {
        this.focusMonitor.stopMonitoring(this.elementRef.nativeElement);
        this.closeDropdown();
    }

    closeDropdown(): void {
        if (!this.overlayContainerRef) {
            return;
        }

        this.overlayContainerRef.close();
        this.overlayContainerRef = null;
    }

    private openDropdown(): void {
        if (this.overlayContainerRef) {
            return;
        }

        const baseClass = 'context-menu';
        const classVariations = this.variation
            .split(',')
            .map((variation: string) => `${baseClass}-${variation}`);

        this.overlayContainerRef = this.overlayService.create(
            this.list,
            this.viewContainerRef,
            {
                variation: [baseClass, ...classVariations],
                positions: this.positions,
                scrollStrategy: OverlayScrollStrategy.Close,
            },
            null,
            this.origin,
        );

        this.overlayContainerRef.open();
    }

    private triggerClickOnItem(item: ContextMenuItemComponent): void {
        if (!item || !item.elementRef.nativeElement) {
            return;
        }

        const event = new MouseEvent('mousedown', { bubbles: true });
        item.elementRef.nativeElement.dispatchEvent(event);
    }

    private getItemListClick$(
        items: Observable<
            QueryList<
                ContextMenuItemComponent | ContextMenuItemContainerDirective
            >
        >,
    ): Observable<null> {
        return items.pipe(
            switchMap((items) => merge(...items.map((item) => item.click$))),
            mapTo(null),
            untilDestroyed(this),
        );
    }
}
