import { Injectable } from '@angular/core';

export const enum FileExtensionIcons {
    Image = 'file-image',
    Pdf = 'file-pdf',
}

export const enum FileType {
    Doc = '.doc',
    ExtendedDoc = '.docx',
    Excel = '.xls',
    ExtendedExcel = '.xlsx',
    Pdf = '.pdf',
    Avi = '.avi',
    Mp4 = '.mp4',
    Mpeg = '.mpeg',
    Jpg = '.jpg',
    Png = '.png',
}

export const enum FileTypeIcon {
    Word = 'file-word',
    Image = 'file-image',
    Excel = 'file-excel',
    Pdf = 'file-pdf',
    Video = 'file-video',
    Error = 'file-exception',
}

@Injectable({
    providedIn: 'root',
})
export class AttachmentHelperService {
    getAttachmentIcon(attachmentExtension: FileType | string): FileTypeIcon {
        switch (attachmentExtension) {
            case FileType.Jpg:
            case FileType.Png:
                return FileTypeIcon.Image;
            case FileType.Pdf:
                return FileTypeIcon.Pdf;
            case FileType.Doc:
            case FileType.ExtendedDoc:
                return FileTypeIcon.Word;
            case FileType.Excel:
            case FileType.ExtendedExcel:
                return FileTypeIcon.Excel;
            case FileType.Avi:
            case FileType.Mp4:
            case FileType.Mpeg:
                return FileTypeIcon.Video;
            default:
                return FileTypeIcon.Image;
        }
    }
}
