<div class="guided-tour-indicator">
    @if ((isMobileView$ | async) === false) {
        <span class="fi-text fi-text--light fi-text--small">
            Step {{ activeStepIndex }} of {{ stepsQuantity }}
        </span>
    }

    <div class="guided-tour-indicator__items">
        @for (index of steps; track index) {
            <span
                [class.guided-tour-indicator__item--active]="
                    index === activeStepIndex
                "
                (click)="changeActiveStepIndex(index)"
                role="button"
                aria-label="Next step"
                class="guided-tour-indicator__item"
            ></span>
        }
    </div>
</div>
