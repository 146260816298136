import { Directive, Input } from '@angular/core';

import { GridCustomConfiguration } from '../models/custom-configuration';

@Directive({
    selector: '[fiGridCustomConfig]',
    exportAs: 'fiGridCustomConfig',
    standalone: true,
})
export class GridCustomConfigDirective {
    @Input('fiGridCustomConfig') readonly config: GridCustomConfiguration;
}
