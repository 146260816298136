import { every, find, some, values, flattenDeep, uniqBy } from 'lodash';

import { GridRow } from '../models';
import { GridNestedPagedResultMap } from '../store/models';

export function isAllRowsSelected(
    rows: GridRow[],
    selectedRows: GridRow[],
): boolean {
    return every(rows, ({ id }) => !!find(selectedRows, ['id', id]));
}

export function isSomeRowsSelected(
    rows: GridRow[],
    selectedRows: GridRow[],
): boolean {
    return some(rows, ({ id }) => !!find(selectedRows, ['id', id]));
}

export function getNestedItems(
    nestedRows: GridNestedPagedResultMap<GridRow>,
): GridRow[] {
    const res = values(nestedRows).map(({ data }) => data);

    return flattenDeep(res);
}

export function selectParentRows(
    row: GridRow,
    selectedRows: GridRow[],
    allRows: GridRow[],
): GridRow[] {
    let selected: GridRow[] = [];
    const parentRow = getParentRow(row, selectedRows, allRows);

    if (parentRow) {
        selected = [...selected, parentRow];
        if (parentRow.parentId) {
            const res = selectParentRows(
                parentRow,
                [...selectedRows, parentRow],
                allRows,
            );
            selected = uniqBy([...selected, ...res], 'id');
        }
    }

    return selected;
}

export function getParentRows(
    row: GridRow,
    allRows: GridRow[],
    selectedRows: GridRow[],
): GridRow[] {
    let parents: GridRow[] = [];
    const parentRow = getParentRow(row, selectedRows, allRows);

    if (parentRow) {
        parents.push(parentRow);
        parents = [
            ...parents,
            ...getParentRows(parentRow, allRows, selectedRows),
        ];
    }

    return parents;
}

function getParentRow(
    row: GridRow,
    selectedRows: GridRow[],
    allRows: GridRow[],
): GridRow {
    const siblings = getSiblings(row, allRows);
    const isAllSiblingsSelected = isAllRowsSelected(siblings, selectedRows);

    if (isAllSiblingsSelected) {
        return allRows.find(({ id }) => id === row.parentId);
    }
}

function getSiblings(row: GridRow, allRows: GridRow[]): GridRow[] {
    return allRows.filter(({ parentId }) => row.parentId === parentId);
}
