import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';

import { SkeletonElementComponent } from '../../../../components/skeleton/element/skeleton-element.component';

const COLUMNS_LENGTH = 5;
const ROWS_LENGTH = 8;

@Component({
    selector: 'fi-grid-table-loader-skeleton',
    templateUrl: './loader-skeleton.component.html',
    styleUrls: ['./loader-skeleton.component.scss'],
    standalone: true,
    imports: [SkeletonElementComponent],
})
export class GridTableLoaderSkeletonComponent implements AfterViewInit {
    columns: null[] = Array(COLUMNS_LENGTH).fill(null);
    rows: null[] = Array(ROWS_LENGTH).fill(null);

    constructor(private cdRef: ChangeDetectorRef) {}

    ngAfterViewInit(): void {
        this.cdRef.detach();
    }
}
