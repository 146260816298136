import { Injectable } from '@angular/core';

import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isoWeek from 'dayjs/plugin/isoWeek';
import localeData from 'dayjs/plugin/localeData';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import objectSupport from 'dayjs/plugin/objectSupport';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

@Injectable({
    providedIn: 'root',
})
export class DateTimeService {
    constructor() {
        dayjs.extend(utc);
        dayjs.extend(timezone);
        dayjs.extend(localeData);
        dayjs.extend(advancedFormat);
        dayjs.extend(isoWeek);
        dayjs.extend(isBetween);
        dayjs.extend(isSameOrBefore);
        dayjs.extend(isSameOrAfter);
        dayjs.extend(objectSupport);
        dayjs.extend(quarterOfYear);
        dayjs.extend(customParseFormat);
    }

    timeFormat(
        time: string | Dayjs,
        timeFormat: string,
        placeholder: string,
        isLocalTimeRelated = false,
    ): string {
        if (!time || time === '0') {
            return placeholder;
        }
        const stringTime = time as string;

        return isLocalTimeRelated
            ? // to show local time right we cut time offset value and use only UTC date value
            dayjs
                .utc(stringTime.substring(0, stringTime.length - 6))
                .local()
                .format(timeFormat)
            : dayjs(time).format(timeFormat);
    }
}
