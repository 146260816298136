<div [fiVariation]="styleVariation" [fiVariationBase]="'fi-input-comment'">
    <div
        [class.fi-input-comment__container--focused]="isFocused"
        [class.fi-input-comment__container--error]="invalid"
        class="fi-input-comment__container"
    >
        <textarea
            #textareaElement
            cdkTextareaAutosize
            [cdkAutosizeMaxRows]="maxRows"
            [id]="id"
            [maxlength]="warningIndicator ? null : maxlength"
            [placeholder]="placeholder"
            [disabled]="disabled"
            [ngModel]="value"
            [attr.required]="required"
            (ngModelChange)="handleInputChange($event)"
            (focus)="handleInputFocus()"
            (blur)="handleInputBlur()"
            (keydown)="handleKeydownEvent($event)"
            class="fi-input-comment__textarea"
        ></textarea>
    </div>

    @if ((limitVisible || isFocused) && !warningIndicator) {
        <div class="fi-input-comment__limit fi-text fi-text--light">
            @if (isFullRemainingFormat) {
                {{ maxlength - value.length }} characters remaining
            } @else {
                {{ value.length }}/{{ maxlength }}
            }
        </div>
    }

    @if (warningIndicator) {
        <div class="fi-input-comment__warning-indicator">
            @if (value | memoizeFunc: isTooMany : this) {
                <div>
                    <span
                        class="fi-text fi-text--heavy fi-text--small fi-text--error"
                    >
                        {{ value.length - maxlength }}
                    </span>
                    <span
                        class="fi-text fi-text--medium fi-text--error fi-text--small"
                    >
                        character{{ value.length - maxlength === 1 ? '' : 's' }}
                        too many
                    </span>
                </div>
            }
            @if (invalid && !value.length) {
                <div
                    class="fi-text fi-text--medium fi-text--error fi-text--small"
                >
                    {{ errorMsg }}
                </div>
            }
            @if (!(value | memoizeFunc: isTooMany : this) && !invalid) {
                <div>
                    <span
                        class="fi-text fi-text--light fi-text--small fi-text--medium"
                    >
                        {{ maxlength - value.length }} characters remaining
                    </span>
                </div>
            }
        </div>
    }
</div>
