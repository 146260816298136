import {
    animate,
    AnimationQueryMetadata,
    query,
    style,
} from '@angular/animations';

const TOOLTIP_TAG = 'fi-tooltip-v2';

const TOP_CLASSES = [
    `.fi-overlay-pane--position-top ${TOOLTIP_TAG}`,
    `.fi-overlay-pane--position-top-left ${TOOLTIP_TAG}`,
    `.fi-overlay-pane--position-top-right ${TOOLTIP_TAG}`,
];

const BOTTOM_CLASSES = [
    `.fi-overlay-pane--position-bottom ${TOOLTIP_TAG}`,
    `.fi-overlay-pane--position-bottom-left ${TOOLTIP_TAG}`,
    `.fi-overlay-pane--position-bottom-right ${TOOLTIP_TAG}`,
];

const RIGHT_CLASSES = [
    `.fi-overlay-pane--position-right ${TOOLTIP_TAG}`,
    `.fi-overlay-pane--position-right-top ${TOOLTIP_TAG}`,
    `.fi-overlay-pane--position-right-bottom ${TOOLTIP_TAG}`,
];

const LEFT_CLASSES = [
    `.fi-overlay-pane--position-left ${TOOLTIP_TAG}`,
    `.fi-overlay-pane--position-left-top ${TOOLTIP_TAG}`,
    `.fi-overlay-pane--position-left-bottom ${TOOLTIP_TAG}`,
];

const ANIMATION_TIME = '200ms';
const ANIMATION_DISTANCE = '1.45em';

function createQueryAnimation(
    selector: string[],
    from:
        | '*'
        | {
              [key: string]: string | number;
          }
        | Array<
              | '*'
              | {
                    [key: string]: string | number;
                }
          >,
    to:
        | '*'
        | {
              [key: string]: string | number;
          }
        | Array<
              | '*'
              | {
                    [key: string]: string | number;
                }
          >,
): AnimationQueryMetadata {
    return query(
        selector.join(','),
        [style(from), animate(ANIMATION_TIME, style(to))],
        { optional: true },
    );
}

export const ENTER_ANIMATIONS: AnimationQueryMetadata[] = [
    createQueryAnimation(
        TOP_CLASSES,
        { transform: `translate(0, ${ANIMATION_DISTANCE})`, opacity: 0 },
        '*',
    ),
    createQueryAnimation(
        BOTTOM_CLASSES,
        { transform: `translate(0, -${ANIMATION_DISTANCE})`, opacity: 0 },
        '*',
    ),
    createQueryAnimation(
        RIGHT_CLASSES,
        { transform: `translate(${ANIMATION_DISTANCE}, 0)`, opacity: 0 },
        '*',
    ),
    createQueryAnimation(
        LEFT_CLASSES,
        { transform: `translate(-${ANIMATION_DISTANCE}, 0)`, opacity: 0 },
        '*',
    ),
];

export const LEAVE_ANIMATIONS: AnimationQueryMetadata[] = [
    createQueryAnimation(TOP_CLASSES, '*', {
        transform: `translate(0, ${ANIMATION_DISTANCE})`,
        opacity: 0,
    }),
    createQueryAnimation(BOTTOM_CLASSES, '*', {
        transform: `translate(0, -${ANIMATION_DISTANCE})`,
        opacity: 0,
    }),
    createQueryAnimation(RIGHT_CLASSES, '*', {
        transform: `translate(${ANIMATION_DISTANCE}, 0)`,
        opacity: 0,
    }),
    createQueryAnimation(LEFT_CLASSES, '*', {
        transform: `translate(-${ANIMATION_DISTANCE}, 0)`,
        opacity: 0,
    }),
];
