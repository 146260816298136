<div class="carousel-indicator">
    @for (item of items; track item) {
        <span
            [class.carousel-indicator__item--active]="item === activeItemIndex"
            (click)="changeActiveItemIndex(item)"
            role="button"
            aria-label="Change"
            class="carousel-indicator__item"
        >
            &#9679;
        </span>
    }
</div>
