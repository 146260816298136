<ng-content></ng-content>
<ng-container
    [ngTemplateOutlet]="tpl ? tpl : row[fieldName] ? defaultTpl : emptyTpl"
    [ngTemplateOutletContext]="{
        row: row
    }"
></ng-container>

<ng-template #defaultTpl>
    {{ row[fieldName] }}
</ng-template>

<ng-template #emptyTpl></ng-template>
