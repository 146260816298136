import { NgControl } from '@angular/forms';
import { DecimalPipe } from '@angular/common';

import { Observable } from 'rxjs';

import {
    getNumericInputCaretOriginalPosition,
    replaceValueNonDigits,
} from '../../../utils';
import { ConfigApp } from '../../../services/config.service';

import { InputFormatBase, InputFormatParsedValue } from './input-format-base';

export class OdometerHoursFormat extends InputFormatBase {
    valueChanges$: Observable<string>;

    constructor(
        ngControl: NgControl,
        private decimalPipe: DecimalPipe,
        private inputElement: HTMLInputElement,
        private initialValue: string,
        private CONFIG: ConfigApp,
    ) {
        super(ngControl);

        const transformedInitialValue = this.getTransformedValue(
            this.initialValue,
        );
        this.setControlValue(transformedInitialValue);

        this.valueChanges$ = this.getInputEvent(transformedInitialValue);
    }

    protected getParsedValue([
        previousValue,
        currentValue,
    ]: string[]): InputFormatParsedValue {
        const {
            selectionEnd: caretPosition,
            value: originalValue, // just updated yet unformatted value
        } = this.inputElement;
        const isValueDeleted = originalValue.length < previousValue.length;

        return {
            caretPosition,
            isValueDeleted,
            originalValue,
            transformedValue: this.getTransformedValue(currentValue),
        };
    }

    protected returnCaretToOriginalPosition({
        caretPosition,
        originalValue,
        transformedValue,
        isValueDeleted,
    }: InputFormatParsedValue) {
        const beforeCaretValue = replaceValueNonDigits(
            originalValue.substring(0, caretPosition),
            this.CONFIG.ODOMETER_HOURS_LENGTH,
        );

        const targetCaretPosition = getNumericInputCaretOriginalPosition(
            beforeCaretValue,
            transformedValue,
            isValueDeleted,
        );

        this.inputElement.setSelectionRange(
            targetCaretPosition,
            targetCaretPosition,
        );
    }

    private getTransformedValue(value: string): string {
        if (!value) {
            return '';
        }

        const digitsValue = replaceValueNonDigits(
            value,
            this.CONFIG.ODOMETER_HOURS_LENGTH,
        );

        return this.transformValue(digitsValue);
    }

    private transformValue(value: string): string {
        return this.decimalPipe.transform(value) || '';
    }
}
