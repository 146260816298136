import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'fi-metrics-tab',
    templateUrl: './metrics-tab.component.html',
    styleUrls: ['./metrics-tab.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class MetricsTabComponent {
    @Input() isActiveTab: boolean;
    @Input() ariaLabel: string = '';
}
