import { CommonModule } from '@angular/common';
import { NgModule, NO_ERRORS_SCHEMA, Type } from '@angular/core';

import { ModalModule } from '../modal';
import { UiCommonModule } from '../../ui-common.module';

import { FilteringDirective } from './filtering.directive';
import { FilteringItemDefDirective } from './item/item-def.directive';
import { FilteringOpenerComponent } from './opener/opener.component';
import { FilteringAppliedItemDefDirective } from './applied/item/applied-item-def.directive';
import { FilteringAppliedItemsComponent } from './applied/items/items.component';
import { FilteringPanelComponent } from './panel/panel.component';
import { FilteringListComponent } from './list/list.component';
import { FilteringPickerComponent } from './picker/picker.component';
import { SimpleFilteringComponent } from './simple/simple.component';

const FEATURES: Array<Type<any> | any[]> = [
    FilteringDirective,
    FilteringItemDefDirective,
    FilteringOpenerComponent,
    FilteringAppliedItemDefDirective,
    FilteringAppliedItemsComponent,
    FilteringPanelComponent,
    FilteringListComponent,
    FilteringPickerComponent,
    SimpleFilteringComponent,
];

@NgModule({
    imports: [...FEATURES],
    exports: FEATURES,
    schemas: [NO_ERRORS_SCHEMA],
})
export class FilteringModule {}
