import {
    Component,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
    OnChanges,
    inject,
} from '@angular/core';

import dayjs from 'dayjs';

import { EnvironmentService } from '../../../services';
import { IsDesktopDeviceDirective, IsMobileDeviceDirective } from '../../../directives';
import { ButtonComponent } from '../../../components';
import { MemoizeFuncPipe, PluralizeStringPipe } from '../../../pipes';
import {
    ModalCloseBarComponent,
    ModalComponent,
    ModalContentComponent,
    ModalEvent, ModalFooterComponent,
    ModalHeaderComponent,
    ModalVariation
} from '../../modal';
import { isEscapeModalEvent } from '../../modal/utils';
import {
    DateRangeModesTitle,
    DateRangeState,
    MaxDatesInRange,
    RangeCalendarCurrentMonthPosition,
} from '../models';
import { DateRangeCalendarComponent } from '../range-calendar/range-calendar.component';

@Component({
    selector: 'fi-date-range-modal',
    templateUrl: './range-modal.component.html',
    styleUrls: ['./range-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        ModalComponent,
        ModalHeaderComponent,
        ModalCloseBarComponent,
        ModalContentComponent,
        ModalFooterComponent,
        ButtonComponent,
        IsMobileDeviceDirective,
        IsDesktopDeviceDirective,
        PluralizeStringPipe,
        MemoizeFuncPipe,
        DateRangeCalendarComponent,
    ]
})
export class DateRangeModalComponent implements OnChanges {
    private readonly environmentService = inject(EnvironmentService);

    @Input() open: boolean;
    @Input() dateRange: DateRangeState;
    @Input() availableDateRange: DateRangeState;
    @Input() maxDatesInRange: MaxDatesInRange;
    @Input() disabledDaysOfWeek: number[];
    @Input() singleDateMode: boolean;
    @Input() oneDateSelectionAvailable = true;
    @Input() currentMonthPosition: RangeCalendarCurrentMonthPosition;
    @Input() fromTitle = DateRangeModesTitle.From;
    @Input() toTitle = DateRangeModesTitle.To;
    @Input() toDateMessageInfo: string;
    @Input() modalTitle: string;
    @Input() rangeTitle: string;

    @Output() dateRangeSelected = new EventEmitter<DateRangeState>();
    @Output() openChange = new EventEmitter<boolean>();
    @Output() confirm = new EventEmitter<void>();

    readonly isMobileDevice$ = this.environmentService.isMobileDevice$;
    readonly modalVariation: ModalVariation[] = [
        ModalVariation.FullHeight,
        ModalVariation.Wide700x,
    ];

    totalDays: number;
    tempDateRange: DateRangeState;

    ngOnChanges(): void {
        this.updateState();
    }

    onCloseModal(): void {
        this.openChange.emit(false);
        this.open = false;
        this.tempDateRange = null;
        this.totalDays = 0;
    }

    onDateRangeChanged(dates: DateRangeState): void {
        this.tempDateRange = {
            ...(dates?.from && { from: dayjs(dates?.from) }),
            ...(dates?.to && { to: dayjs(dates?.to) }),
        };
        this.totalDays = this.calculateTotalDays(this.tempDateRange);
    }

    onConfirmDates(): void {
        this.dateRange = { ...this.tempDateRange };
        this.dateRangeSelected.emit(this.dateRange);
    }

    handleModalEvent({ type }: ModalEvent): void {
        if (isEscapeModalEvent(type)) {
            this.onCloseModal();
        }
    }

    isSelectButtonEnabled(tempDateRange: DateRangeState): boolean {
        if (!tempDateRange) {
            return false;
        }

        const isFromDateValid = tempDateRange.from?.isValid();

        if (this.singleDateMode) {
            return isFromDateValid;
        }

        return isFromDateValid && tempDateRange.to?.isValid();
    }

    private calculateTotalDays(dateRange: DateRangeState): number {
        const { from, to } = dateRange;
        if (!from) {
            return 0;
        }

        return Math.abs(from.diff(to || from, 'day')) + 1;
    }

    private updateState(): void {
        if (this.dateRange) {
            this.onDateRangeChanged(this.dateRange);
        }
    }
}
