import {
    Component,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
    ElementRef,
    inject,
} from '@angular/core';
import { AsyncPipe, NgClass } from '@angular/common';

import dayjs from 'dayjs';
import { combineLatest, map } from 'rxjs';

import { EnvironmentService } from '../../../services';
import { IconComponent } from '../../../components';
import {
    DateRangeModesTitle,
    DateRangeSelectionMode,
    DateRangeState,
    MaxDatesInRange,
    RangeCalendarCurrentMonthPosition,
} from '../models';
import { DateRangeModalComponent } from '../range-modal/range-modal.component';
import { DateRangeCustomComponent } from '../range-custom/range-custom.component';

@Component({
    selector: 'fi-date-range-control',
    templateUrl: './range-control.component.html',
    styleUrls: ['./range-control.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AsyncPipe,
        NgClass,
        IconComponent,
        DateRangeModalComponent,
        DateRangeCustomComponent,
    ]
})
export class DateRangeControlComponent {
    public readonly elementRef = inject(ElementRef);
    private readonly environmentService = inject(EnvironmentService);

    @Input() dateRange: DateRangeState;
    @Input() availableDateRange: DateRangeState;
    @Input() maxDatesInRange: MaxDatesInRange;
    @Input() disabledDaysOfWeek: number[];
    @Input() singleDateMode: boolean;
    @Input() oneDateSelectionAvailable = true;
    @Input() currentMonthPosition: RangeCalendarCurrentMonthPosition;
    @Input() fromTitle = DateRangeModesTitle.From;
    @Input() toTitle = DateRangeModesTitle.To;
    @Input() toDateMessageInfo: string;
    @Input() modalTitle: string;
    @Input() variation: string;

    @Output() dateRangeSelected = new EventEmitter<DateRangeState>();

    readonly dateFormat = 'MM/DD/YY';
    readonly dateRangeSelectionMode = DateRangeSelectionMode;
    readonly isTabletOrMobile$ = combineLatest([
        this.environmentService.isSmallTabletDevice$,
        this.environmentService.isMobileDevice$,
    ]).pipe(
        map(
            ([isSmallTablet, isMobileDevice]) =>
                isSmallTablet || isMobileDevice,
        ),
    );

    isModalOpen: boolean;

    openModal(): void {
        this.isModalOpen = true;
    }

    onDateRangeSelected(dates: DateRangeState): void {
        const dateRangeSelected = {
            ...(dates?.from && { from: dayjs(dates.from) }),
            ...(dates?.to && { to: dayjs(dates.to) }),
        };
        this.dateRangeSelected.emit(dateRangeSelected);
        this.isModalOpen = false;
    }

    onTimesIconClicked(key: DateRangeSelectionMode): void {
        this.onClearDate(key);
    }

    private onClearDate(fieldType: DateRangeSelectionMode): void {
        const dateRangeUpdated =
            fieldType === DateRangeSelectionMode.From
                ? null
                : { ...this.dateRange, to: null };

        this.dateRangeSelected.emit(dateRangeUpdated);
    }
}
