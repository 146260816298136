import { Component, ElementRef, Input, ViewChild, inject } from '@angular/core';
import { AsyncPipe } from '@angular/common';

import { Observable } from 'rxjs';

import { EnvironmentService } from '../../../../services';
import { OnChange } from '../../../../decorators';
import { IconComponent } from '../../../../components';
import { TooltipDirective } from '../../../tooltip';

@Component({
    selector: 'fi-guided-tour-button',
    templateUrl: './guided-tour-button.component.html',
    styleUrls: ['./guided-tour-button.component.scss'],
    standalone: true,
    imports: [AsyncPipe, TooltipDirective, IconComponent],
})
export class GuidedTourButtonComponent {
    private readonly environmentService = inject(EnvironmentService);

    @OnChange('handleTabletView')
    @Input()
    isTabletView = false;

    @ViewChild('customGuidedTourBtn') customGuidedTourBtn: ElementRef;

    isMobile$: Observable<boolean>;

    private handleTabletView(): void {
        this.isMobile$ = this.isTabletView
            ? this.environmentService.isMobileDevice$
            : this.environmentService.isMobile$;
    }
}
