import { ActivatedRoute, ActivatedRouteSnapshot, Data } from '@angular/router';

import { of, OperatorFunction, pipe } from 'rxjs';
import { expand, mergeMap, pluck, takeWhile } from 'rxjs/operators';

export function getDeepestRouteSnapshot(
    snapshot: ActivatedRouteSnapshot,
): ActivatedRouteSnapshot {
    let deepestSnapshot = snapshot;

    while (!!deepestSnapshot.firstChild) {
        deepestSnapshot = deepestSnapshot.firstChild;
    }

    return deepestSnapshot;
}

export function getDeepestRouteData(): OperatorFunction<ActivatedRoute, Data> {
    return pipe(
        mergeMap((route: ActivatedRoute) => {
            return of(route).pipe(
                expand((route: ActivatedRoute) => of(route.children[0])),
                takeWhile((route) => !!route),
            );
        }),
        pluck('snapshot', 'data'),
    );
}
