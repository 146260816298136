<ng-container #contentRef>
    <ng-content></ng-content>
</ng-container>

@if (!contentRef?.children?.length) {
    <ul class="fi-grid-skeleton-list">
        @for (item of items; track item) {
            <li class="fi-grid-skeleton-list__item">
                <fi-skeleton-element
                    class="fi-grid-skeleton-list__element"
                ></fi-skeleton-element>
                <fi-skeleton-element
                    class="fi-grid-skeleton-list__element fi-grid-skeleton-list__element--two-thirds"
                ></fi-skeleton-element>
                <fi-skeleton-element
                    class="fi-grid-skeleton-list__element fi-grid-skeleton-list__element--moved"
                ></fi-skeleton-element>
                <fi-skeleton-element
                    class="fi-grid-skeleton-list__element fi-grid-skeleton-list__element--two-thirds"
                ></fi-skeleton-element>
            </li>
        }
    </ul>
}
