import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'fi-checkbox-field',
    templateUrl: './checkbox-field.component.html',
})
export class FiCheckboxFieldComponent {
    @Input() value: any;
    @Input() formGroup: UntypedFormGroup;
    @Input() name: string;

    get currentValue(): any {
        return this.formGroup.controls[this.name].value;
    }

    handleChange(): void {
        const value = this.formGroup.value[this.name];

        this.formGroup.controls[this.name].setValue(value ? null : this.value);
    }
}
