<div
    [ngClass]="{
        'fi-loader-penske--large': large,
        'fi-loader-penske--no-bg': noBackground,
        'fi-loader-penske--centered': isCentered,
    }"
    class="fi-loader-penske"
>
    <div class="fi-loader-penske__inner">
        <div class="fi-loader-penske__wrapper">
            <fi-icon
                [svgName]="'penske-P'"
                [variation]="'custom'"
                [size]="loaderSizeConfig.letterSize"
                class="fi-loader-penske__icon-middle"
            ></fi-icon>
            <fi-icon
                [svgName]="'inner-arc'"
                [variation]="'custom'"
                [size]="loaderSizeConfig.innerCircleSize"
                class="fi-loader-penske__icon-inner"
            ></fi-icon>
            <fi-icon
                [svgName]="'outer-arc'"
                [variation]="'custom'"
                [size]="loaderSizeConfig.outerCircleSize"
                class="fi-loader-penske__icon-outer"
            ></fi-icon>
        </div>

        @if (loaderText) {
            <p
                [class.fi-text--x-large]="large"
                class="fi-loader-penske__text fi-text fi-text--medium fi-text--dark"
            >
                {{ loaderText }}
            </p>
        }
    </div>
</div>
