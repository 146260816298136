import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';

import {
    UserIdValidationError,
    UserIdValidationErrorNames,
} from '../../validators';

@Component({
    selector: 'fi-user-id-requirements',
    templateUrl: './user-id-requirements.component.html',
    styleUrls: ['./user-id-requirements.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgClass
    ]
})
export class UserIdRequirementsComponent {
    @Input() errors: UserIdValidationError = {};

    readonly userIdRequirements = [
        {
            type: UserIdValidationErrorNames.MinMaxLength,
            description: '3-50 characters',
        },
        {
            type: UserIdValidationErrorNames.LackNonNumber,
            description: 'Contain at least one non-numeric character',
        },
        {
            type: UserIdValidationErrorNames.ContainSpaces,
            description: 'Cannot contain spaces',
        },
        {
            type: UserIdValidationErrorNames.LeadingTrailingDot,
            description: 'Cannot begin or end with dot character (.)',
        },
        {
            type: UserIdValidationErrorNames.SpecialChars,
            description:
                'Can contain one of the following special characters ._@-',
        },
        {
            type: UserIdValidationErrorNames.ValueExists,
            description: 'Cannot be used already for another account',
        },
    ];
}
