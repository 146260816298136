import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostBinding,
    Input,
    OnInit,
    inject,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Animations } from '../../../animations';
import { ButtonComponent } from '../../../components';
import { FilteringDirective } from '../filtering.directive';
import { FilteringListComponent } from '../list/list.component';

@UntilDestroy()
@Component({
    selector: 'fi-filtering-panel',
    templateUrl: './panel.component.html',
    styleUrls: ['./panel.component.scss'],
    exportAs: 'fiFilteringPanel',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [Animations.AnimateExpansion],
    standalone: true,
    imports: [FilteringListComponent, ButtonComponent],
})
export class FilteringPanelComponent implements OnInit {
    private changeDetectorRef = inject(ChangeDetectorRef);
    private filtering = inject(FilteringDirective, { optional: true });

    @Input() animationDisabled = false;
    isFilterShow = false;

    @HostBinding('@.disabled')
    disableAnimations = false;

    ngOnInit(): void {
        this.disableAnimations = this.animationDisabled;

        if (!this.filtering) {
            return;
        }

        this.filtering.stateChanged$
            .pipe(untilDestroyed(this))
            .subscribe(() => this.updateState());

        this.updateState();
    }

    handleCancelFilters(): void {
        this.filtering.cancelFilters();
        this.resetFiltersViewState();
    }

    handleApplyFilters(): void {
        const skipClosing = this.filtering.isFilterDefaultOpen;

        this.filtering.applyFilters(skipClosing);
        this.resetFiltersViewState();
    }

    handleResetFilters(): void {
        this.filtering.resetFilters();
        this.resetFiltersViewState();
    }

    captureAnimationStart(): void {
        this.filtering.setPanelAnimatingState(true);
    }

    captureAnimationDone(): void {
        this.filtering.setPanelAnimatingState(false);
    }

    private updateState(): void {
        this.isFilterShow = this.filtering?.isFilterShow;
        this.changeDetectorRef.markForCheck();
    }

    private resetFiltersViewState(): void {
        this.filtering.resetViewState();
    }
}
