import { Component, Input, Output, EventEmitter, Inject } from '@angular/core';
import {
    UntypedFormBuilder,
    Validators,
    AbstractControl,
    ValidationErrors,
    UntypedFormGroup,
    UntypedFormControl,
} from '@angular/forms';

import { Observable } from 'rxjs';
import { finalize, map, take } from 'rxjs/operators';
import { values } from 'lodash';

import {
    ModalVariation,
    ModalAnimationType,
} from '@pnsk/ui-common/feature/modal';
import { ConfigAppToken, ConfigApp } from '@pnsk/ui-common/services';
import { CUSTOMER_NUMBER_LENGTH } from '@pnsk/ui-common/components/input/input.component';
import { EnvironmentService } from '@pnsk/ui-common/services/environment.service';

import { RecoverPasswordController } from '../../scripts/recover-password';
import { ModalController } from '../../scripts/modal-controller';

import { RequestAccessService, UserDetailsDto } from './request-access.service';
import { EmailAccessValidation } from './email-access-validation.model';

const enum FormKey {
    CustomerNumber = 'customerNumber',
    FirstName = 'firstName',
    LastName = 'lastName',
    Phone = 'phone',
    Email = 'email',
    EmailConfirm = 'emailConfirm',
}

@Component({
    selector: 'fi-request-access',
    templateUrl: './request-access.component.html',
    styleUrls: ['./request-access.component.scss'],
})
export class RequestAccessComponent {
    @Input() open: boolean;
    @Output() close = new EventEmitter<void>();

    readonly isMobile$ = this.environmentService.isMobile$;

    readonly modalVariation = [
        ModalVariation.Wide600x,
        ModalVariation.FullHeight,
        ModalVariation.ContentExpanded,
    ];
    readonly modalAnimation = ModalAnimationType.SlideLeft;

    step = 1;
    isRequestSubmitted = false;
    validationInProgress = false;

    form = new UntypedFormGroup({
        [FormKey.CustomerNumber]: new UntypedFormControl(
            '',
            [Validators.required, Validators.minLength(CUSTOMER_NUMBER_LENGTH)],
            this.isCustomerNumberRecognizedValidator.bind(this),
        ),
        [FormKey.FirstName]: new UntypedFormControl('', [Validators.required]),
        [FormKey.LastName]: new UntypedFormControl('', [Validators.required]),
        [FormKey.Phone]: new UntypedFormControl('', [
            Validators.required,
            Validators.minLength(this.CONFIG_APP.PHONE_NUMBER_LENGTH),
        ]),
        [FormKey.Email]: new UntypedFormControl(
            '',
            [
                Validators.required,
                Validators.pattern(this.CONFIG_APP.forms.emailRegex),
            ],
            this.isEmailExistedValidator.bind(this),
        ),
    });

    firstStepFormControls: AbstractControl[] = [
        this.form.get(FormKey.CustomerNumber),
        this.form.get(FormKey.FirstName),
        this.form.get(FormKey.LastName),
    ];

    constructor(
        @Inject(ConfigAppToken) private CONFIG_APP: ConfigApp,
        private environmentService: EnvironmentService,
        private formBuilder: UntypedFormBuilder,
        private requestAccessService: RequestAccessService,
    ) {}

    handleClose(): void {
        this.form.reset();
        this.step = 1;
        this.isRequestSubmitted = false;

        this.close.emit();
    }

    handleGoToNextStep(): void {
        if (this.isFirstStepControlsInvalid()) {
            this.showFirstStepControlsErrorMessages();
            return;
        }

        this.step = 2;
        this.form.updateValueAndValidity();
    }

    goToPreviousStep(): void {
        this.step = 1;
    }

    handleRequestAccess(): void {
        if (this.form.invalid) {
            this.showControlsErrorMessages();
            return;
        }

        this.requestAccessService
            .requestAccess(
                this.form.get(FormKey.CustomerNumber).value,
                this.getUserDetails(),
            )
            .subscribe(() => this.showSuccessMessage());
    }

    isFirstStepActive(): boolean {
        return this.isStepActive(1);
    }

    isSecondStepActive(): boolean {
        return this.isStepActive(2);
    }

    handleResetPassword(): void {
        const resetController = new RecoverPasswordController(true);
        const modalController = new ModalController();
        const email = this.getFormControlValue(FormKey.Email);

        this.handleClose();

        this.requestAccessService
            .resetPassword(email)
            .pipe(take(1))
            .subscribe(() => {
                resetController.handleSuccess(email);
                modalController.manualOpenModal('recover-password-modal');
            });
    }

    private isStepActive(step: number): boolean {
        return !this.isRequestSubmitted && this.step === step;
    }

    private isFirstStepControlsInvalid(): boolean {
        return this.firstStepFormControls.some((control) => control.invalid);
    }

    private showFirstStepControlsErrorMessages(): void {
        this.firstStepFormControls.forEach((control) => {
            control.markAsTouched();
        });
    }

    private showControlsErrorMessages(): void {
        values(this.form.controls).forEach((control) => {
            control.markAsTouched();
        });
    }

    private getUserDetails(): UserDetailsDto {
        return {
            firstName: this.getFormControlValue(FormKey.FirstName),
            lastName: this.getFormControlValue(FormKey.LastName),
            phone: this.getFormControlValue(FormKey.Phone),
            email: this.getFormControlValue(FormKey.Email),
        };
    }

    private getFormControlValue(key: FormKey): string {
        return this.form.get(key).value;
    }

    private showSuccessMessage(): void {
        this.isRequestSubmitted = true;
    }

    private isCustomerNumberRecognizedValidator({
        value,
    }: AbstractControl): Observable<ValidationErrors | null> {
        return this.requestAccessService.checkCustomerNumber(value).pipe(
            map((isRecognized) => {
                return isRecognized ? null : { notRecognized: true };
            }),
        );
    }

    private isEmailExistedValidator({
        value,
    }: AbstractControl): Observable<ValidationErrors | null> {
        if (this.form.get(FormKey.Email).invalid) {
            return;
        }

        this.validationInProgress = true;

        return this.requestAccessService.checkEmail(value).pipe(
            map(({ response }) => {
                return response.toLowerCase() ===
                    EmailAccessValidation.activeWithOneUserId.toLowerCase()
                    ? { userExisted: true }
                    : null;
            }),
            finalize(() => (this.validationInProgress = false)),
        );
    }
}
