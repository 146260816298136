<div #rangeDropdownToggle class="data-range-dropdown-controls">
    <ng-container
        [ngTemplateOutlet]="dropdownButton"
        [ngTemplateOutletContext]="{
            date:
                dateRange?.from?.format(dateFormat) ||
                tempDateRange?.from?.format(dateFormat),
            withCaret: true
        }"
    ></ng-container>

    @if (!singleDateMode) {
        <ng-container
            [ngTemplateOutlet]="dropdownButton"
            [ngTemplateOutletContext]="{
                date:
                    dateRange?.to?.format(dateFormat) ||
                    tempDateRange?.to?.format(dateFormat)
            }"
        ></ng-container>
    }

</div>

<fi-simple-dropdown
    *fiIsDesktopDevice
    [(open)]="isOpen"
    [relativeTo]="rangeDropdownButton"
    [preventClickOnRelative]="true"
>
    <ng-template>
        <div class="range-dropdown-calendar">
            <fi-range-calendar
                [dateRange]="dateRange"
                [availableDateRange]="availableDateRange"
                [calendarView]="true"
                [singleDateMode]="singleDateMode"
                [currentMonthPosition]="currentMonthPosition"
                (dateRangeChanged)="onDateRangeChanged($event)"
            ></fi-range-calendar>

            <div class="range-dropdown-calendar__footer">
                <fi-button
                    [ariaLabel]="'Cancel'"
                    [variation]="'secondary'"
                    (click)="closeCalendar()"
                >
                    Cancel
                </fi-button>

                <fi-button
                    [ariaLabel]="'Select Date'"
                    [variation]="'primary'"
                    (click)="onDatesSelect()"
                >
                    Select Date
                </fi-button>
            </div>
        </div>
    </ng-template>
</fi-simple-dropdown>

<fi-modal
    *fiIsMobileDevice
    [open]="isOpen"
    [variation]="modalVariation"
    class="range-calendar-modal"
>
    @if (isOpen) {
        <fi-modal-close-bar
            *fiIsMobileDevice
            [closeIcon]="'times'"
            [title]="'Select Date'"
            (close)="closeCalendar()"
        ></fi-modal-close-bar>

        <fi-modal-content class="range-calendar-modal__calendar">
            <fi-range-calendar
                [dateRange]="dateRange"
                [availableDateRange]="availableDateRange"
                [calendarView]="true"
                [singleDateMode]="singleDateMode"
                (dateRangeChanged)="onDateRangeChanged($event)"
            ></fi-range-calendar>
        </fi-modal-content>

        <fi-modal-footer>
            <div class="range-calendar-modal__footer">
                <div class="range-calendar-modal__footer-actions">
                    <fi-button
                        [ariaLabel]="'Select Dates'"
                        [variation]="'primary,big,block'"
                        (click)="onDatesSelect()"
                        class="range-calendar-modal__select-btn"
                    >
                        Select Dates
                    </fi-button>

                    <fi-button
                        [ariaLabel]="'Cancel'"
                        [variation]="'secondary,big,block'"
                        (click)="closeCalendar()"
                    >
                        Cancel
                    </fi-button>
                </div>
            </div>
        </fi-modal-footer>
    }
</fi-modal>

<ng-template #dropdownButton let-date="date" let-withCaret="withCaret">
    <fi-button
        [variation]="'secondary,no-horizontal-padding,block,full-width-content'"
        [active]="isOpen"
        (click)="toggleDropdown()"
        class="data-range-dropdown-btn"
    >
        <div class="data-range-dropdown-btn__content fi-text fi-text--medium">
            @if (date) {
                <ng-container
                    *ngTemplateOutlet="
                        dateTpl;
                        context: {
                            date: date
                        }
                    "
                ></ng-container>
            } @else {
                <fi-icon
                    [svgName]="'clock'"
                    [size]="'medium'"
                    class="data-range-dropdown-btn__icon"
                ></fi-icon>
                None set
            }

            @if (withCaret) {
                <fi-icon
                    [svgName]="isOpen ? 'caret-up' : 'caret-down'"
                    [variation]="'solid'"
                    [size]="'large'"
                    class="data-range-dropdown-btn__caret"
                ></fi-icon>
            }
        </div>
    </fi-button>
</ng-template>

<ng-template #dateTpl let-date="date">
    <fi-icon
        [svgName]="'calendar-alt'"
        [size]="'medium'"
        class="data-range-dropdown-btn__icon"
    ></fi-icon>
    <span class="data-range-dropdown-btn__date">Data for {{ date }}</span>
</ng-template>
