import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    ElementRef,
    ViewChild,
    OnDestroy,
    HostBinding,
} from '@angular/core';

@Component({
    selector: 'fi-infinite-scroll',
    template: `
        <ng-content></ng-content>
        <div #anchor class="fi-infinite-scroll__anchor"></div>
    `,
    styleUrls: ['./infinite-scroll.component.scss'],
    standalone: true,
})
export class InfiniteScrollComponent implements OnInit, OnDestroy {
    @Output() scrolled = new EventEmitter();
    @ViewChild('anchor', { static: true }) anchor: ElementRef<HTMLElement>;

    @HostBinding('attr.tabindex') tabindex = 0;

    // For IE add IntersectionObserver polyfill in the app that uses common module
    // https://github.com/w3c/IntersectionObserver/tree/master/polyfill
    private observer: IntersectionObserver;

    constructor(private host: ElementRef) {}

    get element(): Element {
        return this.host.nativeElement;
    }

    ngOnInit(): void {
        const options = {
            root: this.isHostScrollable() ? this.host.nativeElement : null,
        };

        this.observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                this.scrolled.emit();
            }
        }, options);

        this.observer.observe(this.anchor.nativeElement);
    }

    ngOnDestroy(): void {
        this.observer.disconnect();
    }

    private isHostScrollable(): boolean {
        const style = window.getComputedStyle(this.element);

        return (
            style.getPropertyValue('overflow') === 'auto' ||
            style.getPropertyValue('overflow-y') === 'scroll'
        );
    }
}
