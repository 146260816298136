<ul
    [fiVariation]="
        (variation || '') +
        (withCheck ? ',with-check' : '') +
        (codeShownAsSecondary ? ',code-secondary-shown' : '') +
        (labelShownAsSecondary ? ',label-secondary-shown' : '') +
        (labelShowInfo ? ',label-info-shown' : '')
    "
    [fiVariationBase]="'fi-selectable-list'"
>
    @if (virtualScroll) {
        <cdk-virtual-scroll-viewport
            itemSize="50"
            class="fi-selectable-list__viewport"
        >
            <ng-container
                *cdkVirtualFor="let item of items"
            >
                <li
                    [class.fi-selectable-list__item--selected]="
                        [item, selected] | memoizeFunc: isItemSelected : this
                    "
                    (click)="withCheck && handleClick(item)"
                    class="fi-selectable-list__item"
                >
                    <ng-container
                        *ngTemplateOutlet="
                            itemDetailsTpl;
                            context: { item: item }
                        "
                    ></ng-container>
                </li>
            </ng-container>
        </cdk-virtual-scroll-viewport>
    } @else {
        <ng-container>
            @for (item of items; track item.code) {
                <li
                    #groupItem
                    [class.fi-selectable-list__item--selected]="
                        [item, selected] | memoizeFunc: isItemSelected : this
                    "
                    [id]="item | memoizeFunc: getItemId"
                    (click)="withCheck && handleClick(item)"
                    class="fi-selectable-list__item"
                >
                    <ng-container
                        *ngTemplateOutlet="
                            itemDetailsTpl;
                            context: { item: item }
                        "
                    ></ng-container>
                </li>
            }
        </ng-container>
    }
</ul>

<ng-template #itemDetailsTpl let-item="item">
    <!-- TODO: refactor withCheck input for using in control -->
    <div class="fi-selectable-list__item-content">
        @if (!withoutSelection) {
            @if (multiple) {
                <fi-checkbox
                    [checked]="
                        [item, selected] | memoizeFunc: isItemSelected : this
                    "
                    [disabled]="item.disabled"
                    (change)="!item.disabled && !withCheck && handleClick(item)"
                ></fi-checkbox>
            }

            @if (!multiple && !withCheck && !hideSelection) {
                <fi-radio
                    [checked]="
                        [item, selected] | memoizeFunc: isItemSelected : this
                    "
                    [disabled]="item.disabled"
                    (change)="!item.disabled && handleClick(item)"
                ></fi-radio>
            }
        }

        @if (!labelShowInfo && !withHighlight) {
            <fi-label
                [variation]="getLabelVariation(item)"
                (click)="!item.disabled && !withCheck && handleClick(item)"
                class="fi-selectable-list__label"
            >
                {{ item.description }}
            </fi-label>
        }

        @if (!labelShowInfo && withHighlight) {
            <fi-label
                [variation]="getLabelVariation(item)"
                (click)="!item.disabled && !withCheck && handleClick(item)"
                class="fi-selectable-list__label fi-selectable-list__label--with-highlight"
            >
                <fi-highlight-text
                    [fullText]="item.description"
                    [highlightQuery]="highlightValue"
                ></fi-highlight-text>
            </fi-label>
        }

        @if (labelShowInfo) {
            <fi-label
                [variation]="getLabelVariation(item)"
                (click)="!item.disabled && !withCheck && handleClick(item)"
                class="fi-selectable-list__label"
            >
                <div class="fi-selectable-list__description">
                    <span class="fi-text fi-text--medium fi-text--dark">
                        {{ item.description }}
                    </span>
                    <span
                        [class.fi-text--medium]="isMobile$ | async"
                        class="fi-text fi-text--small fi-selectable-list__info"
                    >
                        {{ item.info }}
                    </span>
                </div>
            </fi-label>
        }

        @if ((isMobile$ | async) && labelShownAsSecondary) {
            <span
                [ngClass]="{
                    'fi-label--dark-blue':
                        ([item, selected]
                            | memoizeFunc: isItemSelected : this) &&
                        !labelShowInfo,
                    'fi-text--medium': labelShowInfo,
                }"
                class="fi-text fi-selectable-list__label-as-secondary"
            >
                {{ item.label }}
            </span>
        }
    </div>

    @if (codeShownAsSecondary) {
        <div>
            <span class="fi-text fi-text--light">{{ item.code }}</span>
        </div>
    }

    @if ((isMobile$ | async) === false && labelShownAsSecondary) {
        <span
            [class.fi-selectable-list__secondary-label--disabled]="
                item | memoizeFunc: hasAccess
            "
            class="fi-text fi-selectable-list__secondary-label"
        >
            {{ item.label }}
        </span>
    }

    @if (templateShownAsSecondary) {
        <ng-container
            [ngTemplateOutlet]="templateShownAsSecondary"
            [ngTemplateOutletContext]="{ data: item }"
        ></ng-container>
    }

    @if (!multiple && withCheck && !hideSelection) {
        <fi-icon
            [svgName]="'check'"
            [variation]="'regular'"
            class="fi-selectable-list__check-icon"
        ></fi-icon>
    }

    @if (item.hasAlertIcon) {
        <fi-alert-icon class="fi-selectable-list__alert-icon"></fi-alert-icon>
    }
</ng-template>
