<!-- TODO: remove -a when old component will be removed -->
<div class="fi-date-range-tabs-a">
    <div
        [class.fi-date-range-tabs-a__item--highlighted]="highlighted === 'from'"
        [class.fi-date-range-tabs-a__item--clickable]="!!tabs?.from"
        (click)="changeTab('from')"
        class="fi-date-range-tabs-a__item"
    >
        <span class="fi-text fi-text--medium fi-text--small">
            {{ fromTitle }}:
        </span>

        <span
            [ngClass]="tabs?.from ? 'fi-text--dark' : 'fi-text--light'"
            class="fi-text fi-text--medium"
        >
            {{ tabs.from | timeFormat: 'SHORT_DATE' : valuePlaceholder }}
        </span>
    </div>

    <div
        [class.fi-date-range-tabs-a__item--clickable]="!!tabs?.from"
        (click)="changeTab('to')"
        class="fi-date-range-tabs-a__item"
    >
        <span class="fi-text fi-text--medium fi-text--small">
            {{ toTitle }}:
        </span>

        <span
            [ngClass]="tabs?.to ? 'fi-text--dark' : 'fi-text--light'"
            class="fi-text fi-text--medium"
        >
            {{ tabs.to | timeFormat: 'SHORT_DATE' : valuePlaceholder }}
        </span>
    </div>

    <div
        [class.fi-date-range-tabs-a__active-indicator--on-right]="
            active === 'to'
        "
        class="fi-date-range-tabs-a__active-indicator"
    ></div>
</div>
