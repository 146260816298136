import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import { EmailAccessValidation } from './email-access-validation.model';

export interface CustomerDto {
    customerNumber?: string;
    name?: string;
    description?: string;
}

export interface CustomerDetailsDto {
    customer?: CustomerDto;
    unitsCount?: number;
}

export interface UserDetailsDto {
    companyId?: number;
    companyName?: string;
    customerList?: CustomerDetailsDto[];
    email?: string;
    firstName?: string;
    fullUserName?: string;
    lastName?: string;
    phone?: string;
    userId?: string;
}

@Injectable()
export class RequestAccessService {
    private currentlyCheckingCustomerNumber: string | null = null;
    private currentlyCheckingCustomerRequest: Observable<boolean> | null = null;

    constructor(private http: HttpClient) {}

    checkCustomerNumber(customerNumber: string): Observable<boolean> {
        if (this.isSameCustomerNumberRequestInProgress(customerNumber)) {
            return this.currentlyCheckingCustomerRequest;
        }

        this.currentlyCheckingCustomerNumber = customerNumber;
        this.currentlyCheckingCustomerRequest = this.http
            .get<{ message: boolean }>(
                '{api_v1}/public/check/customer-number',
                {
                    params: { customerNumber },
                },
            )
            .pipe(
                map(({ message }) => {
                    return message;
                }),
                tap(() => {
                    this.currentlyCheckingCustomerNumber = null;
                    this.currentlyCheckingCustomerRequest = null;
                }),
            );

        return this.currentlyCheckingCustomerRequest;
    }

    checkEmail(email: string): Observable<{ response: EmailAccessValidation }> {
        return this.http.get<{ response: EmailAccessValidation }>(
            '{api_v1}/public/email/validate',
            {
                params: { email },
            },
        );
    }

    resetPassword(email: string): Observable<{ email: string }> {
        return this.http.post<{ email: string }>(
            `{api_v1}/public/${email}/resetPassword`,
            {},
        );
    }

    requestAccess(
        customerNumber: string,
        body: UserDetailsDto,
    ): Observable<void> {
        return this.http.post<void>(
            `{api_v1}/public/accessRequest/${customerNumber}`,
            body,
        );
    }

    private isSameCustomerNumberRequestInProgress(
        customerNumber: string,
    ): boolean {
        return (
            customerNumber === this.currentlyCheckingCustomerNumber &&
            !!this.currentlyCheckingCustomerRequest
        );
    }
}
