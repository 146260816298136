import {
    FlexibleConnectedPositionStrategy,
    OverlayConfig,
} from '@angular/cdk/overlay';

import { flatten } from 'lodash';

import { variationClassBuilder } from '../utils';

import { BaseOverlay } from './base-overlay';
import { ConnectedOverlayParams } from './params';
import { BASE_OVERLAY_CLASS } from './css-classes';
import {
    CONNECTED_OVERLAY_POSITIONS,
    OverlayConnectedPosition,
} from './position';

export class ConnectedOverlay<T, K> extends BaseOverlay<
    T,
    ConnectedOverlayParams,
    K
> {
    protected resolveConfig(): OverlayConfig {
        const {
            positions,
            scrollStrategy,
            variation = [],
            disposeOnNavigation = true,
            ...other
        } = this.params;

        const config = {
            ...other,
            disposeOnNavigation,
            panelClass: [
                BASE_OVERLAY_CLASS,
                ...variationClassBuilder(BASE_OVERLAY_CLASS, [
                    ...flatten([variation]),
                    'connected-position',
                ]),
            ],
            positionStrategy: this.getPosition(
                this.origin,
                flatten([positions]),
            ),
            scrollStrategy: this.getScrollStrategy(scrollStrategy),
        };

        return new OverlayConfig({
            ...config,
        });
    }

    protected getPosition(
        origin: K,
        positions: OverlayConnectedPosition[] = [],
    ): FlexibleConnectedPositionStrategy {
        const connectedPositions = positions
            .map(
                (positionType) =>
                    CONNECTED_OVERLAY_POSITIONS[positionType] || null,
            )
            .filter((position) => !!position);

        if (!connectedPositions.length) {
            connectedPositions.push(CONNECTED_OVERLAY_POSITIONS.top);
        }

        return this.overlayBuilder
            .position()
            .flexibleConnectedTo(origin as any)
            .withPositions(connectedPositions)
            .withPush(false);
    }
}
