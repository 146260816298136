import {
    Directive,
    OnChanges,
    OnDestroy,
    HostListener,
    Output,
    EventEmitter,
    Input,
    OnInit,
    HostBinding,
} from '@angular/core';

import { Subject } from 'rxjs';

import { OnChange } from '../../../decorators';

@Directive({
    selector: '[fiGridExpand]',
    exportAs: 'fiGridExpand',
    standalone: true,
})
export class GridExpandDirective implements OnChanges, OnDestroy, OnInit {
    @OnChange<boolean>(function (this: GridExpandDirective, value) {
        if (value) {
            this.state = false;
            this.stateChanged.next();
        }
    })
    @Input('fiGridExpand')
    gridReloading: boolean;
    @OnChange('changeExpandState')
    @Input()
    openByDefault: boolean;

    @Input()
    isRowExpanded: boolean;

    @Output() readonly fiGridExpandChange = new EventEmitter<boolean>();

    @HostBinding('attr.tabindex') tabindex = 0;

    readonly stateChanged = new Subject<void>();

    state: boolean;

    @HostListener('click', ['$event'])
    @HostListener('keydown.enter', ['$event'])
    handleClick(): void {
        this.expand();
    }

    ngOnInit(): void {
        if (this.isRowExpanded) {
            this.expand();
        }
    }

    expand(): void {
        this.state = !this.state;
        this.stateChanged.next();

        this.fiGridExpandChange.emit(this.state);
    }

    changeExpandState(): void {
        if (this.openByDefault) {
            this.state = this.openByDefault;
            this.stateChanged.next();
            if (this.state) {
                this.fiGridExpandChange.emit(true);
            }
        }
    }

    ngOnChanges(): void {
        this.stateChanged.next();
    }

    ngOnDestroy(): void {
        this.stateChanged.complete();
    }
}
