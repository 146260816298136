import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostListener,
    Input,
    Output,
    ElementRef,
    ViewChild,
} from '@angular/core';
import { AsyncPipe } from '@angular/common';

import { asyncScheduler, Observable, of } from 'rxjs';
import { map, observeOn } from 'rxjs/operators';

import { IconComponent } from '../../components';
import { VariationDirective } from '../../directives';
import { MemoizeFuncPipe } from '../../pipes';
import { TooltipDirective } from '../tooltip';

@Component({
    selector: 'fi-pill',
    templateUrl: './pill.component.html',
    styleUrls: ['./pill.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AsyncPipe,
        VariationDirective,
        TooltipDirective,
        IconComponent,
        MemoizeFuncPipe,
    ],
})
export class PillComponent {
    @ViewChild('pillTitle', { static: true }) pillTitle: ElementRef;

    @Input() variation: string;
    @Input() readonly = false;
    @Input() tooltipValue: string;
    @Input() tooltipAlwaysVisible: boolean;
    @Input() timesClickEnable: boolean;

    @Output() pillClick = new EventEmitter<Event>();
    @Output() timesClick = new EventEmitter<void>();

    @HostListener('click', ['$event'])
    hostClick(event: Event): void {
        if (this.readonly) {
            event.stopPropagation();
            return;
        }

        this.pillClick.emit(event);
    }

    hasEllipsis(): Observable<boolean> {
        return of(null).pipe(
            observeOn(asyncScheduler),
            map(() => {
                if (this.tooltipAlwaysVisible) {
                    return true;
                }

                const { offsetWidth, scrollWidth } =
                    this.pillTitle.nativeElement;

                return offsetWidth < scrollWidth;
            }),
        );
    }

    handleTimesClick(event: Event): void {
        if (this.timesClickEnable) {
            event.stopPropagation();
            this.timesClick.emit();
        }
    }
}
