<div class="fi-time-picker-input__section">
    <div
        (click)="handleIncrease()"
        role="button"
        aria-label="Increase"
        class="fi-time-picker-input__scroller">
        <fi-icon
            [svgName]="'caret-up'"
            [variation]="'solid'"
            [size]="size"
            class="fi-time-picker-input__scroller-icon"
        ></fi-icon>
    </div>

    <div class="fi-time-picker-input__text fi-text--medium fi-text--large">
        {{ value }}
    </div>

    <div
        (click)="handleDecrease()"
        role="button"
        aria-label="Decrease"
        class="
            fi-time-picker-input__scroller
            fi-time-picker-input__scroller--decrease
        "
    >
        <fi-icon
            [svgName]="'caret-up'"
            [variation]="'solid'"
            [size]="size"
            class="
                fi-time-picker-input__scroller-icon
                fi-time-picker-input__scroller-icon--decrease
            "
        ></fi-icon>
    </div>
</div>
