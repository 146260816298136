import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { isEqual } from 'lodash';

export type TableHorizontalScrollBreakpoints = {
    index: number;
    points: number[];
};

@Injectable()
export class GridTableScrollService {
    readonly breakpoints =
        new BehaviorSubject<TableHorizontalScrollBreakpoints>({
            index: 0,
            points: [],
        });

    breakPoints$ = this.breakpoints.asObservable();

    setBreakPoints(breakpoints: { index?: number; points?: number[] }): void {
        if (isEqual(breakpoints, this.breakpoints.value)) {
            return;
        }

        this.breakpoints.next({
            ...this.breakpoints.value,
            ...breakpoints,
        });

        this.scrollColumns();
    }

    scrollColumns(diff = 0): void {
        const { index, points } = this.breakpoints.value;

        const newIndex = Math.max(Math.min(index + diff, points.length - 1), 0);

        this.breakpoints.next({ index: newIndex, points });
    }
}
