import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';

import {
    NavTabsComponent,
    NavTabsItemComponent,
    SkeletonComponent,
    SkeletonElementComponent,
} from '../../../components';
import { OnChange } from '../../../decorators';
import { MemoizeFuncPipe, PluralizePipe, ToNumArray } from '../../../pipes';
import { FleetMetricsUnitDto } from '../../metrics/models';
import { METRIC_TABS_AND_INTERFACE_MAP } from '../constants';
import { FleetProfileMetricsTabs } from '../enums';
import { MetricsTabComponent } from '../metrics-tab';

@Component({
    selector: 'fi-fleet-profile-metrics-tabs',
    templateUrl: './fleet-profile-tabs.component.html',
    styleUrls: ['./fleet-profile-tabs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgTemplateOutlet,
        SkeletonComponent,
        SkeletonElementComponent,
        NavTabsComponent,
        NavTabsItemComponent,
        MemoizeFuncPipe,
        ToNumArray,
        PluralizePipe,
        MetricsTabComponent,
    ],
})
export class FleetProfileMetricsTabsComponent {
    @Input() isInitializing: boolean;
    @Input() metrics: FleetMetricsUnitDto;

    @OnChange('handleActiveTab')
    @Input()
    activeTab: FleetProfileMetricsTabs;

    @Output() onClick = new EventEmitter<FleetProfileMetricsTabs>();
    @Output() enter = new EventEmitter<FleetProfileMetricsTabs>();
    @Output() leave = new EventEmitter<void>();

    readonly leaseTab = FleetProfileMetricsTabs.Lease;
    readonly contractMaintenanceTab =
        FleetProfileMetricsTabs.ContractMaintenance;
    readonly rentalTab = FleetProfileMetricsTabs.Rental;

    activeMetricTab: FleetProfileMetricsTabs;
    isActiveTabIndicatorReset = false;
    isCommercialCompany: boolean;

    getTabsVariation(): string {
        switch (this.activeMetricTab) {
            case FleetProfileMetricsTabs.Lease:
                return 'dark-blue-indicator';
            case FleetProfileMetricsTabs.ContractMaintenance:
                return 'bright-blue-indicator';
            case FleetProfileMetricsTabs.Rental:
                return 'yellow-indicator';
        }
    }

    isMetricChartTabVisible([tab, metric]: [
        FleetProfileMetricsTabs,
        FleetMetricsUnitDto,
    ]): boolean {
        if (!metric) {
            return;
        }
        const shouldDisplayTabForCommercialCompany = [
            FleetProfileMetricsTabs.Lease,
            FleetProfileMetricsTabs.ContractMaintenance,
        ].includes(tab)
            ? !this.isCommercialCompany
            : true;

        return (
            metric[METRIC_TABS_AND_INTERFACE_MAP[tab]] > 0 ||
            (!metric.leaseUnits &&
                !metric.contractMaintenanceUnits &&
                !metric.rentalUnits &&
                shouldDisplayTabForCommercialCompany)
        );
    }

    isActiveMetric([tab]: [FleetProfileMetricsTabs]): boolean {
        return tab === this.activeMetricTab;
    }

    setDefaultMetric(): void {
        this.activeMetricTab = null;
        this.leave.emit();
    }

    setActiveMetric(tab: FleetProfileMetricsTabs): void {
        this.activeMetricTab = tab;
        this.isActiveTabIndicatorReset = false;
        this.enter.emit(tab);
    }

    handleClick(tab: FleetProfileMetricsTabs): void {
        this.onClick.emit(tab);
    }

    private handleActiveTab(): void {
        this.activeMetricTab = this.activeTab;
        this.isActiveTabIndicatorReset = !this.activeTab;
    }
}
