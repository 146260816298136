import {
    ContentChild,
    Directive,
    Input,
    TemplateRef,
    Output,
    EventEmitter,
} from '@angular/core';

import { AccordionItemHeaderDirective } from './header/header.directive';
import { AccordionItemContentDirective } from './content/content.directive';
import { AccordionItemCustomHeaderDirective } from './header/custom-header.directive';

export interface AccordionGuidedTourOptions {
    step: number;
    title: string;
    text: string;
    topMargin?: number;
    tourId?: string;
    className?: string;
}

@Directive({
    selector: '[fiAccordionItem]',
    exportAs: 'fiAccordionItem',
    standalone: true,
})
export class AccordionItemDirective {
    @Input() expanded: boolean = null;
    @Input() defaultExpanded: boolean;
    // Should be always boolean if it's passed, it's used for removing of leave-modal
    @Input() hasChanges: boolean = null;
    // The unique AccordionItem id
    @Input() id = '';
    @Input() disabled: boolean;
    @Input() guidedTourItem: AccordionGuidedTourOptions;
    @Input() guidedTourHeader: AccordionGuidedTourOptions;
    @Input() guidedTourContent: AccordionGuidedTourOptions;

    @Output() expandedChange = new EventEmitter<boolean>();

    @ContentChild(AccordionItemHeaderDirective, {
        read: TemplateRef,
    })
    header: TemplateRef<AccordionItemHeaderDirective>;

    @ContentChild(AccordionItemCustomHeaderDirective, {
        read: TemplateRef,
    })
    customHeader: TemplateRef<AccordionItemCustomHeaderDirective>;

    @ContentChild(AccordionItemContentDirective, {
        read: TemplateRef,
    })
    content: TemplateRef<AccordionItemContentDirective>;
}
