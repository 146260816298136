import { NgModule, Type } from '@angular/core';

import { DraggableListComponent } from './draggable-list.component';
import { DraggableListItemDirective } from './item/item.directive';

const FEATURES: (Type<any> | any[])[] = [
    DraggableListComponent,
    DraggableListItemDirective,
];

@NgModule({
    imports: [...FEATURES],
    exports: FEATURES,
})
export class DraggableListModule {}
