import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    OnDestroy,
    Input,
    Output,
    TemplateRef,
    ViewChild,
    ViewEncapsulation,
    OnInit,
    ChangeDetectorRef,
} from '@angular/core';
import { AsyncPipe } from '@angular/common';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { DownloadFileService, EnvironmentService } from '../../../services';
import { FiIconVariation } from '../../../components/icon';
import { ButtonComponent, IconComponent } from '../../../components';
import {
    ModalComponent,
    ModalHeaderComponent,
    ModalContentComponent,
    ModalFooterComponent,
} from '../../../feature/modal';
import { TooltipDirective } from '../../../feature/tooltip';
import { GridMoreActionsService } from '../services/more-actions.service';

@UntilDestroy()
@Component({
    selector: 'fi-grid-download-report',
    templateUrl: './download-report.component.html',
    styleUrls: ['./download-report.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        AsyncPipe,
        ButtonComponent,
        IconComponent,
        TooltipDirective,
        ModalComponent,
        ModalHeaderComponent,
        ModalContentComponent,
        ModalFooterComponent,
    ],
})
export class GridDownloadReportComponent implements OnInit, OnDestroy {
    @Input() tooltipValue: string;
    @Input() iconVariation = FiIconVariation.Light;
    @Input() buttonVariation = 'narrow,secondary-icon';

    @Output() downloadReport: EventEmitter<void> = new EventEmitter();
    @Output() closeDownloadReport: EventEmitter<void> = new EventEmitter();

    @ViewChild('modalWindow', { read: TemplateRef })
    modalWindow: TemplateRef<any>;

    readonly isMobileOS = this.environmentService.isMobileOS;
    readonly downloadInProgress$: Observable<boolean> =
        this.downloadFileService.inProgress$;

    isModalOpen = false;

    constructor(
        private downloadFileService: DownloadFileService,
        private moreActionsService: GridMoreActionsService,
        private changeDetectorRef: ChangeDetectorRef,
        private environmentService: EnvironmentService,
    ) {}

    ngOnInit(): void {
        this.handleSubscriptions();
    }

    ngOnDestroy(): void {
        this.closeModal();
    }

    openModal(): void {
        this.isModalOpen = true;
    }

    closeModal(): void {
        this.isModalOpen = false;
        this.closeDownloadReport.emit();
        this.moreActionsService.closeDownloadModal();
    }

    handleDownloadReport(): void {
        this.downloadReport.emit();
    }

    private handleSubscriptions(): void {
        this.downloadFileService.inProgress$
            .pipe(
                filter((inProgress) => !inProgress),
                untilDestroyed(this),
            )
            .subscribe(() => {
                this.closeModal();
            });

        this.moreActionsService.isOpenedDownloadModal$
            .pipe(
                filter((isOpened) => isOpened),
                untilDestroyed(this),
            )
            .subscribe(() => {
                this.openModal();
                this.changeDetectorRef.markForCheck();
            });
    }
}
