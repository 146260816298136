import { environment } from '@environments/environment';

import { USERID_NOT_FOUND, USER_ID_ERROR_CODE } from './common';
import { RecoverUserInfoAbstractController } from './recover-user-info.abstract.controller';

const EMPTY_ERROR_MESSAGE = 'Please enter username';
const NOT_FOUND_ERROR_MESSAGE =
    'We do not recognize this username. Please try again or contact us at <a href="tel:8444264555">(844) 426-4555</a>.';

export class RecoverPasswordController extends RecoverUserInfoAbstractController {
    private modalElement = document.getElementById(
        'recover-password-modal',
    ) as HTMLElement;

    private usernameInputElement = document.getElementById(
        'recover-username-input',
    ) as HTMLInputElement;
    private errorElement = document.getElementById(
        'recover-username-error',
    ) as HTMLInputElement;
    private responseElement = document.getElementById(
        'recover-password-response',
    ) as HTMLInputElement;
    private sendBtn = document.getElementById('recover-password-send-btn');

    constructor(isSilent = false) {
        super();

        if (!isSilent) {
            this.handleCloseModal();
            this.attachEvents();
        }
    }

    private attachEvents(): void {
        this.usernameInputElement.addEventListener('input', (event) =>
            this.hideInputError(event.target as HTMLInputElement),
        );

        this.sendBtn.addEventListener('click', () => {
            const isFormValid = this.validateForm();

            if (isFormValid) {
                const { value } = this.usernameInputElement;

                const requestUrl = `${environment.externalConfig.PUBLIC_API_VERSION}us/en/public/${value}/resetPassword`;

                this.sendRequest(requestUrl, this.sendCallback.bind(this));
            }
        });
    }

    clearForm(): void {
        this.usernameInputElement.value = '';
        this.hideInputError(this.usernameInputElement);
        this.hideModalSuccess(this.modalElement);
    }

    private validateForm(): boolean {
        const isEmptyInput = this.handleEmptyInputState(
            this.usernameInputElement,
        );

        return !isEmptyInput;
    }

    private handleEmptyInputState(element: HTMLInputElement): boolean {
        const isEmpty = this.isInputEmpty(element);

        if (isEmpty) {
            this.showMessage(this.errorElement, EMPTY_ERROR_MESSAGE);
            this.showInputError(element);
        } else {
            this.hideInputError(element);
        }

        return isEmpty;
    }

    private sendCallback(request: XMLHttpRequest): void {
        const { responseText, status, readyState } = request;

        if (!responseText) {
            return;
        }

        const data = JSON.parse(responseText);

        if (readyState === 4 && status === 200) {
            this.handleSuccess(data.email);
        }

        if (
            status === 400 &&
            (data.code === USERID_NOT_FOUND || data.code === USER_ID_ERROR_CODE)
        ) {
            this.handleError();
        }
    }

    handleSuccess(responseEmail: string): void {
        this.showModalSuccess(this.modalElement);
        this.showMessage(this.responseElement, responseEmail);
    }

    handleError(): void {
        this.showMessage(this.errorElement, NOT_FOUND_ERROR_MESSAGE);
        this.showInputError(this.usernameInputElement);
    }
}
