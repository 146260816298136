import { Directive, Input, OnChanges } from '@angular/core';
import { NgClass } from '@angular/common';

import { isString, isArray, isPlainObject, flattenDeep } from 'lodash';

@Directive({
    selector: '[fiVariation]',
    providers: [NgClass],
    standalone: true,
})
export class VariationDirective extends NgClass implements OnChanges {
    @Input() fiVariation: string | any[];
    @Input() fiVariationBase: string;

    get variation(): string[] {
        if (isString(this.fiVariation)) {
            return this.fiVariation.split(',');
        }

        if (isArray(this.fiVariation)) {
            return this.fiVariation;
        }

        return [];
    }

    ngOnChanges(): void {
        const baseClass = this.fiVariationBase;

        this.ngClass = baseClass
            ? [
                  baseClass,
                  ...this.parseVariationsList(this.variation, baseClass),
              ]
            : [];
    }

    private parseVariationsList(
        variations: any[],
        baseClass: string,
    ): string[] {
        const variationBuff = variations.map(this.parseVariation);

        return flattenDeep(variationBuff).map(
            (variation) => `${baseClass}--${variation}`,
        );
    }

    private parseVariation(variation: any): string[] {
        if (isString(variation) && variation.length) {
            return [variation];
        }

        if (isPlainObject(variation)) {
            return Object.keys(variation)
                .map((key) => [key, variation[key]])
                .filter(([, value]) => !!value)
                .map(([key]) => key.toString());
        }

        return [];
    }
}
