import { AnimationMetadata, query, style, animate } from '@angular/animations';

import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';

type AnimationTransition = 'open' | 'close';
type AnimationStyle =
    | '*'
    | {
          [key: string]: string;
      };

const TOAST_CLASS_NAME = '.toast';
const ANIMATION_TIMINGS = '600ms ease-out';

function getAnimationStyle(isOpen: boolean, isMobile: boolean): AnimationStyle {
    const transform = isMobile ? 'translateY(-120%)' : 'translateX(100%)';

    return isOpen ? { transform } : '*';
}

function getAnimationMetadata(
    transition: AnimationTransition,
    isMobile: boolean,
): AnimationMetadata[] {
    const isOpen = transition === 'open';
    const from = getAnimationStyle(isOpen, isMobile);
    const to = getAnimationStyle(!isOpen, isMobile);

    return [
        query(TOAST_CLASS_NAME, [
            style(from),
            animate(ANIMATION_TIMINGS, style(to)),
        ]),
    ];
}

export function getAnimationMetadata$(
    transition: AnimationTransition,
    isMobile$: Observable<boolean>,
): Observable<AnimationMetadata[]> {
    return isMobile$.pipe(
        take(1),
        map((isMobile) => getAnimationMetadata(transition, isMobile)),
    );
}
