<div #contentRef class="fi-skeleton-element-container">
    <ng-content></ng-content>
</div>

@if (!content?.nativeElement?.childNodes?.length) {
    <div
        [fiVariation]="variant"
        [fiVariationBase]="'fi-skeleton-element'"
    ></div>
}
