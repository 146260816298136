import { Directive } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { BehaviorSubject } from 'rxjs';

import { EnvironmentService } from '../../../services';

@UntilDestroy()
@Directive({
    selector: '[fiGridOnTablet]',
    exportAs: 'fiGridOnTablet',
    standalone: true,
})
export class GridOnTabletDirective {
    private readonly isTablet$ = new BehaviorSubject<boolean>(false);

    readonly isGridOnTablet$ = this.isTablet$
        .asObservable()
        .pipe(untilDestroyed(this));

    constructor(private environmentService: EnvironmentService) {
        this.environmentService.isSmallTabletDevice$
            .pipe(untilDestroyed(this))
            .subscribe((isTablet) => {
                this.isTablet$.next(isTablet);
            });
    }
}
