import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'fi-skeleton',
    templateUrl: 'skeleton.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class SkeletonComponent {
    @Input() isInitializing = true;
}
