export const enum OverlayEventType {
    Attach = 'attach',
    Enter = 'enter',
    Leave = 'leave',
    Detach = 'detach',
    OutsideClick = 'outsideClick',
    Escape = 'escape',
}

export class OverlayEvent {
    constructor(public type: OverlayEventType) {}
}
