import {
    BaseAnalyticsEvent,
    TagManagerBaseService,
} from './tag-manager.service';

export abstract class AnalyticsBaseService {
    constructor(protected gtmService: TagManagerBaseService) {}

    trackView(event: BaseAnalyticsEvent): void {
        const eventData = {
            event: 'Pageview',
            ...event,
        };

        this.track(eventData);
    }

    track(event: BaseAnalyticsEvent): void {
        this.gtmService.pushTag(event);
    }
}
