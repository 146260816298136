import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';

import { get, isNumber } from 'lodash';
import {
    distinctUntilChanged,
    filter,
    map,
    pairwise,
    startWith,
} from 'rxjs/operators';

import { BodyUtilService } from '../services/body-util.service';
import { getDeepestRouteSnapshot } from '../utils/get-deepest-route-snapshot';

import { EnvironmentService } from './environment.service';

const SCROLL_CHECK_INTERVAL = 250;
const SCROLL_CHECK_COUNT = 40;
const SCROLL_TO_DELAY = 50;

@Injectable({
    providedIn: 'root',
})
export class BodyService {
    constructor(
        @Inject(DOCUMENT)
        private document: Document,
        private environmentService: EnvironmentService,
        private router: Router,
        private route: ActivatedRoute,
        private bodyUtilService: BodyUtilService,
    ) {}

    get bodyClasses(): string[] {
        return this.bodyUtilService.bodyClasses;
    }

    init(): void {
        const deepestSnapshot = getDeepestRouteSnapshot(this.route.snapshot);
        const initialBodyClasses: string = get(
            deepestSnapshot,
            'data.bodyClass',
            '',
        );
        this.bodyUtilService.addClasses(initialBodyClasses);

        this.router.events
            .pipe(
                filter(
                    (event): event is ActivationEnd =>
                        event instanceof ActivationEnd,
                ),
                map(({ snapshot }) => getDeepestRouteSnapshot(snapshot)),
                map((snapshot) => get(snapshot, 'data.bodyClass', '')),
                distinctUntilChanged(),
                startWith(initialBodyClasses),
                pairwise(),
            )
            .subscribe(([previousBodyClasses, currentBodyClasses]) =>
                this.updateBodyClasses(previousBodyClasses, currentBodyClasses),
            );
    }

    scrollTop(
        yOffset = 0,
        element?: HTMLElement,
        behavior: ScrollBehavior = 'smooth',
    ): Promise<void> {
        const yRoundedOffset = Math.floor(yOffset);
        const scrollOptions = {
            left: 0,
            top: yRoundedOffset,
            behavior,
        };

        if (this.environmentService.hasSmoothScroll) {
            setTimeout(() => {
                if (element) {
                    return element.scrollTo(scrollOptions);
                }
                return window.scrollTo(scrollOptions);
            }, SCROLL_TO_DELAY);
        } else {
            this.animatedScrollTop(yRoundedOffset, element);
        }

        // TODO figure out some better way to detect scroll done
        const getScrollValue = () => Math.floor(window.pageYOffset);
        const maxScrollValue =
            Math.max(
                document.body.scrollHeight,
                document.body.offsetHeight,
                document.documentElement.clientHeight,
                document.documentElement.scrollHeight,
                document.documentElement.offsetHeight,
            ) - window.innerHeight;
        let lastScrollValue = getScrollValue();

        return new Promise<void>((resolve) => {
            let scrollIntervalCount = 0;

            const scrollingInterval = window.setInterval(() => {
                const currentScrollValue = getScrollValue();
                const scrollToLastPoint =
                    yRoundedOffset > maxScrollValue
                        ? maxScrollValue
                        : yRoundedOffset;

                if (
                    (this.isApproxEqual(
                        lastScrollValue,
                        currentScrollValue,
                        1,
                    ) &&
                        (lastScrollValue === 0 ||
                            this.isApproxEqual(
                                lastScrollValue,
                                scrollToLastPoint,
                                1,
                            ))) ||
                    scrollIntervalCount > SCROLL_CHECK_COUNT
                ) {
                    resolve();
                    return clearInterval(scrollingInterval);
                }

                lastScrollValue = currentScrollValue;
                scrollIntervalCount = scrollIntervalCount + 1;
            }, SCROLL_CHECK_INTERVAL);
        });
    }

    hasClass(name: string): boolean {
        return this.document.body.classList.contains(name);
    }

    scrollToElementById(id: string): void {
        const element = this.document.getElementById(id);
        this.scrollToElement(element);
    }

    scrollToElementByIdWhenTopHidden(id: string): void {
        const element = this.document.getElementById(id);
        const isElementTopHidden =
            element.getBoundingClientRect().top - this.getTopOffset() < 0;

        if (isElementTopHidden) {
            this.scrollToElement(element);
        }
    }

    scrollToElement(element: HTMLElement, offsetTop?: number): void {
        if (!element || !element.scrollTo) {
            return;
        }

        const topOffset = isNumber(offsetTop) ? offsetTop : this.getTopOffset();
        const result = element.offsetTop - topOffset;
        this.scrollTop(result);
    }

    scrollElementTop(element: HTMLElement, yOffset = 0): void {
        this.scrollTop(yOffset, element);
    }

    updateBodyClasses(
        previousBodyClasses: string,
        currentBodyClasses: string,
    ): void {
        this.bodyUtilService.updateBodyClasses(
            previousBodyClasses,
            currentBodyClasses,
        );
    }

    animatedScrollTop(yOffset = 0, element?: HTMLElement): void {
        const bodyParentNode = this.document.body.parentNode as HTMLElement;
        let start: number;

        if (element) {
            start = element.scrollTop;
        } else {
            start =
                this.document.documentElement.scrollTop ||
                bodyParentNode.scrollTop ||
                this.document.body.scrollTop;
        }

        const change = yOffset - start;
        let currentTime = 0;
        const increment = 20;

        const easeInOutQuad = (t: number, b: number, c: number, d: number) => {
            t /= d / 2;
            if (t < 1) {
                return (c / 2) * t * t + b;
            }
            t--;
            return (-c / 2) * (t * (t - 2) - 1) + b;
        };

        const scrollInterval = setInterval(() => {
            if (currentTime < 500) {
                currentTime += increment;
                const elementToScroll = element ? element : window;

                elementToScroll.scrollTo(
                    0,
                    easeInOutQuad(currentTime, start, change, 500),
                );
            } else {
                clearInterval(scrollInterval);
            }
        }, increment);
    }

    // TODO: Temporary solution during migration
    private getTopOffset(): number {
        const menu = this.document.getElementsByTagName('nav')[0];

        return menu ? menu.offsetHeight : 0;
    }

    private isApproxEqual(a: number, b: number, range = 0): boolean {
        return Math.abs(a - b) <= range;
    }
}
