import { Injectable } from '@angular/core';

import { animationFrameScheduler, combineLatest, fromEvent, Observable } from 'rxjs';
import {
    debounceTime,
    map,
    shareReplay,
    startWith,
    mapTo,
    distinctUntilChanged,
} from 'rxjs/operators';

const MOBILE_PX = 600;
const TABLET_PX = 906;
const LAPTOP_PX = 1240;
const DESKTOP_PX = 1440;

type WindowScrollPosition = {
    top: number;
    left: number;
};

const EVENT_DEBOUNCE_TIME = 400;

@Injectable({
    providedIn: 'root',
})
export class EnvironmentService {
    private userAgent: string = window.navigator.userAgent;

    readonly windowResize$: Observable<Window> = fromEvent<Event>(
        window,
        'resize',
        { passive: true },
    ).pipe(
        debounceTime(EVENT_DEBOUNCE_TIME, animationFrameScheduler),
        map(({ target }) => target as Window),
        startWith(window),
        shareReplay({ bufferSize: 1, refCount: true }),
    );

    // ( < 600px )
    readonly isMobileDevice$: Observable<boolean> = this.windowResize$.pipe(
        map(() => window.innerWidth),
        distinctUntilChanged(),
        map((width: number) => width < MOBILE_PX),
        startWith(window.innerWidth < MOBILE_PX),
        shareReplay({ bufferSize: 1, refCount: true }),
    );

    // ( 600 - 905px )
    readonly isSmallTabletDevice$: Observable<boolean> =
        this.windowResize$.pipe(
            map(() => window.innerWidth),
            distinctUntilChanged(),
            map((width: number) => MOBILE_PX <= width && width < TABLET_PX),
            startWith(
                MOBILE_PX <= window.innerWidth && window.innerWidth < TABLET_PX,
            ),
            shareReplay({ bufferSize: 1, refCount: true }),
        );

    // ( 906 - 1239px )
    readonly isTabletDevice$: Observable<boolean> = this.windowResize$.pipe(
        map(() => window.innerWidth),
        distinctUntilChanged(),
        map((width: number) => TABLET_PX <= width && width < LAPTOP_PX),
        startWith(
            TABLET_PX <= window.innerWidth && window.innerWidth < LAPTOP_PX,
        ),
        shareReplay({ bufferSize: 1, refCount: true }),
    );

    // ( 1240 - 1439px )
    readonly isLaptopDevice$: Observable<boolean> = this.windowResize$.pipe(
        map(() => window.innerWidth),
        distinctUntilChanged(),
        map((width: number) => LAPTOP_PX <= width && width < DESKTOP_PX),
        startWith(
            LAPTOP_PX <= window.innerWidth && window.innerWidth < DESKTOP_PX,
        ),
        shareReplay({ bufferSize: 1, refCount: true }),
    );

    // ( 1440+ px ) (old -> isXlDesktop$)
    readonly isLargeDevice$: Observable<boolean> = this.windowResize$.pipe(
        map(() => window.innerWidth),
        distinctUntilChanged(),
        map((width: number) => width >= DESKTOP_PX),
        startWith(window.innerWidth >= DESKTOP_PX),
        shareReplay({ bufferSize: 1, refCount: true }),
    );

    // ( 1240+ px )
    readonly isLargeDesktop$: Observable<boolean> = this.windowResize$.pipe(
        map(() => window.innerWidth),
        distinctUntilChanged(),
        map((width: number) => width >= LAPTOP_PX),
        startWith(window.innerWidth >= LAPTOP_PX),
        shareReplay({ bufferSize: 1, refCount: true }),
    );

    readonly deviceIsTablet$ = combineLatest([
        this.isSmallTabletDevice$,
        this.isTabletDevice$,
    ]).pipe(
        map(
            ([isSmallTabletDevice, isTabletDevice]) =>
                isSmallTabletDevice || isTabletDevice,
        ),
    );

    /**
     * @deprecated
     */
    // ( < 906px )  ( old: < 768px )
    // this is an old variable, should be replaced with isMobileDevice$ during redesign
    readonly isMobile$: Observable<boolean> = this.windowResize$.pipe(
        map(() => window.innerWidth),
        distinctUntilChanged(),
        map((width: number) => width < TABLET_PX),
        startWith(window.innerWidth < TABLET_PX),
        shareReplay({ bufferSize: 1, refCount: true }),
    );

    // ( 600 - 1239px )
    readonly isTablet$: Observable<boolean> = this.windowResize$.pipe(
        map(() => window.innerWidth),
        distinctUntilChanged(),
        map((width: number) => MOBILE_PX <= width && width < LAPTOP_PX),
        startWith(
            MOBILE_PX <= window.innerWidth && window.innerWidth < LAPTOP_PX,
        ),
        shareReplay({ bufferSize: 1, refCount: true }),
    );

    /**
     * @deprecated
     */
    // ( >= 906px )  ( old: > 768px )
    // this is an old variable, should be replaced with isDesktopDevice$ during redesign
    readonly isDesktop$: Observable<boolean> = this.isMobile$.pipe(
        map((isMobile) => !isMobile),
        shareReplay({ bufferSize: 1, refCount: true }),
    );

    // ( >= 600px )
    readonly isDesktopDevice$: Observable<boolean> = this.isMobileDevice$.pipe(
        map((isMobileDevice) => !isMobileDevice),
        shareReplay({ bufferSize: 1, refCount: true }),
    );

    readonly windowOrientationChange$: Observable<null> = fromEvent<Event>(
        window,
        'orientationchange',
        { passive: true },
    ).pipe(
        debounceTime(EVENT_DEBOUNCE_TIME, animationFrameScheduler),
        mapTo(null),
        shareReplay({ bufferSize: 1, refCount: true }),
    );

    readonly windowScroll$: Observable<WindowScrollPosition> = fromEvent<Event>(
        window,
        'scroll',
        { passive: true },
    ).pipe(
        startWith(null),
        debounceTime(0, animationFrameScheduler),
        map(() => window),
        map(({ pageYOffset, pageXOffset }) => ({
            top: pageYOffset,
            left: pageXOffset,
        })),
        shareReplay({ bufferSize: 1, refCount: true }),
    );

    readonly isAndroid: boolean = this.checkIsAndroid();
    readonly isRelatedAppsCheckAvailable = (window.navigator as any)
        .getInstalledRelatedApps;
    readonly isFireFox: boolean = this.checkIsFireFox();
    readonly isInternetExplorer: boolean = this.checkIsInternetExplorer();
    readonly isMSEdge: boolean = this.checkIsMSEdge();
    readonly isIOS: boolean = this.checkIsIOS();
    readonly isSafari: boolean = this.checkIsSafari();
    readonly isRetina: boolean = this.checkIsRetina();
    readonly isMacLike: boolean = this.checkIsMac();

    readonly isMobileOS = this.isIOS || this.isAndroid;

    readonly hasSmoothScroll: boolean = this.checkSmoothScroll();
    readonly transitionsEndEventName = this.getTransitionsEndEventName();
    readonly isMobileDevice = window.screen.hasOwnProperty('orientation');

    private getTransitionsEndEventName(): string {
        const el = document.createElement('fakeelement');

        const transitions = [
            { css: 'transition', method: 'transitionend' },
            { css: 'OTransition', method: 'oTransitionEnd' },
            { css: 'MozTransition', method: 'transitionend' },
            { css: 'WebkitTransition', method: 'webkitTransitionEnd' },
        ];

        for (const entry of transitions) {
            if (el.style.hasOwnProperty(entry.css)) {
                return entry.method;
            }
        }
    }

    private checkIsAndroid(): boolean {
        return this.userAgent.indexOf('Android') > -1;
    }

    private checkIsFireFox(): boolean {
        return this.userAgent.includes('Firefox');
    }

    private checkIsInternetExplorer(): boolean {
        return (
            !!this.userAgent.match(/MSIE|Trident|rv:11/) ||
            !!(window.navigator as any).msSaveOrOpenBlob // TODO ANGULAR: non-supported in TS navigator method
        );
    }

    private checkIsMSEdge(): boolean {
        return this.userAgent.includes('Edge');
    }

    private checkIsIOS(): boolean {
        return (
            /iphone|ipod|ipad/i.test(this.userAgent) ||
            (/Macintosh/.test(navigator.userAgent) && 'ontouchend' in document)
        );
    }

    private checkIsMac(): boolean {
        return /mac/i.test(this.userAgent) || this.checkIsIOS();
    }

    private checkIsSafari(): boolean {
        return (
            this.isIOS || /^((?!chrome|android).)*safari/i.test(this.userAgent)
        );
    }

    private checkIsRetina(): boolean {
        const mediaQuery =
            '(-webkit-min-device-pixel-ratio: 1.5),\
            (min--moz-device-pixel-ratio: 1.5),\
            (-o-min-device-pixel-ratio: 3/2),\
            (min-resolution: 1.5dppx)';
        if (window.devicePixelRatio > 1) {
            return true;
        }

        return window.matchMedia && window.matchMedia(mediaQuery).matches;
    }

    private checkSmoothScroll(): boolean {
        const documentElementStyles = window.document.documentElement
            .style as CSSStyleDeclaration;
        return documentElementStyles.hasOwnProperty('scrollBehavior');
    }
}
