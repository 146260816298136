import { ConnectedPosition } from '@angular/cdk/overlay';

import { variationClassBuilder } from '../utils';

import { BASE_OVERLAY_CLASS } from './css-classes';

export interface OverlayGlobalPositionOffset {
    top?: string;
    right?: string;
    bottom?: string;
    left?: string;
}

export const enum OverlayGlobalPosition {
    Top = 'top',
    TopRight = 'topRight',
    Right = 'right',
    Bottom = 'bottom',
    Left = 'left',
    Center = 'center',
}

export const enum OverlayConnectedPosition {
    Top = 'top',
    TopLeft = 'topLeft',
    TopLeftInner = 'topLeftInner',
    TopRight = 'topRight',
    TopRightInner = 'topRightInner',
    Right = 'right',
    RightTop = 'rightTop',
    RightBottom = 'rightBottom',
    Bottom = 'bottom',
    BottomLeft = 'bottomLeft',
    BottomLeftInner = 'bottomLeftInner',
    BottomRight = 'bottomRight',
    BottomRightInner = 'bottomRightInner',
    Left = 'left',
    LeftTop = 'leftTop',
    LeftBottom = 'leftBottom',
}

export const CONNECTED_OVERLAY_POSITIONS: {
    [key: string]: ConnectedPosition;
} = {
    top: {
        originX: 'center',
        originY: 'top',
        overlayX: 'center',
        overlayY: 'bottom',
        panelClass: variationClassBuilder(BASE_OVERLAY_CLASS, ['position-top']),
    },
    topLeft: {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'bottom',
        panelClass: variationClassBuilder(BASE_OVERLAY_CLASS, [
            'position-top-left',
        ]),
    },
    topLeftInner: {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'top',
        panelClass: variationClassBuilder(BASE_OVERLAY_CLASS, [
            'position-top-left-inner',
        ]),
    },
    topRight: {
        originX: 'end',
        originY: 'top',
        overlayX: 'end',
        overlayY: 'bottom',
        panelClass: variationClassBuilder(BASE_OVERLAY_CLASS, [
            'position-top-right',
        ]),
    },
    topRightInner: {
        originX: 'end',
        originY: 'top',
        overlayX: 'end',
        overlayY: 'top',
        panelClass: variationClassBuilder(BASE_OVERLAY_CLASS, [
            'position-top-right-inner',
        ]),
    },
    right: {
        originX: 'end',
        originY: 'center',
        overlayX: 'start',
        overlayY: 'center',
        panelClass: variationClassBuilder(BASE_OVERLAY_CLASS, [
            'position-right',
        ]),
    },
    rightTop: {
        originX: 'end',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'top',
        panelClass: variationClassBuilder(BASE_OVERLAY_CLASS, [
            'position-right-top',
        ]),
    },
    rightBottom: {
        originX: 'end',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'bottom',
        panelClass: variationClassBuilder(BASE_OVERLAY_CLASS, [
            'position-right-bottom',
        ]),
    },
    bottom: {
        originX: 'center',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'top',
        panelClass: variationClassBuilder(BASE_OVERLAY_CLASS, [
            'position-bottom',
        ]),
    },
    bottomLeft: {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
        panelClass: variationClassBuilder(BASE_OVERLAY_CLASS, [
            'position-bottom-left',
        ]),
    },
    bottomLeftInner: {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'bottom',
        panelClass: variationClassBuilder(BASE_OVERLAY_CLASS, [
            'position-bottom-left-inner',
        ]),
    },
    bottomRight: {
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'top',
        panelClass: variationClassBuilder(BASE_OVERLAY_CLASS, [
            'position-bottom-right',
        ]),
    },
    bottomRightInner: {
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'bottom',
        panelClass: variationClassBuilder(BASE_OVERLAY_CLASS, [
            'position-bottom-right-inner',
        ]),
    },
    left: {
        originX: 'start',
        originY: 'center',
        overlayX: 'end',
        overlayY: 'center',
        panelClass: variationClassBuilder(BASE_OVERLAY_CLASS, [
            'position-left',
        ]),
    },
    leftTop: {
        originX: 'start',
        originY: 'top',
        overlayX: 'end',
        overlayY: 'top',
        panelClass: variationClassBuilder(BASE_OVERLAY_CLASS, [
            'position-left-top',
        ]),
    },
    leftBottom: {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'bottom',
        panelClass: variationClassBuilder(BASE_OVERLAY_CLASS, [
            'position-left-bottom',
        ]),
    },
};
