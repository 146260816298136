import { ContentChild, Directive, Input, TemplateRef } from '@angular/core';

import { GridCellDefDirective } from '../cell/cell-def.directive';
import { GridHeaderCellDefDirective } from '../header-cell/header-cell-def.directive';
import { GridExpandedCellDefDirective } from '../expanded/cell-expanded-def.directive';

@Directive({
    selector: '[fiGridColumnDef]',
    standalone: true,
})
export class GridColumnDefDirective {
    @Input() sticky = false;
    @Input() expander = false;
    @Input() stickyRight = false;
    @Input() sortable = false;
    @Input() asRow = false;
    // This should probably be replaced with desktopVariation to make it flexible
    @Input() reversed = false;
    @Input() centered = false;
    @Input() flexible = false;
    @Input() minWidth: number;

    // Any styles we need to apply for mobile presentation
    @Input() mobileVariation: string;

    @Input('fiGridColumnDef') field = '';

    @ContentChild(GridCellDefDirective, { read: TemplateRef })
    cell: TemplateRef<GridCellDefDirective>;

    @ContentChild(GridHeaderCellDefDirective, {
        read: TemplateRef,
    })
    headerCell: TemplateRef<GridHeaderCellDefDirective>;

    @ContentChild(GridExpandedCellDefDirective, {
        read: TemplateRef,
    })
    expandedCell: TemplateRef<GridExpandedCellDefDirective>;
}
