<fi-label
    [class.hasError]="field.touched && field.errors"
    [fiVariation]="variation"
    [fiVariationBase]="'fi-select-field'"
    [formGroup]="formGroup"
    class="fi-select-field"
>
    <span #label>
        <ng-content></ng-content>
    </span>
    <select
        [name]="name"
        [formControlName]="name"
        [value]="field.value"
        class="fi-select-field__select fi-text fi-text--small fi-text--dark"
    >
        <ng-content select="option"></ng-content>
    </select>
</fi-label>
