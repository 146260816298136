import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    OnDestroy,
    ViewChild,
    ElementRef,
    AfterViewInit,
    ChangeDetectorRef,
    inject,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Chart } from 'highcharts';
import { get, findLast } from 'lodash';
import { animationFrameScheduler } from 'rxjs';
import { observeOn, filter } from 'rxjs/operators';

import { resizeElement } from '../../utils';

const DEFAULT_COLOR = '#e4e9ed';
const TEXT_COLOR = '#969696';
const FONT_SIZE = '14px';

// @todo: create one interface for the whole app
interface ChartOptions {
    valueKey: string;
    min?: number;
    max?: number;
    getTooltipText: (value: number | string) => string;
    getYAxisLabel?: (value: number | string) => string;
    chartHeight?: number;
    title?: string;
    disallowBarSelect?: boolean;
}

@UntilDestroy()
@Component({
    selector: 'fi-quarterly-monthly-chart',
    templateUrl: 'quarterly-monthly-chart.component.html',
    styleUrls: ['./quarterly-monthly-chart.component.scss'],
    standalone: true,
})
export class QuarterlyMonthlyChartComponent
    implements OnChanges, OnDestroy, AfterViewInit
{
    private readonly changeDetectorRef = inject(ChangeDetectorRef);

    @Input() data: any;
    @Input() isMonthly: boolean;
    @Input() options: ChartOptions;

    @Output() selectPeriod = new EventEmitter<Record<string, any>>();

    @ViewChild('chartElement') chartElRef: ElementRef<HTMLElement>;

    readonly MONTHLY_OPTIONS = {
        pointPadding: 0.35,
        tickInterval: 20,
        xOffset: -15,
    };
    readonly optionalMethods = ['getTooltipText', 'getYAxisLabel'];

    chart: any = null;
    chartMap: Record<string, any[]> = {};
    selectedBarIndex: number;
    selected: any;

    ngOnChanges(changesObj: any): void {
        if (changesObj.options?.currentValue) {
            this.setOptions();
        }

        if (changesObj.data || changesObj.isMonthly) {
            setTimeout(() => {
                this.processWatchedData();
                this.changeDetectorRef.markForCheck();
            }, 0);
        }
    }

    ngAfterViewInit(): void {
        this.handleChartResize();
    }

    ngOnDestroy(): void {
        this.safelyDestroy();
    }

    /**
     * Sets options. All options that are listed in 'optionalMethods' list override methods on this
     * Redraws chart if something was changed
     */
    private setOptions(): void {
        const changed = this.optionalMethods.filter(
            (methodName) => !!this.options[methodName],
        );

        if (changed.length > 1) {
            setTimeout(() => this.processWatchedData(), 0);
        }
    }

    /**
     * Accepts custom event from custom events Highcharts module
     */
    private setSelectedModel(event: any): void {
        const { point } = event.target;
        if (point.selected) {
            event.preventDefault();
            return;
        }

        point.select();

        const selected = this.chartMap[point.category];
        this.selected = selected;
        this.selectPeriod.emit(selected);
        this.selectedBarIndex = point.index;
    }

    private processWatchedData(): void {
        if (!this.data) {
            this.chart && this.safelyDestroy();
            return;
        }

        if (this.data.length) {
            this.handleChartWithData();
        } else {
            this.safelyDestroy();
            this.chartMap = {};
        }
    }

    private handleChartWithData(): void {
        const options = this.getChartOptions(this.data, this.isMonthly);
        this.chartMap = this.getChartMap(this.data);

        if (this.chart) {
            this.repaint(options);
        } else {
            this.paint(options);
        }

        this.selectDefaultItem();
    }

    private getChartOptions(chartData: any, isMonthlyChart: boolean): any {
        const values = this.getItemValues(chartData);

        let options = {
            data: values,
            minValue: get(this.options, 'min', this.getMin(values)),
            maxValue: this.options.max,
            avgValue: this.getAverage(values),
            categories: chartData.map(this.getItemLabel, this),
            pointPadding: 0,
            xOffset: 0,
            title: get(this.options, 'title', ''),
            height: this.options.chartHeight,
        };

        if (isMonthlyChart) {
            options = {
                ...options,
                ...this.MONTHLY_OPTIONS,
                ...{ step: values.length - 1 },
            };
        }

        return options;
    }

    private getChartMap(dataArray: any[] = []): any {
        return dataArray.reduce((map: any, item) => {
            const label = this.getItemLabel(item);
            map[label] = item;
            return map;
        }, {});
    }

    /**
     * Selects most recent kpi as default value and sets it
     */
    private selectDefaultItem(): void {
        if (this.hasSelectedItem()) {
            this.selected = this.data[this.selectedBarIndex];
            return;
        }

        const chartData = get(this.chart, 'series[0].data', []);
        if (!chartData.length) {
            return;
        }

        const lastWithData = findLast(chartData, (item) => item.y > 0) ?? {
            index: 0,
        };
        const { index } = lastWithData;

        chartData[index].select(true, true);
        this.selected = this.data[index];
        this.selectedBarIndex = index;
    }

    private hasSelectedItem(): boolean {
        if (!this.selected) {
            return false;
        }

        /**
         * we check against the label since month and quarter props can be spread
         * through multiple years
         */
        return this.data.some((item) => item.label === this.selected.label);
    }

    private getMin(values: number[]): number {
        return Math.min.apply(null, values) * 0.95;
    }

    private getAverage(values: number[]): number {
        const sum = values.reduce((sum, item) => sum + item, 0);

        return sum / values.length;
    }

    private getItemValues(chartData: any[]): any {
        return chartData.map((item) => item[this.options.valueKey]);
    }

    private getItemLabel(item: any): string {
        return item.label;
    }

    private repaint(options: any): void {
        this.chart.series[0].setData(options.data, true);
        this.chart.xAxis[0].setCategories(options.categories, true, true);
        this.chart.yAxis[0].setExtremes(options.minValue, options.maxValue);

        const avgLine = this.chart.yAxis[0].plotLinesAndBands[0];
        avgLine.options.value = options.avgValue;
        avgLine.render();

        this.chart.yAxis[0].options.tickInterval = options.tickInterval;
        this.chart.series[0].options.pointPadding = options.pointPadding;
        this.chart.xAxis[0].options.labels.step = options.step;

        this.chart.yAxis[0].isDirty = true;
        this.chart.xAxis[0].isDirty = true;
        this.chart.redraw();
    }

    private paint(options: any): void {
        const vm = this;

        this.chart = new Chart('fi-quarterly-monthly-chart', {
            chart: {
                type: 'column',
                spacingRight: 30,
                height: options.height,
            },
            legend: { enabled: false },
            title: {
                text: options.title,
                useHTML: true,
                y: 4,
            },
            xAxis: {
                categories: options.categories,
                tickWidth: 0,
                crosshair: false,
                labels: {
                    x: options.xOffset,
                    style: {
                        color: TEXT_COLOR,
                        fontSize: FONT_SIZE,
                        textOverflow: 'none',
                    },
                    step: options.step,
                },
                lineColor: '#f1f5f8',
            },
            yAxis: {
                tickInterval: options.tickInterval,
                min: options.minValue,
                max: options.maxValue,
                title: { text: '' },
                labels: {
                    style: { color: TEXT_COLOR, fontSize: FONT_SIZE },
                    formatter() {
                        return vm.getYAxisLabel(this.value);
                    },
                },
                plotLines: [
                    {
                        value: options.avgValue,
                        color: '#979797',
                        dashStyle: 'Dash',
                        label: {
                            align: 'right',
                            text: 'Avg.',
                            useHTML: true,
                            y: 3,
                            x: 30,
                            style: {
                                color: TEXT_COLOR,
                                fontSize: FONT_SIZE,
                                fontFamily: 'inherit',
                                backgroundColor: 'none',
                            },
                        },
                        width: 1,
                        zIndex: 0,
                    },
                ],
                gridLineColor: '#f1f5f8',
            },
            credits: { enabled: false },
            tooltip: {
                formatter() {
                    return `<p style="color: #959899">${
                        this.x
                    }</p>${vm.getTooltipText(this.y)}`;
                },
                headerFormat: '',
                shared: false,
                useHTML: true,
                borderRadius: 10,
                borderWidth: 0,
                shadow: false,
                backgroundColor: '#222222',
                outside: true,
                style: {
                    color: 'rgba(255, 255, 255, 0.85)',
                    fontSize: FONT_SIZE,
                    fontFamily: 'inherit',
                },
            },
            plotOptions: {
                column: {
                    pointPadding: options.pointPadding,
                    groupPadding: 0.1,
                    zones: [{ color: '#0073cf' }],
                    states: {
                        hover: { color: '#0073cf' },
                        select: { color: '#fecb00', borderColor: '#fecb00' },
                    },
                },
                series: {
                    point: {
                        events: {
                            click: !vm.options.disallowBarSelect
                                ? (event) => this.setSelectedModel(event)
                                : null,
                            // @ts-ignore
                            mouseOver(event?) {
                                vm.updateColorsOnMouseOver(
                                    get(event.target, 'index'),
                                );
                            },
                            mouseOut() {
                                vm.setDefaultColors();
                            },
                        },
                    },
                    allowPointSelect: !vm.options.disallowBarSelect,
                },
            },
            series: [{ type: 'column', data: options.data }],
        });
    }

    private getYAxisLabel(value: number | string): string {
        const getYAxisLabel = this.options.getYAxisLabel;

        return getYAxisLabel ? getYAxisLabel(value) : `${value}`;
    }

    private getTooltipText(value: number | string): string {
        const getTooltipText = this.options.getTooltipText;

        return getTooltipText ? getTooltipText(value) : `<p>${value}</p>`;
    }

    private updateColorsOnMouseOver(index: number): void {
        const item = this.chart.series[0].data[index];

        this.updateSelectBar(item.selected);
        this.updateColors(index);
    }

    private setDefaultColors(): void {
        this.updateSelectBar(true);
        this.updateColors();
    }

    private updateColors(index?: number): void {
        this.chart.series[0].data.map((item: any) => {
            const mouseOverColor =
                item.index === index ? item.defaultColor : DEFAULT_COLOR;
            const mouseOutColor = item.defaultColor;

            item.update({
                color: index !== undefined ? mouseOverColor : mouseOutColor,
            });
        });
    }

    private updateSelectBar(flag: boolean): void {
        this.chart.series[0].data[this.selectedBarIndex].select(flag, flag);
    }

    private safelyDestroy(): void {
        if (this.chart) {
            this.chart.destroy();
            this.chart = null;
        }
    }

    private handleChartResize(): void {
        resizeElement(this.chartElRef.nativeElement)
            .pipe(
                observeOn(animationFrameScheduler),
                filter(() => !!this.chart),
                untilDestroyed(this),
            )
            .subscribe(() => {
                this.chart.reflow(null);
            });
    }
}
