import {
    AfterContentInit,
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    ContentChildren,
    ElementRef,
    EventEmitter,
    forwardRef,
    HostBinding,
    HostListener,
    Input,
    OnDestroy,
    Output,
    QueryList,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
    inject,
} from '@angular/core';
import {
    A11yModule,
    ActiveDescendantKeyManager,
    FocusMonitor,
    FocusOrigin,
} from '@angular/cdk/a11y';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AsyncPipe } from '@angular/common';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, fromEvent, merge, of, Observable } from 'rxjs';
import {
    exhaustMap,
    filter,
    first,
    map,
    mapTo,
    skip,
    switchMap,
    tap,
} from 'rxjs/operators';
import { find, flatten, isEqual, xorWith } from 'lodash';

import { OnChange } from '../../decorators';
import { VariationDirective } from '../../directives';
import { IconComponent } from '../../components';
import {
    OverlayContainerRef,
    OverlayEvent,
    OverlayEventType,
    OverlayService,
    OverlayConnectedPosition,
    OverlayScrollStrategy,
    isBlurEvent,
    OverlayModuleOwn,
} from '../overlay';

import { DropdownItemComponent, DropdownOption } from './item/item.component';
import { DropdownListDefDirective } from './list/list-def.directive';

@UntilDestroy()
@Component({
    selector: 'fi-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss'],
    exportAs: 'fiDropdown',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DropdownComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        OverlayModuleOwn,
        A11yModule,
        AsyncPipe,
        VariationDirective,
        IconComponent,
    ],
})
export class DropdownComponent
    implements AfterContentInit, ControlValueAccessor, OnDestroy
{
    private readonly focusMonitor = inject(FocusMonitor);
    private readonly elementRef = inject(ElementRef);
    private readonly overlayService = inject(OverlayService);
    private readonly viewContainerRef = inject(ViewContainerRef);

    @Input() variation = 'dropdown';
    @Input() multiple: boolean;
    @Input() disabled = false;
    @OnChange('updateSelectedFlag')
    @Input()
    selected: DropdownOption | DropdownOption[];
    @Input() alwaysSend = false;
    @Input() relativeWidth = false;

    @Output() change = new EventEmitter<DropdownOption | DropdownOption[]>();

    @HostBinding('attr.tabindex') tabindex = 0;

    @ContentChild(DropdownListDefDirective, {
        read: TemplateRef,
    })
    list: TemplateRef<DropdownListDefDirective>;

    @ContentChildren(DropdownItemComponent)
    options: QueryList<DropdownItemComponent>;

    @ViewChild('origin') origin: ElementRef;

    private keyManager: ActiveDescendantKeyManager<DropdownItemComponent>;
    private overlayContainerRef: OverlayContainerRef<TemplateRef<any>> | null;

    private isDropdownOpen = new BehaviorSubject<boolean>(false);

    isDropdownOpen$ = this.isDropdownOpen.asObservable();

    private closeDropdown$ = this.isDropdownOpen$.pipe(
        skip(1),
        filter((isOpen) => !isOpen),
        mapTo(null),
        untilDestroyed(this),
    );

    private openDropdown$ = this.isDropdownOpen$.pipe(
        skip(1),
        filter((isOpen) => isOpen),
        mapTo(null),
        untilDestroyed(this),
    );

    private itemClick$: Observable<DropdownOption>;

    private focusMonitor$: Observable<FocusOrigin> = this.focusMonitor.monitor(
        this.elementRef.nativeElement,
    );

    private focusDropdown$: Observable<null> = this.focusMonitor$.pipe(
        filter((origin: FocusOrigin) => !!origin),
        mapTo(null),
        untilDestroyed(this),
    );

    private blurDropdown$: Observable<null> = this.focusMonitor$.pipe(
        switchMap(() => this.overlayContainerRef.event$),
        filter(({ type }: OverlayEvent) => isBlurEvent(type)),
        mapTo(null),
        untilDestroyed(this),
    );

    private overlayDetached$: Observable<null> = of(null).pipe(
        switchMap(() => this.overlayContainerRef.event$),
        filter(({ type }: OverlayEvent) => type === OverlayEventType.Detach),
        mapTo(null),
        untilDestroyed(this),
    );

    private enterKeydown$: Observable<DropdownOption> = fromEvent(
        this.elementRef.nativeElement,
        'keydown',
    ).pipe(
        filter(({ key }: KeyboardEvent) => key === 'Enter'),
        map(() => this.keyManager.activeItem),
        map(({ option }: DropdownItemComponent) => option),
        untilDestroyed(this),
    );

    @HostListener('keydown', ['$event'])
    onKeydown(event: KeyboardEvent): void {
        this.keyManager.onKeydown(event);
    }

    @HostListener('keydown.tab', ['$event'])
    onTabKeydown(event: KeyboardEvent): void {
        if (!this.isDropdownOpen.value) {
            return;
        }

        event.preventDefault();
        this.isDropdownOpen.next(false);
        return;
    }

    @HostListener('keydown.space', ['$event'])
    onSpaceKeydown(event: KeyboardEvent): void {
        event.preventDefault();
        this.isDropdownOpen.next(true);
    }

    @HostListener('mousedown', ['$event'])
    onMouseDown(event: MouseEvent): void {
        event.stopPropagation();
    }

    @HostListener('click')
    onMouseClick(): void {
        const { value } = this.isDropdownOpen;
        this.isDropdownOpen.next(!value);
    }

    @HostListener('keydown.escape')
    onEscapeKeydown(): void {
        this.isDropdownOpen.next(false);
    }

    ngAfterContentInit(): void {
        this.itemClick$ = this.options.changes.pipe(
            tap(() => this.updateSelectedFlag()),
            switchMap((options) =>
                merge(
                    ...options.map(
                        (option: DropdownItemComponent) => option.changeEvent$,
                    ),
                    this.enterKeydown$,
                ),
            ),
            untilDestroyed(this),
        );

        this.keyManager = new ActiveDescendantKeyManager(
            this.options,
        ).skipPredicate(({ option }: DropdownItemComponent) =>
            this.isOptionSelected(option),
        );

        this.keyManager.change
            .asObservable()
            .pipe(
                filter(() => this.isDropdownHasScroll()),
                untilDestroyed(this),
            )
            .subscribe(() => {
                const element =
                    this.keyManager.activeItem.elementRef.nativeElement;

                if (element) {
                    element.scrollIntoView();
                }
            });

        this.focusDropdown$
            .pipe(
                exhaustMap(() => this.openDropdown$),
                exhaustMap(() => {
                    this.openDropdown();

                    return merge(
                        this.closeDropdown$,
                        this.itemClick$,
                        this.blurDropdown$,
                        this.overlayDetached$,
                    ).pipe(first(), untilDestroyed(this));
                }),
                untilDestroyed(this),
            )
            .subscribe((option?: DropdownOption) => {
                this.closeDropdown();
                this.isDropdownOpen.next(false);

                if (!option) {
                    return;
                }

                this.selectOption(option);
            });
    }

    ngOnDestroy(): void {
        this.focusMonitor.stopMonitoring(this.elementRef.nativeElement);
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChange = (_: DropdownOption) => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onTouched = () => {};

    writeValue(value: DropdownOption): void {
        this.selected = value;
    }

    registerOnChange(fn: (value: DropdownOption) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    private selectOption(option: DropdownOption): void {
        this.toggleOptionSelection(option);
        this.updateSelectedFlag();
    }

    private isOptionSelected(option: DropdownOption): boolean {
        return !!find(
            flatten([this.selected]),
            (selectedItem: DropdownOption) => isEqual(option, selectedItem),
        );
    }

    private toggleOptionSelection(option: DropdownOption): void {
        const isItemSelected = this.isOptionSelected(option);

        if (this.multiple) {
            this.toggleMultipleSelection(option, isItemSelected);
            return;
        }

        this.toggleSingleSelection(option, isItemSelected);
    }

    private toggleSingleSelection(
        option: DropdownOption,
        isOptionSelected: boolean,
    ): void {
        if (isOptionSelected && !this.alwaysSend) {
            return;
        }

        this.selected = option;
        this.change.emit(this.selected);
        this.onChange(this.selected);
    }

    private toggleMultipleSelection(
        option: DropdownOption,
        isOptionSelected: boolean,
    ): void {
        if (isOptionSelected) {
            this.selected = xorWith(
                [option],
                flatten([this.selected]),
                isEqual,
            );

            return;
        }

        this.selected = [...(this.selected as any), option];
        this.change.emit(this.selected);
        this.onChange(this.selected);
    }

    private updateSelectedFlag(): void {
        if (!this.options) {
            return;
        }

        this.options.forEach((optionComponent: DropdownItemComponent) => {
            optionComponent.isSelected = !!find(
                flatten([this.selected]),
                (selectedItem: DropdownOption) =>
                    isEqual(optionComponent.option, selectedItem),
            );
        });
    }

    private closeDropdown(): void {
        if (!this.overlayContainerRef) {
            return;
        }

        this.overlayContainerRef.close();
        this.overlayContainerRef = null;
    }

    private openDropdown(): void {
        if (this.overlayContainerRef) {
            return;
        }

        this.overlayContainerRef = this.overlayService.create(
            this.list,
            this.viewContainerRef,
            {
                variation: this.variation,
                positions: [
                    OverlayConnectedPosition.BottomLeft,
                    // TODO: Think how add 'OverlayConnectedPosition.Top' and check it position in overlay
                ],
                // TODO: Change to 'OverlayScrollStrategy.Reposition' after full migration
                scrollStrategy: OverlayScrollStrategy.Close,
            },
            null,
            this.origin,
        );

        this.overlayContainerRef.open();

        if (this.relativeWidth) {
            this.overlayContainerRef.attachRelativeWidth();
        }
    }

    private isDropdownHasScroll(): boolean {
        const element: HTMLElement = this.overlayContainerRef.overlayElement;
        if (!element) {
            return false;
        }

        return element.scrollHeight > element.clientHeight;
    }
}
