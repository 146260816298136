export type FiAnalyticsValue = string | number | boolean;

export interface BaseAnalyticsEvent {
    event: string;
    pagePath?: string;
    pageSection?: string;
    contentName?: string;

    [property: string]:
        | string
        | number
        | boolean
        | string[]
        | number[]
        | Array<Record<string, FiAnalyticsValue>>
        | Record<string,
        | FiAnalyticsValue
        | Array<Record<string, FiAnalyticsValue>>
        | Array<FiAnalyticsValue>
    >;
}

export abstract class TagManagerBaseService {
    pushTag: (event: BaseAnalyticsEvent) => void;
}

export class TagManagerService implements TagManagerBaseService {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    pushTag(): void {}
}
