<div #container class="fi-carousel">
    <div
        #list
        [style.width]="listWidthValue$ | async"
        [style.transform]="listTranslateValue$ | async"
        (panmove)="moveCarousel($event)"
        (panend)="adjustCarousel($event)"
        class="fi-carousel__list"
    >
        @for (item of items; track item) {
            <div class="fi-carousel__item">
                <ng-container [ngTemplateOutlet]="item"></ng-container>
            </div>
        }
    </div>
</div>
