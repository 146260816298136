<div
    [fiVariationBase]="'fi-modal-unit-row'"
    [fiVariation]="variation"
    [class.fi-modal-unit-row--top-indent]="isConnectedUnit"
>
    <div class="fi-modal-unit-row__cell">
        <fi-unit-title
            [isConnectedIconShown]="isConnectedUnit"
            class="fi-modal-unit-row__label fi-text fi-text--small"
        ></fi-unit-title>

        @if (!isNonContractUnit) {
            <div>
                @if (unitNumber) {
                    <span
                        [ngClass]="disabled ? 'fi-text--dark' : 'fi-text--link'"
                        (click)="handleUnitClicked()"
                        class="fi-text fi-text--medium unit-number"
                    >
                        <ng-container
                            *ngTemplateOutlet="
                                query ? highlightedUnit : unit;
                                context: { unitNumber: unitNumber }
                            "
                        ></ng-container>
                    </span>
                }
                @if (hasAlerts) {
                    <fi-alert-icon
                        [variation]="alerts.variation"
                        [fiTooltip]="alertsTooltipTpl"
                        [fiTooltipPosition]="
                            isMobileOS
                                ? mobileOSInfoTooltipPositions
                                : undefined
                        "
                    ></fi-alert-icon>
                }

                @if (nonContractUnitNumber) {
                    <span class="fi-text fi-text--medium">
                        <ng-container
                            *ngTemplateOutlet="
                                query ? highlightedUnit : unit;
                                context: { unitNumber: nonContractUnitNumber }
                            "
                        ></ng-container>
                    </span>
                }
            </div>
        } @else {
            <span class="fi-text fi-text--medium fi-text--dark">
                {{ unitNumber }}
            </span>
            <span class="fi-text fi-text--small">
                This vehicle is not under contract with Penske
            </span>
        }

        <ng-template #highlightedUnit let-unitNumber="unitNumber">
            <fi-highlight-text
                [fullText]="unitNumber"
                [highlightQuery]="query || ''"
                [variation]="'heavy,bg-yellow'"
            ></fi-highlight-text>
        </ng-template>

        <ng-template #unit let-unitNumber="unitNumber">
            {{ unitNumber }}
        </ng-template>
    </div>

    @if (showSecondField) {
        <div class="fi-modal-unit-row__cell">
            <span class="fi-modal-unit-row__label fi-text fi-text--small">
                {{ secondField || 'My Unit' }}
            </span>
            <ng-content></ng-content>
        </div>
    }
</div>

<!-- Template for alerts tooltip -->
<ng-template #alertsTooltipTpl>
    <span [innerHTML]="alerts.message"></span>
</ng-template>
