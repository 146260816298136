@for (
    itemDef of appliedItemDefs | memoizeFunc: modifyListStructure;
    track itemDef
) {
    @if (itemDef.group) {
        @if (
            (
                [itemDef.key, appliedFilters]
                | memoizeFunc: getAppliedFilterDataByKey : this
            ).length
        ) {
            <ng-container
                [ngTemplateOutlet]="itemDef.templateRef"
                [ngTemplateOutletContext]="{
                    state: state,
                    items:
                        [itemDef.key, appliedFilters]
                        | memoizeFunc: getAppliedFilterDataByKey : this,
                    remove: removeAppliedFilterGroup.bind(
                        this,
                        itemDef.key,
                        state
                    ),
                }"
            ></ng-container>
        }
    }
    @if (!itemDef.group) {
        @for (
            appliedData of [itemDef.key, appliedFilters]
                | memoizeFunc: getAppliedFilterDataByKey : this;
            track appliedData
        ) {
            <ng-container
                [ngTemplateOutlet]="itemDef.templateRef"
                [ngTemplateOutletContext]="{
                    state: state,
                    item: appliedData,
                    remove: removeAppliedFilter.bind(
                        this,
                        appliedData.value,
                        appliedData.key
                    ),
                }"
            ></ng-container>
        }
    }
}

@if (
    (hasControls && appliedFilters | memoizeFunc: canReset) || hideEmptyGroups
) {
    <fi-button
        [ariaLabel]="'Reset filters'"
        [variation]="'secondary-link,smallest'"
        (click)="handleResetAppliedFilters()"
    >
        Reset filters
    </fi-button>
}
