const PARSE_EMAIL_REGEX = /(.[^@]*)?(@)?(.[^\.]*)?(\.)?(.*)?/;
const LEADING_DOT_OR_AT_REGEX = /^\.|^@/;

export const enum EmailParsedGroup {
    Name = 'name',
    At = 'at',
    DomainName = 'domainName',
    Dot = 'dot',
    DomainArea = 'domainArea',
}

export type EmailParsedModel = Map<EmailParsedGroup, string>;

export function parseEmailValueToGroups(value = ''): EmailParsedModel {
    const matched = value.match(PARSE_EMAIL_REGEX);

    return new Map([
        [EmailParsedGroup.Name, matched[1]],
        [EmailParsedGroup.At, matched[2]],
        [EmailParsedGroup.DomainName, matched[3]],
        [EmailParsedGroup.Dot, matched[4]],
        [EmailParsedGroup.DomainArea, matched[5]],
    ]);
}

export function transformEmailValue(value: string, previousValue: string) {
    if (!value || hasOnlyDotOrAt(value)) {
        return '';
    }

    if (startWithDotOrAt(value)) {
        return getValueWithoutLeadingDotOrAt(value);
    }

    if (hasDoubleDotOrAt(value)) {
        return getValueWithoutDoubleDotOrAt(value);
    }

    if (hasPrecedingAt(value, previousValue)) {
        return getValueWithMovedAt(value, previousValue);
    }

    if (hasFollowingAt(value, previousValue)) {
        return getValueWithoutFollowingAt(value);
    }

    return value;
}

function hasOnlyDotOrAt(value: string): boolean {
    return /^[\.|@]$/.test(value);
}

function startWithDotOrAt(value: string): boolean {
    return LEADING_DOT_OR_AT_REGEX.test(value);
}

function hasDoubleDotOrAt(value: string): boolean {
    return /\.{2}|@{2}/.test(value);
}

function hasSecondAt(value: string): boolean {
    const matched = value.match(/@/g);

    return matched && matched.length > 1;
}

function hasPrecedingAt(currentValue: string, previousValue: string): boolean {
    return (
        hasSecondAt(currentValue) &&
        currentValue.indexOf('@') < previousValue.indexOf('@')
    );
}

function hasFollowingAt(currentValue: string, previousValue: string): boolean {
    return (
        hasSecondAt(currentValue) &&
        currentValue.indexOf('@') === previousValue.indexOf('@')
    );
}

function getValueWithoutLeadingDotOrAt(value: string): string {
    return value.replace(LEADING_DOT_OR_AT_REGEX, '');
}

function getValueWithoutDoubleDotOrAt(value: string): string {
    return value.replace(
        /(.+)(\.{2}|@{2})(.*)/,
        (_str, beforeMatchedValue, matched, afterMatchedValue) => {
            const singleMatchedSymbol = matched.substring(0, 1);

            return `${beforeMatchedValue}${singleMatchedSymbol}${afterMatchedValue}`;
        },
    );
}

function getValueWithMovedAt(
    currentValue: string,
    previousValue: string,
): string {
    const parsedValue = currentValue.split('');
    const previousAtPosition = previousValue.indexOf('@');

    parsedValue.splice(previousAtPosition + 1, 1, '');

    return parsedValue.join('');
}

function getValueWithoutFollowingAt(currentValue: string): string {
    const parsedValue = currentValue.split('');
    const lastAtPosition = currentValue.lastIndexOf('@');

    parsedValue.splice(lastAtPosition, 1, '');

    return parsedValue.join('');
}
