import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    inject,
} from '@angular/core';
import { UntypedFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { NgTemplateOutlet, NgClass, AsyncPipe } from '@angular/common';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { cloneDeep, isEqual } from 'lodash';

import { Animations } from '../../../animations';
import { FilterListPipe, MemoizeFuncPipe } from '../../../pipes';
import { OnChange } from '../../../decorators';
import {
    SelectableListItem,
    SelectableListComponent,
} from '../../../components/selectable-list/selectable-list.component';
import {
    InfiniteScrollComponent,
    SkeletonComponent,
    SkeletonElementComponent,
    IconComponent,
    ButtonComponent,
    InputComponent,
} from '../../../components';
import { GridSearchConfiguration } from '../../grid/models';
import { GridSearch } from '../../grid/store';
import { GridOnTabletService } from '../../grid/services';
import { GridSearchComponent } from '../../grid/search';
import { isEscapeModalEvent } from '../../overlay';
import {
    ModalCloseBarComponent,
    ModalComponent,
    ModalContentComponent,
    ModalContentTopComponent,
    ModalEvent,
    ModalFooterComponent,
    ModalHeaderComponent,
    ModalVariation,
} from '../../modal';

export interface SelectModalConfig {
    title: string;
    searchPlaceholder?: string;
    gridSearch?: boolean;
    listVariation?: string;
    labelShownAsSecondary?: boolean;
    loaderLabel?: string;
    // until no translation
    descriptionSingular: string;
    descriptionPlural: string;
    codeShownAsSecondary?: boolean;
    labelShowInfo?: boolean;
    type?: string;
    showTotal?: boolean;
    showTotalDescription?: boolean;
    primaryButtonLabel?: string;
    gtmClassNamePrimaryButton?: string;
    hideSelection?: boolean;
    modalVariation?: ModalVariation[];
    hasCustomSmallTabletAnimation?: boolean;
    templateShownAsSecondary?: TemplateRef<any>;
    searchFields?: string;
    withoutSelection?: boolean;
}

@UntilDestroy()
@Component({
    selector: 'fi-select-modal',
    templateUrl: './select-modal.component.html',
    styleUrls: ['./select-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [FilterListPipe],
    animations: [Animations.AnimateFooter],
    standalone: true,
    imports: [
        NgClass,
        AsyncPipe,
        ModalComponent,
        ModalCloseBarComponent,
        ModalHeaderComponent,
        ModalContentTopComponent,
        ModalContentComponent,
        ModalFooterComponent,
        ReactiveFormsModule,
        GridSearchComponent,
        InputComponent,
        InfiniteScrollComponent,
        SkeletonComponent,
        NgTemplateOutlet,
        SelectableListComponent,
        SkeletonElementComponent,
        IconComponent,
        ButtonComponent,
        MemoizeFuncPipe,
    ],
})
export class SelectModalComponent implements OnInit {
    private formBuilder = inject(UntypedFormBuilder);
    private filterListPipe = inject(FilterListPipe);
    private changeDetectorRef = inject(ChangeDetectorRef);
    private gridOnTabletService = inject(GridOnTabletService);

    @OnChange('setFilterItems')
    @Input()
    items: SelectableListItem[] = [];
    @OnChange('setCurrentSelectedItems')
    @Input()
    isModalOpened: boolean;
    @Input() isMultipleSelection = false;
    @Input() isInitializing: boolean;
    @Input() selected: SelectableListItem[];
    @Input() modalConfig: SelectModalConfig;
    @Input() modalVariation: ModalVariation[] = [];
    @Input() hasCustomSmallTabletAnimation = false;
    // @todo: investigate usage in other projects, need to be updated such as showNoDataPlaceholder is not correct and doesn't depend on filteredItems and has strange design for header/footer
    @Input() noDataPlaceholderContent: TemplateRef<any>;
    @Input() noDataMessage = '';
    @Input() searchFields: string;

    @Input() isInfiniteScrollLoading: boolean;
    @Input() gridSearchConfig: GridSearchConfiguration;
    @Input() gridSearchTotalItems: number;
    @Input() gridSearch: GridSearch;

    @Output() gridSearchChange = new EventEmitter<GridSearch>();
    @Output() loadMoreItems = new EventEmitter<void>();

    @Output() close = new EventEmitter<void>();
    @Output() selectionChange = new EventEmitter<SelectableListItem[]>();

    readonly isMobileView$ = this.gridOnTabletService.isMobileView$;
    readonly isDesktopView$ = this.gridOnTabletService.isDesktopView$;

    form = this.formBuilder.group({
        search: [''],
    });

    filteredItems: SelectableListItem[] = [];
    currentSelected: SelectableListItem[] = [];
    search = '';
    selectionChanged = false;

    ngOnInit(): void {
        this.form
            .get('search')
            .valueChanges.pipe(untilDestroyed(this))
            .subscribe((value: string | null) => {
                const trimmedValue = value?.trim();
                this.search = trimmedValue;
                this.filteredItems = this.filterListPipe.transform(
                    this.items,
                    this.searchFields || 'description',
                    trimmedValue,
                );

                if (!this.gridSearch && this.currentSelected) {
                    this.selected = this.currentSelected;
                }

                this.changeDetectorRef.markForCheck();
            });
    }

    setFilterItems(): void {
        this.filteredItems = cloneDeep(this.items);
    }

    setCurrentSelectedItems(): void {
        const selected = this.selected || [];
        this.currentSelected = [...selected];

        if (!this.currentSelected.length) {
            this.selectionChanged = false;
        }
    }

    showNoDataPlaceholder(isInitializing: boolean): boolean {
        return (
            this.noDataPlaceholderContent &&
            !isInitializing &&
            !this.filteredItems.length
        );
    }

    closeModal(): void {
        this.resetFrom();
        this.close.emit();
    }

    onSelectItems(selected: SelectableListItem[]): void {
        this.currentSelected = [...selected];
        this.selectionChanged = !isEqual(this.currentSelected, this.selected);
    }

    onSelect(): void {
        this.resetFrom();
        this.selectionChange.emit(this.currentSelected);
    }

    reset(): void {
        this.onSelectItems([]);
        this.onSelect();
    }

    getDescription([filteredQuantity = 0, selectedQuantity = 0]): string {
        const filteredText = this.getFilteredDescription(
            this.modalConfig.gridSearch
                ? this.gridSearchTotalItems
                : filteredQuantity,
        );

        if (this.search?.length && selectedQuantity && !this.gridSearch) {
            return `${this.getFilteredDescription(selectedQuantity)} selected`;
        }

        return selectedQuantity > 0 && this.isMultipleSelection
            ? `${selectedQuantity} of ${filteredText} selected`
            : filteredText;
    }

    handleGridSearch(search: GridSearch): void {
        this.currentSelected = [];
        this.gridSearchChange.emit(search);
    }

    handleLoadMoreItems(): void {
        this.loadMoreItems.emit();
    }

    handleModalEvent({ type }: ModalEvent): void {
        if (isEscapeModalEvent(type)) {
            this.closeModal();
        }
    }

    getModalVariation(modalVariation: ModalVariation[] = []): ModalVariation[] {
        return modalVariation.length
            ? modalVariation
            : [ModalVariation.FullHeight];
    }

    private getFilteredDescription(quantity = 0): string {
        const { descriptionPlural, descriptionSingular } = this.modalConfig;

        return `${quantity} ${
            !quantity || quantity > 1 ? descriptionPlural : descriptionSingular
        }`;
    }

    private resetFrom(): void {
        if (this.form) {
            this.form.reset();
        }
    }
}
