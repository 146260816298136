export function textHasOverflow(element: HTMLElement): boolean {
    if (element.offsetWidth < element.scrollWidth) {
        return true;
    }

    // Here we are temporarily adding styles, letting browser do reflow.
    // If word-break is happening, the element will expands its height.
    // We are checking if so happens, then returning true. And anyhow reverting styles.

    const className = 'break-if-does-not-fit';
    const initialHeight = element.scrollHeight;
    element.classList.add(className);

    if (element.offsetHeight > initialHeight) {
        element.classList.remove(className);
        return true;
    }

    element.classList.remove(className);
    return false;
}

export function textHasVerticalOverflow(element: HTMLElement): boolean {
    return element.offsetHeight < element.scrollHeight;
}
