import { Injectable } from '@angular/core';

import { BehaviorSubject, defer } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class GridMoreActionsService {
    readonly isOpenedDownloadModal$ = defer(() => this.isOpenedDownloadModal);
    readonly isOpenedManageColumnModal$ = defer(() => this.isOpenedManageColumnModal);

    private readonly isOpenedDownloadModal = new BehaviorSubject(false);
    private readonly isOpenedManageColumnModal = new BehaviorSubject(false);

    openDownloadModal(): void {
        this.isOpenedDownloadModal.next(true);
    }

    closeDownloadModal(): void {
        this.isOpenedDownloadModal.next(false);
    }

    openManageColumnModal(): void {
        this.isOpenedManageColumnModal.next(true);
    }

    closeManageColumnModal(): void {
        this.isOpenedManageColumnModal.next(false);
    }
}
