<fi-modal
    [open]="open"
    [animation]="modalAnimation"
    [variation]="modalVariation"
    [scrollStrategy]="scrollStrategy"
    (event)="handleModalEvent($event)"
    class="manage-columns-modal"
>
    @if (open) {
        <fi-modal-header (close)="closeModal()">
            <h3 class="fi-heading fi-heading--tertiary fi-heading--dark">
                Manage Columns
            </h3>
        </fi-modal-header>

        <fi-modal-content class="manage-columns-modal__content">
            <div class="manage-columns-modal__description">
                <p class="fi-text fi-text--medium fi-text--small">
                    Customize your columns by selecting the field(s) you would
                    like to be visible and reordering its position.
                </p>
            </div>

            <div class="manage-columns-modal__list" cdkScrollable fiScroll>
                @let notMovableItems =
                    [config, false]
                        | memoizeFunc: filterSettingByMovable : this;

                @for (setting of notMovableItems; track setting.key) {
                    <div class="manage-columns-modal__row">
                        <ng-container
                            [ngTemplateOutlet]="selectionSettingTemplate"
                            [ngTemplateOutletContext]="{
                                settingKey: setting.key,
                                setting: setting.value,
                            }"
                        ></ng-container>

                        @if (setting.value.notHideable) {
                            <span
                                class="fi-text fi-text--medium fi-text--small fi-text--light"
                            >
                                Required
                            </span>
                        }
                    </div>
                }

                <fi-draggable-list
                    [boundaryClass]="'.fi-overlay-pane'"
                    [withPlaceholder]="true"
                    (changeOrder)="changeSettingOrder($event)"
                >
                    @let movableItems =
                        [config, true]
                            | memoizeFunc: filterSettingByMovable : this;

                    @for (setting of movableItems; track setting.key) {
                        <ng-template
                            fiDraggableListItem
                            [fiDraggableListItemVariation]="
                                'with-arrows-placeholder'
                            "
                            [fiDraggableListItemArrowsPlaceholder]="
                                setting.value.notHideable ? 'Required' : ''
                            "
                        >
                            <ng-container
                                [ngTemplateOutlet]="selectionSettingTemplate"
                                [ngTemplateOutletContext]="{
                                    settingKey: setting.key,
                                    setting: setting.value,
                                }"
                            ></ng-container>
                        </ng-template>
                    }
                </fi-draggable-list>
            </div>
        </fi-modal-content>

        <fi-modal-footer>
            <div class="manage-columns-modal__footer">
                <span class="fi-text fi-text--medium">
                    {{ config | memoizeFunc: getDescription }}
                </span>

                <div class="manage-columns-modal__footer-control">
                    <fi-button
                        [ariaLabel]="'Cancel'"
                        [variation]="'secondary,block,animated'"
                        (click)="closeModal()"
                    >
                        Cancel
                    </fi-button>

                    <fi-button
                        [ariaLabel]="'Save Changes'"
                        [variation]="'primary,block,animated'"
                        (click)="handleSave()"
                    >
                        Save Changes
                    </fi-button>
                </div>
            </div>
        </fi-modal-footer>
    }
</fi-modal>

<ng-template
    #selectionSettingTemplate
    let-setting="setting"
    let-settingKey="settingKey"
>
    <fi-checkbox
        [checked]="!setting.isHidden"
        [disabled]="setting.notHideable"
        (change)="toggleSetting(settingKey, !setting.isHidden)"
        class="manage-columns-modal__selectable-item"
    >
        <span class="fi-text fi-text--medium">
            {{ setting?.text }}
        </span>
    </fi-checkbox>
</ng-template>
