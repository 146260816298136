export const MOBILE_MAX_PX = 600;
export const TABLET_MAX_PX = 1024;

export const INPUT_ERROR_CLASS = 'landing-form__field--error';
export const MODAL_SUCCESS_CLASS = 'landing-modal--confirm';

export const USERID_NOT_FOUND = 'USERID_NOT_FOUND';
export const USER_ID_ERROR_CODE = 'INVALID_VALUE';

export const ENTER_KEY = 13;

export function isIOS(): boolean {
    return !!navigator.userAgent.match(/iPhone|iPad|iPod/i);
}
