import { AfterViewInit, Directive, ElementRef, HostListener, Renderer2, inject } from '@angular/core';

/**
 * Hide modal footer top border when target element scrolled to its bottom
 */
@Directive({
    selector: '[hideModalFooterTopBorder]',
    standalone: true,
})
export class HideModalFooterTopBorderDirective implements AfterViewInit {
    private readonly el = inject(ElementRef);
    private readonly renderer = inject(Renderer2);

    @HostListener('scroll')
    onScroll(): void {
        this.applyFooterTopBorderStyling();
    }

    ngAfterViewInit(): void {
        this.applyFooterTopBorderStyling();
    }

    private get element(): HTMLElement {
        return this.el.nativeElement;
    }

    private applyFooterTopBorderStyling(): void {
        const modalFooter = document.querySelector('.fi-modal-footer') as HTMLElement;
        const isElementBottomReached = this.element.scrollHeight - this.element.offsetHeight - this.element.scrollTop < 1;

        if (isElementBottomReached) {
            this.renderer.setStyle(modalFooter, 'border-top-color', 'transparent');
        } else {
            this.renderer.removeStyle(modalFooter, 'border-top-color');
        }
    }
}
